.AdvancedApprovalPathHeader {
    display: flex;
    height: unset;
    padding-bottom: 0px;
    padding-top: 20px;
    margin-left: 16px;

	/* .AdvancedApprovalPathHeader__header */
	&__header {
        margin: auto 0;
        font-family: Inter;
        font-size: 15px;
        font-weight: 500;
        line-height: 22px;
        text-align: left;
	}

}
