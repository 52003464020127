// .InfoDisplay
.InfoDisplay {
	// .InfoDisplay__Avatar
	&__Avatar {
		border-radius: 50%;
		float: left;
		height: 36px;
		margin: 2px 12px 0 0;
		width: 36px;
	}
	// .InfoDisplay__BrandedInfo
	&__BrandedInfo {
		align-items: center;
		display: flex;
		fab-color: theme;
		fab-font-weight: semibold;
		fab-line-height: small;
	}
	// .InfoDisplay__Column
	&__Column {
		flex: 1;
		max-width: 275px;
	}
	// .InfoDisplay__Icon
	&__Icon {
		margin-right: 8px;
	}
	// .InfoDisplay__Info
	&__Info {
		align-items: center;
		display: flex;
	}
	// .InfoDisplay__InfoText
	&__InfoText {
		display: flex;
		fab-color: gray7;
		fab-font-size: teenie;
		fab-line-height: teenie;
		margin-bottom: 2px;
		margin-left: 24px;
	}
	// .InfoDisplay__Label
	&__Label {
		display: flex;
		fab-color: gray7;
		fab-font-size: teenie;
		fab-line-height: teenie;
		margin-bottom: 2px;
	}
	// .InfoDisplay__Row
	&__Row {
		display: flex;
		margin-bottom: 23px;
		// .InfoDisplay__Row:last-of-type
		&:last-of-type {
			margin-bottom: 0;
		}
	}
}
