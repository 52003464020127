.DepositAccount {
	align-items: center;
	box-sizing: border-box;
	display: flex;
	width: 100%;

	/* .DepositAccount__order */
	&__order {
		height: 23px;
		width: 23px;
		text-align: center;
		fab-color: white;
		border: 3px solid fab-color(white);
		border-radius: 50%;
		fab-background-color: gray6;
		fab-text: medium;
		margin-right: 11px;
	}

	/* .DepositAccount__data */
	&__data {
		align-items: center;
		display: flex;
		height: 65px;
		width: 100%;

		/* .DepositAccount__data--bordered */
		&--bordered {
			border-bottom: 1px solid fab-color(gray3);
		}
	}

	/* .DepositAccount__errorMessage */
	&__errorMessage {
		fab-color: attention;
		fab-font-size: teenie;
	}

	/* .DepositAccount__text */
	&__text {
		margin: 0;

		/* .DepositAccount__text--bold */
		&--bold {
			fab-font-weight: semibold;
		}
	}


	/* .DepositAccount__icon */
	&__icon {
		fab-fill: gray5;
		width: 81px;
		margin-right: 10px;
		text-align: center;
	}

	/* .DepositAccount__logo */
	&__logo {
		height: 32px;
		margin-right: 10px;
	}
}
