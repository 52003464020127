.NHPTemplateForm {
	padding-top: 4px;
	text-align: left;

	&__header {
		padding-bottom: 4px;
	}

	&__taskNote {
		margin-left: 9px;
	}
}
