
.BankAccountForm {
	margin-top: -9px;
	text-align: left;

	// .BankAccountForm--topSpacing
	&--topSpacing {
		padding-top: 3px;
		margin-top: unset;
	}

	// .BankAccountForm__bankLogo
	&__bankLogo {
		vertical-align: middle;
	}

	// .BankAccountForm__bankImage
	&__bankImage {
		height 28px;
	}

	// .BankAccountForm__contentBlock
	&__contentBlock {
		padding-bottom: 27px;
		margin-top: 0;
	}

	// .BankAccountForm__verified
	&__verified {
		margin-top: 12px;
	}

	// Footer overrides to hide/show the Cancel button when primary is enabled/disabled
	// .BankAccountForm__footerPrimary
	&__footerPrimary {

		// TODO: Avoid using Button implementation details to target the button. A custom class name should be used instead.
		// .BankAccountForm__footerPrimary.fab-Button:disabled + .SiteFooter__action, .BankAccountForm__footerPrimary.MuiButton-contained:disabled + .SiteFooter__action
		&.fab-Button:disabled + .SiteFooter__action,
		&.MuiButton-contained:disabled + .SiteFooter__action {
			visibility: hidden !important;
		}
	}
	&__plaidDropdown {
		display: block;
		margin-top: 4px;
	}
}
