.DeductionNameCell {
	box-sizing: border-box;
	height: 48px;

	// .DeductionNameCell--name
	&--name {
		padding-top: 14px;
	}

	// .DeductionNameCell--input
	&--input {
		padding-top: 7px;
	}
}
