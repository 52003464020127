// .SettingsApprovalsContainer
.SettingsApprovalsContainer {
	// .SettingsApprovalsContainer .AdvancedApprovals
	.AdvancedApprovals {
		// .SettingsApprovalsContainer .AdvancedApprovals__subheader
		&__subheader {
			// .SettingsApprovalsContainer .AdvancedApprovals__subheader .Subheader
			.Subheader {
				display: flex;
				gap: 12px;
				align-items: center;

				// .SettingsApprovalsContainer .AdvancedApprovals__subheader .Subheader__actionButton
				&__actionButton {
					cursor: pointer;
				}

				// .SettingsApprovalsContainer .AdvancedApprovals__subheader .Subheader__actionButton, .SettingsApprovalsContainer .AdvancedApprovals__subheader .Subheader__pathTypeIcon:not(.baseFillColor)
				&__actionButton,
				&__pathTypeIcon:not(.baseFillColor) {
					fill: $svgColorPalette.gray-3;
					display: flex;
					align-items: center;
				}


				// .SettingsApprovalsContainer .AdvancedApprovals__subheader .Subheader__actions
				&__actions {
					display: flex;
					padding-top: 0;
					position: relative;
                    top: 0;
				}

				// .SettingsApprovalsContainer .AdvancedApprovals__subheader .Subheader__catch-all-divider
				&__catch-all-divider {
					border-top: 1px solid $bhrColors.gray6;
					margin-top: 20px;
					margin-left: 42px;
					width: 93%;
				}

				// .SettingsApprovalsContainer .AdvancedApprovals__subheader .Subheader__icon
				&__icon {
					display: flex;
					justify-content: center;
					position: unset;
					top: 0;
					min-width: 0px;
				}

				// .SettingsApprovalsContainer .AdvancedApprovals__subheader .Subheader__type
				&__type {
					display: flex;
					flex-grow: 1;
					position: relative;
					min-height: unset;
					top: 0px;

					/* .SettingsApprovalsContainer .AdvancedApprovals__subheader .Subheader__type--editing */
					&--editing {
						display: block;
					}
				}
			}

			// .SettingsApprovalsContainer .AdvancedApprovals__subheader .PathTypeOptions
			.PathTypeOptions {
				flex-direction: column;
				display: block;

				// .SettingsApprovalsContainer .AdvancedApprovals__subheader .PathTypeOptions__list
				&__list {
					color: fab-color(gray10);
					list-style: none;
					list-style-position: inside;
					line-height: 19px;
					margin-left: 15px;

					//.SettingsApprovalsContainer .AdvancedApprovals__subheader .PathTypeOptions__listItem
					&Item {
						margin-top: 8px;

						//.SettingsApprovalsContainer .AdvancedApprovals__subheader .PathTypeOptions__listItem:before
						&:before {
							content:'\2022';
							color: fab-color(theme);
							margin-right: 9px;
						}
					}

				}

				// .SettingsApprovalsContainer .AdvancedApprovals__subheader .PathTypeOptions__title
				&__title {
					color: fab-color(theme);
					display: inline-block;
					flex-grow: 1;
					padding-top: 0 !important;
					font-family: Inter;
					font-size: 16px;
					font-weight: 500;
					line-height: 24px;
					text-align: left;
					margin-bottom: 0px !important;
					margin-top: 0px !important;
					margin-left: 0px;
				}
			}

			.PathTypeSelect {
				margin-left: 10px;
				width: 195px;
				display: inline-block;

				&__toggle {
					color: fab-color(gray10);
				}
			}

			// .SettingsApprovalsContainer .AdvancedApprovals__subheader .ActionButtons
			.ActionButtons {
				display: block;

				// .SettingsApprovalsContainer .AdvancedApprovals__subheader .ActionButtons .ActionButton
				.ActionButton {
					cursor: pointer;
					fill: $svgColorPalette.gray-3;

                    // .SettingsApprovalsContainer .AdvancedApprovals__subheader .ActionButton ActionButton__container
					&__container {
					    display: flex;
					}

					// .SettingsApprovalsContainer .AdvancedApprovals__subheader .ActionButtons .ActionButton__deleteButton
					&__deleteButton {
						margin-left: 12px;
					}
				}

				// .SettingsApprovalsContainer .AdvancedApprovals__subheader .ActionButtons--catchAll
				&--catchAll {
					// .SettingsApprovalsContainer .AdvancedApprovals__subheader .ActionButtons--catchAll .ActionButton__deleteButton
					.ActionButton__deleteButton {
						display: none;
					}
				}
			}
		}
	}
}
