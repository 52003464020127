// Former combiner
@require 'jCrop.mod';
@require 'Tab.mod';
@require 'Multiplex.mod.css';
// End former combiner

// Modules
@require 'FileList.mod';
@require 'Masks.mod';
@require 'FilesWrapper.mod.css';
@require 'checkbox-card/checkbox-card.styl';
@require 'hiring/email-editor/styles.styl';
@require 'hiring/placeholders/styles.styl';

@require '../components/ProcessBar/styles.styl';
@require 'account/webhooks/webhooks.styl';

@require '~perfect-scrollbar/dist/css/perfect-scrollbar.css';

// Job Openings
@require '../hiring/offer-letter/preview/styles';

@css {

/*------------------------------------*\
$Settings Styles
styles used Settings Page
\*------------------------------------*/

/*****************************************************************************************************************
* Position Relative
*****************************************************************************************************************/
#settings-page h2 span, .Approvals__level, .Approvals__undo, #settings-left, .settings-sub, #settings-main-content, .settings-sprites {
	position: relative;
}
body.settings footer, body.settings #navMain {
	z-index: 3;
}
.chzn-container.chzn-container-single + p {
	margin-top: 5px;
}
p.description {
	margin-bottom: 19px;
}
#grey-side-bar {
	display: block;
	height: 100%;
	width: 140px;
	background: #ffffff;
	background: -moz-linear-gradient(left, #ffffff 0, #F3F3F3 100%);
	background: -webkit-gradient(linear, left top, right top, color-stop(0, #ffffff), color-stop(100%, #F3F3F3));
	background: -webkit-linear-gradient(left, #ffffff 0, #F3F3F3 100%);
	background: -o-linear-gradient(left, #ffffff 0, #F3F3F3 100%);
	background: -ms-linear-gradient(left, #ffffff 0, #F3F3F3 100%);
	background: linear-gradient(to right, #ffffff 0, #F3F3F3 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#f3f3f3', GradientType=1 );
	position: absolute;
	z-index: 2;
	overflow: hidden;
	left: -140px;
}
#settings-page {
	width: 100%;
}
#settings-page:after {
	content: '';
	position: absolute;
	display: block;
	border-right: 1px solid #CCC;
	bottom: 0;
	top: 0;
	left: 0;
	width: 19.8%;
	min-width: 193px;
	z-index: 2;
}
#settings-page h2 {
	min-height: 30px;
}
#settings-page h2 span {
	display: inline-block;
	top: -1px;
}
#settings-left, .settings-sub, #settings-main-content {
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}
+jade() {
	#settings-page .settings-sub h3, #settings-page #settings-main-content h3 {
		padding-top: 10px;
	}
}
#settings-page .settings-sub h3 {
	padding-left: 22px;
	padding-top:10px;
}
#settings-page h3 > .settings-sprites {
	margin-right: 2px;
	margin-left: -22px;
}
#settings-main-content h3 > .settings-sprites {
	margin-left: 0;
}
#settings-page .dropdown-menu {
	margin-top: 6px;
}
#settings-page .settings-sub ul.settings-second-nav li a span {
	font-size: 13px;
	color: #888;
	font-weight: normal;
	padding-left: 3px;
}
#settings-left {
	display: block;
	height: 100%;
	padding-right: 20px;
	width: 19.8%;
	min-width: 193px;
	float: left;
	z-index: 3;
}
.settings-sub {
	padding-right: 1.59%;
	padding-left: 1.59%;
	background: white;
	float: left;
	height: 100%;
	width: 19%;
	z-index: 1;
	border-right-style: solid;
	border-right-width: 1px;
	left: 0;
	opacity: 0;
	visibility: hidden;
	position: absolute;
}
.settings-sub-wrapper {
	height: inherit;
}
.settings-sub.slideOut {
	left: 225px;
}
#settings-main-content {
	opacity: 0;
	padding-left: 4.4%;
	float: right;
	width: 79.5%;
	box-sizing: border-box;
	-webkit-transition: opacity .3s ease;
	-moz-transition: opacity .3s ease;
	-ms-transition: opacity .3s ease;
	-o-transition: opacity .3s ease;
	transition: opacity .3s ease;
}
#settings-main-content.no-sub-nav {
	width: 79.54%;
}
#settings-left li, .settings-sub li {
	font-size: 15px;
}
#settings-left li {
	padding: .21em 0 .23em;
}
#settings-left li.settings-with-divider {
	padding: .39em 0 .38em;
}
.settings-sub ul:not(.down-list) {
	padding-top: 1px;
}
.settings-sub ul:not(.down-list) li {
	padding: 5px 0 5px;
	line-height: 1.1em;
	margin-left: 10px;
}
.settings-selected {
	font-weight: 600;
}
.settings-action {
	position: relative;
	float: right;
	z-index: 100;
	margin-top: 6px;
}

.BftPlanSettings__buttonWrapper {
	display: flex;
	justify-content: space-between;
}

.settings-poweredit {
	z-index: 0;
}
.with-divider, #settings-left .settings-with-divider {
	border-bottom: 1px solid #AFAFAF;
}
#settings-left .settings-with-divider:last-of-type {
	padding-top: 0.19em;
}
+jade() {
	#settings-main-content h4 {
		padding-top: 6px;
	}
}
#settings-main-content .col1 h4, #settings-main-content #col2 h4 {
	padding-top: 0;
}
#settings-main-content h3 + h4 {
	padding-top: 1px;
}
.settings-second-nav a {
	text-decoration: none;
}
.settings-second-nav a:not(.settings-selected):hover {
	text-decoration: none;
}
.settings-second-nav.with-divider {
	padding-bottom: 5px;
	margin-bottom: 5px;
}
#settings-left .settings-with-divider.account-settings-list {
	padding-bottom: .39em;
}
#settings-left .settings-with-divider.employee-settings-list {
	padding-bottom: .36em;
}
#settings-left .settings-with-divider.timetracking-settings-list {
	padding-top: .36em;
	padding-bottom: .36em;
}
.settingsTimeTrackingLink {
	box-sizing: border-box;
	height: 27px;
}
.settings-top-nav {
	opacity: 0;
	margin-top: 6px;
	-webkit-transition: opacity .3s ease;
	-moz-transition: opacity .3s ease;
	-ms-transition: opacity .3s ease;
	-o-transition: opacity .3s ease;
	transition: opacity .3s ease;
}

#settings-page div.addLinks {
	float: none;
	margin-bottom: 10px;
	max-width: 870px;
	padding-top: 6px;
}
#settings-page h3 + div.addLinks {
	padding-top: 0;
	margin-top: -1px;
}

#showLayout {
	padding: 10px;
	border: 10px solid rgba(135, 196, 62, .9);
	position: fixed;
	right: 0;
	z-index: 2001;
	box-shadow: 5px 5px 5px rgba(0, 0, 0, .5);
	border-radius: 3px;
	font-family: 'comic sans ms';
	cursor: pointer;
	color: #424242;
	text-shadow: 0 0 1px rgba(255, 255, 255, 0.8);
}
#showLayout.show:before {
	content: 'Hide';
}
#showLayout:before {
	content: 'Show';
}
#layout {
	display: none;
	opacity: .5;
	position: absolute;
	left: 0;
	top: 0;
	z-index: 2000;
	cursor: move;
	box-shadow: 0 0 15px black;
}
#layout.ui-draggable-dragging {
	border: none !important;
	padding: 0;
}
#layout.layout50 {
	opacity: .5;
	display: block;
}
#layout.layout100 {
	opacity: 1;
	display: block;
}

/** custom approval form wizard **/
.settingsMainContentForm {
	margin-bottom: 35px;
}
.settingsMainContentForm #report-wizard {
	margin-left: 0;
}
.settingsMainContentForm span.inlineAjaxError {
	color: #b42f06;
	font-size: 13px;
	left: 5px;
	position: relative;
	top: 3px;
}
.settingsMainContentForm .js_ca_name_wrapper.js_error label {
	color: #b42f06;
}
.settingsMainContentForm li:hover .js_ca_name_wrapper.js_error label:before {
	content: url('https://staticfe.bamboohr.com/resources/images/bicons/field-error.png');
	display: inline-block;
	margin: 0 3px 0 0;
}
.settingsMainContentForm .js_ca_name_wrapper.js_error input {
	border-color: #b42f06;
}
.settingsMainContentForm #available .requireToggleSpan, .settingsMainContentForm #selected li.tableField:hover a.config {
	display: none;
}
.settingsMainContentForm #selected .requireToggleSpan {
	float: right;
	padding: 0;
	position: absolute;
	top: 1px;
	right: 25px;
}
.settingsMainContentForm #selected .requireToggleSpan__star {
	background-image: url('https://staticfe.bamboohr.com/resources/images/color-branded-icons/star.png');
	height: 11px;
	position: relative;
	right: 20px;
	top: 10px;
	width: 11px;
}
.settingsMainContentForm #selected .ui-sortable-helper .requireToggleSpan__star {
	background-image: url('https://staticfe.bamboohr.com/resources/images/color-branded-icons/star_drag.png');
}
.settingsMainContentForm__template {
	padding: 15px 0;
}
.settingsMainContentForm__templateList {
	margin: 0;
	padding: 0;
}
.settingsMainContentForm__templateListitem {
	padding: 10px 20px;
	margin: 0 0 4px;
	border: 1px solid #dddddd;
	max-width: 300px;
	box-sizing: border-box;
}
.settingsMainContentForm__templateListitem--link {
	border-top: none;
	border-left: none;
	border-right: none;
	padding: 10px 10px 14px 0;
}
.accountSubPage .note {
	color: #777;
	font-size: 13px;
	margin: 21px 0 25px;
	line-height: 1.2em;
}
.accountSubPage hr {
	border-color: #d4d4d4;
	margin: 20px 0 18px;
}
#settings-main-content .accountSubPage h4 {
	padding-top: 0;
}
.accountSubPage p {
	line-height: 1.3em;
}
.accountSubPage hr + .btn {
	margin-top: 7px;
}

/****************** account info page ***********************/

.AccountInfoSection {
	border-top: 1px solid #D4D4D4;
	padding-top: 15px !important;
	padding-bottom: 30px;
}

.AccountInfoSection:first-of-type {
	border: none;
	margin-top: 0;
	padding-top: 0 !important;
	padding-bottom: 20px;
}

.AccountInfoSection dd {
	padding-bottom: 8px;
}

.AccountInfoSection dd.paddingBottom {
	padding-bottom: 10px;
}

.AccountInfoSection address {
	font-style: normal;
}

.AccountInfoSection__header {
	font-size: 16px;
	font-weight: 400;
	padding-bottom: 8px;
}

.AccountInfoSection--smallFont {
	font-size: 14px;
}

.AccountInfoSection .account-info dd {
	font-size: 16px;
}

#settings-page dl:not(.basics) dt {
	color: #777;
}

.AccountInfoSection--parens {
	color: #999;
}

.AccountInfo__blank {
	color: #999999;
	padding-top: 15px;
	font-size: 14px;
}

.AccountInfo__column--spacer {
	height: 73px;
}

.ProgressBar__parent {
	margin-top: 5px;
	margin-bottom: 3px;
	width: 150px;
	height: 13px;
	border-radius: 25px;
	border-color: #B8B8B8;
	overflow: hidden;
	opacity: 0.8;
}

.ProgressBar__child {
	border-top-left-radius: 25px;
	border-bottom-left-radius: 25px;
}

#settings-page dl:not(.basics) dt.slightUp {
	margin-top: 9px;
}

#settings-page dl:not(.basics) dt.moreUp {
	margin-top: 2px;
}

.AccountOwner__photo {
	height: 32px;
	border-radius: 50%;
	float: left;
	margin-right: 5px;
	margin-top: 2px;
}

.AccountOwner__displayName {
	padding-right: 5px;
}

.Employees__svg {
	display: block;
	float: left;
	height: 15px;
	width: 18px;
	padding-top: 3px;
}

.account-info .AccountInfo--spacer {
	padding-top: 5px;
}

.account-info .BillingInfo {
	border-top: 1px solid #D4D4D4;
	padding-top: 15px !important;
	padding-bottom: 30px;
	margin-top: 35px;
}

.account-info .BillingInfo__name {
	font-size: 16px;
	padding-bottom: 2px;
}

.account-info .BillingInfo__none {
	color: #999999;
	font-size: 16px;
	padding-bottom: 2px;
}
.AccountInfoSection__addOns--tall {
	min-height: 67px;
}
.AccountInfoSection__addOnsDetail {
	margin-bottom: 3px;
}
.AccountInfoSection__addOnsIcons {
	position: relative;
	top: 1px;
	margin-right: 6px;
}
.AccountInfoSection__addOnsIcons--time {
	margin-right: 9px;
}
.account-info .CurrentPackage--tall {
	min-height: 67px;
}

.Trial {
	border: 1px solid #CDCDCD;
	border-radius: 10px;
	padding: 20px;
	width: 50%;
	text-align: center;
	margin-top: 15px;
}

.Trial__text {
	font-size: 20px;
	font-weight: 600;
	padding: 15px 0 15px 0;
}

.Trial__call {
	color: #555555;
	font-size: 18px;
	margin-top: 4px;
    margin-bottom: 20px;
	font-weight: 600;
}

.account-info a {
	font-size: 14px;
}

.CompanyInformation .Address--block {
	display: block;
}

.CompanyInformation {
	font-size: 25px;
	font-weight: 400;
	margin: 0 auto;
	text-align: left;
	max-width: 1140px;
	width: 95%;
	padding: 0;
	position: relative;
	padding-bottom: 50px;
}

.CompanyInformation--accent {
	color: #999999;
}

/*#current-usage {
	border-top: 1px solid #D4D4D4;
	padding-top: 15px;
	padding-bottom: 30px;
	margin-top: 35px;
}*/
#gauge-dial {
	width: 100%;
}
#gauge2 {
	padding: 0 2%;
}
.gauge {
	display: inline-block;
	width: 32%;
	text-align: center;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}
.gauge div {
	font-family: "BhrHeaderFont";
	font-size: 16px;
	text-align: center;
	margin-bottom: 18px;
	font-weight: 600;
}
.col1 {
	width: 40%;
}
.col2 {
	width: 60%;
}
.colSplit {
	width: 50%;
}
.col1, .col2, .colSplit {
	float: left;
	vertical-align: top;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}
#settings-page .account-info dt {
	font-size: 14px;
	font-weight: 400;
	color: #777;
}
.account-info dd {
	font-size: 15px;
	color: #222;
	line-height: 1.2em;
}
span.linkSpan {
	font-size: 13px;
}
#company-expiration {
	text-align: center;
}

.has, .daysLeft {
	font-family: "BhrHeaderFont";
	font-size: 18px;
	font-weight: bold;
}
.has {
	margin-bottom: 8px;
}
.daysLeft {
	margin-top: 4px;
	margin-bottom: 20px;
}
.countdown {
	background: url('https://staticfe.bamboohr.com/resources/images/settings/countdown-2-digits.png') center top no-repeat;
	width: 93px;
	height: 71px;
	margin: auto;
	font-family: 'Source sans pro';
	font-weight: 700;
	font-size: 42px;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	padding-top: 20px;
	padding-left: 13px;
	letter-spacing: 23px;
}
.countdown.singleDigit {
	background: url('https://staticfe.bamboohr.com/resources/images/settings/countdown-1-digit.png') top center no-repeat;
	width: 48px;
}
.or {
	font-size: 13px;
	color: #777;
	margin: 6px 0 5px 0;
}
.chat {
	font-size: 13px;
	display: inline-block;
	font-weight: 600;
}

/********************* end *************************/
/************ onboarding/offboarding draggable ***********/
.ts-list .item.ui-sortable-helper td {
	border-top: solid #d9d9d9 1px;
}
.ts-list .ui-sortable-placeholder {
	background-color: #fcfcfc !important;
	border: dashed #d9d9d9 1px !important;
	border-top: none !important;
}

/** settings icons - moved from buttons.css (KB) **/
.settings-sprites {
	background-image: url("https://staticfe.bamboohr.com/resources/images/color-branded-icons/settings-sprites.png");
	content: "";
	display: inline-block;
	height: 14px;
	width: 20px;
	vertical-align: middle;
	margin-right: 5px;
	background-color: #a8a8a8;
}
.settings-sprites.gears {
	background-position: left top;
	height: 24px;
	width: 24px;
	margin-top: 2px;
	padding-top: -4px;
	margin-right: 5px;
	margin-left: 2px;
	padding-left: -2px;
	vertical-align: baseline;
	border: 0;
}
.settings-sprites.wrench {
	background-position: left -30px;
	height: 14px;
	top: -3px;
	left: 1px;
}
.settings-sprites.wrench.white {
	background-position: left -60px;
}
.settings-sprites.perms {
	background-position: left -90px;
	height: 16px;
	top: -2px;
	left: 2px;
}
.settings-sprites.perms.white {
	background-position: left -120px;
}
.settings-sprites.fields {
	background-position: left -150px;
	height: 16px;
	top: -3px;
}
.settings-sprites.fields.white {
	background-position: left -180px;
}
.settings-sprites.thumbs {
	background-position: left -210px;
	height: 16px;
	top: -3px;
	left: 1px;
}
.settings-sprites.thumbs.white {
	background-position: left -240px;
}
.settings-sprites.heart {
	background-position: left -270px;
	height: 15px;
	top: -3px;
}
.settings-sprites.heart.white {
	background-position: left -300px;
}
.settings-sprites.profile {
	background-position: left -330px;
	height: 15px;
	top: -3px;
	left: 1px;
}
.settings-sprites.profile.white {
	background-position: left -360px;
}
.settings-sprites.alert {
	background-position: left -390px;
	height: 15px;
	top: -4px;
}
.settings-sprites.alert.white {
	background-position: left -420px;
}
.settings-sprites.gift {
	background-position: left -450px;
	height: 15px;
	top: -3px;
	left: 1px;
}
.settings-sprites.gift.white {
	background-position: left -480px;
}
.settings-sprites.logo {
	background-position: left -510px;
	height: 15px;
	top: -3px;
}
.settings-sprites.logo.white {
	background-position: left -540px;
}
.settings-sprites.onboard {
	background-position: left -570px;
	height: 15px;
	top: -3px;
}
.settings-sprites.onboard.white {
	background-position: left -600px;
}
.settings-sprites.offboard {
	background-position: left -630px;
	height: 17px;
	top: -3px;
}
.settings-sprites.offboard.white {
	background-position: left -660px;
}
.settings-sprites.timeoff {
	background-position: left -690px;
	height: 15px;
	top: -2px;
}
.settings-sprites.timeoff.white {
	background-position: left -720px;
}
.settings-sprites.training {
	background-position: left -750px;
	height: 15px;
	top: -3px;
	left: -1px;
}
.settings-sprites.training.white {
	background-position: left -780px;
}
.settings-sprites.applicant_statuses {
	background-position: left -810px;
	height: 19px;
	top: -2px;
	left: 2px;
}
.settings-sprites.applicant_statuses.white {
	background-position: left -840px;
}
h3 .settings-sprites.active {
	top: 1px;
	vertical-align: baseline;
}
h3 .settings-sprites.active.perms {
	top: 0;
}

.settings-sprites.performance_dial {
	background-position: left -870px;
	height: 16px;
	top: -2px;
	left: -1px;
}
.settings-sprites.performance_dial.white { background-position:left -900px; }

h3 .settings-sprites.active { top: 1px; vertical-align: baseline; }
h3 .settings-sprites.active.perms { top: 0; }

.timeTrackingSettingsIcon {
	margin: -2px 5px 2px 0;
	vertical-align: top;
	fill: #777;
	color: #a8a8a8;
}

.settings-selected .timeTrackingSettingsIcon {
	color: #777;
}

.timeTrackingSubNavIcon {
	color: #777;
	margin: 0 9px 0 -22px;
	position: relative;
	top: 3px;
}

.fieldBox.valignTop {
	vertical-align: top;
	margin-top: 9px;
}

} // end @css block

#settings-page {
	flex: 1;
}

.settingsMainContentForm__templateList {
	display: flex;
	flex-direction: column;
	gap: 12px;
}

.settingsMainContentForm__templateListitem {
	padding: 8px 16px;
	border-radius: 8px;
}

.settingsMainContentForm__templateListitem--link {
	border: none;
	margin-top: 8px;
	margin-bottom: 20px;
}

#settings-page {
	display: flex;
	padding-bottom: spacing(5);
}

#settings-page #settings-main-content .blankState h3 {
	padding-top: 0;
}

.settings-top-nav {
	margin-top: 0;
}

.settings-second-nav {
	display: flex;
	flex-direction: column;
	gap: spacing(0.5);
}

.settings-sub {
	border: none;
}

.settings-second-nav {
	display: flex;
	flex-direction: column;
	gap: spacing(0.5);
}

.settings-second-nav.with-divider {
	padding-bottom: spacing(2);
	margin-bottom: 0;
}

.section-inner-content {
	width: 100%;
	display: flex;
}

.section-header {
	display: none;

	&__divider {
		margin-top: spacing(2);
	}
}

.settings-main-wrapper {
	flex-grow: 2;
	min-width: 0;
}
.settings-section {
	min-width: 0;
	padding-top: 20px;
}

#settings-page {
	padding-bottom: unset;
}

.SettingsNav {
	/* .SettingsNav__item */
	&__item {
		/* .SettingsNav__itemLink */
		&Link {
			box-sizing: border-box;
			min-height: 27px;
		}
		/* .SettingsNav__itemIcon */
		&Icon {
			display: inline;
			/* .SettingsNav__itemIcon--enps */
			&--enps {
				margin-right: 6px;
			}
		}
		/* .SettingsNav__itemText */
		&Text {
			vertical-align: top;
			/* .SettingsNav__itemText--payroll */
			&--payroll {
				margin-left: -2px;
			}
		}
	}
}

.PayrollSubNav {
	/* .PayrollSubNav__icon */
	&__icon {
		color: #777;
		margin: 0 9px 0 -22px;
		position: relative;
		top: 3px;
	}
}

.SettingsApps__navIcon {
    fill: #a8;
    margin-left: 0;
    margin-right: 7px;
}


.ApprovalsSettingsNav {
	&__icon {
		fill: $svgColorPalette.gray-3;
		margin: -2px 5px 2px 0;
		vertical-align: top;
	}

	&:not(.settings-selected) {
		.ApprovalsSettingsNav__icon {
			color: $svgColorPalette.gray-2;
		}
	}
}

.accountSubPage .note {
	fab-color: gray7;
	fab-text: teenie;
}
#deleteForm {
	.Modal__separator {
		margin: 19px 0 18px;
	}
}

#settings-main-content
{
	float: left;
	padding-top: 0;
	position: relative;
	z-index: 2;

	padding-left: 0;
	width: 100%;
	background-color: white;
	padding-bottom: 0px;
}

#settings-left {
	padding: 8px 24px;

	padding: 0;
}

#settings-left,
#settings-page:after {
	width: 262px;
	width: 264px;
	min-width: 264px;
	margin-right: spacing(5);
}

#settings-left li {
	padding: 0;

	// #settings-left .optionTextWrapper
	& .optionTextWrapper {
		display: flex;

		// #settings-left .optionTextWrapper .optionText
		& .optionText {
			flex-direction: column;
			justify-content: center;
		}
	}
}

.settings-sub li a {
	padding-left: 0;
}

.settings-sub ul:not(.down-list) li {
	fab-line-height: small;
	padding: 0;

	margin-left: 0;

	& a {
		display: block;
		padding: 8px 14px;

		padding: spacing(1) spacing(1.5);
	}

}

#settings-left .settings-selected,
.settings-sub .settings-selected a {
	fab-font-weight: heavy;
	color: fab-color(theme);

	// #settings-left .settings-selected SettingsNav__itemIcon, .settings-sub .settings-selected SettingsNav__itemIcon
	& .SettingsNav__itemIcon {
		fill: fab-color(theme);
	}
}

#settings-page:after {
	border: none;
}

#settings-page div.addLinks {
	max-width: inherit;
	margin: 12px 0px 16px 0px;
	&__addEmailAlert {
		margin: 0 0 0 auto;
		padding-top: 0;
	}
	&__payrollAddLinks {
		margin: 24px 0;
		padding-top: 0;
	}
}

#settings-page .TT_SetupBoxEncore {
	display: flex;
}

.settings-sub .settings-selected,
.settings-sub li:hover {
	background-color: fab-color(gray1);
	border-radius: 4px;

	border-radius: 8px;
}

.settings-sub li:hover a {
	color: fab-color(theme);
}

.settingsTimeTrackingLink {
	height: auto;
}

.settings-sub {
	border-right-color: fab-color(theme-lightest);
	padding: 6px 20px 45px;
	width: 225px;

	width: 200px;
	min-width: 200px;
	padding: 0;
	max-width: 223px;
	margin-right: spacing(4);
}

.SettingsNav {

	//.SettingsNavPageTitle
	&PageTitle {
		margin-bottom: 20px;
		//.SettingsNavPageTitle__icon
		&__icon {
			margin-right: 5px;
			position: relative;
			top: 2px;
		}
	}
	/* .SettingsNav__item */
	&__item {
		/* .SettingsNav__itemIcon */
		&Icon {

			// .SettingsNav__itemIconContainer
			&Container {
				display: inline-flex;
				width: 20px;
				height: 22px;
				flex-direction: column;
				justify-content: center;
			}
		}

		/* .SettingsNav__itemText */
		&Text {
			display: inline-flex;
			flex-direction: column;
			justify-content: center;
			/* .SettingsNav__itemText--payroll */
			&--payroll {
				margin-left: 0;
			}
		}
	}

	&__hiddenIcon {
		display: none;
	}

}

.SettingsSubNav {
	&Title {
		margin-left: 17px;
		display: flex;
		align-items: center;

		&Icon {
			margin-right: 8px;
		}

		&Right {
			flex-grow: 1;
			text-align: right;
		}
	}
	&__groupLabel {
		fab-color(gray7);
		fab-font-size: teenie;
		fab-line-height: teenie;
		margin: 12px 0 1px 17px;

		margin: spacing(2) 0 spacing(0.5) 0;
		fab-color(gray6);
	}

	&__link {
		padding: spacing(1) spacing(1.5);
		fab-color(gray8);
		fab-font-size: small;
		fab-font-weight: regular;

		&--active {
			color: fab-color(theme);
			background-color: fab-color(gray1);
			border-radius: 8px;
			fab-font-weight: bold;
		}
	}
}

.settingsMainContentForm #selected {

	.subTable {
		.requireToggleSpan .bhrToggle--require .bhrToggle__label:after,
		.requireToggleSpan .bhrToggleSmall--require .bhrToggle__label:after {
			left:5px;
		}

		.requireToggleSpan .bhrToggle--require input:checked + .bhrToggle__label:after,
		.requireToggleSpan .bhrToggleSmall--require input:checked + .bhrToggle__label:after {
			left: -8px;
		}
	}

	.requireToggleSpan {
		float: right;
		padding: 0;
		position: absolute;
		top: 4px;
		right: 63px;

		.bhrToggle--require .bhrToggle__label:after,
		.bhrToggleSmall--require .bhrToggle__label:after {
			left: -5px;
		}

		.bhrToggle--require input:checked + .bhrToggle__label:after,
		.bhrToggleSmall--require input:checked + .bhrToggle__label:after {
			left: -19px;
		}
	}


}

// .EditRow
.EditRow {
	background-color: fab-color(gray1);

	// .EditRow__note
	&__note {
		color: fab-color(gray6);
		fab-font-size: small;
		fab-line-height: teenie;
		margin-left: 16px;
		position: relative;
		bottom: 2px;
	}

	// .EditRow__actions
	&__actions {

		// .EditRow__actions--close
		&--close {
			position: relative;
			left: -4px;
		}
	}
}

// .settings-main-heading
.settings-main-heading {
	align-items: center;
	display: flex;

	// .settings-main-heading ba-icon
	ba-icon {
		fab-fill: theme;
		margin-right: 10px;
	}

	// .settings-main-heading--extraMarginBottom
	&--extraMarginBottom {
		margin-bottom: 24px;
	}

	&--smallExtraMarginBottom {
		margin-bottom: 19px;
	}
}

// Account overrides
.AccountInfoSection {
	position: relative;
	&:first-of-type {
		padding-top: 9px !important;
	}
}

.Trial__text {
	fab-color: theme;
}

.settings-second-nav.with-divider {
	border-color: fab-color(gray3);

	border-color: fab-color(gray2);
}

#settings-page {
	.settings-sub {
		ul {
			&.settings-second-nav {
				li {
					a {
						span {
							fab-color: gray7;
						}
					}
				}
			}
		}
	}
}

.CustomFields {
	&Icon {
		fill: fab-color(theme);
		margin-right: 8px;
	}
}
