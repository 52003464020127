.SettingsAccountTimeTracking {
	&__tableHeader {
		.filter {
			label {
				display: inline-block;
				margin-top: 4px;
			}

			select {
				display: none !important;
			}
		}

		.chzn-container {
			&,
			.chzn-drop {
				width: 100px;
				min-width: @width;
				max-width: @width;
				box-sizing: border-box;
			}
		}
	}

	&__empty {
		&Wrapper {
			text-align: center;
		}

		&Icon {
			margin: 87px auto 5px;
		}

		&Header {
			font-size: 21px;
			color: #9;
			margin: 8px auto !important;
		}

		&Message {
			margin: 0 auto;
			color: #9;
		}
	}

	&__filterByYear {
		margin-left: 8px;
	}

	&ResultsModal {
		&__info {
			display: flex;
			margin-bottom: 16px;

			&Details {
				flex: 1;
			}
		}
	}
}
