.EmployeeWellbeingGetStarted {
	text-align: center;

	&__container {
		margin-left: auto;
		margin-right: auto;
		max-width: 618px;
		padding-top: 40px;
		overflow: hidden;

		+encore() {
			max-width: 700px;
		}
	}

	&__video {
		+jadeFabricTheme() {
			height: 430px;
			position: relative;
			top: -120px;
			margin-bottom: -120px;
		}
	}

	/* @startCleanup encore */
	&__summary {
		fab-color: gray7;
		font-size: 18px;
		line-height: 26px;
		margin: 40px 0 24px;
	}

	&__previewButtons {
		display: flex;
		justify-content: center;
		align-items: center;

		div {
			padding: 4px 16px;
			fab-color: info;

			a {
				fab-fill: info;
			}

			&:first-child {
				border-right: 1px solid fab-color(gray4);
			}
		}

		span {
			margin-left: 8px;
			font-size: 15px;
			line-height: 22px;
		}
	}
	/* @endCleanup encore */
}
