.EnpsRadioButton {

	&__number {
		align-items: center;
		background-color: white;
		border-radius: 16px;
		border: 1px solid fab-color(gray3);
		cursor: pointer;
		display: flex;
		fab-color: gray8;
		fab-font-size: small;
		fab-font-weight: semibold;
		height: 30px;
		justify-content: center;
		line-height: 17px;
		user-select: none;
		width: 30px;
		z-index: 2;

		+encore() {
			border: 1px solid var(--gray4);
			border-radius: 50%;
			height: 48px;
			width: 48px;
		}

		&:hover {
			border-color: fab-color(theme-base);
			fab-color: theme-base;
		}

		&--selected {
			border-color: fab-color(theme-base);
			fab-background-color: theme-base;
			fab-color: white;
			font-weight: bold;

			&:hover {
				border-color: fab-color(theme-base);
				fab-background-color: theme-base;
				fab-color: white;
			}
		}

	}
}
