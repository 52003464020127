// .Approvals__Select--readonly
.Approvals__Select--readonly {
	// .Approvals__Select--readonly .ba-Select__caret
	// .Approvals__Select--readonly .ba-Select__clearButton
	.ba-Select__caret,
	.ba-Select__clearButton {
		display: none;
	}
}

// .Approvals__list
.Approvals__list {
	padding-top: 0;
	
	&-levels {
		margin-left: 20px;	
	}
}

// .ApprovalsForm__footerBreak
.ApprovalsForm__footerBreak {
	margin-bottom: 20px;
}
