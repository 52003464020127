/* .AdvancedApprovalsConfirmationModal */
.AdvancedApprovalsConfirmationModal {
	padding-left: 40px;
	padding-right: 40px;

 display: flex;
 justify-content: center;

	/* .AdvancedApprovalsConfirmationModal, .AdvancedApprovalsConfirmationModal p */
	&,
	p {
		text-align: center;
	}

	/* AdvancedApprovalsConfirmationModal__footer-cancel-button */
	&__footer-cancel-button {
		color: $colors.link;
		background: none;
		border: none;
		font-family: inherit;
		font-size: inherit;
	}

	/* AdvancedApprovalsConfirmationModal__footer-cancel-button:hover */
	&__footer-cancel-button:hover {
		text-decoration: underline;
	}

	/* .AdvancedApprovalsConfirmationModal__text-container */
	&__text-container {
		color: fab-color(gray10);
		fab-font-size: biggie;
		max-width: 460px;

		/* .AdvancedApprovalsConfirmationModal__text-container--sub-text */
		&--sub-text {
			fab-font-size: medium;
			margin: 6px 0 0 15px;
			max-width: 395px;
		}
	}

	/* .AdvancedApprovalsConfirmationModal__warning-icon-container */
	&__warning-icon-container {
		fill: $bhrColors.warning1;
		margin: 24px auto 22px;
	}
}
