.AlertsList {
	&__buttonLabel {
		margin-left: 6px;
	}

	&__navigation {
		margin-bottom: 15px;
	}
}

.AlertsEdit {
	&__includesLink {
		vertical-align: baseline !important;
	}
}

.SettingsAlerts {
	&__heading {
		&Icon {
			margin-right: 8px;
			transform: translateY(2px);
		}
	}

	&__filterIcon {
		transform: translateY(2px);
		fab-fill: gray8;
	}
	&__FormSection {
		&:not(:last-of-type) {
			border-bottom :1px solid var(--gray2);
			margin-bottom: 20px;
			padding-bottom: 20px;
		}
	}
	&__TextInput {
		box-sizing: border-box;
	}
	&__filterRow {
		display: flex;
		align-items: start;
		font-size: var(--font-size-biggie);
	}
	&__Row {
		& td {
			vertical-align: middle;
		}
		& td.edit {
			vertical-align: top;
		}
		&__UpdatedAt {
			color: var(--gray7);
			fab-font-size(teenie);
		}
	}
}
