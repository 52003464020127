.NewHirePacketFiles {
	.FileCard {
		background: none;
		padding: 0;
	}
}

.FileSectionWrapper {
	margin-top: 12px;
	margin-left: 5px;
}
