.SurveyAnswerScale {
	display: flex;
	flex-basis: 100%;

	&__box {
		align-items: center;
		background-color: #f;
		border: 1px solid fab-color(gray4);
		border-radius: 2px;
		box-sizing: border-box;
		fab-color: gray10;
		cursor: pointer;
		display: flex;
		flex-basis: 0;
		flex-grow: 1;
		flex-direction: column;
		fab-font-size: small;
		fab-line-height: small;
		margin: 0 5px;
		padding: 20px;
		text-align: center;
		user-select: none;

		&:focus, &:hover {
			border-color: fab-color(theme-base);

			+encore() {
				border-color: var(--fabric-theme-base);
				color: var(--fabric-theme-base);
				background-color: var(--fabric-theme-lightest35);
			}

			& .SurveyAnswerScale__checkIcon > svg {
				+encore() {
					fill: var(--fabric-theme-base);
				}
			}
		}

		&:first-child {
			margin-left: 0;
		}

		&:last-child {
			margin-right: 0;
		}

		&--selected {
			border-color: fab-color(theme-lighter);
			box-shadow: 0 0 0 2px fab-color(theme-lightest35);
			fab-color: theme-base;
			font-weight: 600;
		}
	}

	&__checkIcon {
		fab-fill: gray3;
		margin-bottom: 10px;

		&--selected {
			fab-fill: theme-base;
		}

		&--small {
			display: none;
		}
	}

	&__text {
		display: block; // Required to wrap text in IE 11
		max-width: 100%; // Required to wrap text in IE 11
	}
}

@media only screen and (max-width: 800px) {
	.SurveyAnswerScale {
		flex-wrap: wrap;
		width: 100%;

		&--hasSelected {
			.SurveyAnswerScale__box:not(.SurveyAnswerScale__box--selected):not(:hover) {
				+jadeFabricTheme() {
					border-color: fab-color(gray3);
					fab-color: gray3;
				}
			}
		}

		&__box {
			border-radius: 3px;
			border-color: fab-color(gray5);
			flex-grow: 1;
			flex-direction: row;
			flex-basis: 100%;
			font-size: 20px;
			line-height: 25px;
			margin: 5px 0;
			padding: 15px;
			text-align: left;
			width: 100%;

			+encore() {
				border: 1px solid var(--gray6);
				border-radius: 4px;
				color: var(--gray6);
			}

			&--large-text {
				font-size: 20px;
			}

			&:first-child {
				margin-left: 0;
			}

			&:last-child {
				margin-right: 0;
			}

			&--selected {
				border-color: fab-color(theme-lighter);
				box-shadow: 0 0 0 2px fab-color(theme-lightest35);
				font-weight: 600;
			}
		}

		&__checkIcon {
			display: flex;
			flex-shrink: 0;
			margin: 0 15px 0 0;

			&--centered {
				flex: 1 1 auto;
				margin: 0;
			}

			&--large {
				display: none;
			}
		}

		&__text {

			&--centered {
				position: relative;
				right: 15px;
				flex: 1 1 auto;
			}

			&--selected {
				fab-color: theme-base;
			}
		}
	}
}
