.TTChecklist {
	display: flex;
	flex-direction: column;
	align-items: flex-start;

	&__header {
		display: flex;
		flex-direction: row;
		align-items: center;
		border-bottom: 1px solid fab-color(gray4);
		padding-bottom: 23px;
		margin: 22px 0;
		width: 100%;

		&__icon {
			display: flex;
			padding: 0;
			fab-fill: theme-base;
			margin-right: 16px;
		}

		&__text {
			display: flex;
			flex-direction: column;

			&--title {
				font-size: 22px !important; // TODO JADE: remove importants after global #settings h3 styles are fixed
				line-height: 30px !important;
				margin: 0 0 3px !important;
				padding: 0 !important;
			}
		}
	}

	&__list {
		margin-bottom: 12px;
	}

	&__popover {

		&--enable {
			margin-right: 10px;
		}
	}

	&__buttons {
		margin-top: 10px;
		padding-top: 24px;
		border-top: 1px solid fab-color(gray4);
		width: 100%;
		display: flex;
		gap: 24px;
	}
}
