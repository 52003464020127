/*------------------------------------*\
$FilesWrapper MODULE

BEM styles used for File uploading wrapper
\*------------------------------------*/

.FilesWrapper { margin-bottom: 13px; }

.FilesWrapper__links {
	margin-left: 5px;
	font-size: 13px;
	line-height: 1em;
}

.FilesWrapper__title,
label.FilesWrapper__title {
	margin-bottom: 4px;
	line-height: 1em;
}

/* NOTE: These files are only used in performance and should be deprecated */
.FilesWrapper__files {
	margin-top: 13px;
}

.FilesWrapper__files:empty {
	display: none;
}

.FilesWrapper__file {
	margin-bottom: 3px;
	line-height: 1;
}
.FilesWrapper__file--template { display: none; }

.FilesWrapper__file-icon,
.FilesWrapper__file-name,
.FilesWrapper__file-action {
	display: inline-block;
	vertical-align: middle;
}

.FilesWrapper__file-icon { margin-right: 4px; }

.FilesWrapper__file-name {
	margin-right: 3px;
	line-height: 16px;
}

.FilesWrapper__BlankState {
	border-top: none;
	position: absolute;
	top: 38px;
	width: 100%;
}

.FileListModal .FilesWrapper__BlankState {
	position: static;
}

.FilesWrapper__BlankState .blankState__image {
	margin-bottom: 12px;
}

.FilesWrapper__BlankState .blankState__subText--large {
	margin: 0 75px;
}
