.Columns {

	// .Columns__endDate
	&__endDate {

		// .Columns__endDate--attention
		&--attention {
			fab-color: attention;
		}

		// .Columns__endDate--inactive
		&--inactive {
			fab-color: gray6;
		}
	}
}
