.OneTimeCodeIndicator {
	// .OneTimeCodeIndicator__wrapper
	&__wrapper {
		bottom: 0;
		height: 2px;
		left: 0;
		position: absolute;
		width: 100%;
	}

	// .OneTimeCodeIndicator__wrapper svg
	&__wrapper svg {
		display: block;
	}

	// .OneTimeCodeIndicator__markerBg
	&__markerBg {
		fill: fab-color(gray5);
		transition: fill 200ms ease-out;
	}

	// .OneTimeCodeIndicator__caret
	&__caret {
		fill: fab-color(theme-base);
		transition: all 200ms ease-out;
	}

	// Error styles
	// .OneTimeCodeIndicator__wrapper--error .OneTimeCodeIndicator__caret
	&__wrapper--error &__caret {
		fill: fab-color(danger);
	}

	// .OneTimeCodeIndicator__wrapper--error .OneTimeCodeIndicator__caret
	&__wrapper--error &__markerBg  {
		fill: fab-color(danger);
	}
}
