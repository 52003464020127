.DeductionMigrationConfigure {

	&__heading {
		text-align: center;
	}

	&__instruction {
		border-top: 1px solid fab-color(gray3);
		fab-color: gray10;
		fab-font-size: large;
		fab-font-weight: semibold;
		fab-line-height: medium;
		margin-bottom: 12px;
		padding-top: 20px;
	}

	&__subHeading {
		fab-color: gray10;
		fab-font-size: medium;
		margin-bottom: 26px;

		&Link {
			white-space: nowrap;
		}

		&Warning {
			fab-color: attention;
		}
	}
}
