.STE_ {

	&settings-subnav-count {
		display: inline-block;
		vertical-align: baseline;
	}

	&EmpTable {

		& .BhrTable__row,
		& .BhrTable__cell,
		& .BhrTable__row:hover,
		& .BhrTable__cell:hover {
			cursor: default !important;
		}

		&__actions {
			width: 37px;
		}

		&__logged {
			width: 192px !important;
		}

		&__actionIcon {
			cursor: pointer;
			fill: #898a8a;
			margin-top: 3px;
			opacity: 0;
			transition: opacity .2s ease-in-out;

			&:first-of-type:not(:last-of-type) {
				margin-right: 5px;
			}
		}

		&__row:hover &__actionIcon {
			opacity: 1;
		}

		&__warning-icon {
			fill: #f6a400;
			padding-right: 8px;
		}

		&__data--gray {
			color: $bhrColors.gray7;
		}

		&__startDate {
			color: $bhrColors.gray7;
			font-size: 13px;
		}
	}
}

.STE_ {
	&EmpTable {
		&__startDate {
			margin-top: -4px;
		}

		&__warning {
			display: inline-flex;
			align-items: center;

			&__icon {
				display: flex;
				fab-fill: attention;
				margin-right: 8px;
			}
		}
	}

	&ScheduleModal {
		&__issueIcon {
			fill: fab-color(gray5);
			margin-right: 4px;
			position: relative;
			top: 1px;
		}
	}
}
