.MappingTable {

	// .MappingTable__icon
	&__icon {
		fab-fill: gray2;

		// .MappingTable__icon--selected
		&--selected {
			fab-fill: gray6;
		}
	}
}
