.EnableModal {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	padding-bottom: 0;

	&__clock-success-icon {
		fab-fill: theme-base;
		margin-top: 0;
		margin-bottom: 15px;
	}

	&__clock-edit-icon {
		fab-fill: theme-base;
		margin-top: 0;
		margin-bottom: 15px;
	}

	&__text {
		margin: 0;
	}

	&__sub-text {
		max-width: unset;
		fab-color: gray7;
		text-align: center !important;
		margin: 4px 0 0;
	}

	&__header {
		font-size: 18px;
		font-weight: 500;
		margin: 0 0 4px;

		&--secondary {
			fab-color: gray9;
			margin-bottom: 0;
		}
	}

	&Table {
		width: 100%;
		fab-background-color: gray1;
		padding: 16px;
		margin-top: 20px;

		&__row {
			display: flex;
		}

		&__column1 {
			box-sizing border-box;
			padding-right: 4px;
			width: 75%;
			flex: 1;
		}
		&__column2 {
			width: 160px;
		}

		&__columnHeader {
			font-size: 14px;
			border-bottom: 1px solid fab-color(gray4);
			fab-color: gray8;
			margin-bottom: 16px;
			padding-bottom: 9px;
		}

		&__boldSchedule {
			font-size 15px;
			font-weight: 600;
			height: 28px;
			margin-bottom: 12px;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			padding-top: 5px;
		}

		&__dropdown {
			margin-bottom: 10px;
		}
	}
}

{featureToggleOn('timeTrackingFutureScheduling')} {
	.EnableModal {
		&Table {

			&__row {
				display: flex;
				padding-top: 5px;
			}

			&__column1 {
				box-sizing border-box;
				padding-right: 4px;
				width: 75%;
				flex: 1;
			}

			&__boldSchedule {
				font-size 15px;
				font-weight: 600;
				height: 42px;
				margin-bottom: 12px;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				padding-top: 5px;
			}

			&__employeeCount {
				fab-font-size: teenie;
				fab-color: gray7;
				fab-font-weight: normal;
				line-height: 14px;
			}
		}
	}
}
