.SettingsAccountACA {

	&__headlineWrapper {
		border-bottom: 1px solid fab-color(gray3);
		padding: 0 0 32px;
		margin: 0 0 24px;
	}

	&__headline {
		font-size: 22px;
		line-height: 30px;
		fab-color: theme-base;
		fab-font-weight: semibold;
		padding: 0 0 16px;
	}

	&__emphasis {
			fab-color: theme-base;
			fab-font-weight: semibold;
	}

	&__list {
		list-style: inside;
		list-style-type: disc;
		margin: 12px 0;

		li {
			fab-color: theme-base;
		}

		li span {
			fab-color: gray8;
			margin-left: -8px;
		}
	}

	&__section {
		margin: 18px 0;
	}
}
