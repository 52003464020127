.Settings {
	&Users {
		&AddEdit {
			&__btn {
				&[data-action="deleteUser"] {
					svg {
						size: 1em;
						fill: #85;
						margin-bottom: -1px;
						margin-right: 4px;
					}
				}
			}

			&__emailMsg {
				padding-top: 10px;
				color: rgba(#64, 0.85);
				fill: #bbb;

				&Ico {
					size: 1em;
					margin-bottom: -2px;
					margin-right: 6px;
				}
			}

			&__ssoLabel {
				display: inline-block;
				margin-bottom: 7px;
			}

			&__resetPassword {
				display: inline-flex;
			}
		}
	}
}
