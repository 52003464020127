.StepCheckFeedback {
	border-bottom: 1px solid fab-color(gray3);
	fab-color: gray10;
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 52px;
	text-align: left;

	// .StepCheckFeedback__complete
	&__complete {
		fab-fill: gray10;
		display: flex;
		align-items: center;

		// .StepCheckFeedback__completeText
		&Text {
			margin-left: 10px;
		}
	}

	// .StepCheckFeedback__checking
	&__checking {
		fab-color: theme;
		display: flex;
		align-items: center;

		// .StepCheckFeedback__checkingText
		&Text {
			margin-left: -20px;
		}
	}

	// Unfortunate specificity battle :(
	.dotLoader .StepCheckFeedback__dot {
		fab-background-color: theme;
	}
}
