// .InterstitialSetup
.InterstitialSetup {
	// .InterstitialSetup__Footer
	&__Footer {
		margin-top: 70px;
	}
	// .InterstitialSetup__Instructions
	&__Instructions {
		margin-top: 0;
		fab-text: medium;
		fab-font-weight: semibold;
		color: var(--gray9);
	}
	// .InterstitialSetup__QR
	&__QR {
		align-items: center;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		width: 160px;
	}
	// .InterstitialSetup__Setup
	&__Setup {
		display: flex;
		flex-direction: row;

		margin-top: spacing(4);
	}
	// .InterstitialSetup__Step
	&__Step {
		// align-items: center;
		display: flex;
		flex-direction: row;
	}
	// .InterstitialSetup__StepNumber
	&__StepNumber {
		align-items: center;
		align-self: flex-start;
		display: flex;
		fab-color: white;
		flex-shrink: 0;
		height: 28px;
		justify-content: center;
		margin-right: 10px;
		width: 28px;
		// .InterstitialSetup__StepNumber:before
		&:before {
			border-radius: 50%;
			content: '';
			fab-background-color: gray6;
			height: 28px;
			position: absolute;
			width: 28px;
			z-index: -1;
		}
	}
	// .InterstitialSetup__StepText
	&__StepText {

		margin-top: 0;
		color: var(--gray9);
	}
	// .InterstitialSetup__Steps
	&__Steps {
		display: flex;
		flex-direction: column;
		flex-grow: 1;

		gap: spacing(2);
		justify-content: flex-start;
	}
}
