__ = BEM('BaTabs', 'fab-wizard-theme');

{__('tab')} {
	display: flex;
	flex-direction: row-reverse;
	align-items: center;
	height: 56px;
	cursor: default !important;
	fab-color: gray7;
	fab-font-size: biggie;
	// @startCleanup encore
	line-height: 1em;
	padding-left: 35px;
	padding-right: 45px;
	// @endCleanup encore

	+encore() {
		padding: spacing(2) spacing(2.5);
		border-radius: 16px;
		fab-font-weight: medium;
		fab-line-height: large;
	}
}

{__('tab', 'active')} {
	fab-color: theme;

	// @startCleanup encore
	+jadeFabricTheme() {
		&:not(:last-of-type) {
			background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMTI5cHgiIGhlaWdodD0iNDZweCIgdmVyc2lvbj0iMS4xIiB2aWV3Qm94PSIwIDAgMTI5IDQ2IiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgoJPGRlZnM+CgkJPGxpbmVhckdyYWRpZW50IGlkPSJhIiB4MT0iMjcuODk4JSIgeDI9IjkzLjczMiUiIHkxPSI0Ni4wNjYlIiB5Mj0iNDYuMDY2JSI+CgkJCTxzdG9wIHN0b3AtY29sb3I9IiNGNEY0RjQiIG9mZnNldD0iMCIvPgoJCQk8c3RvcCBzdG9wLWNvbG9yPSIjRUJFQkVCIiBvZmZzZXQ9IjEiLz4KCQk8L2xpbmVhckdyYWRpZW50PgoJPC9kZWZzPgoJPGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KCQk8cGF0aCBkPSJtMCAxLjk5NjljMC0xLjEwMjggMC45MDE1OS0xLjk5NjkgMS45OTE2LTEuOTk2OWgxMDguMDJjMS4wOTk5IDAgMi40NDg1IDAuNzcyNzYgMy4wMTA0IDEuNzIzbDEyLjk1NiAyMS45MTJjMC41NjI2NSAwLjk1MTYgMC41MzY4OSAyLjQ4MjktMC4wNDc0OTggMy40MDQzbC0xMi44NjEgMjAuMjc5Yy0wLjU4ODg4IDAuOTI4NTctMS45Njc5IDEuNjgxMy0zLjA1NzkgMS42ODEzaC0xMDguMDJjLTEuMDk5OSAwLTEuOTkxNi0wLjg5MjMyLTEuOTkxNi0xLjk5Njl2LTQ1LjAwNnoiIGZpbGw9IiNEM0QzRDMiLz4KCQk8cGF0aCBkPSJtMCAxLjk5NjljMC0xLjEwMjggMC44OTM1NC0xLjk5NjkgMS45OTc2LTEuOTk2OWgxMDcuMDFjMS4xMDMyIDAgMi40NTQ1IDAuNzcyNzYgMy4wMTY0IDEuNzIzbDEyLjk1NiAyMS45MTJjMC41NjI2NSAwLjk1MTYgMC41MzY4OSAyLjQ4MjktMC4wNDc0OTggMy40MDQzbC0xMi44NjEgMjAuMjc5Yy0wLjU4ODg4IDAuOTI4NTctMS45NTk4IDEuNjgxMy0zLjA2MzkgMS42ODEzaC0xMDcuMDFjLTEuMTAzMiAwLTEuOTk3Ni0wLjg5MjMyLTEuOTk3Ni0xLjk5Njl2LTQ1LjAwNnoiIGZpbGw9InVybCgjYSkiLz4KCTwvZz4KPC9zdmc+Cg==");
			background-position: center right;
			background-repeat: no-repeat;
			background-size: auto 100%;
		}
	}
	// @endCleanup encore
	
	+encore() {
		background-color: var(--gray1);
	}
}

{__('tabCaret')} {
	// @startCleanup encore
	+jadeFabricTheme() {
		display: flex;
		justify-content: center;
		align-items: center;
		fab-background-color: gray6;
		border-radius: 100%;
		size: 24px;
		text-align: center;
		fab-color: white;
		margin-right: 12px;
		fab-fill: white;
		fab-font-size: large;
		line-height: 1em;
	}
	// @endCleanup encore

	+encore() {
		display: flex;
		justify-content: center;
		align-items: center;
		margin-right: spacing(1.5);
	}
}

// @startCleanup encore
{__('tabCaret', 'active')} {
	+jadeFabricTheme() {
		fab-background-color: theme;
	}
}
// @endCleanup encore

{__('tabWrapper')} {
	// @startCleanup encore
	fab-background-color: gray1;
	border: 1px solid fab-color(gray3);
	border-left: none;
	border-right: none;
	// @endCleanup encore
	user-select: none;

	+encore() {
		border: none;
		padding: spacing(2) spacing(4);
		background-color: var(--white);
		box-shadow: 1px 1px 0 2px rgba($fabEncoreColors.gray9, 0.03);
		border-radius: 16px;
	}
}

{__('tab', 'done')} {
	+encore() {
		color: var(--fabric-theme-base);
	}
}

{__('tabContentWrapper')} {
	+encore() {
		margin-top: spacing(4);
	}
}
