.WelcomeEmailPreviewer {
	padding: 25px 16px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;

	//.WelcomeEmailPreviewerQuestions
	&Questions {

		margin-top: 22px;
		margin-bottom: 22px;

		&__title {
			color: fab-color(theme);
		}
		&__question {
			color: fab-color(theme);
			fab-text: medium;
			fab-font-weight: bold;
		}

		&__answer {
			fab-font-size: small;
			margin-bottom: 20px;
		}
	}

	// .WelcomeEmailPreviewer__badgeContainer
	&__badgeContainer {
		background-color: fab-color(gray1);
		margin-left: 27px;
		padding: 35px 9px 32px;
		width: 440px;
		margin: 0px auto 24px;
	}

	// .WelcomeEmailPreviewer__emailHeader
	&__emailHeader {
		color: fab-color(white);
		background-color: fab-color(theme-light);
		background-image:url('https://staticfe.bamboohr.com/resources/images/settings/tasklists/onboarding/welcome_email_template/emailHeaderBackground.png');
		width: 512px;
		height: 174px;
		margin: 0px auto 24px;
		padding-top: 35px;
		position: relative;
		text-align: center;

		.EmailHeader {
			// .EmailHeader__icon
			&__icon {

			}

			// .EmailHeader__title
			&__title {
				font-size: 24px; //Non-standard size for fabric but standard size for Email templates we we are using it
				fab-line-height: h4;
				margin-top: 16px;
			}

			// .EmailHeader__subTitle
			&__subTitle {
				fab-font-size: large;
				fab-line-height: teenie;
				margin-top: 7px;
			}
		}

		.borderBar {
			width: 100%;
			height 4px;
			/* fab-color() here broke things so I used a hash */
			background-color: rgba(#222222, .35);
			position: absolute;
			bottom: 0;
		}
	}

	// .WelcomeEmailPreviewer__emailBody
	&__emailBody {
		padding: 0 25px;
		white-space: pre-line;

		// .WelcomeEmailPreviewer__emailBodyText
		&Text {
			fab-text: small;
			margin-bottom: 24px;
		}
	}

	// .WelcomeEmailPreviewer__placeholderQuestion
	&__placeholderQuestion {
		display: flex;
		margin-top: 22px;
		margin-bottom: 22px;

		.PlaceholderQuestion {
			// .PlaceholderQuestion__icon
			&__icon {
				margin-right: 12px;
			}

			// .PlaceholderQuestion__text
			&__text {
				color: fab-color(gray6);
				display: inline-block;
				fab-text: medium;
			}
		}
	}
}
