.Multiplex {
	background-color: #FBFBFB;
	border: 1px solid #CBCBCB;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	border-radius: 3px;
	box-sizing: border-box;
	height: 300px;
	overflow-y: auto;
	width: 330px;

	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.Multiplex--noBorders {
	border: none;
}

.Multiplex--notRounded {
	-webkit-border-radius: 0;
	-moz-border-radius: 0;
	border-radius: 0;
}

.Multiplex__list {
	background-color: #FFF;
	cursor: default;
	font-family: 'Source Sans Pro', Arial, Helvetica, sans-serif;
	font-size: 14px;
	margin: 0;
	padding: 0;

	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.Multiplex__list .Multiplex__item {
	list-style-type: none;
	margin: 0;
	padding: 0;
}

.Multiplex__list .Multiplex__item--selected {
	color: white;
	background-color: #4d95ff;
}

.Multiplex__list .Multiplex__item--disabled {
	color: #aaa;
}

.Multiplex__btn {
	border: 1px solid #CBCBCB;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	border-radius: 3px;
	box-sizing: border-box;
	height: 26px;
	margin-bottom: 4px;
	width: 46px;
}

.Multiplex__btn:hover {
	background: rgb(255,255,255); /* Old browsers */
	background: -moz-linear-gradient(top,  #FFFFFF 0%, #F4F4F4 100%); /* FF3.6+ */
	background: -webkit-linear-gradient(top,  #FFFFFF 0%, #F4F4F4 100%); /* Chrome10+,Safari5.1+ */
	background: -ms-linear-gradient(top,  #FFFFFF 0%, #F4F4F4 100%); /* IE10+ */
	background: linear-gradient(to bottom,  #FFFFFF 0%, #F4F4F4 100%); /* W3C */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#F4F4F4',GradientType=0 ); /* IE6-9 */
	border-color: #BBB;
}

.Multiplex__btn:active {
	background: rgb(255,255,255); /* Old browsers */
	background: -moz-linear-gradient(top,  #F4F4F4 0%,  #FFFFFF 100%); /* FF3.6+ */
	background: -webkit-linear-gradient(top,  #F4F4F4 0%, #FFFFFF 100%); /* Chrome10+,Safari5.1+ */
	background: -ms-linear-gradient(top,  #F4F4F4 0%, #FFFFFF 100%); /* IE10+ */
	background: linear-gradient(to bottom,  #F4F4F4 0%, #FFFFFF 100%); /* W3C */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#F4F4F4', endColorstr='#FFFFFF',GradientType=0 ); /* IE6-9 */
	border-color: #AAA;
}

.Multiplex__btn:before {
	border: solid #333;
	border-width: 0 3px 3px 0;
	content: "\00a0";
	display: block;
	height: 5px;
	position: relative;
	top: 50%;
	left: 37%;
	-webkit-transform: translate(-50%, -50%) rotate(-45deg);
	-moz-transform: translate(-50%, -50%) rotate(-45deg);
	-ms-transform: translate(-50%, -50%) rotate(-45deg);
	-o-transform: translate(-50%, -50%) rotate(-45deg);
	transform: translate(-50%, -50%) rotate(-45deg);
	width: 5px;

	-webkit-border-radius: 1px;
	-moz-border-radius: 1px;
	border-radius: 1px;
}

.Multiplex__btn:after {
	border: solid #333;
	border-width: 0 3px 3px 0;
	content: "\00a0";
	display: block;
	height: 5px;
	position: relative;
	top: 16%;
	left: 55%;
	-webkit-transform: translate(-50%, -50%) rotate(-45deg);
	-moz-transform: translate(-50%, -50%) rotate(-45deg);
	-ms-transform: translate(-50%, -50%) rotate(-45deg);
	-o-transform: translate(-50%, -50%) rotate(-45deg);
	transform: translate(-50%, -50%) rotate(-45deg);
	width: 5px;

	-webkit-border-radius: 1px;
	-moz-border-radius: 1px;
	border-radius: 1px;
}

.Multiplex__btn.Multiplex__btn--one:before {
	left: 45%;
}

.Multiplex__btn.Multiplex__btn--one:after {
	content: none;
}

.Multiplex__btn.Multiplex__btn--left:before {
	top: 50%;
	left: 43%;
	-webkit-transform: translate(-50%, -50%) rotate(135deg);
	-moz-transform: translate(-50%, -50%) rotate(135deg);
	-ms-transform: translate(-50%, -50%) rotate(135deg);
	-o-transform: translate(-50%, -50%) rotate(135deg);
	transform: translate(-50%, -50%) rotate(135deg);
}

.Multiplex__btn.Multiplex__btn--left:after {
	top: 16%;
	left: 61%;
	-webkit-transform: translate(-50%, -50%) rotate(135deg);
	-moz-transform: translate(-50%, -50%) rotate(135deg);
	-ms-transform: translate(-50%, -50%) rotate(135deg);
	-o-transform: translate(-50%, -50%) rotate(135deg);
	transform: translate(-50%, -50%) rotate(135deg);
}

.Multiplex__btn.Multiplex__btn--left.Multiplex__btn--one:before {
	left: 52%;
}

.Multiplex__btn.Multiplex__btn--left.Multiplex__btn--one:after {
	content: none;
}
