{featureToggleOn("jade")} {
	.LineChart {

		&--strokeThemeColor {
			stroke: fab-color(theme-base);
		}

		&--strokeLightThemeColor {
			stroke: fab-color(theme-light);
		}
	}
}
