.SettingsUsersConsent .SiteFooter {
	display: none;
}

.ConsentOnlyInterstitial {
	text-align: center;
	padding: 0 0 21px;

	// .ConsentOnlyInterstitial__header
	&__header {
		fab-color: gray10;
		fab-font-weight: medium;
		margin-top: 19px;
	}

	.TosConsent__box {
		margin-top: 22px;
	}
}
