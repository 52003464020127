.ba-TypeDeleteModal {
	$alert = #ba3108;

	padding: 22px 40px 30px;
	text-align: center;
	width: 334px;

	&__trashIcon {
		margin: 0 0 6px;
		width: 109px;
	}

	&__confirmationMessage {
		font-size: 18px;
		margin: 0 auto 20px;
		line-height: 1.4;
		color: #56;
	}

	&__actionCard {
		padding: 18px 20px 22px;
		background-color: #f0;
		border: 1px solid $alert;
		border-radius: 3px;
	}

	&__deleteMessage {
		margin: 0 0 12px;
		font-size: 13px;
		color: $alert;
		line-height: normal;
	}

	&__typeDelete {
		margin: 0 0 6px;
		font-size: 15px;
		color: $alert;
	}
}
