/* Overriding global form and blank state styles */
.WithholdingBlankState {

	/* .WithholdingBlankState .ba-BlankState__icon */
	.ba-BlankState__icon {
		margin-bottom: 12px;
	}

	/* .WithholdingBlankState .ba-BlankState__title */
	.ba-BlankState__title {
		color: $bhrColors.gray6;
		font-size: 18px;
		margin: 0;
	}
}
