.EnpsDetailAnswer {
	
	&__questions {
		padding: 27px 22px 5px;
	}
}

@media only screen and (max-width: 800px) {
	.EnpsDetailAnswer {

		&__questions {
			margin: 0;
			padding: 0 40px;
		}

		&__navigation {
			border: none;
			cursor: pointer;
			display: flex;
			fab-background-color: white;
			fab-color: gray7;
			font-size: 16px;
			margin: 16px;

			&Icon {
				fab-fill: gray7;
				margin-right: 12px;
			}
		}
	}
}
