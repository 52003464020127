.WageMetadata {
	box-sizing: border-box;
	min-height: 60px;
	text-align: left;
	display: flex;
	padding-top: 16px;

	// .WageMetadata__detail
	&__detail {
		margin-bottom: 2px;
	}

	// .WageMetadata__values
	&__values {
		margin-left: 20px;
	}
}
