elem = BEM('BaTabs', 'fab-large-theme');

{elem('tabContent')} {
	+encore() {
		fab-background-color: gray1;
		border-radius: 8px;
	}
}

{elem('tabContent', 'fab-large-theme_light')} {
	+encore() {
		fab-background-color: white;
		border-radius: 8px;
	}
}

{elem('tab')} {
	padding: 20px 24px 15px;
	// @startCleanup encore
	border-top-radius: 4px;
	// @endCleanup encore
	border-bottom-radius: 0;
	flex-direction: column;
	align-items: stretch;
	flex: 1;
	
	+encore() {
		border-top-radius: 8px;
		margin: 0 spacing(.5);
	}

	&:not(.BaTabs__tab--active) {
		fab-color: gray8;
		fill: rgba(fab-color(gray8), 0.8);
	}

	&:not(.BaTabs__tab--disabled):not(.BaTabs__tab--active):not(.BaTabs__tab--loading):hover {
		fab-color: theme;
		fab-fill: theme;
		// @startCleanup encore
		background-color: rgba(fab-color('white'), 0.5);
		// @endCleanup encore
		+encore() {
			background-color: rgba($fabEncoreColors.gray05, 0.9);
		}
	}
}

{elem('tab', 'withIcon')} {
	// left padding + icon width + icon margin
	padding-left: 24px + 28px + 12px;
}

{elem('tabLabel', 'primary')} {
	fab-text: large;
	
	+encore() {
		fab-font-weight: medium;
	}
}

{elem('tabLabel', 'primary active')} {
	fab-font-weight: heavy;
}

{elem('tabLabel', 'secondary')} {
	fab-text: small;
	fab-color: gray8;
	fab-font-weight: regular;
	margin-top: 4px;
	max-width: 100%;
}

{elem('tabLabel', 'secondary fab-large-theme_light')} {
	fab-color: gray7;
}

{elem('tab', 'active')} {
	fab-color: theme;
	fab-fill: theme;
	// @startCleanup encore
	fab-background-color: 'white';
	box-shadow: 0 -4px 8px 0 rgba(#0, 0.05);
	// @endCleanup encore

	+encore() {
		fab-background-color: gray1;
		// @startCleanup encore
		box-shadow: none;
		// @endCleanup encore
	}
}

{elem('tabIcon')} {
	position: absolute;
	top: 20px;
	left: 24px;

	> svg {
		// @startCleanup encore
		size: 28px;
		// @endCleanup encore

		+encore() {
			size: 24px;
		}
	}
}

{elem('tabIcon', 'fab-large-theme_light')} {
	+encore() {
		> svg {
			size: 24px;
		}
	}
}

{elem('tabIcon', 'active')} {
	+encore() {
		fill: var(--fabric-theme-base);
	}
}

{elem('tabWrapper')} {
	padding: 32px 24px 0;

	// @startCleanup encore
	&:not(.BaTabs__tabWrapper--fab-large-theme_header) {
		fab-background-color: gray1;
		+encore() {
			fab-background-color: white;
		}
	}
	&:before {
		content: "";
		display: block;
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		height: 3px;
		background-image: linear-gradient(to top, rgba(#0, 0.05) 0, rgba(#0, 0) 3px, rgba(#0, 0) 100%);

		+encore() {
			content: none;
		}
	}
	// @endCleanup encore
}

{elem('tabWrapper', 'fab-large-theme_light')} {
	+encore() {
		fab-background-color: gray1;
		border-radius: 16px;
	}
}

{elem('tab', 'fab-large-theme_light')} {
	&:not(.BaTabs__tab--active) {
		+encore() {
			fab-background-color: gray1;
		}
	}

	+encore() {
		fab-background-color: 'white';
		fill: var(--gray8);
	}
}
