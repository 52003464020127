.DeductionSettings {

	// .DeductionSettings__top
	&__top {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding-top: 6px;
		margin-bottom: 6px;

		// .DeductionSettings__topHeader
		&Header {
			display: flex;
			align-items: center;
		}

		// .DeductionSettings__topIcon
		&Icon {
			margin-right: 9px;
			padding-top: 9px;
		}

		// .DeductionSettings__topFilter
		&Filter {
			padding-top: 6px;
		}
	}
}
