.GTKYQuestion {
	align-items: center;

	height: unset;
	margin-bottom: unset;

	// .GTKYQuestion__dragHandle
	&__dragHandle {
		cursor: move;
		pointer-events: all;
	}

	// .GTKYQuestionToggle
	&Toggle {
		margin: 3px 10px 0;
	}

	// .GTKYQuestionDelete
	&Delete {
		cursor: pointer;
		line-height: 0;
	}
}
