.PayGroupsSettings {

	// .PayGroupsSettings__heading
	&__heading {
		display: flex;
		align-items: center;
	}

	// .PayGroupsSettings__icon
	&__icon {
		fab-fill: theme;
		margin: 10px 9px 0 0;
	}
}

