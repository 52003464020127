.MappingTableItem {
	box-sizing: border-box;
	border: 1px solid fab-color(gray3);
	fab-background-color: gray1;
	padding: 15px;
	width: 420px;
	text-align: left;
	min-height: 66px;

	// .MappingTableItem__displayValue
	&__displayValue {
		height: 34px;
		display: flex;
		align-items: center;
	}
}
