@require "~datatables.net-jqui/css/dataTables.jqueryui.css";

.DataTable {
	padding: 0;
	width: 100%;

	&__container {
		min-width: 100% !important;
	}

	tbody {
		transition: opacity 0.4s;

		tr {
			// .DataTable tbody tr.DataTable__row--active
			&^[0]__row--active {
				background: #f0 !important;

				background-color: fab-color(theme-lightest10) !important;
			}
		}
	}

	&--no-sort {
		th {
			cursor: default !important;

			&:after {
				display: none !important;
			}
		}
	}

	&--noScroll {
		.dataTables_scrollBody {
			overflow: visible !important;
		}
	}

	&--show-children {
		tbody {
			tr {
				// .DataTable--show-children tbody tr:not(.DataTable__row--child)
				&:not(^[0]__row--child) {
					> td {
						// .DataTable--tight.DataTable--show-children tbody tr:not(.DataTable__row--child) > td
						~/--tight& {
							padding: 6px 10px 7px;
						}
					}
				}
			}
		}
	}

	&:not(&--show-children) {
		// .DataTable:not(.DataTable--show-children) .DataTable__row--child
		& ^[0]__row--child {
			display: none;
		}
	}

	&--processing {
		tbody {
			opacity: 0;
		}
	}

	&__filter-buttons {
		display: none !important;

		// .DataTable__filter-buttons .DataTable__reset-filters
		& ^[0]__reset-filters {
			min-width: 0;
			color: #006ec2;
			font-weight: normal;
		}
	}

	&__pagination {
		justify-content: space-between;
		line-height: 24px;
		display: flex;
		flex-wrap: nowrap;
		flex-direction: row;
		padding-top: 16px;

		+encore() {
			&:empty {
				padding-top: 0;
			}
		}

		.dataTables_info {
			fab-color: gray5;
			fab-font-size: small;
		}

		.dataTables_paginate {
			display: flex;
			flex-wrap: nowrap;
			flex-direction: row;

			* {
				box-sizing: border-box;
			}

			> span {
				display: flex;
				flex-wrap: nowrap;
				flex-direction: row;
			}
		}
	}

	&__filters {
		padding-bottom: 15px;
		margin-top: -4px;
		float: left;

		&:not(&--active) {
			.chzn-single {
				background: #fff;
				box-shadow: none;
			}

			&:not(:hover) {
				.chzn-single {
					border-color: #e4e4e4;

					> div b {
						display: none;
					}
				}

				.search-choice-close {
					display: none;
				}

				.EmployeeFilters__link--add {
					display: none !important;
				}
			}
		}

		select {
			opacity: 0;
		}

		.chzn-search input[type="text"] {
			box-sizing: border-box;
			max-width: 100%;
		}

		&--active {
			// .DataTable__filters--active .DataTable__filter-buttons
			& ^[0]__filter-buttons {
				display: inline-block !important;
			}
		}

		> div {
			display: inline-block;
			padding-right: 15px;
			padding-top: 4px;
		}

		.chzn-container,
		.chzn-drop {
			max-width: 150px;
		}

		.chzn-drop {
			box-sizing: border-box;
		}

		.EmployeeFilters {
			margin: 0 -3px;

			&__selected-container {
				.chzn-container {
					margin-right: 15px;
					width: auto !important;

					&,
					.chzn-drop {
						box-sizing: border-box;
					}
				}
			}

			&__link--add {
				margin: 0 -15px;
			}
		}
	}

	&__cell {
		$defaultCellWidth = 150px;

		width: $defaultCellWidth;
		min-width: @width;
		max-width: @width;

		> input,
		> select,
		> .chzn-container,
		.chzn-drop {
			../.errorColumn ^[-1..-1] {
				width: $defaultCellWidth - 35px;
				min-width: @width;
				max-width: @width;
			}

			width: $defaultCellWidth - 15px;
			min-width: @width;
			max-width: @width;
		}

		&--actions {
			.btn {
				opacity: 0;
			}

			&:hover {
				opacity: 1;
			}
		}
	}

	//NOTE: from here down are style overrides for the dataTables plugin

	.dataTables_wrapper {
		.dataTables_scrollBody {
			border-bottom: none;
		}
	}

	table.dataTable {
		border: none;
		width: 100%;

		thead {
			tr {
				background: fab-color(gray2);
			}

			th {
				// @startCleanup encore
				border: 1px solid #97;
				border-left: none;
				border-right: none;
				padding: 12px 16px 11px;
				// @endCleanup encore
				fab-background-color: gray2;
				fab-color: gray8;
				+encore() {
					border: none;
					padding: 13px 16px;
					fab-background-color: gray1;
					font-size: var(--font-size-medium);
					font-weight: var(--font-weight-semibold);
				}

				&.sorting {
					&,
					&_asc,
					&_desc {
						background-image: none;
						position: relative;
					}
				}

				&.fab-Table__header--sortable {
					cursor: pointer !important;

					&:hover {
						// @startCleanup encore
						fab-background-color: gray3;
						// @endCleanup encore

						+encore() {
							background-color: var(--gray1);
						}
					}
				}
			}
		}

		th,
		td {
			vertical-align: middle;
			text-align: left;
			min-width: 80px;
			max-width: 220px;
			white-space: normal;
			box-sizing: border-box;

			&.no-wrap {
				white-space: nowrap !important;
			}

			&.DataTable__cell {

				&--lastLogin {
					padding-right: 0;
				}

				&:last-of-type {
					max-width: 100%;
					width: auto;
				}
			}
		}

		td {
			padding: 13px 17px;

			&.dataTables_empty {
				background: #fff;
				border: none !important;
				padding: 32px 20px !important;
				font-weight: normal;
			}

			// .DataTable--tight.DataTable table.dataTable td
			~/--tight& {
				padding: 9px 12px;
			}
		}

		tbody {
			tr {
				// The dataTable plugin is stupid, and this is
				// the only way to override the default theme...
				&.fab-Table__row--selected,
				&.fab-Table__row--highlighted {
					fab-background-color: theme-lightest10;
				}

				td {
					border-bottom: 1px solid #d9;

					> *,
					input,
					select,
					.chzn-drop {
						margin: 0;
						box-sizing: border-box;
					}
					+encore() {
						> ba-select {
							display: inline !important;
						}
						border-bottom: 1px fab-color(gray1) solid;
					}

					.chzn-container:not(.chzn-container-active) {
						.chzn-drop {
							display: none;
						}
					}

					.ui-datepicker-trigger {
						top: 0;
					}
				}

				// .DataTable:not(.DataTable--no-alt-rows).DataTable table.dataTable tbody tr
				~/:not(^[0]--no-alt-rows)& {
					&:nth-child(even) {
						// @startCleanup encore
						background: #fb;
						// @endCleanup encore
						+encore() {
							background: fab-color(white);
						}
					}
				}
			}
		}
	}

	.dataTables_scroll {
		position: relative;

		&Head {
			th.sorting {

				&_asc,
				&_desc {
					&:after {
						content: "";
						display: none;
						top: 50%;
						transform: translateY(-50%);
						size: 0;
						border: 4px solid transparent;
						margin-left: 6px;
					}
				}

				&_asc:after {
					border-bottom-color: #555;
					border-top-width: 0;
				}

				&_desc:after {
					border-top-color: #555;
					border-bottom-width: 0;
				}
			}
		}
	}

	.dataTables_info,
	.dataTables_paginate {
		padding: 0;
	}

	.dataTables_info {
		+legacy() {
			color: #7;
			padding-right: 14px;
			line-height: 17px;
		}
	}

	.dataTables_paginate {
		+legacy() {
			line-height: 15px;
		}

		.DataTable--singlePage& {
			display: none;
		}

		> span {
			+legacy() {
				float: left;
			}
		}

		.paginate_button {
			background: none !important;
			text-align: center;
			fab-font-size: small;
			fab-line-height: medium;
			height: 24px;
			border-radius: 2px;
			border: 1px solid transparent;
			fab-background-color: gray1;
			margin-left: 8px;
			padding: 0 8px;
			text-decoration: none !important;

			&[data-dt-idx="1"]:last-of-type,
			&.next[data-dt-idx="2"],
			&.previous.disabled {
				display: none;
			}

			&.current {
				background-color: fab-color(white) !important;
				border-color: fab-color(theme-lighter);
				color: fab-color(theme-lighter) !important;
			}

			&.disabled {
				&,
				&:hover {
					color: #b !important;
					border: none !important;
				}
			}

			&.previous,
			&.next {

				&:not(.disabled) {
					color: fab-color(info) !important;

					&:hover {
						color: fab-color(theme) !important;
						text-decoration: underline !important;
					}
				}
			}

			&.previous {
				margin-left: 4px;
			}

			&.next {
				&:before {
				}

				&.disabled {
					color: fab-color(gray5) !important;
				}
			}

			&:not(.disabled):not(.current) {
			}

			&:not(.disabled):not(.next):not(.previous) {
				&:not(:hover) {
					color: fab-color(gray7) !important;
				}

				&:hover {
					background-color: fab-color(theme-lighter) !important;
					color: fab-color(white) !important;
				}
			}

			+ .ellipsis {
				padding: 0 !important;
				color: #7;
			}
		}
	}

	.DTFC {

		&_LeftWrapper,
		&_RightWrapper {
			height: auto !important;

			&:before {
				content: "";
				position: absolute;
				display: block;
				top: 0;
				bottom: 0;
				width: 1px;
				z-index: 1000;
				border: 0 solid #97;
				// @startCleanup encore
				border-color: fab-color(gray4);
				// @endCleanup encore
				+encore() {
					border-color: var(--gray2);
				}
			}
		}

		&_LeftHeadWrapper {
			+encore() {
				border-radius: 8px 0 0 8px !important;
			}
		}

		&_LeftWrapper {
			edge-shadow: right;
			z-index: 2;
			background-color: fab-color(white);

			// .DataTable:not(.DataTable--scrolled-left) .DTFC_LeftWrapper
			~/:not(^[0]--scrolled-left) ^[1..-1] {
				edge-shadow: none;
			}

			&:before {
				border-right-width: 2px;
				right: 0;
				+encore() {
					border-right-width: 1px;
				}

				// .DataTable:not(.DataTable--hasLeftColumns) .DTFC_LeftWrapper:before
				~/:not(^[0]--hasLeftColumns) ^[1..-1] {
					border-right: none;
				}
			}

			th:only-of-type {
				+encore() {
					border-radius: 8px 0 0 8px;
				}
			}
		}

		&_RightWrapper {
			edge-shadow: left;
			bottom: 0;

			// .DataTable:not(.DataTable--scrolled-right) .DTFC_RightWrapper
			~/:not(^[0]--scrolled-right) ^[1..-1] {
				edge-shadow: none;
			}

			&:before {
				border-left-width: 1px;
				left: 0;

				// .DataTable:not(.DataTable--hasRightColumns) .DTFC_RightWrapper:before
				~/:not(^[0]--hasRightColumns) ^[1..-1] {
					border-left: none;
				}
			}
		}

		&_RightHeadBlocker {
			// @startCleanup encore
			background: linear-gradient(to bottom, #f3 0, #eee 100%);
			border: 1px solid #97;
			border-left: none;
			border-right: none;
			// @endCleanup encore
			+encore() {
				background: var(--white);
				border: none !important;
			}

			&:last-child {
				left: 0;
				right: 0;
				z-index: 100;
			}
		}

		&_ScrollWrapper {
			// @startCleanup encore
			border-right: 2px solid fab-color(gray4);
			// @endCleanup encore
			+encore() {
				border-right: unset;
				height: auto !important;
			}
		}
	}
}
