.settings--full-width {

	#settings-main-content {
		display: flex;
		padding: 0;
		min-height: 100%;
		> div {
			min-height: 100%;
			width: 100%;
		}
	}
}

.TimeOffImport {
		margin: unset;

	&__step {
		display: flex;
		flex-direction: row;
		align-items: center;
		padding: 0 0 32px 22px;

		&:nth-of-type(3) { // Don't know why but :last-of-type didn't work so I had to use this
			padding: 0 0 18px 22px;
		}

		&Mark {
			fab-background-color: gray7;
			border: none;
			fab-color: white;
			font-size: 15px;
			font-weight: 600;
			padding-top: 2px;
			border-radius: 50%;
			display: inline-block;
			height: 26px;
			width: 26px;
			margin-right: 15px;
			position: relative;
			text-align: center;
			box-sizing: border-box;

			&--import {
				top: 10px;
			}
		}

        	gap: 16px;  
	}

	&__template {
		font-weight: 600;
	}

	&__file {
		display: inline-flex;
		align-items: center;
		margin-left: 8px;
		fab-color: gray6;

		&Icon {
			margin-right: 6px;
			fab-fill: gray5;
		}
	}

	&__files {
		padding-top: 2px;

		& .file-wrapper {
			padding: 4px 20px 0 1px;
		}
	}

	&__note {
		margin-left: 64px;
		fab-text: small;
		fab-color: gray7;
	}

	&__importResults {

		&__cell {

			&Icon {
				position: relative;
				top: 2px;
				margin-right: 6px;

				&--success {
					fab-fill: success;
				}

				&--warning {
					fab-fill: attention;
				}
			}
		}
	}
}

.TimeOffImport__step:last-of-type {
	background-color: red;
}

