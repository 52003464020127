.BaTabs {
	&__progress,
	&__loader {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate3d(-50%, -50%, 0);
	}

	&__tab {
		position: relative;
		user-select: none;
		box-sizing: border-box;

		+jade() {
			display: flex;

			&[class*="-theme_large"] {
				fab-font-size: large;
			}

			&--active {
				fab-font-weight: heavy;
			}

			&--disabled {
				opacity: 0.6;
			}
		}

		&:not(&--active):not(&--disabled):not(&--loading) {
			cursor: pointer;
		}

		+jade() {
			fab-color: gray8;
			fab-fill: gray7;
		}

		&--disabled {
			&,
			* {
				cursor: default;
			}
		}

		&OverflowWrapper {
			position: absolute;
			display: none;

			../:hover & {
				display: block;
			}
		}

		&Label {
			display: block;
			box-sizing: border-box;

			&,
			&:hover {
				text-decoration: none;
			}

			&[class*="-theme_icons"] {
				// Hide label for "icons" theme variants
				display: none;
			}
		}

		&Icon {
			line-height: 0;
		}

		&Caret {
			&--notify {
				position: absolute;
				top: -9px;
				right: -5px;
				background: $bhrColors.error1;
				border-radius: 50%;
				color: $colors.white;
				font-size: 11px;
				height: 18px;
				min-width: @height;
				text-align: center;
				line-height: @height;
			}
		}

		&Wrapper {
			display: flex;
			align-items: center;
			position: relative;
			box-sizing: border-box;

			&--hidden {
				opacity: 0;
			}

			&Header {
				+jade() {
					display: flex;
				}
			}

			&Title {
				&Wrapper {
					+jade() {
						flex-grow: 2;
						display: flex;
						flex-direction: column;
					}
				}

				+jade() {
					justify-content: center;
				}
			}
		}

		&Content {
			position: relative;
			width: 100%;

			&:not(&--active) {
				display: none;
			}

			&--loading {
				min-height: 100px;
			}

			&Wrapper {
				box-sizing: border-box;
				display: flex;
				flex: 1;

				&--processing {
					> .BaTabs__tabContent {
						display: none;
					}
				}
			}
		}
	}
}
