// Step 2 inidividual
.BenefitsList {


	padding-left: 20px;
	color: #777777;
	width: 100%;
	font-weight: 600;

	&-smallMargin {
		margin-bottom: 14px;
	}

	&-leftIndent {
		margin-left: 20px;
	}

	&__planTitle {

		padding-top: 20px;
		margin-left: -20px;

		&-name {
			width: 500px;
			font-weight: 600;
			color: black;
		}

		&-svg {
			margin-right: 10px;
			height: 28px;
			width: 34px;
			display: block;
		}
	}

	&__variable {

		&-effectiveDate {
			padding-left: 52px;
			padding-right: 10px;
		}

		&-enrollmentStatus {
			padding-left: 49px;
		}

		&-coverage {
			padding-left: 67px;
		}

		&-employeePays {
			padding-left: 121px;
		}

		&-companyPays {
			padding-left: 42px;
		}

		&-deductionFrequency {
			padding-left: 103px;
		}

		&-comment {
			padding-left: 52px;
		}
	}

	&__nonhealth {

		&-effectiveDate {
			padding-left: 52px;
			padding-right: 10px;
		}

		&-enrollmentStatus {
			padding-left: 50px;
		}

		&-employeePays {
			padding-left: 65px;
		}

		&-companyPays {
			padding-left: 76px;
		}

		&-deductionFrequency {
			padding-left: 217px;
		}

		&-comment {
			padding-left: 52px;
		}
	}

	&__health {

		&-effectiveDate {
			padding-left: 52px;
			padding-right: 10px;
		}

		&-enrollmentStatus {
			padding-left: 63px;
		}

		&-coverage {
			padding-left: 87px;
		}

		&-comment {
			padding-left: 143px;
		}
	}

	&__reimbursement {

		&-effectiveDate {
			padding-left: 54px;
		}

		&-enrollmentStatus {
			padding-left: 58px;
		}

		&-comment {
			padding-left: 68px;
		}
	}
}

label.greyed {
	background-color: #989898 !important;
	top: 1px;
	left: 1px;
}

.BhrForms {

	& .BenefitCard {
		background-color: #e8e8e8;
		border: solid white 1px;
		margin: 2px 0 10px 0;
		padding: 10px 10px 15px 10px;
		width: 1000px;
		z-index: 5px;
		display: none;

		&__vhr {
			height: 25px;
			border-left: 1px solid #B1B1B1;
			margin-bottom: 2px;
			margin-top: 2px;
			vertical-align: bottom;

			&--top {
				vertical-align: top;
			}
		}

		&--dimmed {
			position: relative;
			user-select: none;

			&:hover {
				pointer-events: none;
				cursor: crosshair;
			}

			&:before {
				content: "";
				z-index: 10;
				display: block;
				position: absolute;
				height: 100%;
				top: 0;
				left: 0;
				right: 0;
				background: #e8e8e8;
				opacity: 0.8;
			}
		}

		&--active {
			border: solid #9e9e9e 1px;

			.BenefitCard__nonhealth--commentParent {
				display: block;
			}

			.BenefitCard__variable--commentParent {
				display: block;
			}
		}

		&-note {
			position: absolute;
			left: 335px;
			text-align: right;
			top: -19px;
			width: 400px;
			font-weight: normal;

			&--info:before {
				background-image: url('https://staticfe.bamboohr.com/resources/images/settings/new_benefit/information.svg');
				background-size: 12px 12px;
				background-repeat: no-repeat;
				content: "";
				display: inline-block;
				height: 12px;
				padding-right: 5px;
				width: 12px;
			}

			&--info {
				color: #56afdd;
				font-weight: normal;
				z-index: 11;
				opacity: 0.8;
			}

			&--error:before {
				background-image: url('https://staticfe.bamboohr.com/resources/images/settings/new_benefit/information_error.svg');
				background-size: 12px 12px;
				background-repeat: no-repeat;
				content: "";
				display: inline-block;
				height: 12px;
				padding-right: 5px;
				width: 12px;
			}

			&--error {
				color: #cf6240;
			}
		}

		&-svg {
			height: 20px;
			width: 16px;
			display: block;
		}

		&-employee {
			line-height: 1em;
			width: 250px;
			margin: 0;
			font-weight: normal;
			color: black;

		}


		&__nonhealth {

			&-effectiveDate {
				width: 126px;
				padding-left: 40px;
			}

			&-employeePays {
				min-width: 148px;
			}

			&-companyPays {
				min-width: 290px;
			}

			&-deductionFrequency {
				margin-top: 2px;
			}

			&--commentParent {
				display: none;
			}

			&-comment {
				padding-top: 5px;
				padding-left: 40px;

				& input {
					min-width: 303px;
					width: 303px;
				}
			}
		}

		&__variable {

			&-effectiveDate {
				width: 126px;
				padding-left: 40px;
			}

			&-enrollmentStatus {
				width: 150px;
				padding-right: 6px;
			}

			&-coverage {
				width: 150px;
				padding-right: 8px;
			}

			&-employeePays {
				min-width: 124px;
				margin-right: 0;
			}

			&-companyPays {
				min-width: 180px;
				margin-right: 0;
				margin-left: 0;
			}

			&-deductionFrequency {
				margin-top: 10px;
			}

			&--commentParent {
				display: none;
			}

			&-comment {

				padding-top: 5px;
				padding-left: 40px;

				& input {
					min-width: 480px;
					width: 480px;
				}
			}
		}

		&__health {

			padding-right: 20px;

			&-effectiveDate {
				width: 126px;
				padding-left: 40px;
			}

			&-enrollmentStatus {
				width: 150px;
				padding-right: 7px;
			}

			&-coverage {
				width: 150px;
				padding-right: 6px;
			}

			&-comment {
				min-width: 334px;
				width: 334px;
				padding-left: 35px;
			}
		}

		&__reimbursement {

			&-effectiveDate {
				width: 94px;
				padding-left: 40px;
				padding-right: 32px;
			}

			&-enrollmentStatus {
				width: 157px;
			}

			&-comment {

				& input {
					min-width: 590px;
					width: 590px;
				}
			}
		}

		// .BhrForms .BenefitCard__deductionNote
		&__deductionNote {
			fab-font-weight: normal;
			margin: 8px 0 0 40px;
		}
	}
}

// Step 3 individual table stylings
.BenefitHeader {
	padding-left: 23px;
}

.BenefitTableHeader {

	&--light {
		color: #888888;
		font-size: small;
		font-weight: normal;
	}

	&-employee {

		padding-bottom: 0;
		color: black;
		background-color: #eaeaea;

		&--light {
			color: #888888;
			font-size: small;
			font-weight: normal;
		}
	}

	&__reimbursement {

		&-employee {
			min-width: 120px;
		}

		&-effective {
		}

		&-planStatus {
		}

		&-comment {

		}
	}

}

.BenefitTableBody {

	&--bold {
		font-weight: bold;
	}
}

{featureToggleOn("jade")} {
	.bft-form-step-1 {
		padding-top: 20px;
	}

	.formNote:not(.gross-pay-hidden) {
		display: inline-block;
	}

	.BhrTableSection__artboard {
		padding: 0 0 4px 10px;
		display: inline-block;
	}

	.Nester li:hover {
		fab-background-color: theme;
	}

	.BenefitsList {

		// .BenefitsList__planTitle
		&__planTitle {
			display: flex;
			align-items: center;
			padding-top: unset;

			// .BenefitsList__planTitle-svg
			&-svg {
				fab-fill: theme;
			}
		}
	}

	.BhrForms {

		// .BhrForms .BenefitCard
		.BenefitCard {
			width: 1200px;

			// .BhrForms .BenefitCard__nonhealth-effectiveDate
			&__nonhealth-effectiveDate,
			// .BhrForms .BenefitCard__health-effectiveDate
			&__health-effectiveDate,
			// .BhrForms .BenefitCard__variable-effectiveDate
			&__variable-effectiveDate,
			// .BhrForms .BenefitCard__reimbursement-effectiveDate
			&__reimbursement-effectiveDate {
				width: 200px;
			}

			// .BhrForms .BenefitCard__variable
			&__variable {

				// .BhrForms .BenefitCard__variable-deductionFrequency
				&-deductionFrequency {
					margin-top: 4px;
				}
			}
		}
	}
}

.BenefitsBulkEdit {

	&StepTwo {

		&__card {

			&Icon {
				fab-fill: theme-base;
			}
		}

		&__companyPays {
			display: inline-block;

			&--inline {
				display: inline;
			}
		}

		&__planTitle {
			fab-color: theme-base;
			margin-bottom: 12px;
		}

		&__safeHarbor {
			display: inline;

			&Message {
				align-items: center;
				display: flex;
				fab-color: gray6;
				fab-fill: gray6;
				fab-font-size: small;

				&Icon {
					align-items: center;
					display: flex;
					margin-left: 3px;
				}
			}
		}
	}

	&StepThree {

		&__cardIcon {
			fab-fill: theme-base;
		}
	}
}
