/**********************************************
Withholding
**********************************************/

.EmployeeTaxStates {
	margin-top: 7px;
	padding-bottom: 25px;
}

form[name="pi-withholdings"] {
	padding-top: 0;
}

.pi-withhold h4 {
	color: #222;
	margin: 0 8px 6px 0;
}
/* @startCleanup encore */
.pi-withhold__box {
	background: #fc;
	border: 1px solid #b8;
	box-shadow: inset 0 1px 2px 0 rgba(0, 0, 0, 0.17);
	border-radius: 3px;
	margin: 0 5px 0 0;
	padding: 1px 7px;
	text-align: center;
}

.pi-withhold__box div:first-of-type {
	font-size: 26px;
	color: #222;
	margin: 0 0 2px;
}

.pi-withhold__box div:nth-of-type(2) {
	font-size: 10px;
	color: #888;
	line-height: 10px;
	margin: 0 0 4px;
	text-transform: uppercase;
}
/* @endCleanup encore */
+encoreHighSpecificity() {
	// .pi-withhold__box
	.pi-withhold__box {
		background: fab-color(white);
		border: 1px solid fab-color(gray2);
		border-radius: 8px;
		box-shadow: unset;
		display: inline-flex;
		flex-direction: column;
		justify-content: center;
		min-height: 48px;
		min-width: 48px;
		padding: 8px;
		text-align: center;
		// .pi-withhold__box div:first-of-type
		div:first-of-type {
			font-size: 26px;
			color: #222;
			margin: 0 0 4px;
		}
		// .pi-withhold__box div:nth-of-type(2)
		div:nth-of-type(2) {
			font-size: 10px;
			color: #888;
			line-height: 10px;
			margin: unset;
			text-transform: uppercase;
		}
	}
}

.pi-withhold__written {
	display: inline;
	line-height: 18px;
	margin: 0 0 0 5px;
	padding-top: 0;
	position: relative;
	top: -2px;
	left: 2px;
}

.pi-withhold-help {
	display: inline-block;
	margin-left: 3px;
	background-color: #B6B6B6;
	color: white;
	height: 14px;
	width: 14px;
	border-radius: 100%;
	text-align: center;
	font-size: 12px;
	line-height: 14px;
	cursor: pointer;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.pi-withholdings-state {
	margin-top: 11px;
	padding-top: 20px;
	border-top: solid #e0 1px;
}

.pi-federal-loader {
	margin-top: 29px;
	margin-bottom: 36px;
}

.pi-state-loader {
	margin-top: 32px;
}

/**********************************************
Error Sections
**********************************************/
.pi-broken-state,
.pi-exempt-state {
	margin: 0 auto;
	text-align: center;
	width: 685px;
}

.pi-broken-state,
.pi-exempt-state {
	margin: 10px auto 33px;
	width: 100%;
}

.pi-broken-state img,
.pi-exempt-state img {
	display: block;
	margin: 0 auto;
}

.pi-exempt-state-info {
	margin-top: 7px;
	font-size: 18px;
	color: #c5;
}

.pi-broken-state .pi-broken-more-info {
	max-width: 100%;
}
