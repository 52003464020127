.AccountCard {
	/* .AccountCard__slat */
	&__slat {
		fab-background-color: gray1;
		border-radius: 4px;
		display: flex;
		margin-bottom: 10px;
		padding: 5px 5px 0 25px;
		width: 674px;
	}

	/* .AccountCard:hover */
	&:hover {

		/* .AccountCard:hover .AccountCard__delete */
		.AccountCard__delete {
			opacity: 1;
		}
	}

	/* .AccountCard__form */
	&__form {
		padding: 16px 0;
	}

	/* .AccountCard__order */
	&__order {
		padding: 22px 10px;

		/* .AccountCard__orderNumber */
		&Number {
			align-items: center;
			fab-background-color: gray5;
			border-radius: 50px;
			fab-color: 'white';
			display: flex;
			font-size: 16px;
			height: 26px;
			justify-content: center;
			width: 26px;

			// .AccountCard__orderNumberHeader
			// Required for the mobile view
			&Header {
				display: none;
			}
		}

		/* .AccountCard__orderNumberJade */
		&NumberJade {
			align-items: center;
			fab-background-color: gray6;
			border-radius: 50px;
			fab-color: 'white';
			display: flex;
			font-size: 16px;
			height: 26px;
			justify-content: center;
			width: 26px;
			// .AccountCard__orderNumberJadeHeader
			// Required for the mobile view
			&Header {
				display: none;
			}
		}
	}

	/* .AccountCard__validation */
	&__validation {
		align-items: center;
		display: inline-flex;
		height: 32px;
		margin: 19px 0 0 10px;
		width: 140px;
	}

	/* .AccountCard__bankName */
	&__bank {

		/* .AccountCard__bankPending */
		&Pending {
			fab-color: gray6;
			display: inline-flex;
			font-size: 12px;

			.dotLoader { /* override width... */
				width: 50px;
			}
		}

		/* .AccountCard__bankLogo */
		&Logo {
			height: 32px;
		}

		/* .AccountCard__bankText */
		&Text {
			fab-color: gray6;
			font-size: 11px;
		}
	}

	/* .AccountCard__delete */
	&__delete {
		margin-left: 0;
		opacity: 0;

		&:focus-within {
			opacity: 1;
		}
	}
}
