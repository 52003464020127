.TeamIntroduction {
	// .TeamIntroduction__FormSection--indent
	&__FormSection--indent {
		margin-left: 30px;
	}

	// .TeamIntroduction__gtky
	&__gtky {
		// .TeamIntroduction__gtky--dropdown
		&--dropdown {
			margin: 0 16px;
		}

		// .TeamIntroduction__gtkyQuestions
		&Questions {
			margin-left: 24px;

			// .TeamIntroduction__gtkyQuestionsNote
			&Note {
				fab-text: small;
				fab-color: gray8;
				margin-bottom: 3px;
			}
		}
	}

	// .TeamIntroductionPreviewEmail
	&PreviewEmail {
		margin-left: 24px;

		// .TeamIntroductionPreviewEmail__button
		&__button {
			margin-left: 12px;
		}
	}
}
