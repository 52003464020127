.ClockedOutError {
	box-sizing: border-box;
	width: 346px;
	padding: 15px 20px 30px;
	display: flex;
	align-items: flex-start;
	justify-content: space-between;

	/* .ClockedOutError__warningIcon */
	&__warningIcon {
		fill: $bhrColors.warning1;
		margin-top: 5px;
	}

	/* .ClockedOutError__message */
	&__message {
		width: 269px;

		/* .ClockedOutError__messageHeader */
		&Header {
			color: $bhrColors.gray10;
			font-size: 16px;
			font-weight: 600;
			margin-bottom: 5px;
		}

		/* .ClockedOutError__messageText */
		&Text {
			color: $bhrColors.gray9;
		}
	}
}
