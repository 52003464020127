.HolidayAction__year {
	display: inline-flex;
	align-items: center;
	margin-right: 8px;
}
.HolidaySettings {
	&__header {
		display: flex;
		align-items: center;

		&Icon {
			margin-right: 8px;
		}
	}
}

.HolidayAdd {

	.reqDiv {
		fab-text: teenie;
		fab-color: theme;
		padding: 4px 0 12px;
	}

	&__addDateRange {
		margin-left: 8px;
	}

	&__employeeFilterIcon {
		fab-fill: gray7;
	}

	.CalendarPicker__rangeColumn {
		display: flex;
	}

	.CalendarPicker__rangeSeparator {
		fab-color: gray10;
	}
}

.HolidaySettingsEncoreHeader {
	display: flex;
	justify-content: space-between;

	.fab-SectionHeader__titleMedium {
		font-size: 26px;
		line-height: 34px;
	}

	.addLinks {
		.HolidayActionHeader{
			display: inline-block;
			margin-right: 12px;
		}
		.fab-Button:hover{
			text-decoration: none;
		}
	}
}
