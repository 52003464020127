/* header bar */
.brandedBar {
	position: relative;
	height: 70px;
	padding: 20px 0 0;
}
.brandedBar:after {
	content: '';
	display: block;
	width: 100%;
	height: 15px;
	position: absolute;
	bottom: 0;
	background-color: rgba(255,255,255,.5);
}
.brandedBar #contentTop h2 { color: #fff; margin-top: 0; }


.frown:before {
	content: '';
	display: inline-block;
	width: 29px;
	height: 27px;
	margin-right: 7px;
	position: relative;
	top: 4px;
	background: url("https://staticfe.bamboohr.com/resources/images/account/frown.png") no-repeat;
}

.statusMessage {
  width: 90%;
	margin: 150px auto 150px;
}
.statusMessage > img {
  float: left;
  margin-right: 50px;
  vertical-align: top;
	margin-top: 8px;
}
.statusMessage > img.horn { margin-top: -26px; }
.statusMessage > img.suspended { margin-top: 0; }
.statusMessage h2 {
	font-weight: 400;
	font-size: 36px;
	margin-bottom: 13px;
	line-height: 1em;
}
.statusMessage p {
	font-size: 18px;
}
.statusMessage > p:last-child { margin-top: 28px; }

/* Setting interval variables in case this grouping of colors need to change within the same requirements */
$cautionColor = $bhrColors.warning2;
$warnColor = $bhrColors.error2;
$grayColor = $bhrColors.gray8;

// .StorageLimit
.StorageLimit {
	// .StorageLimit button
	button {
		// .StorageLimit button.button--link
		&.button--link {
			background: none;
			border: none;
			color: $colors.link;
			font-size: 12px;
			margin-left: 10px;
			padding: 0;

			.button--text {
				position: relative;
			}

			// .StorageLimit button.button--link:hover
			&:hover {
				cursor: pointer;
				text-decoration: underline;
			}
		}
	}

	// .StorageLimit__labelIcon
	&__labelIcon {
		// .StorageLimit__labelIcon .StorageLimit__icon
		.StorageLimit__icon {
			margin-right: 5px;
		}
	}

	// .StorageLimit__percentage
	&__percentage {
		display: none;
		font-family: Source Sans Pro;
		font-size: 14px;
		line-height: 14px;
		position: absolute;
		top: 0;
		left: 160px;
	}

	// .StorageLimit__progressBar
	&__progressBar {
		position: relative;
	}

	// .StorageLimit--requested
	&--requested {
		// .StorageLimit--requested button.button--link
		button.button--link {
			color: $grayColor;

			// .StorageLimit--requested button.button--link:hover
			&:hover {
				cursor: default;
				text-decoration: none;
			}
		}

		// .StorageLimit--caution.StorageLimit--requested,
		// .StorageLimit--warn.StorageLimit--requested,
		// .StorageLimit--full.StorageLimit--requested
		~/--caution&,
		~/--warn&,
		~/--full& {
			// .StorageLimit--caution.StorageLimit--requested .StorageLimit__progressBar,
			// .StorageLimit--warn.StorageLimit--requested .StorageLimit__progressBar,
			// .StorageLimit--full.StorageLimit--requested .StorageLimit__progressBar
			.StorageLimit__progressBar {
				// .StorageLimit--caution.StorageLimit--requested .StorageLimit__progressBar .ProgressBar,
				// .StorageLimit--warn.StorageLimit--requested .StorageLimit__progressBar .ProgressBar,
				// .StorageLimit--full.StorageLimit--requested .StorageLimit__progressBar .ProgressBar
				.ProgressBar {
					animation: none;
				}
			}
		}
	}

	// .StorageLimit--caution,
	// .StorageLimit--warn,
	// .StorageLimit--full
	&--caution,
	&--warn,
	&--full {
		// .StorageLimit--caution .StorageLimit__progressBar,
		// .StorageLimit--warn .StorageLimit__progressBar,
		// .StorageLimit--full .StorageLimit__progressBar
		.StorageLimit__progressBar {
			// .StorageLimit--caution .StorageLimit__progressBar .ProgressBar,
			// .StorageLimit--warn .StorageLimit__progressBar .ProgressBar,
			// .StorageLimit--full .StorageLimit__progressBar .ProgressBar
			.ProgressBar {
				animation-name: pulse;
				animation-iteration-count: infinite;
			}
		}
		// .StorageLimit--caution .StorageLimit__percentage,
		// .StorageLimit--warn .StorageLimit__percentage,
		// .StorageLimit--full .StorageLimit__percentage
		.StorageLimit__percentage {
			display: block;
		}
	}

	// .StorageLimit--caution
	&--caution {
		// .StorageLimit--caution .StorageLimit__header
		.StorageLimit__header {
			color: $cautionColor !important;
			font-size: 14px;
		}
		// .StorageLimit--caution .StorageLimit__labelIcon
		.StorageLimit__labelIcon {
			// .StorageLimit--caution .StorageLimit__labelIcon svg
			svg {
				fill: $cautionColor;
			}
		}
		// .StorageLimit--caution .StorageLimit__progressBar
		.StorageLimit__progressBar {
			// .StorageLimit--caution .StorageLimit__progressBar .ProgressBar
			.ProgressBar {
				animation-duration: 2s;
			}
		}
		// .StorageLimit--caution .StorageLimit__percentage
		.StorageLimit__percentage {
			color: $cautionColor;
		}
	}

	// .StorageLimit--warn,
	// .StorageLimit--full
	&--warn,
	&--full {
		// .StorageLimit--warn .StorageLimit__header,
		// .StorageLimit--full .StorageLimit__header
		.StorageLimit__header {
			color: $warnColor !important;
			font-size: 14px;
		}
		// .StorageLimit--warn .StorageLimit__labelIcon,
		// .StorageLimit--full .StorageLimit__labelIcon
		.StorageLimit__labelIcon {
			// .StorageLimit--warn .StorageLimit__labelIcon svg,
			// .StorageLimit--full .StorageLimit__labelIcon svg
			svg {
				fill: $warnColor;
			}
		}
		// .StorageLimit--warn .StorageLimit__percentage,
		// .StorageLimit--full .StorageLimit__percentage
		.StorageLimit__percentage {
			color: $warnColor;
		}
	}

	// .StorageLimit--warn
	&--warn {
		// .StorageLimit--warn .StorageLimit__progressBar
		.StorageLimit__progressBar {
			// .StorageLimit--warn .StorageLimit__progressBar .ProgressBar
			.ProgressBar {
				animation-duration: 1.25s;
			}
		}
	}

	// .StorageLimit--full
	&--full {
		// .StorageLimit--full .StorageLimit__progressBar
		.StorageLimit__progressBar {
			// .StorageLimit--full .StorageLimit__progressBar .ProgressBar
			.ProgressBar {
				animation-name: pulse;
				animation-duration: .75s;
				animation-iteration-count: infinite;
			}
		}
	}
}

// .StorageLimitModal
.StorageLimitModal {
	// .StorageLimitModal__icon
	&__icon {
		fill: $grayColor;
		margin-bottom: 15px;
		text-align: center;
	}
	// .StorageLimitModal__text
	&__text {
		color: $bhrColors.gray10;
		font-size: 17px;
		line-height: 24px;
		max-width: 387px;
		text-align: center;
	}
}

@keyframes pulse {
    0%   { opacity: 1 }
    50% { opacity: 0.4 }
    100% { opacity: 1 }
}
