.JobTitleEEOCategories {
	margin-top: -48px;
	position: absolute;
	right: 0;

	&Button {
		&--link,
		&--link:active,
		&--link:focus,
		&--link:hover,
		&--link:visited, {
			font-size: 15px;
		}
	}
}
