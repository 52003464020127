
	/* jquery.Jcrop.min.css v0.9.12 (build:20130126) */
	.jcrop-holder {
		direction: ltr;
		text-align: left;
	}
	.jcrop-vline, .jcrop-hline {
		background: #FFF url('https://staticfe.bamboohr.com/resources/images/Jcrop.gif');
		font-size: 0;
		position: absolute;
	}
	.jcrop-vline {
		height: 100%;
		width: 1px !important;
	}
	.jcrop-vline.right {
		right: 0;
	}
	.jcrop-hline {
		height: 1px !important;
		width: 100%;
	}
	.jcrop-hline.bottom {
		bottom: 0;
	}
	.jcrop-tracker {
		-webkit-tap-highlight-color: transparent;
		-webkit-touch-callout: none;
		-webkit-user-select: none;
		height: 100%;
		width: 100%;
	}
	.jcrop-handle {
		background-color: #333;
		border: 1px #EEE solid;
		font-size: 1px;
		height: 7px;
		width: 7px;
	}
	.jcrop-handle.ord-n {
		left: 50%;
		margin-left: -4px;
		margin-top: -4px;
		top: 0;
	}
	.jcrop-handle.ord-s {
		bottom: 0;
		left: 50%;
		margin-bottom: -4px;
		margin-left: -4px;
	}
	.jcrop-handle.ord-e {
		margin-right: -4px;
		margin-top: -4px;
		right: 0;
		top: 50%;
	}
	.jcrop-handle.ord-w {
		left: 0;
		margin-left: -4px;
		margin-top: -4px;
		top: 50%;
	}
	.jcrop-handle.ord-nw {
		left: 0;
		margin-left: -4px;
		margin-top: -4px;
		top: 0;
	}
	.jcrop-handle.ord-ne {
		margin-right: -4px;
		margin-top: -4px;
		right: 0;
		top: 0;
	}
	.jcrop-handle.ord-se {
		bottom: 0;
		margin-bottom: -4px;
		margin-right: -4px;
		right: 0;
	}
	.jcrop-handle.ord-sw {
		bottom: 0;
		left: 0;
		margin-bottom: -4px;
		margin-left: -4px;
	}
	.jcrop-dragbar.ord-n, .jcrop-dragbar.ord-s {
		height: 7px;
		width: 100%;
	}
	.jcrop-dragbar.ord-e, .jcrop-dragbar.ord-w {
		height: 100%;
		width: 7px;
	}
	.jcrop-dragbar.ord-n {
		margin-top: -4px;
	}
	.jcrop-dragbar.ord-s {
		bottom: 0;
		margin-bottom: -4px;
	}
	.jcrop-dragbar.ord-e {
		margin-right: -4px;
		right: 0;
	}
	.jcrop-dragbar.ord-w {
		margin-left: -4px;
	}
	.jcrop-light .jcrop-vline, .jcrop-light .jcrop-hline {
		background: #FFF;
		filter: alpha(opacity = 70) !important;
		opacity: .70 !important;
	}
	.jcrop-light .jcrop-handle {
		-moz-border-radius: 3px;
		-webkit-border-radius: 3px;
		background-color: #000;
		border-color: #FFF;
		border-radius: 3px;
	}
	.jcrop-dark .jcrop-vline, .jcrop-dark .jcrop-hline {
		background: #000;
		filter: alpha(opacity = 70) !important;
		opacity: .7 !important;
	}
	.jcrop-dark .jcrop-handle {
		-moz-border-radius: 3px;
		-webkit-border-radius: 3px;
		background-color: #FFF;
		border-color: #000;
		border-radius: 3px;
	}
	.solid-line .jcrop-vline, .solid-line .jcrop-hline {
		background: #FFF;
	}
	.jcrop-holder img, img.jcrop-preview {
		max-width: none;
	}
.Tab {
  position: relative;
  display: inline-block;
  padding: 2px 5px 0;
  border: solid 1px;
  border-bottom: none;
}

	.Tab--select {
		padding: 4px 5px 6px 5px;
		min-width: 150px;
		font-size: 15px;
		background: -moz-linear-gradient(top, #f2f2f2 0%, #fcfcfc 100%); /* FF3.6+ */
		background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #f2f2f2), color-stop(100%, #fcfcfc)); /* Chrome,Safari4+ */
		background: -webkit-linear-gradient(top, #f2f2f2 0%, #fcfcfc 100%); /* Chrome10+,Safari5.1+ */
		background: -o-linear-gradient(top, #f2f2f2 0%, #fcfcfc 100%); /* Opera 11.10+ */
		background: -ms-linear-gradient(top, #f2f2f2 0%, #fcfcfc 100%); /* IE10+ */
		background: linear-gradient(to bottom, #f2f2f2 0%, #fcfcfc 100%); /* W3C */
		filter: progid: DXImageTransform . Microsoft . gradient(startColorstr = '#f2f2f2', endColorstr = '#fcfcfc', GradientType = 0  ); /* IE6-9 */
		border-color: #787878;
		border-radius:  2px 2px 0 0;
		box-shadow: inset 0 1px 2px 0 rgba(141, 141, 141, .5);
	}
.Tab--select:after {
  content: '';
  position: absolute;
  top: 12px;
  right: 8px;
  display: block;
  border: solid transparent 5px;
  border-top-color: #777;
}
.Tab--select + .ScrollBox {
  border-top-left-radius: 0;
}
.Multiplex {
	background-color: #FBFBFB;
	border: 1px solid #CBCBCB;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	border-radius: 3px;
	box-sizing: border-box;
	height: 300px;
	overflow-y: auto;
	width: 330px;

	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.Multiplex--noBorders {
	border: none;
}

.Multiplex--notRounded {
	-webkit-border-radius: 0;
	-moz-border-radius: 0;
	border-radius: 0;
}

.Multiplex__list {
	background-color: #FFF;
	cursor: default;
	font-family: 'Source Sans Pro', Arial, Helvetica, sans-serif;
	font-size: 14px;
	margin: 0;
	padding: 0;

	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.Multiplex__list .Multiplex__item {
	list-style-type: none;
	margin: 0;
	padding: 0;
}

.Multiplex__list .Multiplex__item--selected {
	color: white;
	background-color: #4d95ff;
}

.Multiplex__list .Multiplex__item--disabled {
	color: #aaa;
}

.Multiplex__btn {
	border: 1px solid #CBCBCB;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	border-radius: 3px;
	box-sizing: border-box;
	height: 26px;
	margin-bottom: 4px;
	width: 46px;
}

.Multiplex__btn:hover {
	background: rgb(255,255,255); /* Old browsers */
	background: -moz-linear-gradient(top,  #FFFFFF 0%, #F4F4F4 100%); /* FF3.6+ */
	background: -webkit-linear-gradient(top,  #FFFFFF 0%, #F4F4F4 100%); /* Chrome10+,Safari5.1+ */
	background: -ms-linear-gradient(top,  #FFFFFF 0%, #F4F4F4 100%); /* IE10+ */
	background: linear-gradient(to bottom,  #FFFFFF 0%, #F4F4F4 100%); /* W3C */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#F4F4F4',GradientType=0 ); /* IE6-9 */
	border-color: #BBB;
}

.Multiplex__btn:active {
	background: rgb(255,255,255); /* Old browsers */
	background: -moz-linear-gradient(top,  #F4F4F4 0%,  #FFFFFF 100%); /* FF3.6+ */
	background: -webkit-linear-gradient(top,  #F4F4F4 0%, #FFFFFF 100%); /* Chrome10+,Safari5.1+ */
	background: -ms-linear-gradient(top,  #F4F4F4 0%, #FFFFFF 100%); /* IE10+ */
	background: linear-gradient(to bottom,  #F4F4F4 0%, #FFFFFF 100%); /* W3C */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#F4F4F4', endColorstr='#FFFFFF',GradientType=0 ); /* IE6-9 */
	border-color: #AAA;
}

.Multiplex__btn:before {
	border: solid #333;
	border-width: 0 3px 3px 0;
	content: "\00a0";
	display: block;
	height: 5px;
	position: relative;
	top: 50%;
	left: 37%;
	-webkit-transform: translate(-50%, -50%) rotate(-45deg);
	-moz-transform: translate(-50%, -50%) rotate(-45deg);
	-ms-transform: translate(-50%, -50%) rotate(-45deg);
	-o-transform: translate(-50%, -50%) rotate(-45deg);
	transform: translate(-50%, -50%) rotate(-45deg);
	width: 5px;

	-webkit-border-radius: 1px;
	-moz-border-radius: 1px;
	border-radius: 1px;
}

.Multiplex__btn:after {
	border: solid #333;
	border-width: 0 3px 3px 0;
	content: "\00a0";
	display: block;
	height: 5px;
	position: relative;
	top: 16%;
	left: 55%;
	-webkit-transform: translate(-50%, -50%) rotate(-45deg);
	-moz-transform: translate(-50%, -50%) rotate(-45deg);
	-ms-transform: translate(-50%, -50%) rotate(-45deg);
	-o-transform: translate(-50%, -50%) rotate(-45deg);
	transform: translate(-50%, -50%) rotate(-45deg);
	width: 5px;

	-webkit-border-radius: 1px;
	-moz-border-radius: 1px;
	border-radius: 1px;
}

.Multiplex__btn.Multiplex__btn--one:before {
	left: 45%;
}

.Multiplex__btn.Multiplex__btn--one:after {
	content: none;
}

.Multiplex__btn.Multiplex__btn--left:before {
	top: 50%;
	left: 43%;
	-webkit-transform: translate(-50%, -50%) rotate(135deg);
	-moz-transform: translate(-50%, -50%) rotate(135deg);
	-ms-transform: translate(-50%, -50%) rotate(135deg);
	-o-transform: translate(-50%, -50%) rotate(135deg);
	transform: translate(-50%, -50%) rotate(135deg);
}

.Multiplex__btn.Multiplex__btn--left:after {
	top: 16%;
	left: 61%;
	-webkit-transform: translate(-50%, -50%) rotate(135deg);
	-moz-transform: translate(-50%, -50%) rotate(135deg);
	-ms-transform: translate(-50%, -50%) rotate(135deg);
	-o-transform: translate(-50%, -50%) rotate(135deg);
	transform: translate(-50%, -50%) rotate(135deg);
}

.Multiplex__btn.Multiplex__btn--left.Multiplex__btn--one:before {
	left: 52%;
}

.Multiplex__btn.Multiplex__btn--left.Multiplex__btn--one:after {
	content: none;
}

.FileListModal__subHeader {
  height: 36px;
  margin-top: 20px;
  padding: 16px;
  background-color: #f4f4f4;
  border-bottom: solid #e0e0e0 1px;
}
.FilesList__count {
  color: #555;
  line-height: var(--line-height-teenie, 18px);
  font-size: var(--font-size-teenie, 13px);
  font-weight: var(--font-weight-regular, 400);
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -o-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -o-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  height: 33px;
}
.FileList {
  width: 100%;
}
.FileListModal .chzn-container {
  margin-top: 2px;
}
.FileList__row {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  border-bottom: solid #e0e0e0 1px;
  margin: 0;
  padding: 0 16px;
  height: 64px;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.FileList__row:hover {
  background: #f4f4f4;
  cursor: pointer;
}
.FileList__row--active,
.FileList__row--active:hover {
  background: var(--theme-lightest10-default, rgba(192,233,79,0.1));
  background: var(--fabric-theme-lightest10, rgba(192,233,79,0.1));
  background: var(--fabric-theme-lightest10, rgba(192,233,79,0.1));
}
.FileList__row--active .FileList__actions {
  opacity: 1;
  -ms-filter: none;
  filter: none;
  -webkit-transition: opacity 0.15s linear;
  -moz-transition: opacity 0.15s linear;
  -o-transition: opacity 0.15s linear;
  -ms-transition: opacity 0.15s linear;
  transition: opacity 0.15s linear;
  visibility: visible;
}
.FileList__row--active .FileList__info-title {
  color: #222;
  font-weight: var(--font-weight-semibold, 600);
}
.FileList__row--disabled {
  cursor: default !important;
}
.FileList__row--disabled:hover {
  background: transparent;
}
.FileList__row--disabled > div {
  opacity: 0.4;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=40)";
  filter: alpha(opacity=40);
}
.FileList__row--disabled label {
  z-index: 9999;
}
.FileList__check {
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  width: 35px;
}
.FileList__check > label {
  width: 16px;
}
.FileList__icon {
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  margin-right: 5px;
  padding: 1px 0;
  width: 27px;
}
.FileList__info {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  min-width: 0;
}
.FileList__info-title,
.FileList__info-info {
  display: block;
  line-height: var(--line-height-teenie, 18px);
}
.FileList__info-title {
  color: #555;
  font-size: var(--font-size-medium, 15px);
}
.FileList__info-info {
  font-size: var(--font-size-teenie, 13px);
  color: #686868;
}
.FileList__actions {
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  margin-left: 10px;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  visibility: hidden;
  width: 38px;
}
.FileList--scrollable {
  overflow-y: auto;
/*set height for specific instance*/
}
.FileList--minimal .FileList__icon {
  margin-right: 5px;
}
.FileList--minimal .FileList__info {
  width: 90%;
}
.FileList--minimal .FileList__info-info {
  color: #777;
  font-size: var(--font-size-teenie, 13px);
  margin-top: -3px;
}
.FileList--minimal .FileList__row {
  padding: 0 0 17px;
}
.FileList--minimal .FileList__row:last-child {
  padding-bottom: 0;
}
.FileList--minimal .FileList__row,
.FileList--minimal .FileList__row:hover {
  background: transparent;
  border: none;
}
.FileList--minimal .FileList__info-title {
  display: inline-block;
  margin-bottom: 0;
  position: relative;
  top: -2px;
  width: 100%;
}
.ba-privateContent {
  color: transparent;
  display: inline-block;
  position: relative;
  width: 100%;
}
body:not([data-feature-toggles~="jade"]) .ba-privateContent:before {
  content: '';
  position: absolute;
  width: 32px;
  height: 10px;
  left: 0;
  background-image: url("data:image/svg+xml,%20%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20width%3D%2232%22%20height%3D%2210%22%20viewBox%3D%220%200%2032%2010%22%3E%3Cellipse%20cx%3D%225%22%20cy%3D%225%22%20rx%3D%224%22%20ry%3D%224%22%20fill%3D%22%23444%22/%3E%3Cellipse%20cx%3D%2227%22%20cy%3D%225%22%20rx%3D%224%22%20ry%3D%224%22%20fill%3D%22%23A1A1A1%22/%3E%3Cellipse%20cx%3D%2216%22%20cy%3D%225%22%20rx%3D%224%22%20ry%3D%224%22%20fill%3D%22%23737373%22/%3E%3C/svg%3E");
  -webkit-background-size: 32px 10px;
  -moz-background-size: 32px 10px;
  background-size: 32px 10px;
  background-repeat: no-repeat;
}
.ba-privateContent:after {
  color: #222;
  content: attr(data-show-text);
  display: inline-block;
  position: absolute;
  left: 36px;
  font-size: 15px;
  line-height: 20px;
}
body[data-feature-toggles~="jade"] .ba-privateContent:after {
  color: var(--gray9, #444);
  font-size: var(--font-size-teenie, 13px);
  line-height: var(--line-height-teenie, 18px);
}
.ba-privateContent:before,
.ba-privateContent:after {
/* @startCleanup encore */
  top: 50%;
/* @endCleanup encore */
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
:where(body[data-fabric-theme~="encore"]) .ba-privateContent:before,
:where(body[data-fabric-theme~="encore"]) .ba-privateContent:after {
  top: 40%;
}
.ba-privateContent:hover {
  cursor: default;
}
.ba-privateContent:hover:after {
  cursor: pointer;
}
.ba-privateContent--icon {
  display: none;
}
.ba-privateContent .ba-privateContent--icon {
  fill: var(--gray7, #686868);
  display: -webkit-inline-box;
  display: -moz-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-box;
  display: inline-flex;
/* @startCleanup encore */
  position: absolute;
  top: calc(50% - 3px);
/* @endCleanup encore */
}
:where(body[data-fabric-theme~="encore"]) .ba-privateContent .ba-privateContent--icon {
  position: inherit;
  top: unset;
}
.FilesWrapper {
  margin-bottom: 13px;
}
.FilesWrapper__links {
  margin-left: 5px;
  font-size: 13px;
  line-height: 1em;
}
.FilesWrapper__title,
label.FilesWrapper__title {
  margin-bottom: 4px;
  line-height: 1em;
}
.FilesWrapper__files {
  margin-top: 13px;
}
.FilesWrapper__files:empty {
  display: none;
}
.FilesWrapper__file {
  margin-bottom: 3px;
  line-height: 1;
}
.FilesWrapper__file--template {
  display: none;
}
.FilesWrapper__file-icon,
.FilesWrapper__file-name,
.FilesWrapper__file-action {
  display: inline-block;
  vertical-align: middle;
}
.FilesWrapper__file-icon {
  margin-right: 4px;
}
.FilesWrapper__file-name {
  margin-right: 3px;
  line-height: 16px;
}
.FilesWrapper__BlankState {
  border-top: none;
  position: absolute;
  top: 38px;
  width: 100%;
}
.FileListModal .FilesWrapper__BlankState {
  position: static;
}
.FilesWrapper__BlankState .blankState__image {
  margin-bottom: 12px;
}
.FilesWrapper__BlankState .blankState__subText--large {
  margin: 0 75px;
}
.ba-CheckboxCard {
  display: inline-block;
  position: relative;
  border: 1px solid var(--theme-base-default, #527a00);
  border: 1px solid var(--fabric-theme-base, #527a00);
  border: 1px solid var(--fabric-theme-base, #527a00);
  border-radius: 2px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
}
:where(body[data-fabric-theme~="encore"]) .ba-CheckboxCard {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -o-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  color: var(--gray8, #555);
  fill: var(--gray7, #686868);
  -webkit-box-shadow: 1px 1px 0 2px rgba(56,49,47,0.031);
  box-shadow: 1px 1px 0 2px rgba(56,49,47,0.031);
  width: 220px;
  height: 145px;
  padding: 20px !important;
  border-radius: 16px;
  -webkit-box-pack: start;
  -moz-box-pack: start;
  -o-box-pack: start;
  -ms-flex-pack: start;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
  -webkit-box-align: start;
  -moz-box-align: start;
  -o-box-align: start;
  -ms-flex-align: start;
  -webkit-align-items: flex-start;
  align-items: flex-start;
  gap: 35px;
}
input[type="checkbox"] + .ba-CheckboxCard,
input[type="radio"] + .ba-CheckboxCard,
.BhrForms input[type="checkbox"] + .ba-CheckboxCard,
.BhrForms input[type="radio"] + .ba-CheckboxCard {
  padding: 0;
}
body input[type="checkbox"]:focus + .ba-CheckboxCard:before,
body input[type="radio"]:focus + .ba-CheckboxCard:before,
body .BhrForms input[type="checkbox"]:focus + .ba-CheckboxCard:before,
body .BhrForms input[type="radio"]:focus + .ba-CheckboxCard:before {
  border-radius: 100%;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.ba-CheckboxCard:before,
input[type="checkbox"] + .ba-CheckboxCard:before,
input[type="checkbox"]:checked + .ba-CheckboxCard:before,
input[type="radio"] + .ba-CheckboxCard:before,
input[type="radio"]:checked + .ba-CheckboxCard:before {
  content: "";
  position: absolute;
  bottom: -1px;
  right: -1px;
  margin: 0;
  height: 21px;
  width: 21px;
  display: none;
  background: none;
  background-color: var(--theme-base-default, #527a00);
  background-color: var(--fabric-theme-base, #527a00);
  border: 3px solid var(--theme-base-default, #527a00);
  border: 3px solid var(--fabric-theme-base, #527a00);
  border: 3px solid var(--fabric-theme-base, #527a00);
  border-radius: 4px 1px 2px 1px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
:where(body[data-fabric-theme~="encore"]) .ba-CheckboxCard:before,
:where(body[data-fabric-theme~="encore"]) input[type="checkbox"] + .ba-CheckboxCard:before,
:where(body[data-fabric-theme~="encore"]) input[type="checkbox"]:checked + .ba-CheckboxCard:before,
:where(body[data-fabric-theme~="encore"]) input[type="radio"] + .ba-CheckboxCard:before,
:where(body[data-fabric-theme~="encore"]) input[type="radio"]:checked + .ba-CheckboxCard:before {
  background: var(--gray1, #f5f4f1);
  border: 1px solid var(--gray4, #c6c2bf);
  gap: 0;
  border-radius: 4px;
  -webkit-box-shadow: 1px 1px 0 1px rgba(56,49,47,0.051) inset;
  box-shadow: 1px 1px 0 1px rgba(56,49,47,0.051) inset;
  display: none;
  bottom: 20px;
  right: 20px;
  left: unset;
  top: unset;
  height: 16px;
  width: 16px;
}
:where(body[data-fabric-theme~="encore"]) input[type="checkbox"]:checked + .ba-CheckboxCard:before,
:where(body[data-fabric-theme~="encore"]) input[type="radio"]:checked + .ba-CheckboxCard:before {
  border: none;
  background-color: var(--theme-base-default, #527a00);
  background-color: var(--fabric-theme-base, #527a00);
}
.ba-CheckboxCard--no-description {
  display: -webkit-inline-box;
  display: -moz-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-box;
  display: inline-flex;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -o-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -o-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}
.ba-CheckboxCard__icon--no-description {
  text-align: center;
}
.ba-CheckboxCard:after {
  content: "";
  position: absolute;
  bottom: 8px;
  right: 4px;
  -webkit-transform: rotate(-50deg);
  -moz-transform: rotate(-50deg);
  -o-transform: rotate(-50deg);
  -ms-transform: rotate(-50deg);
  transform: rotate(-50deg);
  width: 9px;
  height: 4px;
  display: none;
  background: none;
  border: 2px solid #fff;
  border-top: none;
  border-right: none;
}
:where(body[data-fabric-theme~="encore"]) .ba-CheckboxCard:after {
  bottom: 22px;
  right: 21px;
  border: none;
  width: 12px;
  height: 12px;
  -webkit-transform: none;
  -moz-transform: none;
  -o-transform: none;
  -ms-transform: none;
  transform: none;
  background-image: url("data:image/svg+xml,%3Csvg%20xmlns=%22http://www.w3.org/2000/svg%22%20fill=%22white%22%20viewBox=%220%200%20448%20512%22%3E%3Cpath%20d=%22M438.6%20105.4c12.5%2012.5%2012.5%2032.8%200%2045.3l-256%20256c-12.5%2012.5-32.8%2012.5-45.3%200l-128-128c-12.5-12.5-12.5-32.8%200-45.3s32.8-12.5%2045.3%200L160%20338.7%20393.4%20105.4c12.5-12.5%2032.8-12.5%2045.3%200z%22/%3E%3C/svg%3E");
  background-repeat: no-repeat;
}
input[type="checkbox"]:not(:checked) + .ba-CheckboxCard,
input[type="radio"]:not(:checked) + .ba-CheckboxCard {
  border-color: var(--gray5, #adadad);
}
:where(body[data-fabric-theme~="encore"]) input[type="checkbox"]:not(:checked) + .ba-CheckboxCard,
:where(body[data-fabric-theme~="encore"]) input[type="radio"]:not(:checked) + .ba-CheckboxCard {
  border-color: var(--gray2, #ebebeb);
}
input[type="checkbox"]:checked + .ba-CheckboxCard,
input[type="radio"]:checked + .ba-CheckboxCard {
  background: none;
  -webkit-box-shadow: 0 0 1px 1px var(--theme-lightest-default, #c0e94f);
  box-shadow: 0 0 1px 1px var(--theme-lightest-default, #c0e94f);
  box-shadow: 0 0 1px 1px var(--fabric-theme-lightest, #c0e94f);
  -webkit-box-shadow: 0 0 1px 1px var(--fabric-theme-lightest, #c0e94f);
  box-shadow: 0 0 1px 1px var(--fabric-theme-lightest, #c0e94f);
}
:where(body[data-fabric-theme~="encore"]) input[type="checkbox"]:checked + .ba-CheckboxCard,
:where(body[data-fabric-theme~="encore"]) input[type="radio"]:checked + .ba-CheckboxCard {
  background-color: var(--white);
  color: var(--theme-base-default, #527a00);
  color: var(--fabric-theme-base, #527a00);
  fill: var(--theme-base-default, #527a00);
  fill: var(--fabric-theme-base, #527a00);
}
:where(body[data-fabric-theme~="encore"]) input[type="checkbox"]:checked + .ba-CheckboxCard .ba-CheckboxCard__activeIcon,
:where(body[data-fabric-theme~="encore"]) input[type="radio"]:checked + .ba-CheckboxCard .ba-CheckboxCard__activeIcon {
  display: block;
}
:where(body[data-fabric-theme~="encore"]) input[type="checkbox"]:checked + .ba-CheckboxCard .ba-CheckboxCard__icon,
:where(body[data-fabric-theme~="encore"]) input[type="radio"]:checked + .ba-CheckboxCard .ba-CheckboxCard__icon {
  display: none;
}
input[type="checkbox"]:checked + .ba-CheckboxCard:before,
input[type="radio"]:checked + .ba-CheckboxCard:before,
input[type="checkbox"]:checked + .ba-CheckboxCard:after,
input[type="radio"]:checked + .ba-CheckboxCard:after {
  display: block;
}
.ba-CheckboxCard:hover:before,
input[type="checkbox"] + .ba-CheckboxCard:hover:before,
input[type="radio"] + .ba-CheckboxCard:hover:before,
.ba-CheckboxCard:hover:after,
input[type="checkbox"] + .ba-CheckboxCard:hover:after,
input[type="radio"] + .ba-CheckboxCard:hover:after {
  display: block;
}
.ba-CheckboxCard:hover,
input[type="checkbox"]:not(:checked) + .ba-CheckboxCard:hover,
input[type="radio"]:not(:checked) + .ba-CheckboxCard:hover {
  background: none;
}
.ba-CheckboxCard:hover:before,
input[type="checkbox"]:not(:checked) + .ba-CheckboxCard:hover:before,
input[type="radio"]:not(:checked) + .ba-CheckboxCard:hover:before,
.ba-CheckboxCard:hover:after,
input[type="checkbox"]:not(:checked) + .ba-CheckboxCard:hover:after,
input[type="radio"]:not(:checked) + .ba-CheckboxCard:hover:after {
  display: block;
}
.ba-CheckboxCard:hover:before,
input[type="checkbox"]:not(:checked) + .ba-CheckboxCard:hover:before,
input[type="radio"]:not(:checked) + .ba-CheckboxCard:hover:before {
  background-color: var(--gray6, #999);
  border: 3px solid #999;
}
:where(body[data-fabric-theme~="encore"]) .ba-CheckboxCard:hover:before,
:where(body[data-fabric-theme~="encore"]) input[type="checkbox"]:not(:checked) + .ba-CheckboxCard:hover:before,
:where(body[data-fabric-theme~="encore"]) input[type="radio"]:not(:checked) + .ba-CheckboxCard:hover:before {
  border: 1px solid #999;
}
input[type="checkbox"]:checked + .ba-CheckboxCard.ba-CheckboxCard--no-description,
input[type="radio"]:checked + .ba-CheckboxCard.ba-CheckboxCard--no-description,
input[type="checkbox"]:checked + .ba-CheckboxCard.ba-CheckboxCard--no-description p,
input[type="radio"]:checked + .ba-CheckboxCard.ba-CheckboxCard--no-description p {
  color: var(--theme-base-default, #527a00);
  color: var(--fabric-theme-base, #527a00);
  font-weight: var(--font-weight-bold, 700);
}
.ba-CheckboxCard__label {
  font-weight: var(--font-weight-medium, 500);
}
.ba-CheckboxCard__activeIcon {
  display: none;
}
.ATSSectionWrapperEncore {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  gap: 32px;
}
.ATSEmailWrapper {
  margin: 0px;
  padding: 0px;
}
.ATSEmail {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  margin: -1px auto 0;
  position: relative;
  min-height: 60vh;
}
:where(body[data-fabric-theme~="encore"]) .ATSEmail {
  min-height: 0;
}
.ATSEmail__Editor {
  width: calc(100% - 358px);
  padding: 8px 0 35px;
}
:where(body[data-fabric-theme~="encore"]) .ATSEmail__Editor {
  padding: 0px 0px 0px 0px;
}
.ATSEmail__Editor--reply {
  width: calc(100% - 2px);
}
.ATSEmail__Editor--settings {
  width: calc(100% - 358px);
}
:where(body[data-fabric-theme~="encore"]) .ATSEmail__Editor--settings {
  width: 100%;
}
.ATSEmail__Editor__legend {
  position: relative;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  margin: 25px 0 15px;
  width: 100%;
}
:where(body[data-fabric-theme~="encore"]) .ATSEmail__Editor__legend {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  margin: 0px 0px 24px 0px;
  gap: 16px;
}
.ATSEmail__Editor__legendMessage {
  margin: 0 10px;
  min-width: 100px;
}
:where(body[data-fabric-theme~="encore"]) .ATSEmail__Editor__legendMessage {
  margin: 0px 0px 0px 0px;
}
.ATSEmail__Editor__legendNote {
  font-size: var(--font-size-small, 14px);
  line-height: var(--line-height-small, 20px);
  color: var(--gray7, #686868);
}
.ATSEmail__Editor__legendLink {
  position: absolute;
  right: 5px;
  top: -5px;
}
:where(body[data-fabric-theme~="encore"]) .ATSEmail__Editor__legendLink {
  top: 10px;
}
.ATSEmail__Editor__legendIcon {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  width: 40px;
  height: 40px;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -o-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  background-color: #f4f4f4;
  border-radius: 15px;
}
.ATSEmail__Editor-tools {
  padding-bottom: 11px;
}
:where(body[data-fabric-theme~="encore"]) .ATSEmail__Editor-tools {
  padding-bottom: 24px;
}
.ATSEmail__Editor-cronofyAttach {
  display: inline-block;
}
.ATSEmail__Editor-toolbar {
  height: 33px;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  -webkit-transition: opacity 0.3s;
  -moz-transition: opacity 0.3s;
  -o-transition: opacity 0.3s;
  -ms-transition: opacity 0.3s;
  transition: opacity 0.3s;
  overflow: visible;
}
.ATSEmail__Editor-toolbar .mce-panel {
  display: block !important;
  margin-top: -2px;
  background: none;
}
.ATSEmail .mceWrapper--message {
  padding: 0 0 24px 24px;
  position: relative;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  min-width: 400px;
  overflow-x: hidden;
  overflow-y: hidden;
}
.ATSEmail .mceWrapper--message .mceEditor {
  min-height: 300px;
  padding: 5px 20px 25px 0;
}
.ATSEmail .mceWrapper--message--offerTemplate .PlaceholderWidget__scroll {
  height: calc(100vh - 120px);
}
.ATSEmail .mceWrapper--message--sendEmail .PlaceholderWidget__scroll {
  height: calc(100% - 42px);
}
.ATSEmail__SideColumn {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  background-color: #f3f3f3;
  border-left: 1px solid #dfdfdf;
  height: 100%;
  margin: 0;
  padding: 0 0 10px 0;
  position: absolute;
  right: -32px;
  width: 358px;
}
:where(body[data-fabric-theme~="encore"]) .ATSEmail__SideColumn {
  border: none;
  position: relative;
  right: 0;
  text-align: left;
  padding: 0;
}
.ATSEmail__SideColumn--sendEmail {
  right: 0;
}
.ATSEmail__Editor--ats .mceWrapper--subject {
  margin-right: 0;
  position: relative;
  top: 1px;
  height: 52px;
  font-weight: 600;
  overflow: hidden;
}
.ATSEmail__Editor--ats .mceWrapper--subject:before {
  background-color: var(--gray4, #ccc);
  bottom: -1px;
  content: '';
  height: 2px;
  position: absolute;
  right: 0;
  width: 100%;
  z-index: 0;
}
.ATSEmail__Editor--ats .mceWrapper--subject .mceEditor {
  border-top: 1px #ccc solid;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  height: 71px;
  margin-top: -1px;
  min-width: 400px;
  overflow-x: auto;
  padding: 16px 23px 0;
  white-space: nowrap;
}
.ATSEmail__Editor--ats .mceWrapper--subject .mceEditor:before {
  margin-top: 0;
}
.ATSEmail__Editor--ats .mceWrapper--subject:hover .mceEditor {
  border-color: #adadad;
}
.ATSEmail__Editor--ats .mceWrapper--error:not(.mceWrapper--focused) {
  border: solid #bd5800 1px;
}
.ATSEmail__Editor--ats .mceWrapper--error:not(.mceWrapper--focused) .mceEditor {
  border-top-color: transparent;
}
.ATSEmail__Editor--ats .mceWrapper--error:not(.mceWrapper--focused):before {
  background-color: transparent;
}
.ATSEmail__Editor--ats .FileCards__status {
  color: var(--gray7, #686868);
  font-size: var(--font-size-teenie, 13px);
  line-height: var(--line-height-teenie, 18px);
  margin: 0;
  padding: 4px 0 10px 0;
  margin-bottom: -24px;
}
.ATS__sendEmail-container .ATSEmail__Editor--ats .mceWrapper--message {
  top: 164px;
}
.PlaceholderWidget {
  height: 100%;
  background-color: var(--gray1, #f4f4f4);
}
:where(body[data-fabric-theme~="encore"]) .PlaceholderWidget {
  font-family: 'Inter', sans-serif;
  overflow-y: hidden;
  height: 100%;
  background-color: var(--gray05);
}
.PlaceholderWidget__scroll {
  height: 100%;
  overflow-y: auto;
}
.PlaceholderWidget__scroll.fixed {
  height: calc(100vh - 120px);
  position: fixed;
  top: 22px;
  width: 358px;
}
:where(body[data-fabric-theme~="encore"]) .PlaceholderWidget__scroll.fixed {
  position: sticky;
  top: 108px;
}
.PlaceholderWidget__list {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.PlaceholderWidget__listEmpty,
.PlaceholderWidget__listHeader,
.PlaceholderWidget__listFilterWrap,
.PlaceholderWidget__listItem {
  position: relative;
}
.PlaceholderWidget__listEmpty:after,
.PlaceholderWidget__listHeader:after,
.PlaceholderWidget__listFilterWrap:after,
.PlaceholderWidget__listItem:after {
  content: '';
  background-color: #e5e5e5;
  height: 1px;
  width: calc(100% - 8px);
  position: absolute;
  left: 5px;
  bottom: 0;
}
:where(body[data-fabric-theme~="encore"]) .PlaceholderWidget__listEmpty:after,
:where(body[data-fabric-theme~="encore"]) .PlaceholderWidget__listHeader:after,
:where(body[data-fabric-theme~="encore"]) .PlaceholderWidget__listFilterWrap:after,
:where(body[data-fabric-theme~="encore"]) .PlaceholderWidget__listItem:after {
  width: 100%;
  left: 0;
}
.PlaceholderWidget__listSearch {
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.PlaceholderWidget__listHeader {
  min-width: 240px;
  padding: 15px 0 0 12px;
  margin: 0 23px 3px 20px;
}
:where(body[data-fabric-theme~="encore"]) .PlaceholderWidget__listHeader {
  margin: 0;
  padding: 0 32px 24px 32px;
}
.PlaceholderWidget__listHeaderTitle {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  top: 1px;
}
:where(body[data-fabric-theme~="encore"]) .PlaceholderWidget__listHeaderTitle {
  margin: 0;
}
.PlaceholderWidget__listHeaderTitleIcon {
  margin-right: 5px;
  fill: var(--gray7, #686868);
}
:where(body[data-fabric-theme~="encore"]) .PlaceholderWidget__listHeaderTitleIcon {
  margin-right: 12px;
  height: 20px;
}
.PlaceholderWidget__listHeaderText {
  color: var(--gray7, #686868);
  font-size: var(--font-size-small, 14px);
  line-height: var(--line-height-small, 20px);
}
:where(body[data-fabric-theme~="encore"]) .PlaceholderWidget__listHeaderText {
  font-size: var(--font-size-medium, 15px);
  margin-top: 16px;
}
.PlaceholderWidget__listHeader:after {
  bottom: -10px;
}
:where(body[data-fabric-theme~="encore"]) .PlaceholderWidget__listHeader:after {
  bottom: 0;
}
.PlaceholderWidget__listFilter--muteFocusOnLoad {
  border-color: #ccc;
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none;
}
.PlaceholderWidget__listFilterWrap {
  margin: 0 23px 0 20px;
  padding: 15px 0 11px 12px;
  position: relative;
}
:where(body[data-fabric-theme~="encore"]) .PlaceholderWidget__listFilterWrap {
  padding: 24px 32px 24px 32px;
  margin: 0;
}
.PlaceholderWidget__listFilterClear {
  display: none;
  fill: var(--gray5, #adadad);
  cursor: pointer;
  position: absolute;
  line-height: 16px;
  right: 6px;
  height: 20px;
  margin-top: 3px;
}
.PlaceholderWidget__listEmpty {
  color: var(--gray7, #686868);
  font-size: var(--font-size-teenie, 13px);
  line-height: var(--line-height-teenie, 18px);
  margin: 0 23px 0 20px;
  padding: 22px 0 24px 12px;
}
:where(body[data-fabric-theme~="encore"]) .PlaceholderWidget__listEmpty {
  margin: 0;
  padding: 16px 32px 16px 32px;
  font-size: var(--font-size-small, 14px);
  line-height: var(--line-height-small, 20px);
}
.PlaceholderWidget__listFooter {
  color: var(--gray7, #686868);
  font-size: var(--font-size-teenie, 13px);
  line-height: var(--line-height-teenie, 18px);
  margin: 0 23px 0 20px;
  padding: 13px 0 27px 12px;
}
:where(body[data-fabric-theme~="encore"]) .PlaceholderWidget__listFooter {
  margin: 0;
  padding: 16px 32px 16px 32px;
  font-size: var(--font-size-medium, 15px);
  line-height: var(--line-height-medium, 22px);
}
.PlaceholderWidget__listFooterTitle {
  font-size: var(--font-size-small, 14px);
  line-height: var(--line-height-small, 20px);
  color: var(--gray8, #555);
  font-weight: 600;
}
:where(body[data-fabric-theme~="encore"]) .PlaceholderWidget__listFooterTitle {
  margin: 0 0 4px 0;
  font-size: var(--font-size-medium, 15px);
  line-height: var(--line-height-medium, 22px);
}
.PlaceholderWidget__listItem {
  position: relative;
  font-size: var(--font-size-small, 14px);
  line-height: var(--line-height-small, 20px);
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin: -1px 19px 0 20px;
  border: 1px solid transparent;
}
:where(body[data-fabric-theme~="encore"]) .PlaceholderWidget__listItem {
  margin: -1px 0 0 0;
  font-size: var(--font-size-medium, 15px);
}
.PlaceholderWidget__listItem:after {
  bottom: -1px;
}
.PlaceholderWidget__listItem:hover:not(.dragEnded) {
  background-color: var(--white, #fff);
  -webkit-box-shadow: 0 1px 2px 0 rgba(0,0,0,0.1);
  box-shadow: 0 1px 2px 0 rgba(0,0,0,0.1);
  border: 1px solid #ccc;
  cursor: pointer;
}
.PlaceholderWidget__listItem:hover:not(.dragEnded):after {
  height: 0;
}
.PlaceholderWidget__listItem:last-of-type:after {
  height: 0;
}
.PlaceholderWidget__listItem-name {
  font-size: var(--font-size-small, 14px);
  line-height: var(--line-height-small, 20px);
  color: var(--gray10, #222);
  font-weight: 600;
  margin-left: 4px;
}
:where(body[data-fabric-theme~="encore"]) .PlaceholderWidget__listItem-name {
  font-size: var(--font-size-medium, 15px);
  line-height: var(--line-height-medium, 22px);
  margin: 0 0 4px 0;
}
.PlaceholderWidget__listItem-example {
  color: var(--gray7, #686868);
  font-size: var(--font-size-teenie, 13px);
  line-height: var(--line-height-teenie, 18px);
  margin-left: 4px;
}
:where(body[data-fabric-theme~="encore"]) .PlaceholderWidget__listItem-example {
  font-size: var(--font-size-small, 14px);
  line-height: var(--line-height-small, 20px);
  margin-left: 0px;
}
.PlaceholderWidget__listItem img {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: calc(100% - 2px);
  height: calc(100% - 1px);
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
}
.PlaceholderWidget__listItem-wrap {
  padding: 11px 7px 11px;
  pointer-events: none;
}
:where(body[data-fabric-theme~="encore"]) .PlaceholderWidget__listItem-wrap {
  padding: 16px 32px 17px 32px;
}
.BhrForms input[type="text"].PlaceholderWidget__listFilter::-ms-clear {
  display: none;
}
.ProcessBar {
  background-color: #f3f3f3;
  border: 1px solid #afafaf;
  border-left: none;
  border-right: none;
  font-family: "BhrHeaderFont", "Trebuchet MS";
  height: 47px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.ProcessBar__list {
  display: block !important;
  float: left;
  margin-left: 21px;
  padding: 11px 29px 11px 0;
}
.ProcessBar__list:last-of-type.ProcessBar--current {
  background: none;
}
.ProcessBar__list:not(.ProcessBar--current) .ProcessBar__step-number {
  background-color: #f3f3f3;
}
.ProcessBar__list:not(.ProcessBar--current) .ProcessBar__step-name {
  color: #888;
}
.ProcessBar__list.ProcessBar--current {
  background: url("https://staticfe.bamboohr.com/resources/images/settings/new_benefit/process-arrow.svg") no-repeat right center;
}
.ProcessBar__list.ProcessBar--complete .ProcessBar__step-number {
  background-color: #bfbfbf;
  border: none;
  color: transparent;
}
.ProcessBar__list.ProcessBar--complete .ProcessBar__step-number:before {
  border: solid #fff;
  border-width: 0 4px 4px 0;
  content: "\00a0";
  display: block;
  height: 10px;
  position: relative;
  top: 44%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%) rotate(45deg);
  -moz-transform: translate(-50%, -50%) rotate(45deg);
  -o-transform: translate(-50%, -50%) rotate(45deg);
  -ms-transform: translate(-50%, -50%) rotate(45deg);
  transform: translate(-50%, -50%) rotate(45deg);
  width: 5px;
  border-radius: 1px;
}
.ProcessBar__step-number {
  border: 1px solid #b3b3b3;
  border-radius: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  color: #888;
  font-size: 15px;
  font-weight: bold;
  width: 25px;
  height: 25px;
  line-height: 25px;
  margin-right: 7px;
  text-align: center;
}
.ProcessBar__list.ProcessBar--current .ProcessBar__step-number {
  border-color: transparent;
  color: #fff;
}
.ProcessBar__step-name {
  height: 25px;
  font-size: 16px;
  line-height: 28px;
}
.ProcessBar__list.ProcessBar--current .ProcessBar__step-name {
  font-weight: bold;
}
.Webhook__header {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-orient: horizontal;
  -moz-box-orient: horizontal;
  -o-box-orient: horizontal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: justify;
  -moz-box-pack: justify;
  -o-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}
.Webhook__dropdown {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-orient: horizontal;
  -moz-box-orient: horizontal;
  -o-box-orient: horizontal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.Webhook__type {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -o-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}
.Webhook__subtype {
  font-size: 14px;
  color: #999;
}
.Webhook__label {
  margin-right: 10px;
  color: #555;
  font-size: 14px;
}
.Webhook__icon {
  margin-left: 5px;
}
.ps-container {
  -ms-touch-action: auto;
  touch-action: auto;
  overflow: hidden !important;
  -ms-overflow-style: none;
}
@supports (-ms-overflow-style: none) {
  .ps-container {
    overflow: auto !important;
  }
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .ps-container {
    overflow: auto !important;
  }
}
.ps-container.ps-active-x > .ps-scrollbar-x-rail,
.ps-container.ps-active-y > .ps-scrollbar-y-rail {
  display: block;
  background-color: transparent;
}
.ps-container.ps-in-scrolling.ps-x > .ps-scrollbar-x-rail {
  background-color: #eee;
  opacity: 0.9;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=90)";
  filter: alpha(opacity=90);
}
.ps-container.ps-in-scrolling.ps-x > .ps-scrollbar-x-rail > .ps-scrollbar-x {
  background-color: #999;
  height: 11px;
}
.ps-container.ps-in-scrolling.ps-y > .ps-scrollbar-y-rail {
  background-color: #eee;
  opacity: 0.9;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=90)";
  filter: alpha(opacity=90);
}
.ps-container.ps-in-scrolling.ps-y > .ps-scrollbar-y-rail > .ps-scrollbar-y {
  background-color: #999;
  width: 11px;
}
.ps-container > .ps-scrollbar-x-rail {
  display: none;
  position: absolute;
/* please don't change 'position' */
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  -webkit-transition: background-color 0.2s linear, opacity 0.2s linear;
  -o-transition: background-color 0.2s linear, opacity 0.2s linear;
  -moz-transition: background-color 0.2s linear, opacity 0.2s linear;
  -webkit-transition: background-color 0.2s linear, opacity 0.2s linear;
  -moz-transition: background-color 0.2s linear, opacity 0.2s linear;
  -o-transition: background-color 0.2s linear, opacity 0.2s linear;
  -ms-transition: background-color 0.2s linear, opacity 0.2s linear;
  transition: background-color 0.2s linear, opacity 0.2s linear;
  bottom: 0px;
/* there must be 'bottom' for ps-scrollbar-x-rail */
  height: 15px;
}
.ps-container > .ps-scrollbar-x-rail > .ps-scrollbar-x {
  position: absolute;
/* please don't change 'position' */
  background-color: #aaa;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  -webkit-transition: background-color 0.2s linear, height 0.2s linear, width 0.2s ease-in-out, -webkit-border-radius 0.2s ease-in-out;
  -webkit-transition: background-color 0.2s linear, height 0.2s linear, width 0.2s ease-in-out, -webkit-border-radius 0.2s ease-in-out;
  -moz-transition: background-color 0.2s linear, height 0.2s linear, width 0.2s ease-in-out, -webkit-border-radius 0.2s ease-in-out;
  -o-transition: background-color 0.2s linear, height 0.2s linear, width 0.2s ease-in-out, -webkit-border-radius 0.2s ease-in-out;
  -ms-transition: background-color 0.2s linear, height 0.2s linear, width 0.2s ease-in-out, -webkit-border-radius 0.2s ease-in-out;
  transition: background-color 0.2s linear, height 0.2s linear, width 0.2s ease-in-out, -webkit-border-radius 0.2s ease-in-out;
  -o-transition: background-color 0.2s linear, height 0.2s linear, width 0.2s ease-in-out, border-radius 0.2s ease-in-out;
  -moz-transition: background-color 0.2s linear, height 0.2s linear, width 0.2s ease-in-out, border-radius 0.2s ease-in-out, -moz-border-radius 0.2s ease-in-out;
  -webkit-transition: background-color 0.2s linear, height 0.2s linear, width 0.2s ease-in-out, border-radius 0.2s ease-in-out;
  -moz-transition: background-color 0.2s linear, height 0.2s linear, width 0.2s ease-in-out, border-radius 0.2s ease-in-out;
  -o-transition: background-color 0.2s linear, height 0.2s linear, width 0.2s ease-in-out, border-radius 0.2s ease-in-out;
  -ms-transition: background-color 0.2s linear, height 0.2s linear, width 0.2s ease-in-out, border-radius 0.2s ease-in-out;
  transition: background-color 0.2s linear, height 0.2s linear, width 0.2s ease-in-out, border-radius 0.2s ease-in-out;
  -webkit-transition: background-color 0.2s linear, height 0.2s linear, width 0.2s ease-in-out, border-radius 0.2s ease-in-out, -webkit-border-radius 0.2s ease-in-out, -moz-border-radius 0.2s ease-in-out;
  -moz-transition: background-color 0.2s linear, height 0.2s linear, width 0.2s ease-in-out, border-radius 0.2s ease-in-out, -webkit-border-radius 0.2s ease-in-out, -moz-border-radius 0.2s ease-in-out;
  -o-transition: background-color 0.2s linear, height 0.2s linear, width 0.2s ease-in-out, border-radius 0.2s ease-in-out, -webkit-border-radius 0.2s ease-in-out, -moz-border-radius 0.2s ease-in-out;
  -ms-transition: background-color 0.2s linear, height 0.2s linear, width 0.2s ease-in-out, border-radius 0.2s ease-in-out, -webkit-border-radius 0.2s ease-in-out, -moz-border-radius 0.2s ease-in-out;
  transition: background-color 0.2s linear, height 0.2s linear, width 0.2s ease-in-out, border-radius 0.2s ease-in-out, -webkit-border-radius 0.2s ease-in-out, -moz-border-radius 0.2s ease-in-out;
  bottom: 2px;
/* there must be 'bottom' for ps-scrollbar-x */
  height: 6px;
}
.ps-container > .ps-scrollbar-x-rail:hover > .ps-scrollbar-x,
.ps-container > .ps-scrollbar-x-rail:active > .ps-scrollbar-x {
  height: 11px;
}
.ps-container > .ps-scrollbar-y-rail {
  display: none;
  position: absolute;
/* please don't change 'position' */
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  -webkit-transition: background-color 0.2s linear, opacity 0.2s linear;
  -o-transition: background-color 0.2s linear, opacity 0.2s linear;
  -moz-transition: background-color 0.2s linear, opacity 0.2s linear;
  -webkit-transition: background-color 0.2s linear, opacity 0.2s linear;
  -moz-transition: background-color 0.2s linear, opacity 0.2s linear;
  -o-transition: background-color 0.2s linear, opacity 0.2s linear;
  -ms-transition: background-color 0.2s linear, opacity 0.2s linear;
  transition: background-color 0.2s linear, opacity 0.2s linear;
  right: 0;
/* there must be 'right' for ps-scrollbar-y-rail */
  width: 15px;
}
.ps-container > .ps-scrollbar-y-rail > .ps-scrollbar-y {
  position: absolute;
/* please don't change 'position' */
  background-color: #aaa;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  -webkit-transition: background-color 0.2s linear, height 0.2s linear, width 0.2s ease-in-out, -webkit-border-radius 0.2s ease-in-out;
  -webkit-transition: background-color 0.2s linear, height 0.2s linear, width 0.2s ease-in-out, -webkit-border-radius 0.2s ease-in-out;
  -moz-transition: background-color 0.2s linear, height 0.2s linear, width 0.2s ease-in-out, -webkit-border-radius 0.2s ease-in-out;
  -o-transition: background-color 0.2s linear, height 0.2s linear, width 0.2s ease-in-out, -webkit-border-radius 0.2s ease-in-out;
  -ms-transition: background-color 0.2s linear, height 0.2s linear, width 0.2s ease-in-out, -webkit-border-radius 0.2s ease-in-out;
  transition: background-color 0.2s linear, height 0.2s linear, width 0.2s ease-in-out, -webkit-border-radius 0.2s ease-in-out;
  -o-transition: background-color 0.2s linear, height 0.2s linear, width 0.2s ease-in-out, border-radius 0.2s ease-in-out;
  -moz-transition: background-color 0.2s linear, height 0.2s linear, width 0.2s ease-in-out, border-radius 0.2s ease-in-out, -moz-border-radius 0.2s ease-in-out;
  -webkit-transition: background-color 0.2s linear, height 0.2s linear, width 0.2s ease-in-out, border-radius 0.2s ease-in-out;
  -moz-transition: background-color 0.2s linear, height 0.2s linear, width 0.2s ease-in-out, border-radius 0.2s ease-in-out;
  -o-transition: background-color 0.2s linear, height 0.2s linear, width 0.2s ease-in-out, border-radius 0.2s ease-in-out;
  -ms-transition: background-color 0.2s linear, height 0.2s linear, width 0.2s ease-in-out, border-radius 0.2s ease-in-out;
  transition: background-color 0.2s linear, height 0.2s linear, width 0.2s ease-in-out, border-radius 0.2s ease-in-out;
  -webkit-transition: background-color 0.2s linear, height 0.2s linear, width 0.2s ease-in-out, border-radius 0.2s ease-in-out, -webkit-border-radius 0.2s ease-in-out, -moz-border-radius 0.2s ease-in-out;
  -moz-transition: background-color 0.2s linear, height 0.2s linear, width 0.2s ease-in-out, border-radius 0.2s ease-in-out, -webkit-border-radius 0.2s ease-in-out, -moz-border-radius 0.2s ease-in-out;
  -o-transition: background-color 0.2s linear, height 0.2s linear, width 0.2s ease-in-out, border-radius 0.2s ease-in-out, -webkit-border-radius 0.2s ease-in-out, -moz-border-radius 0.2s ease-in-out;
  -ms-transition: background-color 0.2s linear, height 0.2s linear, width 0.2s ease-in-out, border-radius 0.2s ease-in-out, -webkit-border-radius 0.2s ease-in-out, -moz-border-radius 0.2s ease-in-out;
  transition: background-color 0.2s linear, height 0.2s linear, width 0.2s ease-in-out, border-radius 0.2s ease-in-out, -webkit-border-radius 0.2s ease-in-out, -moz-border-radius 0.2s ease-in-out;
  right: 2px;
/* there must be 'right' for ps-scrollbar-y */
  width: 6px;
}
.ps-container > .ps-scrollbar-y-rail:hover > .ps-scrollbar-y,
.ps-container > .ps-scrollbar-y-rail:active > .ps-scrollbar-y {
  width: 11px;
}
.ps-container:hover.ps-in-scrolling.ps-x > .ps-scrollbar-x-rail {
  background-color: #eee;
  opacity: 0.9;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=90)";
  filter: alpha(opacity=90);
}
.ps-container:hover.ps-in-scrolling.ps-x > .ps-scrollbar-x-rail > .ps-scrollbar-x {
  background-color: #999;
  height: 11px;
}
.ps-container:hover.ps-in-scrolling.ps-y > .ps-scrollbar-y-rail {
  background-color: #eee;
  opacity: 0.9;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=90)";
  filter: alpha(opacity=90);
}
.ps-container:hover.ps-in-scrolling.ps-y > .ps-scrollbar-y-rail > .ps-scrollbar-y {
  background-color: #999;
  width: 11px;
}
.ps-container:hover > .ps-scrollbar-x-rail,
.ps-container:hover > .ps-scrollbar-y-rail {
  opacity: 0.6;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=60)";
  filter: alpha(opacity=60);
}
.ps-container:hover > .ps-scrollbar-x-rail:hover {
  background-color: #eee;
  opacity: 0.9;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=90)";
  filter: alpha(opacity=90);
}
.ps-container:hover > .ps-scrollbar-x-rail:hover > .ps-scrollbar-x {
  background-color: #999;
}
.ps-container:hover > .ps-scrollbar-y-rail:hover {
  background-color: #eee;
  opacity: 0.9;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=90)";
  filter: alpha(opacity=90);
}
.ps-container:hover > .ps-scrollbar-y-rail:hover > .ps-scrollbar-y {
  background-color: #999;
}
.PreviewEmail {
  padding-top: 0;
}
.PreviewEmail-header {
  margin-bottom: 32px;
  padding-bottom: 16px;
  border-bottom: solid 1px var(--gray2);
}
.PreviewEmail-header_address {
  font-size: var(--font-size-small, 14px);
  line-height: var(--line-height-small, 20px);
  color: var(--gray7, #686868);
  font-weight: 700;
}
.PreviewEmail-header_address--light {
  font-weight: 500;
}
.PreviewEmail table tr {
  background-color: transparent;
}
.PreviewEmail table.baseBgColor {
  background-color: var(--theme-base-default, #527a00);
  background-color: var(--fabric-theme-base, #527a00);
}
.PreviewEmail table .baseBgColor {
  background-color: var(--theme-base-default, #527a00);
  background-color: var(--fabric-theme-base, #527a00);
}
.PreviewEmail table .baseBorderColor {
  background-color: var(--theme-base-default, #527a00);
  background-color: var(--fabric-theme-base, #527a00);
}
.PreviewEmail .missingPlaceholder {
  color: var(--danger, #c20b0b);
}
.OfferLetter__draftPlaceholder {
  font-family: 'Lato';
  font-size: var(--font-size-teenie, 13px);
  line-height: var(--line-height-teenie, 18px);
  font-weight: var(--font-weight-bold, 700);
  color: var(--gray8, #555);
  display: inline-block;
  background-color: var(--gray3, #e0e0e0);
  padding: 0 4px;
  border-radius: 2px;
}
.OLPreview__wrapper {
  background-color: var(--gray2, #ebebeb);
  padding-bottom: 40px;
  background-color: var(--gray05, #f6f6f4);
  padding: 0;
}
.OLPreview__wrapperForm {
  max-width: 1348px;
  margin: 0 auto;
  -webkit-box-shadow: 0 8px 14px 3px rgba(0,0,0,0.05);
  box-shadow: 0 8px 14px 3px rgba(0,0,0,0.05);
  max-width: 1348px;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.OLPreview__wrapper--view {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
  background-color: var(--white);
  min-width: 100%;
  max-width: 100%;
  min-height: 100%;
  margin: 0;
  padding-top: 40px;
  margin-bottom: -20px;
}
.OLPreview__letter {
  padding: 10px 110px 40px;
  -webkit-box-shadow: 0 8px 14px 3px rgba(0,0,0,0.05);
  box-shadow: 0 8px 14px 3px rgba(0,0,0,0.05);
  background-color: var(--white, #fff);
  -webkit-box-shadow: 0 0 0 0 rgba(0,0,0,0);
  box-shadow: 0 0 0 0 rgba(0,0,0,0);
  background-color: var(--gray05, #f6f6f4);
  padding: 0;
  margin: 0 40px;
  min-width: calc(100% - 80px);
}
.OLPreview__letter--encoreContainer {
  background-color: var(--white, #fff);
  border-radius: var(--radius-600);
  max-width: 944px;
  margin: 40px auto 60px auto;
  min-width: 700px;
  padding: 32px;
}
.OLPreview__header {
  margin-bottom: 43px;
  padding: 29px 0 36px;
  text-align: center;
  position: relative;
  margin-bottom: 40px;
  padding: 24px 0 32px;
}
.OLPreview__header-date {
  margin-top: 17px;
  font-size: var(--font-size-small, 14px);
  line-height: var(--line-height-small, 20px);
  color: var(--gray6);
  text-transform: uppercase;
  letter-spacing: 0.3px;
}
.OLPreview__content {
  font-size: var(--font-size-large, 16px);
  line-height: var(--line-height-large, 24px);
}
.OLPreview__section {
  padding-bottom: 22px;
  padding-bottom: 24px;
}
.OLPreview__section:not(:last-of-type) {
  position: relative;
  margin-bottom: 35px;
  margin-bottom: 40px;
}
.OLPreview__section:not(:last-of-type):after {
  content: '';
  background-image: -webkit-linear-gradient(0deg, #c0e94f 0, #82af13 100%);
  background-image: -moz-linear-gradient(0deg, #c0e94f 0, #82af13 100%);
  background-image: -o-linear-gradient(0deg, #c0e94f 0, #82af13 100%);
  background-image: -ms-linear-gradient(0deg, #c0e94f 0, #82af13 100%);
  background-image: linear-gradient(90deg, #c0e94f 0, #82af13 100%);
  background-image: -webkit-linear-gradient(0deg, var(--fabric-theme-lightest, #c0e94f) 0, var(--fabric-theme-lighter, #82af13) 100%);
  background-image: -moz-linear-gradient(0deg, var(--fabric-theme-lightest, #c0e94f) 0, var(--fabric-theme-lighter, #82af13) 100%);
  background-image: -o-linear-gradient(0deg, var(--fabric-theme-lightest, #c0e94f) 0, var(--fabric-theme-lighter, #82af13) 100%);
  background-image: -ms-linear-gradient(0deg, var(--fabric-theme-lightest, #c0e94f) 0, var(--fabric-theme-lighter, #82af13) 100%);
  background-image: linear-gradient(90deg, var(--fabric-theme-lightest, #c0e94f) 0, var(--fabric-theme-lighter, #82af13) 100%);
  height: 2px;
  position: absolute;
  bottom: -2px;
  left: 0;
  right: 0;
  background-image: none;
  background-color: var(--gray2);
}
.OLPreview__section-header {
  margin: 0;
  margin-bottom: 16px;
}
.OLPreview__section-note {
  color: var(--gray7, #686868);
  font-size: var(--font-size-small, 14px);
  line-height: var(--line-height-small, 20px);
}
.OLPreview__section--FileCards {
  margin-top: 24px;
}
.OLPreview__section--flex {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
}
.OLPreview__acceptance {
  width: 60%;
}
.OLPreview__acceptance-tagline {
  margin-bottom: 21px;
  margin-top: 5px;
  margin-bottom: 20px;
  margin-top: 4px;
}
.OLPreview__signatureField,
.OLPreview__dateField {
  border-radius: 2px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
  position: relative;
  border-radius: var(--radius-200);
}
.OLPreview__signatureFieldWrapper,
.OLPreview__dateFieldWrapper {
  margin-bottom: 12px;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -o-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: baseline;
  -moz-box-align: baseline;
  -o-box-align: baseline;
  -ms-flex-align: baseline;
  -webkit-align-items: baseline;
  align-items: baseline;
}
.OLPreview__signatureFieldWrapper:after,
.OLPreview__dateFieldWrapper:after {
  content: '';
  background-color: var(--gray6, #999);
  position: absolute;
  left: 0;
  height: 1px;
  width: 100%;
  bottom: 24px;
  content: none;
}
.OLPreview__signatureField--complete::placeholder,
.OLPreview__dateField--complete::placeholder {
  color: transparent;
}
.OLPreview__signatureField--complete::-ms-input-placeholder,
.OLPreview__dateField--complete::-ms-input-placeholder {
/* Internet Explorer 10-11 */
  color: transparent;
}
.OLPreview__signatureField--complete:-ms-input-placeholder,
.OLPreview__dateField--complete:-ms-input-placeholder {
/* Microsoft Edge */
  color: transparent;
}
.OLPreview__signatureField {
  -webkit-background-size: contain;
  -moz-background-size: contain;
  background-size: contain;
  background-repeat: no-repeat;
  background-position-y: center;
  background-position-x: 10px;
  -webkit-background-origin: content;
  -moz-background-origin: content;
  background-origin: content-box;
  padding: 3px 8px;
  padding: 4px 8px;
  background-position-x: 0;
}
.OLPreview__signatureWrapper {
  position: relative;
  margin-bottom: 23px;
  width: 286px;
  margin-bottom: 24px;
  color: var(--gray10);
}
.OLPreview__signatureField {
  height: 40px;
  padding: 5px 10px;
  height: var(--height--biggie);
  padding: 4px 8px;
}
.OLPreview__signatureField-canvas {
  background-position: left center;
  background-repeat: no-repeat;
  -webkit-background-size: contain;
  -moz-background-size: contain;
  background-size: contain;
  width: 100%;
  height: 100%;
}
.OLPreview__signatureField.OLPreview__field--disabled .OLPreview__signatureField-placeholder {
  cursor: default;
}
.OLPreview__fieldLabel {
  margin-top: 4px;
  padding-top: 9px;
  color: var(--gray5, #adadad);
  border-top: solid #999 1px;
  padding-top: 8px;
}
.OLPreview__dateWrapper {
  display: inline-block;
  position: relative;
}
.OLPreview__dateField {
  padding: 6px 5px 7px;
  width: 100%;
  font-family: inherit;
  color: var(--gray10);
}
.OLPreview__dateWrapper .ui-datepicker-trigger {
  display: none;
}
.OLPreview__questions {
  width: 40%;
}
.OLPreview__questions-whoToContact {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-orient: horizontal;
  -moz-box-orient: horizontal;
  -o-box-orient: horizontal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-column-gap: 12px;
  -moz-column-gap: 12px;
  column-gap: 12px;
  -webkit-box-align: start;
  -moz-box-align: start;
  -o-box-align: start;
  -ms-flex-align: start;
  -webkit-align-items: start;
  align-items: start;
}


/*------------------------------------*$Settings Styles
styles used Settings Page
\*------------------------------------*/

/*****************************************************************************************************************
* Position Relative
*****************************************************************************************************************/
#settings-page h2 span, .Approvals__level, .Approvals__undo, #settings-left, .settings-sub, #settings-main-content, .settings-sprites {
	position: relative;
}
body.settings footer, body.settings #navMain {
	z-index: 3;
}
.chzn-container.chzn-container-single + p {
	margin-top: 5px;
}
p.description {
	margin-bottom: 19px;
}
#grey-side-bar {
	display: block;
	height: 100%;
	width: 140px;
	background: #ffffff;
	background: -moz-linear-gradient(left, #ffffff 0, #F3F3F3 100%);
	background: -webkit-gradient(linear, left top, right top, color-stop(0, #ffffff), color-stop(100%, #F3F3F3));
	background: -webkit-linear-gradient(left, #ffffff 0, #F3F3F3 100%);
	background: -o-linear-gradient(left, #ffffff 0, #F3F3F3 100%);
	background: -ms-linear-gradient(left, #ffffff 0, #F3F3F3 100%);
	background: linear-gradient(to right, #ffffff 0, #F3F3F3 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#f3f3f3', GradientType=1 );
	position: absolute;
	z-index: 2;
	overflow: hidden;
	left: -140px;
}
#settings-page {
	width: 100%;
}
#settings-page:after {
	content: '';
	position: absolute;
	display: block;
	border-right: 1px solid #CCC;
	bottom: 0;
	top: 0;
	left: 0;
	width: 19.8%;
	min-width: 193px;
	z-index: 2;
}
#settings-page h2 {
	min-height: 30px;
}
#settings-page h2 span {
	display: inline-block;
	top: -1px;
}
#settings-left, .settings-sub, #settings-main-content {
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}
+jade() {
	#settings-page .settings-sub h3, #settings-page #settings-main-content h3 {
		padding-top: 10px;
	}
}
#settings-page .settings-sub h3 {
	padding-left: 22px;
	padding-top:10px;
}
#settings-page h3 > .settings-sprites {
	margin-right: 2px;
	margin-left: -22px;
}
#settings-main-content h3 > .settings-sprites {
	margin-left: 0;
}
#settings-page .dropdown-menu {
	margin-top: 6px;
}
#settings-page .settings-sub ul.settings-second-nav li a span {
	font-size: 13px;
	color: #888;
	font-weight: normal;
	padding-left: 3px;
}
#settings-left {
	display: block;
	height: 100%;
	padding-right: 20px;
	width: 19.8%;
	min-width: 193px;
	float: left;
	z-index: 3;
}
.settings-sub {
	padding-right: 1.59%;
	padding-left: 1.59%;
	background: white;
	float: left;
	height: 100%;
	width: 19%;
	z-index: 1;
	border-right-style: solid;
	border-right-width: 1px;
	left: 0;
	opacity: 0;
	visibility: hidden;
	position: absolute;
}
.settings-sub-wrapper {
	height: inherit;
}
.settings-sub.slideOut {
	left: 225px;
}
#settings-main-content {
	opacity: 0;
	padding-left: 4.4%;
	float: right;
	width: 79.5%;
	box-sizing: border-box;
	-webkit-transition: opacity .3s ease;
	-moz-transition: opacity .3s ease;
	-ms-transition: opacity .3s ease;
	-o-transition: opacity .3s ease;
	transition: opacity .3s ease;
}
#settings-main-content.no-sub-nav {
	width: 79.54%;
}
#settings-left li, .settings-sub li {
	font-size: 15px;
}
#settings-left li {
	padding: .21em 0 .23em;
}
#settings-left li.settings-with-divider {
	padding: .39em 0 .38em;
}
.settings-sub ul:not(.down-list) {
	padding-top: 1px;
}
.settings-sub ul:not(.down-list) li {
	padding: 5px 0 5px;
	line-height: 1.1em;
	margin-left: 10px;
}
.settings-selected {
	font-weight: 600;
}
.settings-action {
	position: relative;
	float: right;
	z-index: 100;
	margin-top: 6px;
}

.BftPlanSettings__buttonWrapper {
	display: flex;
	justify-content: space-between;
}

.settings-poweredit {
	z-index: 0;
}
.with-divider, #settings-left .settings-with-divider {
	border-bottom: 1px solid #AFAFAF;
}
#settings-left .settings-with-divider:last-of-type {
	padding-top: 0.19em;
}
+jade() {
	#settings-main-content h4 {
		padding-top: 6px;
	}
}
#settings-main-content .col1 h4, #settings-main-content #col2 h4 {
	padding-top: 0;
}
#settings-main-content h3 + h4 {
	padding-top: 1px;
}
.settings-second-nav a {
	text-decoration: none;
}
.settings-second-nav a:not(.settings-selected):hover {
	text-decoration: none;
}
.settings-second-nav.with-divider {
	padding-bottom: 5px;
	margin-bottom: 5px;
}
#settings-left .settings-with-divider.account-settings-list {
	padding-bottom: .39em;
}
#settings-left .settings-with-divider.employee-settings-list {
	padding-bottom: .36em;
}
#settings-left .settings-with-divider.timetracking-settings-list {
	padding-top: .36em;
	padding-bottom: .36em;
}
.settingsTimeTrackingLink {
	box-sizing: border-box;
	height: 27px;
}
.settings-top-nav {
	opacity: 0;
	margin-top: 6px;
	-webkit-transition: opacity .3s ease;
	-moz-transition: opacity .3s ease;
	-ms-transition: opacity .3s ease;
	-o-transition: opacity .3s ease;
	transition: opacity .3s ease;
}

#settings-page div.addLinks {
	float: none;
	margin-bottom: 10px;
	max-width: 870px;
	padding-top: 6px;
}
#settings-page h3 + div.addLinks {
	padding-top: 0;
	margin-top: -1px;
}

#showLayout {
	padding: 10px;
	border: 10px solid rgba(135, 196, 62, .9);
	position: fixed;
	right: 0;
	z-index: 2001;
	box-shadow: 5px 5px 5px rgba(0, 0, 0, .5);
	border-radius: 3px;
	font-family: 'comic sans ms';
	cursor: pointer;
	color: #424242;
	text-shadow: 0 0 1px rgba(255, 255, 255, 0.8);
}
#showLayout.show:before {
	content: 'Hide';
}
#showLayout:before {
	content: 'Show';
}
#layout {
	display: none;
	opacity: .5;
	position: absolute;
	left: 0;
	top: 0;
	z-index: 2000;
	cursor: move;
	box-shadow: 0 0 15px black;
}
#layout.ui-draggable-dragging {
	border: none !important;
	padding: 0;
}
#layout.layout50 {
	opacity: .5;
	display: block;
}
#layout.layout100 {
	opacity: 1;
	display: block;
}

/** custom approval form wizard **/
.settingsMainContentForm {
	margin-bottom: 35px;
}
.settingsMainContentForm #report-wizard {
	margin-left: 0;
}
.settingsMainContentForm span.inlineAjaxError {
	color: #b42f06;
	font-size: 13px;
	left: 5px;
	position: relative;
	top: 3px;
}
.settingsMainContentForm .js_ca_name_wrapper.js_error label {
	color: #b42f06;
}
.settingsMainContentForm li:hover .js_ca_name_wrapper.js_error label:before {
	content: url('https://staticfe.bamboohr.com/resources/images/bicons/field-error.png');
	display: inline-block;
	margin: 0 3px 0 0;
}
.settingsMainContentForm .js_ca_name_wrapper.js_error input {
	border-color: #b42f06;
}
.settingsMainContentForm #available .requireToggleSpan, .settingsMainContentForm #selected li.tableField:hover a.config {
	display: none;
}
.settingsMainContentForm #selected .requireToggleSpan {
	float: right;
	padding: 0;
	position: absolute;
	top: 1px;
	right: 25px;
}
.settingsMainContentForm #selected .requireToggleSpan__star {
	background-image: url('https://staticfe.bamboohr.com/resources/images/color-branded-icons/star.png');
	height: 11px;
	position: relative;
	right: 20px;
	top: 10px;
	width: 11px;
}
.settingsMainContentForm #selected .ui-sortable-helper .requireToggleSpan__star {
	background-image: url('https://staticfe.bamboohr.com/resources/images/color-branded-icons/star_drag.png');
}
.settingsMainContentForm__template {
	padding: 15px 0;
}
.settingsMainContentForm__templateList {
	margin: 0;
	padding: 0;
}
.settingsMainContentForm__templateListitem {
	padding: 10px 20px;
	margin: 0 0 4px;
	border: 1px solid #dddddd;
	max-width: 300px;
	box-sizing: border-box;
}
.settingsMainContentForm__templateListitem--link {
	border-top: none;
	border-left: none;
	border-right: none;
	padding: 10px 10px 14px 0;
}
.accountSubPage .note {
	color: #777;
	font-size: 13px;
	margin: 21px 0 25px;
	line-height: 1.2em;
}
.accountSubPage hr {
	border-color: #d4d4d4;
	margin: 20px 0 18px;
}
#settings-main-content .accountSubPage h4 {
	padding-top: 0;
}
.accountSubPage p {
	line-height: 1.3em;
}
.accountSubPage hr + .btn {
	margin-top: 7px;
}

/****************** account info page ***********************/

.AccountInfoSection {
	border-top: 1px solid #D4D4D4;
	padding-top: 15px !important;
	padding-bottom: 30px;
}

.AccountInfoSection:first-of-type {
	border: none;
	margin-top: 0;
	padding-top: 0 !important;
	padding-bottom: 20px;
}

.AccountInfoSection dd {
	padding-bottom: 8px;
}

.AccountInfoSection dd.paddingBottom {
	padding-bottom: 10px;
}

.AccountInfoSection address {
	font-style: normal;
}

.AccountInfoSection__header {
	font-size: 16px;
	font-weight: 400;
	padding-bottom: 8px;
}

.AccountInfoSection--smallFont {
	font-size: 14px;
}

.AccountInfoSection .account-info dd {
	font-size: 16px;
}

#settings-page dl:not(.basics) dt {
	color: #777;
}

.AccountInfoSection--parens {
	color: #999;
}

.AccountInfo__blank {
	color: #999999;
	padding-top: 15px;
	font-size: 14px;
}

.AccountInfo__column--spacer {
	height: 73px;
}

.ProgressBar__parent {
	margin-top: 5px;
	margin-bottom: 3px;
	width: 150px;
	height: 13px;
	border-radius: 25px;
	border-color: #B8B8B8;
	overflow: hidden;
	opacity: 0.8;
}

.ProgressBar__child {
	border-top-left-radius: 25px;
	border-bottom-left-radius: 25px;
}

#settings-page dl:not(.basics) dt.slightUp {
	margin-top: 9px;
}

#settings-page dl:not(.basics) dt.moreUp {
	margin-top: 2px;
}

.AccountOwner__photo {
	height: 32px;
	border-radius: 50%;
	float: left;
	margin-right: 5px;
	margin-top: 2px;
}

.AccountOwner__displayName {
	padding-right: 5px;
}

.Employees__svg {
	display: block;
	float: left;
	height: 15px;
	width: 18px;
	padding-top: 3px;
}

.account-info .AccountInfo--spacer {
	padding-top: 5px;
}

.account-info .BillingInfo {
	border-top: 1px solid #D4D4D4;
	padding-top: 15px !important;
	padding-bottom: 30px;
	margin-top: 35px;
}

.account-info .BillingInfo__name {
	font-size: 16px;
	padding-bottom: 2px;
}

.account-info .BillingInfo__none {
	color: #999999;
	font-size: 16px;
	padding-bottom: 2px;
}
.AccountInfoSection__addOns--tall {
	min-height: 67px;
}
.AccountInfoSection__addOnsDetail {
	margin-bottom: 3px;
}
.AccountInfoSection__addOnsIcons {
	position: relative;
	top: 1px;
	margin-right: 6px;
}
.AccountInfoSection__addOnsIcons--time {
	margin-right: 9px;
}
.account-info .CurrentPackage--tall {
	min-height: 67px;
}

.Trial {
	border: 1px solid #CDCDCD;
	border-radius: 10px;
	padding: 20px;
	width: 50%;
	text-align: center;
	margin-top: 15px;
}

.Trial__text {
	font-size: 20px;
	font-weight: 600;
	padding: 15px 0 15px 0;
}

.Trial__call {
	color: #555555;
	font-size: 18px;
	margin-top: 4px;
  margin-bottom: 20px;
	font-weight: 600;
}

.account-info a {
	font-size: 14px;
}

.CompanyInformation .Address--block {
	display: block;
}

.CompanyInformation {
	font-size: 25px;
	font-weight: 400;
	margin: 0 auto;
	text-align: left;
	max-width: 1140px;
	width: 95%;
	padding: 0;
	position: relative;
	padding-bottom: 50px;
}

.CompanyInformation--accent {
	color: #999999;
}

/*#current-usage {
	border-top: 1px solid #D4D4D4;
	padding-top: 15px;
	padding-bottom: 30px;
	margin-top: 35px;
}*/
#gauge-dial {
	width: 100%;
}
#gauge2 {
	padding: 0 2%;
}
.gauge {
	display: inline-block;
	width: 32%;
	text-align: center;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}
.gauge div {
	font-family: "BhrHeaderFont";
	font-size: 16px;
	text-align: center;
	margin-bottom: 18px;
	font-weight: 600;
}
.col1 {
	width: 40%;
}
.col2 {
	width: 60%;
}
.colSplit {
	width: 50%;
}
.col1, .col2, .colSplit {
	float: left;
	vertical-align: top;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}
#settings-page .account-info dt {
	font-size: 14px;
	font-weight: 400;
	color: #777;
}
.account-info dd {
	font-size: 15px;
	color: #222;
	line-height: 1.2em;
}
span.linkSpan {
	font-size: 13px;
}
#company-expiration {
	text-align: center;
}

.has, .daysLeft {
	font-family: "BhrHeaderFont";
	font-size: 18px;
	font-weight: bold;
}
.has {
	margin-bottom: 8px;
}
.daysLeft {
	margin-top: 4px;
	margin-bottom: 20px;
}
.countdown {
	background: url('https://staticfe.bamboohr.com/resources/images/settings/countdown-2-digits.png') center top no-repeat;
	width: 93px;
	height: 71px;
	margin: auto;
	font-family: 'Source sans pro';
	font-weight: 700;
	font-size: 42px;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	padding-top: 20px;
	padding-left: 13px;
	letter-spacing: 23px;
}
.countdown.singleDigit {
	background: url('https://staticfe.bamboohr.com/resources/images/settings/countdown-1-digit.png') top center no-repeat;
	width: 48px;
}
.or {
	font-size: 13px;
	color: #777;
	margin: 6px 0 5px 0;
}
.chat {
	font-size: 13px;
	display: inline-block;
	font-weight: 600;
}

/********************* end *************************/
/************ onboarding/offboarding draggable ***********/
.ts-list .item.ui-sortable-helper td {
	border-top: solid #d9d9d9 1px;
}
.ts-list .ui-sortable-placeholder {
	background-color: #fcfcfc !important;
	border: dashed #d9d9d9 1px !important;
	border-top: none !important;
}

/** settings icons - moved from buttons.css (KB) **/
.settings-sprites {
	background-image: url("https://staticfe.bamboohr.com/resources/images/color-branded-icons/settings-sprites.png");
	content: "";
	display: inline-block;
	height: 14px;
	width: 20px;
	vertical-align: middle;
	margin-right: 5px;
	background-color: #a8a8a8;
}
.settings-sprites.gears {
	background-position: left top;
	height: 24px;
	width: 24px;
	margin-top: 2px;
	padding-top: -4px;
	margin-right: 5px;
	margin-left: 2px;
	padding-left: -2px;
	vertical-align: baseline;
	border: 0;
}
.settings-sprites.wrench {
	background-position: left -30px;
	height: 14px;
	top: -3px;
	left: 1px;
}
.settings-sprites.wrench.white {
	background-position: left -60px;
}
.settings-sprites.perms {
	background-position: left -90px;
	height: 16px;
	top: -2px;
	left: 2px;
}
.settings-sprites.perms.white {
	background-position: left -120px;
}
.settings-sprites.fields {
	background-position: left -150px;
	height: 16px;
	top: -3px;
}
.settings-sprites.fields.white {
	background-position: left -180px;
}
.settings-sprites.thumbs {
	background-position: left -210px;
	height: 16px;
	top: -3px;
	left: 1px;
}
.settings-sprites.thumbs.white {
	background-position: left -240px;
}
.settings-sprites.heart {
	background-position: left -270px;
	height: 15px;
	top: -3px;
}
.settings-sprites.heart.white {
	background-position: left -300px;
}
.settings-sprites.profile {
	background-position: left -330px;
	height: 15px;
	top: -3px;
	left: 1px;
}
.settings-sprites.profile.white {
	background-position: left -360px;
}
.settings-sprites.alert {
	background-position: left -390px;
	height: 15px;
	top: -4px;
}
.settings-sprites.alert.white {
	background-position: left -420px;
}
.settings-sprites.gift {
	background-position: left -450px;
	height: 15px;
	top: -3px;
	left: 1px;
}
.settings-sprites.gift.white {
	background-position: left -480px;
}
.settings-sprites.logo {
	background-position: left -510px;
	height: 15px;
	top: -3px;
}
.settings-sprites.logo.white {
	background-position: left -540px;
}
.settings-sprites.onboard {
	background-position: left -570px;
	height: 15px;
	top: -3px;
}
.settings-sprites.onboard.white {
	background-position: left -600px;
}
.settings-sprites.offboard {
	background-position: left -630px;
	height: 17px;
	top: -3px;
}
.settings-sprites.offboard.white {
	background-position: left -660px;
}
.settings-sprites.timeoff {
	background-position: left -690px;
	height: 15px;
	top: -2px;
}
.settings-sprites.timeoff.white {
	background-position: left -720px;
}
.settings-sprites.training {
	background-position: left -750px;
	height: 15px;
	top: -3px;
	left: -1px;
}
.settings-sprites.training.white {
	background-position: left -780px;
}
.settings-sprites.applicant_statuses {
	background-position: left -810px;
	height: 19px;
	top: -2px;
	left: 2px;
}
.settings-sprites.applicant_statuses.white {
	background-position: left -840px;
}
h3 .settings-sprites.active {
	top: 1px;
	vertical-align: baseline;
}
h3 .settings-sprites.active.perms {
	top: 0;
}

.settings-sprites.performance_dial {
	background-position: left -870px;
	height: 16px;
	top: -2px;
	left: -1px;
}
.settings-sprites.performance_dial.white { background-position:left -900px; }

h3 .settings-sprites.active { top: 1px; vertical-align: baseline; }
h3 .settings-sprites.active.perms { top: 0; }

.timeTrackingSettingsIcon {
	margin: -2px 5px 2px 0;
	vertical-align: top;
	fill: #777;
	color: #a8a8a8;
}

.settings-selected .timeTrackingSettingsIcon {
	color: #777;
}

.timeTrackingSubNavIcon {
	color: #777;
	margin: 0 9px 0 -22px;
	position: relative;
	top: 3px;
}

.fieldBox.valignTop {
	vertical-align: top;
	margin-top: 9px;
}
#settings-page {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.settingsMainContentForm__templateList {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -o-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  gap: 12px;
}
.settingsMainContentForm__templateListitem {
  padding: 8px 16px;
  border-radius: 8px;
}
.settingsMainContentForm__templateListitem--link {
  border: none;
  margin-top: 8px;
  margin-bottom: 20px;
}
#settings-page {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  padding-bottom: 40px;
}
#settings-page #settings-main-content .blankState h3 {
  padding-top: 0;
}
.settings-top-nav {
  margin-top: 0;
}
.settings-second-nav {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -o-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  gap: 4px;
}
.settings-sub {
  border: none;
}
.settings-second-nav {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -o-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  gap: 4px;
}
.settings-second-nav.with-divider {
  padding-bottom: 16px;
  margin-bottom: 0;
}
.section-inner-content {
  width: 100%;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
}
.section-header {
  display: none;
}
.section-header__divider {
  margin-top: 16px;
}
.settings-main-wrapper {
  -webkit-box-flex: 2;
  -moz-box-flex: 2;
  -o-box-flex: 2;
  -ms-box-flex: 2;
  box-flex: 2;
  -webkit-flex-grow: 2;
  flex-grow: 2;
  min-width: 0;
}
.settings-section {
  min-width: 0;
  padding-top: 20px;
}
#settings-page {
  padding-bottom: unset;
}
.SettingsNav__itemLink {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  min-height: 27px;
}
.SettingsNav__itemIcon {
  display: inline;
/* .SettingsNav__itemIcon--enps */
}
.SettingsNav__itemIcon--enps {
  margin-right: 6px;
}
.SettingsNav__itemText {
  vertical-align: top;
/* .SettingsNav__itemText--payroll */
}
.SettingsNav__itemText--payroll {
  margin-left: -2px;
}
.PayrollSubNav__icon {
  color: #777;
  margin: 0 9px 0 -22px;
  position: relative;
  top: 3px;
}
.SettingsApps__navIcon {
  fill: #a8a8a8;
  margin-left: 0;
  margin-right: 7px;
}
.ApprovalsSettingsNav__icon {
  fill: #939393;
  margin: -2px 5px 2px 0;
  vertical-align: top;
}
.ApprovalsSettingsNav:not(.settings-selected) .ApprovalsSettingsNav__icon {
  color: #b1b1b1;
}
.accountSubPage .note {
  color: var(--gray7, #686868);
  font-size: var(--font-size-teenie, 13px);
  line-height: var(--line-height-teenie, 18px);
}
#deleteForm .Modal__separator {
  margin: 19px 0 18px;
}
#settings-main-content {
  float: left;
  padding-top: 0;
  position: relative;
  z-index: 2;
  padding-left: 0;
  width: 100%;
  background-color: #fff;
  padding-bottom: 0px;
}
#settings-left {
  padding: 8px 24px;
  padding: 0;
}
#settings-left,
#settings-page:after {
  width: 262px;
  width: 264px;
  min-width: 264px;
  margin-right: 40px;
}
#settings-left li {
  padding: 0;
}
#settings-left li .optionTextWrapper {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
}
#settings-left li .optionTextWrapper .optionText {
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -o-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -o-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}
.settings-sub li a {
  padding-left: 0;
}
.settings-sub ul:not(.down-list) li {
  line-height: var(--line-height-small, 20px);
  padding: 0;
  margin-left: 0;
}
.settings-sub ul:not(.down-list) li a {
  display: block;
  padding: 8px 14px;
  padding: 8px 12px;
}
#settings-left .settings-selected,
.settings-sub .settings-selected a {
  font-weight: var(--font-weight-heavy, 800);
  color: var(--theme-base-default, #527a00);
  color: var(--fabric-theme-base, #527a00);
}
#settings-left .settings-selected .SettingsNav__itemIcon,
.settings-sub .settings-selected a .SettingsNav__itemIcon {
  fill: var(--theme-base-default, #527a00);
  fill: var(--fabric-theme-base, #527a00);
}
#settings-page:after {
  border: none;
}
#settings-page div.addLinks {
  max-width: inherit;
  margin: 12px 0px 16px 0px;
}
#settings-page div.addLinks__addEmailAlert {
  margin: 0 0 0 auto;
  padding-top: 0;
}
#settings-page div.addLinks__payrollAddLinks {
  margin: 24px 0;
  padding-top: 0;
}
#settings-page .TT_SetupBoxEncore {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
}
.settings-sub .settings-selected,
.settings-sub li:hover {
  background-color: #f4f4f4;
  border-radius: 4px;
  border-radius: 8px;
}
.settings-sub li:hover a {
  color: var(--theme-base-default, #527a00);
  color: var(--fabric-theme-base, #527a00);
}
.settingsTimeTrackingLink {
  height: auto;
}
.settings-sub {
  border-right-color: var(--theme-lightest-default, #c0e94f);
  border-right-color: var(--fabric-theme-lightest, #c0e94f);
  padding: 6px 20px 45px;
  width: 225px;
  width: 200px;
  min-width: 200px;
  padding: 0;
  max-width: 223px;
  margin-right: 32px;
}
.SettingsNavPageTitle {
  margin-bottom: 20px;
}
.SettingsNavPageTitle__icon {
  margin-right: 5px;
  position: relative;
  top: 2px;
}
.SettingsNav__itemIconContainer {
  display: -webkit-inline-box;
  display: -moz-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-box;
  display: inline-flex;
  width: 20px;
  height: 22px;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -o-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -o-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}
.SettingsNav__itemText {
  display: -webkit-inline-box;
  display: -moz-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-box;
  display: inline-flex;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -o-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -o-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
/* .SettingsNav__itemText--payroll */
}
.SettingsNav__itemText--payroll {
  margin-left: 0;
}
.SettingsNav__hiddenIcon {
  display: none;
}
.SettingsSubNavTitle {
  margin-left: 17px;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.SettingsSubNavTitleIcon {
  margin-right: 8px;
}
.SettingsSubNavTitleRight {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  -ms-box-flex: 1;
  box-flex: 1;
  -webkit-flex-grow: 1;
  flex-grow: 1;
  text-align: right;
}
.SettingsSubNav__groupLabel {
  color: var(--gray7, #686868);
  font-size: var(--font-size-teenie, 13px);
  line-height: var(--line-height-teenie, 18px);
  margin: 12px 0 1px 17px;
  margin: 16px 0 4px 0;
  color: var(--gray6, #999);
}
.SettingsSubNav__link {
  padding: 8px 12px;
  color: var(--gray8, #555);
  font-size: var(--font-size-small, 14px);
  font-weight: var(--font-weight-regular, 400);
}
.SettingsSubNav__link--active {
  color: var(--theme-base-default, #527a00);
  color: var(--fabric-theme-base, #527a00);
  background-color: #f4f4f4;
  border-radius: 8px;
  font-weight: var(--font-weight-bold, 700);
}
.settingsMainContentForm #selected .subTable .requireToggleSpan .bhrToggle--require .bhrToggle__label:after,
.settingsMainContentForm #selected .subTable .requireToggleSpan .bhrToggleSmall--require .bhrToggle__label:after {
  left: 5px;
}
.settingsMainContentForm #selected .subTable .requireToggleSpan .bhrToggle--require input:checked + .bhrToggle__label:after,
.settingsMainContentForm #selected .subTable .requireToggleSpan .bhrToggleSmall--require input:checked + .bhrToggle__label:after {
  left: -8px;
}
.settingsMainContentForm #selected .requireToggleSpan {
  float: right;
  padding: 0;
  position: absolute;
  top: 4px;
  right: 63px;
}
.settingsMainContentForm #selected .requireToggleSpan .bhrToggle--require .bhrToggle__label:after,
.settingsMainContentForm #selected .requireToggleSpan .bhrToggleSmall--require .bhrToggle__label:after {
  left: -5px;
}
.settingsMainContentForm #selected .requireToggleSpan .bhrToggle--require input:checked + .bhrToggle__label:after,
.settingsMainContentForm #selected .requireToggleSpan .bhrToggleSmall--require input:checked + .bhrToggle__label:after {
  left: -19px;
}
.EditRow {
  background-color: #f4f4f4;
}
.EditRow__note {
  color: #999;
  font-size: var(--font-size-small, 14px);
  line-height: var(--line-height-teenie, 18px);
  margin-left: 16px;
  position: relative;
  bottom: 2px;
}
.EditRow__actions--close {
  position: relative;
  left: -4px;
}
.settings-main-heading {
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
}
.settings-main-heading ba-icon {
  fill: var(--theme-base-default, #527a00);
  fill: var(--fabric-theme-base, #527a00);
  margin-right: 10px;
}
.settings-main-heading--extraMarginBottom {
  margin-bottom: 24px;
}
.settings-main-heading--smallExtraMarginBottom {
  margin-bottom: 19px;
}
.AccountInfoSection {
  position: relative;
}
.AccountInfoSection:first-of-type {
  padding-top: 9px !important;
}
.Trial__text {
  color: var(--theme-base-default, #527a00);
  color: var(--fabric-theme-base, #527a00);
}
.settings-second-nav.with-divider {
  border-color: #e0e0e0;
  border-color: #ebebeb;
}
#settings-page .settings-sub ul.settings-second-nav li a span {
  color: var(--gray7, #686868);
}
.CustomFieldsIcon {
  fill: var(--theme-base-default, #527a00);
  fill: var(--fabric-theme-base, #527a00);
  margin-right: 8px;
}

.legacyModal__portal {
  position: relative;
}
.legacyModal__overlay {
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  bottom: 0;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -o-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -o-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  left: 0;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  position: fixed;
  right: 0;
  top: 0;
  -webkit-transition: opacity 350ms ease;
  -moz-transition: opacity 350ms ease;
  -o-transition: opacity 350ms ease;
  -ms-transition: opacity 350ms ease;
  transition: opacity 350ms ease;
  background-color: #f4f4f4;
}
:where(body[data-fabric-theme~="encore"]) .legacyModal__overlay {
  background-color: rgba(103,98,96,0.9);
}
:where(body[data-fabric-theme~="encore"]) .legacyModal__overlay:has(.LockScreen) {
  background-color: var(--gray1);
}
.legacyModal__overlay.ReactModal__Overlay--after-open {
  opacity: 1;
  -ms-filter: none;
  filter: none;
}
.legacyModal__overlay.ReactModal__Overlay--before-close {
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  -webkit-transition-duration: 250ms;
  -moz-transition-duration: 250ms;
  -o-transition-duration: 250ms;
  -ms-transition-duration: 250ms;
  transition-duration: 250ms;
}
.legacyModal__overlay:before {
  content: '';
  -webkit-box-flex: 45;
  -moz-box-flex: 45;
  -o-box-flex: 45;
  box-flex: 45;
  -webkit-flex: 45;
  -ms-flex: 45;
  flex: 45;
}
.legacyModal__overlay:after {
  content: '';
  -webkit-box-flex: 55;
  -moz-box-flex: 55;
  -o-box-flex: 55;
  box-flex: 55;
  -webkit-flex: 55;
  -ms-flex: 55;
  flex: 55;
}
.legacyModal__modal {
  position: relative;
  -webkit-transform: translateY(-$modalSlideDistance);
  -moz-transform: translateY(-$modalSlideDistance);
  -o-transform: translateY(-$modalSlideDistance);
  -ms-transform: translateY(-$modalSlideDistance);
  transform: translateY(-$modalSlideDistance);
  -webkit-transition: -webkit-transform 350ms ease;
  -moz-transition: -moz-transform 350ms ease;
  -o-transition: -o-transform 350ms ease;
  -ms-transition: -ms-transform 350ms ease;
  transition: transform 350ms ease;
  width: 608px;
}
:where(body[data-fabric-theme~="encore"]) .legacyModal__modal {
  background-color: #fff;
  border-radius: var(--radius-400);
  padding: 4px;
}
.legacyModal__modal.ReactModal__Content--after-open {
  -webkit-transform: none;
  -moz-transform: none;
  -o-transform: none;
  -ms-transform: none;
  transform: none;
}
.legacyModal__modal.ReactModal__Content--before-close {
  -webkit-transform: translateY(20px);
  -moz-transform: translateY(20px);
  -o-transform: translateY(20px);
  -ms-transform: translateY(20px);
  transform: translateY(20px);
  -webkit-transition-duration: 250ms;
  -moz-transition-duration: 250ms;
  -o-transition-duration: 250ms;
  -ms-transition-duration: 250ms;
  transition-duration: 250ms;
}
.legacyModal__modalLoader {
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  bottom: 0;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -o-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
:where(body[data-fabric-theme~="encore"]) .legacyModal__modal--loading {
  background-color: transparent;
}
.legacyModal__title {
  margin-bottom: 10px;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
  -webkit-transition: opacity 350ms ease, -webkit-transform 350ms ease;
  -moz-transition: opacity 350ms ease, -moz-transform 350ms ease;
  -o-transition: opacity 350ms ease, -o-transform 350ms ease;
  -ms-transition: opacity 350ms ease, -ms-transform 350ms ease;
  transition: opacity 350ms ease, transform 350ms ease;
}
.legacyModal__title--hidden {
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  -webkit-transform: translateY(-$modalSlideDistance);
  -moz-transform: translateY(-$modalSlideDistance);
  -o-transform: translateY(-$modalSlideDistance);
  -ms-transform: translateY(-$modalSlideDistance);
  transform: translateY(-$modalSlideDistance);
}
:where(body[data-fabric-theme~="encore"]) .legacyModal__title {
  margin: 0;
}
.legacyModal__title--hasCloseButton {
  padding-right: 40px;
}
.legacyModal__titleCloseButton {
  grid-column-start: 2;
}
.legacyModal__titleGrid {
  display: grid;
  grid-template-columns: 1fr 40px;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  border-top-left-radius: var(--radius-400);
  border-top-right-radius: var(--radius-400);
  padding: 6px 20px 10px;
  background-color: var(--gray05, #fff);
  -webkit-transition: opacity 350ms ease, -webkit-transform 350ms ease;
  -moz-transition: opacity 350ms ease, -moz-transform 350ms ease;
  -o-transition: opacity 350ms ease, -o-transform 350ms ease;
  -ms-transition: opacity 350ms ease, -ms-transform 350ms ease;
  transition: opacity 350ms ease, transform 350ms ease;
}
.legacyModal__titleGrid--hidden {
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  -webkit-transform: translateY(-$modalSlideDistance);
  -moz-transform: translateY(-$modalSlideDistance);
  -o-transform: translateY(-$modalSlideDistance);
  -ms-transform: translateY(-$modalSlideDistance);
  transform: translateY(-$modalSlideDistance);
}
:where(body[data-fabric-theme~="encore"]) .legacyModal__titleGrid {
  padding: 16px 20px;
}
.legacyModal__titleGrid--noTitle {
  background-color: #fff;
  padding-top: 20px;
}
:where(body[data-fabric-theme~="encore"]) .legacyModal__titleGrid--noTitle {
  padding: 16px 20px;
}
.legacyModal__titleGrid--fullScreen {
  background-color: var(--white);
  position: absolute;
  right: 22px;
  top: 10px;
  z-index: 1;
}
.legacyModal__closeButton {
  background: transparent;
  fill: #999;
  padding: 8px;
  position: absolute;
  right: -8px;
  top: 12px;
  background: none;
  border: none;
  cursor: pointer;
  text-decoration: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  -webkit-transition: opacity 350ms ease, -webkit-transform 350ms ease;
  -moz-transition: opacity 350ms ease, -moz-transform 350ms ease;
  -o-transition: opacity 350ms ease, -o-transform 350ms ease;
  -ms-transition: opacity 350ms ease, -ms-transform 350ms ease;
  transition: opacity 350ms ease, transform 350ms ease;
}
.legacyModal__closeButton--hidden {
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  -webkit-transform: translateY(-$modalSlideDistance);
  -moz-transform: translateY(-$modalSlideDistance);
  -o-transform: translateY(-$modalSlideDistance);
  -ms-transform: translateY(-$modalSlideDistance);
  transform: translateY(-$modalSlideDistance);
}
.legacyModal__closeButton:hover {
  fill: #555;
}
.legacyModal__main {
  background-color: #fff;
  border-radius: 2px;
  -webkit-box-shadow: 0 8px 14px 3px rgba(0,0,0,0.05);
  box-shadow: 0 8px 14px 3px rgba(0,0,0,0.05);
  -webkit-transition: opacity 350ms ease, -webkit-transform 350ms ease;
  -moz-transition: opacity 350ms ease, -moz-transform 350ms ease;
  -o-transition: opacity 350ms ease, -o-transform 350ms ease;
  -ms-transition: opacity 350ms ease, -ms-transform 350ms ease;
  transition: opacity 350ms ease, transform 350ms ease;
}
:where(body[data-fabric-theme~="encore"]) .legacyModal__main {
  background-color: transparent;
  border-radius: var(--radius-0);
  -webkit-box-shadow: none;
  box-shadow: none;
}
.legacyModal__main--hidden {
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  -webkit-transform: translateY(-$modalSlideDistance);
  -moz-transform: translateY(-$modalSlideDistance);
  -o-transform: translateY(-$modalSlideDistance);
  -ms-transform: translateY(-$modalSlideDistance);
  transform: translateY(-$modalSlideDistance);
}
.legacyModal__header {
  display: block;
  padding: 32px 48px 0;
  position: relative;
}
.legacyModal__header--none {
  display: none;
}
.legacyModal__header--filled {
  padding: 20px 32px 0;
}
.legacyModal__header--stacked {
  margin-bottom: 8px;
  text-align: center;
}
.legacyModal__header--text {
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  border-bottom: 1px solid #e0e0e0;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  padding: 24px 0 8px;
  margin: 0 32px 24px;
}
:where(body[data-fabric-theme~="encore"]) .legacyModal__header--text {
  padding: 20px 0 12px;
  margin: 0 20px 32px;
}
.legacyModal__header--text .legacyModal__headerIcon {
  margin: 0 8px 0 0;
}
.legacyModal__headerBox {
  background-color: #f4f4f4;
  border-bottom: 1px solid #ccc;
  border-radius: 2px 2px 0 0;
  padding: 12px 16px;
}
.legacyModal__headerIcon {
  fill: #555;
  margin-bottom: 20px;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -o-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}
:where(body[data-fabric-theme~="encore"]) .legacyModal__headerIcon {
  display: block;
  background-color: var(--gray1);
  padding: 20px;
  border-radius: var(--radius-400);
}
.legacyModal__headerIcon--theme {
  fill: var(--theme-base-default, #527a00);
  fill: var(--fabric-theme-base, #527a00);
}
.legacyModal__headerIcon--info {
  fill: var(--info, #0772b3);
}
.legacyModal__headerIcon--danger {
  fill: var(--danger, #c20b0b);
}
.legacyModal__headerIcon--attention {
  fill: var(--attention, #bd5800);
}
.legacyModal__headerIcon--success {
  fill: var(--success, #0e840d);
}
.legacyModal__headerIcon--action {
  fill: var(--action, #ffe30a);
}
.legacyModal__headerContent {
  margin: 0;
}
.legacyModal__content {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  max-height: 64vh;
  overflow: auto;
  position: relative;
  margin: 0 32px;
  padding: 32px 16px;
}
:where(body[data-fabric-theme~="encore"]) .legacyModal__content {
  margin: 0;
  padding: 32px 20px;
}
.legacyModal__content:empty {
  padding-bottom: 24px;
}
.legacyModal__content--noMaxHeight {
  max-height: none;
  overflow: visible;
}
.legacyModal__content--noPadding {
  margin: 0 auto;
  padding: 0 2px;
  width: calc(100% - (64px - (-2px * 2)));
}
:where(body[data-fabric-theme~="encore"]) .legacyModal__content--noPadding {
  padding: 0 2px;
  width: calc(100% - 2px);
}
.legacyModal__header ~ .legacyModal__content {
  padding-top: 0;
}
.legacyModal__footer {
  border-top: 1px solid #ccc;
  background-color: #f4f4f4;
  margin: 0 32px;
  padding: 24px 16px;
  position: relative;
}
:where(body[data-fabric-theme~="encore"]) .legacyModal__footer {
  background: initial;
  border-top: 1px solid var(--gray2);
  margin: 0 20px;
  padding: 20px 0;
}
.legacyModal__actions {
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  padding: 26px 48px 32px;
  position: relative;
}
:where(body[data-fabric-theme~="encore"]) .legacyModal__actions {
  -webkit-box-pack: end;
  -moz-box-pack: end;
  -o-box-pack: end;
  -ms-flex-pack: end;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
  padding: 16px 20px;
}
.legacyModal__actionsContainer {
  background-color: var(--gray05, #fff);
  border-bottom-left-radius: var(--radius-400);
  border-bottom-right-radius: var(--radius-400);
  -webkit-transition: opacity 350ms ease, -webkit-transform 350ms ease;
  -moz-transition: opacity 350ms ease, -moz-transform 350ms ease;
  -o-transition: opacity 350ms ease, -o-transform 350ms ease;
  -ms-transition: opacity 350ms ease, -ms-transform 350ms ease;
  transition: opacity 350ms ease, transform 350ms ease;
}
.legacyModal__actionsContainer--hidden {
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  -webkit-transform: translateY(-$modalSlideDistance);
  -moz-transform: translateY(-$modalSlideDistance);
  -o-transform: translateY(-$modalSlideDistance);
  -ms-transform: translateY(-$modalSlideDistance);
  transform: translateY(-$modalSlideDistance);
}
:where(body[data-fabric-theme~="encore"]) .legacyModal__actions > button {
  margin: 0 0 0 16px;
}
.legacyModal__actions:before {
  background: -webkit-linear-gradient(0deg, #c0e94f 0, #82af13 100%);
  background: -moz-linear-gradient(0deg, #c0e94f 0, #82af13 100%);
  background: -o-linear-gradient(0deg, #c0e94f 0, #82af13 100%);
  background: -ms-linear-gradient(0deg, #c0e94f 0, #82af13 100%);
  background: linear-gradient(90deg, #c0e94f 0, #82af13 100%);
  background: -webkit-linear-gradient(0deg, var(--fabric-theme-lightest, ((#c0e94f))) 0, var(--fabric-theme-lighter, ((#82af13))) 100%);
  background: -moz-linear-gradient(0deg, var(--fabric-theme-lightest, ((#c0e94f))) 0, var(--fabric-theme-lighter, ((#82af13))) 100%);
  background: -o-linear-gradient(0deg, var(--fabric-theme-lightest, ((#c0e94f))) 0, var(--fabric-theme-lighter, ((#82af13))) 100%);
  background: -ms-linear-gradient(0deg, var(--fabric-theme-lightest, ((#c0e94f))) 0, var(--fabric-theme-lighter, ((#82af13))) 100%);
  background: linear-gradient(90deg, var(--fabric-theme-lightest, ((#c0e94f))) 0, var(--fabric-theme-lighter, ((#82af13))) 100%);
  content: "";
  display: block;
  height: 2px;
  left: 32px;
  position: absolute;
  top: 0;
  width: calc(100% - 64px);
}
:where(body[data-fabric-theme~="encore"]) .legacyModal__actions:before {
  content: none;
}
.legacyModal__actionNote {
  color: var(--gray7, #686868);
  margin-left: 12px;
  font-size: var(--font-size-small, 14px);
  line-height: var(--line-height-small, 20px);
}
.legacyModal__additionalAction {
  margin-left: auto;
}
:where(body[data-fabric-theme~="encore"]) .legacyModal__additionalAction {
  margin-left: unset;
  margin-right: auto;
}
.legacyModal__modal--small {
  display: block;
}
.legacyModal__modal--aggressiveConfirmation .legacyModal__header {
  margin-bottom: 28px;
}
:where(body[data-fabric-theme~="encore"]) .legacyModal__modal--aggressiveConfirmation .legacyModal__header {
  margin-bottom: 0;
}
.legacyModal__modal--aggressiveConfirmation .legacyModal__headerIcon {
  fill: var(--danger, #c20b0b);
}
:where(body[data-fabric-theme~="encore"]) .legacyModal__modal--aggressiveConfirmation .legacyModal__headerIcon {
  fill: var(--dangerDark);
}
.legacyModal__modal--aggressiveConfirmation .legacyModal__content {
  color: var(--danger, #c20b0b);
  background-color: #f4f4f4;
  border-radius: 2px;
  margin: 0 48px 32px;
  padding: 20px 20px 24px;
  text-align: center;
  font-size: var(--font-size-small, 14px);
  line-height: var(--line-height-small, 20px);
}
:where(body[data-fabric-theme~="encore"]) .legacyModal__modal--aggressiveConfirmation .legacyModal__content {
  color: var(--dangerDark, #890000);
  background-color: var(--gray05);
  border-radius: var(--radius-300);
  margin: 20px;
}
.legacyModal__modal--aggressiveConfirmation .legacyModal__note {
  margin-bottom: 12px;
}
.legacyModal__modal--aggressiveConfirmation .legacyModal__delete {
  margin-bottom: 8px;
}
.legacyModal__modal--aggressiveConfirmation .legacyModal__bold {
  font-weight: var(--font-weight-bold, 700);
}
.legacyModal__modal--medium {
  width: 800px;
}
.legacyModal__modal--headless .legacyModal__closeButton {
  right: 12px;
}
.legacyModal__modal--headless .legacyModal__main {
  padding-top: 48px;
}
.legacyModal__modal--headless .legacyModal__header {
  padding-top: 0;
}
.legacyModal__modal--headless .legacyModal__content {
  padding-top: 0;
}
/* -------------------------------------------------
FULL SCREEN TYPE
------------------------------------------------- */
:where(body[data-fabric-theme~="encore"]) .legacyModal__modal--fullScreen {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -o-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}
.legacyModal__modal--fullScreen:not(.legacyModal__modal--loading) {
  height: 100%;
  width: 100%;
}
:where(body[data-fabric-theme~="encore"]) .legacyModal__modal--fullScreen:not(.legacyModal__modal--loading) {
  height: calc(100% - 80px);
  width: calc(100% - 80px);
}
.legacyModal__modal--fullScreen .legacyModal__closeButton {
  right: 24px;
  top: 24px;
  z-index: 1;
}
.legacyModal__modal--fullScreen .legacyModal__main {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -o-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100%;
  padding: 0;
}
:where(body[data-fabric-theme~="encore"]) .legacyModal__modal--fullScreen .legacyModal__main {
  border-radius: var(--radius-200);
  height: calc(100% - 90px);
  background-color: var(--gray05, #fff);
  padding: 0;
}
:where(body[data-fabric-theme~="encore"]) .legacyModal__modal--fullScreen .legacyModal__footer {
  margin: 0 36px;
}
.legacyModal__modal--fullScreen .legacyModal__header {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  margin: 0;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  background-color: #f4f4f4;
  border-bottom: 1px solid #e0e0e0;
  padding: 19px 32px;
}
:where(body[data-fabric-theme~="encore"]) .legacyModal__modal--fullScreen .legacyModal__header--stacked {
  min-height: 42px;
}
:where(body[data-fabric-theme~="encore"]) .legacyModal__modal--fullScreen .legacyModal__header {
  background-color: var(--white, #fff);
  border-bottom: 1px solid var(--gray2);
  border-top-left-radius: var(--radius-200);
  border-top-right-radius: var(--radius-200);
  padding: 20px 20px 20px 40px;
}
.legacyModal__modal--fullScreen .legacyModal__headerContent {
  overflow: hidden;
  padding-right: 80px;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.legacyModal__modal--fullScreen .legacyModal__headerIcon {
  margin: 0 16px 0 0;
}
.legacyModal__modal--fullScreen .legacyModal__content {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  margin: 0;
  max-height: none;
}
:where(body[data-fabric-theme~="encore"]) .legacyModal__modal--fullScreen .legacyModal__content {
  background-color: var(--gray05, #fff);
}
.legacyModal__modal--fullScreen .legacyModal__content:not(.legacyModal__content--noPadding) {
  padding: 32px 48px;
}
:where(body[data-fabric-theme~="encore"]) .legacyModal__modal--fullScreen .legacyModal__content:not(.legacyModal__content--noPadding) {
  padding: 40px;
}
.legacyModal__modal--fullScreen .legacyModal__content--noPadding {
  width: auto;
  padding: 0;
}
:where(body[data-fabric-theme~="encore"]) .legacyModal__modal--fullScreen .legacyModal__content--noPadding {
  width: auto;
  padding: 0;
}
.legacyModal__modal--fullScreen .legacyModal__actions {
  background-color: #f4f4f4;
  border-top: 1px solid #e0e0e0;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  padding: 24px 32px 32px;
}
:where(body[data-fabric-theme~="encore"]) .legacyModal__modal--fullScreen .legacyModal__actions {
  background-color: #fff;
  -webkit-box-pack: left;
  -moz-box-pack: left;
  -o-box-pack: left;
  -ms-flex-pack: left;
  -webkit-justify-content: left;
  justify-content: left;
  padding: 24px;
}
.legacyModal__modal--fullScreen .legacyModal__actions:before {
  content: none;
}
.legacyModal__modal--fullScreen .legacyModal__logo {
  fill: #686868;
  margin-left: auto;
}
.legacyModal__overlay--sheet {
  background-color: rgba(235,235,235,0.95);
  -webkit-transition: opacity 200ms linear;
  -moz-transition: opacity 200ms linear;
  -o-transition: opacity 200ms linear;
  -ms-transition: opacity 200ms linear;
  transition: opacity 200ms linear;
}
.legacyModal__modal--sheet {
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  -webkit-transform: scale(0);
  -moz-transform: scale(0);
  -o-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
  -webkit-transition: -webkit-transform 200ms ease 50ms;
  -moz-transition: -moz-transform 200ms ease 50ms;
  -o-transition: -o-transform 200ms ease 50ms;
  -ms-transition: -ms-transform 200ms ease 50ms;
  transition: transform 200ms ease 50ms;
  -webkit-transition-property: opacity, -webkit-transform;
  -moz-transition-property: opacity, -moz-transform;
  -o-transition-property: opacity, -o-transform;
  -ms-transition-property: opacity, -ms-transform;
  transition-property: opacity, transform;
}
.legacyModal__modal--sheet.ReactModal__Content--after-open {
  opacity: 1;
  -ms-filter: none;
  filter: none;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -o-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}
.legacyModal__modal--sheet.ReactModal__Content--before-close {
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  -webkit-transform: scale(0);
  -moz-transform: scale(0);
  -o-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
  -webkit-transition-duration: 250ms;
  -moz-transition-duration: 250ms;
  -o-transition-duration: 250ms;
  -ms-transition-duration: 250ms;
  transition-duration: 250ms;
}
.legacyModal__modal--sheet .legacyModal__title {
  -webkit-transition: opacity 200ms ease;
  -moz-transition: opacity 200ms ease;
  -o-transition: opacity 200ms ease;
  -ms-transition: opacity 200ms ease;
  transition: opacity 200ms ease;
}
:where(body[data-fabric-theme~="encore"]) .legacyModal__modal--sheet .legacyModal__title {
  color: var(--theme-base-default, #527a00);
  color: var(--fabric-theme-base, #527a00);
}
.legacyModal__modal--sheet .legacyModal__title--hidden {
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  -webkit-transform: none;
  -moz-transform: none;
  -o-transform: none;
  -ms-transform: none;
  transform: none;
  -webkit-transition: none;
  -moz-transition: none;
  -o-transition: none;
  -ms-transition: none;
  transition: none;
}
.legacyModal__modal--sheet .legacyModal__closeButton {
  top: 8px;
  -webkit-transition: opacity 200ms ease;
  -moz-transition: opacity 200ms ease;
  -o-transition: opacity 200ms ease;
  -ms-transition: opacity 200ms ease;
  transition: opacity 200ms ease;
}
.legacyModal__modal--sheet .legacyModal__closeButton--hidden {
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  -webkit-transform: none;
  -moz-transform: none;
  -o-transform: none;
  -ms-transform: none;
  transform: none;
  -webkit-transition: none;
  -moz-transition: none;
  -o-transition: none;
  -ms-transition: none;
  transition: none;
}
.legacyModal__modal--sheet .legacyModal__main {
  -webkit-transition: -webkit-transform 200ms ease, opacity 200ms ease;
  -moz-transition: -moz-transform 200ms ease, opacity 200ms ease;
  -o-transition: -o-transform 200ms ease, opacity 200ms ease;
  -ms-transition: -ms-transform 200ms ease, opacity 200ms ease;
  transition: transform 200ms ease, opacity 200ms ease;
}
.legacyModal__modal--sheet .legacyModal__main--hidden {
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  -webkit-transform: scale(0);
  -moz-transform: scale(0);
  -o-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
}
.legacyModal__modal--smallSheet {
  width: 528px;
}
.legacyModal__modal--mediumSheet {
  width: 720px;
}
.legacyModal__modal--largeSheet {
  width: 912px;
}
.legacyModal__modal--custom {
  width: auto;
}
.legacyModal__modal--custom .legacyModal__closeButton {
  right: 12px;
}
/* -------------------------------------------------
	LOW RESOLUTION WINDOW HEIGHT
	------------------------------------------------- */
@media (max-height: 840px) {
  .legacyModal__modal:not(.legacyModal__modal--fullScreen) .legacyModal__content:not(.legacyModal__content--noMaxHeight) {
    max-height: 50vh;
  }
}
/*
MOBILE FRIENDLY STYLES
 */
.legacyModal__actions--small--mobileShow,
.legacyModal__header--small--mobileShow,
.legacyModal__actions--medium--mobileShow,
.legacyModal__header--medium--mobileShow,
.legacyModal__actions--smallSheet--mobileShow,
.legacyModal__header--smallSheet--mobileShow,
.legacyModal__actions--mediumSheet--mobileShow,
.legacyModal__header--mediumSheet--mobileShow,
.legacyModal__actions--largeSheet--mobileShow,
.legacyModal__header--largeSheet--mobileShow {
  display: none;
}
:where(body[data-fabric-theme~="encore"]) .formSection--noMarginTop {
  margin-top: 0px;
}
:where(body[data-fabric-theme~="encore"]) .formSection--noPaddingBottom {
  padding-bottom: 0px;
}
@media (max-width: 654px) {
  :where(body[data-fabric-theme~="encore"]) .legacyModal__modal--small--mobileFriendly {
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: box;
    display: flex;
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    -o-box-orient: vertical;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .legacyModal__modal--small--mobileFriendly:not(.legacyModal__modal--loading) {
    height: 100%;
    width: 100%;
  }
  :where(body[data-fabric-theme~="encore"]) .legacyModal__modal--small--mobileFriendly:not(.legacyModal__modal--loading) {
    height: calc(100% - 80px);
    width: calc(100% - 80px);
  }
  .legacyModal__modal--small--mobileFriendly .legacyModal__closeButton {
    right: 24px;
    top: 24px;
    z-index: 1;
  }
  .legacyModal__modal--small--mobileFriendly .legacyModal__main {
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: box;
    display: flex;
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    -o-box-orient: vertical;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    height: 100%;
    padding: 0;
  }
  :where(body[data-fabric-theme~="encore"]) .legacyModal__modal--small--mobileFriendly .legacyModal__main {
    border-radius: var(--radius-200);
    height: calc(100% - 90px);
    background-color: var(--gray05, #fff);
    padding: 0;
  }
  :where(body[data-fabric-theme~="encore"]) .legacyModal__modal--small--mobileFriendly .legacyModal__footer {
    margin: 0 36px;
  }
  .legacyModal__modal--small--mobileFriendly .legacyModal__header {
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: box;
    display: flex;
    margin: 0;
    -webkit-box-align: center;
    -moz-box-align: center;
    -o-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    background-color: #f4f4f4;
    border-bottom: 1px solid #e0e0e0;
    padding: 19px 32px;
  }
  :where(body[data-fabric-theme~="encore"]) .legacyModal__modal--small--mobileFriendly .legacyModal__header--stacked {
    min-height: 42px;
  }
  :where(body[data-fabric-theme~="encore"]) .legacyModal__modal--small--mobileFriendly .legacyModal__header {
    background-color: var(--white, #fff);
    border-bottom: 1px solid var(--gray2);
    border-top-left-radius: var(--radius-200);
    border-top-right-radius: var(--radius-200);
    padding: 20px 20px 20px 40px;
  }
  .legacyModal__modal--small--mobileFriendly .legacyModal__headerContent {
    overflow: hidden;
    padding-right: 80px;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .legacyModal__modal--small--mobileFriendly .legacyModal__headerIcon {
    margin: 0 16px 0 0;
  }
  .legacyModal__modal--small--mobileFriendly .legacyModal__content {
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -o-box-flex: 1;
    box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    margin: 0;
    max-height: none;
  }
  :where(body[data-fabric-theme~="encore"]) .legacyModal__modal--small--mobileFriendly .legacyModal__content {
    background-color: var(--gray05, #fff);
  }
  .legacyModal__modal--small--mobileFriendly .legacyModal__content:not(.legacyModal__content--noPadding) {
    padding: 32px 48px;
  }
  :where(body[data-fabric-theme~="encore"]) .legacyModal__modal--small--mobileFriendly .legacyModal__content:not(.legacyModal__content--noPadding) {
    padding: 40px;
  }
  .legacyModal__modal--small--mobileFriendly .legacyModal__content--noPadding {
    width: auto;
    padding: 0;
  }
  :where(body[data-fabric-theme~="encore"]) .legacyModal__modal--small--mobileFriendly .legacyModal__content--noPadding {
    width: auto;
    padding: 0;
  }
  .legacyModal__modal--small--mobileFriendly .legacyModal__actions {
    background-color: #f4f4f4;
    border-top: 1px solid #e0e0e0;
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: box;
    display: flex;
    padding: 24px 32px 32px;
  }
  :where(body[data-fabric-theme~="encore"]) .legacyModal__modal--small--mobileFriendly .legacyModal__actions {
    background-color: #fff;
    -webkit-box-pack: left;
    -moz-box-pack: left;
    -o-box-pack: left;
    -ms-flex-pack: left;
    -webkit-justify-content: left;
    justify-content: left;
    padding: 24px;
  }
  .legacyModal__modal--small--mobileFriendly .legacyModal__actions:before {
    content: none;
  }
  .legacyModal__modal--small--mobileFriendly .legacyModal__logo {
    fill: #686868;
    margin-left: auto;
  }
  .legacyModal__header {
    padding: 19px 24px;
  }
  :where(body[data-fabric-theme~="encore"]) .legacyModal__header {
    padding: 20px 0 12px;
  }
  .legacyModal__headerContent {
    padding-right: 30px;
  }
  .legacyModal__closeButton {
    right: 16px;
  }
  .legacyModal__content:not(.legacyModal__content--noMaxHeight) {
    max-height: none;
  }
  .legacyModal__header--small--mobileShow,
  .legacyModal__title--small--mobileShow {
    display: block;
  }
  .legacyModal__header--small--mobileHide,
  .legacyModal__title--small--mobileHide {
    display: none;
  }
  .legacyModal__headerIcon--small--mobileHide,
  .legacyModal__titleIcon--small--mobileHide {
    display: none;
  }
}
@media (max-width: 846px) {
  :where(body[data-fabric-theme~="encore"]) .legacyModal__modal--medium--mobileFriendly {
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: box;
    display: flex;
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    -o-box-orient: vertical;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .legacyModal__modal--medium--mobileFriendly:not(.legacyModal__modal--loading) {
    height: 100%;
    width: 100%;
  }
  :where(body[data-fabric-theme~="encore"]) .legacyModal__modal--medium--mobileFriendly:not(.legacyModal__modal--loading) {
    height: calc(100% - 80px);
    width: calc(100% - 80px);
  }
  .legacyModal__modal--medium--mobileFriendly .legacyModal__closeButton {
    right: 24px;
    top: 24px;
    z-index: 1;
  }
  .legacyModal__modal--medium--mobileFriendly .legacyModal__main {
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: box;
    display: flex;
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    -o-box-orient: vertical;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    height: 100%;
    padding: 0;
  }
  :where(body[data-fabric-theme~="encore"]) .legacyModal__modal--medium--mobileFriendly .legacyModal__main {
    border-radius: var(--radius-200);
    height: calc(100% - 90px);
    background-color: var(--gray05, #fff);
    padding: 0;
  }
  :where(body[data-fabric-theme~="encore"]) .legacyModal__modal--medium--mobileFriendly .legacyModal__footer {
    margin: 0 36px;
  }
  .legacyModal__modal--medium--mobileFriendly .legacyModal__header {
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: box;
    display: flex;
    margin: 0;
    -webkit-box-align: center;
    -moz-box-align: center;
    -o-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    background-color: #f4f4f4;
    border-bottom: 1px solid #e0e0e0;
    padding: 19px 32px;
  }
  :where(body[data-fabric-theme~="encore"]) .legacyModal__modal--medium--mobileFriendly .legacyModal__header--stacked {
    min-height: 42px;
  }
  :where(body[data-fabric-theme~="encore"]) .legacyModal__modal--medium--mobileFriendly .legacyModal__header {
    background-color: var(--white, #fff);
    border-bottom: 1px solid var(--gray2);
    border-top-left-radius: var(--radius-200);
    border-top-right-radius: var(--radius-200);
    padding: 20px 20px 20px 40px;
  }
  .legacyModal__modal--medium--mobileFriendly .legacyModal__headerContent {
    overflow: hidden;
    padding-right: 80px;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .legacyModal__modal--medium--mobileFriendly .legacyModal__headerIcon {
    margin: 0 16px 0 0;
  }
  .legacyModal__modal--medium--mobileFriendly .legacyModal__content {
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -o-box-flex: 1;
    box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    margin: 0;
    max-height: none;
  }
  :where(body[data-fabric-theme~="encore"]) .legacyModal__modal--medium--mobileFriendly .legacyModal__content {
    background-color: var(--gray05, #fff);
  }
  .legacyModal__modal--medium--mobileFriendly .legacyModal__content:not(.legacyModal__content--noPadding) {
    padding: 32px 48px;
  }
  :where(body[data-fabric-theme~="encore"]) .legacyModal__modal--medium--mobileFriendly .legacyModal__content:not(.legacyModal__content--noPadding) {
    padding: 40px;
  }
  .legacyModal__modal--medium--mobileFriendly .legacyModal__content--noPadding {
    width: auto;
    padding: 0;
  }
  :where(body[data-fabric-theme~="encore"]) .legacyModal__modal--medium--mobileFriendly .legacyModal__content--noPadding {
    width: auto;
    padding: 0;
  }
  .legacyModal__modal--medium--mobileFriendly .legacyModal__actions {
    background-color: #f4f4f4;
    border-top: 1px solid #e0e0e0;
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: box;
    display: flex;
    padding: 24px 32px 32px;
  }
  :where(body[data-fabric-theme~="encore"]) .legacyModal__modal--medium--mobileFriendly .legacyModal__actions {
    background-color: #fff;
    -webkit-box-pack: left;
    -moz-box-pack: left;
    -o-box-pack: left;
    -ms-flex-pack: left;
    -webkit-justify-content: left;
    justify-content: left;
    padding: 24px;
  }
  .legacyModal__modal--medium--mobileFriendly .legacyModal__actions:before {
    content: none;
  }
  .legacyModal__modal--medium--mobileFriendly .legacyModal__logo {
    fill: #686868;
    margin-left: auto;
  }
  .legacyModal__header {
    padding: 19px 24px;
  }
  :where(body[data-fabric-theme~="encore"]) .legacyModal__header {
    padding: 20px 0 12px;
  }
  .legacyModal__headerContent {
    padding-right: 30px;
  }
  .legacyModal__closeButton {
    right: 16px;
  }
  .legacyModal__content:not(.legacyModal__content--noMaxHeight) {
    max-height: none;
  }
  .legacyModal__header--medium--mobileShow,
  .legacyModal__title--medium--mobileShow {
    display: block;
  }
  .legacyModal__header--medium--mobileHide,
  .legacyModal__title--medium--mobileHide {
    display: none;
  }
  .legacyModal__headerIcon--medium--mobileHide,
  .legacyModal__titleIcon--medium--mobileHide {
    display: none;
  }
}
@media (max-width: 574px) {
  .legacyModal__modal--smallSheet--mobileFriendly {
    width: calc(100% - -46px);
  }
  .legacyModal__actions--mobileShow {
    display: block;
  }
  .legacyModal__actions--mobileHide {
    display: none;
  }
  :where(body[data-fabric-theme~="encore"]) .legacyModal__modal--smallSheet--mobileFriendlyFullScreen {
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: box;
    display: flex;
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    -o-box-orient: vertical;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .legacyModal__modal--smallSheet--mobileFriendlyFullScreen:not(.legacyModal__modal--loading) {
    height: 100%;
    width: 100%;
  }
  :where(body[data-fabric-theme~="encore"]) .legacyModal__modal--smallSheet--mobileFriendlyFullScreen:not(.legacyModal__modal--loading) {
    height: calc(100% - 80px);
    width: calc(100% - 80px);
  }
  .legacyModal__modal--smallSheet--mobileFriendlyFullScreen .legacyModal__closeButton {
    right: 24px;
    top: 24px;
    z-index: 1;
  }
  .legacyModal__modal--smallSheet--mobileFriendlyFullScreen .legacyModal__main {
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: box;
    display: flex;
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    -o-box-orient: vertical;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    height: 100%;
    padding: 0;
  }
  :where(body[data-fabric-theme~="encore"]) .legacyModal__modal--smallSheet--mobileFriendlyFullScreen .legacyModal__main {
    border-radius: var(--radius-200);
    height: calc(100% - 90px);
    background-color: var(--gray05, #fff);
    padding: 0;
  }
  :where(body[data-fabric-theme~="encore"]) .legacyModal__modal--smallSheet--mobileFriendlyFullScreen .legacyModal__footer {
    margin: 0 36px;
  }
  .legacyModal__modal--smallSheet--mobileFriendlyFullScreen .legacyModal__header {
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: box;
    display: flex;
    margin: 0;
    -webkit-box-align: center;
    -moz-box-align: center;
    -o-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    background-color: #f4f4f4;
    border-bottom: 1px solid #e0e0e0;
    padding: 19px 32px;
  }
  :where(body[data-fabric-theme~="encore"]) .legacyModal__modal--smallSheet--mobileFriendlyFullScreen .legacyModal__header--stacked {
    min-height: 42px;
  }
  :where(body[data-fabric-theme~="encore"]) .legacyModal__modal--smallSheet--mobileFriendlyFullScreen .legacyModal__header {
    background-color: var(--white, #fff);
    border-bottom: 1px solid var(--gray2);
    border-top-left-radius: var(--radius-200);
    border-top-right-radius: var(--radius-200);
    padding: 20px 20px 20px 40px;
  }
  .legacyModal__modal--smallSheet--mobileFriendlyFullScreen .legacyModal__headerContent {
    overflow: hidden;
    padding-right: 80px;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .legacyModal__modal--smallSheet--mobileFriendlyFullScreen .legacyModal__headerIcon {
    margin: 0 16px 0 0;
  }
  .legacyModal__modal--smallSheet--mobileFriendlyFullScreen .legacyModal__content {
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -o-box-flex: 1;
    box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    margin: 0;
    max-height: none;
  }
  :where(body[data-fabric-theme~="encore"]) .legacyModal__modal--smallSheet--mobileFriendlyFullScreen .legacyModal__content {
    background-color: var(--gray05, #fff);
  }
  .legacyModal__modal--smallSheet--mobileFriendlyFullScreen .legacyModal__content:not(.legacyModal__content--noPadding) {
    padding: 32px 48px;
  }
  :where(body[data-fabric-theme~="encore"]) .legacyModal__modal--smallSheet--mobileFriendlyFullScreen .legacyModal__content:not(.legacyModal__content--noPadding) {
    padding: 40px;
  }
  .legacyModal__modal--smallSheet--mobileFriendlyFullScreen .legacyModal__content--noPadding {
    width: auto;
    padding: 0;
  }
  :where(body[data-fabric-theme~="encore"]) .legacyModal__modal--smallSheet--mobileFriendlyFullScreen .legacyModal__content--noPadding {
    width: auto;
    padding: 0;
  }
  .legacyModal__modal--smallSheet--mobileFriendlyFullScreen .legacyModal__actions {
    background-color: #f4f4f4;
    border-top: 1px solid #e0e0e0;
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: box;
    display: flex;
    padding: 24px 32px 32px;
  }
  :where(body[data-fabric-theme~="encore"]) .legacyModal__modal--smallSheet--mobileFriendlyFullScreen .legacyModal__actions {
    background-color: #fff;
    -webkit-box-pack: left;
    -moz-box-pack: left;
    -o-box-pack: left;
    -ms-flex-pack: left;
    -webkit-justify-content: left;
    justify-content: left;
    padding: 24px;
  }
  .legacyModal__modal--smallSheet--mobileFriendlyFullScreen .legacyModal__actions:before {
    content: none;
  }
  .legacyModal__modal--smallSheet--mobileFriendlyFullScreen .legacyModal__logo {
    fill: #686868;
    margin-left: auto;
  }
  .legacyModal__header {
    padding: 19px 24px;
  }
  :where(body[data-fabric-theme~="encore"]) .legacyModal__header {
    padding: 20px 0 12px;
  }
  .legacyModal__headerContent {
    padding-right: 30px;
  }
  .legacyModal__closeButton {
    right: 16px;
  }
  .legacyModal__header--smallSheet--mobileShow,
  .legacyModal__title--smallSheet--mobileShow {
    display: block;
  }
  .legacyModal__header--smallSheet--mobileHide,
  .legacyModal__title--smallSheet--mobileHide {
    display: none;
  }
  .legacyModal__headerIcon--smallSheet--mobileHide,
  .legacyModal__titleIcon--smallSheet--mobileHide {
    display: none;
  }
}
@media (max-width: 766px) {
  .legacyModal__modal--mediumSheet--mobileFriendly {
    width: calc(100% - -46px);
  }
  :where(body[data-fabric-theme~="encore"]) .legacyModal__modal--mediumSheet--mobileFriendlyFullScreen {
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: box;
    display: flex;
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    -o-box-orient: vertical;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .legacyModal__modal--mediumSheet--mobileFriendlyFullScreen:not(.legacyModal__modal--loading) {
    height: 100%;
    width: 100%;
  }
  :where(body[data-fabric-theme~="encore"]) .legacyModal__modal--mediumSheet--mobileFriendlyFullScreen:not(.legacyModal__modal--loading) {
    height: calc(100% - 80px);
    width: calc(100% - 80px);
  }
  .legacyModal__modal--mediumSheet--mobileFriendlyFullScreen .legacyModal__closeButton {
    right: 24px;
    top: 24px;
    z-index: 1;
  }
  .legacyModal__modal--mediumSheet--mobileFriendlyFullScreen .legacyModal__main {
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: box;
    display: flex;
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    -o-box-orient: vertical;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    height: 100%;
    padding: 0;
  }
  :where(body[data-fabric-theme~="encore"]) .legacyModal__modal--mediumSheet--mobileFriendlyFullScreen .legacyModal__main {
    border-radius: var(--radius-200);
    height: calc(100% - 90px);
    background-color: var(--gray05, #fff);
    padding: 0;
  }
  :where(body[data-fabric-theme~="encore"]) .legacyModal__modal--mediumSheet--mobileFriendlyFullScreen .legacyModal__footer {
    margin: 0 36px;
  }
  .legacyModal__modal--mediumSheet--mobileFriendlyFullScreen .legacyModal__header {
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: box;
    display: flex;
    margin: 0;
    -webkit-box-align: center;
    -moz-box-align: center;
    -o-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    background-color: #f4f4f4;
    border-bottom: 1px solid #e0e0e0;
    padding: 19px 32px;
  }
  :where(body[data-fabric-theme~="encore"]) .legacyModal__modal--mediumSheet--mobileFriendlyFullScreen .legacyModal__header--stacked {
    min-height: 42px;
  }
  :where(body[data-fabric-theme~="encore"]) .legacyModal__modal--mediumSheet--mobileFriendlyFullScreen .legacyModal__header {
    background-color: var(--white, #fff);
    border-bottom: 1px solid var(--gray2);
    border-top-left-radius: var(--radius-200);
    border-top-right-radius: var(--radius-200);
    padding: 20px 20px 20px 40px;
  }
  .legacyModal__modal--mediumSheet--mobileFriendlyFullScreen .legacyModal__headerContent {
    overflow: hidden;
    padding-right: 80px;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .legacyModal__modal--mediumSheet--mobileFriendlyFullScreen .legacyModal__headerIcon {
    margin: 0 16px 0 0;
  }
  .legacyModal__modal--mediumSheet--mobileFriendlyFullScreen .legacyModal__content {
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -o-box-flex: 1;
    box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    margin: 0;
    max-height: none;
  }
  :where(body[data-fabric-theme~="encore"]) .legacyModal__modal--mediumSheet--mobileFriendlyFullScreen .legacyModal__content {
    background-color: var(--gray05, #fff);
  }
  .legacyModal__modal--mediumSheet--mobileFriendlyFullScreen .legacyModal__content:not(.legacyModal__content--noPadding) {
    padding: 32px 48px;
  }
  :where(body[data-fabric-theme~="encore"]) .legacyModal__modal--mediumSheet--mobileFriendlyFullScreen .legacyModal__content:not(.legacyModal__content--noPadding) {
    padding: 40px;
  }
  .legacyModal__modal--mediumSheet--mobileFriendlyFullScreen .legacyModal__content--noPadding {
    width: auto;
    padding: 0;
  }
  :where(body[data-fabric-theme~="encore"]) .legacyModal__modal--mediumSheet--mobileFriendlyFullScreen .legacyModal__content--noPadding {
    width: auto;
    padding: 0;
  }
  .legacyModal__modal--mediumSheet--mobileFriendlyFullScreen .legacyModal__actions {
    background-color: #f4f4f4;
    border-top: 1px solid #e0e0e0;
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: box;
    display: flex;
    padding: 24px 32px 32px;
  }
  :where(body[data-fabric-theme~="encore"]) .legacyModal__modal--mediumSheet--mobileFriendlyFullScreen .legacyModal__actions {
    background-color: #fff;
    -webkit-box-pack: left;
    -moz-box-pack: left;
    -o-box-pack: left;
    -ms-flex-pack: left;
    -webkit-justify-content: left;
    justify-content: left;
    padding: 24px;
  }
  .legacyModal__modal--mediumSheet--mobileFriendlyFullScreen .legacyModal__actions:before {
    content: none;
  }
  .legacyModal__modal--mediumSheet--mobileFriendlyFullScreen .legacyModal__logo {
    fill: #686868;
    margin-left: auto;
  }
  .legacyModal__header {
    padding: 19px 24px;
  }
  :where(body[data-fabric-theme~="encore"]) .legacyModal__header {
    padding: 20px 0 12px;
  }
  .legacyModal__headerContent {
    padding-right: 30px;
  }
  .legacyModal__closeButton {
    right: 16px;
  }
  .legacyModal__header--mediumSheet--mobileShow,
  .legacyModal__title--mediumSheet--mobileShow {
    display: block;
  }
  .legacyModal__header--mediumSheet--mobileHide,
  .legacyModal__title--mediumSheet--mobileHide {
    display: none;
  }
  .legacyModal__headerIcon--mediumSheet--mobileHide,
  .legacyModal__titleIcon--mediumSheet--mobileHide {
    display: none;
  }
}
@media (max-width: 958px) {
  .legacyModal__modal--largeSheet--mobileFriendly {
    width: calc(100% - -46px);
  }
  :where(body[data-fabric-theme~="encore"]) .legacyModal__modal--largeSheet--mobileFriendlyFullScreen {
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: box;
    display: flex;
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    -o-box-orient: vertical;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .legacyModal__modal--largeSheet--mobileFriendlyFullScreen:not(.legacyModal__modal--loading) {
    height: 100%;
    width: 100%;
  }
  :where(body[data-fabric-theme~="encore"]) .legacyModal__modal--largeSheet--mobileFriendlyFullScreen:not(.legacyModal__modal--loading) {
    height: calc(100% - 80px);
    width: calc(100% - 80px);
  }
  .legacyModal__modal--largeSheet--mobileFriendlyFullScreen .legacyModal__closeButton {
    right: 24px;
    top: 24px;
    z-index: 1;
  }
  .legacyModal__modal--largeSheet--mobileFriendlyFullScreen .legacyModal__main {
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: box;
    display: flex;
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    -o-box-orient: vertical;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    height: 100%;
    padding: 0;
  }
  :where(body[data-fabric-theme~="encore"]) .legacyModal__modal--largeSheet--mobileFriendlyFullScreen .legacyModal__main {
    border-radius: var(--radius-200);
    height: calc(100% - 90px);
    background-color: var(--gray05, #fff);
    padding: 0;
  }
  :where(body[data-fabric-theme~="encore"]) .legacyModal__modal--largeSheet--mobileFriendlyFullScreen .legacyModal__footer {
    margin: 0 36px;
  }
  .legacyModal__modal--largeSheet--mobileFriendlyFullScreen .legacyModal__header {
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: box;
    display: flex;
    margin: 0;
    -webkit-box-align: center;
    -moz-box-align: center;
    -o-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    background-color: #f4f4f4;
    border-bottom: 1px solid #e0e0e0;
    padding: 19px 32px;
  }
  :where(body[data-fabric-theme~="encore"]) .legacyModal__modal--largeSheet--mobileFriendlyFullScreen .legacyModal__header--stacked {
    min-height: 42px;
  }
  :where(body[data-fabric-theme~="encore"]) .legacyModal__modal--largeSheet--mobileFriendlyFullScreen .legacyModal__header {
    background-color: var(--white, #fff);
    border-bottom: 1px solid var(--gray2);
    border-top-left-radius: var(--radius-200);
    border-top-right-radius: var(--radius-200);
    padding: 20px 20px 20px 40px;
  }
  .legacyModal__modal--largeSheet--mobileFriendlyFullScreen .legacyModal__headerContent {
    overflow: hidden;
    padding-right: 80px;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .legacyModal__modal--largeSheet--mobileFriendlyFullScreen .legacyModal__headerIcon {
    margin: 0 16px 0 0;
  }
  .legacyModal__modal--largeSheet--mobileFriendlyFullScreen .legacyModal__content {
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -o-box-flex: 1;
    box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    margin: 0;
    max-height: none;
  }
  :where(body[data-fabric-theme~="encore"]) .legacyModal__modal--largeSheet--mobileFriendlyFullScreen .legacyModal__content {
    background-color: var(--gray05, #fff);
  }
  .legacyModal__modal--largeSheet--mobileFriendlyFullScreen .legacyModal__content:not(.legacyModal__content--noPadding) {
    padding: 32px 48px;
  }
  :where(body[data-fabric-theme~="encore"]) .legacyModal__modal--largeSheet--mobileFriendlyFullScreen .legacyModal__content:not(.legacyModal__content--noPadding) {
    padding: 40px;
  }
  .legacyModal__modal--largeSheet--mobileFriendlyFullScreen .legacyModal__content--noPadding {
    width: auto;
    padding: 0;
  }
  :where(body[data-fabric-theme~="encore"]) .legacyModal__modal--largeSheet--mobileFriendlyFullScreen .legacyModal__content--noPadding {
    width: auto;
    padding: 0;
  }
  .legacyModal__modal--largeSheet--mobileFriendlyFullScreen .legacyModal__actions {
    background-color: #f4f4f4;
    border-top: 1px solid #e0e0e0;
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: box;
    display: flex;
    padding: 24px 32px 32px;
  }
  :where(body[data-fabric-theme~="encore"]) .legacyModal__modal--largeSheet--mobileFriendlyFullScreen .legacyModal__actions {
    background-color: #fff;
    -webkit-box-pack: left;
    -moz-box-pack: left;
    -o-box-pack: left;
    -ms-flex-pack: left;
    -webkit-justify-content: left;
    justify-content: left;
    padding: 24px;
  }
  .legacyModal__modal--largeSheet--mobileFriendlyFullScreen .legacyModal__actions:before {
    content: none;
  }
  .legacyModal__modal--largeSheet--mobileFriendlyFullScreen .legacyModal__logo {
    fill: #686868;
    margin-left: auto;
  }
  .legacyModal__header {
    padding: 19px 24px;
  }
  :where(body[data-fabric-theme~="encore"]) .legacyModal__header {
    padding: 20px 0 12px;
  }
  .legacyModal__headerContent {
    padding-right: 30px;
  }
  .legacyModal__closeButton {
    right: 16px;
  }
  .legacyModal__header--largeSheet--mobileShow,
  .legacyModal__title--largeSheet--mobileShow {
    display: block;
  }
  .legacyModal__header--largeSheet--mobileHide,
  .legacyModal__title--largeSheet--mobileHide {
    display: none;
  }
  .legacyModal__headerIcon--largeSheet--mobileHide,
  .legacyModal__titleIcon--largeSheet--mobileHide {
    display: none;
  }
}

.BackupCodes__wrapper {
  margin-bottom: 33px;
  position: relative;
}
:where(body[data-fabric-theme~="encore"]) .BackupCodes__wrapper {
  margin-bottom: 24px;
}
.BackupCodes__list {
  display: grid;
  grid-template-columns: 1fr 1fr;
  border: 1px solid #ccc;
  padding: 35px;
  row-gap: 10px;
}
:where(body[data-fabric-theme~="encore"]) .BackupCodes__list {
  border: 1px solid var(--gray2);
  row-gap: 16px;
  padding: 32px;
  padding-bottom: 76px;
  border-radius: 8px;
}
.BackupCodes__code {
  font-weight: var(--font-weight-semibold, 600);
  text-align: center;
  font-size: var(--font-size-h3, 22px);
  line-height: var(--line-height-h3, 30px);
  color: #444;
}
:where(body[data-fabric-theme~="encore"]) .BackupCodes__code {
  font-size: var(--font-size-h5, 16px);
  line-height: var(--line-height-h5, 22px);
  color: var(--gray8);
}
.BackupCodes__code--used {
  text-decoration: line-through;
}
.BackupCodes__date {
  display: block;
  background-color: var(--white, #fff);
  left: 50%;
  padding: 0 10px;
  position: absolute;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  font-size: var(--font-size-medium, 15px);
  line-height: var(--line-height-teenie, 18px);
  color: #444;
  top: calc(100% - 11px);
}
:where(body[data-fabric-theme~="encore"]) .BackupCodes__date {
  top: calc(100% - 40px);
  font-size: var(--font-size-small, 14px);
  line-height: var(--line-height-small, 20px);
  color: var(--gray7);
}

.BackupCodesContent__copy {
  margin-bottom: 28px;
}
:where(body[data-fabric-theme~="encore"]) .BackupCodesContent__copy {
  margin-bottom: 24px;
}
.BackupCodesContent__copy p {
  color: #444;
}
:where(body[data-fabric-theme~="encore"]) .BackupCodesContent__copy p {
  color: var(--gray9);
}
.BackupCodesContent__buttonWrapper {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -o-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}
.BackupCodesContent__printHeader {
  display: none;
}
@media print {
  .BackupCodesContent__printHeader {
    display: block;
    margin-bottom: 24px;
    border-bottom: 1px solid #e0e0e0;
  }
  .BackupCodesContent__buttonWrapper {
    display: none;
  }
}

body:not([data-feature-toggles~="jade"]) .SettingsApps__appModalbody:not([data-feature-toggles~="jade"]) .SettingsApps__appModal--conflict,
body:not([data-feature-toggles~="jade"]) .SettingsApps__appModalbody:not([data-feature-toggles~="jade"]) .SettingsApps__appModal--uninstall {
  text-align: center;
}
body:not([data-feature-toggles~="jade"]) .SettingsApps__appModalbody:not([data-feature-toggles~="jade"]) .SettingsApps__appModal--conflict {
  max-width: 365px;
  padding: 13px 0 7px;
}
body:not([data-feature-toggles~="jade"]) .SettingsApps__appModalbody:not([data-feature-toggles~="jade"]) .SettingsApps__appModal--uninstall {
  max-width: 297px;
  padding: 2px 34px 0;
}
body:not([data-feature-toggles~="jade"]) .SettingsApps__appModalbody:not([data-feature-toggles~="jade"]) .SettingsApps__appModal--install {
  width: 360px;
}
body:not([data-feature-toggles~="jade"]) .SettingsApps__appModalbody:not([data-feature-toggles~="jade"]) .SettingsApps__appModal--config {
  width: 530px;
}
body:not([data-feature-toggles~="jade"]) .SettingsApps__appModalbody:not([data-feature-toggles~="jade"]) .SettingsApps__appModal--config legend {
  display: none;
}
body:not([data-feature-toggles~="jade"]) .SettingsApps__appModalbody:not([data-feature-toggles~="jade"]) .SettingsApps__appModal--config fieldset[class*="fieldsetIndent-"] {
  margin-left: 0;
  padding-left: 0;
}
body:not([data-feature-toggles~="jade"]) .SettingsApps__appModalLogo {
  width: 190px;
  margin-bottom: 20px;
}
body:not([data-feature-toggles~="jade"]) .SettingsApps__appModalIconbody:not([data-feature-toggles~="jade"]) .SettingsApps__appModalIcon--conflict {
  margin-bottom: 7px;
}
body:not([data-feature-toggles~="jade"]) .SettingsApps__appModalIconbody:not([data-feature-toggles~="jade"]) .SettingsApps__appModalIcon--uninstall {
  fill: #858585;
}
body:not([data-feature-toggles~="jade"]) .SettingsApps__appModalMsgbody:not([data-feature-toggles~="jade"]) .SettingsApps__appModalMsg--install,
body:not([data-feature-toggles~="jade"]) .SettingsApps__appModalMsgbody:not([data-feature-toggles~="jade"]) .SettingsApps__appModalMsg--conflict,
body:not([data-feature-toggles~="jade"]) .SettingsApps__appModalMsgbody:not([data-feature-toggles~="jade"]) .SettingsApps__appModalMsg--uninstall {
  font-size: 17px;
  line-height: 24px;
  text-align: center;
}
body:not([data-feature-toggles~="jade"]) .SettingsApps__appModalMsgbody:not([data-feature-toggles~="jade"]) .SettingsApps__appModalMsg--install {
  max-width: 300px;
  margin: 10px auto;
}
body:not([data-feature-toggles~="jade"]) .SettingsApps__appModalMsgbody:not([data-feature-toggles~="jade"]) .SettingsApps__appModalMsg--uninstall {
  margin: 5px 0 -3px;
}
body:not([data-feature-toggles~="jade"]) .SettingsApps__appModal textareabody:not([data-feature-toggles~="jade"]) .SettingsApps__appModalTxtCopy {
  resize: none;
  height: 150px;
  font-size: 13px;
  line-height: 14px;
  font-family: monospace;
  word-wrap: pre;
  cursor: pointer;
}
.SettingsApps__appModalCopy {
  color: #444;
}
.SettingsApps__appModalCopy--centered {
  text-align: center;
}

.SettingsApps__appItem {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  padding: 10px 0;
}
:where(body[data-fabric-theme~="encore"]) .SettingsApps__appItem {
  padding: 32px 0 0 0;
}
.SettingsApps__appItemPubWrapper {
  width: 215px;
  height: 130px;
  text-align: center;
  border: 1px solid #cbcbcb;
  border-radius: 3px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 17px 0;
}
.SettingsApps__appItemPubLogo {
  width: 150px;
}
.SettingsApps__appItemPubLogoWrapper {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: distribute;
  -moz-box-pack: distribute;
  -o-box-pack: distribute;
  -ms-flex-pack: distribute;
  -webkit-justify-content: space-around;
  justify-content: space-around;
  height: 75px;
}
.SettingsApps__appItemPubLogoWrapper:only-child {
  height: 100%;
}
.SettingsApps__appItemPubMsg {
  display: block;
  font-size: 13px;
  color: #777;
}
.SettingsApps__appItemDetailWrapper {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -o-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-left: 28px;
}
.SettingsApps__appItemTitle {
  font-weight: 500;
  color: #222;
  font-size: 18px;
  line-height: 21px;
  margin-bottom: 3px;
}
.SettingsApps__appItemInstalled {
  font-size: 13px;
  line-height: 17px;
  color: #777;
}
.SettingsApps__appItemActions {
  padding-top: 16px;
}
.SettingsApps__appItemAction {
  margin: 0;
}
body:not([data-feature-toggles~="jade"]) .SettingsApps__appItemAction {
  display: inline-block;
}
.SettingsApps__appItemAction + .SettingsApps__appItemAction {
  margin-left: 10px;
}

.SettingsApps__appListEmptyState {
  text-align: center;
  padding: 50px 100px 40px;
}
.SettingsApps__appListEmptyStateIcon {
  fill: #cbcbcb;
}
.SettingsApps__appListEmptyStateTitle {
  font-size: 25px;
  margin: 13px 0 2px;
  color: #b1b1b1;
}
.SettingsApps__appListEmptyStateMsg {
  font-size: 18px;
  line-height: 22px;
  color: #b1b1b1;
  margin: 0 0 22px;
}

.SettingsApps__appList {
  padding: 18px 16px;
}
:where(body[data-fabric-theme~="encore"]) .SettingsApps__appList {
  padding: 18px 24px;
}
.SettingsApps__appList--installed {
  padding-bottom: 30px;
}
:where(body[data-fabric-theme~="encore"]) .SettingsApps__appList--installed {
  padding-bottom: 52px;
  padding-top: 0;
}
.SettingsApps__appList--uninstalled {
  padding-top: 12px;
}
:where(body[data-fabric-theme~="encore"]) .SettingsApps__appList--uninstalled {
  padding-top: 0;
}

:where(body[data-fabric-theme~="encore"]) .SettingsApps__installHeaders {
  color: var(--theme-base-default, #527a00);
  color: var(--fabric-theme-base, #527a00);
  margin-bottom: 0;
}
:where(body[data-fabric-theme~="encore"]) .SettingsApps__installHeaders--installed {
  color: var(--theme-base-default, #527a00);
  color: var(--fabric-theme-base, #527a00);
  margin-bottom: 0;
  margin-top: 26px;
}
.SettingsApps__headingIcon {
  margin-right: 8px;
}
:where(body[data-fabric-theme~="encore"]) .SettingsApps__heading {
  color: var(--theme-base-default, #527a00);
  color: var(--fabric-theme-base, #527a00);
  font-size: 26px;
  font-weight: 600;
  line-Height: 34px;
  margin-top: 0;
}
.SettingsApps__description {
  font-size: var(--font-size-biggie, 18px);
}
.SettingsApps__form {
  padding-bottom: 0;
}
.SettingsApps__formRow--last {
  margin-bottom: 0;
}
.SettingsApps__groupLabel--required:after {
  content: '*';
  color: var(--theme-base-default, #527a00);
  color: var(--fabric-theme-base, #527a00);
  font-family: sans-serif;
  font-size: fabricFontSize(large);
}
.SettingsApps__msg--uninstalled {
  margin-top: 11px;
}
.SettingsApps__appModalForm--config .header {
  margin-bottom: 24px;
}
.SettingsApps__appModalForm--config .bold {
  font-weight: 700;
}
.SettingsApps__appModalForm--config .info {
  font-size: 14px;
  margin-top: 8px;
}
.SettingsApps__appModalForm--inline {
  display: inline-block;
}
.SettingsApps__appModalForm--secondaryGray {
  color: var(--gray7, #686868);
}
.SettingsApps__appModalForm--confirmIcon {
  fill: #0e840d;
}

.DataProcessingAgreeementAgreement__summary {
  line-height: var(--line-height-teenie, 18px);
  font-size: var(--font-size-teenie, 13px);
  color: var(--gray7, #686868);
  margin-top: 6px;
  margin-left: 24px;
  max-width: 441px;
}

.DataProcessingAgreementConditionalAgreement {
  padding-top: 20px;
  color: var(--gray8, #555);
}
.DataProcessingAgreementConditionalAgreement__agreement {
  background-color: var(--gray1, #f4f4f4);
  margin-left: 24px;
  margin-top: 12px;
  padding: 20px 25px;
}
.DataProcessingAgreementConditionalAgreement__answers {
  margin-top: 14px;
}
.DataProcessingAgreementConditionalAgreement__requiredMessage {
  color: var(--gray10, #222);
  font-weight: var(--font-weight-semibold, 600);
  margin-bottom: 10px;
}

.DataProcessingAgreementModalHeadline {
  text-align: center;
}
.DataProcessingAgreementModalHeadline--hasSeparator {
  border-bottom: 1px solid #e0e0e0;
}

.StepIndicator__step {
  display: inline-block;
  position: relative;
  width: 9px;
  height: 9px;
  margin-left: 12px;
  border-radius: 50%;
  background-color: var(--theme-light-default, #6a950a);
  background-color: var(--fabric-theme-light, #6a950a);
}
.StepIndicator__step ~ .StepIndicator__step:before {
  content: "";
  display: block;
  width: 6px;
  height: 1px;
  position: absolute;
  left: -9px;
  top: 50%;
  background-color: var(--gray4, #ccc);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.StepIndicator__step.StepIndicator__step--active {
  width: 11px;
  height: 11px;
  background-color: var(--theme-base-default, #527a00);
  background-color: var(--fabric-theme-base, #527a00);
}
.StepIndicator__step.StepIndicator__step--active:before {
  width: 5px;
  left: -8px;
}
.StepIndicator__step.StepIndicator__step--active ~ .StepIndicator__step {
  background-color: var(--gray4, #ccc);
}
.StepIndicator__wrapper {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  float: right;
  margin-top: 5px;
}
.StepIndicator__wrapper--hasSeparator {
  padding-right: 24px;
  border-right: 2px solid #e0e0e0;
}
.StepIndicator__message {
  font-size: var(--font-size-teenie, 13px);
  line-height: var(--line-height-teenie, 18px);
  font-weight: var(--font-weight-regular, 400);
  color: var(--gray7, #686868);
}
.StepIndicator__stepWrapper {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  margin-top: 1px;
}

/* @startCleanup encore #deleteFile */
.CompanyDataModalHeadline {
  text-align: center;
/* @endCleanup encore */
}
.CompanyDataModalHeadline--hasSeparator {
  border-bottom: 1px solid #e0e0e0;
}

.PasswordResetModal__message {
  text-align: center;
}

.AccessLevelsSettingsModal {
  min-width: 524px;
}
.AccessLevelsSettingsModal__levelIco {
  width: 20px;
  height: 20px;
  color: #909090;
}
.AccessLevelsSettingsModal__levelName {
  font-family: 'BhrHeaderFont', 'Trebuchet MS';
  font-size: 19px;
  color: #222;
}
.AccessLevelsSettingsModal__body {
  padding: 22px;
}
.AccessLevelsSettingsModal__msg {
  max-width: 440px;
  margin: 0;
  font-size: 14px;
  line-height: 1.3em;
}
.AccessLevelsSettingsModal__msg.AccessLevelsSettingsModal__msg--note {
  font-size: 13px;
  color: #646464;
  padding-top: 8px;
}
.AccessLevelsSettingsModal__msgWrapper {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  padding: 0 22px 22px 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  max-width: 100%;
  border-bottom: 1px solid #ccc;
  margin-bottom: 22px;
}
.AccessLevelsSettingsModal__msgIco {
  width: 32px;
  height: 32px;
  margin-right: 12px;
}
.AccessLevelsSettingsModal__msgIco.AccessLevelsSettingsModal__msgIco--warning {
  fill: #bd5800;
}
.AccessLevelsSettingsModal__advanced-toggle {
  position: relative;
  padding-left: 14px;
}
.AccessLevelsSettingsModal__advanced-toggle:before {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  border: 5px solid transparent;
  border-left-color: #006ec2;
  border-left-width: 6px;
  border-right-width: 0;
  -webkit-transition: -webkit-transform 0.3s;
  -moz-transition: -moz-transform 0.3s;
  -o-transition: -o-transform 0.3s;
  -ms-transition: -ms-transform 0.3s;
  transition: transform 0.3s;
  margin-top: -5px;
}
.AccessLevelsSettingsModal__advanced-container {
  display: none;
}
.AccessLevelsSettingsModal__advanced-container:not(:first-of-type) {
  padding-top: 8px;
}
.AccessLevelsSettingsModal__advanced-wrapper {
  padding-top: 8px;
}
.AccessLevelsSettingsModal__advanced-wrapper[data-can-combine="false"] {
  display: none !important;
}
.AccessLevelsSettingsModal__advanced-wrapper--open .AccessLevelsSettingsModal__advanced-container {
  display: block;
}
.AccessLevelsSettingsModal__advanced-wrapper--open .AccessLevelsSettingsModal__advanced-toggle:before {
  -webkit-transform: rotateZ(90deg);
  -moz-transform: rotateZ(90deg);
  -o-transform: rotateZ(90deg);
  -ms-transform: rotateZ(90deg);
  transform: rotateZ(90deg);
}
.AccessLevelsSettingsModal__advanced-options strong {
  font-weight: 600;
}
.AccessLevelsSettingsModal .modalSubHeader {
  padding: 14px 20px 16px;
}

.AccessLevelChangeModal__copy {
  text-align: center;
}

input[ba-access-level-selector] {
  visibility: hidden;
}
.fieldBox > label + .fieldDiv > .AccessLevelSelector.AccessLevelSelector--input {
  margin-top: 4px;
}
.AccessLevelSelector.AccessLevelSelector--li,
li[ba-access-level-selector] {
  min-height: 43px;
  visibility: visible !important;
}
.AccessLevelSelector.AccessLevelSelector--li .AccessLevelSelector__menu {
  width: 100%;
}
.AccessLevelSelector.AccessLevelSelector--li .AccessLevelSelector__down-list {
  visibility: hidden;
  left: auto !important;
  display: block !important;
  top: 0;
  right: calc(100% + 5px);
}
li[ba-access-level-selector]:hover > .AccessLevelSelector.AccessLevelSelector--li .AccessLevelSelector__down-list {
  visibility: visible;
}
.AccessLevelSelector.AccessLevelSelector--li .AccessLevelSelector__btn {
  border: none;
  background: none;
  width: 100%;
  -webkit-box-shadow: none;
  box-shadow: none;
  padding: 0 !important;
  font-weight: normal;
  line-height: 14px;
  font-size: 14px;
  color: #000;
  height: auto;
}
.AccessLevelSelector.AccessLevelSelector--li .AccessLevelSelector__btn:after {
  display: none;
}
li[ba-access-level-selector]:hover > .AccessLevelSelector.AccessLevelSelector--li .AccessLevelSelector__btn > a,
li[ba-access-level-selector]:hover > .AccessLevelSelector.AccessLevelSelector--li .AccessLevelSelector__btn > a > i {
  color: #fff;
}
.AccessLevelSelector.AccessLevelSelector--li .AccessLevelSelector__label {
  font-style: normal;
  color: #777;
  font-size: 12px;
}
.AccessLevelSelector ul.AccessLevelSelector__down-list {
  min-width: 230px;
  z-index: 1010;
  text-align: left;
}
.AccessLevelSelector ul.AccessLevelSelector__down-list .section-end:last-of-type {
  display: none;
}
.AccessLevelSelector .AccessLevelSelector__menu {
  margin: 0 !important;
}
.AccessLevelSelector .AccessLevelSelector__item {
  position: relative;
}
.AccessLevelSelector .AccessLevelSelector__item,
.AccessLevelSelector .AccessLevelSelector__item.section-header {
  font-size: 14px;
}
.AccessLevelSelector .AccessLevelSelector__item.section-header,
.AccessLevelSelector .AccessLevelSelector__item i {
  color: #686868;
}
.AccessLevelSelector .AccessLevelSelector__item:not(.section-header) {
  padding: 6px 26px;
  color: #000;
}
.AccessLevelSelector .AccessLevelSelector__item:not(.section-header).checked {
  font-weight: 600;
}
.AccessLevelSelector .AccessLevelSelector__item:not(.section-header).checked:before {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 9px;
  border-width: 3px;
  border-style: solid;
  border-top: none;
  border-left: none;
  width: 4px;
  height: 8px;
  -webkit-transform: translateY(-7px) rotateZ(45deg);
  -moz-transform: translateY(-7px) rotateZ(45deg);
  -o-transform: translateY(-7px) rotateZ(45deg);
  -ms-transform: translateY(-7px) rotateZ(45deg);
  transform: translateY(-7px) rotateZ(45deg);
}
.AccessLevelSelector .AccessLevelSelector__item:not(.section-header):hover {
  color: #fff;
}
.AccessLevelSelector .AccessLevelSelector__item:not(.section-header):hover:before {
  border-color: #fff !important;
}
.AccessLevelSelector__item {
  padding-left: 22px;
  position: relative;
  margin: 0;
}
.AccessLevelSelector .AccessLevelSelector__btn {
  margin: 0 !important;
  padding: 0 14px 0 6px !important;
  position: relative;
}
.AccessLevelSelector .AccessLevelSelector__btn:after {
  content: "";
  position: absolute;
  right: 4px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  border: 4px solid transparent;
  border-top-color: #939393;
  border-bottom: none;
}
.AccessLevelSelector .AccessLevelSelector__btn > i {
  background-color: #aaa;
}
.AccessLevelSelector__ico {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  height: 100%;
}
.AccessLevelSelector__ico--preview,
.AccessLevelSelector__ico--checked {
  position: absolute;
  left: 0;
}
.AccessLevelSelector__ico--preview {
  fill: var(--gray7, #686868);
}
.AccessLevelSelector__ico--checked {
  -webkit-transform: translateY(1px);
  -moz-transform: translateY(1px);
  -o-transform: translateY(1px);
  -ms-transform: translateY(1px);
  transform: translateY(1px);
}
.AccessLevelSelector__emailModal .SimpleModal__body {
  width: 456px;
}
.AccessLevelSelector__emailModalMsg {
  margin-bottom: 17px;
}
.AccessLevelSelector__ico.AccessLevelSelector__ico--ba-option.AccessLevelSelector__ico--checked {
  position: absolute;
  top: 50%;
  left: 2px;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  fill: var(--gray9, #444);
}
.AccessLevelSelector__ico.AccessLevelSelector__ico--ba-option.AccessLevelSelector__ico--checked > svg {
  -webkit-transform: translateY(1px);
  -moz-transform: translateY(1px);
  -o-transform: translateY(1px);
  -ms-transform: translateY(1px);
  transform: translateY(1px);
}
.fab-MenuOption--active .AccessLevelSelector__ico.AccessLevelSelector__ico--ba-option.AccessLevelSelector__ico--checked {
  fill: var(--gray9, #444);
  color: var(--gray9, #444);
}
.fab-MenuOption--active .AccessLevelSelector__ico.AccessLevelSelector__ico--ba-option.AccessLevelSelector__ico--checked:hover {
  color: var(--gray9, #444);
  fill: var(--gray9, #444);
}
.AccessLevelSelector__item.AccessLevelSelector__item--ba-option:not(.section-header) {
  padding-left: 26px;
  position: relative;
}
.AccessLevelSelector__item.AccessLevelSelector__item--ba-option.checked {
  margin: 0;
  color: var(--gray9, #444);
  fill: var(--gray9, #444);
  font-weight: var(--font-weight-semibold, 600);
}
.AccessLevelSelector__item.AccessLevelSelector__item--ba-option.checked:hover {
  color: var(--gray9, #444);
  fill: var(--gray9, #444);
}
.fab-MenuOption--active .AccessLevelSelector__item.AccessLevelSelector__item--ba-option {
  color: var(--white, #fff);
  color: var(--gray9, #444);
}
.fab-MenuOption--active .AccessLevelSelector__item.AccessLevelSelector__item--ba-option:hover {
  color: var(--gray9, #444);
  fill: var(--gray9, #444);
}

td.dt-control {
  background: url("https://www.datatables.net/examples/resources/details_open.png") no-repeat center center;
  cursor: pointer;
}
tr.dt-hasChild td.dt-control {
  background: url("https://www.datatables.net/examples/resources/details_close.png") no-repeat center center;
}
table.dataTable th.dt-left,
table.dataTable td.dt-left {
  text-align: left;
}
table.dataTable th.dt-center,
table.dataTable td.dt-center,
table.dataTable td.dataTables_empty {
  text-align: center;
}
table.dataTable th.dt-right,
table.dataTable td.dt-right {
  text-align: right;
}
table.dataTable th.dt-justify,
table.dataTable td.dt-justify {
  text-align: justify;
}
table.dataTable th.dt-nowrap,
table.dataTable td.dt-nowrap {
  white-space: nowrap;
}
table.dataTable thead th.dt-head-left,
table.dataTable thead td.dt-head-left,
table.dataTable tfoot th.dt-head-left,
table.dataTable tfoot td.dt-head-left {
  text-align: left;
}
table.dataTable thead th.dt-head-center,
table.dataTable thead td.dt-head-center,
table.dataTable tfoot th.dt-head-center,
table.dataTable tfoot td.dt-head-center {
  text-align: center;
}
table.dataTable thead th.dt-head-right,
table.dataTable thead td.dt-head-right,
table.dataTable tfoot th.dt-head-right,
table.dataTable tfoot td.dt-head-right {
  text-align: right;
}
table.dataTable thead th.dt-head-justify,
table.dataTable thead td.dt-head-justify,
table.dataTable tfoot th.dt-head-justify,
table.dataTable tfoot td.dt-head-justify {
  text-align: justify;
}
table.dataTable thead th.dt-head-nowrap,
table.dataTable thead td.dt-head-nowrap,
table.dataTable tfoot th.dt-head-nowrap,
table.dataTable tfoot td.dt-head-nowrap {
  white-space: nowrap;
}
table.dataTable tbody th.dt-body-left,
table.dataTable tbody td.dt-body-left {
  text-align: left;
}
table.dataTable tbody th.dt-body-center,
table.dataTable tbody td.dt-body-center {
  text-align: center;
}
table.dataTable tbody th.dt-body-right,
table.dataTable tbody td.dt-body-right {
  text-align: right;
}
table.dataTable tbody th.dt-body-justify,
table.dataTable tbody td.dt-body-justify {
  text-align: justify;
}
table.dataTable tbody th.dt-body-nowrap,
table.dataTable tbody td.dt-body-nowrap {
  white-space: nowrap;
}
td.dt-control {
  background: url("https://www.datatables.net/examples/resources/details_open.png") no-repeat center center;
  cursor: pointer;
}
tr.dt-hasChild td.dt-control {
  background: url("https://www.datatables.net/examples/resources/details_close.png") no-repeat center center;
}
table.dataTable th.dt-left,
table.dataTable td.dt-left {
  text-align: left;
}
table.dataTable th.dt-center,
table.dataTable td.dt-center,
table.dataTable td.dataTables_empty {
  text-align: center;
}
table.dataTable th.dt-right,
table.dataTable td.dt-right {
  text-align: right;
}
table.dataTable th.dt-justify,
table.dataTable td.dt-justify {
  text-align: justify;
}
table.dataTable th.dt-nowrap,
table.dataTable td.dt-nowrap {
  white-space: nowrap;
}
table.dataTable thead th.dt-head-left,
table.dataTable thead td.dt-head-left,
table.dataTable tfoot th.dt-head-left,
table.dataTable tfoot td.dt-head-left {
  text-align: left;
}
table.dataTable thead th.dt-head-center,
table.dataTable thead td.dt-head-center,
table.dataTable tfoot th.dt-head-center,
table.dataTable tfoot td.dt-head-center {
  text-align: center;
}
table.dataTable thead th.dt-head-right,
table.dataTable thead td.dt-head-right,
table.dataTable tfoot th.dt-head-right,
table.dataTable tfoot td.dt-head-right {
  text-align: right;
}
table.dataTable thead th.dt-head-justify,
table.dataTable thead td.dt-head-justify,
table.dataTable tfoot th.dt-head-justify,
table.dataTable tfoot td.dt-head-justify {
  text-align: justify;
}
table.dataTable thead th.dt-head-nowrap,
table.dataTable thead td.dt-head-nowrap,
table.dataTable tfoot th.dt-head-nowrap,
table.dataTable tfoot td.dt-head-nowrap {
  white-space: nowrap;
}
table.dataTable tbody th.dt-body-left,
table.dataTable tbody td.dt-body-left {
  text-align: left;
}
table.dataTable tbody th.dt-body-center,
table.dataTable tbody td.dt-body-center {
  text-align: center;
}
table.dataTable tbody th.dt-body-right,
table.dataTable tbody td.dt-body-right {
  text-align: right;
}
table.dataTable tbody th.dt-body-justify,
table.dataTable tbody td.dt-body-justify {
  text-align: justify;
}
table.dataTable tbody th.dt-body-nowrap,
table.dataTable tbody td.dt-body-nowrap {
  white-space: nowrap;
}
table.dataTable {
  width: 100%;
  margin: 0 auto;
  clear: both;
  border-collapse: separate;
  border-spacing: 0;
/*
   * Header and footer styles
   */
/*
   * Body styles
   */
}
table.dataTable thead th,
table.dataTable tfoot th {
  font-weight: bold;
}
table.dataTable thead th,
table.dataTable thead td {
  padding: 10px 18px;
}
table.dataTable thead th:active,
table.dataTable thead td:active {
  outline: none;
}
table.dataTable tfoot th,
table.dataTable tfoot td {
  padding: 10px 18px 6px 18px;
}
table.dataTable tbody tr {
  background-color: #fff;
}
table.dataTable tbody tr.selected {
  background-color: #b0bed9;
}
table.dataTable tbody th,
table.dataTable tbody td {
  padding: 8px 10px;
}
table.dataTable.row-border tbody th,
table.dataTable.row-border tbody td,
table.dataTable.display tbody th,
table.dataTable.display tbody td {
  border-top: 1px solid #ddd;
}
table.dataTable.row-border tbody tr:first-child th,
table.dataTable.row-border tbody tr:first-child td,
table.dataTable.display tbody tr:first-child th,
table.dataTable.display tbody tr:first-child td {
  border-top: none;
}
table.dataTable.cell-border tbody th,
table.dataTable.cell-border tbody td {
  border-top: 1px solid #ddd;
  border-right: 1px solid #ddd;
}
table.dataTable.cell-border tbody tr th:first-child,
table.dataTable.cell-border tbody tr td:first-child {
  border-left: 1px solid #ddd;
}
table.dataTable.cell-border tbody tr:first-child th,
table.dataTable.cell-border tbody tr:first-child td {
  border-top: none;
}
table.dataTable.stripe tbody tr.odd,
table.dataTable.display tbody tr.odd {
  background-color: #f9f9f9;
}
table.dataTable.stripe tbody tr.odd.selected,
table.dataTable.display tbody tr.odd.selected {
  background-color: #acbad4;
}
table.dataTable.hover tbody tr:hover,
table.dataTable.display tbody tr:hover {
  background-color: #f6f6f6;
}
table.dataTable.hover tbody tr:hover.selected,
table.dataTable.display tbody tr:hover.selected {
  background-color: #aab7d1;
}
table.dataTable.order-column tbody tr > .sorting_1,
table.dataTable.order-column tbody tr > .sorting_2,
table.dataTable.order-column tbody tr > .sorting_3,
table.dataTable.display tbody tr > .sorting_1,
table.dataTable.display tbody tr > .sorting_2,
table.dataTable.display tbody tr > .sorting_3 {
  background-color: #fafafa;
}
table.dataTable.order-column tbody tr.selected > .sorting_1,
table.dataTable.order-column tbody tr.selected > .sorting_2,
table.dataTable.order-column tbody tr.selected > .sorting_3,
table.dataTable.display tbody tr.selected > .sorting_1,
table.dataTable.display tbody tr.selected > .sorting_2,
table.dataTable.display tbody tr.selected > .sorting_3 {
  background-color: #acbad5;
}
table.dataTable.display tbody tr.odd > .sorting_1,
table.dataTable.order-column.stripe tbody tr.odd > .sorting_1 {
  background-color: #f1f1f1;
}
table.dataTable.display tbody tr.odd > .sorting_2,
table.dataTable.order-column.stripe tbody tr.odd > .sorting_2 {
  background-color: #f3f3f3;
}
table.dataTable.display tbody tr.odd > .sorting_3,
table.dataTable.order-column.stripe tbody tr.odd > .sorting_3 {
  background-color: #f5f5f5;
}
table.dataTable.display tbody tr.odd.selected > .sorting_1,
table.dataTable.order-column.stripe tbody tr.odd.selected > .sorting_1 {
  background-color: #a6b4cd;
}
table.dataTable.display tbody tr.odd.selected > .sorting_2,
table.dataTable.order-column.stripe tbody tr.odd.selected > .sorting_2 {
  background-color: #a8b5cf;
}
table.dataTable.display tbody tr.odd.selected > .sorting_3,
table.dataTable.order-column.stripe tbody tr.odd.selected > .sorting_3 {
  background-color: #a9b7d1;
}
table.dataTable.display tbody tr.even > .sorting_1,
table.dataTable.order-column.stripe tbody tr.even > .sorting_1 {
  background-color: #fafafa;
}
table.dataTable.display tbody tr.even > .sorting_2,
table.dataTable.order-column.stripe tbody tr.even > .sorting_2 {
  background-color: #fcfcfc;
}
table.dataTable.display tbody tr.even > .sorting_3,
table.dataTable.order-column.stripe tbody tr.even > .sorting_3 {
  background-color: #fefefe;
}
table.dataTable.display tbody tr.even.selected > .sorting_1,
table.dataTable.order-column.stripe tbody tr.even.selected > .sorting_1 {
  background-color: #acbad5;
}
table.dataTable.display tbody tr.even.selected > .sorting_2,
table.dataTable.order-column.stripe tbody tr.even.selected > .sorting_2 {
  background-color: #aebcd6;
}
table.dataTable.display tbody tr.even.selected > .sorting_3,
table.dataTable.order-column.stripe tbody tr.even.selected > .sorting_3 {
  background-color: #afbdd8;
}
table.dataTable.display tbody tr:hover > .sorting_1,
table.dataTable.order-column.hover tbody tr:hover > .sorting_1 {
  background-color: #eaeaea;
}
table.dataTable.display tbody tr:hover > .sorting_2,
table.dataTable.order-column.hover tbody tr:hover > .sorting_2 {
  background-color: #ececec;
}
table.dataTable.display tbody tr:hover > .sorting_3,
table.dataTable.order-column.hover tbody tr:hover > .sorting_3 {
  background-color: #efefef;
}
table.dataTable.display tbody tr:hover.selected > .sorting_1,
table.dataTable.order-column.hover tbody tr:hover.selected > .sorting_1 {
  background-color: #a2aec7;
}
table.dataTable.display tbody tr:hover.selected > .sorting_2,
table.dataTable.order-column.hover tbody tr:hover.selected > .sorting_2 {
  background-color: #a3b0c9;
}
table.dataTable.display tbody tr:hover.selected > .sorting_3,
table.dataTable.order-column.hover tbody tr:hover.selected > .sorting_3 {
  background-color: #a5b2cb;
}
table.dataTable.no-footer {
  border-bottom: 1px solid #111;
}
table.dataTable.nowrap th,
table.dataTable.nowrap td {
  white-space: nowrap;
}
table.dataTable.compact thead th,
table.dataTable.compact thead td {
  padding: 4px 17px;
}
table.dataTable.compact tfoot th,
table.dataTable.compact tfoot td {
  padding: 4px;
}
table.dataTable.compact tbody th,
table.dataTable.compact tbody td {
  padding: 4px;
}
table.dataTable th,
table.dataTable td {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}
.dataTables_wrapper {
  position: relative;
  clear: both;
}
.dataTables_wrapper .dataTables_length {
  float: left;
}
.dataTables_wrapper .dataTables_length select {
  border: 1px solid #aaa;
  border-radius: 3px;
  padding: 5px;
  background-color: transparent;
  padding: 4px;
}
.dataTables_wrapper .dataTables_filter {
  float: right;
  text-align: right;
}
.dataTables_wrapper .dataTables_filter input {
  border: 1px solid #aaa;
  border-radius: 3px;
  padding: 5px;
  background-color: transparent;
  margin-left: 3px;
}
.dataTables_wrapper .dataTables_info {
  clear: both;
  float: left;
  padding-top: 0.755em;
}
.dataTables_wrapper .dataTables_paginate {
  float: right;
  text-align: right;
  padding-top: 0.25em;
}
.dataTables_wrapper .dataTables_paginate .paginate_button {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: inline-block;
  min-width: 1.5em;
  padding: 0.5em 1em;
  margin-left: 2px;
  text-align: center;
  text-decoration: none !important;
  cursor: pointer;
  *cursor: hand;
  color: #333 !important;
  border: 1px solid transparent;
  border-radius: 2px;
}
.dataTables_wrapper .dataTables_paginate .paginate_button.current,
.dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
  color: #333 !important;
  border: 1px solid #979797;
  background-color: #fff;
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #fff), color-stop(100%, #dcdcdc));
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #fff), color-stop(100%, #dcdcdc));
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #fff), color-stop(100%, #dcdcdc));
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #fff), color-stop(100%, #dcdcdc));
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #fff), color-stop(100%, #dcdcdc));
/* Chrome,Safari4+ */
  background: -webkit--webkit-linear-gradient(top, #fff 0%, #dcdcdc 100%);
  background: -webkit--moz-linear-gradient(top, #fff 0%, #dcdcdc 100%);
  background: -webkit--o-linear-gradient(top, #fff 0%, #dcdcdc 100%);
  background: -webkit--ms-linear-gradient(top, #fff 0%, #dcdcdc 100%);
  background: -webkit-linear-gradient(to bottom, #fff 0%, #dcdcdc 100%);
/* Chrome10+,Safari5.1+ */
  background: -moz--webkit-linear-gradient(top, #fff 0%, #dcdcdc 100%);
  background: -moz--moz-linear-gradient(top, #fff 0%, #dcdcdc 100%);
  background: -moz--o-linear-gradient(top, #fff 0%, #dcdcdc 100%);
  background: -moz--ms-linear-gradient(top, #fff 0%, #dcdcdc 100%);
  background: -moz-linear-gradient(to bottom, #fff 0%, #dcdcdc 100%);
/* FF3.6+ */
  background: -ms--webkit-linear-gradient(top, #fff 0%, #dcdcdc 100%);
  background: -ms--moz-linear-gradient(top, #fff 0%, #dcdcdc 100%);
  background: -ms--o-linear-gradient(top, #fff 0%, #dcdcdc 100%);
  background: -ms--ms-linear-gradient(top, #fff 0%, #dcdcdc 100%);
  background: -ms-linear-gradient(to bottom, #fff 0%, #dcdcdc 100%);
/* IE10+ */
  background: -o--webkit-linear-gradient(top, #fff 0%, #dcdcdc 100%);
  background: -o--moz-linear-gradient(top, #fff 0%, #dcdcdc 100%);
  background: -o--o-linear-gradient(top, #fff 0%, #dcdcdc 100%);
  background: -o--ms-linear-gradient(top, #fff 0%, #dcdcdc 100%);
  background: -o-linear-gradient(to bottom, #fff 0%, #dcdcdc 100%);
/* Opera 11.10+ */
  background: -webkit-linear-gradient(top, #fff 0%, #dcdcdc 100%);
  background: -moz-linear-gradient(top, #fff 0%, #dcdcdc 100%);
  background: -o-linear-gradient(top, #fff 0%, #dcdcdc 100%);
  background: -ms-linear-gradient(top, #fff 0%, #dcdcdc 100%);
  background: linear-gradient(to bottom, #fff 0%, #dcdcdc 100%);
/* W3C */
}
.dataTables_wrapper .dataTables_paginate .paginate_button.disabled,
.dataTables_wrapper .dataTables_paginate .paginate_button.disabled:hover,
.dataTables_wrapper .dataTables_paginate .paginate_button.disabled:active {
  cursor: default;
  color: #666 !important;
  border: 1px solid transparent;
  background: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.dataTables_wrapper .dataTables_paginate .paginate_button:hover {
  color: #fff !important;
  border: 1px solid #111;
  background-color: #585858;
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #585858), color-stop(100%, #111));
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #585858), color-stop(100%, #111));
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #585858), color-stop(100%, #111));
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #585858), color-stop(100%, #111));
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #585858), color-stop(100%, #111));
/* Chrome,Safari4+ */
  background: -webkit--webkit-linear-gradient(top, #585858 0%, #111 100%);
  background: -webkit--moz-linear-gradient(top, #585858 0%, #111 100%);
  background: -webkit--o-linear-gradient(top, #585858 0%, #111 100%);
  background: -webkit--ms-linear-gradient(top, #585858 0%, #111 100%);
  background: -webkit-linear-gradient(to bottom, #585858 0%, #111 100%);
/* Chrome10+,Safari5.1+ */
  background: -moz--webkit-linear-gradient(top, #585858 0%, #111 100%);
  background: -moz--moz-linear-gradient(top, #585858 0%, #111 100%);
  background: -moz--o-linear-gradient(top, #585858 0%, #111 100%);
  background: -moz--ms-linear-gradient(top, #585858 0%, #111 100%);
  background: -moz-linear-gradient(to bottom, #585858 0%, #111 100%);
/* FF3.6+ */
  background: -ms--webkit-linear-gradient(top, #585858 0%, #111 100%);
  background: -ms--moz-linear-gradient(top, #585858 0%, #111 100%);
  background: -ms--o-linear-gradient(top, #585858 0%, #111 100%);
  background: -ms--ms-linear-gradient(top, #585858 0%, #111 100%);
  background: -ms-linear-gradient(to bottom, #585858 0%, #111 100%);
/* IE10+ */
  background: -o--webkit-linear-gradient(top, #585858 0%, #111 100%);
  background: -o--moz-linear-gradient(top, #585858 0%, #111 100%);
  background: -o--o-linear-gradient(top, #585858 0%, #111 100%);
  background: -o--ms-linear-gradient(top, #585858 0%, #111 100%);
  background: -o-linear-gradient(to bottom, #585858 0%, #111 100%);
/* Opera 11.10+ */
  background: -webkit-linear-gradient(top, #585858 0%, #111 100%);
  background: -moz-linear-gradient(top, #585858 0%, #111 100%);
  background: -o-linear-gradient(top, #585858 0%, #111 100%);
  background: -ms-linear-gradient(top, #585858 0%, #111 100%);
  background: linear-gradient(to bottom, #585858 0%, #111 100%);
/* W3C */
}
.dataTables_wrapper .dataTables_paginate .paginate_button:active {
  outline: none;
  background-color: #2b2b2b;
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #2b2b2b), color-stop(100%, #0c0c0c));
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #2b2b2b), color-stop(100%, #0c0c0c));
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #2b2b2b), color-stop(100%, #0c0c0c));
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #2b2b2b), color-stop(100%, #0c0c0c));
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #2b2b2b), color-stop(100%, #0c0c0c));
/* Chrome,Safari4+ */
  background: -webkit--webkit-linear-gradient(top, #2b2b2b 0%, #0c0c0c 100%);
  background: -webkit--moz-linear-gradient(top, #2b2b2b 0%, #0c0c0c 100%);
  background: -webkit--o-linear-gradient(top, #2b2b2b 0%, #0c0c0c 100%);
  background: -webkit--ms-linear-gradient(top, #2b2b2b 0%, #0c0c0c 100%);
  background: -webkit-linear-gradient(to bottom, #2b2b2b 0%, #0c0c0c 100%);
/* Chrome10+,Safari5.1+ */
  background: -moz--webkit-linear-gradient(top, #2b2b2b 0%, #0c0c0c 100%);
  background: -moz--moz-linear-gradient(top, #2b2b2b 0%, #0c0c0c 100%);
  background: -moz--o-linear-gradient(top, #2b2b2b 0%, #0c0c0c 100%);
  background: -moz--ms-linear-gradient(top, #2b2b2b 0%, #0c0c0c 100%);
  background: -moz-linear-gradient(to bottom, #2b2b2b 0%, #0c0c0c 100%);
/* FF3.6+ */
  background: -ms--webkit-linear-gradient(top, #2b2b2b 0%, #0c0c0c 100%);
  background: -ms--moz-linear-gradient(top, #2b2b2b 0%, #0c0c0c 100%);
  background: -ms--o-linear-gradient(top, #2b2b2b 0%, #0c0c0c 100%);
  background: -ms--ms-linear-gradient(top, #2b2b2b 0%, #0c0c0c 100%);
  background: -ms-linear-gradient(to bottom, #2b2b2b 0%, #0c0c0c 100%);
/* IE10+ */
  background: -o--webkit-linear-gradient(top, #2b2b2b 0%, #0c0c0c 100%);
  background: -o--moz-linear-gradient(top, #2b2b2b 0%, #0c0c0c 100%);
  background: -o--o-linear-gradient(top, #2b2b2b 0%, #0c0c0c 100%);
  background: -o--ms-linear-gradient(top, #2b2b2b 0%, #0c0c0c 100%);
  background: -o-linear-gradient(to bottom, #2b2b2b 0%, #0c0c0c 100%);
/* Opera 11.10+ */
  background: -webkit-linear-gradient(top, #2b2b2b 0%, #0c0c0c 100%);
  background: -moz-linear-gradient(top, #2b2b2b 0%, #0c0c0c 100%);
  background: -o-linear-gradient(top, #2b2b2b 0%, #0c0c0c 100%);
  background: -ms-linear-gradient(top, #2b2b2b 0%, #0c0c0c 100%);
  background: linear-gradient(to bottom, #2b2b2b 0%, #0c0c0c 100%);
/* W3C */
  -webkit-box-shadow: inset 0 0 3px #111;
  box-shadow: inset 0 0 3px #111;
}
.dataTables_wrapper .dataTables_paginate .ellipsis {
  padding: 0 1em;
}
.dataTables_wrapper .dataTables_processing {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 40px;
  margin-left: -50%;
  margin-top: -25px;
  padding-top: 20px;
  text-align: center;
  font-size: 1.2em;
  background-color: #fff;
  background: -webkit-gradient(linear, left top, right top, color-stop(0%, rgba(255,255,255,0)), color-stop(25%, rgba(255,255,255,0.9)), color-stop(75%, rgba(255,255,255,0.9)), color-stop(100%, rgba(255,255,255,0)));
  background: -webkit-gradient(linear, left top, right top, color-stop(0%, rgba(255,255,255,0)), color-stop(25%, rgba(255,255,255,0.9)), color-stop(75%, rgba(255,255,255,0.9)), color-stop(100%, rgba(255,255,255,0)));
  background: -webkit-gradient(linear, left top, right top, color-stop(0%, rgba(255,255,255,0)), color-stop(25%, rgba(255,255,255,0.9)), color-stop(75%, rgba(255,255,255,0.9)), color-stop(100%, rgba(255,255,255,0)));
  background: -webkit-gradient(linear, left top, right top, color-stop(0%, rgba(255,255,255,0)), color-stop(25%, rgba(255,255,255,0.9)), color-stop(75%, rgba(255,255,255,0.9)), color-stop(100%, rgba(255,255,255,0)));
  background: -webkit-gradient(linear, left top, right top, color-stop(0%, rgba(255,255,255,0)), color-stop(25%, rgba(255,255,255,0.9)), color-stop(75%, rgba(255,255,255,0.9)), color-stop(100%, rgba(255,255,255,0)));
  background: -webkit--webkit-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,0.9) 25%, rgba(255,255,255,0.9) 75%, rgba(255,255,255,0) 100%);
  background: -webkit--moz-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,0.9) 25%, rgba(255,255,255,0.9) 75%, rgba(255,255,255,0) 100%);
  background: -webkit--o-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,0.9) 25%, rgba(255,255,255,0.9) 75%, rgba(255,255,255,0) 100%);
  background: -webkit--ms-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,0.9) 25%, rgba(255,255,255,0.9) 75%, rgba(255,255,255,0) 100%);
  background: -webkit-linear-gradient(to right, rgba(255,255,255,0) 0%, rgba(255,255,255,0.9) 25%, rgba(255,255,255,0.9) 75%, rgba(255,255,255,0) 100%);
  background: -moz--webkit-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,0.9) 25%, rgba(255,255,255,0.9) 75%, rgba(255,255,255,0) 100%);
  background: -moz--moz-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,0.9) 25%, rgba(255,255,255,0.9) 75%, rgba(255,255,255,0) 100%);
  background: -moz--o-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,0.9) 25%, rgba(255,255,255,0.9) 75%, rgba(255,255,255,0) 100%);
  background: -moz--ms-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,0.9) 25%, rgba(255,255,255,0.9) 75%, rgba(255,255,255,0) 100%);
  background: -moz-linear-gradient(to right, rgba(255,255,255,0) 0%, rgba(255,255,255,0.9) 25%, rgba(255,255,255,0.9) 75%, rgba(255,255,255,0) 100%);
  background: -ms--webkit-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,0.9) 25%, rgba(255,255,255,0.9) 75%, rgba(255,255,255,0) 100%);
  background: -ms--moz-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,0.9) 25%, rgba(255,255,255,0.9) 75%, rgba(255,255,255,0) 100%);
  background: -ms--o-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,0.9) 25%, rgba(255,255,255,0.9) 75%, rgba(255,255,255,0) 100%);
  background: -ms--ms-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,0.9) 25%, rgba(255,255,255,0.9) 75%, rgba(255,255,255,0) 100%);
  background: -ms-linear-gradient(to right, rgba(255,255,255,0) 0%, rgba(255,255,255,0.9) 25%, rgba(255,255,255,0.9) 75%, rgba(255,255,255,0) 100%);
  background: -o--webkit-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,0.9) 25%, rgba(255,255,255,0.9) 75%, rgba(255,255,255,0) 100%);
  background: -o--moz-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,0.9) 25%, rgba(255,255,255,0.9) 75%, rgba(255,255,255,0) 100%);
  background: -o--o-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,0.9) 25%, rgba(255,255,255,0.9) 75%, rgba(255,255,255,0) 100%);
  background: -o--ms-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,0.9) 25%, rgba(255,255,255,0.9) 75%, rgba(255,255,255,0) 100%);
  background: -o-linear-gradient(to right, rgba(255,255,255,0) 0%, rgba(255,255,255,0.9) 25%, rgba(255,255,255,0.9) 75%, rgba(255,255,255,0) 100%);
  background: -webkit-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,0.9) 25%, rgba(255,255,255,0.9) 75%, rgba(255,255,255,0) 100%);
  background: -moz-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,0.9) 25%, rgba(255,255,255,0.9) 75%, rgba(255,255,255,0) 100%);
  background: -o-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,0.9) 25%, rgba(255,255,255,0.9) 75%, rgba(255,255,255,0) 100%);
  background: -ms-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,0.9) 25%, rgba(255,255,255,0.9) 75%, rgba(255,255,255,0) 100%);
  background: linear-gradient(to right, rgba(255,255,255,0) 0%, rgba(255,255,255,0.9) 25%, rgba(255,255,255,0.9) 75%, rgba(255,255,255,0) 100%);
}
.dataTables_wrapper .dataTables_length,
.dataTables_wrapper .dataTables_filter,
.dataTables_wrapper .dataTables_info,
.dataTables_wrapper .dataTables_processing,
.dataTables_wrapper .dataTables_paginate {
  color: #333;
}
.dataTables_wrapper .dataTables_scroll {
  clear: both;
}
.dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody {
  *margin-top: -1px;
  -webkit-overflow-scrolling: touch;
}
.dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody > table > thead > tr > th,
.dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody > table > thead > tr > td,
.dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody > table > tbody > tr > th,
.dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody > table > tbody > tr > td {
  vertical-align: middle;
}
.dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody > table > thead > tr > th > div.dataTables_sizing,
.dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody > table > thead > tr > td > div.dataTables_sizing,
.dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody > table > tbody > tr > th > div.dataTables_sizing,
.dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody > table > tbody > tr > td > div.dataTables_sizing {
  height: 0;
  overflow: hidden;
  margin: 0 !important;
  padding: 0 !important;
}
.dataTables_wrapper.no-footer .dataTables_scrollBody {
  border-bottom: 1px solid #111;
}
.dataTables_wrapper.no-footer div.dataTables_scrollHead table.dataTable,
.dataTables_wrapper.no-footer div.dataTables_scrollBody > table {
  border-bottom: none;
}
.dataTables_wrapper:after {
  visibility: hidden;
  display: block;
  content: "";
  clear: both;
  height: 0;
}
@media screen and (max-width: 767px) {
  .dataTables_wrapper .dataTables_info,
  .dataTables_wrapper .dataTables_paginate {
    float: none;
    text-align: center;
  }
  .dataTables_wrapper .dataTables_paginate {
    margin-top: 0.5em;
  }
}
@media screen and (max-width: 640px) {
  .dataTables_wrapper .dataTables_length,
  .dataTables_wrapper .dataTables_filter {
    float: none;
    text-align: center;
  }
  .dataTables_wrapper .dataTables_filter {
    margin-top: 0.5em;
  }
}
table.dataTable thead th div.DataTables_sort_wrapper {
  position: relative;
}
table.dataTable thead th div.DataTables_sort_wrapper span {
  position: absolute;
  top: 50%;
  margin-top: -8px;
  right: -18px;
}
table.dataTable thead th.ui-state-default,
table.dataTable tfoot th.ui-state-default {
  border-left-width: 0;
}
table.dataTable thead th.ui-state-default:first-child,
table.dataTable tfoot th.ui-state-default:first-child {
  border-left-width: 1px;
}
.dataTables_wrapper .dataTables_paginate .fg-button {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: inline-block;
  min-width: 1.5em;
  padding: 0.5em;
  margin-left: 2px;
  text-align: center;
  text-decoration: none !important;
  cursor: pointer;
  *cursor: hand;
  border: 1px solid transparent;
}
.dataTables_wrapper .dataTables_paginate .fg-button:active {
  outline: none;
}
.dataTables_wrapper .dataTables_paginate .fg-button:first-child {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}
.dataTables_wrapper .dataTables_paginate .fg-button:last-child {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}
.dataTables_wrapper .ui-widget-header {
  font-weight: normal;
}
.dataTables_wrapper .ui-toolbar {
  padding: 8px;
}
.dataTables_wrapper.no-footer .dataTables_scrollBody {
  border-bottom: none;
}
.dataTables_wrapper .dataTables_length,
.dataTables_wrapper .dataTables_filter,
.dataTables_wrapper .dataTables_info,
.dataTables_wrapper .dataTables_processing,
.dataTables_wrapper .dataTables_paginate {
  color: inherit;
}
.DataTable {
  padding: 0;
  width: 100%;
}
.DataTable__container {
  min-width: 100% !important;
}
.DataTable tbody {
  -webkit-transition: opacity 0.4s;
  -moz-transition: opacity 0.4s;
  -o-transition: opacity 0.4s;
  -ms-transition: opacity 0.4s;
  transition: opacity 0.4s;
}
.DataTable tbody tr.DataTable__row--active {
  background: #f0f0f0 !important;
  background-color: var(--theme-lightest10-default, rgba(192,233,79,0.1)) !important;
  background-color: var(--fabric-theme-lightest10, rgba(192,233,79,0.1)) !important;
}
.DataTable--no-sort th {
  cursor: default !important;
}
.DataTable--no-sort th:after {
  display: none !important;
}
.DataTable--noScroll .dataTables_scrollBody {
  overflow: visible !important;
}
.DataTable--tight.DataTable--show-children tbody tr:not(.DataTable__row--child) > td {
  padding: 6px 10px 7px;
}
.DataTable:not(.DataTable--show-children) .DataTable__row--child {
  display: none;
}
.DataTable--processing tbody {
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
}
.DataTable__filter-buttons {
  display: none !important;
}
.DataTable__filter-buttons .DataTable__reset-filters {
  min-width: 0;
  color: #006ec2;
  font-weight: normal;
}
.DataTable__pagination {
  -webkit-box-pack: justify;
  -moz-box-pack: justify;
  -o-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  line-height: 24px;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-lines: single;
  -moz-box-lines: single;
  -o-box-lines: single;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-orient: horizontal;
  -moz-box-orient: horizontal;
  -o-box-orient: horizontal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  padding-top: 16px;
}
:where(body[data-fabric-theme~="encore"]) .DataTable__pagination:empty {
  padding-top: 0;
}
.DataTable__pagination .dataTables_info {
  color: var(--gray5, #adadad);
  font-size: var(--font-size-small, 14px);
}
.DataTable__pagination .dataTables_paginate {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-lines: single;
  -moz-box-lines: single;
  -o-box-lines: single;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-orient: horizontal;
  -moz-box-orient: horizontal;
  -o-box-orient: horizontal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}
.DataTable__pagination .dataTables_paginate * {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.DataTable__pagination .dataTables_paginate > span {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-lines: single;
  -moz-box-lines: single;
  -o-box-lines: single;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-orient: horizontal;
  -moz-box-orient: horizontal;
  -o-box-orient: horizontal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}
.DataTable__filters {
  padding-bottom: 15px;
  margin-top: -4px;
  float: left;
}
.DataTable__filters:not(.DataTable__filters--active) .chzn-single {
  background: #fff;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.DataTable__filters:not(.DataTable__filters--active):not(:hover) .chzn-single {
  border-color: #e4e4e4;
}
.DataTable__filters:not(.DataTable__filters--active):not(:hover) .chzn-single > div b {
  display: none;
}
.DataTable__filters:not(.DataTable__filters--active):not(:hover) .search-choice-close {
  display: none;
}
.DataTable__filters:not(.DataTable__filters--active):not(:hover) .EmployeeFilters__link--add {
  display: none !important;
}
.DataTable__filters select {
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
}
.DataTable__filters .chzn-search input[type="text"] {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  max-width: 100%;
}
.DataTable__filters--active .DataTable__filter-buttons {
  display: inline-block !important;
}
.DataTable__filters > div {
  display: inline-block;
  padding-right: 15px;
  padding-top: 4px;
}
.DataTable__filters .chzn-container,
.DataTable__filters .chzn-drop {
  max-width: 150px;
}
.DataTable__filters .chzn-drop {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.DataTable__filters .EmployeeFilters {
  margin: 0 -3px;
}
.DataTable__filters .EmployeeFilters__selected-container .chzn-container {
  margin-right: 15px;
  width: auto !important;
}
.DataTable__filters .EmployeeFilters__selected-container .chzn-container,
.DataTable__filters .EmployeeFilters__selected-container .chzn-container .chzn-drop {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.DataTable__filters .EmployeeFilters__link--add {
  margin: 0 -15px;
}
.DataTable__cell {
  width: 150px;
  min-width: 150px;
  max-width: 150px;
}
.DataTable__cell > input,
.DataTable__cell > select,
.DataTable__cell > .chzn-container,
.DataTable__cell .chzn-drop {
  width: 135px;
  min-width: 135px;
  max-width: 135px;
}
.DataTable__cell.errorColumn > input,
.DataTable__cell.errorColumn > select,
.DataTable__cell.errorColumn > .chzn-container,
.DataTable__cell.errorColumn .chzn-drop {
  width: 115px;
  min-width: 115px;
  max-width: 115px;
}
.DataTable__cell--actions .btn {
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
}
.DataTable__cell--actions:hover {
  opacity: 1;
  -ms-filter: none;
  filter: none;
}
.DataTable .dataTables_wrapper .dataTables_scrollBody {
  border-bottom: none;
}
.DataTable table.dataTable {
  border: none;
  width: 100%;
}
.DataTable table.dataTable thead tr {
  background: #ebebeb;
}
.DataTable table.dataTable thead th {
  border: 1px solid #979797;
  border-left: none;
  border-right: none;
  padding: 12px 16px 11px;
  background-color: var(--gray2, #ebebeb);
  color: var(--gray8, #555);
}
:where(body[data-fabric-theme~="encore"]) .DataTable table.dataTable thead th {
  border: none;
  padding: 13px 16px;
  background-color: var(--gray1, #f4f4f4);
  font-size: var(--font-size-medium);
  font-weight: var(--font-weight-semibold);
}
.DataTable table.dataTable thead th.sorting,
.DataTable table.dataTable thead th.sorting_asc,
.DataTable table.dataTable thead th.sorting_desc {
  background-image: none;
  position: relative;
}
.DataTable table.dataTable thead th.fab-Table__header--sortable {
  cursor: pointer !important;
}
.DataTable table.dataTable thead th.fab-Table__header--sortable:hover {
  background-color: var(--gray3, #e0e0e0);
}
:where(body[data-fabric-theme~="encore"]) .DataTable table.dataTable thead th.fab-Table__header--sortable:hover {
  background-color: var(--gray1);
}
.DataTable table.dataTable th,
.DataTable table.dataTable td {
  vertical-align: middle;
  text-align: left;
  min-width: 80px;
  max-width: 220px;
  white-space: normal;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.DataTable table.dataTable th.no-wrap,
.DataTable table.dataTable td.no-wrap {
  white-space: nowrap !important;
}
.DataTable table.dataTable th.DataTable__cell--lastLogin,
.DataTable table.dataTable td.DataTable__cell--lastLogin {
  padding-right: 0;
}
.DataTable table.dataTable th.DataTable__cell:last-of-type,
.DataTable table.dataTable td.DataTable__cell:last-of-type {
  max-width: 100%;
  width: auto;
}
.DataTable table.dataTable td {
  padding: 13px 17px;
}
.DataTable table.dataTable td.dataTables_empty {
  background: #fff;
  border: none !important;
  padding: 32px 20px !important;
  font-weight: normal;
}
.DataTable--tight.DataTable table.dataTable td {
  padding: 9px 12px;
}
.DataTable table.dataTable tbody tr.fab-Table__row--selected,
.DataTable table.dataTable tbody tr.fab-Table__row--highlighted {
  background-color: var(--theme-lightest10-default, rgba(192,233,79,0.1));
  background-color: var(--fabric-theme-lightest10, rgba(192,233,79,0.1));
}
.DataTable table.dataTable tbody tr td {
  border-bottom: 1px solid #d9d9d9;
}
.DataTable table.dataTable tbody tr td > *,
.DataTable table.dataTable tbody tr td input,
.DataTable table.dataTable tbody tr td select,
.DataTable table.dataTable tbody tr td .chzn-drop {
  margin: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
:where(body[data-fabric-theme~="encore"]) .DataTable table.dataTable tbody tr td {
  border-bottom: 1px #f4f4f4 solid;
}
:where(body[data-fabric-theme~="encore"]) .DataTable table.dataTable tbody tr td > ba-select {
  display: inline !important;
}
.DataTable table.dataTable tbody tr td .chzn-container:not(.chzn-container-active) .chzn-drop {
  display: none;
}
.DataTable table.dataTable tbody tr td .ui-datepicker-trigger {
  top: 0;
}
.DataTable:not(.DataTable--no-alt-rows).DataTable table.dataTable tbody tr:nth-child(even) {
  background: #fbfbfb;
}
:where(body[data-fabric-theme~="encore"]) .DataTable:not(.DataTable--no-alt-rows).DataTable table.dataTable tbody tr:nth-child(even) {
  background: #fff;
}
.DataTable .dataTables_scroll {
  position: relative;
}
.DataTable .dataTables_scrollHead th.sorting_asc:after,
.DataTable .dataTables_scrollHead th.sorting_desc:after {
  content: "";
  display: none;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border: 4px solid transparent;
  margin-left: 6px;
}
.DataTable .dataTables_scrollHead th.sorting_asc:after {
  border-bottom-color: #555;
  border-top-width: 0;
}
.DataTable .dataTables_scrollHead th.sorting_desc:after {
  border-top-color: #555;
  border-bottom-width: 0;
}
.DataTable .dataTables_info,
.DataTable .dataTables_paginate {
  padding: 0;
}
body:not([data-feature-toggles~="jade"]) .DataTable .dataTables_info {
  color: #777;
  padding-right: 14px;
  line-height: 17px;
}
body:not([data-feature-toggles~="jade"]) .DataTable .dataTables_paginate {
  line-height: 15px;
}
.DataTable--singlePage.DataTable .dataTables_paginate {
  display: none;
}
body:not([data-feature-toggles~="jade"]) .DataTable .dataTables_paginate > span {
  float: left;
}
.DataTable .dataTables_paginate .paginate_button {
  background: none !important;
  text-align: center;
  font-size: var(--font-size-small, 14px);
  line-height: var(--line-height-medium, 22px);
  height: 24px;
  border-radius: 2px;
  border: 1px solid transparent;
  background-color: var(--gray1, #f4f4f4);
  margin-left: 8px;
  padding: 0 8px;
  text-decoration: none !important;
}
.DataTable .dataTables_paginate .paginate_button[data-dt-idx="1"]:last-of-type,
.DataTable .dataTables_paginate .paginate_button.next[data-dt-idx="2"],
.DataTable .dataTables_paginate .paginate_button.previous.disabled {
  display: none;
}
.DataTable .dataTables_paginate .paginate_button.current {
  background-color: #fff !important;
  border-color: var(--theme-lighter-default, #82af13);
  border-color: var(--fabric-theme-lighter, #82af13);
  color: var(--theme-lighter-default, #82af13) !important;
  color: var(--fabric-theme-lighter, #82af13) !important;
}
.DataTable .dataTables_paginate .paginate_button.disabled,
.DataTable .dataTables_paginate .paginate_button.disabled:hover {
  color: #bbb !important;
  border: none !important;
}
.DataTable .dataTables_paginate .paginate_button.previous:not(.disabled),
.DataTable .dataTables_paginate .paginate_button.next:not(.disabled) {
  color: #0772b3 !important;
}
.DataTable .dataTables_paginate .paginate_button.previous:not(.disabled):hover,
.DataTable .dataTables_paginate .paginate_button.next:not(.disabled):hover {
  color: var(--theme-base-default, #527a00) !important;
  color: var(--fabric-theme-base, #527a00) !important;
  text-decoration: underline !important;
}
.DataTable .dataTables_paginate .paginate_button.previous {
  margin-left: 4px;
}
.DataTable .dataTables_paginate .paginate_button.next.disabled {
  color: #adadad !important;
}
.DataTable .dataTables_paginate .paginate_button:not(.disabled):not(.next):not(.previous):not(:hover) {
  color: #686868 !important;
}
.DataTable .dataTables_paginate .paginate_button:not(.disabled):not(.next):not(.previous):hover {
  background-color: var(--theme-lighter-default, #82af13) !important;
  background-color: var(--fabric-theme-lighter, #82af13) !important;
  color: #fff !important;
}
.DataTable .dataTables_paginate .paginate_button + .ellipsis {
  padding: 0 !important;
  color: #777;
}
.DataTable .DTFC_LeftWrapper,
.DataTable .DTFC_RightWrapper {
  height: auto !important;
}
.DataTable .DTFC_LeftWrapper:before,
.DataTable .DTFC_RightWrapper:before {
  content: "";
  position: absolute;
  display: block;
  top: 0;
  bottom: 0;
  width: 1px;
  z-index: 1000;
  border: 0 solid #979797;
  border-color: #ccc;
}
:where(body[data-fabric-theme~="encore"]) .DataTable .DTFC_LeftWrapper:before,
:where(body[data-fabric-theme~="encore"]) .DataTable .DTFC_RightWrapper:before {
  border-color: var(--gray2);
}
:where(body[data-fabric-theme~="encore"]) .DataTable .DTFC_LeftHeadWrapper {
  border-radius: 8px 0 0 8px !important;
}
.DataTable .DTFC_LeftWrapper {
  position: relative;
  z-index: 2;
  background-color: #fff;
}
.DataTable .DTFC_LeftWrapper:after {
  content: "";
  position: absolute;
  display: block;
  z-index: 1000;
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  -ms-transition: all 0.3s linear;
  transition: all 0.3s linear;
  border-radius: 21px;
  right: 0;
  outline: 28px solid transparent;
  top: 0;
  bottom: 0;
  width: 28px;
  -webkit-box-shadow: 0 0 28px rgba(0,0,0,0.35), 0 0 9.333333333333334px rgba(0,0,0,0.35);
  box-shadow: 0 0 28px rgba(0,0,0,0.35), 0 0 9.333333333333334px rgba(0,0,0,0.35);
  clip: rect(0, 56px, 10000px, 28px);
}
.DataTable:not(.DataTable--scrolled-left) .DTFC_LeftWrapper:after {
  content: "";
  position: absolute;
  display: block;
  z-index: 1000;
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  -ms-transition: all 0.3s linear;
  transition: all 0.3s linear;
  border-radius: 21px;
  -webkit-box-shadow: 0 0 0 rgba(0,0,0,0.6), 0 0 0 rgba(0,0,0,0.6);
  box-shadow: 0 0 0 rgba(0,0,0,0.6), 0 0 0 rgba(0,0,0,0.6);
  border-radius: 0;
}
.DataTable .DTFC_LeftWrapper:before {
  border-right-width: 2px;
  right: 0;
}
:where(body[data-fabric-theme~="encore"]) .DataTable .DTFC_LeftWrapper:before {
  border-right-width: 1px;
}
.DataTable:not(.DataTable--hasLeftColumns) .DTFC_LeftWrapper:before {
  border-right: none;
}
:where(body[data-fabric-theme~="encore"]) .DataTable .DTFC_LeftWrapper th:only-of-type {
  border-radius: 8px 0 0 8px;
}
.DataTable .DTFC_RightWrapper {
  position: relative;
  bottom: 0;
}
.DataTable .DTFC_RightWrapper:after {
  content: "";
  position: absolute;
  display: block;
  z-index: 1000;
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  -ms-transition: all 0.3s linear;
  transition: all 0.3s linear;
  border-radius: 21px;
  left: 0;
  outline: 28px solid transparent;
  top: 0;
  bottom: 0;
  width: 28px;
  -webkit-box-shadow: 0 0 28px rgba(0,0,0,0.35), 0 0 9.333333333333334px rgba(0,0,0,0.35);
  box-shadow: 0 0 28px rgba(0,0,0,0.35), 0 0 9.333333333333334px rgba(0,0,0,0.35);
/*
				*  Turns out clip-path isn't ready for the big time,
				*  commenting out until browser support catches up
				*/
  clip: rect(0, 0, 10000px, -28px);
}
.DataTable:not(.DataTable--scrolled-right) .DTFC_RightWrapper:after {
  content: "";
  position: absolute;
  display: block;
  z-index: 1000;
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  -ms-transition: all 0.3s linear;
  transition: all 0.3s linear;
  border-radius: 21px;
  -webkit-box-shadow: 0 0 0 rgba(0,0,0,0.6), 0 0 0 rgba(0,0,0,0.6);
  box-shadow: 0 0 0 rgba(0,0,0,0.6), 0 0 0 rgba(0,0,0,0.6);
  border-radius: 0;
}
.DataTable .DTFC_RightWrapper:before {
  border-left-width: 1px;
  left: 0;
}
.DataTable:not(.DataTable--hasRightColumns) .DTFC_RightWrapper:before {
  border-left: none;
}
.DataTable .DTFC_RightHeadBlocker {
  background: -webkit-linear-gradient(top, #f3f3f3 0, #eee 100%);
  background: -moz-linear-gradient(top, #f3f3f3 0, #eee 100%);
  background: -o-linear-gradient(top, #f3f3f3 0, #eee 100%);
  background: -ms-linear-gradient(top, #f3f3f3 0, #eee 100%);
  background: linear-gradient(to bottom, #f3f3f3 0, #eee 100%);
  border: 1px solid #979797;
  border-left: none;
  border-right: none;
}
:where(body[data-fabric-theme~="encore"]) .DataTable .DTFC_RightHeadBlocker {
  background: var(--white);
  border: none !important;
}
.DataTable .DTFC_RightHeadBlocker:last-child {
  left: 0;
  right: 0;
  z-index: 100;
}
.DataTable .DTFC_ScrollWrapper {
  border-right: 2px solid #ccc;
}
:where(body[data-fabric-theme~="encore"]) .DataTable .DTFC_ScrollWrapper {
  border-right: unset;
  height: auto !important;
}

.SetAccessLevelModal__header {
  background-color: var(--gray1, #f4f4f4);
  padding: 12px 16px 16px;
  margin: -12px -16px 24px;
  border-bottom: 1px solid #ccc;
}
.SetAccessLevelModal__headerTitle {
  font-size: var(--font-size-large, 16px);
  line-height: var(--line-height-large, 24px);
  font-weight: var(--font-weight-semibold, 600);
}
.SetAccessLevelModal__headerListItem {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  fill: var(--gray8, #555);
  margin-top: 2px;
}
.SetAccessLevelModal__headerListItemIco {
  margin-right: 8px;
}

.EmployeeSelectorReact {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  margin: 10px 0;
  padding: 0;
  max-width: 780px;
}
:where(body[data-fabric-theme~="encore"]) .EmployeeSelectorReact {
  margin: 0 !important;
}
.EmployeeSelectorReact__container {
  width: calc((100% - 80px) / 2);
}
.EmployeeSelectorReact__title {
  margin-bottom: 2px;
  font-weight: var(--font-weight-bold, 700);
}
:where(body[data-fabric-theme~="encore"]) .EmployeeSelectorReact__title {
  font-size: var(--font-size-small, 14px);
  color: var(--gray9);
}
body[data-fabric-theme~="encore"] .EmployeeSelectorReact__title {
  font-weight: var(--font-weight-medium, 500);
}
.EmployeeSelectorReact__wrapper {
  background-color: #f4f4f4;
  border: 1px solid #ccc;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -o-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 374px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -o-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  border-radius: 2px;
}
:where(body[data-fabric-theme~="encore"]) .EmployeeSelectorReact__wrapper {
  -webkit-box-pack: start;
  -moz-box-pack: start;
  -o-box-pack: start;
  -ms-flex-pack: start;
  -webkit-justify-content: start;
  justify-content: start;
  overflow: clip;
}
body[data-fabric-theme~="encore"] .EmployeeSelectorReact__wrapper {
  border-radius: 16px;
}
.EmployeeSelectorReact__selects {
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-flex-shrink: 0;
  flex-shrink: 0;
  height: 57px;
  padding-left: 12px;
  border-bottom: 1px solid #ccc;
}
:where(body[data-fabric-theme~="encore"]) .EmployeeSelectorReact__selects {
  margin-bottom: 8px;
}
body[data-fabric-theme~="encore"] .EmployeeSelectorReact__selects {
  height: max-content;
  padding: 16px 16px 0;
  border-bottom: none;
}
.EmployeeSelectorReact__addSelectIcon {
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  cursor: pointer;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  fill: #686868;
  height: 20px;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -o-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  margin-left: 6px;
  width: 20px;
}
.EmployeeSelectorReact__addSelectIcon:hover {
  fill: var(--theme-base-default, #527a00);
  fill: var(--fabric-theme-base, #527a00);
}
.EmployeeSelectorReact__plusSelectIcon {
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  font-size: 18px;
  height: 28px;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -o-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  width: 30px;
}
.EmployeeSelectorReact__searchContainer {
  position: relative;
}
:where(body[data-fabric-theme~="encore"]) .EmployeeSelectorReact__searchContainer {
  padding: 0 16px 16px;
  border-bottom: 1px solid #ccc;
}
.EmployeeSelectorReact__searchInput {
  -webkit-box-shadow: 0 1px 2px -1px rgba(0,0,0,0.1);
  box-shadow: 0 1px 2px -1px rgba(0,0,0,0.1);
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  height: 42px;
  width: 100%;
  border: 0;
  border-bottom: 1px solid #e0e0e0;
  font-size: 14px;
  padding-top: 1px;
  padding-left: 33px;
}
.EmployeeSelectorReact__searchInputIcon {
  position: absolute;
  left: 10px;
  top: 12px;
  fill: #adadad;
}
.EmployeeSelectorReact__list {
  background-color: #fff;
}
.EmployeeSelectorReact__listContainer {
  -webkit-box-flex: 2;
  -moz-box-flex: 2;
  -o-box-flex: 2;
  box-flex: 2;
  -webkit-flex: 2;
  -ms-flex: 2;
  flex: 2;
  overflow: auto;
}
:where(body[data-fabric-theme~="encore"]) .EmployeeSelectorReact__listContainer {
  border-radius: 0 0 16px 16px;
}
.EmployeeSelectorReact__listItem {
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-orient: horizontal;
  -moz-box-orient: horizontal;
  -o-box-orient: horizontal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -moz-box-pack: justify;
  -o-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  border-bottom: 1px solid #e0e0e0;
  font-size: 14px;
  height: 42px;
  padding: 0 10px;
}
.EmployeeSelectorReact__listItem:hover {
  background-color: var(--theme-lightest10-default, rgba(192,233,79,0.1));
  background-color: var(--fabric-theme-lightest10, rgba(192,233,79,0.1));
}
.EmployeeSelectorReact__listItem--disabled {
  color: #999;
}
.EmployeeSelectorReact__listItem--highlighted {
  background-color: var(--theme-base-default, #527a00);
  background-color: var(--fabric-theme-base, #527a00);
  color: #fff;
  font-weight: 600;
}
.EmployeeSelectorReact__listItem--highlighted:hover {
  background-color: var(--theme-base-default, #527a00);
  background-color: var(--fabric-theme-base, #527a00);
}
.EmployeeSelectorReact__listItem__mainContent {
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.EmployeeSelectorReact__listItem__textRight {
  color: #999;
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  font-size: 13px;
  padding: 0 10px 0 10px;
  max-width: 50%;
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}
.EmployeeSelectorReact__listItem__textRightIcon {
  fill: var(--gray6, #999);
  margin-right: 7px;
}
.EmployeeSelectorReact__listItem__textRightIcon--highlighted {
  fill: var(--white, #fff);
}
.EmployeeSelectorReact__listItem__textRight--highlighted {
  color: #fff;
}
.EmployeeSelectorReact__buttons {
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -o-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-shrink: 0;
  flex-shrink: 0;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -o-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  margin-top: 58px;
  width: 80px;
}
:where(body[data-fabric-theme~="encore"]) .EmployeeSelectorReact__buttons {
  margin-top: 12px;
  padding: 0 4px;
}
.EmployeeSelectorReact__btn {
  margin-bottom: 4px;
}
:where(body[data-fabric-theme~="encore"]) .EmployeeSelectorReact__btn {
  margin-bottom: 8px;
}
.EmployeeSelectorReact__noFilterMatchBlankState {
  color: #adadad;
  font-size: var(--font-size-h4, 18px);
  line-height: var(--line-height-h4, 26px);
  font-weight: var(--font-weight-bold, 700);
  margin-top: 40px;
  text-align: center;
  padding: 0 20px;
}
.EmployeeSelectorReact__rightBlankState {
/* @startCleanup encore */
  color: #adadad;
  font-size: var(--font-size-h3, 22px);
  line-height: var(--line-height-h3, 30px);
  font-weight: var(--font-weight-bold, 700);
  text-align: center;
  padding: 0 20px;
/* @endCleanup encore */
  position: relative;
}
.EmployeeSelectorReact__rightBlankStateIcon {
  fill: #ccc;
/* @startCleanup encore */
  margin-bottom: 10px;
/* @endCleanup encore */
}
:where(body[data-fabric-theme~="encore"]) .EmployeeSelectorReact__rightBlankStateIcon {
  margin-bottom: 20px;
}
:where(body[data-fabric-theme~="encore"]) .EmployeeSelectorReact__rightBlankState {
  font-family: Inter;
  font-size: 15px;
  line-height: 22px;
  text-align: center;
  font-weight: var(--font-weight-regular);
  color: var(--gray7);
  margin-top: calc(50% - 39px);
  padding: 0 40px;
}
.EmployeeSelectorReact__rightBlankState__text {
  text-wrap: pretty;
}

.SettingsAccessLevelsAddEmployeeModal__advancedOptionsContainer {
  padding-left: 17px;
}
.SettingsAccessLevelsAddEmployeeModal__advancedOptionsContainer--closed {
  display: none;
}
.SettingsAccessLevelsAddEmployeeModal__advancedOptionsToggleIco--closed {
  -webkit-transform: rotateZ(-90deg);
  -moz-transform: rotateZ(-90deg);
  -o-transform: rotateZ(-90deg);
  -ms-transform: rotateZ(-90deg);
  transform: rotateZ(-90deg);
}

.GrantAccessModal {
  min-width: 320px;
}
.GrantAccessModal__user {
  font-size: 14px;
  line-height: 1.4em;
  color: #000;
  font-weight: normal;
}
.GrantAccessModal__userIco {
  width: 0.8em;
  height: 0.8em;
  padding: 3px;
  margin-bottom: -4px;
}
.GrantAccessModal__body {
  padding: 20px;
}
.GrantAccessModal__body label {
  color: #646464;
}
.GrantAccessModal__accessLevel,
.GrantAccessModal__accessLevel .chzn-drop {
  width: 216px;
  min-width: 216px;
  max-width: 216px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.GrantAccessModal__accessLevel .chzn-search {
  width: 100% !important;
}
.GrantAccessModal .modalSubHeader {
  height: auto;
  padding: 10px 20px 14px;
}
.SettingsAccessLevels__title {
  color: var(--theme-base-default, #527a00);
  color: var(--fabric-theme-base, #527a00);
  margin: 0;
}
.SettingsAccessLevels__titleIcon {
  margin-right: 5px;
  -webkit-transform: translateY(2px);
  -moz-transform: translateY(2px);
  -o-transform: translateY(2px);
  -ms-transform: translateY(2px);
  transform: translateY(2px);
  display: none;
}
.SettingsAccessLevels__container {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-pack: justify;
  -moz-box-pack: justify;
  -o-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  margin-top: 12px;
  margin-bottom: 20px;
}
.SettingsAccessLevels__exportItems {
  -webkit-align-self: end;
  align-self: end;
  -ms-flex-item-align: end;
}
.SettingsAccessLevels__modalLink {
  font-size: 14px;
  line-height: 0;
}
.SettingsAccessLevels__table table {
  table-layout: fixed;
}
.SettingsAccessLevels__table tbody tr.SettingsAccessLevels__row--selected {
  background: #feffe3 !important;
}
.SettingsAccessLevels__table tbody tr:has(.DataTable__cell--actions):hover {
  background-color: var(--gray1);
}
.SettingsAccessLevels__table th.DataTable__cell {
  white-space: wrap !important;
}
.SettingsAccessLevels__table .dataTables_scrollHeadInner {
  width: unset !important;
}
.SettingsAccessLevels__table .DataTable__cell {
  overflow: visible;
  cursor: default !important;
  vertical-align: top !important;
  white-space: normal;
  word-break: break-word;
}
.SettingsAccessLevels__body--noAccess tr:not(.DataTable__row--disabled) .DataTable__cell {
  cursor: pointer !important;
}
.SettingsAccessLevels__table .DataTable__cell--employeeName {
  word-break: break-word;
}
.SettingsAccessLevels__table .DataTable__cell--employeeName .SettingsAccessLevels__ico {
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-right: 4px;
}
.SettingsAccessLevels__table .DataTable__cell--employeeName .SettingsAccessLevels__ico:not(.baseFillColor) {
  fill: var(--gray4, #ccc);
}
.SettingsAccessLevels__table .DataTable__cell--employeeName .SettingsAccessLevels__userNote {
  color: #888;
  font-size: 0.95em;
  padding-left: 0.33em;
}
.SettingsAccessLevels__table .DataTable__cell--employeeName input[type="checkbox"] + label {
  display: inline-block;
  width: 15px;
  height: 15px;
  margin: -3px 6px 0 0;
}
.SettingsAccessLevels__table .DataTable__cell--employeeName a {
  font-weight: normal;
}
.SettingsAccessLevels__table .DataTable__cell--checkbox {
  padding-right: 4px;
  width: 36px !important;
  max-width: 36px !important;
  min-width: 36px !important;
}
.SettingsAccessLevels__table .DataTable__cell--checkbox .fab-Checkbox {
  margin-top: 1px;
}
.SettingsAccessLevels__table .DataTable__cell:not(.SettingsAccessLevels__table .DataTable__cell--actions,
.SettingsAccessLevels__table .DataTable__cell--checkbox) {
  width: 162px !important;
  max-width: 162px !important;
  min-width: 162px !important;
}
.SettingsAccessLevels__table .DataTable__cell--actions {
  vertical-align: middle !important;
  width: 85px !important;
  min-width: 85px !important;
  max-width: 85px !important;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}
.SettingsAccessLevels__table .DataTable__cell--actions.DataTable__cell--multipleActions {
  min-width: 160px !important;
  max-width: 160px !important;
  width: 160px !important;
}
.SettingsAccessLevels__table .DataTable__cell--actions .access-button-spacer {
  height: 32px;
}
.SettingsAccessLevels__table .DataTable__cell--actions .btn:not(.active),
.SettingsAccessLevels__table .DataTable__cell--actions .AccessLevelSelector {
  opacity: 1;
  -ms-filter: none;
  filter: none;
  -webkit-animation-name: fadeInOpacity;
  -moz-animation-name: fadeInOpacity;
  -o-animation-name: fadeInOpacity;
  -ms-animation-name: fadeInOpacity;
  animation-name: fadeInOpacity;
  -webkit-animation-iteration-count: 1;
  -moz-animation-iteration-count: 1;
  -o-animation-iteration-count: 1;
  -ms-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-timing-function: ease-in;
  -moz-animation-timing-function: ease-in;
  -o-animation-timing-function: ease-in;
  -ms-animation-timing-function: ease-in;
  animation-timing-function: ease-in;
  -webkit-animation-duration: 0.1s;
  -moz-animation-duration: 0.1s;
  -o-animation-duration: 0.1s;
  -ms-animation-duration: 0.1s;
  animation-duration: 0.1s;
  -webkit-transform: translateY(-1px);
  -moz-transform: translateY(-1px);
  -o-transform: translateY(-1px);
  -ms-transform: translateY(-1px);
  transform: translateY(-1px);
  margin: 0;
}
.SettingsAccessLevels__table .DataTable__cell--actions .btn:not(.active) .down-list,
.SettingsAccessLevels__table .DataTable__cell--actions .AccessLevelSelector .down-list {
  left: auto;
  right: 0;
}
@-moz-keyframes fadeInOpacity {
  0% {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
  100% {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
}
@-webkit-keyframes fadeInOpacity {
  0% {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
  100% {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
}
@-o-keyframes fadeInOpacity {
  0% {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
  100% {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
}
@keyframes fadeInOpacity {
  0% {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
  100% {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
}
.SettingsAccessLevels__tableActionsCell {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-pack: end;
  -moz-box-pack: end;
  -o-box-pack: end;
  -ms-flex-pack: end;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
  gap: 8px;
}
.SettingsAccessLevels__tableActionOption {
  display: -webkit-inline-box;
  display: -moz-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-box;
  display: inline-flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  gap: 8px;
}
.SettingsAccessLevels__empty-wrapper {
  border-top: 1px solid #e0e0e0;
}
.SettingsAccessLevels__empty-ico {
  width: 84px;
  height: 84px;
  margin-bottom: 6px;
  fill: #ececec;
  color: #ececec;
}
.SettingsAccessLevels__empty-msg {
  color: #c5c5c5;
  font-weight: normal;
  margin-bottom: 13px !important;
}
.SettingsAccessLevels__button-wrapper {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  padding: 14px 0;
}
.SettingsAccessLevels__button-wrapper--left {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.SettingsAccessLevels__button-wrapper--right {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 0;
  -ms-flex: 0;
  flex: 0;
  -webkit-box-pack: end;
  -moz-box-pack: end;
  -o-box-pack: end;
  -ms-flex-pack: end;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
}
.SettingsAccessLevels__button--googleImport .bicon {
  width: 22px;
  height: 17px;
  background-position: center center;
}
.SettingsAccessLevels__newAccessLevelDownBtn {
  margin-top: -4px;
  position: relative;
  width: 31px;
  height: 26px !important;
}
.SettingsAccessLevels__newAccessLevelDownBtn .caret {
  position: absolute;
  margin: 0;
  top: 11px;
  right: 3px;
}
.SettingsAccessLevels__newAccessLevelDownBtnIco {
  position: absolute;
  top: 6px;
  left: 5px;
  fill: #828282;
}
.SettingsAccessLevels__newAccessLevelDownList {
  width: 320px;
}
.SettingsAccessLevels__newAccessLevelDownListLabel,
.SettingsAccessLevels__newAccessLevelDownListDescription {
  display: block !important;
}
.SettingsAccessLevels__newAccessLevelDownListLabel {
  font-weight: 500;
  font-size: 1.15em;
}
.SettingsAccessLevels__newAccessLevelDownListDescription {
  color: #888;
  max-width: 100% !important;
  white-space: normal !important;
  padding-top: 3px !important;
  line-height: 1.2em !important;
}
.SettingsAccessLevels__newAccessLevelDownList > li {
  padding: 0 14px !important;
}
.SettingsAccessLevels__newAccessLevelDownList > li:hover > a > span {
  color: #fff;
}
.SettingsAccessLevels__newAccessLevelDownList > li:first-of-type > a {
  padding-top: 5px !important;
  padding-bottom: 0 !important;
}
.SettingsAccessLevels__newAccessLevelDownList > li:last-of-type > a {
  padding-top: 1px !important;
  padding-bottom: 7px !important;
}
.SettingsAccessLevels__newAccessLevelDownList > li > a {
  max-width: 100% !important;
  padding: 5px 0 !important;
}
.SettingsAccessLevels__accessLevelDescription {
  white-space: pre-wrap;
  margin-bottom: 16px;
}
.SettingsAccessLevels__accessLevelMenu .down-list li a {
  max-width: none;
}
@media screen and (min-width: 1430px) and (max-width: 1600px) {
  .SettingsAccessLevels__table .DataTable__cell--actions.DataTable__cell--multipleActions {
    width: 140px !important;
    max-width: 140px !important;
    min-width: 140px !important;
  }
}
@media screen and (max-width: 1600px) {
  .SettingsAccessLevels__table .DataTable__cell:not(.SettingsAccessLevels__table .DataTable__cell--actions,
  .SettingsAccessLevels__table .DataTable__cell--checkbox) {
    width: 108px !important;
    max-width: 108px !important;
    min-width: 108px !important;
  }
  .SettingsAccessLevels__tableActionsCell {
    gap: 2px !important;
  }
}
@media screen and (min-width: 1650px) {
  .SettingsAccessLevels__table .DataTable__cell--actions.DataTable__cell--multipleActions {
    width: 150px !important;
    max-width: 150px !important;
    min-width: 150px !important;
  }
}
@media screen and (min-width: 1750px) {
  .SettingsAccessLevels__table .DataTable__cell--actions.DataTable__cell--multipleActions {
    width: 140px !important;
    max-width: 140px !important;
    min-width: 140px !important;
  }
}

.settings-sub,
#settings-main-content {
  -webkit-transition: opacity 0.2s linear;
  -moz-transition: opacity 0.2s linear;
  -o-transition: opacity 0.2s linear;
  -ms-transition: opacity 0.2s linear;
  transition: opacity 0.2s linear;
}
.Settings__subNav {
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  left: 0;
}
.Settings__subNav-label {
  font-size: 13px !important;
  color: #888;
  font-weight: normal;
}

form .fieldDiv > .description {
  color: #000;
}
table#AlertDates {
  border: none;
}
.alertDateTableWrapper.collapsed {
  padding-bottom: 3em;
}
.alertDateTableWrapper.collapsed > div {
  height: 120px;
  max-height: 120px;
  overflow: hidden;
  position: relative;
}
.alertDateTableWrapper.collapsed > div:before {
  content: "";
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  position: absolute;
  background: -moz--webkit-linear-gradient(top, rgba(255,255,255,0) 0, #fff 100%);
  background: -moz--moz-linear-gradient(top, rgba(255,255,255,0) 0, #fff 100%);
  background: -moz--o-linear-gradient(top, rgba(255,255,255,0) 0, #fff 100%);
  background: -moz--ms-linear-gradient(top, rgba(255,255,255,0) 0, #fff 100%);
  background: -moz-linear-gradient(to bottom, rgba(255,255,255,0) 0, #fff 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0, rgba(255,255,255,0)), color-stop(100%, #fff));
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0, rgba(255,255,255,0)), color-stop(100%, #fff));
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0, rgba(255,255,255,0)), color-stop(100%, #fff));
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0, rgba(255,255,255,0)), color-stop(100%, #fff));
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0, rgba(255,255,255,0)), color-stop(100%, #fff));
  background: -webkit--webkit-linear-gradient(top, rgba(255,255,255,0) 0, #fff 100%);
  background: -webkit--moz-linear-gradient(top, rgba(255,255,255,0) 0, #fff 100%);
  background: -webkit--o-linear-gradient(top, rgba(255,255,255,0) 0, #fff 100%);
  background: -webkit--ms-linear-gradient(top, rgba(255,255,255,0) 0, #fff 100%);
  background: -webkit-linear-gradient(to bottom, rgba(255,255,255,0) 0, #fff 100%);
  background: -o--webkit-linear-gradient(top, rgba(255,255,255,0) 0, #fff 100%);
  background: -o--moz-linear-gradient(top, rgba(255,255,255,0) 0, #fff 100%);
  background: -o--o-linear-gradient(top, rgba(255,255,255,0) 0, #fff 100%);
  background: -o--ms-linear-gradient(top, rgba(255,255,255,0) 0, #fff 100%);
  background: -o-linear-gradient(to bottom, rgba(255,255,255,0) 0, #fff 100%);
  background: -ms--webkit-linear-gradient(top, rgba(255,255,255,0) 0, #fff 100%);
  background: -ms--moz-linear-gradient(top, rgba(255,255,255,0) 0, #fff 100%);
  background: -ms--o-linear-gradient(top, rgba(255,255,255,0) 0, #fff 100%);
  background: -ms--ms-linear-gradient(top, rgba(255,255,255,0) 0, #fff 100%);
  background: -ms-linear-gradient(to bottom, rgba(255,255,255,0) 0, #fff 100%);
  background: -webkit-linear-gradient(top, rgba(255,255,255,0) 0, #fff 100%);
  background: -moz-linear-gradient(top, rgba(255,255,255,0) 0, #fff 100%);
  background: -o-linear-gradient(top, rgba(255,255,255,0) 0, #fff 100%);
  background: -ms-linear-gradient(top, rgba(255,255,255,0) 0, #fff 100%);
  background: linear-gradient(to bottom, rgba(255,255,255,0) 0, #fff 100%);
  z-index: 1;
}
.alertDateTableExpand {
  cursor: pointer;
  display: none;
}
.alertDateTableWrapper.collapsed .alertDateTableExpand {
  display: inline-block;
}
table#AlertDates .BhrTable__body {
  position: relative;
  display: block;
  border-top: 2px solid #d9d9d9;
}
table#AlertDates .BhrTable__body:before {
  content: attr(data-year);
  background: #fff;
  position: absolute;
  display: inline-block;
  top: -2px;
  right: 0;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 0.75em;
  line-height: 0.75em;
  padding: 5px;
}
table#AlertDates .BhrTable__body:first-of-type {
  border-top: none;
  min-height: 10px;
}
table#AlertDates .BhrTable__body:first-of-type:before {
  display: none;
}
table#AlertDates .dateCell,
table#AlertDates .dayCell {
  white-space: nowrap;
}
table#AlertDates .dayCell--warning {
  color: var(--attention, #bd5800);
  font-weight: var(--font-weight-semibold, 600);
}
.weekend-msg--warning {
  color: var(--attention, #bd5800);
  font-weight: var(--font-weight-semibold, 600);
}
table#AlertDates .actionCell input.calendar-field {
  background: transparent url("https://staticfe.bamboohr.com/resources/images/bicons/inline-icons.png") -1px -46px;
  color: transparent;
  border: 1px solid transparent;
  padding: 0;
  margin: 0;
  width: 20px;
  height: 20px;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  cursor: pointer;
  -webkit-box-shadow: none;
  box-shadow: none;
}
table#AlertDates .BhrTable__row:hover .actionCell input.calendar-field {
  opacity: 0.7;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=70)";
  filter: alpha(opacity=70);
}
table#AlertDates .BhrTable__row .actionCell input.calendar-field:hover {
  opacity: 1;
  -ms-filter: none;
  filter: none;
  border-color: #d9d9d9;
}
#departmentFilter,
#employmentStateFilter,
#locationFilter,
#jobTitleFilter,
#divisionFilter,
#dobFilter,
#hireDateFilter {
  display: none;
}
label+span.description {
  margin-left: 15px;
}
.emailAlerts #edit_form checkbox {
  vertical-align: middle;
}
.emailAlerts .description {
  color: #686868;
  font-size: var(--font-size-teenie, 13px);
}
.emailAlerts .reminder-row {
  margin-bottom: 8px;
}
:where(body[data-fabric-theme~="encore"]) .emailAlerts .reminder-row {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.emailAlerts .block-form p {
  margin: 5px 0 0 0;
}
.emailAlerts .block-form li {
  clear: left;
}
.emailAlerts #otherFields {
  margin: 5px 0 0 0;
/*border: 1px solid #CCCCCC;
	background-color: #FFFFFF;
	border-radius: 3px;
	-moz-border-radius: 3px;
	-webkit-border-radius: 3px;	*/
}
.emailAlerts #otherFields li {
  display: inline-block;
  width: 30%;
  zoom: 1;
  *display: inline;
  _height: 30px;
}
.emailAlerts span.item {
  margin: 4px 2px 2px;
}
.emailAlerts #alertWho label {
  display: inline-block;
  margin-right: 8px;
}
.emailAlerts .inlineGroup {
  float: left;
  margin: 0 10px 5px 0;
}
.emailAlerts h3 {
  margin-bottom: 10px;
}
.emailAlerts hr {
  margin-top: 20px;
}
.reminder-row .bicon-remove {
  margin-top: 10px;
  margin-left: 5px;
}
span.input {
  margin-top: 0;
}
.input-list input.free {
  margin-top: 0;
}
.emailAlerts .input-list.shown {
  min-height: 45px;
}
.SettingsEmailAlerts__otherField {
  margin-top: 2px;
}
.SettingsEmailAlerts__sendToCheckbox[name="useInputList"]:checked ~ .formNote .SettingsEmailAlerts__otherFieldNote {
  display: none;
}
.SettingsEmailAlerts__sendToCheckbox[name="useInputList"]:not(:checked) ~ .formNote .SettingsEmailAlerts__otherField {
  display: none !important;
}

.AlertsList__buttonLabel {
  margin-left: 6px;
}
.AlertsList__navigation {
  margin-bottom: 15px;
}
.AlertsEdit__includesLink {
  vertical-align: baseline !important;
}
.SettingsAlerts__headingIcon {
  margin-right: 8px;
  -webkit-transform: translateY(2px);
  -moz-transform: translateY(2px);
  -o-transform: translateY(2px);
  -ms-transform: translateY(2px);
  transform: translateY(2px);
}
.SettingsAlerts__filterIcon {
  -webkit-transform: translateY(2px);
  -moz-transform: translateY(2px);
  -o-transform: translateY(2px);
  -ms-transform: translateY(2px);
  transform: translateY(2px);
  fill: var(--gray8, #555);
}
.SettingsAlerts__FormSection:not(:last-of-type) {
  border-bottom: 1px solid var(--gray2);
  margin-bottom: 20px;
  padding-bottom: 20px;
}
.SettingsAlerts__TextInput {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.SettingsAlerts__filterRow {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: start;
  -moz-box-align: start;
  -o-box-align: start;
  -ms-flex-align: start;
  -webkit-align-items: start;
  align-items: start;
  font-size: var(--font-size-biggie);
}
.SettingsAlerts__Row td {
  vertical-align: middle;
}
.SettingsAlerts__Row td.edit {
  vertical-align: top;
}
.SettingsAlerts__Row__UpdatedAt {
  color: var(--gray7);
  font-size: var(--font-size-teenie, 13px);
}

.settings--full-width #settings-main-content {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  padding: 0;
  min-height: 100%;
}
.settings--full-width #settings-main-content > div {
  min-height: 100%;
  width: 100%;
}
.TimeOffImport {
  margin: unset;
}
.TimeOffImport__step {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-orient: horizontal;
  -moz-box-orient: horizontal;
  -o-box-orient: horizontal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  padding: 0 0 32px 22px;
  gap: 16px;
}
.TimeOffImport__step:nth-of-type(3) {
  padding: 0 0 18px 22px;
}
.TimeOffImport__stepMark {
  background-color: var(--gray7, #686868);
  border: none;
  color: var(--white, #fff);
  font-size: 15px;
  font-weight: 600;
  padding-top: 2px;
  border-radius: 50%;
  display: inline-block;
  height: 26px;
  width: 26px;
  margin-right: 15px;
  position: relative;
  text-align: center;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.TimeOffImport__stepMark--import {
  top: 10px;
}
.TimeOffImport__template {
  font-weight: 600;
}
.TimeOffImport__file {
  display: -webkit-inline-box;
  display: -moz-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-box;
  display: inline-flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  margin-left: 8px;
  color: var(--gray6, #999);
}
.TimeOffImport__fileIcon {
  margin-right: 6px;
  fill: var(--gray5, #adadad);
}
.TimeOffImport__files {
  padding-top: 2px;
}
.TimeOffImport__files .file-wrapper {
  padding: 4px 20px 0 1px;
}
.TimeOffImport__note {
  margin-left: 64px;
  font-size: var(--font-size-small, 14px);
  line-height: var(--line-height-small, 20px);
  color: var(--gray7, #686868);
}
.TimeOffImport__importResults__cellIcon {
  position: relative;
  top: 2px;
  margin-right: 6px;
}
.TimeOffImport__importResults__cellIcon--success {
  fill: var(--success, #0e840d);
}
.TimeOffImport__importResults__cellIcon--warning {
  fill: var(--attention, #bd5800);
}
.TimeOffImport__step:last-of-type {
  background-color: #f00;
}

.InfoDisplay__Avatar {
  border-radius: 50%;
  float: left;
  height: 36px;
  margin: 2px 12px 0 0;
  width: 36px;
}
.InfoDisplay__BrandedInfo {
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  color: var(--theme-base-default, #527a00);
  color: var(--fabric-theme-base, #527a00);
  font-weight: var(--font-weight-semibold, 600);
  line-height: var(--line-height-small, 20px);
}
.InfoDisplay__Column {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  max-width: 275px;
}
.InfoDisplay__Icon {
  margin-right: 8px;
}
.InfoDisplay__Info {
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
}
.InfoDisplay__InfoText {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  color: var(--gray7, #686868);
  font-size: var(--font-size-teenie, 13px);
  line-height: var(--line-height-teenie, 18px);
  margin-bottom: 2px;
  margin-left: 24px;
}
.InfoDisplay__Label {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  color: var(--gray7, #686868);
  font-size: var(--font-size-teenie, 13px);
  line-height: var(--line-height-teenie, 18px);
  margin-bottom: 2px;
}
.InfoDisplay__Row {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  margin-bottom: 23px;
}
.InfoDisplay__Row:last-of-type {
  margin-bottom: 0;
}

.TrialExpired {
  text-align: center;
}
.TrialExpired__header {
  margin: 19px 0 13px;
}
.TrialExpired__thanks {
  color: var(--gray9, #444);
}

.CompanyInfo__column {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  max-width: 275px;
}
.CompanyInfo__description {
  color: var(--gray8, #555);
  font-size: var(--font-size-small, 14px);
}
.CompanyInfo__edit {
  color: var(--gray8, #555);
  font-size: var(--font-size-small, 14px);
}
.CompanyInfo__info {
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
}
.CompanyInfo__info--branded {
  color: var(--theme-base-default, #527a00);
  color: var(--fabric-theme-base, #527a00);
  font-weight: var(--font-weight-semibold, 600);
}
.CompanyInfo__label {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  color: var(--gray7, #686868);
  font-size: var(--font-size-teenie, 13px);
  margin-bottom: 2px;
}
.CompanyInfo__row {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  color: var(--gray7, #686868);
  margin-bottom: 16px;
}
.CompanyInfo__sectionHeading {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  margin-bottom: 0;
}
.CompanyInfo__spacer {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}

.AcaStatusSelect__description {
  color: var(--gray7, #686868);
  font-size: var(--font-size-small, 14px);
  line-height: 18px;
  margin-top: 8px;
}
.AcaStatusSelect__descriptionUrl {
  margin-left: 4px;
}

.SettingsAccountACA__headlineWrapper {
  border-bottom: 1px solid #e0e0e0;
  padding: 0 0 32px;
  margin: 0 0 24px;
}
.SettingsAccountACA__headline {
  font-size: 22px;
  line-height: 30px;
  color: var(--theme-base-default, #527a00);
  color: var(--fabric-theme-base, #527a00);
  font-weight: var(--font-weight-semibold, 600);
  padding: 0 0 16px;
}
.SettingsAccountACA__emphasis {
  color: var(--theme-base-default, #527a00);
  color: var(--fabric-theme-base, #527a00);
  font-weight: var(--font-weight-semibold, 600);
}
.SettingsAccountACA__list {
  list-style: inside;
  list-style-type: disc;
  margin: 12px 0;
}
.SettingsAccountACA__list li {
  color: var(--theme-base-default, #527a00);
  color: var(--fabric-theme-base, #527a00);
}
.SettingsAccountACA__list li span {
  color: var(--gray8, #555);
  margin-left: -8px;
}
.SettingsAccountACA__section {
  margin: 18px 0;
}

.SettingsAccountTimeTracking__tableHeader .filter label {
  display: inline-block;
  margin-top: 4px;
}
.SettingsAccountTimeTracking__tableHeader .filter select {
  display: none !important;
}
.SettingsAccountTimeTracking__tableHeader .chzn-container,
.SettingsAccountTimeTracking__tableHeader .chzn-container .chzn-drop {
  width: 100px;
  min-width: 100px;
  max-width: 100px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.SettingsAccountTimeTracking__emptyWrapper {
  text-align: center;
}
.SettingsAccountTimeTracking__emptyIcon {
  margin: 87px auto 5px;
}
.SettingsAccountTimeTracking__emptyHeader {
  font-size: 21px;
  color: #999;
  margin: 8px auto !important;
}
.SettingsAccountTimeTracking__emptyMessage {
  margin: 0 auto;
  color: #999;
}
.SettingsAccountTimeTracking__filterByYear {
  margin-left: 8px;
}
.SettingsAccountTimeTrackingResultsModal__info {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  margin-bottom: 16px;
}
.SettingsAccountTimeTrackingResultsModal__infoDetails {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.SettingsAccountTranslations__langSelector {
  position: absolute;
  right: 0;
  top: 10px;
}
.SettingsAccountTranslations__table.SettingsAccountTranslations__table--loading {
  opacity: 0.6;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=60)";
  filter: alpha(opacity=60);
}
.SettingsAccountTranslations__translationInput {
  margin-right: 10px;
}
.SettingsAccountTranslations__translationInputWrapper {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  margin: -10px 0;
}
.SettingsAccountTranslations__pageHeader.SettingsAccountTranslations__pageHeader--active > .SettingsAccountTranslations__tableCell.SettingsAccountTranslations__tableCell--key:before {
  -webkit-transform: rotateZ(90deg);
  -moz-transform: rotateZ(90deg);
  -o-transform: rotateZ(90deg);
  -ms-transform: rotateZ(90deg);
  transform: rotateZ(90deg);
}
.SettingsAccountTranslations__pageHeader.SettingsAccountTranslations__pageHeader--active > .SettingsAccountTranslations__tableCell.SettingsAccountTranslations__tableCell--key:not(.SettingsAccountTranslations__tableCell--noToggle):before {
  top: 8px;
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}
.SettingsAccountTranslations__pageHeader .SettingsAccountTranslations__tableCell.SettingsAccountTranslations__tableCell--key.SettingsAccountTranslations__group {
  padding: 10px 8px 10px 40px;
}
.SettingsAccountTranslations__pageHeader > .SettingsAccountTranslations__tableCell.SettingsAccountTranslations__tableCell--key:not(.SettingsAccountTranslations__tableCell--noToggle) {
  cursor: pointer;
}
.SettingsAccountTranslations__pageHeader > .SettingsAccountTranslations__tableCell.SettingsAccountTranslations__tableCell--key:not(.SettingsAccountTranslations__tableCell--noToggle):before {
  display: block;
  position: absolute;
  border: none;
  content: url("data:image/svg+xml,%3Csvg%20width=%2716%27%20height=%2716%27%20viewBox=%270%200%2016%2016%27%20fill=%27none%27%20xmlns=%27http://www.w3.org/2000/svg%27%3E%3Cpath%20d=%27M11.6875%208.71875L7.6875%2012.7188C7.40625%2013%206.96875%2013.0938%206.59375%2012.9375C6.21875%2012.7812%205.96875%2012.4062%205.96875%2012V4C5.96875%203.59375%206.21875%203.25%206.59375%203.09375C6.96875%202.9375%207.40625%203.03125%207.6875%203.3125L11.6875%207.3125C12.0938%207.6875%2012.0938%208.34375%2011.6875%208.71875Z%27%20fill=%27%23777270%27/%3E%3C/svg%3E%0A");
  height: unset;
  left: 12px;
  top: 10px;
  width: unset;
}
.SettingsAccountTranslations__pageHeader > .SettingsAccountTranslations__tableCell.SettingsAccountTranslations__tableCell--key:not(.SettingsAccountTranslations__tableCell--noToggle):hover {
  color: var(--gray10, #222);
  text-decoration: underline;
}
.SettingsAccountTranslations__pageHeader > .SettingsAccountTranslations__tableCell.SettingsAccountTranslations__tableCell--key:not(.SettingsAccountTranslations__tableCell--noToggle):hover:before {
  border-left-color: #222;
}
.SettingsAccountTranslations__pageHeader > .SettingsAccountTranslations__tableCell.SettingsAccountTranslations__tableCell--value:not(.SettingsAccountTranslations__tableCell--noToggle) {
  font-weight: var(--font-weight-medium, 500);
}
.SettingsAccountTranslations__group {
  display: table-cell;
  top: 2px;
}
.SettingsAccountTranslations__tableHeader.SettingsAccountTranslations__tableHeader--key,
.SettingsAccountTranslations__tableCell.SettingsAccountTranslations__tableCell--key {
  width: 100%;
  padding-left: 16px;
}
.SettingsAccountTranslations__tableHeader.SettingsAccountTranslations__tableHeader--value,
.SettingsAccountTranslations__tableCell.SettingsAccountTranslations__tableCell--value {
  font-weight: var(--font-weight-regular, 400);
  vertical-align: middle;
  width: 0;
  min-width: 330px;
  padding-bottom: 0;
  padding-left: 16px;
  padding-top: 0;
  white-space: wrap;
}
.SettingsAccountTranslations__pageHeader .SettingsAccountTranslations__tableHeader.SettingsAccountTranslations__tableHeader--value,
.SettingsAccountTranslations__pageHeader .SettingsAccountTranslations__tableCell.SettingsAccountTranslations__tableCell--value {
  padding-top: 0;
  padding-bottom: 0;
}
.SettingsAccountTranslations__tableHeader.SettingsAccountTranslations__tableHeader--key {
  padding-left: 16px;
}
.SettingsAccountTranslations__tableHeader.SettingsAccountTranslations__tableHeader--value {
  font-weight: var(--font-weight-medium, 500);
}
.SettingsAccountTranslations__tableCell {
  vertical-align: middle;
}
.SettingsAccountTranslations__tableRow--last > .SettingsAccountTranslations__tableCell {
  border: none;
}
.SettingsAccountTranslations__tableHeader.SettingsAccountTranslations__tableHeader--actions,
.SettingsAccountTranslations__tableCell.SettingsAccountTranslations__tableCell--actions {
  vertical-align: middle;
  white-space: nowrap;
  width: 0;
  min-width: 84px;
}
.SettingsAccountTranslations__tableHeader.SettingsAccountTranslations__tableHeader--actions > .SettingsAccountTranslations__cellActions,
.SettingsAccountTranslations__tableCell.SettingsAccountTranslations__tableCell--actions > .SettingsAccountTranslations__cellActions {
  height: unset;
  top: unset;
}
.SettingsAccountTranslations__tableCell.SettingsAccountTranslations__tableCell--value {
  padding-top: 0;
  padding-bottom: 0;
}
.SettingsAccountTranslations__tableCell.SettingsAccountTranslations__tableCell--actions {
  padding: 0;
}
.SettingsAccountTranslations__subTableHeader > .SettingsAccountTranslations__tableCell,
.SettingsAccountTranslations__subTableRow:not(.SettingsAccountTranslations__tableRow--last) > .SettingsAccountTranslations__tableCell {
  border: none;
  padding-bottom: 10px;
}
.SettingsAccountTranslations__subTableHeader > .SettingsAccountTranslations__tableCell.SettingsAccountTranslations__tableCell--key,
.SettingsAccountTranslations__subTableRow:not(.SettingsAccountTranslations__tableRow--last) > .SettingsAccountTranslations__tableCell.SettingsAccountTranslations__tableCell--key {
  padding-bottom: 16px;
}
.SettingsAccountTranslations__subTableHeader > .SettingsAccountTranslations__tableCell.SettingsAccountTranslations__tableCell--value,
.SettingsAccountTranslations__subTableRow:not(.SettingsAccountTranslations__tableRow--last) > .SettingsAccountTranslations__tableCell.SettingsAccountTranslations__tableCell--value {
  padding-top: 0;
  padding-bottom: 0;
}
.SettingsAccountTranslations__subTableRow > .SettingsAccountTranslations__tableCell {
  padding-top: 10px;
}
.SettingsAccountTranslations__subTableRow > .SettingsAccountTranslations__tableCell.SettingsAccountTranslations__tableCell--key {
  padding: 16px 16px 16px 32px;
}
.SettingsAccountTranslations__subTableRow > .SettingsAccountTranslations__tableCell.SettingsAccountTranslations__tableCell--value {
  padding-top: 0;
  padding-bottom: 0;
}
.SettingsAccountTranslations__tableRow:not(.SettingsAccountTranslations__subTableRow):not(.SettingsAccountTranslations__pageHeader) > .SettingsAccountTranslations__tableCell.SettingsAccountTranslations__tableCell--key {
  font-weight: var(--font-weight-medium, 500);
}
.SettingsAccountTranslations__tableRow.SettingsAccountTranslations__tableRow--missing > .SettingsAccountTranslations__tableCell.SettingsAccountTranslations__tableCell--key:after,
.SettingsAccountTranslations__tableCell.SettingsAccountTranslations__tableCell--value.SettingsAccountTranslations__tableCell--missing:before {
  content: "";
  display: inline-block;
  border-radius: 100%;
  background-color: var(--errorDark);
  height: 8px;
  width: 8px;
}
.SettingsAccountTranslations__tableRow.SettingsAccountTranslations__tableRow--missing > .SettingsAccountTranslations__tableCell.SettingsAccountTranslations__tableCell--key:after {
  margin-left: 0.5em;
}
.SettingsAccountTranslations__tableCell.SettingsAccountTranslations__tableCell--value.SettingsAccountTranslations__tableCell--missing:before {
  position: absolute;
  top: 50%;
  left: 0;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.SettingsAccountTranslations__icon.SettingsAccountTranslations__icon--missing {
  fill: var(--attention, #bd5800);
}
.SettingsAccountTranslations__tableCell.SettingsAccountTranslations__tableCell--key .SettingsAccountTranslations__icon.SettingsAccountTranslations__icon--missing {
  margin-left: 0.5em;
}
.SettingsAccountTranslations__tableCell.SettingsAccountTranslations__tableCell--value .SettingsAccountTranslations__icon.SettingsAccountTranslations__icon--missing {
  position: absolute;
  left: -3px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.fab-Table__row--group:after {
  bottom: unset;
  height: 36px;
  margin-top: 4px;
  top: unset;
}

.GetStarted {
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -o-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  padding: 30px 98px 0;
}
.GetStarted__header {
  color: var(--theme-base-default, #527a00);
  color: var(--fabric-theme-base, #527a00);
  margin: 0 0 30px;
  text-align: center;
  width: 100%;
}
.GetStarted__getStartedBtn {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -o-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  margin: 30px 0;
}
.GetStarted__info {
  color: var(--gray9, #444);
  font-size: var(--font-size-medium, 15px);
  line-height: var(--line-height-medium, 22px);
  text-align: center;
  margin-bottom: 30px;
}
.GetStarted__info:last-child {
  margin-bottom: 0;
}
.GetStarted__iconWrapper {
  fill: var(--gray5, #adadad);
  margin: 76px 0 93px;
}

.earlyAccessSubPage hr {
  margin: 20px 0 13px;
}
.earlyAccessSubPage h4 {
  margin-bottom: 12px;
}
.earlyAccessSubPage .fieldRow.check-radio {
  margin-bottom: 5px;
}
.earlyAccessSubPage .ea-section-heading {
  margin: 9px 0 18px;
  font-size: 15px;
}
.earlyAccessSubPage .ea-submit-feedback {
  margin: 9px 0 0;
}
.earlyAccessSubPage .ea-table {
  display: table;
  margin: 0 0 20px 16px;
  border-top: solid #dfdfdf 1px;
}
.earlyAccessSubPage .ea-table-row {
  display: table-row;
}
.earlyAccessSubPage .ea-table-cell {
  display: table-cell;
  padding: 12px 0 14px;
  vertical-align: top;
  border-bottom: solid #dfdfdf 1px;
}
.earlyAccessSubPage .ea-table-row > .ea-table-cell:first-child {
  width: 30%;
}
.brandedBar {
  position: relative;
  height: 70px;
  padding: 20px 0 0;
}
.brandedBar:after {
  content: '';
  display: block;
  width: 100%;
  height: 15px;
  position: absolute;
  bottom: 0;
  background-color: rgba(255,255,255,0.5);
}
.brandedBar #contentTop h2 {
  color: #fff;
  margin-top: 0;
}
.frown:before {
  content: '';
  display: inline-block;
  width: 29px;
  height: 27px;
  margin-right: 7px;
  position: relative;
  top: 4px;
  background: url("https://staticfe.bamboohr.com/resources/images/account/frown.png") no-repeat;
}
.statusMessage {
  width: 90%;
  margin: 150px auto 150px;
}
.statusMessage > img {
  float: left;
  margin-right: 50px;
  vertical-align: top;
  margin-top: 8px;
}
.statusMessage > img.horn {
  margin-top: -26px;
}
.statusMessage > img.suspended {
  margin-top: 0;
}
.statusMessage h2 {
  font-weight: 400;
  font-size: 36px;
  margin-bottom: 13px;
  line-height: 1em;
}
.statusMessage p {
  font-size: 18px;
}
.statusMessage > p:last-child {
  margin-top: 28px;
}
.StorageLimit button.button--link {
  background: none;
  border: none;
  color: #006ec2;
  font-size: 12px;
  margin-left: 10px;
  padding: 0;
}
.StorageLimit button.button--link .button--text {
  position: relative;
}
.StorageLimit button.button--link:hover {
  cursor: pointer;
  text-decoration: underline;
}
.StorageLimit__labelIcon .StorageLimit__icon {
  margin-right: 5px;
}
.StorageLimit__percentage {
  display: none;
  font-family: Source Sans Pro;
  font-size: 14px;
  line-height: 14px;
  position: absolute;
  top: 0;
  left: 160px;
}
.StorageLimit__progressBar {
  position: relative;
}
.StorageLimit--requested button.button--link {
  color: #858585;
}
.StorageLimit--requested button.button--link:hover {
  cursor: default;
  text-decoration: none;
}
.StorageLimit--caution.StorageLimit--requested .StorageLimit__progressBar .ProgressBar,
.StorageLimit--warn.StorageLimit--requested .StorageLimit__progressBar .ProgressBar,
.StorageLimit--full.StorageLimit--requested .StorageLimit__progressBar .ProgressBar {
  -webkit-animation: none;
  -moz-animation: none;
  -o-animation: none;
  -ms-animation: none;
  animation: none;
}
.StorageLimit--caution .StorageLimit__progressBar .ProgressBar,
.StorageLimit--warn .StorageLimit__progressBar .ProgressBar,
.StorageLimit--full .StorageLimit__progressBar .ProgressBar {
  -webkit-animation-name: pulse;
  -moz-animation-name: pulse;
  -o-animation-name: pulse;
  -ms-animation-name: pulse;
  animation-name: pulse;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  -o-animation-iteration-count: infinite;
  -ms-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}
.StorageLimit--caution .StorageLimit__percentage,
.StorageLimit--warn .StorageLimit__percentage,
.StorageLimit--full .StorageLimit__percentage {
  display: block;
}
.StorageLimit--caution .StorageLimit__header {
  color: #d17f00 !important;
  font-size: 14px;
}
.StorageLimit--caution .StorageLimit__labelIcon svg {
  fill: #d17f00;
}
.StorageLimit--caution .StorageLimit__progressBar .ProgressBar {
  -webkit-animation-duration: 2s;
  -moz-animation-duration: 2s;
  -o-animation-duration: 2s;
  -ms-animation-duration: 2s;
  animation-duration: 2s;
}
.StorageLimit--caution .StorageLimit__percentage {
  color: #d17f00;
}
.StorageLimit--warn .StorageLimit__header,
.StorageLimit--full .StorageLimit__header {
  color: #b5320a !important;
  font-size: 14px;
}
.StorageLimit--warn .StorageLimit__labelIcon svg,
.StorageLimit--full .StorageLimit__labelIcon svg {
  fill: #b5320a;
}
.StorageLimit--warn .StorageLimit__percentage,
.StorageLimit--full .StorageLimit__percentage {
  color: #b5320a;
}
.StorageLimit--warn .StorageLimit__progressBar .ProgressBar {
  -webkit-animation-duration: 1.25s;
  -moz-animation-duration: 1.25s;
  -o-animation-duration: 1.25s;
  -ms-animation-duration: 1.25s;
  animation-duration: 1.25s;
}
.StorageLimit--full .StorageLimit__progressBar .ProgressBar {
  -webkit-animation-name: pulse;
  -moz-animation-name: pulse;
  -o-animation-name: pulse;
  -ms-animation-name: pulse;
  animation-name: pulse;
  -webkit-animation-duration: 0.75s;
  -moz-animation-duration: 0.75s;
  -o-animation-duration: 0.75s;
  -ms-animation-duration: 0.75s;
  animation-duration: 0.75s;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  -o-animation-iteration-count: infinite;
  -ms-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}
.StorageLimitModal__icon {
  fill: #858585;
  margin-bottom: 15px;
  text-align: center;
}
.StorageLimitModal__text {
  color: #555;
  font-size: 17px;
  line-height: 24px;
  max-width: 387px;
  text-align: center;
}
@-moz-keyframes pulse {
  0% {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
  50% {
    opacity: 0.4;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=40)";
    filter: alpha(opacity=40);
  }
  100% {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
}
@-webkit-keyframes pulse {
  0% {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
  50% {
    opacity: 0.4;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=40)";
    filter: alpha(opacity=40);
  }
  100% {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
}
@-o-keyframes pulse {
  0% {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
  50% {
    opacity: 0.4;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=40)";
    filter: alpha(opacity=40);
  }
  100% {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
}
@keyframes pulse {
  0% {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
  50% {
    opacity: 0.4;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=40)";
    filter: alpha(opacity=40);
  }
  100% {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
}
:where(body[data-fabric-theme~="encore"]) .ChangeOwnerFormSection {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  -ms-box-flex: 1;
  box-flex: 1;
  -webkit-flex-grow: 1;
  flex-grow: 1;
}


.SettingsUsersAddEdit__btn[data-action="deleteUser"] svg {
  width: 1em;
  height: 1em;
  fill: #858585;
  margin-bottom: -1px;
  margin-right: 4px;
}
.SettingsUsersAddEdit__emailMsg {
  padding-top: 10px;
  color: rgba(100,100,100,0.85);
  fill: #bbb;
}
.SettingsUsersAddEdit__emailMsgIco {
  width: 1em;
  height: 1em;
  margin-bottom: -2px;
  margin-right: 6px;
}
.SettingsUsersAddEdit__ssoLabel {
  display: inline-block;
  margin-bottom: 7px;
}
.SettingsUsersAddEdit__resetPassword {
  display: -webkit-inline-box;
  display: -moz-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-box;
  display: inline-flex;
}

.SettingsUsersAccountSettings__headerBar {
  height: 56px !important;
}
.SettingsUsersAccountSettings__headerIcon {
  width: 60px;
  height: 60px;
  border: 2px solid #fff;
  background-color: #d8d8d8;
  border-radius: 100%;
  margin: 7px 11px 0 -3px;
}
.SettingsUsersAccountSettings__headerName,
.SettingsUsersAccountSettings__headerTitle {
  color: #fff !important;
}
.SettingsUsersAccountSettings__headerName {
  margin: 9px 0 2px;
  font-size: 18px;
  line-height: 21px;
}
.SettingsUsersAccountSettings__headerTitle {
  font-size: 14px;
  line-height: 16px;
}
.SettingsUsersAccountSettings__birthdayToggle {
  padding-bottom: 12px;
}
.SettingsUsersAccountSettings__birthdayToggle__text {
  color: var(--gray9, #444);
  margin-left: 12px;
}

.TosConsent__box {
  background-color: #f4f4f4;
  padding: 20px 20px 24px;
  margin: 32px 0 24px;
}
.TosConsent__copy {
  color: #686868;
  font-size: 13px;
  margin-bottom: 20px;
  text-align: left;
}
.TosConsent__buttons {
  text-align: left;
}

.SettingsUsersConsent .SiteFooter {
  display: none;
}
.ConsentOnlyInterstitial {
  text-align: center;
  padding: 0 0 21px;
}
.ConsentOnlyInterstitial__header {
  color: var(--gray10, #222);
  font-weight: var(--font-weight-medium, 500);
  margin-top: 19px;
}
.ConsentOnlyInterstitial .TosConsent__box {
  margin-top: 22px;
}

.SettingsUsersEmailValidate .SiteFooter {
  display: none;
}
/* @startCleanup encore */
.EmailVerification {
  text-align: center;
  padding-top: 9px;
}
.EmailVerification__header {
  color: var(--gray10, #222);
  font-weight: var(--font-weight-medium, 500);
  margin-top: 19px;
}
.EmailVerification__instruction {
  color: var(--gray9, #444);
  font-size: var(--font-size-medium, 15px);
  margin: 0;
}
.EmailVerification__instructionContainer {
  margin: 15px 0 19px;
}
.EmailVerification__email {
  color: var(--theme-base-default, #527a00);
  color: var(--fabric-theme-base, #527a00);
}
.EmailVerification__resend {
  font-size: var(--font-size-small, 14px);
  color: var(--gray7, #686868);
}
/* @endCleanup encore */

.SettingsUsersEmailValidate {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -o-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}
.SettingsUsersEmailValidate__wrapper {
  width: 588px;
}
.SettingsUsersEmailValidate__card {
  min-height: 400px;
  padding: 60px;
  padding-bottom: 52px;
  text-align: center;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.SettingsUsersEmailValidate__footer {
  height: 20px;
  color: #888;
  font-size: 12px;
  padding: 0 5px;
  margin-top: -20px;
  -webkit-transform: translateY(calc(100% + 10px));
  -moz-transform: translateY(calc(100% + 10px));
  -o-transform: translateY(calc(100% + 10px));
  -ms-transform: translateY(calc(100% + 10px));
  transform: translateY(calc(100% + 10px));
}
.SettingsUsersEmailValidate__icon--success {
  margin-top: -12px;
}
.SettingsUsersEmailValidate__title {
  line-height: 29px;
  margin: 15px 10px 18px;
}
.SettingsUsersEmailValidate__divider {
  width: 300px;
  margin: 0 auto;
}
.SettingsUsersEmailValidate__msg {
  margin: 20px 0;
  line-height: 1.33em;
}
.SettingsUsersEmailValidate__msg:last-of-type {
  margin-bottom: 0;
}
.SettingsUsersEmailValidate__msg.SettingsUsersEmailValidate__msg--email {
  margin: 24px 0 32px;
}
.SettingsUsersEmailValidate__msg.SettingsUsersEmailValidate__msg--small {
  font-size: 14px;
}
.SettingsUsersEmailValidate__msg.SettingsUsersEmailValidate__msg--success {
  margin-top: 24px;
}
.SettingsUsersEmailValidate__actions {
  padding-top: 35px;
}

.OneTimeCodeIndicator__wrapper {
  bottom: 0;
  height: 2px;
  left: 0;
  position: absolute;
  width: 100%;
}
.OneTimeCodeIndicator__wrapper svg {
  display: block;
}
.OneTimeCodeIndicator__markerBg {
  fill: #adadad;
  -webkit-transition: fill 200ms ease-out;
  -moz-transition: fill 200ms ease-out;
  -o-transition: fill 200ms ease-out;
  -ms-transition: fill 200ms ease-out;
  transition: fill 200ms ease-out;
}
.OneTimeCodeIndicator__caret {
  fill: var(--theme-base-default, #527a00);
  fill: var(--fabric-theme-base, #527a00);
  -webkit-transition: all 200ms ease-out;
  -moz-transition: all 200ms ease-out;
  -o-transition: all 200ms ease-out;
  -ms-transition: all 200ms ease-out;
  transition: all 200ms ease-out;
}
.OneTimeCodeIndicator__wrapper--error .OneTimeCodeIndicator__caret {
  fill: #c20b0b;
}
.OneTimeCodeIndicator__wrapper--error .OneTimeCodeIndicator__markerBg {
  fill: #c20b0b;
}

.OneTimeCode__wrapper {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -o-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  position: relative;
}
.OneTimeCode__input {
  height: 0;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  position: absolute;
  width: 0;
}
.OneTimeCode__codeWrapper {
  display: inline-block;
  position: relative;
}
.OneTimeCode__code {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -o-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}
.OneTimeCode__digit {
  height: 55px;
  margin-right: 10px;
  text-align: center;
  width: 50px;
}
.OneTimeCode__digit:last-child {
  margin-right: 0;
}
.OneTimeCode__digit--chunkMargin {
  margin-right: 30px;
}
.OneTimeCode__digit span {
  color: var(--theme-base-default, #527a00);
  color: var(--fabric-theme-base, #527a00);
  display: inline-block;
  font-size: 34px;
  font-weight: 700;
  line-height: 48px;
  -webkit-transition: color 200ms ease-out;
  -moz-transition: color 200ms ease-out;
  -o-transition: color 200ms ease-out;
  -ms-transition: color 200ms ease-out;
  transition: color 200ms ease-out;
}
.OneTimeCode__code--error .OneTimeCode__digit span {
  color: #c20b0b;
}

.InterstitialAuth {
  min-height: 450px;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -o-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -moz-box-pack: justify;
  -o-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}
.InterstitialAuth__Auth {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -o-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  -ms-box-flex: 1;
  box-flex: 1;
  -webkit-flex-grow: 1;
  flex-grow: 1;
  -webkit-box-pack: end;
  -moz-box-pack: end;
  -o-box-pack: end;
  -ms-flex-pack: end;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
  padding-bottom: 120px;
  text-align: center;
}
.InterstitialAuth__Code {
  margin-top: 20px;
}
.InterstitialAuth__Footer {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-flex-shrink: 0;
  flex-shrink: 0;
}
.InterstitialAuth__FooterAction:not(:first-of-type) {
  margin-left: 12px;
}
.InterstitialAuth__FooterAction--last {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  -ms-box-flex: 1;
  box-flex: 1;
  -webkit-flex-grow: 1;
  flex-grow: 1;
  -webkit-box-pack: end;
  -moz-box-pack: end;
  -o-box-pack: end;
  -ms-flex-pack: end;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
}
.InterstitialAuth__InstructionsError {
  color: var(--danger, #c20b0b);
  margin-top: 10px;
}
.InterstitialAuth__RememberMe {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -o-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  margin-top: 30px;
}

.InterstitialBackupCodes .InterstitialBackupCodes__footer {
  margin-top: 40px;
}
.InterstitialBackupCodes .InterstitialBackupCodes__footer button:nth-child(n+2) {
  margin-left: 16px;
}
.InterstitialBackupCodes .InterstitialBackupCodes__headerTitle {
  margin-bottom: 24px;
}

.InterstitialSetup__Footer {
  margin-top: 70px;
}
.InterstitialSetup__Instructions {
  margin-top: 0;
  font-size: var(--font-size-medium, 15px);
  line-height: var(--line-height-medium, 22px);
  font-weight: var(--font-weight-semibold, 600);
  color: var(--gray9);
}
.InterstitialSetup__QR {
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -o-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -moz-box-pack: justify;
  -o-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  width: 160px;
}
.InterstitialSetup__Setup {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-orient: horizontal;
  -moz-box-orient: horizontal;
  -o-box-orient: horizontal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  margin-top: 32px;
}
.InterstitialSetup__Step {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-orient: horizontal;
  -moz-box-orient: horizontal;
  -o-box-orient: horizontal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}
.InterstitialSetup__StepNumber {
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-align-self: flex-start;
  align-self: flex-start;
  -ms-flex-item-align: start;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  color: var(--white, #fff);
  -webkit-flex-shrink: 0;
  flex-shrink: 0;
  height: 28px;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -o-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  margin-right: 10px;
  width: 28px;
}
.InterstitialSetup__StepNumber:before {
  border-radius: 50%;
  content: '';
  background-color: var(--gray6, #999);
  height: 28px;
  position: absolute;
  width: 28px;
  z-index: -1;
}
.InterstitialSetup__StepText {
  margin-top: 0;
  color: var(--gray9);
}
.InterstitialSetup__Steps {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -o-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  -ms-box-flex: 1;
  box-flex: 1;
  -webkit-flex-grow: 1;
  flex-grow: 1;
  gap: 16px;
  -webkit-box-pack: start;
  -moz-box-pack: start;
  -o-box-pack: start;
  -ms-flex-pack: start;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
}

.MfaManualCodeModal__bodyCode {
  color: var(--theme-base-default, #527a00);
  color: var(--fabric-theme-base, #527a00);
}
.MfaManualCodeModal__content {
  text-align: center;
}

.ReminderModal__body {
  color: var(--gray9, #444);
}
.ReminderModal__body--block {
  margin-top: 20px;
}
.ReminderModal__content {
  text-align: center;
}
.ReminderModal__header {
  margin-bottom: 20px;
}


.fabRadio-tempFix {
  width: 500px;
  position: relative;
  left: 25px;
  top: -22px;
}
.fabRadio-tempFix .fieldLabel {
  margin-top: 3px !important;
}
.TaskForm {
  margin-left: 22px;
}
.fab-FormField .fieldLabel {
  margin-left: 8px;
  margin-top: 6px;
}
.afterRelation,
.afterType {
  width: 85px;
}
.titleIcon {
  margin-right: 9px;
  position: relative;
  top: 2px;
}
.settingsTasklists #departmentFilter,
.settingsTasklists #locationFilter,
.settingsTasklists #jobTitleFilter,
.settingsTasklists #divisionFilter,
.settingsTasklists #employmentStateFilter {
  display: none;
}
.settingsTasklists .updateInformation {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.settingsTasklists .updateInformation__icon {
  margin-left: 6px;
  fill: var(--gray6, #999);
}
.settingsTasklists .updateInformation__icon:hover {
  fill: var(--theme-base-default, #527a00);
  fill: var(--fabric-theme-base, #527a00);
}
.settingsTasklists div.optional {
  background: #f2f2f2;
  padding: 10px 10px 5px 10px;
  border-radius: 5px;
  width: 370px;
  margin-top: 10px;
  margin-left: 5px;
}
.settingsTasklists hr {
  margin-top: 4px;
}
.settingsTasklists .dueInactive {
  opacity: 0.2;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
  filter: alpha(opacity=20);
}
.settingsTasklists .filter-options-link {
  display: inline-block;
  background: none;
  margin: -3px 0 0 6px;
}
.settingsTasklists .allow-ee-uploads {
  display: inline-block;
  margin-left: 15px;
  padding-top: 7px;
}
.settingsTasklists .allow-ee-uploads input[type=checkbox] + label {
  color: #555;
}
.RequiredFor__icon {
  fill: #686868;
  margin-right: 3px;
  vertical-align: middle;
}
.RequiredFor--bold {
  font-weight: var(--font-weight-semibold, 600);
}
#filter-description {
  padding-left: 8px;
}
#filter-description div {
  padding: 0;
  margin: 0;
}
body[data-feature-toggles~="NHP_Template_Creation"] .TaskListTable__assignedTo {
  min-width: 112px;
}

.NHPTemplateListHeader__actions {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-pack: justify;
  -moz-box-pack: justify;
  -o-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}
.NHPTemplateListHeader__header {
  margin-bottom: 6px;
}
.NHPTemplateListHeader__icon {
  margin-right: 5px;
  position: relative;
  top: 1px;
}
.NHPTemplateListHeader__subHeader {
  color: #686868;
  font-size: var(--font-size-small, 14px);
  line-height: var(--line-height-teenie, 18px);
  margin-bottom: 25px;
}
.NHPEmailLinkOption {
  width: 246px;
}

.NHPTemplateSettings {
  padding-top: 6px;
}
.NHPTemplateSettings__wrapper {
  padding-bottom: 11px;
}
.NHPTemplateSettings__wrapper--empty {
  border-bottom: solid 1px #e0e0e0;
}
.NHPTemplateListBlankState__wrapper {
  margin-top: 108px;
}

.Badge {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  padding: 0 16px;
}
.Badge--modalHeader {
  background: #f4f4f4;
}
:where(body[data-fabric-theme~="encore"]) .Badge {
  padding: 0 16px 0 0;
}
.Badge--noPadding {
  padding: 0;
}
.BadgeContainer {
  min-height: 64px;
}
.BadgeContainer--modalHeader {
  border-bottom: 1px solid #ccc;
  margin: 0 32px;
  padding-top: 20px;
}
:where(body[data-fabric-theme~="encore"]) .BadgeContainer {
  min-height: 48px;
}
.BadgeContainer--newHire .BadgeImage__image {
  height: 90px;
  width: 90px;
  margin-right: 20px;
}
.BadgeContainer--newHire .BadgeText__title {
  color: var(--theme-base-default, #527a00);
  color: var(--fabric-theme-base, #527a00);
  font-size: var(--font-size-biggie, 18px);
  font-weight: var(--font-weight-bold, 700);
}
.BadgeImage {
  height: 64px;
  display: -webkit-inline-box;
  display: -moz-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-box;
  display: inline-flex;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -o-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -o-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  margin-right: 13px;
}
:where(body[data-fabric-theme~="encore"]) .BadgeImage {
  height: 48px;
}
.BadgeImage__image {
  height: 42px;
  width: 42px;
  border-radius: 50%;
}
:where(body[data-fabric-theme~="encore"]) .BadgeImage__image {
  border-radius: 25%;
  height: 48px;
  width: 48px;
}
.BadgeText {
  color: #686868;
  font-size: var(--font-size-small, 14px);
  line-height: var(--line-height-teenie, 18px);
  display: -webkit-inline-box;
  display: -moz-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-box;
  display: inline-flex;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -o-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -o-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  min-height: 100%;
}
.BadgeText__title {
  color: #222;
  font-size: var(--font-size-large, 16px);
  font-weight: var(--font-weight-semibold, 600);
  line-height: var(--line-height-small, 20px);
}

.EmploymentSubtitles__subTitleIcon {
  display: inline-block;
  width: 21px;
}
.EmploymentSubtitles__subTitleText {
  color: #686868;
  font-size: var(--font-size-small, 14px);
  line-height: var(--line-height-small, 20px);
}

.WelcomeEmailPreviewer {
  padding: 25px 16px 0;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -o-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -o-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}
.WelcomeEmailPreviewerQuestions {
  margin-top: 22px;
  margin-bottom: 22px;
}
.WelcomeEmailPreviewerQuestions__title {
  color: var(--theme-base-default, #527a00);
  color: var(--fabric-theme-base, #527a00);
}
.WelcomeEmailPreviewerQuestions__question {
  color: var(--theme-base-default, #527a00);
  color: var(--fabric-theme-base, #527a00);
  font-size: var(--font-size-medium, 15px);
  line-height: var(--line-height-medium, 22px);
  font-weight: var(--font-weight-bold, 700);
}
.WelcomeEmailPreviewerQuestions__answer {
  font-size: var(--font-size-small, 14px);
  margin-bottom: 20px;
}
.WelcomeEmailPreviewer__badgeContainer {
  background-color: #f4f4f4;
  margin-left: 27px;
  padding: 35px 9px 32px;
  width: 440px;
  margin: 0px auto 24px;
}
.WelcomeEmailPreviewer__emailHeader {
  color: #fff;
  background-color: var(--theme-light-default, #6a950a);
  background-color: var(--fabric-theme-light, #6a950a);
  background-image: url("https://staticfe.bamboohr.com/resources/images/settings/tasklists/onboarding/welcome_email_template/emailHeaderBackground.png");
  width: 512px;
  height: 174px;
  margin: 0px auto 24px;
  padding-top: 35px;
  position: relative;
  text-align: center;
}
.WelcomeEmailPreviewer__emailHeader .EmailHeader__title {
  font-size: 24px;
  line-height: var(--line-height-h4, 26px);
  margin-top: 16px;
}
.WelcomeEmailPreviewer__emailHeader .EmailHeader__subTitle {
  font-size: var(--font-size-large, 16px);
  line-height: var(--line-height-teenie, 18px);
  margin-top: 7px;
}
.WelcomeEmailPreviewer__emailHeader .borderBar {
  width: 100%;
  height: 4px;
/* fab-color() here broke things so I used a hash */
  background-color: rgba(34,34,34,0.35);
  position: absolute;
  bottom: 0;
}
.WelcomeEmailPreviewer__emailBody {
  padding: 0 25px;
  white-space: pre-line;
}
.WelcomeEmailPreviewer__emailBodyText {
  font-size: var(--font-size-small, 14px);
  line-height: var(--line-height-small, 20px);
  margin-bottom: 24px;
}
.WelcomeEmailPreviewer__placeholderQuestion {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  margin-top: 22px;
  margin-bottom: 22px;
}
.WelcomeEmailPreviewer__placeholderQuestion .PlaceholderQuestion__icon {
  margin-right: 12px;
}
.WelcomeEmailPreviewer__placeholderQuestion .PlaceholderQuestion__text {
  color: #999;
  display: inline-block;
  font-size: var(--font-size-medium, 15px);
  line-height: var(--line-height-medium, 22px);
}

.GTKYQuestion {
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  height: unset;
  margin-bottom: unset;
}
.GTKYQuestion__dragHandle {
  cursor: move;
  pointer-events: all;
}
.GTKYQuestionToggle {
  margin: 3px 10px 0;
}
.GTKYQuestionDelete {
  cursor: pointer;
  line-height: 0;
}

.QuestionsList {
  margin-bottom: 6px;
}
.QuestionsList__questions {
  margin-left: 24px;
}
.QuestionsList__questionsNote {
  font-size: var(--font-size-small, 14px);
  line-height: var(--line-height-small, 20px);
  color: var(--gray8, #555);
  margin-bottom: 3px;
}
.QuestionsList__addQuestion {
  margin-left: 24px;
}

.TeamIntroduction__FormSection--indent {
  margin-left: 30px;
}
.TeamIntroduction__gtky--dropdown {
  margin: 0 16px;
}
.TeamIntroduction__gtkyQuestions {
  margin-left: 24px;
}
.TeamIntroduction__gtkyQuestionsNote {
  font-size: var(--font-size-small, 14px);
  line-height: var(--line-height-small, 20px);
  color: var(--gray8, #555);
  margin-bottom: 3px;
}
.TeamIntroductionPreviewEmail {
  margin-left: 24px;
}
.TeamIntroductionPreviewEmail__button {
  margin-left: 12px;
}

.DynamicForm__FieldRow > .DynamicForm__FieldRow {
  margin-bottom: 0;
}
.DynamicForm__FieldRow .fab-FormNote--error {
  position: relative;
  bottom: 8px;
  vertical-align: bottom;
}
.DynamicForm__FieldRow + .DynamicForm__Table {
  margin-top: 40px;
}
.DynamicForm__Table + .DynamicForm__FieldRow {
  margin-top: 40px;
}

.DynamicForm__fieldBox {
  position: relative;
  vertical-align: bottom;
}
.DynamicForm__fieldBox.checkbox {
  bottom: 6px;
}
.DynamicForm__fieldBox.fieldBox + .DynamicForm__fieldBox.fieldBox {
  margin-left: 12px;
}

.DynamicForm__OneEmailRequired--error {
  position: relative;
}
.DynamicForm__OneEmailRequired--error .fab-Label {
  margin-bottom: 10px;
}
.DynamicForm__OneEmailRequired--error .DynamicForm__OneEmailRequired__wrapper:before {
  border: 1px solid #c20b0b;
  bottom: -3px;
  content: "";
  left: -3px;
  position: absolute;
  top: 24px;
  width: 293px;
}
.DynamicForm__OneEmailRequired--required .DynamicForm__FieldRow .fab-Label {
  display: none;
}
.DynamicForm__OneEmailRequired__wrapper > .DynamicForm__FieldRow {
  margin-bottom: 8px;
}

.DynamicForm__Checkbox .fab-Label--error {
  color: #c20b0b !important;
}

.fab-TextInput--disabled {
  border-color: var(--gray3, #e0e0e0);
  -webkit-box-shadow: none;
  box-shadow: none;
  color: var(--gray5, #adadad);
  cursor: auto;
}

.DynamicForm--EmploymentStatusNote .TaxTypeWarningText {
  left: 230px;
  top: 20px;
}

.TimeTrackingGenericWarningModal {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  padding: 4px 1px 0;
  width: 480px;
}
.TimeTrackingGenericWarningModal__alertIcon {
  fill: #f1a200;
  -webkit-flex-shrink: 0;
  flex-shrink: 0;
  margin: 4px 11px 0 0;
}
.TimeTrackingGenericWarningModal__header {
  color: #555;
  font-size: 24px;
  line-height: 29px;
  margin-bottom: 15px;
}
.TimeTrackingGenericWarningModal__header--emphasized {
  color: #222;
}
.TimeTrackingGenericWarningModal__description {
  color: #555;
  font-size: 15px;
  max-width: 420px;
}

.TT_EmpMenu__status {
  display: none;
}
.TT_EmpMenu__status--selected {
  display: inline-block;
}
.TT_EmpMenu__subList {
  min-width: 166px;
}
.TT_EmpMenu__subList li a {
  padding: 5px 0 3px;
}
.TT_EmpMenu__li.disabled {
  font-weight: 600;
}
.TT_EmpMenu__check {
  margin-right: 5px;
  position: relative;
  top: 2px;
}
.TT_EmpMenu__li:not(.TT_EmpMenu__li--selected) .TT_EmpMenu__check {
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
}
.TT_NoPayScheduleModal {
  max-width: 368px;
  padding: 5px 6px 0;
  text-align: center;
}
.TT_NoPayScheduleModal__icon {
  margin-bottom: 5px;
  color: #d53e0f;
  fill: #b1b1b1;
}
.TT_NoPayScheduleModal__header {
  margin-bottom: 18px;
  line-height: 1.3;
  color: var(--danger, #c20b0b);
}
.TT_NoPayScheduleModal__instructions {
  color: #777;
}

.DisableTimeTrackingModal__content {
  text-align: center;
}

.EnableTimeTracking__forWho {
  text-align: center;
}
.EnableTimeTracking__date-select {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -o-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  margin-top: 18px;
}

.Forms__confirmationMessage {
  text-align: center;
}
.historyModalHeader {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
}
.historyModalHeader__avatar {
  border-radius: 50%;
  height: 42px;
  width: 42px;
}
:where(body[data-fabric-theme~="encore"]) .historyModalHeader__avatar {
  border-radius: 8px;
}
.historyModalHeader__info {
  margin-left: 12px;
}
.historyModalHeader__infoName {
  font-size: var(--font-size-large, 16px);
  line-height: var(--line-height-small, 20px);
  font-weight: var(--font-weight-semibold, 600);
  margin: 2px 0 0;
}
.historyModalHeader__infoTitle {
  color: var(--gray7, #686868);
  font-size: var(--font-size-small, 14px);
  line-height: var(--line-height-teenie, 18px);
}
.regrettableInfo {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -o-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  font-size: 14px;
  gap: 12px;
  line-height: 19px;
  padding: 18px 24px 24px;
  max-width: 274px;
}

.DynamicForm__Legend {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-orient: horizontal;
  -moz-box-orient: horizontal;
  -o-box-orient: horizontal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.DynamicForm__LegendIcon {
  display: -webkit-inline-box;
  display: -moz-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-box;
  display: inline-flex;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -o-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -o-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  min-height: 22px;
}
.DynamicForm__LegendText {
  display: inline-block;
}
.DynamicForm__Legend.BhrTable__header--section {
  float: left;
}
.DynamicForm__Legend.BhrTable__header--section + .fieldRow {
  clear: both;
}

.DynamicForm--PayScheduleNote {
  left: 230px;
  position: absolute;
  top: 20px;
  width: 230px;
}

.DynamicForm--static__dash {
  bottom: 8px;
  font-weight: bold;
  margin: 0px 20px;
  padding: 0px;
  position: relative;
}
.DynamicForm--static__payrollInformationText {
  color: #0772b3;
  font-weight: var(--font-weight-semibold, 600);
  display: inline;
}
.DynamicForm--static__payrollInformationIcon {
  display: inline-block;
  margin-right: 5px;
  position: relative;
  top: 2px;
}

.DynamicForm--inputIconWrapper .fab-TextInputNub svg {
  fill: #999;
  line-height: 0;
}

.AccountCard__slat {
  background-color: var(--gray1, #f4f4f4);
  border-radius: 4px;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  margin-bottom: 10px;
  padding: 5px 5px 0 25px;
  width: 674px;
}
.AccountCard:hover .AccountCard__delete {
  opacity: 1;
  -ms-filter: none;
  filter: none;
}
.AccountCard__form {
  padding: 16px 0;
}
.AccountCard__order {
  padding: 22px 10px;
/* .AccountCard__orderNumber */
/* .AccountCard__orderNumberJade */
}
.AccountCard__orderNumber {
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  background-color: var(--gray5, #adadad);
  border-radius: 50px;
  color: var(--white, #fff);
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  font-size: 16px;
  height: 26px;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -o-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  width: 26px;
}
.AccountCard__orderNumberHeader {
  display: none;
}
.AccountCard__orderNumberJade {
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  background-color: var(--gray6, #999);
  border-radius: 50px;
  color: var(--white, #fff);
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  font-size: 16px;
  height: 26px;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -o-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  width: 26px;
}
.AccountCard__orderNumberJadeHeader {
  display: none;
}
.AccountCard__validation {
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  display: -webkit-inline-box;
  display: -moz-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-box;
  display: inline-flex;
  height: 32px;
  margin: 19px 0 0 10px;
  width: 140px;
}
.AccountCard__bankPending {
  color: var(--gray6, #999);
  display: -webkit-inline-box;
  display: -moz-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-box;
  display: inline-flex;
  font-size: 12px;
}
.AccountCard__bankPending .dotLoader {
/* override width... */
  width: 50px;
}
.AccountCard__bankLogo {
  height: 32px;
}
.AccountCard__bankText {
  color: var(--gray6, #999);
  font-size: 11px;
}
.AccountCard__delete {
  margin-left: 0;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
}
.AccountCard__delete:focus-within {
  opacity: 1;
  -ms-filter: none;
  filter: none;
}

.DDModal__buttonLinks {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-pack: justify;
  -moz-box-pack: justify;
  -o-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}
.DDModal__cardMaxText {
  color: var(--gray6, #999);
  font-size: 13px;
}
.DDModal__paperCheck {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  border-top: 1px solid #e0e0e0;
  padding-top: 15px;
  margin-top: 20px;
  -webkit-transition: padding 1s, opacity 1s, margin-top 1s, height 1s;
  -moz-transition: padding 1s, opacity 1s, margin-top 1s, height 1s;
  -o-transition: padding 1s, opacity 1s, margin-top 1s, height 1s;
  -ms-transition: padding 1s, opacity 1s, margin-top 1s, height 1s;
  transition: padding 1s, opacity 1s, margin-top 1s, height 1s;
/* .DDModal__paperCheck--hidden */
/* .DDModal__paperCheckIcon */
/* .DDModal__paperCheckText */
}
.DDModal__paperCheck--hidden {
  padding-top: 0;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  margin-top: 0;
  height: 0;
}
.DDModal__paperCheckIcon {
  display: inline-block;
  fill: var(--gray6, #999);
  margin-top: 6px;
}
.DDModal__paperCheckText {
  margin-left: 10px;
  font-size: 13px;
  color: var(--gray7, #686868);
  padding-top: 5px;
}
.DDModal__checkingText {
  text-align: center;
}

.DepositAccount {
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  width: 100%;
/* .DepositAccount__order */
/* .DepositAccount__data */
/* .DepositAccount__errorMessage */
/* .DepositAccount__text */
/* .DepositAccount__icon */
/* .DepositAccount__logo */
}
.DepositAccount__order {
  height: 23px;
  width: 23px;
  text-align: center;
  color: var(--white, #fff);
  border: 3px solid #fff;
  border-radius: 50%;
  background-color: var(--gray6, #999);
  font-size: var(--font-size-medium, 15px);
  line-height: var(--line-height-medium, 22px);
  margin-right: 11px;
}
.DepositAccount__data {
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  height: 65px;
  width: 100%;
/* .DepositAccount__data--bordered */
}
.DepositAccount__data--bordered {
  border-bottom: 1px solid #e0e0e0;
}
.DepositAccount__errorMessage {
  color: var(--attention, #bd5800);
  font-size: var(--font-size-teenie, 13px);
}
.DepositAccount__text {
  margin: 0;
/* .DepositAccount__text--bold */
}
.DepositAccount__text--bold {
  font-weight: var(--font-weight-semibold, 600);
}
.DepositAccount__icon {
  fill: var(--gray5, #adadad);
  width: 81px;
  margin-right: 10px;
  text-align: center;
}
.DepositAccount__logo {
  height: 32px;
  margin-right: 10px;
}

.DirectDeposit__accounts {
  position: relative;
/* .DirectDeposit__accounts--blank */
/* .DirectDeposit__accounts--borderBottom */
}
.DirectDeposit__accounts--blank {
  padding-top: 67px;
  text-align: center;
}
.DirectDeposit__accounts--borderBottom {
  border-bottom: 1px solid #e0e0e0;
}

.DynamicForm--employeeAccess {
  margin-left: 28px;
}
.DynamicForm--employeeAccess .fieldBox.fab-FormField {
  margin: 10px 0 0 0;
}
.DynamicForm--employeeAccess__noAccess {
  color: var(--gray5, #adadad);
}
.DynamicForm--employeeAccess__noMargin {
  margin: 0;
}

.SectionStyledBox + .SectionStyledBox {
  border-top: solid 1px #ccc;
}

.HelpPopover {
  display: -webkit-inline-box;
  display: -moz-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-box;
  display: inline-flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -o-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  right: -20px;
  margin-left: 10px;
  background-color: #b6b6b6;
  color: #fff;
  height: 14px;
  width: 14px;
  border-radius: 100%;
  font-size: 12px;
  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
/* .HelpPopover--addTop */
}
.HelpPopover--addTop {
  top: 8px;
}
.EncoreHelpPopover {
  display: -webkit-inline-box;
  display: -moz-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-box;
  display: inline-flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -o-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  right: -20px;
  margin-left: 10px;
  height: 14px;
  width: 14px;
  border-radius: 100%;
  font-size: 12px;
  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
/* .HelpPopover--addTop */
}
.EncoreHelpPopover--addTop {
  top: 8px;
}

.FedMessage2020 {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  margin: -4px 0 12px;
}
.FedMessage2020__icon {
  fill: var(--info, #0772b3);
  margin-right: 5px;
  height: 20px;
}
.FedMessage2020__text {
  font-size: var(--font-size-small, 14px);
  display: inline;
}

.FedWithholding__status {
  margin-top: 4px;
}
.FedWithholding__checkbox {
  margin-right: 16px;
/* .FedWithholding__checkboxGroup */
}
.FedWithholding__checkboxGroup {
  padding-bottom: 8px;
/* .FedWithholding__checkboxGroupLabel */
}
.FedWithholding__checkboxGroupLabel {
  margin-bottom: 5px;
}

.FedWithholding2020 {
  position: unset;
}

.StateWithholding__sectionNoteContainer {
  -webkit-box-align: start;
  -moz-box-align: start;
  -o-box-align: start;
  -ms-flex-align: start;
  -webkit-align-items: flex-start;
  align-items: flex-start;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  color: var(--info, #0772b3);
  fill: var(--info, #0772b3);
  font-size: var(--font-size-small, 14px);
  font-weight: var(--font-weight-semibold, 600);
  margin-bottom: 8px;
}
.StateWithholding__sectionNoteIcon {
  margin-top: 3px;
}
.StateWithholding__sectionNoteText {
  margin-left: 4px;
}
.StateWithholding__select {
  margin-top: 4px;
}
/* Override form style */
.OptionalCheckbox ~ .OptionalCheckbox .OptionalCheckbox__label {
  display: none;
}
.OptionalCheckbox ~ *:not(.OptionalCheckbox) {
  margin-top: 16px;
}
.StateWithholding__exempt {
  padding-top: 22px;
}

.LinkedSelects__select {
  margin-top: 4px;
}
.LinkedSelects__labelHelpContainer {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.OptionalTextInput__box {
  display: inline-block;
}
.OptionalTextInput__help > .HelpPopover {
  position: absolute;
  right: -25px;
  top: 8px;
}

.OptionalCheckbox {
  margin-bottom: 0 !important;
/* .OptionalCheckbox__label */
/* .OptionalCheckbox__tempCheckbox */
/* .OptionalCheckBox__help > .HelpPopover */
}
.OptionalCheckbox__label {
  margin-bottom: 5px !important;
}
.OptionalCheckbox__tempCheckbox {
  margin: 3px 0;
}
.OptionalCheckbox__help > .HelpPopover {
  position: absolute;
  top: 5px;
  right: -3px;
}

.OptionalSelect {
  margin-top: 4px;
/* .OptionalSelect__help > .HelpPopover */
}
.OptionalSelect__help > .HelpPopover {
  position: absolute;
  top: 11px;
  right: -25px;
}

.StateUnemployment__location {
  margin-top: 4px;
}
.StateUnemployment__check {
  padding-left: 14px;
  padding-top: 17px;
}
.StateUnemployment__sectionNoteContainer {
  -webkit-box-align: start;
  -moz-box-align: start;
  -o-box-align: start;
  -ms-flex-align: start;
  -webkit-align-items: flex-start;
  align-items: flex-start;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  color: var(--info, #0772b3);
  fill: var(--info, #0772b3);
  font-size: var(--font-size-small, 14px);
  font-weight: var(--font-weight-semibold, 600);
  margin-bottom: 8px;
}
.StateUnemployment__sectionNoteIcon {
  margin-top: 3px;
}
.StateUnemployment__sectionNoteText {
  margin-left: 4px;
}

.ba-BlankState {
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  color: var(--gray6, #999);
  cursor: default;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -o-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -o-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  text-align: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 100%;
}
.ba-BlankState--topSpacing {
  padding-top: 72px;
}
.ba-BlankState__icon {
  fill: var(--gray4, #ccc);
  margin-bottom: 32px;
}
:where(body[data-fabric-theme~="encore"]) .ba-BlankState__icon {
  margin-bottom: 24px;
}
.ba-BlankState__title {
  color: var(--gray6, #999);
  margin: 0;
  width: 100%;
}
body:not([data-feature-toggles~="jade"]) .ba-BlankState__title {
  font-family: 'BhrHeaderFont', 'Trebuchet MS';
  font-size: 22px;
  line-height: 26px;
}
:where(body[data-fabric-theme~="encore"]) .ba-BlankState__title {
  color: var(--gray8, #555);
  font-size: var(--font-size-h3, 22px);
  line-height: var(--line-height-h3, 30px);
}
.ba-BlankState__subtitle {
  font-size: var(--font-size-large, 16px);
  line-height: var(--line-height-large, 24px);
  margin-top: 8px;
  max-width: 512px;
  width: 100%;
}
.ba-BlankState__subtitle--wide {
  max-width: 612px;
}
:where(body[data-fabric-theme~="encore"]) .ba-BlankState__subtitle {
  color: var(--gray7, #686868);
  font-size: var(--font-size-medium, 15px);
  line-height: var(--line-height-medium, 22px);
}
.ba-BlankState__button {
  margin-top: 24px;
}

/* Overriding global form and blank state styles */
.WithholdingBlankState .ba-BlankState__icon {
  margin-bottom: 12px;
}
.WithholdingBlankState .ba-BlankState__title {
  color: #b1b1b1;
  font-size: 18px;
  margin: 0;
}

/* Overrides to support BhrForms--view mode with ba-selects */
.BhrForms--view .ba-Select {
  background: #fff;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-color: #dedede;
/* .BhrForms--view .ba-Select:hover */
/* .BhrForms--view .ba-Select__caret,
		   .BhrForms--view .ba-Select__x */
}
.BhrForms--view .ba-Select:hover {
  border-color: #aaa;
}
.BhrForms--view .ba-Select:hover .ba-Select__caret,
.BhrForms--view .ba-Select:hover .ba-Select__x {
  display: inline-block;
}
.BhrForms--view .ba-Select__caret,
.BhrForms--view .ba-Select__x {
  display: none;
}
.HelpPopover {
  position: unset;
}
.Federal {
  height: 373px;
  -webkit-transition: height 600ms;
  -moz-transition: height 600ms;
  -o-transition: height 600ms;
  -ms-transition: height 600ms;
  transition: height 600ms;
  overflow: hidden;
}
.Federal--2020FedEx {
  overflow: visible;
  height: 475px;
}
.Federal--hidden {
  display: none;
}

.SectionStyledBox + .SectionStyledBox {
  border-top: solid 1px #ccc;
}

/**********************************************
Withholding
**********************************************/
.EmployeeTaxStates {
  margin-top: 7px;
  padding-bottom: 25px;
}
form[name="pi-withholdings"] {
  padding-top: 0;
}
.pi-withhold h4 {
  color: #222;
  margin: 0 8px 6px 0;
}
/* @startCleanup encore */
.pi-withhold__box {
  background: #fcfcfc;
  border: 1px solid #b8b8b8;
  -webkit-box-shadow: inset 0 1px 2px 0 rgba(0,0,0,0.17);
  box-shadow: inset 0 1px 2px 0 rgba(0,0,0,0.17);
  border-radius: 3px;
  margin: 0 5px 0 0;
  padding: 1px 7px;
  text-align: center;
}
.pi-withhold__box div:first-of-type {
  font-size: 26px;
  color: #222;
  margin: 0 0 2px;
}
.pi-withhold__box div:nth-of-type(2) {
  font-size: 10px;
  color: #888;
  line-height: 10px;
  margin: 0 0 4px;
  text-transform: uppercase;
}
/* @endCleanup encore */
body[data-fabric-theme~="encore"] .pi-withhold__box {
  background: #fff;
  border: 1px solid #ebebeb;
  border-radius: 8px;
  -webkit-box-shadow: unset;
  box-shadow: unset;
  display: -webkit-inline-box;
  display: -moz-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-box;
  display: inline-flex;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -o-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -o-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  min-height: 48px;
  min-width: 48px;
  padding: 8px;
  text-align: center;
}
body[data-fabric-theme~="encore"] .pi-withhold__box div:first-of-type {
  font-size: 26px;
  color: #222;
  margin: 0 0 4px;
}
body[data-fabric-theme~="encore"] .pi-withhold__box div:nth-of-type(2) {
  font-size: 10px;
  color: #888;
  line-height: 10px;
  margin: unset;
  text-transform: uppercase;
}
.pi-withhold__written {
  display: inline;
  line-height: 18px;
  margin: 0 0 0 5px;
  padding-top: 0;
  position: relative;
  top: -2px;
  left: 2px;
}
.pi-withhold-help {
  display: inline-block;
  margin-left: 3px;
  background-color: #b6b6b6;
  color: #fff;
  height: 14px;
  width: 14px;
  border-radius: 100%;
  text-align: center;
  font-size: 12px;
  line-height: 14px;
  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.pi-withholdings-state {
  margin-top: 11px;
  padding-top: 20px;
  border-top: solid #e0e0e0 1px;
}
.pi-federal-loader {
  margin-top: 29px;
  margin-bottom: 36px;
}
.pi-state-loader {
  margin-top: 32px;
}
/**********************************************
Error Sections
**********************************************/
.pi-broken-state,
.pi-exempt-state {
  margin: 0 auto;
  text-align: center;
  width: 685px;
}
.pi-broken-state,
.pi-exempt-state {
  margin: 10px auto 33px;
  width: 100%;
}
.pi-broken-state img,
.pi-exempt-state img {
  display: block;
  margin: 0 auto;
}
.pi-exempt-state-info {
  margin-top: 7px;
  font-size: 18px;
  color: #c5c5c5;
}
.pi-broken-state .pi-broken-more-info {
  max-width: 100%;
}

.WithholdingWrapper .fab-FormSection {
  margin-top: 16px;
  padding-bottom: 16px;
}
.WithholdingWrapper .fab-FormSection:first-child {
  margin-top: 32px;
}
.WithholdingWrapper .fab-FormSection--hidden {
  display: none;
}
.WithholdingWrapper__taxType.fab-FormSection {
  margin-top: 32px;
  padding-bottom: 32px;
}
.WithholdingWrapper__taxType.fab-FormSection .fab-Label {
  color: var(--gray6, #999);
}
.WithholdingWrapper__taxType.fab-FormSection .fab-FormSection--content {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.WithholdingWrapper__taxType.fab-FormSection .fab-FormSection--content .fab-Select {
  margin-right: 12px;
}
.WithholdingWrapper__taxType.fab-FormSection .fab-FormSection--content .WithholdingWrapper__explanation {
  color: var(--gray6, #999);
}
.WithholdingWrapper .StateWithholdingContainer--hidden {
  display: none;
}
.ExemptionsCheckbox {
  margin-left: 34px !important;
}

.DynamicForm--taxInformation {
  position: relative;
}
.DynamicForm--taxInformation__delete {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 9999;
}
.DynamicForm--taxInformation.DynamicForm--error legend {
  color: var(--danger, #c20b0b);
}

.DynamicForm--HoverBase__Editable .HoverHint__content {
  display: none !important;
}
.DynamicForm--HoverBase__hint {
  padding-right: 30px;
}
.DynamicForm--HoverBase__hint:hover .HoverHint__content {
  display: -webkit-inline-box;
  display: -moz-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-box;
  display: inline-flex;
}
.hasSuppressionNote .DynamicForm--HoverBase__hint {
  padding-right: 0;
}
.HoverHint {
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  display: -webkit-inline-box;
  display: -moz-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-box;
  display: inline-flex;
}
.HoverHint__content {
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  display: none;
  margin-left: 8px;
}
.HoverHint__icon {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  margin-right: 4px;
}
.HoverHint__link {
  font-size: var(--font-size-teenie, 13px);
}
.BhrForms--view .hasNote .HoverHint:hover .HoverHint__content,
.BhrForms--view .hasNote .HoverHint .HoverHint__content:hover {
  display: none;
}
.BhrForms--view .HoverHint:hover .HoverHint__content {
  display: -webkit-inline-box;
  display: -moz-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-box;
  display: inline-flex;
}

.DynamicForm__pending {
  display: none;
  position: absolute;
  right: 6px;
  top: 3px;
  z-index: 2;
}
.DynamicForm__pending--icon {
  display: none;
  fill: #adadad;
  float: right;
  position: absolute;
  right: 6px;
  top: 8px;
  z-index: 2;
}
.DynamicForm__pending..DynamicForm__pending--container {
  position: relative;
}
.DynamicForm__pending..DynamicForm__pending--container--icon {
  float: none;
  left: 4px;
  right: 0;
  top: -2px;
}
.fieldBox:hover .DynamicForm__pending--icon {
  display: block;
}
.fieldBox:hover .DynamicForm__pending {
  display: block;
}

.dynamic-field-react.repeatItem {
  border-bottom: none;
  padding-bottom: 0;
}
.dynamic-field-react.repeatItem:not(:first-of-type) {
  border-top: 1px solid #d4d4d4;
  padding-top: 32px;
}
.dynamic-field-react.repeatItem:not(:nth-child(2)) {
  margin: 32px 0 0 0;
}

fieldset {
  position: relative;
}
.deleteIconContainer {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
}

.NewHirePacketFiles .FileCard {
  background: none;
  padding: 0;
}
.FileSectionWrapper {
  margin-top: 12px;
  margin-left: 5px;
}

.NHPTemplateForm {
  padding-top: 4px;
  text-align: left;
}
.NHPTemplateForm__header {
  padding-bottom: 4px;
}
.NHPTemplateForm__taskNote {
  margin-left: 9px;
}

#EditEmailForm {
  height: 100%;
}

.SettingsTrainingAddEdit .SettingsTrainingAddEdit__attachRow .FilesWrapper {
  margin: 0;
}
.SettingsTrainingAddEdit .SettingsTrainingAddEdit__attachRow .FilesWrapper__files {
  margin-top: 5px;
  margin-bottom: -1px;
}
.SettingsTrainingAddEdit .SettingsTrainingAddEdit__attachRow .FileCard {
  height: 94px;
}
.SettingsTrainingAddEdit .SettingsTrainingAddEdit__attachLabel {
  margin-bottom: 4px;
}
.SettingsTrainingAddEdit .SettingsTrainingAddEdit__filterRow {
  margin-top: -5px;
  margin-bottom: 7px;
}
.SettingsTrainingAddEdit .SettingsTrainingAddEdit__filterMsg {
  margin-left: 4px;
}
.SettingsTrainingAddEdit .SettingsTrainingAddEdit__requiredWithinDays {
  margin-left: 6px;
  margin-right: 5px;
  padding: 4px 8px;
  min-width: 35px;
}

.SettingsTraining__blankState {
  border-top: solid 1px;
  border-color: var(--gray6, #999);
  color: var(--gray6, #999);
  fill: var(--gray6, #999);
  padding-top: 72px;
  text-align: center;
}
.SettingsTraining__blankStateText {
  font-size: var(--font-size-large, 16px);
}
.SettingsTraining__blankStateText--heading {
  color: var(--gray6, #999);
}
.SettingsTraining__required {
  text-align: center;
}
.SettingsTraining__categoryIco {
  fill: #898989;
  margin-right: 3px;
}
.SettingsTraining__categoryIco svg {
  -webkit-transform: translateY(2px);
  -moz-transform: translateY(2px);
  -o-transform: translateY(2px);
  -ms-transform: translateY(2px);
  transform: translateY(2px);
}
body[data-feature-toggles~="jade"] .SettingsTraining__category {
  padding: 9px 0;
}
body[data-feature-toggles~="jade"] .SettingsTraining__category:first-child {
  padding-left: 16px;
}
body[data-feature-toggles~="jade"] .SettingsTraining__category:last-child {
  padding-right: 16px;
}
body[data-feature-toggles~="jade"] .SettingsTraining__categoryIcon {
  fill: var(--gray7, #686868);
}
.SettingsTraining__addCategoryIco {
  fill: #898989;
  margin-right: 3px;
  overflow: inherit;
}
.SettingsTraining__addCategoryIco > svg {
  -webkit-transform: translateY(4px);
  -moz-transform: translateY(4px);
  -o-transform: translateY(4px);
  -ms-transform: translateY(4px);
  transform: translateY(4px);
}
.SettingsTraining__modalMessage {
  text-align: center;
}
.SettingsTraining__modalCheckboxes {
  margin-top: 15px;
}
.SettingsTraining__modal.deleteModal {
  margin: 0 0 0 0;
}
body[data-feature-toggles~="jade"] .SettingsTraining__tableLabel {
  color: inherit;
  font-weight: var(--font-weight-bold, 700);
  font-size: inherit;
}

.DateField__note--bottom {
  display: block;
  margin: 2px 0 0 0;
}

.BaseInputField__tooltipIconWrapper {
  margin-left: 5px;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  fill: #686868;
}
.BaseInputField__tooltipIconWrapper:hover {
  fill: var(--theme-base-default, #527a00);
  fill: var(--fabric-theme-base, #527a00);
}

.NumberField__input {
  -moz-appearance: textfield;
}
.NumberField__input::-webkit-outer-spin-button,
.NumberField__input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.ControlledCurrency__Menu--wrapper {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  margin-right: -40px;
  -webkit-transform: translateX(-100%);
  -moz-transform: translateX(-100%);
  -o-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  transform: translateX(-100%);
}
.ControlledCurrency__Menu--wrapper div {
  display: -webkit-box !important;
  display: -moz-box !important;
  display: -webkit-flex !important;
  display: -ms-flexbox !important;
  display: box !important;
  display: flex !important;
}
.ControlledCurrency__Menu--wrapper:after {
  content: attr(data-currency-code);
  padding: 0 7px;
  font-size: var(--font-size-teenie, 13px);
  border-right: 1px solid transparent;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  font-family: "Source Sans Pro", Arial, Helvetica, sans-serif;
}
.ControlledCurrency__Menu--wrapper .CurrencyPicker__button--disabled {
  border-color: transparent !important;
  border-left-color: #e0e0e0 !important;
}

.CurrencySelectorField input {
  padding-right: 45px;
}

.Columns__eligibleList {
  counter-reset: eligible-counter;
  margin-left: 0;
}
.Columns__eligibleList li {
  counter-increment: eligible-counter;
  color: var(--gray10, #222);
  padding-left: 16px;
  text-indent: -16px;
}
.Columns__eligibleList li:before {
  content: counter(eligible-counter) '. ';
  color: var(--gray6, #999);
}
.Columns__eligible--inactive {
  color: var(--gray6, #999);
}
.Columns__eligible--inactive .Columns__eligibleList li {
  color: var(--gray6, #999);
}

.Columns__endDate--attention {
  color: var(--attention, #bd5800);
}
.Columns__endDate--inactive {
  color: var(--gray6, #999);
}

.Columns__statusNotEnrolled {
  color: var(--gray7, #686868);
}
.Columns__status--inactive {
  color: var(--gray6, #999);
}

.DateRangeInput {
  -webkit-box-align: start;
  -moz-box-align: start;
  -o-box-align: start;
  -ms-flex-align: start;
  -webkit-align-items: start;
  align-items: start;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
}
.DateRangeInput__separator {
  margin: 24px 8px 0;
}

.DeductionDate__optionNote {
  color: var(--gray7, #686868);
  font-size: var(--font-size-teenie, 13px);
  line-height: 16px;
}
.fab-MenuOption--active .DeductionDate__optionNote {
  color: var(--white, #fff);
}
.DeductionDate__option--bold {
  font-weight: var(--font-weight-bold, 700);
}

.DeductionMigrationConfigure__heading {
  text-align: center;
}
.DeductionMigrationConfigure__instruction {
  border-top: 1px solid #e0e0e0;
  color: var(--gray10, #222);
  font-size: var(--font-size-large, 16px);
  font-weight: var(--font-weight-semibold, 600);
  line-height: var(--line-height-medium, 22px);
  margin-bottom: 12px;
  padding-top: 20px;
}
.DeductionMigrationConfigure__subHeading {
  color: var(--gray10, #222);
  font-size: var(--font-size-medium, 15px);
  margin-bottom: 26px;
}
.DeductionMigrationConfigure__subHeadingLink {
  white-space: nowrap;
}
.DeductionMigrationConfigure__subHeadingWarning {
  color: var(--attention, #bd5800);
}

.BenefitPlans__blank {
  margin-top: 72px;
}
.BenefitPlans__loader {
  left: 50%;
  position: absolute;
  top: 80%;
}

.BenefitsList {
  padding-left: 20px;
  color: #777;
  width: 100%;
  font-weight: 600;
}
.BenefitsList-smallMargin {
  margin-bottom: 14px;
}
.BenefitsList-leftIndent {
  margin-left: 20px;
}
.BenefitsList__planTitle {
  padding-top: 20px;
  margin-left: -20px;
}
.BenefitsList__planTitle-name {
  width: 500px;
  font-weight: 600;
  color: #000;
}
.BenefitsList__planTitle-svg {
  margin-right: 10px;
  height: 28px;
  width: 34px;
  display: block;
}
.BenefitsList__variable-effectiveDate {
  padding-left: 52px;
  padding-right: 10px;
}
.BenefitsList__variable-enrollmentStatus {
  padding-left: 49px;
}
.BenefitsList__variable-coverage {
  padding-left: 67px;
}
.BenefitsList__variable-employeePays {
  padding-left: 121px;
}
.BenefitsList__variable-companyPays {
  padding-left: 42px;
}
.BenefitsList__variable-deductionFrequency {
  padding-left: 103px;
}
.BenefitsList__variable-comment {
  padding-left: 52px;
}
.BenefitsList__nonhealth-effectiveDate {
  padding-left: 52px;
  padding-right: 10px;
}
.BenefitsList__nonhealth-enrollmentStatus {
  padding-left: 50px;
}
.BenefitsList__nonhealth-employeePays {
  padding-left: 65px;
}
.BenefitsList__nonhealth-companyPays {
  padding-left: 76px;
}
.BenefitsList__nonhealth-deductionFrequency {
  padding-left: 217px;
}
.BenefitsList__nonhealth-comment {
  padding-left: 52px;
}
.BenefitsList__health-effectiveDate {
  padding-left: 52px;
  padding-right: 10px;
}
.BenefitsList__health-enrollmentStatus {
  padding-left: 63px;
}
.BenefitsList__health-coverage {
  padding-left: 87px;
}
.BenefitsList__health-comment {
  padding-left: 143px;
}
.BenefitsList__reimbursement-effectiveDate {
  padding-left: 54px;
}
.BenefitsList__reimbursement-enrollmentStatus {
  padding-left: 58px;
}
.BenefitsList__reimbursement-comment {
  padding-left: 68px;
}
label.greyed {
  background-color: #989898 !important;
  top: 1px;
  left: 1px;
}
.BhrForms .BenefitCard {
  background-color: #e8e8e8;
  border: solid #fff 1px;
  margin: 2px 0 10px 0;
  padding: 10px 10px 15px 10px;
  width: 1000px;
  z-index: 5px;
  display: none;
}
.BhrForms .BenefitCard__vhr {
  height: 25px;
  border-left: 1px solid #b1b1b1;
  margin-bottom: 2px;
  margin-top: 2px;
  vertical-align: bottom;
}
.BhrForms .BenefitCard__vhr--top {
  vertical-align: top;
}
.BhrForms .BenefitCard--dimmed {
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.BhrForms .BenefitCard--dimmed:hover {
  pointer-events: none;
  cursor: crosshair;
}
.BhrForms .BenefitCard--dimmed:before {
  content: "";
  z-index: 10;
  display: block;
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  background: #e8e8e8;
  opacity: 0.8;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
  filter: alpha(opacity=80);
}
.BhrForms .BenefitCard--active {
  border: solid #9e9e9e 1px;
}
.BhrForms .BenefitCard--active .BenefitCard__nonhealth--commentParent {
  display: block;
}
.BhrForms .BenefitCard--active .BenefitCard__variable--commentParent {
  display: block;
}
.BhrForms .BenefitCard-note {
  position: absolute;
  left: 335px;
  text-align: right;
  top: -19px;
  width: 400px;
  font-weight: normal;
}
.BhrForms .BenefitCard-note--info:before {
  background-image: url("https://staticfe.bamboohr.com/resources/images/settings/new_benefit/information.svg");
  -webkit-background-size: 12px 12px;
  -moz-background-size: 12px 12px;
  background-size: 12px 12px;
  background-repeat: no-repeat;
  content: "";
  display: inline-block;
  height: 12px;
  padding-right: 5px;
  width: 12px;
}
.BhrForms .BenefitCard-note--info {
  color: #56afdd;
  font-weight: normal;
  z-index: 11;
  opacity: 0.8;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
  filter: alpha(opacity=80);
}
.BhrForms .BenefitCard-note--error:before {
  background-image: url("https://staticfe.bamboohr.com/resources/images/settings/new_benefit/information_error.svg");
  -webkit-background-size: 12px 12px;
  -moz-background-size: 12px 12px;
  background-size: 12px 12px;
  background-repeat: no-repeat;
  content: "";
  display: inline-block;
  height: 12px;
  padding-right: 5px;
  width: 12px;
}
.BhrForms .BenefitCard-note--error {
  color: #cf6240;
}
.BhrForms .BenefitCard-svg {
  height: 20px;
  width: 16px;
  display: block;
}
.BhrForms .BenefitCard-employee {
  line-height: 1em;
  width: 250px;
  margin: 0;
  font-weight: normal;
  color: #000;
}
.BhrForms .BenefitCard__nonhealth-effectiveDate {
  width: 126px;
  padding-left: 40px;
}
.BhrForms .BenefitCard__nonhealth-employeePays {
  min-width: 148px;
}
.BhrForms .BenefitCard__nonhealth-companyPays {
  min-width: 290px;
}
.BhrForms .BenefitCard__nonhealth-deductionFrequency {
  margin-top: 2px;
}
.BhrForms .BenefitCard__nonhealth--commentParent {
  display: none;
}
.BhrForms .BenefitCard__nonhealth-comment {
  padding-top: 5px;
  padding-left: 40px;
}
.BhrForms .BenefitCard__nonhealth-comment input {
  min-width: 303px;
  width: 303px;
}
.BhrForms .BenefitCard__variable-effectiveDate {
  width: 126px;
  padding-left: 40px;
}
.BhrForms .BenefitCard__variable-enrollmentStatus {
  width: 150px;
  padding-right: 6px;
}
.BhrForms .BenefitCard__variable-coverage {
  width: 150px;
  padding-right: 8px;
}
.BhrForms .BenefitCard__variable-employeePays {
  min-width: 124px;
  margin-right: 0;
}
.BhrForms .BenefitCard__variable-companyPays {
  min-width: 180px;
  margin-right: 0;
  margin-left: 0;
}
.BhrForms .BenefitCard__variable-deductionFrequency {
  margin-top: 10px;
}
.BhrForms .BenefitCard__variable--commentParent {
  display: none;
}
.BhrForms .BenefitCard__variable-comment {
  padding-top: 5px;
  padding-left: 40px;
}
.BhrForms .BenefitCard__variable-comment input {
  min-width: 480px;
  width: 480px;
}
.BhrForms .BenefitCard__health {
  padding-right: 20px;
}
.BhrForms .BenefitCard__health-effectiveDate {
  width: 126px;
  padding-left: 40px;
}
.BhrForms .BenefitCard__health-enrollmentStatus {
  width: 150px;
  padding-right: 7px;
}
.BhrForms .BenefitCard__health-coverage {
  width: 150px;
  padding-right: 6px;
}
.BhrForms .BenefitCard__health-comment {
  min-width: 334px;
  width: 334px;
  padding-left: 35px;
}
.BhrForms .BenefitCard__reimbursement-effectiveDate {
  width: 94px;
  padding-left: 40px;
  padding-right: 32px;
}
.BhrForms .BenefitCard__reimbursement-enrollmentStatus {
  width: 157px;
}
.BhrForms .BenefitCard__reimbursement-comment input {
  min-width: 590px;
  width: 590px;
}
.BhrForms .BenefitCard__deductionNote {
  font-weight: var(--font-weight-normal, 400);
  margin: 8px 0 0 40px;
}
.BenefitHeader {
  padding-left: 23px;
}
.BenefitTableHeader--light {
  color: #888;
  font-size: small;
  font-weight: normal;
}
.BenefitTableHeader-employee {
  padding-bottom: 0;
  color: #000;
  background-color: #eaeaea;
}
.BenefitTableHeader-employee--light {
  color: #888;
  font-size: small;
  font-weight: normal;
}
.BenefitTableHeader__reimbursement-employee {
  min-width: 120px;
}
.BenefitTableBody--bold {
  font-weight: bold;
}
body[data-feature-toggles~="jade"] .bft-form-step-1 {
  padding-top: 20px;
}
body[data-feature-toggles~="jade"] .formNote:not(.gross-pay-hidden) {
  display: inline-block;
}
body[data-feature-toggles~="jade"] .BhrTableSection__artboard {
  padding: 0 0 4px 10px;
  display: inline-block;
}
body[data-feature-toggles~="jade"] .Nester li:hover {
  background-color: var(--theme-base-default, #527a00);
  background-color: var(--fabric-theme-base, #527a00);
}
body[data-feature-toggles~="jade"] .BenefitsList__planTitle {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  padding-top: unset;
}
body[data-feature-toggles~="jade"] .BenefitsList__planTitle-svg {
  fill: var(--theme-base-default, #527a00);
  fill: var(--fabric-theme-base, #527a00);
}
body[data-feature-toggles~="jade"] .BhrForms .BenefitCard {
  width: 1200px;
}
body[data-feature-toggles~="jade"] .BhrForms .BenefitCard__nonhealth-effectiveDate,
body[data-feature-toggles~="jade"] .BhrForms .BenefitCard__health-effectiveDate,
body[data-feature-toggles~="jade"] .BhrForms .BenefitCard__variable-effectiveDate,
body[data-feature-toggles~="jade"] .BhrForms .BenefitCard__reimbursement-effectiveDate {
  width: 200px;
}
body[data-feature-toggles~="jade"] .BhrForms .BenefitCard__variable-deductionFrequency {
  margin-top: 4px;
}
.BenefitsBulkEditStepTwo__cardIcon {
  fill: var(--theme-base-default, #527a00);
  fill: var(--fabric-theme-base, #527a00);
}
.BenefitsBulkEditStepTwo__companyPays {
  display: inline-block;
}
.BenefitsBulkEditStepTwo__companyPays--inline {
  display: inline;
}
.BenefitsBulkEditStepTwo__planTitle {
  color: var(--theme-base-default, #527a00);
  color: var(--fabric-theme-base, #527a00);
  margin-bottom: 12px;
}
.BenefitsBulkEditStepTwo__safeHarbor {
  display: inline;
}
.BenefitsBulkEditStepTwo__safeHarborMessage {
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  color: var(--gray6, #999);
  fill: var(--gray6, #999);
  font-size: var(--font-size-small, 14px);
}
.BenefitsBulkEditStepTwo__safeHarborMessageIcon {
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  margin-left: 3px;
}
.BenefitsBulkEditStepThree__cardIcon {
  fill: var(--theme-base-default, #527a00);
  fill: var(--fabric-theme-base, #527a00);
}

.SurveyStatus {
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -o-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-top: 25px;
  text-align: center;
/* @startCleanup encore */
/* @endCleanup encore */
}
.SurveyStatus--has-image {
  padding: 46px 0;
}
.SurveyStatus__header {
  color: var(--theme-base-default, #527a00);
  color: var(--fabric-theme-base, #527a00);
  font-weight: var(--font-weight-semibold, 600);
  font-family: unset;
  font-size: 22px;
  line-height: 28px;
  margin: 20px 0;
  text-align: center;
}
:where(body[data-fabric-theme~="encore"]) .SurveyStatus__header {
  font-family: 'Fields', serif;
}
.SurveyStatus__icon {
  fill: var(--theme-base-default, #527a00);
  fill: var(--fabric-theme-base, #527a00);
}
.SurveyStatus__subtitle {
  color: var(--gray10, #222);
  font-size: var(--font-size-medium, 15px);
  text-align: center;
}

.FloatingCard {
  width: 100%;
/* @startCleanup encore */
/* @endCleanup encore */
}
:where(body[data-fabric-theme~="jade"]) .FloatingCard__body {
  background-color: #fff;
  border-bottom-style: solid;
  border-image-slice: 0 0 2 0;
  border-image-source: linear-gradient(90deg, #c0e94f 0, #82af13 100%);
  border-image-source: linear-gradient(90deg, var(--fabric-theme-lightest), var(--fabric-theme-base));
  border-radius: 2px;
  border-width: 0 0 3px 0;
  -webkit-box-shadow: 0 8px 14px 3px rgba(0,0,0,0.05);
  box-shadow: 0 8px 14px 3px rgba(0,0,0,0.05);
  text-align: left;
}
:where(body[data-fabric-theme~="encore"]) .FloatingCard__body {
  background-color: var(--white);
  border-radius: var(--radius-200);
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: inline-grid;
  gap: 8px;
  padding: 12px;
  width: 100%;
}
.FloatingCard__footer {
  height: 100%;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  font-size: 12px;
  -webkit-box-pack: justify;
  -moz-box-pack: justify;
  -o-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}
:where(body[data-fabric-theme~="jade"]) .FloatingCard__footer {
  color: var(--gray6, #999);
}
.FloatingCard__footerLock {
  padding-right: 4px;
  fill: var(--gray6, #999);
}
.FloatingCard__footerLogo {
  padding-left: 4px;
  vertical-align: bottom;
  fill: var(--gray6, #999);
}
.FloatingCard__poweredBy {
  margin-top: 16px;
}
.FloatingCard__privacy {
  margin-top: 12px;
}
@media only screen and (max-width: 800px) {
  .FloatingCard {
    width: calc(100% - 30px);
    margin: 15px;
  }
  .FloatingCard__footer {
    -webkit-box-direction: reverse;
    -moz-box-direction: reverse;
    -o-box-direction: reverse;
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    -o-box-orient: vertical;
    -webkit-flex-direction: column-reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }
  :where(body[data-fabric-theme~="encore"]) .FloatingCard__footer {
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    -o-box-orient: vertical;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .FloatingCard__privacy {
    margin-top: 0;
  }
  :where(body[data-fabric-theme~="encore"]) .FloatingCard__privacy {
    margin-top: 20px;
  }
}

.EnpsSurveyBodyStyles {
  background: -webkit-linear-gradient(270deg, #eee 0, #fbfbfb 100%);
  background: -moz-linear-gradient(270deg, #eee 0, #fbfbfb 100%);
  background: -o-linear-gradient(270deg, #eee 0, #fbfbfb 100%);
  background: -ms-linear-gradient(270deg, #eee 0, #fbfbfb 100%);
  background: linear-gradient(180deg, #eee 0, #fbfbfb 100%);
}
.SurveyWrapper {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -o-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  width: 100%;
}
.SurveyWrapper__content {
  margin-top: 32px;
  width: 800px;
}
.SurveyWrapper__content--small {
  width: 590px;
}
.SurveyWrapper__body {
/* @startCleanup encore */
  padding: 16px 30px 40px 30px;
/* @endCleanup encore */
}
:where(body[data-fabric-theme~="encore"]) .SurveyWrapper__body {
  padding: 16px 30px 0 30px;
}
.SurveyWrapper__body--full-bleed {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -o-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  padding: 0;
}
.SurveyWrapper__customFooter {
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  display: -webkit-inline-box;
  display: -moz-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-box;
  display: inline-flex;
  font-size: 15px;
}
.SurveyWrapper__customFooterDivider {
  margin: 0 3px;
}
.SurveyWrapper__customFooter--muted {
  color: var(--gray7, #686868);
  fill: var(--gray7, #686868);
}
.SurveyWrapper__customFooter > * {
  margin: 0 5px;
}
@media only screen and (max-width: 800px) {
  .SurveyWrapper__content {
    margin-top: 0;
    width: 100%;
  }
  .SurveyWrapper__content--small {
    width: 100%;
    max-width: 590px;
  }
}

.AnonymityBanner {
  height: 25px;
  background: #444;
  padding: 8px 16px;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
:where(body[data-fabric-theme~="encore"]) .AnonymityBanner {
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  height: unset;
  padding: 5px 20px;
}
@media (max-width: 768px) {
  .AnonymityBanner {
    padding: 14px 0 14px 20px;
  }
  :where(body[data-fabric-theme~="encore"]) .AnonymityBanner {
    padding: 8px 20px 4px;
  }
}
.AnonymityBanner__icon {
  -webkit-flex-shrink: 0;
  flex-shrink: 0;
}
.AnonymityBanner__icon svg {
  height: 25px;
  width: 30px;
  fill: #fff;
}
@media (max-width: 768px) {
  .AnonymityBanner__icon svg {
    height: 32px;
    width: 38px;
  }
}
.AnonymityBanner__textContainer {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  margin-left: 16px;
  font-size: 15px;
  line-height: 18px;
  -webkit-box-orient: horizontal;
  -moz-box-orient: horizontal;
  -o-box-orient: horizontal;
  -webkit-box-lines: multiple;
  -moz-box-lines: multiple;
  -o-box-lines: multiple;
  -webkit-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
}
@media (max-width: 768px) {
  .AnonymityBanner__textContainer {
    font-size: 18px;
    line-height: 22px;
  }
  :where(body[data-fabric-theme~="encore"]) .AnonymityBanner__textContainer {
    font-size: var(--font-size-medium);
    line-height: var(--line-height-medium);
  }
}
.AnonymityBanner__text {
  color: #fff;
  margin-right: 6px;
}
.AnonymityBanner__link {
  color: var(--gray4, #ccc);
}
:where(body[data-fabric-theme~="jade"]) .AnonymityBanner__button {
  color: var(--gray4, #ccc);
  font-weight: 400;
}

.EnpsAction {
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  padding-bottom: 38px;
  padding-left: 56px;
  overflow: hidden;
  max-height: 1000px;
  -webkit-transition: max-height 600ms ease-in, padding-bottom 200ms ease-in;
  -moz-transition: max-height 600ms ease-in, padding-bottom 200ms ease-in;
  -o-transition: max-height 600ms ease-in, padding-bottom 200ms ease-in;
  -ms-transition: max-height 600ms ease-in, padding-bottom 200ms ease-in;
  transition: max-height 600ms ease-in, padding-bottom 200ms ease-in;
}
:where(body[data-fabric-theme~="encore"]) .EnpsAction {
  background-color: var(--gray05);
  -webkit-box-direction: reverse;
  -moz-box-direction: reverse;
  -o-box-direction: reverse;
  -webkit-box-orient: horizontal;
  -moz-box-orient: horizontal;
  -o-box-orient: horizontal;
  -webkit-flex-direction: row-reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
  padding: 20px;
}
.EnpsAction--hidden {
  max-height: 0;
  padding-bottom: 0;
}
:where(body[data-fabric-theme~="encore"]) .EnpsAction--hidden {
  visibility: hidden;
}
.EnpsAction__privacyStatement {
  color: var(--gray7, #686868);
  fill: var(--gray6, #999);
  font-size: var(--font-size-small, 14px);
  line-height: 17px;
  margin-left: 19px;
/* @startCleanup encore */
/* @endCleanup encore */
}
:where(body[data-fabric-theme~="encore"]) .EnpsAction__privacyStatement {
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  color: var(--gray7);
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  font-size: var(--font-size-medium);
  line-height: var(--line-height-medium);
  margin-left: 0;
}
.EnpsAction__privacyStatementText {
  margin-left: 5px;
}
:where(body[data-fabric-theme~="jade"]) .EnpsAction__privacyStatement button {
  color: var(--info, #0772b3);
  fill: var(--info, #0772b3);
  font-size: var(--font-size-small, 14px);
  line-height: 17px;
  height: 17px;
  font-weight: 400;
}
.EnpsAction__row {
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
/* @startCleanup encore */
/* @endCleanup encore */
}
:where(body[data-fabric-theme~="encore"]) .EnpsAction__row {
  -webkit-box-direction: reverse;
  -moz-box-direction: reverse;
  -o-box-direction: reverse;
  -webkit-box-orient: horizontal;
  -moz-box-orient: horizontal;
  -o-box-orient: horizontal;
  -webkit-flex-direction: row-reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
  -webkit-box-pack: justify;
  -moz-box-pack: justify;
  -o-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  width: 100%;
}
.EnpsAction__rowSpacer {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}
@media only screen and (max-width: 800px) {
  .EnpsAction {
    -webkit-box-pack: center;
    -moz-box-pack: center;
    -o-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    padding-left: 0;
  }
  :where(body[data-fabric-theme~="encore"]) .EnpsAction {
    background-color: unset;
  }
  .EnpsAction__button {
    width: 100%;
    height: 36px;
    font-size: 18px;
    font-weight: 600;
    line-height: 23px;
  }
  :where(body[data-fabric-theme~="encore"]) .EnpsAction__button {
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: box;
    display: flex;
    height: unset;
    -webkit-box-pack: center;
    -moz-box-pack: center;
    -o-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
  }
}

.ConductSurvey__privacyMessage {
  color: var(--gray6, #999);
  fill: var(--gray6, #999);
  padding-bottom: 40px;
  margin-left: 0 auto;
  text-align: center;
}
:where(body[data-fabric-theme~="encore"]) .ConductSurvey__privacyMessage {
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  color: var(--gray7);
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  font-size: var(--font-size-medium);
  line-height: var(--line-height-medium);
  margin-left: 0;
}
.ConductSurvey__privacyMessageText {
  margin-left: 8px;
}
.ConductSurvey__privacyMessage button {
  height: 17px;
  color: var(--info, #0772b3);
  fill: var(--info, #0772b3);
  font-weight: 400;
}
.ConductSurvey__questions {
  padding: 27px 22px 5px;
}
:where(body[data-fabric-theme~="encore"]) .ConductSurvey__questions {
  padding: 32px 48px 10px 20px;
}
.ConductSurvey__questionHeading--fadeOut {
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  -webkit-animation: fadeOut 0.5s;
  -moz-animation: fadeOut 0.5s;
  -o-animation: fadeOut 0.5s;
  -ms-animation: fadeOut 0.5s;
  animation: fadeOut 0.5s;
}
@-moz-keyframes fadeOut {
  0% {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
  100% {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
}
@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
  100% {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
}
@-o-keyframes fadeOut {
  0% {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
  100% {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
}
@keyframes fadeOut {
  0% {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
  100% {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
}
.ConductSurvey__questionHeading--fadeIn {
  opacity: 1;
  -ms-filter: none;
  filter: none;
  -webkit-animation: fadeIn 0.5s;
  -moz-animation: fadeIn 0.5s;
  -o-animation: fadeIn 0.5s;
  -ms-animation: fadeIn 0.5s;
  animation: fadeIn 0.5s;
}
@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
  100% {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
}
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
  100% {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
}
@-o-keyframes fadeIn {
  0% {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
  100% {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
  100% {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
}
.ConductSurvey__scale {
/* @startCleanup encore */
  margin-left: 20px;
  margin-top: 25px;
  width: 470px;
/* @endCleanup encore */
}
:where(body[data-fabric-theme~="encore"]) .ConductSurvey__scale {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin-top: 20px;
  margin-left: 0;
  padding-left: 20px;
  width: 100%;
}
@media only screen and (max-width: 800px) {
  :where(body[data-fabric-theme~="encore"]) .ConductSurvey__privacyMessage {
    -webkit-box-pack: center;
    -moz-box-pack: center;
    -o-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    width: 100%;
  }
  .ConductSurvey__questions {
    margin: 0;
    padding: 0 40px;
  }
  .ConductSurvey__scale {
    margin: 0;
    width: 100%;
  }
}

.EnpsBannerHeading {
  background-color: var(--theme-base-default, #527a00);
  background-color: var(--fabric-theme-base, #527a00);
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -o-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 102px;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -o-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  padding-left: 40px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.EnpsBannerHeading__title {
  color: var(--white, #fff);
  font-weight: var(--font-weight-bold, 700);
  font-size: 22px;
  line-height: 23px;
  padding-bottom: 5px;
}
.EnpsBannerHeading__subtitle {
  color: var(--white, #fff);
  font-weight: var(--font-weight-semibold, 600);
  font-size: 16px;
  line-height: 23px;
}

.EnpsIconHeading {
  display: none;
}
@media only screen and (max-width: 800px) {
  .EnpsIconHeading {
    fill: var(--theme-base-default, #527a00);
    fill: var(--fabric-theme-base, #527a00);
    display: block;
    text-align: center;
    margin: 35px 0 20px 0;
  }
}

.EnpsScoreHeading {
  text-align: center;
}
.EnpsScoreHeading__score {
  border-radius: 50%;
  border: 2px solid var(--theme-base-default, #527a00);
  border: 2px solid var(--fabric-theme-base, #527a00);
  border: 2px solid var(--fabric-theme-base, #527a00);
  background-color: var(--theme-base-default, #527a00);
  background-color: var(--fabric-theme-base, #527a00);
  color: var(--white, #fff);
  font-weight: var(--font-weight-heavy, 800);
  font-size: 32px;
  height: 50px;
  line-height: 50px;
  margin: 0 auto 25px auto;
  text-align: center;
  width: 50px;
}

.MeteredTextarea {
/* @startCleanup encore */
  padding: 0 41px 0 20px;
/* @endCleanup encore */
}
:where(body[data-fabric-theme~="encore"]) .MeteredTextarea {
  margin-top: 20px;
  padding: 0 0 0 20px;
}
.MeteredTextarea__meter {
  border-radius: 10px;
  bottom: 4px;
  background-color: var(--gray4, #ccc);
  height: 8px;
  overflow: hidden;
  position: relative;
  width: 100%;
}
:where(body[data-fabric-theme~="encore"]) .MeteredTextarea__meter {
  border-radius: var(--radius-100);
  bottom: 0;
  margin-top: 12px;
  height: 4px;
  width: calc(100% - 10px);
  left: 5px;
}
.MeteredTextarea__progress {
  height: 8px;
  -webkit-transition: width ease 0.5s;
  -moz-transition: width ease 0.5s;
  -o-transition: width ease 0.5s;
  -ms-transition: width ease 0.5s;
  transition: width ease 0.5s;
}
.MeteredTextarea__progress--default {
  background-color: var(--gray7, #686868);
}
.MeteredTextarea__progress--better {
  background-color: var(--attention, #bd5800);
}
.MeteredTextarea__progress--best {
  background-color: var(--success, #0e840d);
}
.MeteredTextarea__comment {
  font-size: 12px;
  padding-top: 7px;
  font-size: var(--font-size-teenie, 13px);
}
.MeteredTextarea__comment--default {
  color: var(--gray7, #686868);
}
.MeteredTextarea__comment--better {
  color: var(--attention, #bd5800);
}
.MeteredTextarea__comment--best {
  color: var(--success, #0e840d);
}
.MeteredTextarea__wrapper {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -o-box-orient: vertical;
  -webkit-flex-flow: column;
  -ms-flex-flow: column;
  flex-flow: column;
  -ms-flex-flow: column;
  width: 100%;
}
.MeteredTextarea__wrapper > textarea {
  font-family: inherit;
  width: 100%;
}
.MeteredTextarea .MeteredTextarea__body {
  font-size: 14px;
  height: 155px;
  line-height: 21px;
  position: relative;
  resize: none;
  z-index: 2;
}
:where(body[data-fabric-theme~="jade"]) .MeteredTextarea .MeteredTextarea__body {
  border-bottom: none;
}
@media only screen and (max-width: 800px) {
  .MeteredTextarea {
    padding: 0;
  }
  .MeteredTextarea__comment {
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    margin-top: 10px;
  }
}

.EnpsQuestion {
  overflow: hidden;
  max-height: 1000px;
  -webkit-transition: max-height 600ms ease-in;
  -moz-transition: max-height 600ms ease-in;
  -o-transition: max-height 600ms ease-in;
  -ms-transition: max-height 600ms ease-in;
  transition: max-height 600ms ease-in;
}
.EnpsQuestion--hidden {
  max-height: 0;
}
.EnpsQuestion .EnpsQuestion__heading {
  font-weight: var(--font-weight-semibold, 600);
  font-size: 16px;
  line-height: 23px;
  margin-bottom: 3px;
  padding-left: 20px;
  text-indent: -20px;
}
.EnpsQuestion__number {
  padding-right: 7px;
}
.EnpsQuestion__subtitle {
  color: var(--gray7, #686868);
  font-size: var(--font-size-teenie, 13px);
  line-height: 16px;
  padding-left: 20px;
}
:where(body[data-fabric-theme~="encore"]) .EnpsQuestion__subtitle {
  margin-top: 12px;
}
:where(body[data-fabric-theme~="encore"]) .EnpsQuestion + .EnpsQuestion:not(.EnpsQuestion--hidden) {
  margin-top: 40px;
}
@media only screen and (max-width: 800px) {
  :where(body[data-fabric-theme~="jade"]) .EnpsQuestion {
    margin-bottom: 35px;
  }
  .EnpsQuestion .EnpsQuestion__heading {
    color: var(--theme-base-default, #527a00);
    color: var(--fabric-theme-base, #527a00);
    font-size: 22px;
    font-weight: 600;
    line-height: 26px;
    margin-bottom: 24px;
    text-align: center;
  }
  .EnpsQuestion__heading--encore {
    text-align: center;
  }
  .EnpsQuestion__subtitle {
    padding-left: 0;
    text-align: center;
  }
  :where(body[data-fabric-theme~="encore"]) .EnpsQuestion__subtitle {
    margin-top: 16px;
  }
}

.EnpsDetailAnswer__questions {
  padding: 27px 22px 5px;
}
@media only screen and (max-width: 800px) {
  .EnpsDetailAnswer__questions {
    margin: 0;
    padding: 0 40px;
  }
  .EnpsDetailAnswer__navigation {
    border: none;
    cursor: pointer;
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: box;
    display: flex;
    background-color: var(--white, #fff);
    color: var(--gray7, #686868);
    font-size: 16px;
    margin: 16px;
  }
  .EnpsDetailAnswer__navigationIcon {
    fill: var(--gray7, #686868);
    margin-right: 12px;
  }
}

.SurveyAnswerScale {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-flex-basis: 100%;
  flex-basis: 100%;
}
.SurveyAnswerScale__box {
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 2px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  color: var(--gray10, #222);
  cursor: pointer;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-flex-basis: 0;
  flex-basis: 0;
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  -ms-box-flex: 1;
  box-flex: 1;
  -webkit-flex-grow: 1;
  flex-grow: 1;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -o-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  font-size: var(--font-size-small, 14px);
  line-height: var(--line-height-small, 20px);
  margin: 0 5px;
  padding: 20px;
  text-align: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.SurveyAnswerScale__box:focus,
.SurveyAnswerScale__box:hover {
  border-color: var(--theme-base-default, #527a00);
  border-color: var(--fabric-theme-base, #527a00);
}
:where(body[data-fabric-theme~="encore"]) .SurveyAnswerScale__box:focus,
:where(body[data-fabric-theme~="encore"]) .SurveyAnswerScale__box:hover {
  border-color: var(--fabric-theme-base);
  color: var(--fabric-theme-base);
  background-color: var(--fabric-theme-lightest35);
}
:where(body[data-fabric-theme~="encore"]) .SurveyAnswerScale__box:focus .SurveyAnswerScale__checkIcon > svg,
:where(body[data-fabric-theme~="encore"]) .SurveyAnswerScale__box:hover .SurveyAnswerScale__checkIcon > svg {
  fill: var(--fabric-theme-base);
}
.SurveyAnswerScale__box:first-child {
  margin-left: 0;
}
.SurveyAnswerScale__box:last-child {
  margin-right: 0;
}
.SurveyAnswerScale__box--selected {
  border-color: var(--theme-lighter-default, #82af13);
  border-color: var(--fabric-theme-lighter, #82af13);
  -webkit-box-shadow: 0 0 0 2px var(--theme-lightest35-default, rgba(192,233,79,0.35));
  box-shadow: 0 0 0 2px var(--theme-lightest35-default, rgba(192,233,79,0.35));
  box-shadow: 0 0 0 2px var(--fabric-theme-lightest35, rgba(192,233,79,0.35));
  -webkit-box-shadow: 0 0 0 2px var(--fabric-theme-lightest35, rgba(192,233,79,0.35));
  box-shadow: 0 0 0 2px var(--fabric-theme-lightest35, rgba(192,233,79,0.35));
  color: var(--theme-base-default, #527a00);
  color: var(--fabric-theme-base, #527a00);
  font-weight: 600;
}
.SurveyAnswerScale__checkIcon {
  fill: var(--gray3, #e0e0e0);
  margin-bottom: 10px;
}
.SurveyAnswerScale__checkIcon--selected {
  fill: var(--theme-base-default, #527a00);
  fill: var(--fabric-theme-base, #527a00);
}
.SurveyAnswerScale__checkIcon--small {
  display: none;
}
.SurveyAnswerScale__text {
  display: block;
  max-width: 100%;
}
@media only screen and (max-width: 800px) {
  .SurveyAnswerScale {
    -webkit-box-lines: multiple;
    -moz-box-lines: multiple;
    -o-box-lines: multiple;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    width: 100%;
  }
  :where(body[data-fabric-theme~="jade"]) .SurveyAnswerScale--hasSelected .SurveyAnswerScale__box:not(.SurveyAnswerScale__box--selected):not(:hover) {
    border-color: #e0e0e0;
    color: var(--gray3, #e0e0e0);
  }
  .SurveyAnswerScale__box {
    border-radius: 3px;
    border-color: #adadad;
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -o-box-flex: 1;
    -ms-box-flex: 1;
    box-flex: 1;
    -webkit-flex-grow: 1;
    flex-grow: 1;
    -webkit-box-orient: horizontal;
    -moz-box-orient: horizontal;
    -o-box-orient: horizontal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-basis: 100%;
    flex-basis: 100%;
    font-size: 20px;
    line-height: 25px;
    margin: 5px 0;
    padding: 15px;
    text-align: left;
    width: 100%;
  }
  :where(body[data-fabric-theme~="encore"]) .SurveyAnswerScale__box {
    border: 1px solid var(--gray6);
    border-radius: 4px;
    color: var(--gray6);
  }
  .SurveyAnswerScale__box--large-text {
    font-size: 20px;
  }
  .SurveyAnswerScale__box:first-child {
    margin-left: 0;
  }
  .SurveyAnswerScale__box:last-child {
    margin-right: 0;
  }
  .SurveyAnswerScale__box--selected {
    border-color: var(--theme-lighter-default, #82af13);
    border-color: var(--fabric-theme-lighter, #82af13);
    -webkit-box-shadow: 0 0 0 2px var(--theme-lightest35-default, rgba(192,233,79,0.35));
    box-shadow: 0 0 0 2px var(--theme-lightest35-default, rgba(192,233,79,0.35));
    box-shadow: 0 0 0 2px var(--fabric-theme-lightest35, rgba(192,233,79,0.35));
    -webkit-box-shadow: 0 0 0 2px var(--fabric-theme-lightest35, rgba(192,233,79,0.35));
    box-shadow: 0 0 0 2px var(--fabric-theme-lightest35, rgba(192,233,79,0.35));
    font-weight: 600;
  }
  .SurveyAnswerScale__checkIcon {
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: box;
    display: flex;
    -webkit-flex-shrink: 0;
    flex-shrink: 0;
    margin: 0 15px 0 0;
  }
  .SurveyAnswerScale__checkIcon--centered {
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -o-box-flex: 1;
    box-flex: 1;
    -webkit-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    margin: 0;
  }
  .SurveyAnswerScale__checkIcon--large {
    display: none;
  }
  .SurveyAnswerScale__text--centered {
    position: relative;
    right: 15px;
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -o-box-flex: 1;
    box-flex: 1;
    -webkit-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
  }
  .SurveyAnswerScale__text--selected {
    color: var(--theme-base-default, #527a00);
    color: var(--fabric-theme-base, #527a00);
  }
}

.EnpsScaleAnswer__questions {
  padding: 27px 22px 5px;
}
.EnpsScaleAnswer__scale {
  margin-left: 20px;
  margin-top: 25px;
  width: 470px;
}
@media only screen and (max-width: 800px) {
  .EnpsScaleAnswer__questions {
    margin: 0;
    padding: 0 40px;
  }
  .EnpsScaleAnswer__scale {
    margin: 0;
    width: 100%;
  }
}

.EnpsRadioButton__number {
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  background-color: #fff;
  border-radius: 16px;
  border: 1px solid #e0e0e0;
  cursor: pointer;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  color: var(--gray8, #555);
  font-size: var(--font-size-small, 14px);
  font-weight: var(--font-weight-semibold, 600);
  height: 30px;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -o-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  line-height: 17px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 30px;
  z-index: 2;
}
:where(body[data-fabric-theme~="encore"]) .EnpsRadioButton__number {
  border: 1px solid var(--gray4);
  border-radius: 50%;
  height: 48px;
  width: 48px;
}
.EnpsRadioButton__number:hover {
  border-color: var(--theme-base-default, #527a00);
  border-color: var(--fabric-theme-base, #527a00);
  color: var(--theme-base-default, #527a00);
  color: var(--fabric-theme-base, #527a00);
}
.EnpsRadioButton__number--selected {
  border-color: var(--theme-base-default, #527a00);
  border-color: var(--fabric-theme-base, #527a00);
  background-color: var(--theme-base-default, #527a00);
  background-color: var(--fabric-theme-base, #527a00);
  color: var(--white, #fff);
  font-weight: bold;
}
.EnpsRadioButton__number--selected:hover {
  border-color: var(--theme-base-default, #527a00);
  border-color: var(--fabric-theme-base, #527a00);
  background-color: var(--theme-base-default, #527a00);
  background-color: var(--fabric-theme-base, #527a00);
  color: var(--white, #fff);
}

.EnpsRadioScale__scale {
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  height: 40px;
  -webkit-box-pack: justify;
  -moz-box-pack: justify;
  -o-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}
:where(body[data-fabric-theme~="encore"]) .EnpsRadioScale__scale {
  height: 48px;
}
.EnpsRadioScale__divider {
  background-color: var(--gray3, #e0e0e0);
  bottom: 22px;
  height: 4px;
  position: relative;
  width: 100%;
  z-index: 1;
}
:where(body[data-fabric-theme~="encore"]) .EnpsRadioScale__divider {
  background-color: var(--gray2);
  bottom: 26px;
}
.EnpsRadioScale__legend {
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  color: var(--gray6, #999);
  font-size: var(--font-size-teenie, 13px);
  font-weight: var(--font-weight-semibold, 600);
  -webkit-box-pack: justify;
  -moz-box-pack: justify;
  -o-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  line-height: 16px;
  margin-bottom: 25px;
  text-transform: none;
  width: 100%;
}
:where(body[data-fabric-theme~="encore"]) .EnpsRadioScale__legend {
  color: var(--gray7);
  font-size: var(--font-size-small);
  font-weight: var(--font-weight-normal);
  line-height: var(--line-height-small);
  margin: 6px 0 0;
}

.EnpsSurvey {
  margin-bottom: 35px;
}
.EnpsSurvey__content--full-width {
  width: 100%;
}

.EnpsEmailPreviewModal {
  position: relative;
  width: 600px;
  padding: 20px 0 25px;
  margin: auto;
}
:where(body[data-fabric-theme~="encore"]) .EnpsEmailPreviewModal {
  pointer-events: none;
}
.EnpsEmailPreviewModal td {
  text-align: inherit;
}
.EnpsEmailPreviewModal__blocker {
  position: absolute;
  width: 100%;
  height: 100%;
}
.js-simplemodal-close {
  line-height: 100%;
}
.CTAbutton {
  background-color: var(--theme-base-default, #527a00) !important;
  background-color: var(--fabric-theme-base, #527a00) !important;
}
.FollowUp__icon {
  background-color: var(--theme-base-default, #527a00) !important;
  background-color: var(--fabric-theme-base, #527a00) !important;
}
.FollowUp__heading {
  color: var(--theme-base-default, #527a00) !important;
  color: var(--fabric-theme-base, #527a00) !important;
}
:where(body[data-fabric-theme~="encore"]) .LightTop,
:where(body[data-fabric-theme~="encore"]) .BodyFallback {
  background-color: #fff !important;
}
.LightTop tr:hover,
.BodyFallback tr:hover {
  background-color: #fff !important;
}
.LightTop__iconArea > .HeavyTop__iconImage {
  background-color: var(--theme-base-default, #527a00) !important;
  background-color: var(--fabric-theme-base, #527a00) !important;
}
.LightTop__heading {
  text-align: center !important;
}
.LightTop__padding {
  border-top: 4px solid var(--theme-base-default, #527a00) !important;
  border-top: 4px solid var(--fabric-theme-base, #527a00) !important;
}
.LightTop__stripe {
  background-color: var(--theme-base-default, #527a00) !important;
  background-color: var(--fabric-theme-base, #527a00) !important;
}
.MainText__paragraph {
  width: 100% !important;
  padding-left: 15px !important;
  padding-right: 15px !important;
}
.Survey__scaleButton {
  background-color: #fff !important;
  border: 1px solid var(--theme-base-default, #527a00) !important;
  border: 1px solid var(--fabric-theme-base, #527a00) !important;
  border: 1px solid var(--fabric-theme-base, #527a00) !important;
  border-radius: 16px !important;
  color: var(--theme-base-default, #527a00);
  color: var(--fabric-theme-base, #527a00);
}
.Survey__scaleLegend td {
  width: 100% !important;
  white-space: nowrap;
}
.mobile-content {
  display: none !important;
}
.lg-screen-only {
  display: unset !important;
}
tr.lg-screen-only {
  display: table-row !important;
}
table.lg-screen-only {
  display: table !important;
}

.Setup {
  color: var(--gray8, #555);
}
.Setup__includeContractors {
  margin-bottom: 20px;
}
.Setup__emailBody {
  display: block;
  margin-bottom: 8px;
  height: 240px;
  font-size: 15px;
  line-height: 22px;
}
:where(body[data-fabric-theme~="encore"]) .Setup__emailBody {
  height: 280px;
  margin-bottom: 12px;
}
.Setup__emailEnd {
  display: block;
  background-color: var(--gray1, #f4f4f4);
  color: var(--gray7, #686868);
  font-size: 14px;
  line-height: 20px;
  border: none;
  padding: 14px 13px;
  margin-bottom: 6px;
  min-height: auto;
}
.Setup__emailEnd p + p {
  margin-top: 7px;
}
:where(body[data-fabric-theme~="encore"]) .Setup__emailEnd {
  background-color: var(--gray05);
  border-radius: var(--radius-200);
  color: var(--gray10);
  padding: 20px 16px;
  margin-bottom: 16px;
}
.Setup__6MthInfoIcon {
  margin-left: 4px;
  fill: currentColor;
  width: 14px;
  height: 14px;
  display: inline-block;
  vertical-align: sub;
}
.Setup .Setup__surveyFrequency {
  margin: 15px 0 20px;
}
.Setup .Setup__surveyFrequency label {
  margin-bottom: 4px;
}
:where(body[data-fabric-theme~="encore"]) .Setup .Setup__surveyFrequency {
  margin: 16px 0 0;
}
.Setup__pssst {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-pack: justify;
  -moz-box-pack: justify;
  -o-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  font-size: 13px;
}
.Setup__icon {
  margin-right: 5px;
}
.Setup__icon--link {
  fill: var(--info, #0772b3);
}
.Setup__footer {
  height: auto;
  text-align: left;
  min-width: auto;
  margin-top: 30px;
  padding-top: 15px;
  border-top: 1px solid #ccc;
}
:where(body[data-fabric-theme~="encore"]) .Setup__footer {
  border-top: 1px solid var(--gray2);
}
.Setup__footerCancelButton {
  margin-left: 16px;
}
.Setup__save {
  margin-right: 10px;
}
.Setup__stepHeading {
  color: var(--gray10, #222);
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 5px;
}
:where(body[data-fabric-theme~="encore"]) .Setup__sendDateWrapper {
  margin-bottom: 12px;
}
.Setup__stepParagraph {
  margin-bottom: 20px;
  font-size: 14px;
  line-height: 17px;
}
:where(body[data-fabric-theme~="encore"]) .Setup__stepParagraph {
  margin-bottom: 0;
}
.Setup.BhrForms {
  padding-top: 0;
}
.Setup .Setup__lessMargin {
  margin-bottom: 0;
}
.Setup .Setup__emailBody,
.Setup .Setup__emailEnd {
  font-size: 14px;
  line-height: 21px;
  resize: none;
}

.AreaChart--themeFill {
  fill: var(--theme-base-default, #527a00);
  fill: var(--fabric-theme-base, #527a00);
}
.AreaChart--themeStroke {
  stroke: var(--theme-base-default, #527a00);
  stroke: var(--fabric-theme-base, #527a00);
}

.ba-BarChart__groupLabelsArea {
  fill: #777;
}
body[data-feature-toggles~="jade"] .BarChart--fillThemeColor {
  fill: var(--theme-base-default, #527a00);
  fill: var(--fabric-theme-base, #527a00);
}
body[data-feature-toggles~="jade"] .ba-BarChart__groupLabelsArea {
  fill: var(--gray8, #555);
}

.yAxisLabel {
  font-size: 13px;
  line-height: 16;
  fill: #8a8a8a;
  text-align: left;
  width: 100px;
}
:where(body[data-fabric-theme~="encore"]) .yAxisLabel {
  fill: var(--gray6);
  font-family: 'Inter', sans-serif;
}
.yAxisLabel__label {
  text-transform: uppercase;
  font-size: 13px;
  line-height: 16;
  fill: #8a8a8a;
}
:where(body[data-fabric-theme~="encore"]) .yAxisLabel__label {
  text-transform: capitalize;
  font-family: 'Inter', sans-serif;
}

body[data-feature-toggles~="jade"] .LineChart--strokeThemeColor {
  stroke: var(--theme-base-default, #527a00);
  stroke: var(--fabric-theme-base, #527a00);
}
body[data-feature-toggles~="jade"] .LineChart--strokeLightThemeColor {
  stroke: var(--theme-light-default, #6a950a);
  stroke: var(--fabric-theme-light, #6a950a);
}

.SimpleMultiLineAreaChart--themeFill {
  fill: var(--theme-base-default, #527a00);
  fill: var(--fabric-theme-base, #527a00);
}
.SimpleMultiLineAreaChart--themeStroke {
  stroke: var(--theme-base-default, #527a00);
  stroke: var(--fabric-theme-base, #527a00);
}

.Status__heading {
  margin: 70px 75px 40px;
}
body[data-feature-toggles~="jade"] .Status__heading {
  margin-top: 72px;
  margin-bottom: 35px;
}
@media (min-width: 1090px) {
  .Status__heading {
    margin: 70px 110px 40px;
  }
}
.Status__steps {
  background-color: #f3f3f3;
  border-radius: 2px;
  color: #4f4f4f;
  line-height: 18px;
  padding: 30px 40px;
  margin: 0 48px;
}
body[data-feature-toggles~="jade"] .Status__steps {
  background-color: var(--gray1, #f4f4f4);
  margin-left: auto;
  margin-right: auto;
  width: 606px;
}
.Status__stepHeading {
  font-family: 'BhrHeaderFont', 'Trebuchet MS';
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 5px;
}
.Status__stepHeading--success {
  color: #5c9412;
}
.Status__flexElemsJustified {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-pack: justify;
  -moz-box-pack: justify;
  -o-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}
.Status__complete {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
}
.Status__completeFigure {
  margin: -10px 8px -10px -10px;
}
.Status__completeChart__icon {
  display: block;
  margin: 0 auto;
}
:where(body[data-fabric-theme~="encore"]) .Status__completeChart__value {
  color: var(--gray10) !important;
}
:where(body[data-fabric-theme~="encore"]) .Status__completeChart__valueLabel {
  color: var(--gray10) !important;
  font-size: var(--font-size-teenie) !important;
  line-height: var(--line-height-teenie) !important;
}
.Status__completeTitle {
  font-family: 'BhrHeaderFont', 'Trebuchet MS';
  font-size: 24px;
  margin-bottom: 13px;
}
.Status__completeSubTitle {
  font-family: 'BhrHeaderFont', 'Trebuchet MS';
  font-size: 16px;
  margin-bottom: 10px;
}
.Status__completeText {
  margin-bottom: 24px;
}
body[data-feature-toggles~="jade"] .Status__completeTitle,
body[data-feature-toggles~="jade"] .Status__completeSubTitle {
  font-family: unset;
  color: var(--theme-base-default, #527a00);
  color: var(--fabric-theme-base, #527a00);
}
body[data-feature-toggles~="jade"] .Status__completeText {
  line-height: var(--line-height-medium, 22px);
}
body[data-feature-toggles~="jade"] .Status__stepDescription {
  color: var(--gray8, #555);
  font-size: var(--font-size-small, 14px);
  line-height: 17px;
}
body[data-feature-toggles~="jade"] .Status__stepDescription + body[data-feature-toggles~="jade"] .Status__stepDescription,
body[data-feature-toggles~="jade"] .Status__stepDescription + .Status__stepDescription {
  margin-top: 4px;
}
body[data-feature-toggles~="jade"] .Status__stepHeading {
  color: var(--gray10, #222);
  font-size: var(--font-size-large, 16px);
  font-weight: var(--font-weight-semibold, 600);
  font-family: unset;
  line-height: 19px;
}
body[data-feature-toggles~="jade"] .Status__stepHeading--success {
  color: var(--success, #0e840d);
  fill: var(--success, #0e840d);
}

.EmployeeWellbeingMarketingPromo {
  text-align: center;
}
.EmployeeWellbeingMarketingPromo__container {
  margin-left: auto;
  margin-right: auto;
  max-width: 618px;
  padding-top: 40px;
  overflow: hidden;
}
.EmployeeWellbeingMarketingPromo__title {
  font-family: unset;
  font-size: 32px;
  font-weight: var(--font-weight-semibold, 600);
  color: var(--theme-base-default, #527a00);
  color: var(--fabric-theme-base, #527a00);
  line-height: 42px;
  margin-top: 18px;
  max-width: 618px;
  margin-left: auto;
  margin-right: auto;
}
.EmployeeWellbeingMarketingPromo__video {
  height: 430px;
  position: relative;
  top: -120px;
  margin-bottom: -120px;
}
.EmployeeWellbeingMarketingPromo__banner {
  margin-top: 40px;
}
.EmployeeWellbeingMarketingPromo__button {
  margin-top: 40px;
}
.EmployeeWellbeingMarketingPromo__buttonText {
  font-size: 18px;
  padding: 0 10px;
}
.EmployeeWellbeingMarketingPromo__contactMessage {
  margin-top: 40px;
}
.EmployeeWellbeingMarketingPromo__contactMessageContent {
  border-radius: 4px;
  border: 1px solid;
  font-size: 17px;
  padding: 5px 20px;
}
.EmployeeWellbeingMarketingPromo__summary {
  color: var(--gray7, #686868);
  font-size: 18px;
  line-height: 26px;
  margin: 40px 0 0;
}
.EmployeeWellbeingMarketingPromo__copyright {
  color: #555;
  font-size: 9px;
  line-height: 11px;
  margin-top: 60px;
}
.EmployeeWellbeingMarketingPromo__previewButtons {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -o-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.EmployeeWellbeingMarketingPromo__previewButtons div {
  padding: 4px 16px;
  color: var(--info, #0772b3);
}
.EmployeeWellbeingMarketingPromo__previewButtons div a {
  fill: var(--info, #0772b3);
}
.EmployeeWellbeingMarketingPromo__previewButtons div:first-child {
  border-right: 1px solid #ccc;
}
.EmployeeWellbeingMarketingPromo__previewButtons span {
  margin-left: 8px;
  font-size: 15px;
  line-height: 22px;
}
.EmployeeWellbeingMarketingPromo__getStartedButtonContainer {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -o-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  margin-top: 20px;
}
.EmployeeWellbeingMarketingPromo__marketingPromoContactMessage {
  margin-top: 40px;
}

/* @startCleanup encore */
.EmployeeWellbeingHeader {
  border-bottom: 1px solid #ccc;
  margin-top: 19px;
  margin-bottom: 16px;
  padding-bottom: 20px;
  min-width: auto;
  text-align: left;
}
.EmployeeWellbeingHeader--noBorderBottom {
  border-bottom: none;
}
.EmployeeWellbeingHeader__heading {
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  color: var(--gray10, #222);
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
}
.EmployeeWellbeingHeader__headingIcon {
  line-height: 1px;
  fill: var(--theme-base-default, #527a00);
  fill: var(--fabric-theme-base, #527a00);
  display: -webkit-inline-box;
  display: -moz-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-box;
  display: inline-flex;
  margin-top: 1px;
}
.EmployeeWellbeingHeader__headingSpacer {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}
.EmployeeWellbeingHeader__title {
  color: var(--gray10, #222);
  font-weight: var(--font-weight-semibold, 600);
  font-family: unset;
  font-size: 18px;
  line-height: 25px;
  margin-left: 10px;
}
/* @endCleanup encore */

.EmployeeWellbeingGetStarted {
  text-align: center;
/* @startCleanup encore */
/* @endCleanup encore */
}
.EmployeeWellbeingGetStarted__container {
  margin-left: auto;
  margin-right: auto;
  max-width: 618px;
  padding-top: 40px;
  overflow: hidden;
}
:where(body[data-fabric-theme~="encore"]) .EmployeeWellbeingGetStarted__container {
  max-width: 700px;
}
:where(body[data-fabric-theme~="jade"]) .EmployeeWellbeingGetStarted__video {
  height: 430px;
  position: relative;
  top: -120px;
  margin-bottom: -120px;
}
.EmployeeWellbeingGetStarted__summary {
  color: var(--gray7, #686868);
  font-size: 18px;
  line-height: 26px;
  margin: 40px 0 24px;
}
.EmployeeWellbeingGetStarted__previewButtons {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -o-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.EmployeeWellbeingGetStarted__previewButtons div {
  padding: 4px 16px;
  color: var(--info, #0772b3);
}
.EmployeeWellbeingGetStarted__previewButtons div a {
  fill: var(--info, #0772b3);
}
.EmployeeWellbeingGetStarted__previewButtons div:first-child {
  border-right: 1px solid #ccc;
}
.EmployeeWellbeingGetStarted__previewButtons span {
  margin-left: 8px;
  font-size: 15px;
  line-height: 22px;
}

#popover > div {
  max-width: 462px;
}
#popover p {
  font-size: 14px;
  line-height: 20px;
  color: var(--gray9, #444);
  margin-bottom: 8px;
}
#popover p:last-child {
  margin-bottom: 0;
}
#popover .listHeader {
  font-size: 15px;
  line-height: 22px;
  color: var(--gray10, #222);
  font-weight: 700;
}
#popover ul {
  margin-bottom: 8px;
}
#popover ul li span {
  font-size: 15px;
  line-height: 22px;
  color: var(--gray7, #686868);
}
#popover ul li span:last-child {
  font-size: 14px;
  line-height: 22px;
  color: var(--gray10, #222);
  margin-left: 10px;
}
#popover ul li:last-child span:last-child {
  margin-left: 13px;
}

#extra {
  margin-top: -4px;
}
#extra > li {
  margin: 0 5px 4px;
}
#extra > li > .optional .drop {
  margin-top: -2px;
}
#extra .fieldInfo {
  position: relative;
  top: 5px;
}
#extra.checkboxColList2 {
  padding: 4px 0 0 3px;
}
#extra.checkboxColList2 > li {
  margin-bottom: 5px;
  height: 21px;
}
#extra li > div:not(:first-child) {
  margin-left: 8px;
}
#extra li > div {
  display: inline-block;
}
#extra .filter-option > label {
  width: calc(100% - 12px);
  line-height: 1.2em !important;
}
#extra .filter-option > label > span {
  display: inline-block;
  width: 100%;
  vertical-align: bottom;
}
#extra .filter-option.selected {
  max-width: calc(100% - 60px);
}
#extra .filter-option-drop.selected {
  max-width: calc(100% - 220px);
}
#extra .filter-option-drop-link.selected {
  max-width: calc(100% - 280px);
}
#extra .disabled-row,
select.some-fields {
  display: none;
}
.fieldBox.addNew {
  min-width: 13%;
  float: left;
}
.fieldBox .fieldInfo {
  color: #909090;
  font-size: 13px;
}
.fieldBox.permSectionsBox {
  width: 13.5%;
  display: table-cell;
  vertical-align: top;
}
.fieldBox.sectionBox {
  min-height: 200px;
  display: table-cell;
  width: 100%;
  vertical-align: top;
}
form .sectionBox ul li {
  margin: 4px 5px 4px;
}
.multi-column {
  display: table;
  width: 100%;
  margin-top: 11px;
}
.permSectionsBox > .fieldDiv {
  margin-top: 10px;
}
.sectionBox > .fieldDiv {
  border-left-style: solid;
  border-left-width: 1px;
  margin-top: 14px;
  padding-left: 5%;
}
.sectionBox > label {
  padding-left: 5%;
}
#permSections {
  position: relative;
}
#permSections li {
  font-size: 15px;
  height: 27px;
  padding: 6px 0 0;
}
#permSections li.on a {
  color: #222;
  font-weight: bold;
}
#section-arrow {
  background-image: url("https://staticfe.bamboohr.com/resources/images/bicons/perm-selected-arrow.png");
  display: block;
  position: absolute;
  z-index: 5;
  width: 7px;
  height: 13px;
  right: -8px;
  top: 9px;
}
.sectionFilterBox {
  margin-top: 10px;
  border-top: solid 1px #e1e1e1;
  padding-top: 15px;
  width: 100%;
}
.optional {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  height: 20px;
  line-height: 21px;
  vertical-align: top;
}
.optional .showPopup {
  font-size: 13px;
  float: left;
  margin-top: 1px;
}
.drop {
  float: left;
}
.showPopup + .drop {
  margin-left: 5px;
}
.drop .small {
  margin-top: 0;
}
.selfServiceSingle {
  margin-top: 6px;
  display: inline-block;
}
#filter-description-wrapper {
  display: none;
  margin-top: 9px;
}
#filter-description a {
  margin-right: 2px;
}
.extra__celebration {
  margin-left: 20px;
}
.extra__celebration-child {
  padding: 0 0 4px;
  margin: 0;
}
.extraCommentary__text {
  color: #777;
  font-size: 14px;
  padding-left: 5px;
  margin: 14px 0 4px;
}
.extraCommentary__listItem {
  color: #222;
  font-size: 15px;
  font-weight: 600;
  line-height: 1.2em;
  padding-top: 3px;
}
.GroupAccessModal {
  height: 100%;
  margin: 0;
  padding: 0;
}
.GroupAccessModal__List {
  height: calc(100% - 56px);
}
.GroupAccessModal__List.fieldRow {
  margin-bottom: 0;
}
.GroupAccessModal__Label {
  margin-left: 10px;
  margin-right: 10px;
}
.GroupAccessModal__Label-Text {
  color: #afafaf;
  font-size: 11px;
  border-bottom: #afafaf solid 1px;
}
.GroupAccessModal .filter-list-wrapper {
  height: 100%;
}
.GroupAccessModal ul.filter-list li.GroupAccessModal__Label {
  min-height: 17px;
}
.GroupAccessModal ul.filter-list {
  height: calc(100% - 20px) !important;
  min-width: 450px;
  min-height: 110px;
  max-height: 350px;
  overflow-y: auto;
  overflow-x: hidden;
}
.GroupAccessModal ul.filter-list > li {
  min-height: 34px;
  overflow: visible !important;
}
.GroupAccessModal ul.filter-list > li > .filter-option label {
  margin-top: 0;
}
.GroupAccessModal ul.filter-list > li > .optional {
  margin-top: 5px;
  height: auto;
}
.GroupAccessModal ul.filter-list > li .chzn-container {
  margin: 0;
}
.GroupAccessModal ul.filter-list > li h3 {
  padding: 0 10px;
}
.GroupAccessModal ul.filter-list > li span.truncate {
  display: inline-block;
  position: relative;
  top: 3px;
}

.SettingsPermissionsApi__section + .SettingsPermissionsApi__section {
  padding-top: 30px;
}
.SettingsPermissionsApi__sectionTitle {
  margin: 21px 0 7px;
}
.SettingsPermissionsApi.SettingsPermissionsApi--indent .SettingsPermissionsApi__section {
  padding-left: 18px;
}
.SettingsPermissionsApi__section > .SettingsPermissionsApi__table:first-child {
  margin-top: 6px;
}
.SettingsPermissionsApi__sectionTitle + .SettingsPermissionsApi__headerActions {
  padding-top: 0;
}
.SettingsPermissionsApi__headerTitle {
  padding-bottom: 8px;
}
.SettingsPermissionsApi__headerIcon {
  vertical-align: middle;
}
.SettingsPermissionsApi__headerActions {
  padding: 12px 0 10px;
}
.SettingsPermissionsApi__tableCell.SettingsPermissionsApi__tableCell--created {
  width: 18%;
}
.SettingsPermissionsApi__tableCell.SettingsPermissionsApi__tableCell--application {
  width: 18%;
}
.SettingsPermissionsApi__tableCell.SettingsPermissionsApi__tableCell--deviceId {
  width: 18%;
}
.SettingsPermissionsApi__tableCell.SettingsPermissionsApi__tableCell--actions {
  width: 40px;
  padding-right: 15px;
}
.SettingsPermissionsApi__tableNote {
  margin: 10px 0;
  color: #999;
  font-size: 13px;
}
.SettingsPermissionsApi__action {
  text-decoration: none !important;
  fill: #898989;
}
.SettingsPermissionsApi__tableRow:not(:hover) .SettingsPermissionsApi__action {
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
}
.SettingsPermissionsApi__action:hover {
  fill: #666;
}
.SettingsPermissionsApi__action:not(:first-child) {
  margin-left: 6px;
}
.SettingsPermissionsApiAddEditModal .SimpleModal__body {
  width: 346px;
}
.SettingsPermissionsApiAddEditModal__keySummary {
  position: relative;
}
.SettingsPermissionsApiAddEditModal__keySummary:not(.SettingsPermissionsApiAddEditModal__keySummary--added) {
  display: none;
}
.SettingsPermissionsApiAddEditModal__newApiKey {
  background: #ffece5;
  margin: 7px 0 2px;
  padding: 11px 8px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  height: 44px;
  width: 512px;
}
.SettingsPermissionsApiAddEditModal__copyApiKey {
  float: right;
  text-transform: uppercase;
  font-size: 12px;
  line-height: 15px;
  position: absolute;
  top: 0;
  right: 0;
}

.ba-TypeDeleteModal {
  padding: 22px 40px 30px;
  text-align: center;
  width: 334px;
}
.ba-TypeDeleteModal__trashIcon {
  margin: 0 0 6px;
  width: 109px;
}
.ba-TypeDeleteModal__confirmationMessage {
  font-size: 18px;
  margin: 0 auto 20px;
  line-height: 1.4;
  color: #565656;
}
.ba-TypeDeleteModal__actionCard {
  padding: 18px 20px 22px;
  background-color: #f0f0f0;
  border: 1px solid #ba3108;
  border-radius: 3px;
}
.ba-TypeDeleteModal__deleteMessage {
  margin: 0 0 12px;
  font-size: 13px;
  color: #ba3108;
  line-height: normal;
}
.ba-TypeDeleteModal__typeDelete {
  margin: 0 0 6px;
  font-size: 15px;
  color: #ba3108;
}

.JobTitleEEOCategories {
  margin-top: -48px;
  position: absolute;
  right: 0;
}
.JobTitleEEOCategoriesButton--link,
.JobTitleEEOCategoriesButton--link:active,
.JobTitleEEOCategoriesButton--link:focus,
.JobTitleEEOCategoriesButton--link:hover,
.JobTitleEEOCategoriesButton--link:visited {
  font-size: 15px;
}

.EEO1CategoriesModal__body {
  max-height: 42vh;
  overflow: auto;
  padding: 0;
}

.EEO1Category {
  padding: 0 0 24px 0;
}
.EEO1Category + .EEO1Category {
  border-top: 1px solid #e0e0e0;
}
.EEO1Category__heading {
  color: #555;
  font-size: 16px;
  margin-bottom: 5px;
}
.EEO1Category__body {
  padding-left: 2px;
  margin-left: 0;
  margin-top: 0;
}
.EEO1Category__label {
  display: block;
  margin-bottom: 4px;
}
.EEO1Category__select {
  width: 240px;
}

.EEO1Description {
  margin-bottom: 15px;
}
.EEO1Description__toggle,
.EEO1Description__toggle:active,
.EEO1Description__toggle:focus,
.EEO1Description__toggle:hover,
.EEO1Description__toggle:visited {
  font-size: 13px;
}
.EEO1Description__caret {
  margin-right: 8px;
}
.EEO1Description__caret svg {
  fill: var(--link);
  -webkit-transition: -webkit-transform 0.3s ease;
  -moz-transition: -moz-transform 0.3s ease;
  -o-transition: -o-transform 0.3s ease;
  -ms-transition: -ms-transform 0.3s ease;
  transition: transform 0.3s ease;
}
.EEO1Description__caret--isRotatedDown svg {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}
.EEO1Description__body {
  display: none;
  margin-left: 10px;
}
.EEO1Description__body--isVisible {
  display: block;
}
.EEO1Description__paragraph {
  margin: 10px 0;
  font-size: var(--font-size-small, 14px);
}

.EEO1Header {
  border-bottom: 1px solid #e0e0e0;
  width: auto;
  min-width: auto;
  text-align: left;
  background-color: #fff;
  border-top: none;
  padding: 20px 0px 20px 0px;
}


.AcaMigrationModalTitle__divider {
  border-bottom: 1px solid #e0e0e0;
  padding: 0 0 32px;
  margin: 0 0 24px;
}

.EmployeeFieldsInlineAddForm__button {
  position: relative;
  margin-left: 8px;
}

.EmploymentStatusWrapper__addButtonContainer {
  margin: 23px 0 12px;
}

.EmployeeFieldsForm .disabled .EmployeeFieldsForm__actions {
  display: none;
}
.EmployeeFieldsForm__deleteWarning {
  text-align: center;
}
.EmployeeFieldsForm__modal--legend {
  margin-bottom: 16px;
  font-weight: var(--font-weight-medium, 500);
}
.EmployeeFieldsForm__modal--radiogroup .fab-Radio {
  margin-bottom: 16px;
}
.EmployeeFieldsForm__modal--label {
  margin-bottom: 4px;
}

.PayScheduleList__employeeModal {
  padding-bottom: 32px;
}

.PS_AddEditForm {
  width: 410px;
}
.PS_AddEditForm__fieldSet {
  padding-bottom: 9px !important;
}
.PS_AddEditForm__NoEdit {
  position: relative;
}
.PS_AddEditForm__NoEditMessage {
  display: none;
  padding: 3px 0 10px;
  position: relative;
}
.PS_AddEditForm__NoEditIcon {
  margin: 3px 7px 0 0;
}
.PS_AddEditForm__NoEditText {
  font-size: 12px;
}
.PS_AddEditFormFrequencySection {
  display: none;
}
.PS_AddEditForm__continuedLabel {
  display: inline-block;
  color: #555;
  font-size: 15px;
  margin-left: 6px;
  margin-right: 7px;
  vertical-align: middle;
  position: relative;
  top: 1px;
}
.PS_AddEditForm__sameError {
  margin-left: 0 !important;
}
.PS_AddEditForm--limitedEdit .PS_AddEditForm__NoEdit {
  margin: 30px 0 20px;
}
.PS_AddEditForm--limitedEdit .PS_AddEditForm__NoEdit .PS_AddEditForm__continuedLabel {
  color: #999;
}
.PS_AddEditForm--limitedEdit .PS_AddEditForm__NoEditMessage {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
}
.PS_AddEditForm--limitedEdit .PS_AddEditForm__NoEditBG {
  background-color: #f0f0f0;
  position: absolute;
  top: -10px;
  right: -10px;
  bottom: -10px;
  left: -10px;
}

.PS_Calendar {
  padding: 0 0 32px 0;
}
.PS_Calendar__adjustedIcon {
  height: 13px;
  position: relative;
  display: -webkit-inline-box;
  display: -moz-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-box;
  display: inline-flex;
  fill: #999;
  line-height: 0;
  top: 0;
  margin: 0 4px 0 8px;
}
.PS_Calendar__adjustedIcon:hover {
  fill: var(--theme-base-default, #527a00);
  fill: var(--fabric-theme-base, #527a00);
}

.PS_AddButton {
  display: inline-block;
  width: auto;
  height: 28px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  margin: 13px 0 0;
  font-family: 'Source Sans Pro';
}
.PS_BlankState {
  margin-top: 15px;
}
.PS_List {
  margin-top: 15px;
}
.PS_List__col--wide {
  width: 54px;
}
.PS_List__col--wider {
  width: 160px;
}
.PS_List__actions {
  position: relative;
  top: 2px;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-pack: end;
  -moz-box-pack: end;
  -o-box-pack: end;
  -ms-flex-pack: end;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.PS_List .BhrTable__row,
.PS_List .BhrTable__cell,
.PS_List .BhrTable__row:hover,
.PS_List .BhrTable__cell:hover {
  cursor: default !important;
}
.PS_List .BhrTable__cell {
  padding-top: 11px;
  padding-bottom: 12px;
}
.PS_List__inPayrollCol {
  margin: 0;
  padding-right: 0;
  width: 17px;
}
.PS_List__inPayrollColIcon {
  fill: #898a8a;
  position: relative;
  top: 2px;
}
.PS_List__inPayrollColEinDeletedIcon {
  fill: #bd5800;
  position: relative;
  top: 2px;
}
.PS_TableButton {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -o-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  height: 14px;
  width: 14px;
  margin: 0 0 0 8px;
  position: relative;
}
.PS_TableButton:hover:before {
  content: "";
  width: 18px;
  height: 18px;
  border: 1px solid #afafaf;
  border-radius: 3px;
  position: absolute;
  top: -3px;
  left: -3px;
}
.PS_TableButton__icon {
  fill: #898a8a;
}
.PS_TableButton__icon--edit {
  position: relative;
  left: 1px;
}
.PS_ListItem:only-child .PS_TableButton--delete {
  cursor: default;
}
.PS_ListItem:only-child .PS_TableButton--delete:hover,
.PS_ListItem:only-child .PS_TableButton--delete:active {
  cursor: default;
  background-color: transparent;
  border-color: transparent;
}
.PS_ListItem:only-child .PS_TableButton--delete .PS_TableButton__icon {
  fill: #c5c5c5;
}
.PS_Tooltip {
  font-size: 12px;
  line-height: 15px;
  max-width: 140px;
  padding: 6px 10px;
  text-align: left;
}
.PS_EmpListModal {
  min-height: 300px;
  width: 910px;
}
.PS_EmpListModal__header {
  background-color: #f4f4f4;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  padding: 20px 20px 18px;
}
.PS_EmpListModal__headerIcon {
  margin-right: 10px;
}
.PS_EmpListModal__headerTitle {
  font-size: 20px;
  font-weight: 500;
}
.PS_EmpListModal__table {
  border-bottom: 1px solid #d9d9d9;
}

/* Styles for stale data modal */
.TimeTrackingWarningModal {
  text-align: center;
  width: 100%;
  padding: 0;
/* .TimeTrackingWarningModal__icon */
/* .TimeTrackingWarningModal__paragraph */
}
.TimeTrackingWarningModal__icon {
  fill: var(--attention, #bd5800);
  margin-bottom: 24px;
}
.TimeTrackingWarningModal__paragraph + .TimeTrackingWarningModal__paragraph {
  margin-top: 16px;
}

.ChooseDate {
  width: 100%;
  margin-bottom: 10px;
}
.ChooseDate__clockInTime {
  margin-left: 8px;
}
.ChooseDate__formDash {
  margin: 0 8px;
  position: relative;
  top: 10px;
}
.ChooseDate__timeInput {
  margin: 0 8px;
}
.ChooseDate__totalTime {
  color: var(--gray9, #444);
  font-size: 14px;
}
.ChooseDate__totalTimeContainer {
  position: relative;
  top: 13px;
  left: 13px;
}

.ClockOutSlat {
  border-bottom: 1px solid #d8d8d8;
  margin: 0 20px;
  padding: 12px 0 15px;
/* .ClockOutSlat:last-of-type */
/* .ClockOutSlat__header */
/* .ClockOutSlat__employee */
/* .ClockOutSlat__name */
/* .ClockOutSlat__timezone */
/* .ClockOutSlat__dateRange */
/* .ClockOutSlat__clockForm */
/* .ClockOutSlat__startTime */
/* .ClockOutSlat__clockedOut */
}
.ClockOutSlat:last-of-type {
  border-bottom: none;
}
.ClockOutSlat:last-of-type {
  padding-bottom: 30px;
}
.ClockOutSlat__header {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-pack: justify;
  -moz-box-pack: justify;
  -o-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  margin-bottom: 8px;
}
.ClockOutSlat__employee {
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
}
.ClockOutSlat__name {
  font-size: 16px;
  color: var(--gray10, #222);
  font-weight: 600;
  margin-left: 7px;
}
.ClockOutSlat__timezone {
  color: var(--gray7, #686868);
  font-size: 13px;
  margin: 0 0 0 5px;
}
.ClockOutSlat__dateRange {
  font-size: 13px;
  color: var(--gray7, #686868);
}
.ClockOutSlat__clockForm {
  padding-left: 20px !important;
}
.ClockOutSlat__startTime {
  width: 73px !important;
}
.ClockOutSlat__clockedOut {
  display: -webkit-inline-box;
  display: -moz-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-box;
  display: inline-flex;
  fill: #f0a404;
  line-height: 0;
  margin-bottom: 5px;
}

.ClockedOutError {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  width: 346px;
  padding: 15px 20px 30px;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: start;
  -moz-box-align: start;
  -o-box-align: start;
  -ms-flex-align: start;
  -webkit-align-items: flex-start;
  align-items: flex-start;
  -webkit-box-pack: justify;
  -moz-box-pack: justify;
  -o-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
/* .ClockedOutError__warningIcon */
/* .ClockedOutError__message */
}
.ClockedOutError__warningIcon {
  fill: #f0a404;
  margin-top: 5px;
}
.ClockedOutError__message {
  width: 269px;
/* .ClockedOutError__messageHeader */
/* .ClockedOutError__messageText */
}
.ClockedOutError__messageHeader {
  color: #555;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 5px;
}
.ClockedOutError__messageText {
  color: #777;
}

.ApproverClockOut {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
/* .ApproverClockOut__warning */
}
.ApproverClockOut__warning {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -o-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  text-align: center !important;
  padding: 0 0 10px;
/* ApproverClockOut__warningIcon */
/* ApproverClockOut__warningHeader */
/* ApproverClockOut__warningText */
}
.ApproverClockOut__warningIcon {
  color: #f0a404;
  fill: var(--attention, #bd5800);
  margin-bottom: 0;
}
.ApproverClockOut__warningHeader {
  margin-bottom: 4px;
}
.ApproverClockOut__warningText {
  font-size: 14px;
  line-height: 21px;
  text-align: center !important;
  color: var(--gray7, #686868);
}

.ApproverClockOutRoot {
  min-height: 286px;
  width: 570px;
}

.TransactionMethodSelector .TransactionMethodSelector__groupLabel {
  margin-bottom: 12px;
}
.TransactionMethodSelector .TransactionMethodSelector__otherBank {
  margin-left: 24px;
}
.TransactionMethodSelector .TransactionMethodSelector__otherBank > div {
  margin-bottom: 12px;
}
.TransactionMethodSelector .TransactionMethodSelector__wireTransferMessage {
  margin-left: 24px;
}

.WireTransferInfo td {
  padding: 2px 0;
  font-size: var(--font-size-medium, 15px);
}
.WireTransferInfo td:nth-child(1) {
  width: 170px;
  color: var(--gray7, #686868);
}
.WireTransferInfo td:nth-child(2) {
  font-weight: var(--font-weight-bold, 700);
  color: var(--gray8, #555);
}

.TaskRow {
  margin: 4px 0 8px 24px;
}
.TaskRow__deleteAction {
  cursor: pointer;
  margin-left: 12px;
}
.TaskRow__deleteAction--hasLabel {
  margin-top: 20px;
}

.TTProjectTracking {
  margin: 0px;
}
.TTProjectTracking__radioGroup {
  margin-top: 18px;
}
.TTProjectTracking__bulkSelector {
  margin-left: 26px;
  margin-top: 8px;
}
.TTProjectTracking__select {
  margin-top: 2px;
}
.TTProjectTracking__selectLabel {
  padding-left: 0;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 6px;
}
.TTProjectTracking__taskInputLabel {
  margin-bottom: 1px;
}
.TTProjectTracking__fieldset {
  margin: 8px 0 0 24px;
}
.TTProjectTracking__selectToggle {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
}
.TTProjectTracking__actions {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-pack: justify;
  -moz-box-pack: justify;
  -o-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  padding: 20px 0 18px;
}
.TTProjectTracking__dropdownGearBox {
  margin-left: 12px;
}
.TTProjectTracking__flexDiv {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
}
.TTProjectTracking__isLoading {
  opacity: 0.5;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
  filter: alpha(opacity=50);
  pointer-events: none;
}
.TTProjectTracking__emptyTable {
  width: 100%;
  padding-top: 63px;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -o-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}
.TTProjectTracking__modalContent {
  text-align: center;
}
.TTProjectTracking__taskActions {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: justify;
  -moz-box-pack: justify;
  -o-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  margin: 14px 0 0 24px;
  width: 496px;
}

.TimeTrackingNotificationModal__description {
  text-align: center;
  color: var(--gray9, #444);
  padding: 0 30px 20px;
}
.TimeTrackingNotificationModal__doubleRow {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -o-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}
.TimeTrackingNotificationModal__doubleRow__subText {
  color: var(--gray7, #686868);
  font-size: var(--font-size-teenie, 13px);
}

.EnableModal {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -o-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -o-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  width: 100%;
  padding-bottom: 0;
}
.EnableModal__clock-success-icon {
  fill: var(--theme-base-default, #527a00);
  fill: var(--fabric-theme-base, #527a00);
  margin-top: 0;
  margin-bottom: 15px;
}
.EnableModal__clock-edit-icon {
  fill: var(--theme-base-default, #527a00);
  fill: var(--fabric-theme-base, #527a00);
  margin-top: 0;
  margin-bottom: 15px;
}
.EnableModal__text {
  margin: 0;
}
.EnableModal__sub-text {
  max-width: unset;
  color: var(--gray7, #686868);
  text-align: center !important;
  margin: 4px 0 0;
}
.EnableModal__header {
  font-size: 18px;
  font-weight: 500;
  margin: 0 0 4px;
}
.EnableModal__header--secondary {
  color: var(--gray9, #444);
  margin-bottom: 0;
}
.EnableModalTable {
  width: 100%;
  background-color: var(--gray1, #f4f4f4);
  padding: 16px;
  margin-top: 20px;
}
.EnableModalTable__row {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
}
.EnableModalTable__column1 {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding-right: 4px;
  width: 75%;
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.EnableModalTable__column2 {
  width: 160px;
}
.EnableModalTable__columnHeader {
  font-size: 14px;
  border-bottom: 1px solid #ccc;
  color: var(--gray8, #555);
  margin-bottom: 16px;
  padding-bottom: 9px;
}
.EnableModalTable__boldSchedule {
  font-size: 15px;
  font-weight: 600;
  height: 28px;
  margin-bottom: 12px;
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  padding-top: 5px;
}
.EnableModalTable__dropdown {
  margin-bottom: 10px;
}
body[data-feature-toggles~="timeTrackingFutureScheduling"] .EnableModalTable__row {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  padding-top: 5px;
}
body[data-feature-toggles~="timeTrackingFutureScheduling"] .EnableModalTable__column1 {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding-right: 4px;
  width: 75%;
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
body[data-feature-toggles~="timeTrackingFutureScheduling"] .EnableModalTable__boldSchedule {
  font-size: 15px;
  font-weight: 600;
  height: 42px;
  margin-bottom: 12px;
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  padding-top: 5px;
}
body[data-feature-toggles~="timeTrackingFutureScheduling"] .EnableModalTable__employeeCount {
  font-size: var(--font-size-teenie, 13px);
  color: var(--gray7, #686868);
  font-weight: var(--font-weight-normal, 400);
  line-height: 14px;
}

.STE_settings-subnav-count {
  display: inline-block;
  vertical-align: baseline;
}
.STE_EmpTable .BhrTable__row,
.STE_EmpTable .BhrTable__cell,
.STE_EmpTable .BhrTable__row:hover,
.STE_EmpTable .BhrTable__cell:hover {
  cursor: default !important;
}
.STE_EmpTable__actions {
  width: 37px;
}
.STE_EmpTable__logged {
  width: 192px !important;
}
.STE_EmpTable__actionIcon {
  cursor: pointer;
  fill: #898a8a;
  margin-top: 3px;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  -webkit-transition: opacity 0.2s ease-in-out;
  -moz-transition: opacity 0.2s ease-in-out;
  -o-transition: opacity 0.2s ease-in-out;
  -ms-transition: opacity 0.2s ease-in-out;
  transition: opacity 0.2s ease-in-out;
}
.STE_EmpTable__actionIcon:first-of-type:not(:last-of-type) {
  margin-right: 5px;
}
.STE_EmpTable__row:hover .STE_EmpTable__actionIcon {
  opacity: 1;
  -ms-filter: none;
  filter: none;
}
.STE_EmpTable__warning-icon {
  fill: #f6a400;
  padding-right: 8px;
}
.STE_EmpTable__data--gray {
  color: #939393;
}
.STE_EmpTable__startDate {
  color: #939393;
  font-size: 13px;
}
.STE_EmpTable__startDate {
  margin-top: -4px;
}
.STE_EmpTable__warning {
  display: -webkit-inline-box;
  display: -moz-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-box;
  display: inline-flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.STE_EmpTable__warning__icon {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  fill: var(--attention, #bd5800);
  margin-right: 8px;
}
.STE_ScheduleModal__issueIcon {
  fill: #adadad;
  margin-right: 4px;
  position: relative;
  top: 1px;
}

.ChecklistItem {
  height: 90px;
  width: 500px;
  border: 1px solid #c8c8c8;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-orient: horizontal;
  -moz-box-orient: horizontal;
  -o-box-orient: horizontal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  margin: 10px 0;
  border-radius: var(--radius-300);
}
.ChecklistItem:hover {
  background-color: #fafafa;
}
.ChecklistItem__checkbox {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0 15px 0 21px;
}
.ChecklistItem__checkbox-box {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  background-color: #fff;
  border: 1px solid #afafaf;
  border-radius: 3px;
  -webkit-box-shadow: inset 0 9px 9px -10px #afafaf;
  box-shadow: inset 0 9px 9px -10px #afafaf;
  display: block;
  height: 24px;
  opacity: 0.4;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=40)";
  filter: alpha(opacity=40);
  position: relative;
  width: 24px;
}
.ChecklistItem__checkbox-box:hover,
.ChecklistItem__checkbox-box .css_checkMark {
  cursor: default;
}
.ChecklistItem__checkbox-box--pending .css_checkMark {
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  position: absolute;
  -webkit-transition: opacity 0.2s ease;
  -moz-transition: opacity 0.2s ease;
  -o-transition: opacity 0.2s ease;
  -ms-transition: opacity 0.2s ease;
  transition: opacity 0.2s ease;
}
.ChecklistItem__checkbox-box--pending.ChecklistItem__checkbox-box--active:hover .css_checkMark {
  opacity: 1;
  -ms-filter: none;
  filter: none;
}
.ChecklistItem__checkbox-box--active {
  opacity: 1;
  -ms-filter: none;
  filter: none;
}
.ChecklistItem__checkbox-box--active:hover,
.ChecklistItem__checkbox-box--active:hover .css_checkMark {
  cursor: pointer;
}
.ChecklistItem__title {
  font-size: 16px !important;
  padding-top: 0 !important;
}
.ChecklistItem__text {
  margin: 1px 0 5px;
}
.ChecklistItem__link {
  font-weight: 400;
}
.ChecklistItem {
  height: 100px;
  width: 590px;
  border: 1px solid #ccc;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-orient: horizontal;
  -moz-box-orient: horizontal;
  -o-box-orient: horizontal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  margin: 16px 0;
  border-radius: var(--radius-300);
}
.ChecklistItem:hover {
  background-color: var(--gray1, #f4f4f4);
}
.ChecklistItem__checkbox {
  margin: 0 16px 45px 24px;
}
.ChecklistItem__title {
  font-weight: 700;
  color: var(--theme-base-default, #527a00);
  color: var(--fabric-theme-base, #527a00);
  margin: 2px 0 4px;
  line-height: 18px;
}
.ChecklistItem__text {
  color: var(--gray7, #686868);
  font-size: 14px;
  line-height: 18px;
  margin: 0 0 3px;
}
.ChecklistItem__link {
  font-size: 14px;
}
body[data-feature-toggles~="time_tracking_trial"] .ChecklistItem {
  height: unset;
  padding: 18px 0;
}
body[data-feature-toggles~="time_tracking_trial"] .ChecklistItem__checkbox {
  -webkit-align-self: flex-start;
  align-self: flex-start;
  -ms-flex-item-align: start;
}

.PlaceholderItem {
  height: 90px;
  width: 500px;
  border: 1px solid #dcdcdc;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-orient: horizontal;
  -moz-box-orient: horizontal;
  -o-box-orient: horizontal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  margin: 10px 0;
  border-radius: var(--radius-300);
}
.PlaceholderItem__checkbox {
  height: 25px;
  width: 25px;
  background-color: #f7f7f7;
  margin: 0 14px 0 21px;
  border-radius: 2px;
}
.PlaceholderItem__title {
  height: 14px;
  width: 230px;
  background-color: #f7f7f7;
  border-radius: 2px;
}
.PlaceholderItem__text {
  height: 14px;
  width: 405px;
  background-color: #f7f7f7;
  margin: 6px 0;
  border-radius: 2px;
}
.PlaceholderItem__link {
  height: 14px;
  width: 94px;
  background-color: #f7f7f7;
  border-radius: 2px;
}
.PlaceholderItem {
  height: 100px;
  width: 590px;
  border: 1px solid #e0e0e0;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-orient: horizontal;
  -moz-box-orient: horizontal;
  -o-box-orient: horizontal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  margin: 16px 0;
  border-radius: var(--radius-300);
}
.PlaceholderItem__checkbox {
  height: 24px;
  width: 24px;
  background-color: var(--gray1, #f4f4f4);
  margin: 0 16px 45px 24px;
  border-radius: 2px;
}
.PlaceholderItem__title {
  height: 14px;
  width: 230px;
  background-color: var(--gray1, #f4f4f4);
  border-radius: 2px;
}
.PlaceholderItem__text {
  height: 14px;
  width: 405px;
  background-color: var(--gray1, #f4f4f4);
  margin: 6px 0;
  border-radius: 2px;
}
.PlaceholderItem__link {
  height: 14px;
  width: 94px;
  background-color: var(--gray1, #f4f4f4);
  border-radius: 2px;
}

.TTChecklist {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -o-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -moz-box-align: start;
  -o-box-align: start;
  -ms-flex-align: start;
  -webkit-align-items: flex-start;
  align-items: flex-start;
}
.TTChecklist__header {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-orient: horizontal;
  -moz-box-orient: horizontal;
  -o-box-orient: horizontal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  border-bottom: 1px solid #ccc;
  padding-bottom: 23px;
  margin: 22px 0;
  width: 100%;
}
.TTChecklist__header__icon {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  padding: 0;
  fill: var(--theme-base-default, #527a00);
  fill: var(--fabric-theme-base, #527a00);
  margin-right: 16px;
}
.TTChecklist__header__text {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -o-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}
.TTChecklist__header__text--title {
  font-size: 22px !important;
  line-height: 30px !important;
  margin: 0 0 3px !important;
  padding: 0 !important;
}
.TTChecklist__list {
  margin-bottom: 12px;
}
.TTChecklist__popover--enable {
  margin-right: 10px;
}
.TTChecklist__buttons {
  margin-top: 10px;
  padding-top: 24px;
  border-top: 1px solid #ccc;
  width: 100%;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  gap: 24px;
}

/* For the marketing upsell page for time tracking */
.TimeTrackingUpsell {
  padding-top: 18px;
  text-align: center;
}
.TimeTrackingUpsell__video {
  height: 386px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 40px;
  max-width: 682px;
}
.TimeTrackingUpsell__button {
  margin-top: 40px;
}
.TimeTrackingUpsell__button--hide {
  display: none;
}
.TimeTrackingUpsell__contactMessage {
  border-radius: 4px;
  border: 1px solid;
  font-size: 17px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 40px;
  max-width: 320px;
  padding: 5px 20px;
}
.TimeTrackingUpsell__contactMessage--hide {
  display: none;
}
.TimeTrackingUpsell__summary {
  color: var(--gray7, #686868);
  font-size: var(--font-size-large, 16px);
  line-height: var(--line-height-large, 24px);
  margin-left: auto;
  margin-right: auto;
  margin-top: 40px;
  text-align: center;
}
.TimeTrackingUpsell__note {
  margin-top: 30px;
}
.TTModal {
/* This div is needed to allow hidden "wizard" steps. */
  overflow-x: hidden;
  width: 520px;
  background-color: #fff;
  height: 520px;
}
.TTModal__steps-wrap {
  left: 0;
  position: relative;
  width: calc(520px * 4);
}
.TTModal__step-section {
  float: left;
  margin: 0;
  padding: 0 0 18px;
  width: 520px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.TTModal__header {
  padding: 10px 0;
  text-align: center;
}
.TTModal__subtext {
  margin: 18px 0 11px;
}
.TTModal__page-section {
  margin-bottom: 20px;
}
.TTModal__confirmation-message {
  text-align: center;
}
.TTModal__not-included-list > .TTModal__list-item {
  color: var(--gray10, #222);
  font-size: 15px;
  padding: 0 0 8px;
  line-height: 17px;
}
.TTModal__not-included-list > .TTModal__list-item::before {
  content: "\2022";
  display: inline-block;
  width: 15px;
  color: var(--theme-base-default, #527a00);
  color: var(--fabric-theme-base, #527a00);
}
.TTModal__icon {
  margin-bottom: 12px;
  fill: var(--theme-base-default, #527a00);
  fill: var(--fabric-theme-base, #527a00);
}
.TTModal__icon--question {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -o-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}

.STS_Header {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.STS_HeaderIcon {
  fill: var(--theme-base-default, #527a00);
  fill: var(--fabric-theme-base, #527a00);
  margin: 5px 9px 0 0;
}
.STS_HeaderIcon--trial {
  margin: 8px 9px 0 0;
}
.STS_SectionHeader {
  color: var(--gray10, #222);
  font-size: 16px;
  font-weight: 600;
  margin: 15px 0 9px;
  padding: 18px 0 0;
  border-top: 1px solid #e0e0e0;
}
.STS_SectionHeader--timesheetType {
  border-top: none;
  margin-top: 10px;
  padding: 0 0 10px;
}
.STS_Select {
  margin: 2px 9px 0 0;
}
.STS_formNote {
  margin-left: 0;
}
.STS_TimesheetTypeCard.STS_TimesheetTypeCard {
  margin-right: 12px;
}
.STS_TimesheetType {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  padding: 21px 26px 14px 13px;
  height: 67px;
  width: 285px;
/* .STS_TimesheetType__icon */
/* .STS_TimesheetType__description */
}
.STS_TimesheetTypeCard:hover .STS_TimesheetType__icon {
  fill: var(--theme-base-default, #527a00);
  fill: var(--fabric-theme-base, #527a00);
}
.STS_TimesheetTypeCard:hover .STS_TimesheetType__name {
  color: var(--theme-base-default, #527a00);
  color: var(--fabric-theme-base, #527a00);
}
.STS_TimesheetTypeCard input[type="radio"] {
  display: none;
}
.STS_TimesheetType__icon {
  margin: 2px 12px 0 0;
  fill: var(--theme-base-default, #527a00);
  fill: var(--fabric-theme-base, #527a00);
}
input[type="radio"]:not(:checked) + .ba-CheckboxCard .STS_TimesheetType__icon {
  fill: var(--gray7, #686868);
}
.STS_TimesheetType__description {
  width: 100%;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -o-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  color: var(--gray7, #686868);
  font-size: 13px;
}
.STS_TimesheetType__name {
  display: inline-block;
  color: var(--gray8, #555);
  margin-bottom: 2px;
  font-size: 15px;
  line-height: 18px;
  font-family: unset;
}
input[type="radio"]:checked + .ba-CheckboxCard .STS_TimesheetType__name {
  color: var(--gray10, #222);
  font-weight: 600;
}
.STS_TimesheetType__text {
  font-size: 13px;
  line-height: 18px;
  color: var(--gray7, #686868);
}
.STS_fieldBox {
  display: inline-block;
}
.STS_fieldBox + .STS_fieldBox:not(.masked) {
  margin-left: 8px;
}
.STS_fieldBox + .STS_fieldBox:not(.masked).spaced {
  margin-left: 16px;
}
.STS_fieldBox.fullWidth {
  display: block;
}
.STS_TimesheetTypeClock.STS_TimesheetTypeClock {
  margin: 17px 0 0;
}
.STS_Approvals {
  padding-top: 15px;
}
.STS_Approvals__fieldStatement {
  font-size: var(--font-size-medium, 15px);
  color: var(--gray9, #444);
  position: relative;
  top: 2px;
  right: 6px;
}
.STS_Holidays__select {
  margin-bottom: 12px;
}
.STS_Holidays__rateContainer {
  position: relative;
}
.STS_Holidays__radioWrapper {
  margin-top: 4px;
}
.STS_Holidays__rateContainer {
  margin-bottom: 22px;
}
.STS_Holidays__dropdownGroup {
  margin-bottom: 0;
}
.STS_Holidays__times {
  position: relative;
  top: 1px;
  margin: 0 4px;
  color: var(--gray9, #444);
  font-size: 18px;
}
.STS_Holidays__link {
  margin-top: 14px;
}
.STS_Holidays__payTypeRadioGroup {
  margin-bottom: 16px;
}
.STS_Holidays__formNote {
  color: var(--gray9, #444);
  display: inline;
  font-family: unset;
  position: relative;
  bottom: 3px;
  font-size: 15px;
  margin: 0 4px;
}
.STS_Holidays__employeeStatuses {
  display: inline-block;
  margin: 0 8px 5px 0;
}
.STS_Holidays__employmentStatusRow {
  margin-bottom: 4px;
}
.STS_Holidays__deleteRow {
  color: var(--gray6, #999);
  cursor: pointer;
  display: inline-block;
  font-family: unset;
  font-size: 20px;
  font-weight: 600;
  margin: 0 6px 7px 0;
}
.STS_Holidays__deleteRow:hover {
  color: var(--gray8, #555);
}
.STS_Holidays__addRowIcon {
  color: var(--gray4, #ccc);
  fill: var(--gray4, #ccc);
  position: relative;
  top: 1px;
}
.STS_Holidays__addRowIcon--clickable {
  cursor: pointer;
  fill: var(--gray6, #999);
}
.STS_Holidays__addRowIcon--clickable:hover {
  fill: var(--gray8, #555);
}
.STS_NoStatusesTooltip {
  color: #fff;
  font-size: 12px;
  line-height: 14px;
  padding: 7px;
  text-align: left;
  width: 162px;
}
.STS_NoStatusesTooltip__add {
  color: #fff;
  display: block;
  font-weight: 600;
  margin-top: 8px;
}
.STS_NoStatusesTooltip__add:hover,
.STS_NoStatusesTooltip__add:active,
.STS_NoStatusesTooltip__add:visited {
  color: #fff !important;
}
.STS_Mobile {
  margin-bottom: 47px;
}
.STS_Mobile__checkboxLabel {
  display: block;
}
.STS_Mobile__geolocationCheckbox {
  margin: 24px 0 0 24px;
}
.STS_warningIcon {
  fill: var(--attention, #bd5800);
  float: left;
  margin-top: 4px;
  display: inline-block;
  margin-right: 5px;
}
.STS_formNoteStandout {
  margin-left: 5px;
  margin-top: 10px;
  top: 12px;
  vertical-align: top;
  display: block;
  position: static;
  height: auto;
}
.STS_formNoteStandout_textStyle {
  color: var(--gray10, #222);
  font-size: 14px;
  line-height: 18px;
  display: inline-block;
  float: left;
  width: 97%;
}
.STS_formNoteStandout_highlightText {
  font-weight: 600;
  color: var(--attention, #bd5800);
}
.settings-main-heading__color {
  color: var(--fabric-theme-base, #527a00);
}
.TT_SetupBox {
  border: 1px solid #adadad;
  border-radius: 2px;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  padding: 22px;
  -webkit-box-shadow: 0 1px 4px 0 rgba(0,0,0,0.15);
  box-shadow: 0 1px 4px 0 rgba(0,0,0,0.15);
  background-color: var(--gray2, #ebebeb);
  color: var(--gray9, #444);
  margin-bottom: 18px;
}
.TT_SetupBox--info {
  border-color: var(--info, #0772b3);
  background-color: rgba(7,114,179,0.1);
  -webkit-flex-shrink: 0;
  flex-shrink: 0;
  margin-right: 6px;
  position: relative;
  top: 4px;
}
.TT_SetupBox--info .TT_SetupBox__icon {
  fill: var(--info, #0772b3);
}
.TT_SetupBox--info .TT_SetupBox__header {
  color: var(--info, #0772b3);
}
.TT_SetupBox__icon {
  -webkit-flex-shrink: 0;
  flex-shrink: 0;
  margin-right: 16px;
  position: relative;
  top: -6px;
  fill: var(--gray9, #444);
}
.TT_SetupBox__header {
  margin: 0 0 1px;
  padding-top: 0 !important;
}
.TT_SetupBox .icon-wrapper {
  margin-right: 22px;
}
input[type="radio"]:checked + .ba-CheckboxCard .STS_TimesheetType__name {
  font-weight: 600;
  color: var(--theme-base-default, #527a00);
  color: var(--fabric-theme-base, #527a00);
}
@media only screen and (max-width: 1270px) {
  .STS_TimesheetTypeCard.STS_TimesheetTypeCard {
    margin-top: 12px;
  }
  .STS_fieldBox {
    margin-left: 0;
  }
  .STS_fieldBox + .STS_fieldBox:not(.masked) {
    margin-left: 0;
  }
}
body[data-feature-toggles~="multipleEntriesByHour"] .STS_TimesheetType {
  height: 75px;
  width: 271px;
}
.TimeTrackingTrialPill {
  margin-left: 4px;
  line-height: 14px;
}
.TimeTrackingTrialPill__tag {
  display: -webkit-inline-box;
  display: -moz-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-box;
  display: inline-flex;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -o-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  padding: 2px 4px;
  border-radius: 4px;
  font-weight: var(--font-weight-bold, 700);
  font-size: 11px;
  background-color: var(--theme-base-default, #527a00);
  background-color: var(--fabric-theme-base, #527a00);
  color: #fff;
}
.TimeTrackingTrialPill--subnav {
  margin-left: 8px;
}

/* styles needed for new employee card example */
.EmployeeCardContainer__card {
  border-top: 1px solid #d9d9d9;
  border-bottom: 1px solid #d9d9d9;
  -webkit-box-shadow: 0 10px 10px -7px #f5f5f5, 0 -10px 10px -7px #f5f5f5;
  box-shadow: 0 10px 10px -7px #f5f5f5, 0 -10px 10px -7px #f5f5f5;
  margin: 17px 0 25px;
}
.EmployeeCardContainer__infoContainer {
  width: 80%;
}
/* end styles needed for new employee card example */
#directoryDetails {
  margin-top: 17px;
}
#directoryDetails .fieldsetIndent-inside {
  margin-bottom: 15px;
}
#directoryDetails .directoryLabel {
  color: #909090;
  font-size: 15px;
  margin-bottom: 12px;
}
#directoryDetails .directoryLabel strong {
  font-weight: 600;
}
.directoryForm {
  padding-left: 0;
  border-color: #dfdfdf;
  padding-top: 3px;
}
.directoryForm h5 {
  font-weight: 600;
  font-size: 15px;
  margin-top: 0;
  margin-bottom: 4px;
}
.directoryForm .ui-multiselect.ui-widget {
  margin-top: 0;
}
.directoryForm fieldset {
  margin-top: 11px;
  margin-bottom: 0;
  padding-bottom: 0;
}
.checkboxColList2 {
  width: 480px;
}
#dir {
  margin-bottom: 15px;
}
#dir:before,
#dir:after {
  content: " ";
  width: 100%;
  display: block;
  height: 10px;
}
#dir:before {
  border-bottom: #dfdfdf 1px solid;
  background-image: -webkit-linear-gradient(top, #fff, #f6f6f6);
  background-image: -moz-linear-gradient(top, #fff, #f6f6f6);
  background-image: -o-linear-gradient(top, #fff, #f6f6f6);
  background-image: -ms-linear-gradient(top, #fff, #f6f6f6);
  background-image: linear-gradient(to bottom, #fff, #f6f6f6);
}
#dir:after {
  border-top: #dfdfdf 1px solid;
  margin-bottom: 20px;
  background-image: -webkit-linear-gradient(top, #f6f6f6, #fff);
  background-image: -moz-linear-gradient(top, #f6f6f6, #fff);
  background-image: -o-linear-gradient(top, #f6f6f6, #fff);
  background-image: -ms-linear-gradient(top, #f6f6f6, #fff);
  background-image: linear-gradient(to bottom, #f6f6f6, #fff);
}
#dir + hr {
  margin-bottom: 25px;
}
#dir .entry {
  margin-left: 0 !important;
}
#dir .entry > img {
  float: left;
}
#settings-page h3.directoryHeader {
  margin-bottom: 15px;
  font-size: 18px;
}
#sfWrapper {
  height: 185px;
  clear: both;
}
.aWarning {
  width: 640px;
  margin-top: 15px;
}
.selectFields {
  float: left;
  margin-left: 15px;
  margin-bottom: 15px;
  min-width: 203px;
}
.NA {
  display: none;
}
.fieldRow .fieldBox.share .fieldDiv {
  display: inline-block;
  vertical-align: middle;
}
.fieldRow .fieldBox.share > label {
  display: inline-block;
  font-size: 15px;
  color: #000;
  margin: 0 4px 0 11px;
  top: 2px;
}
.ui-multiselect-checkboxes li.divider {
  border-bottom: solid #f1f1f1 1px;
}
.ui-widget.ui-multiselect-menu .everyone label > input[type="checkbox"] + span:before {
  background: none;
  width: 0;
}
.CompanyDirectoryForm__infoFields {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-lines: multiple;
  -moz-box-lines: multiple;
  -o-box-lines: multiple;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.CompanyDirectoryForm__infoFieldsItem {
  margin-top: 16px;
  width: 31%;
}
.CompanyDirectoryForm__settingGroup {
  height: 32px;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
}
:where(body[data-fabric-theme~="encore"]) .CompanyDirectoryForm__settingGroup {
  height: auto;
}
:where(body[data-fabric-theme~="encore"]) .CompanyDirectoryForm__settingGroup__canBeSeenBy {
  margin-left: 29px;
}
:where(body[data-fabric-theme~="encore"]) .CompanyDirectoryForm__settingGroup label {
  font-weight: var(--font-weight-medium, 500);
  font-size: var(--font-size-medium, 15px);
}
.CompanyDirectoryForm__InlineMessage {
  width: 100%;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -o-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  border-radius: var(--radius-200);
  background-color: var(--infoLight, #e6f5fe);
  padding: 12px;
  font-size: var(--font-size-teenie);
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.CompanyDirectoryForm__InlineMessage__Title {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  color: var(--infoDark, #004279);
  font-size: var(--font-size-small);
}
.CompanyDirectoryForm__InlineMessage__Title strong {
  margin-left: 8px;
}
.CompanyDirectoryForm__InlineMessage__Text {
  color: var(--gray8, #555);
  margin-left: 23px;
}
.CompanyDirectoryForm__InfoCard {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  gap: 25px;
}
.CompanyDirectoryForm__InfoCard__Details {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  width: 100%;
}
.CompanyDirectoryForm__InfoCard__Details__Column {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -o-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  gap: 4px;
  width: 33%;
  min-width: 234px;
}
.CompanyDirectoryForm__InfoCard__Details__Column--gap-12 {
  gap: 12px;
}
.CompanyDirectoryForm__InfoCard__Details__Column__Text {
  color: var(--gray6, #999);
  font-size: var(--font-size-small);
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  gap: 8px;
}
.CompanyDirectoryForm__InfoCard__Details__Column__Text a {
  color: var(--gray6, #999);
  text-decoration: none;
  font-weight: var(--font-weight-regular);
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  gap: 8px;
}
.CompanyDirectoryForm__InfoCard__Details__Column__Text a:hover {
  text-decoration: underline;
  color: var(--gray8, #555);
}
.CompanyDirectoryForm__InfoCard__Details__Column__Text a:hover svg {
  fill: var(--gray8, #555);
}
.CompanyDirectoryForm__InfoCard__Details__Column__Title {
  font-size: var(--font-size-h5);
  color: var(--gray9, #444);
  font-weight: var(--font-weight-semibold);
}
.CompanyDirectoryForm__InfoCard__Details__SocialLinks {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  gap: 3px;
}

.BaTabs__tab--banner-theme {
  border-color: transparent;
  border-radius: 3px;
  border-style: solid;
  border-width: 1px;
  color: #858585;
  height: 135px;
  margin: 15px;
  max-width: 275px;
  padding: 10px 10px 0;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  text-align: center;
}
.BaTabs__tab--active.BaTabs__tab--banner-theme {
  background-color: #fff;
  -webkit-box-shadow: 0 1px 2px 0 rgba(0,0,0,0.25);
  box-shadow: 0 1px 2px 0 rgba(0,0,0,0.25);
  height: 163px;
  padding-top: 24px;
}
.BaTabs__tab--banner-theme:not(.BaTabs__tab--active):not(.BaTabs__tab--disabled):hover {
  background-color: #f3f3f3;
  border-color: #cbcbcb;
}
.BaTabs__tabLabel--primary.BaTabs__tabLabel--banner-theme {
  color: #000;
  font-family: 'BhrHeaderFont', 'Trebuchet MS';
  font-size: 16px;
  margin-top: 5px;
  margin-bottom: 5px;
  text-align: center;
}
.BaTabs__tabLabel--secondary.BaTabs__tabLabel--banner-theme {
  font-family: Source Sans Pro, Arial, Helvetica, sans-serif;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
}
.BaTabs__tabIcon--banner-theme {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -o-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  fill: #858585;
  margin: 0 auto;
}
.BaTabs__tabIcon--banner-theme > svg {
  width: 50px;
  height: 50px;
}
.BaTabs__tab:not(.BaTabs__tab--active):hover .BaTabs__tabIcon--banner-theme {
  fill: #555;
}
.BaTabs__tabCaret--banner-theme {
  position: absolute;
  top: calc(100% - 2px);
  left: 50%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}
.BaTabs__tabCaret:not(.BaTabs__tabCaret--active).BaTabs__tabCaret--banner-theme {
  display: none;
}
.BaTabs__tabWrapper--banner-theme {
  background-color: #efefef;
  border-bottom: 1px solid #adadad;
  border-top: 1px solid #adadad;
  height: 145px;
  margin-bottom: 20px;
  margin-top: 10px;
  padding: 0 15px;
}

.BaTabs__tab--basic-theme {
  background: transparent;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  border-radius: 5px 5px 0 0;
  border: 1px solid transparent;
  bottom: -1px;
  color: #555;
  font-family: Source Sans Pro, Arial, Helvetica, sans-serif;
  font-size: 16px;
  height: 50px;
  margin-right: 5px;
  padding: 0 18px;
}
.BaTabs__tab--active.BaTabs__tab--basic-theme {
  background-color: #fafafa;
  border-bottom-color: #fafafa;
  color: #222;
  font-weight: 600;
}
.BaTabs__tab--disabled.BaTabs__tab--basic-theme {
  opacity: 0.6;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=60)";
  filter: alpha(opacity=60);
}
.BaTabs__tab--disabled.BaTabs__tab--basic-theme:hover {
  border-color: #cbcbcb;
}
.BaTabs__tab--basic-theme:not(.BaTabs__tab--active):not(.BaTabs__tab--disabled):hover {
  border-color: #cbcbcb;
  background-color: #f3f3f3;
}
.BaTabs__tab--dark {
  border-bottom: none;
  color: #fff;
}
.BaTabs__tab--dark.BaTabs__tab--active {
  background-color: #fff;
}
.BaTabs__tab--dark:not(.BaTabs__tab--active):not(.BaTabs__tab--disabled) {
  -webkit-transition: border-color 100ms linear;
  -moz-transition: border-color 100ms linear;
  -o-transition: border-color 100ms linear;
  -ms-transition: border-color 100ms linear;
  transition: border-color 100ms linear;
}
.BaTabs__tab--dark:not(.BaTabs__tab--active):not(.BaTabs__tab--disabled):hover {
  background-color: transparent;
  border-color: rgba(255,255,255,0.8);
}
.BaTabs__tab--dark:not(.BaTabs__tab--active):not(.BaTabs__tab--disabled) .BaTabs__tabIcon {
  fill: #fff;
}
.BaTabs__tabLabel--primary.BaTabs__tabLabel--basic-theme {
  padding-left: 5px;
  line-height: 19px;
}
.BaTabs__tabLabel--secondary.BaTabs__tabLabel--basic-theme {
  display: none;
}
.BaTabs__tabIcon--basic-theme {
  fill: #747474;
}
.BaTabs__tabIcon--basic-theme > svg {
  width: 20px;
  height: 20px;
}
.BaTabs__tabWrapper--basic-theme {
  -webkit-box-align: end;
  -moz-box-align: end;
  -o-box-align: end;
  -ms-flex-align: end;
  -webkit-align-items: flex-end;
  align-items: flex-end;
  background-color: #e7e7e7;
  border-bottom: 1px solid #cbcbcb;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-orient: horizontal;
  -moz-box-orient: horizontal;
  -o-box-orient: horizontal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  margin: 0 auto;
  max-width: 1140px;
  width: 100%;
  height: 60px;
  bottom: -1px;
  padding: 8px 20px 0;
}
.BaTabs__tabWrapper--transparent {
  background-color: transparent;
  border-bottom: none;
}
.BaTabs__tabContentWrapper--basic-theme {
  max-width: 1140px;
  padding: 0 20px;
}

.BaTabs__tab--horizontal-theme {
  white-space: nowrap;
}
.BaTabs__tab--divider.BaTabs__tab--horizontal-theme {
  border-top: 1px solid #e7e7e7;
  padding: 2px 0 0;
  margin-top: 2px;
}
.BaTabs__tabOverflowWrapper--horizontal-theme {
  top: 100%;
  right: 0;
  padding: 2px 0;
  border: 1px solid #afafaf;
  border-radius: 3px;
  width: 160px;
  background: #fff;
  z-index: 99;
  overflow-y: auto;
  max-height: 300px;
}
.BaTabs__tabLabel--horizontal-theme {
  padding: 16px 14px 3px;
  font-size: 15px;
  font-weight: 600;
  line-height: 19px;
}
.BaTabs__tabLabel--disabled.BaTabs__tabLabel--horizontal-theme {
  color: #aaa;
}
.BaTabs__tabLabel--horizontal-theme:not(.BaTabs__tabLabel--active):not(.BaTabs__tabLabel--disabled) {
  color: #222;
}
.BaTabs__tabLabel--horizontal-theme:not(.BaTabs__tabLabel--active):not(.BaTabs__tabLabel--disabled):hover {
  background: #e7e7e7;
}
.BaTabs__tabLabel--active.BaTabs__tabLabel--horizontal-theme:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 5px;
  background: #464646;
}
.BaTabs__tab--overflow .BaTabs__tab > .BaTabs__tabLabel--active.BaTabs__tabLabel--horizontal-theme:before {
  width: 4px;
  height: 27px;
}
.BaTabs__tab--overflow > .BaTabs__tabLabel--primary.BaTabs__tabLabel--horizontal-theme:after {
  content: "";
  display: inline-block;
  position: relative;
  top: 4px;
  margin-left: 3px;
  border: 5px solid transparent;
  border-top-color: #222;
}
.BaTabs__tab--overflow .BaTabs__tab > .BaTabs__tabLabel--primary.BaTabs__tabLabel--horizontal-theme {
  max-width: 200px;
  padding: 4px 11px;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}
.BaTabs__tabLabel--secondary.BaTabs__tabLabel--horizontal-theme {
  display: none;
}
.BaTabs__tabIcon--horizontal-theme {
  display: none;
}

.BaTabs__tab--mini-theme {
  background: #f3f3f3;
  color: #939393;
  border: 1px solid #e7e7e7;
  padding: 8px 11px 6px;
  border-radius: 3px 3px 0 0;
  margin: 0 3px -1px;
  font-size: 14px;
}
.BaTabs__tab--active.BaTabs__tab--mini-theme {
  background: #fff;
  border-bottom-color: #fff;
  color: #3a3a3a;
  font-weight: 600;
}
.BaTabs__tab--mini-theme:not(.BaTabs__tab--active):not(.BaTabs__tab--disabled) {
  cursor: pointer;
}
.BaTabs__tab--mini-theme:not(.BaTabs__tab--active):not(.BaTabs__tab--disabled):hover {
  text-decoration: underline;
}
.BaTabs__tabWrapper--mini-theme {
  margin: 7px 0 10px;
  border-bottom: 1px solid #e7e7e7;
}

.BaTabs__tab--minimal-theme {
  white-space: nowrap;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  padding: 3px 20px 16px;
  margin: 0 1px;
}
.BaTabs__tab--divider.BaTabs__tab--minimal-theme {
  border-top: 1px solid #e7e7e7;
  padding: 2px 0 0;
  margin-top: 2px;
}
.BaTabs__tabOverflowWrapper--minimal-theme {
  top: 100%;
  right: 0;
  padding: 2px 0;
  border: 1px solid #afafaf;
  border-radius: 3px;
  width: 160px;
  background: #fff;
  z-index: 99;
  overflow-y: auto;
  max-height: 300px;
}
.BaTabs__tabLabel--minimal-theme {
  font-size: 15px;
  font-weight: 600;
  line-height: 19px;
}
.BaTabs__tabLabel--disabled.BaTabs__tabLabel--minimal-theme {
  color: #aaa;
}
.BaTabs__tab--overflow > .BaTabs__tabLabel--primary.BaTabs__tabLabel--minimal-theme:after {
  content: "";
  display: inline-block;
  position: relative;
  top: 4px;
  margin-left: 3px;
  border: 5px solid transparent;
  border-top-color: #222;
}
.BaTabs__tab--overflow .BaTabs__tab > .BaTabs__tabLabel--primary.BaTabs__tabLabel--minimal-theme {
  max-width: 200px;
  padding: 4px 11px;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}
.BaTabs__tabLabel--secondary.BaTabs__tabLabel--minimal-theme {
  font-weight: 100;
  padding-left: 5px;
  color: #939393 !important;
}
.BaTabs__tab--active.BaTabs__tab--minimal-theme:after {
  content: "";
  display: block;
  position: absolute;
  bottom: -1px;
  left: 0;
  width: 100%;
  height: 2px;
}
.BaTabs__tab--overflow .BaTabs__tab > .BaTabs__tab--active.BaTabs__tab--minimal-theme:after {
  width: 4px;
  height: 27px;
}
.BaTabs__tab--minimal-theme:not(.BaTabs__tab--active):not(.BaTabs__tab--disabled) .BaTabs__tabIcon--minimal-theme {
  fill: #006ec2;
}
.BaTabs__tab--minimal-theme:not(.BaTabs__tab--active):not(.BaTabs__tab--disabled) .BaTabs__tabLabel {
  font-weight: normal;
}
.BaTabs__tab--minimal-theme:not(.BaTabs__tab--active):not(.BaTabs__tab--disabled) .BaTabs__tabLabel--minimal-theme {
  color: #006ec2;
}
.BaTabs__tab--minimal-theme:not(.BaTabs__tab--active):not(.BaTabs__tab--disabled):after {
  content: "";
  -webkit-transition: 300ms;
  -moz-transition: 300ms;
  -o-transition: 300ms;
  -ms-transition: 300ms;
  transition: 300ms;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
}
.BaTabs__tab--minimal-theme:not(.BaTabs__tab--active):not(.BaTabs__tab--disabled):hover .BaTabs__tabIcon--minimal-theme {
  fill: inherit;
}
.BaTabs__tab--minimal-theme:not(.BaTabs__tab--active):not(.BaTabs__tab--disabled):hover .BaTabs__tabLabel--minimal-theme {
  color: inherit;
}
.BaTabs__tabIcon--minimal-theme > svg {
  width: 20px;
  height: 20px;
  padding-right: 8px;
}
.BaTabs__tabWrapper--minimal-theme {
  margin: 7px 0 10px;
  border-bottom: 1px solid #e7e7e7;
}

.BaTabs--sidebar-theme {
  border-top: 1px solid #d3d3d3;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-orient: horizontal;
  -moz-box-orient: horizontal;
  -o-box-orient: horizontal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: stretch;
  -moz-box-align: stretch;
  -o-box-align: stretch;
  -ms-flex-align: stretch;
  -webkit-align-items: stretch;
  align-items: stretch;
}
.BaTabs__tab--sidebar-theme {
  width: 100%;
}
.BaTabs__tabLabel--primary.BaTabs__tabLabel--sidebar-theme {
  display: block;
  position: relative;
  padding: 11px;
  font-size: 15px;
  line-height: 13px;
  margin-top: 7px;
}
.BaTabs__tabLabel--secondary.BaTabs__tabLabel--sidebar-theme {
  display: none;
}
.BaTabs__tabLabel:not(.BaTabs__tabLabel--active):not(.BaTabs__tabLabel--disabled).BaTabs__tabLabel--sidebar-theme:hover {
  text-decoration: underline;
}
.BaTabs__tabLabel--active.BaTabs__tabLabel--sidebar-theme {
  color: #222;
  font-weight: 600;
}
.BaTabs__tabCaret--sidebar-theme {
  position: absolute;
  top: 50%;
  right: -9px;
  width: 17px;
  height: 17px;
  background: #fff;
  border: 1px solid #d3d3d3;
  border-left-color: transparent;
  border-bottom-color: transparent;
  -webkit-transform: translateY(-50%) scaleY(0.9) rotateZ(45deg);
  -moz-transform: translateY(-50%) scaleY(0.9) rotateZ(45deg);
  -o-transform: translateY(-50%) scaleY(0.9) rotateZ(45deg);
  -ms-transform: translateY(-50%) scaleY(0.9) rotateZ(45deg);
  transform: translateY(-50%) scaleY(0.9) rotateZ(45deg);
}
.BaTabs__tabCaret:not(.BaTabs__tabCaret--active).BaTabs__tabCaret--sidebar-theme {
  display: none;
}
.BaTabs__tabHeading {
  position: relative;
}
.BaTabs__tabIcon--sidebar-theme {
  position: absolute;
  top: calc(50% - 12px);
  right: calc(100% + 8px);
}
.BaTabs__tabIcon--sidebar-theme > svg {
  width: 20px;
  height: 20px;
}
.BaTabs__tabWrapper--sidebar-theme {
  width: 130px;
  border-right: 1px solid #d3d3d3;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -o-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}
.BaTabs__tabContent--sidebar-theme h3 {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 10px;
}
.BaTabs__tabContent--sidebar-theme .fieldBox.check-radio {
  margin-bottom: 10px;
}
.BaTabs__tabContent--sidebar-theme .fieldBox.check-radio:last-child {
  margin-bottom: 0;
}
.BaTabs__tabContent--sidebar-theme .fieldBox.check-radio label.fileFolder:before {
  margin-right: 33px;
}
.BaTabs__tabContent--sidebar-theme .fieldBox.check-radio label.fileFolder:after {
  content: "";
  display: block;
  position: absolute;
  background-image: url("https://staticfe.bamboohr.com/resources/images/color-branded-icons/sprite-files-icons.png");
  background-position: -3px -61px;
  width: 16px;
  height: 13px;
  top: 3px;
  left: 23px;
  opacity: 0.55;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=55)";
  filter: alpha(opacity=55);
}
.BaTabs__tabContent--sidebar-theme .fieldBox.check-radio input[type="checkbox"]:checked + label.fileFolder:after {
  opacity: 1;
  -ms-filter: none;
  filter: none;
}
.BaTabs__tabContent--sidebar-theme .fieldBox.check-radio input[type="checkbox"] + label:last-of-type + .checkRadioFormNote {
  margin-top: 6px;
  margin-bottom: 5px;
}
.BaTabs__tabContent--sidebar-theme .checkRadioFormNote {
  margin-top: 0;
  margin-bottom: 11px;
  font-size: 14px;
  color: #777;
  line-height: 18px;
}
.BaTabs__tabContent--sidebar-theme .checkRadioFormNote strong {
  font-weight: 600;
}
.BaTabs__tabContentWrapper--sidebar-theme {
  padding: 20px 0 0 50px;
}

.BaTabs.BaTabs--settings-subnav-theme {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-orient: horizontal;
  -moz-box-orient: horizontal;
  -o-box-orient: horizontal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}
.BaTabs__tab.BaTabs__tab--settings-subnav-theme {
  padding: 5px 0;
  line-height: 1.1em;
}
.BaTabs__tab:not(.BaTabs__tab--active):not(.BaTabs__tab--disabled).BaTabs__tab--settings-subnav-theme {
  cursor: default;
}
.BaTabs__tabLabel.BaTabs__tabLabel--settings-subnav-theme {
  display: inline-block;
}
.BaTabs__tabLabel.BaTabs__tabLabel--primary.BaTabs__tabLabel--settings-subnav-theme {
  font-size: 15px;
  line-height: 1.1em;
  color: #222;
  cursor: pointer;
}
.BaTabs__tabLabel.BaTabs__tabLabel--primary.BaTabs__tabLabel--active.BaTabs__tabLabel--settings-subnav-theme {
  font-weight: 600;
}
.BaTabs__tabLabel.BaTabs__tabLabel--primary:not(.BaTabs__tabLabel--active):not(.BaTabs__tabLabel--disabled).BaTabs__tabLabel--settings-subnav-theme {
  cursor: pointer;
}
.BaTabs__tabLabel.BaTabs__tabLabel--primary:not(.BaTabs__tabLabel--active):not(.BaTabs__tabLabel--disabled).BaTabs__tabLabel--settings-subnav-theme:hover {
  color: #006ec2;
}
.BaTabs__tabLabel.BaTabs__tabLabel--secondary.BaTabs__tabLabel--settings-subnav-theme {
  font-size: 13px;
  color: #888;
  padding-left: 3px;
}
.BaTabs__tabIcon.BaTabs__tabIcon--settings-subnav-theme {
  padding-right: 6px;
  -webkit-transform: translateY(3px);
  -moz-transform: translateY(3px);
  -o-transform: translateY(3px);
  -ms-transform: translateY(3px);
  transform: translateY(3px);
}
.BaTabs__tabIcon.BaTabs__tabIcon--settings-subnav-theme > svg {
  width: 16px;
  height: 16px;
}
.BaTabs__tabWrapper.BaTabs__tabWrapper--settings-subnav-theme {
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -o-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: stretch;
  -moz-box-align: stretch;
  -o-box-align: stretch;
  -ms-flex-align: stretch;
  -webkit-align-items: stretch;
  align-items: stretch;
  width: 19%;
  padding: 22px 1.59% 45px;
  border-width: 0;
  border-right-width: 1px;
  border-right-style: solid;
}
.BaTabs__tabWrapperHeader.BaTabs__tabWrapperHeader--settings-subnav-theme {
  padding: 10px 0 13px;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-orient: horizontal;
  -moz-box-orient: horizontal;
  -o-box-orient: horizontal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -moz-box-pack: justify;
  -o-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}
.BaTabs__tabWrapperHeaderActions.BaTabs__tabWrapperHeaderActions--settings-subnav-theme {
  -webkit-box-ordinal-group: 10;
  -moz-box-ordinal-group: 10;
  -o-box-ordinal-group: 10;
  -ms-flex-order: 10;
  -webkit-order: 10;
  order: 10;
}
.BaTabs__tabWrapperIcon.BaTabs__tabWrapperIcon--settings-subnav-theme {
  padding-top: 1px;
  padding-right: 6px;
}
.BaTabs__tabWrapperIcon.BaTabs__tabWrapperIcon--settings-subnav-theme > svg {
  width: 16px;
  height: 16px;
}
.BaTabs__tabWrapperTitleWrapper.BaTabs__tabWrapperTitleWrapper--settings-subnav-theme {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-orient: horizontal;
  -moz-box-orient: horizontal;
  -o-box-orient: horizontal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  font-size: 19px;
}
.BaTabs__tabGroup.BaTabs__tabGroup--settings-subnav-theme {
  border-bottom: 1px solid #afafaf;
  padding: 1px 0 5px 10px;
  margin-bottom: 5px;
}
.BaTabs__tabGroup.BaTabs__tabGroup--empty.BaTabs__tabGroup--settings-subnav-theme {
  display: none;
}
.BaTabs__tabGroupIcon.BaTabs__tabGroupIcon--settings-subnav-theme {
  padding-right: 6px;
  -webkit-transform: translateY(3px);
  -moz-transform: translateY(3px);
  -o-transform: translateY(3px);
  -ms-transform: translateY(3px);
  transform: translateY(3px);
  margin-left: -10px;
}
.BaTabs__tabGroupIcon.BaTabs__tabGroupIcon--settings-subnav-theme > svg {
  width: 16px;
  height: 16px;
}
.BaTabs__tabGroupLabel.BaTabs__tabGroupLabel--settings-subnav-theme {
  display: inline-block;
  color: #888;
  font-size: 13px;
  line-height: 1.1em;
  padding: 5px 0;
}
.BaTabs__tabContent.BaTabs__tabContent--settings-subnav-theme {
  padding: 22px 0 45px 4.4%;
}
.BaTabs__tabContentHeader.BaTabs__tabContentHeader--settings-subnav-theme {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-orient: horizontal;
  -moz-box-orient: horizontal;
  -o-box-orient: horizontal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -moz-box-pack: justify;
  -o-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}
.BaTabs__tabContentHeaderActions.BaTabs__tabContentHeaderActions--settings-subnav-theme {
  -webkit-box-ordinal-group: 10;
  -moz-box-ordinal-group: 10;
  -o-box-ordinal-group: 10;
  -ms-flex-order: 10;
  -webkit-order: 10;
  order: 10;
}
.BaTabs__tabContentTitle.BaTabs__tabContentTitle--settings-subnav-theme {
  font-size: 19px;
  line-height: 19px;
  padding-top: 10px;
  margin-bottom: 22px;
}
.BaTabs__tabContentSubtitle.BaTabs__tabContentSubtitle--settings-subnav-theme {
  padding-left: 0.4em;
}

.BaTabs__tab.BaTabs__tab--fab-small-theme {
  padding: 16px 10px;
  margin-bottom: -1px;
  border-bottom-width: 2px;
  border-bottom-style: solid;
  border-color: transparent;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-flex-shrink: 0;
  flex-shrink: 0;
}
:where(body[data-fabric-theme~="encore"]) .BaTabs__tab.BaTabs__tab--fab-small-theme {
  border-bottom-width: 3px;
}
.BaTabs__tab.BaTabs__tab--fab-small-theme + .BaTabs__tab.BaTabs__tab--fab-small-theme {
  margin-left: 12px;
}
.BaTabs__tab.BaTabs__tab--fab-small-theme:not(.BaTabs__tab--disabled):not(.BaTabs__tab--active):not(.BaTabs__tab--loading):hover {
  color: var(--theme-base-default, #527a00);
  color: var(--fabric-theme-base, #527a00);
  fill: var(--theme-base-default, #527a00);
  fill: var(--fabric-theme-base, #527a00);
}
:where(body[data-fabric-theme~="encore"]) .BaTabs__tab.BaTabs__tab--fab-small-theme:not(.BaTabs__tab--disabled):not(.BaTabs__tab--active):not(.BaTabs__tab--loading):hover {
  border-bottom-color: #777270;
  border-bottom-width: 3px;
}
.BaTabs__tab.BaTabs__tab--active.BaTabs__tab--fab-small-theme {
  color: var(--theme-base-default, #527a00);
  color: var(--fabric-theme-base, #527a00);
  fill: var(--theme-base-default, #527a00);
  fill: var(--fabric-theme-base, #527a00);
  border-color: var(--theme-base-default, #527a00);
  border-color: var(--fabric-theme-base, #527a00);
}
.BaTabs__tab.BaTabs__tab--fab-small-theme_light.BaTabs__tab--fab-small-theme {
  padding-top: 24px;
}
.BaTabs__tab.BaTabs__tab--fab-small-theme_icons.BaTabs__tab--fab-small-theme {
  padding: 13px 10px;
}
.BaTabs__tab.BaTabs__tab--overflow.BaTabs__tab--fab-small-theme {
  cursor: default !important;
  padding-top: 0;
  padding-bottom: 0;
}
.BaTabs__overflowTab.BaTabs__overflowTab--fab-small-theme > .BaTabs__tab.BaTabs__tab--fab-small-theme {
  padding: 0;
  border: 0;
  margin: 0;
}
.BaTabs__tabLabel.BaTabs__tabLabel--primary.BaTabs__tabLabel--fab-small-theme {
  white-space: nowrap;
  line-height: 1em;
}
.BaTabs__tabIcon.BaTabs__tabIcon--fab-small-theme > svg {
  width: 14px;
  height: 14px;
}
.BaTabs__tabIcon.BaTabs__tabIcon--fab-small-theme + .BaTabs__tabLabel.BaTabs__tabLabel--fab-small-theme {
  margin-left: 8px;
}
.fab-MenuOption.fab-MenuOption--active :where(body[data-fabric-theme~="jade"]) .BaTabs__tabLabel.BaTabs__tabLabel--fab-small-theme {
  color: var(--white, #fff);
}
.BaTabs__tabIcon.BaTabs__tabIcon--fab-small-theme_icons.BaTabs__tabIcon--fab-small-theme > svg {
  width: 22px;
  height: 22px;
}
.BaTabs__tabWrapper.BaTabs__tabWrapper--fab-small-theme {
  border-bottom: 1px solid #ccc;
  padding: 0 22px;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}
.BaTabs__tabWrapper.BaTabs__tabWrapper--fab-small-theme_light.BaTabs__tabWrapper--fab-small-theme {
  background-color: var(--gray1, #f4f4f4);
}
:where(body[data-fabric-theme~="encore"]) .BaTabs__tabWrapper.BaTabs__tabWrapper--fab-small-theme_light.BaTabs__tabWrapper--fab-small-theme {
  background-color: var(--white, #fff);
}
.BaTabs__tabWrapper.BaTabs__tabWrapper--fab-small-theme_tight.BaTabs__tabWrapper--fab-small-theme {
  padding: 0;
}
.BaTabs__tabWrapperFooter.BaTabs__tabWrapperFooter--fab-small-theme {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  -webkit-box-pack: end;
  -moz-box-pack: end;
  -o-box-pack: end;
  -ms-flex-pack: end;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
}

:where(body[data-fabric-theme~="encore"]) .BaTabs__tabContent.BaTabs__tabContent--fab-medium-theme {
  background-color: var(--gray1, #f4f4f4);
  border-radius: 8px;
}
.BaTabs__tab.BaTabs__tab--fab-medium-theme {
  font-size: var(--font-size-large, 16px);
  line-height: 1em;
  padding: 17px 24px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  height: 50px;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  width: auto !important;
  -webkit-flex-shrink: 0;
  flex-shrink: 0;
}
:where(body[data-fabric-theme~="encore"]) .BaTabs__tab.BaTabs__tab--fab-medium-theme {
  font-size: var(--font-size-medium, 15px);
  line-height: var(--line-height-medium, 22px);
  padding: 20px 24px;
  margin: 0 2px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
:where(body[data-fabric-theme~="encore"]) .BaTabs__tab.BaTabs__tab--fab-medium-theme:focus-visible:after {
  content: none;
}
:where(body[data-fabric-theme~="encore"]) .BaTabs__tab.BaTabs__tab--fab-medium-theme:first-child {
  margin-left: 0;
}
:where(body[data-fabric-theme~="encore"]) .BaTabs__tab.BaTabs__tab--fab-medium-theme:last-child {
  margin-right: 0;
}
.BaTabs__tab.BaTabs__tab--fab-medium-theme:not(.BaTabs__tab--active) {
  color: var(--gray8, #555);
  fill: rgba(85,85,85,0.8);
}
.BaTabs__tab.BaTabs__tab--fab-medium-theme:not(.BaTabs__tab--disabled):not(.BaTabs__tab--active):not(.BaTabs__tab--loading):hover {
  color: var(--theme-base-default, #527a00);
  color: var(--fabric-theme-base, #527a00);
  fill: var(--theme-base-default, #527a00);
  fill: var(--fabric-theme-base, #527a00);
  background-color: rgba(255,255,255,0.5);
}
:where(body[data-fabric-theme~="encore"]) .BaTabs__tab.BaTabs__tab--fab-medium-theme:not(.BaTabs__tab--disabled):not(.BaTabs__tab--active):not(.BaTabs__tab--loading):hover {
  background-color: rgba(255,255,255,0.1);
}
:where(body[data-fabric-theme~="encore"]) .BaTabs__tab.BaTabs__tab--fab-medium-theme:not(.BaTabs__tab--disabled):not(.BaTabs__tab--active):not(.BaTabs__tab--loading):focus-visible:after {
  content: none;
}
:where(body[data-fabric-theme~="encore"]) .BaTabs__tab.BaTabs__tab--fab-medium-theme:not(.BaTabs__tab--disabled):not(.BaTabs__tab--active):not(.BaTabs__tab--loading):focus-visible {
  background-color: rgba(255,255,255,0.2);
}
:where(body[data-fabric-theme~="encore"]) .BaTabs__tab.BaTabs__tab--fab-medium-theme:not(.BaTabs__tab--fab-medium-theme_dark):not(.BaTabs__tab--disabled):not(.BaTabs__tab--active):not(.BaTabs__tab--loading):hover {
  background-color: rgba(246,246,244,0.9);
  color: var(--theme-base-default, #527a00);
  color: var(--fabric-theme-base, #527a00);
}
:where(body[data-fabric-theme~="encore"]) .BaTabs__tab.BaTabs__tab--fab-medium-theme:not(.BaTabs__tab--fab-medium-theme_dark):not(.BaTabs__tab--disabled):not(.BaTabs__tab--active):not(.BaTabs__tab--loading):focus-visible:after {
  content: none;
}
:where(body[data-fabric-theme~="encore"]) .BaTabs__tab.BaTabs__tab--fab-medium-theme:not(.BaTabs__tab--fab-medium-theme_dark):not(.BaTabs__tab--disabled):not(.BaTabs__tab--active):not(.BaTabs__tab--loading):focus-visible {
  background-color: rgba(255,255,255,0.2);
  color: var(--white, #fff);
}
.BaTabs__tab.BaTabs__tab--active.BaTabs__tab--fab-medium-theme {
  font-weight: var(--font-weight-heavy, 800);
  color: var(--theme-base-default, #527a00);
  color: var(--fabric-theme-base, #527a00);
  fill: var(--theme-base-default, #527a00);
  fill: var(--fabric-theme-base, #527a00);
  background-color: var(--white, #fff);
  -webkit-box-shadow: 0 -4px 8px 0 rgba(0,0,0,0.05);
  box-shadow: 0 -4px 8px 0 rgba(0,0,0,0.05);
}
:where(body[data-fabric-theme~="encore"]) .BaTabs__tab.BaTabs__tab--active.BaTabs__tab--fab-medium-theme {
  background-color: var(--gray05);
  -webkit-box-shadow: none;
  box-shadow: none;
}
.BaTabs__tab.BaTabs__tab--overflow.BaTabs__tab--fab-medium-theme_header.BaTabs__tab--fab-medium-theme .fab-link:hover {
  text-decoration: none;
}
.BaTabs__tab.BaTabs__tab--overflow.BaTabs__tab--fab-medium-theme_header.BaTabs__tab--fab-medium-theme .BaTabs__tabLabel {
  color: var(--white, #fff);
}
.BaTabs__tab.BaTabs__tab--overflow.BaTabs__tab--fab-medium-theme_header.BaTabs__tab--fab-medium-theme .BaTabs__textButton {
  fill: #fff;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.BaTabs__tab.BaTabs__tab--overflow.BaTabs__tab--fab-medium-theme_header.BaTabs__tab--fab-medium-theme .BaTabs__textButton:hover {
  fill: #fff;
  text-decoration: none;
}
.BaTabs__tab.BaTabs__tab--overflow.BaTabs__tab--fab-medium-theme_header.BaTabs__tab--fab-medium-theme .BaTabs__textButton:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}
body[data-fabric-theme~="encore"] .BaTabs__tab.BaTabs__tab--overflow.BaTabs__tab--fab-medium-theme_header.BaTabs__tab--fab-medium-theme .MuiButton-root {
  color: #fff;
  fill: #fff;
  outline: none;
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}
body[data-fabric-theme~="encore"] .BaTabs__tab.BaTabs__tab--overflow.BaTabs__tab--fab-medium-theme_header.BaTabs__tab--fab-medium-theme .MuiButton-root:after {
  content: none !important;
}
body[data-fabric-theme~="encore"] .BaTabs__tab.BaTabs__tab--overflow.BaTabs__tab--fab-medium-theme_header.BaTabs__tab--fab-medium-theme:has(.MuiButton-root[aria-expanded="true"]) {
  background-color: rgba(255,255,255,0.2);
}
body[data-fabric-theme~="encore"] .BaTabs__tab.BaTabs__tab--overflow.BaTabs__tab--fab-medium-theme_header.BaTabs__tab--fab-medium-theme:has(.MuiButton-root[aria-expanded="true"]) .MuiButton-root {
  fill: #fff;
}
body[data-fabric-theme~="encore"] .BaTabs__tab.BaTabs__tab--overflow.BaTabs__tab--fab-medium-theme_header.BaTabs__tab--fab-medium-theme:has(.MuiButton-root[aria-expanded="true"]) .BaTabs__tabLabel {
  color: #fff;
}
body[data-fabric-theme~="encore"] .BaTabs__tab.BaTabs__tab--overflow.BaTabs__tab--fab-medium-theme_header.BaTabs__tab--fab-medium-theme:hover .MuiButton-root {
  color: #fff;
  fill: #fff;
  text-decoration: none;
}
body[data-fabric-theme~="encore"] .BaTabs__tab.BaTabs__tab--overflow.BaTabs__tab--fab-medium-theme_header.BaTabs__tab--fab-medium-theme:focus-within {
  background-color: rgba(255,255,255,0.2);
}
body[data-fabric-theme~="encore"] .BaTabs__tab.BaTabs__tab--overflow.BaTabs__tab--fab-medium-theme_header.BaTabs__tab--fab-medium-theme:focus-within .MuiButton-root {
  fill: #fff;
}
body[data-fabric-theme~="encore"] .BaTabs__tab.BaTabs__tab--overflow.BaTabs__tab--fab-medium-theme_header.BaTabs__tab--fab-medium-theme:focus-within .BaTabs__tabLabel {
  color: #fff;
}
.BaTabs__tab.BaTabs__tab--fab-medium-theme_dark.BaTabs__tab--fab-medium-theme:not(.BaTabs__tab--active) {
  color: var(--white, #fff);
  fill: rgba(255,255,255,0.8);
}
.BaTabs__tab.BaTabs__tab--fab-medium-theme_dark.BaTabs__tab--fab-medium-theme:not(.BaTabs__tab--disabled):not(.BaTabs__tab--active):not(.BaTabs__tab--loading):hover {
  color: var(--white, #fff);
  fill: var(--white, #fff);
  background-color: rgba(255,255,255,0.2);
}
.BaTabs__overflowTab.BaTabs__overflowTab--fab-medium-theme {
  display: block;
  margin: -5px -10px;
  padding: 5px 10px;
}
.BaTabs__overflowTab.BaTabs__overflowTab--fab-medium-theme,
.BaTabs__overflowTab.BaTabs__overflowTab--fab-medium-theme:hover {
  text-decoration: none;
}
:where(body[data-fabric-theme~="encore"]) .BaTabs__overflowTab.BaTabs__overflowTab--fab-medium-theme {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  color: #0b4fd1;
  fill: #0b4fd1;
  font-weight: 400;
}
:where(body[data-fabric-theme~="encore"]) .BaTabs__overflowTab.BaTabs__overflowTab--fab-medium-theme.fab-Link--inherit {
  color: #444;
}
.BaTabs__overflowTab.BaTabs__overflowTab--active.BaTabs__overflowTab--fab-medium-theme {
  font-weight: var(--font-weight-heavy, 800);
}
.BaTabs__tabIcon.BaTabs__tabIcon--fab-medium-theme {
  margin-right: 8px;
}
.BaTabs__tabIcon.BaTabs__tabIcon--fab-medium-theme > svg {
  width: 18px;
  height: 18px;
}
.BaTabs__tabWrapper.BaTabs__tabWrapper--fab-medium-theme {
  padding: 32px 24px 0;
}
.BaTabs__tabWrapper.BaTabs__tabWrapper--fab-medium-theme:not(.BaTabs__tabWrapper--fab-medium-theme_header) {
  background-color: var(--gray1, #f4f4f4);
}
:where(body[data-fabric-theme~="encore"]) .BaTabs__tabWrapper.BaTabs__tabWrapper--fab-medium-theme:not(.BaTabs__tabWrapper--fab-medium-theme_header) {
  background-color: var(--white, #fff);
}
.BaTabs__tabWrapper.BaTabs__tabWrapper--fab-medium-theme:before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 3px;
  background-image: -webkit-linear-gradient(bottom, rgba(0,0,0,0.05) 0, rgba(0,0,0,0) 3px, rgba(0,0,0,0) 100%);
  background-image: -moz-linear-gradient(bottom, rgba(0,0,0,0.05) 0, rgba(0,0,0,0) 3px, rgba(0,0,0,0) 100%);
  background-image: -o-linear-gradient(bottom, rgba(0,0,0,0.05) 0, rgba(0,0,0,0) 3px, rgba(0,0,0,0) 100%);
  background-image: -ms-linear-gradient(bottom, rgba(0,0,0,0.05) 0, rgba(0,0,0,0) 3px, rgba(0,0,0,0) 100%);
  background-image: linear-gradient(to top, rgba(0,0,0,0.05) 0, rgba(0,0,0,0) 3px, rgba(0,0,0,0) 100%);
}
:where(body[data-fabric-theme~="encore"]) .BaTabs__tabWrapper.BaTabs__tabWrapper--fab-medium-theme:before {
  content: none;
}
.BaTabs__tabWrapper.BaTabs__tabWrapper--fab-medium-theme_dark.BaTabs__tabWrapper--fab-medium-theme:not(.BaTabs__tabWrapper--fab-medium-theme_header) {
  background-image: -webkit-linear-gradient(0deg, #82af13 0, #527a00 100%);
  background-image: -moz-linear-gradient(0deg, #82af13 0, #527a00 100%);
  background-image: -o-linear-gradient(0deg, #82af13 0, #527a00 100%);
  background-image: -ms-linear-gradient(0deg, #82af13 0, #527a00 100%);
  background-image: linear-gradient(90deg, #82af13 0, #527a00 100%);
  background-image: -webkit-linear-gradient(0deg, var(--fabric-theme-lighter, #82af13) 0, var(--fabric-theme-base, #527a00) 100%);
  background-image: -moz-linear-gradient(0deg, var(--fabric-theme-lighter, #82af13) 0, var(--fabric-theme-base, #527a00) 100%);
  background-image: -o-linear-gradient(0deg, var(--fabric-theme-lighter, #82af13) 0, var(--fabric-theme-base, #527a00) 100%);
  background-image: -ms-linear-gradient(0deg, var(--fabric-theme-lighter, #82af13) 0, var(--fabric-theme-base, #527a00) 100%);
  background-image: linear-gradient(90deg, var(--fabric-theme-lighter, #82af13) 0, var(--fabric-theme-base, #527a00) 100%);
}
:where(body[data-fabric-theme~="encore"]) .BaTabs__tabWrapper.BaTabs__tabWrapper--fab-medium-theme_dark.BaTabs__tabWrapper--fab-medium-theme:not(.BaTabs__tabWrapper--fab-medium-theme_header) {
  background-image: none;
  background-color: var(--theme-base-default, #527a00);
  background-color: var(--fabric-theme-base, #527a00);
  border-radius: 8px;
}
.BaTabs__tabWrapper.BaTabs__tabWrapper--fab-medium-theme_light.BaTabs__tabWrapper--fab-medium-theme:before {
  background-color: var(--gray1, #f4f4f4);
}
:where(body[data-fabric-theme~="encore"]) .BaTabs__tabWrapper.BaTabs__tabWrapper--fab-medium-theme_light.BaTabs__tabWrapper--fab-medium-theme:before {
  background-color: var(--white, #fff);
}
.BaTabs__tabWrapper.BaTabs__tabWrapper--fab-medium-theme_header.BaTabs__tabWrapper--fab-medium-theme {
  margin-top: -50px;
  padding: 0;
}
.BaTabs__tab.BaTabs__tab--fab-medium-theme_header.BaTabs__tab--fab-medium-theme:not(.BaTabs__tab--fab-medium-theme_light):not(.BaTabs__tab--active) {
  color: var(--white, #fff);
  fill: rgba(255,255,255,0.8);
}
.BaTabs__tab.BaTabs__tab--fab-medium-theme_header.BaTabs__tab--fab-medium-theme:not(.BaTabs__tab--fab-medium-theme_light).BaTabs__tab--active {
  color: var(--theme-base-default, #527a00);
  color: var(--fabric-theme-base, #527a00);
  fill: var(--theme-base-default, #527a00);
  fill: var(--fabric-theme-base, #527a00);
  text-decoration: none;
}
.BaTabs__tab.BaTabs__tab--fab-medium-theme_header.BaTabs__tab--fab-medium-theme:not(.BaTabs__tab--fab-medium-theme_light):not(.BaTabs__tab--disabled):not(.BaTabs__tab--active):not(.BaTabs__tab--loading):hover {
  color: var(--white, #fff);
  fill: var(--white, #fff);
  background-color: rgba(255,255,255,0.2);
  text-decoration: none;
}
.BaTabs__overflowTab.BaTabs__overflowTab--fab-medium-theme_header.BaTabs__overflowTab--fab-medium-theme .BaTabs__tab.BaTabs__tab--fab-medium-theme_header.BaTabs__tab--fab-medium-theme:not(.BaTabs__tab--active) {
  color: var(--theme-base-default, #527a00);
  color: var(--fabric-theme-base, #527a00);
  background-color: transparent;
}
.BaTabs__overflowTab.BaTabs__overflowTab--fab-medium-theme_header.BaTabs__overflowTab--fab-medium-theme .BaTabs__tab.BaTabs__tab--fab-medium-theme_header.BaTabs__tab--fab-medium-theme.BaTabs__tab--active {
  color: var(--theme-base-default, #527a00);
  color: var(--fabric-theme-base, #527a00);
  fill: var(--theme-base-default, #527a00);
  fill: var(--fabric-theme-base, #527a00);
  background-color: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.BaTabs__overflowTab.BaTabs__overflowTab--fab-medium-theme_header.BaTabs__overflowTab--fab-medium-theme .BaTabs__tab.BaTabs__tab--fab-medium-theme_header.BaTabs__tab--fab-medium-theme.BaTabs__tab--active:hover {
  color: var(--white, #fff);
  fill: var(--white, #fff);
}
.BaTabs__overflowTab.BaTabs__overflowTab--fab-medium-theme_header.BaTabs__overflowTab--fab-medium-theme .BaTabs__tab.BaTabs__tab--fab-medium-theme_header.BaTabs__tab--fab-medium-theme:not(.BaTabs__tab--disabled):not(.BaTabs__tab--active):not(.BaTabs__tab--loading):hover {
  color: var(--white, #fff);
  fill: var(--white, #fff);
  background-color: transparent;
}

:where(body[data-fabric-theme~="encore"]) .BaTabs__tabContent.BaTabs__tabContent--fab-large-theme {
  background-color: var(--gray1, #f4f4f4);
  border-radius: 8px;
}
:where(body[data-fabric-theme~="encore"]) .BaTabs__tabContent.BaTabs__tabContent--fab-large-theme_light.BaTabs__tabContent--fab-large-theme {
  background-color: var(--white, #fff);
  border-radius: 8px;
}
.BaTabs__tab.BaTabs__tab--fab-large-theme {
  padding: 20px 24px 15px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -o-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: stretch;
  -moz-box-align: stretch;
  -o-box-align: stretch;
  -ms-flex-align: stretch;
  -webkit-align-items: stretch;
  align-items: stretch;
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
:where(body[data-fabric-theme~="encore"]) .BaTabs__tab.BaTabs__tab--fab-large-theme {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  margin: 0 4px;
}
.BaTabs__tab.BaTabs__tab--fab-large-theme:not(.BaTabs__tab--active) {
  color: var(--gray8, #555);
  fill: rgba(85,85,85,0.8);
}
.BaTabs__tab.BaTabs__tab--fab-large-theme:not(.BaTabs__tab--disabled):not(.BaTabs__tab--active):not(.BaTabs__tab--loading):hover {
  color: var(--theme-base-default, #527a00);
  color: var(--fabric-theme-base, #527a00);
  fill: var(--theme-base-default, #527a00);
  fill: var(--fabric-theme-base, #527a00);
  background-color: rgba(255,255,255,0.5);
}
:where(body[data-fabric-theme~="encore"]) .BaTabs__tab.BaTabs__tab--fab-large-theme:not(.BaTabs__tab--disabled):not(.BaTabs__tab--active):not(.BaTabs__tab--loading):hover {
  background-color: rgba(246,246,244,0.9);
}
.BaTabs__tab.BaTabs__tab--withIcon.BaTabs__tab--fab-large-theme {
  padding-left: 64px;
}
.BaTabs__tabLabel.BaTabs__tabLabel--primary.BaTabs__tabLabel--fab-large-theme {
  font-size: var(--font-size-large, 16px);
  line-height: var(--line-height-large, 24px);
}
:where(body[data-fabric-theme~="encore"]) .BaTabs__tabLabel.BaTabs__tabLabel--primary.BaTabs__tabLabel--fab-large-theme {
  font-weight: var(--font-weight-medium, 500);
}
.BaTabs__tabLabel.BaTabs__tabLabel--primary.BaTabs__tabLabel--active.BaTabs__tabLabel--fab-large-theme {
  font-weight: var(--font-weight-heavy, 800);
}
.BaTabs__tabLabel.BaTabs__tabLabel--secondary.BaTabs__tabLabel--fab-large-theme {
  font-size: var(--font-size-small, 14px);
  line-height: var(--line-height-small, 20px);
  color: var(--gray8, #555);
  font-weight: var(--font-weight-regular, 400);
  margin-top: 4px;
  max-width: 100%;
}
.BaTabs__tabLabel.BaTabs__tabLabel--secondary.BaTabs__tabLabel--fab-large-theme_light.BaTabs__tabLabel--fab-large-theme {
  color: var(--gray7, #686868);
}
.BaTabs__tab.BaTabs__tab--active.BaTabs__tab--fab-large-theme {
  color: var(--theme-base-default, #527a00);
  color: var(--fabric-theme-base, #527a00);
  fill: var(--theme-base-default, #527a00);
  fill: var(--fabric-theme-base, #527a00);
  background-color: var(--white, #fff);
  -webkit-box-shadow: 0 -4px 8px 0 rgba(0,0,0,0.05);
  box-shadow: 0 -4px 8px 0 rgba(0,0,0,0.05);
}
:where(body[data-fabric-theme~="encore"]) .BaTabs__tab.BaTabs__tab--active.BaTabs__tab--fab-large-theme {
  background-color: var(--gray1, #f4f4f4);
  -webkit-box-shadow: none;
  box-shadow: none;
}
.BaTabs__tabIcon.BaTabs__tabIcon--fab-large-theme {
  position: absolute;
  top: 20px;
  left: 24px;
}
.BaTabs__tabIcon.BaTabs__tabIcon--fab-large-theme > svg {
  width: 28px;
  height: 28px;
}
:where(body[data-fabric-theme~="encore"]) .BaTabs__tabIcon.BaTabs__tabIcon--fab-large-theme > svg {
  width: 24px;
  height: 24px;
}
:where(body[data-fabric-theme~="encore"]) .BaTabs__tabIcon.BaTabs__tabIcon--fab-large-theme_light.BaTabs__tabIcon--fab-large-theme > svg {
  width: 24px;
  height: 24px;
}
:where(body[data-fabric-theme~="encore"]) .BaTabs__tabIcon.BaTabs__tabIcon--active.BaTabs__tabIcon--fab-large-theme {
  fill: var(--fabric-theme-base);
}
.BaTabs__tabWrapper.BaTabs__tabWrapper--fab-large-theme {
  padding: 32px 24px 0;
}
.BaTabs__tabWrapper.BaTabs__tabWrapper--fab-large-theme:not(.BaTabs__tabWrapper--fab-large-theme_header) {
  background-color: var(--gray1, #f4f4f4);
}
:where(body[data-fabric-theme~="encore"]) .BaTabs__tabWrapper.BaTabs__tabWrapper--fab-large-theme:not(.BaTabs__tabWrapper--fab-large-theme_header) {
  background-color: var(--white, #fff);
}
.BaTabs__tabWrapper.BaTabs__tabWrapper--fab-large-theme:before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 3px;
  background-image: -webkit-linear-gradient(bottom, rgba(0,0,0,0.05) 0, rgba(0,0,0,0) 3px, rgba(0,0,0,0) 100%);
  background-image: -moz-linear-gradient(bottom, rgba(0,0,0,0.05) 0, rgba(0,0,0,0) 3px, rgba(0,0,0,0) 100%);
  background-image: -o-linear-gradient(bottom, rgba(0,0,0,0.05) 0, rgba(0,0,0,0) 3px, rgba(0,0,0,0) 100%);
  background-image: -ms-linear-gradient(bottom, rgba(0,0,0,0.05) 0, rgba(0,0,0,0) 3px, rgba(0,0,0,0) 100%);
  background-image: linear-gradient(to top, rgba(0,0,0,0.05) 0, rgba(0,0,0,0) 3px, rgba(0,0,0,0) 100%);
}
:where(body[data-fabric-theme~="encore"]) .BaTabs__tabWrapper.BaTabs__tabWrapper--fab-large-theme:before {
  content: none;
}
:where(body[data-fabric-theme~="encore"]) .BaTabs__tabWrapper.BaTabs__tabWrapper--fab-large-theme_light.BaTabs__tabWrapper--fab-large-theme {
  background-color: var(--gray1, #f4f4f4);
  border-radius: 16px;
}
:where(body[data-fabric-theme~="encore"]) .BaTabs__tab.BaTabs__tab--fab-large-theme_light.BaTabs__tab--fab-large-theme:not(.BaTabs__tab--active) {
  background-color: var(--gray1, #f4f4f4);
}
:where(body[data-fabric-theme~="encore"]) .BaTabs__tab.BaTabs__tab--fab-large-theme_light.BaTabs__tab--fab-large-theme {
  background-color: var(--white, #fff);
  fill: var(--gray8);
}

.BaTabs.BaTabs--fab-side-theme {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-orient: horizontal;
  -moz-box-orient: horizontal;
  -o-box-orient: horizontal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}
.BaTabs__tabWrapper.BaTabs__tabWrapper--fab-side-theme {
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -o-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: stretch;
  -moz-box-align: stretch;
  -o-box-align: stretch;
  -ms-flex-align: stretch;
  -webkit-align-items: stretch;
  align-items: stretch;
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 0 0 240px;
  -ms-flex: 0 0 240px;
  flex: 0 0 240px;
  background-color: var(--gray1, #f4f4f4);
  padding: 18px 16px;
}
.BaTabs__tabWrapper.BaTabs__tabWrapper--fab-side-theme.BaTabs__tabWrapper--fab-side-theme_sub {
  background-color: var(--white, #fff);
  -webkit-flex-basis: 224px;
  flex-basis: 224px;
  padding: 16px;
}
:where(body[data-fabric-theme~="encore"]) .BaTabs__tabWrapper.BaTabs__tabWrapper--fab-side-theme.BaTabs__tabWrapper--fab-side-theme_sub {
  gap: 4px;
  -webkit-flex-basis: 200px;
  flex-basis: 200px;
  padding: 0;
}
.BaTabs__tab.BaTabs__tab--fab-side-theme {
  padding: 10px 12px;
  border-radius: 4px;
  font-size: var(--font-size-medium, 15px);
  line-height: var(--line-height-medium, 22px);
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.BaTabs__tab.BaTabs__tab--fab-side-theme:not(.BaTabs__tab--disabled):not(.BaTabs__tab--active):not(.BaTabs__tab--loading):hover {
  color: var(--theme-base-default, #527a00);
  color: var(--fabric-theme-base, #527a00);
  fill: var(--theme-base-default, #527a00);
  fill: var(--fabric-theme-base, #527a00);
  background-color: var(--white, #fff);
}
.BaTabs__tab.BaTabs__tab--fab-side-theme + .BaTabs__tab.BaTabs__tab--fab-side-theme {
  margin-top: -2px;
}
.BaTabs__tab.BaTabs__tab--active.BaTabs__tab--fab-side-theme {
  color: var(--theme-base-default, #527a00);
  color: var(--fabric-theme-base, #527a00);
  fill: var(--theme-base-default, #527a00);
  fill: var(--fabric-theme-base, #527a00);
}
:where(body[data-fabric-theme~="encore"]) .BaTabs__tab.BaTabs__tab--active.BaTabs__tab--fab-side-theme {
  border-radius: 8px;
}
.BaTabs__tab.BaTabs__tab--fab-side-theme.BaTabs__tab--fab-side-theme_sub {
  padding: 7px 12px;
}
:where(body[data-fabric-theme~="encore"]) .BaTabs__tab.BaTabs__tab--fab-side-theme.BaTabs__tab--fab-side-theme_sub {
  padding: 8px 12px;
}
.BaTabs__tab.BaTabs__tab--active.BaTabs__tab--fab-side-theme.BaTabs__tab--fab-side-theme_sub,
.BaTabs__tab.BaTabs__tab--fab-side-theme.BaTabs__tab--fab-side-theme_sub:not(.BaTabs__tab--disabled):not(.BaTabs__tab--active):not(.BaTabs__tab--loading):hover {
  background-color: var(--gray1, #f4f4f4);
}
:where(body[data-fabric-theme~="encore"]) .BaTabs__tab.BaTabs__tab--active.BaTabs__tab--fab-side-theme.BaTabs__tab--fab-side-theme_sub,
:where(body[data-fabric-theme~="encore"]) .BaTabs__tab.BaTabs__tab--fab-side-theme.BaTabs__tab--fab-side-theme_sub:not(.BaTabs__tab--disabled):not(.BaTabs__tab--active):not(.BaTabs__tab--loading):hover {
  border-radius: 8px;
}
.BaTabs__tabIcon.BaTabs__tabIcon--fab-side-theme,
.BaTabs__tabIcon.BaTabs__tabIcon--fab-side-theme > svg {
  width: 20px;
  height: 20px;
}
.BaTabs__tabIcon.BaTabs__tabIcon--fab-side-theme + .BaTabs__tabLabel.BaTabs__tabLabel--fab-side-theme {
  margin-left: 11px;
}
.BaTabs__tabIcon.BaTabs__tabIcon--fab-side-theme.BaTabs__tabIcon--fab-side-theme_sub {
  display: none;
}
.BaTabs__tabIcon.BaTabs__tabIcon--fab-side-theme.BaTabs__tabIcon--fab-side-theme_sub + .BaTabs__tabLabel.BaTabs__tabLabel--fab-side-theme {
  margin-left: 0;
}
:where(body[data-fabric-theme~="encore"]) .BaTabs__tabIcon.BaTabs__tabIcon--fab-side-theme.BaTabs__tabIcon--fab-side-theme_sub {
  display: block;
}
:where(body[data-fabric-theme~="encore"]) .BaTabs__tabIcon.BaTabs__tabIcon--fab-side-theme.BaTabs__tabIcon--fab-side-theme_sub + .BaTabs__tabLabel.BaTabs__tabLabel--fab-side-theme {
  margin-left: 12px;
}
.BaTabs__tabGroup.BaTabs__tabGroup--empty.BaTabs__tabGroup--fab-side-theme {
  display: none;
}
.BaTabs__tabGroup.BaTabs__tabGroup--fab-side-theme ~ .BaTabs__tabGroup.BaTabs__tabGroup--fab-side-theme,
.BaTabs__tab.BaTabs__tab--fab-side-theme + .BaTabs__tabGroup.BaTabs__tabGroup--fab-side-theme {
  border-top: 1px solid #e0e0e0;
  margin-top: 10px;
}
.BaTabs__tabWrapperHeader.BaTabs__tabWrapperHeader--fab-side-theme + .BaTabs__tabGroup.BaTabs__tabGroup--fab-side-theme > .BaTabs__tabGroupHeader.BaTabs__tabGroupHeader--fab-side-theme:empty {
  display: none;
}
.BaTabs__tabGroupHeader.BaTabs__tabGroupHeader--fab-side-theme:not(:empty) {
  padding: 12px 16px 4px;
}
.BaTabs__tabGroupHeader.BaTabs__tabGroupHeader--fab-side-theme:empty {
  padding-top: 12px;
}
.BaTabs__tabGroupHeader.BaTabs__tabGroupHeader--fab-side-theme.BaTabs__tabGroupHeader--fab-side-theme_sub:not(:empty) {
  padding: 12px 12px 4px;
}
.BaTabs__tabGroupHeader.BaTabs__tabGroupHeader--fab-side-theme.BaTabs__tabGroupHeader--fab-side-theme_sub:empty {
  padding-top: 10px;
}
.BaTabs__tabGroupLabel.BaTabs__tabGroupLabel--fab-side-theme {
  color: var(--gray7, #686868);
  font-size: var(--font-size-teenie, 13px);
  line-height: var(--line-height-teenie, 18px);
}
:where(body[data-fabric-theme~="encore"]) .BaTabs__tabLabel.BaTabs__tabLabel--fab-side-theme.BaTabs__tabLabel--fab-side-theme_sub {
  font-size: var(--font-size-small, 14px);
  line-height: var(--line-height-small, 20px);
}
.BaTabs__tabContent.BaTabs__tabContent--fab-side-theme > .BaTabs.BaTabs--fab-side-theme.BaTabs--fab-side-theme_sub {
  height: 100%;
}

.BaTabs__tab.BaTabs__tab--fab-wizard-theme {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-direction: reverse;
  -moz-box-direction: reverse;
  -o-box-direction: reverse;
  -webkit-box-orient: horizontal;
  -moz-box-orient: horizontal;
  -o-box-orient: horizontal;
  -webkit-flex-direction: row-reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  height: 56px;
  cursor: default !important;
  color: var(--gray7, #686868);
  font-size: var(--font-size-biggie, 18px);
  line-height: 1em;
  padding-left: 35px;
  padding-right: 45px;
}
:where(body[data-fabric-theme~="encore"]) .BaTabs__tab.BaTabs__tab--fab-wizard-theme {
  padding: 16px 20px;
  border-radius: 16px;
  font-weight: var(--font-weight-medium, 500);
  line-height: var(--line-height-large, 24px);
}
.BaTabs__tab.BaTabs__tab--active.BaTabs__tab--fab-wizard-theme {
  color: var(--theme-base-default, #527a00);
  color: var(--fabric-theme-base, #527a00);
}
:where(body[data-fabric-theme~="jade"]) .BaTabs__tab.BaTabs__tab--active.BaTabs__tab--fab-wizard-theme:not(:last-of-type) {
  background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMTI5cHgiIGhlaWdodD0iNDZweCIgdmVyc2lvbj0iMS4xIiB2aWV3Qm94PSIwIDAgMTI5IDQ2IiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgoJPGRlZnM+CgkJPGxpbmVhckdyYWRpZW50IGlkPSJhIiB4MT0iMjcuODk4JSIgeDI9IjkzLjczMiUiIHkxPSI0Ni4wNjYlIiB5Mj0iNDYuMDY2JSI+CgkJCTxzdG9wIHN0b3AtY29sb3I9IiNGNEY0RjQiIG9mZnNldD0iMCIvPgoJCQk8c3RvcCBzdG9wLWNvbG9yPSIjRUJFQkVCIiBvZmZzZXQ9IjEiLz4KCQk8L2xpbmVhckdyYWRpZW50PgoJPC9kZWZzPgoJPGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KCQk8cGF0aCBkPSJtMCAxLjk5NjljMC0xLjEwMjggMC45MDE1OS0xLjk5NjkgMS45OTE2LTEuOTk2OWgxMDguMDJjMS4wOTk5IDAgMi40NDg1IDAuNzcyNzYgMy4wMTA0IDEuNzIzbDEyLjk1NiAyMS45MTJjMC41NjI2NSAwLjk1MTYgMC41MzY4OSAyLjQ4MjktMC4wNDc0OTggMy40MDQzbC0xMi44NjEgMjAuMjc5Yy0wLjU4ODg4IDAuOTI4NTctMS45Njc5IDEuNjgxMy0zLjA1NzkgMS42ODEzaC0xMDguMDJjLTEuMDk5OSAwLTEuOTkxNi0wLjg5MjMyLTEuOTkxNi0xLjk5Njl2LTQ1LjAwNnoiIGZpbGw9IiNEM0QzRDMiLz4KCQk8cGF0aCBkPSJtMCAxLjk5NjljMC0xLjEwMjggMC44OTM1NC0xLjk5NjkgMS45OTc2LTEuOTk2OWgxMDcuMDFjMS4xMDMyIDAgMi40NTQ1IDAuNzcyNzYgMy4wMTY0IDEuNzIzbDEyLjk1NiAyMS45MTJjMC41NjI2NSAwLjk1MTYgMC41MzY4OSAyLjQ4MjktMC4wNDc0OTggMy40MDQzbC0xMi44NjEgMjAuMjc5Yy0wLjU4ODg4IDAuOTI4NTctMS45NTk4IDEuNjgxMy0zLjA2MzkgMS42ODEzaC0xMDcuMDFjLTEuMTAzMiAwLTEuOTk3Ni0wLjg5MjMyLTEuOTk3Ni0xLjk5Njl2LTQ1LjAwNnoiIGZpbGw9InVybCgjYSkiLz4KCTwvZz4KPC9zdmc+Cg==");
  background-position: center right;
  background-repeat: no-repeat;
  -webkit-background-size: auto 100%;
  -moz-background-size: auto 100%;
  background-size: auto 100%;
}
:where(body[data-fabric-theme~="encore"]) .BaTabs__tab.BaTabs__tab--active.BaTabs__tab--fab-wizard-theme {
  background-color: var(--gray1);
}
:where(body[data-fabric-theme~="jade"]) .BaTabs__tabCaret.BaTabs__tabCaret--fab-wizard-theme {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -o-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  background-color: var(--gray6, #999);
  border-radius: 100%;
  width: 24px;
  height: 24px;
  text-align: center;
  color: var(--white, #fff);
  margin-right: 12px;
  fill: var(--white, #fff);
  font-size: var(--font-size-large, 16px);
  line-height: 1em;
}
:where(body[data-fabric-theme~="encore"]) .BaTabs__tabCaret.BaTabs__tabCaret--fab-wizard-theme {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -o-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  margin-right: 12px;
}
:where(body[data-fabric-theme~="jade"]) .BaTabs__tabCaret.BaTabs__tabCaret--active.BaTabs__tabCaret--fab-wizard-theme {
  background-color: var(--theme-base-default, #527a00);
  background-color: var(--fabric-theme-base, #527a00);
}
.BaTabs__tabWrapper.BaTabs__tabWrapper--fab-wizard-theme {
  background-color: var(--gray1, #f4f4f4);
  border: 1px solid #e0e0e0;
  border-left: none;
  border-right: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
:where(body[data-fabric-theme~="encore"]) .BaTabs__tabWrapper.BaTabs__tabWrapper--fab-wizard-theme {
  border: none;
  padding: 16px 32px;
  background-color: var(--white);
  -webkit-box-shadow: 1px 1px 0 2px rgba(56,49,47,0.03);
  box-shadow: 1px 1px 0 2px rgba(56,49,47,0.03);
  border-radius: 16px;
}
:where(body[data-fabric-theme~="encore"]) .BaTabs__tab.BaTabs__tab--done.BaTabs__tab--fab-wizard-theme {
  color: var(--fabric-theme-base);
}
:where(body[data-fabric-theme~="encore"]) .BaTabs__tabContentWrapper.BaTabs__tabContentWrapper--fab-wizard-theme {
  margin-top: 32px;
}

.BaTabs__progress,
.BaTabs__loader {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate3d(-50%, -50%, 0);
  -moz-transform: translate3d(-50%, -50%, 0);
  -o-transform: translate3d(-50%, -50%, 0);
  -ms-transform: translate3d(-50%, -50%, 0);
  transform: translate3d(-50%, -50%, 0);
}
.BaTabs__tab {
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
body[data-feature-toggles~="jade"] .BaTabs__tab {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
}
body[data-feature-toggles~="jade"] .BaTabs__tab[class*="-theme_large"] {
  font-size: var(--font-size-large, 16px);
}
body[data-feature-toggles~="jade"] .BaTabs__tab--active {
  font-weight: var(--font-weight-heavy, 800);
}
body[data-feature-toggles~="jade"] .BaTabs__tab--disabled {
  opacity: 0.6;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=60)";
  filter: alpha(opacity=60);
}
.BaTabs__tab:not(.BaTabs__tab--active):not(.BaTabs__tab--disabled):not(.BaTabs__tab--loading) {
  cursor: pointer;
}
body[data-feature-toggles~="jade"] .BaTabs__tab {
  color: var(--gray8, #555);
  fill: var(--gray7, #686868);
}
.BaTabs__tab--disabled,
.BaTabs__tab--disabled * {
  cursor: default;
}
.BaTabs__tabOverflowWrapper {
  position: absolute;
  display: none;
}
.BaTabs__tab:hover .BaTabs__tabOverflowWrapper {
  display: block;
}
.BaTabs__tabLabel {
  display: block;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.BaTabs__tabLabel,
.BaTabs__tabLabel:hover {
  text-decoration: none;
}
.BaTabs__tabLabel[class*="-theme_icons"] {
  display: none;
}
.BaTabs__tabIcon {
  line-height: 0;
}
.BaTabs__tabCaret--notify {
  position: absolute;
  top: -9px;
  right: -5px;
  background: #d53e0f;
  border-radius: 50%;
  color: #fff;
  font-size: 11px;
  height: 18px;
  min-width: 18px;
  text-align: center;
  line-height: 18px;
}
.BaTabs__tabWrapper {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  position: relative;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.BaTabs__tabWrapper--hidden {
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
}
body[data-feature-toggles~="jade"] .BaTabs__tabWrapperHeader {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
}
body[data-feature-toggles~="jade"] .BaTabs__tabWrapperTitleWrapper {
  -webkit-box-flex: 2;
  -moz-box-flex: 2;
  -o-box-flex: 2;
  -ms-box-flex: 2;
  box-flex: 2;
  -webkit-flex-grow: 2;
  flex-grow: 2;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -o-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}
body[data-feature-toggles~="jade"] .BaTabs__tabWrapperTitle {
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -o-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}
.BaTabs__tabContent {
  position: relative;
  width: 100%;
}
.BaTabs__tabContent:not(.BaTabs__tabContent--active) {
  display: none;
}
.BaTabs__tabContent--loading {
  min-height: 100px;
}
.BaTabs__tabContentWrapper {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.BaTabs__tabContentWrapper--processing > .BaTabs__tabContent {
  display: none;
}

.AdvancedApprovalPathHeader {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  height: unset;
  padding-bottom: 0px;
  padding-top: 20px;
  margin-left: 16px;
/* .AdvancedApprovalPathHeader__header */
}
.AdvancedApprovalPathHeader__header {
  margin: auto 0;
  font-family: Inter;
  font-size: 15px;
  font-weight: 500;
  line-height: 22px;
  text-align: left;
}

/* .AdvancedApprovalsConfirmationModal */
.AdvancedApprovalsConfirmationModal {
  padding-left: 40px;
  padding-right: 40px;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -o-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
/* .AdvancedApprovalsConfirmationModal, .AdvancedApprovalsConfirmationModal p */
/* AdvancedApprovalsConfirmationModal__footer-cancel-button */
/* AdvancedApprovalsConfirmationModal__footer-cancel-button:hover */
/* .AdvancedApprovalsConfirmationModal__text-container */
/* .AdvancedApprovalsConfirmationModal__warning-icon-container */
}
.AdvancedApprovalsConfirmationModal,
.AdvancedApprovalsConfirmationModal p {
  text-align: center;
}
.AdvancedApprovalsConfirmationModal__footer-cancel-button {
  color: #006ec2;
  background: none;
  border: none;
  font-family: inherit;
  font-size: inherit;
}
.AdvancedApprovalsConfirmationModal__footer-cancel-button:hover {
  text-decoration: underline;
}
.AdvancedApprovalsConfirmationModal__text-container {
  color: #222;
  font-size: var(--font-size-biggie, 18px);
  max-width: 460px;
/* .AdvancedApprovalsConfirmationModal__text-container--sub-text */
}
.AdvancedApprovalsConfirmationModal__text-container--sub-text {
  font-size: var(--font-size-medium, 15px);
  margin: 6px 0 0 15px;
  max-width: 395px;
}
.AdvancedApprovalsConfirmationModal__warning-icon-container {
  fill: #f0a404;
  margin: 24px auto 22px;
}

.HoursPerWeekField__container {
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  color: #686868;
  font-size: 13px;
  margin-top: 20px;
}
.HoursPerWeekField__helpButton {
  background-color: #777;
  border-radius: 100%;
  border-width: 0;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: Lato;
  font-size: 12px;
  font-weight: 800;
  height: 14px;
  line-height: 14px;
  margin-left: 4px;
  padding: 0;
  text-align: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 14px;
}
.HoursPerWeekField__inputContainer {
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
}
.HoursPerWeekField__inputNote {
  margin-left: 8px;
}
.HoursPerWeekField__popoverButton {
  margin-top: 16px;
  text-align: left;
}

.CompensationForm__warningNote {
  color: var(--attention, #bd5800);
  font-weight: var(--font-weight-semibold, 600);
}
.CompensationForm .percentChange {
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  color: #555;
  display: -webkit-inline-box;
  display: -moz-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-box;
  display: inline-flex;
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  margin: auto 17px;
}
.CompensationForm .percentChange-percent {
  margin-right: 5px;
}
.CompensationForm .percentChange-icon {
  font-size: unset;
  margin-left: 4px;
}
.CompensationForm .percentChange-icon-increase {
  fill: #0e840d;
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}
.CompensationForm .percentChange-icon-decrease {
  fill: #c20b0b;
}
.CompensationForm .percentChange.nextLine {
  margin: 8px 0 3px;
  width: 100%;
}
.TaxTypeWarningText {
  margin-left: 12px;
  position: absolute;
  top: -21px;
  color: var(--info, #0772b3);
  line-height: var(--line-height-teenie, 18px);
  width: 290px;
  font-size: var(--font-size-teenie);
}
.TaxTypeWarningText__text {
  font-weight: var(--font-weight-semibold, 600);
}
.TaxTypeWarningText__doubleCheck {
  color: var(--gray7, #686868);
}
.EmploymentStatusModal__statusSelectWrapper--inactive {
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -o-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}
.EmploymentStatusModal__statusSelectWrapper--inactive .EmploymentStatusForm__inactiveStatusNote {
  display: block;
}
.EmploymentStatusForm__inactiveStatusNote {
  display: none;
  color: var(--gray7, #686868);
  font-size: var(--font-size-teenie, 13px);
  line-height: var(--line-height-teenie, 18px);
  margin-top: 8px;
}
.EmploymentStatusForm__employeeTaxTypeFieldRow .fab-Label {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.EmploymentStatusForm__employeeTaxTypeFieldRow .fab-Label .iconWrapper {
  fill: var(--info, #0772b3);
  margin-right: 6px;
}
.EmploymentStatusForm__employeeTaxTypeFieldRow--info .fab-Label {
  color: var(--info, #0772b3);
}
.currencyChange-message {
  height: 70px;
  padding: 10px 14px;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-pack: start;
  -moz-box-pack: start;
  -o-box-pack: start;
  -ms-flex-pack: start;
  -webkit-justify-content: start;
  justify-content: start;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-column-gap: 13px;
  -moz-column-gap: 13px;
  column-gap: 13px;
  background-color: #e6f5fe;
  border-radius: 4px;
  width: 435px;
  margin-top: 15px;
}
.currencyChange-message--icon {
  fill: #0772b3;
  -webkit-flex-shrink: 0;
  flex-shrink: 0;
}
.currencyChange-message--text {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -o-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  -ms-box-flex: 1;
  box-flex: 1;
  -webkit-flex-grow: 1;
  flex-grow: 1;
  line-height: 18px;
}
.currencyChange-message--title {
  font-weight: 600;
  font-size: 15px;
  color: #222;
}
.currencyChange-message--description {
  font-weight: 400;
  font-size: 14px;
  color: #444;
}

.Approvals__head {
  margin-top: -6px;
/* .Approvals__head h3.Approvals__header */
/* .Approvals__head-sub-text */
/* .Approvals__head-preview */
/* .Approvals__head-GearMenu */
/* .Approvals__head--action */
}
.Approvals__headIcon {
  margin-right: 7px;
  position: relative;
  top: 2px;
}
.Approvals__headTitle {
  color: var(--fabric-theme-base);
  display: inline-block;
  -webkit-box-flex: 2;
  -moz-box-flex: 2;
  -o-box-flex: 2;
  -ms-box-flex: 2;
  box-flex: 2;
  -webkit-flex-grow: 2;
  flex-grow: 2;
  margin: 0;
  padding-top: 0 !important;
}
.Approvals__headHeaderContainer {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  padding-top: 0;
}
.Approvals__headActions {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -o-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -o-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}
.Approvals__head h3.Approvals__header {
  margin-bottom: 7px;
}
.Approvals__head-sub-text {
  margin-top: 20px;
  color: #686868;
  font-size: var(--font-size-small, 14px);
  margin-bottom: 16px;
}
.Approvals__head-preview {
  display: inline-block;
  margin-left: 20px;
  position: relative;
  top: 4px;
  color: #686868;
}
.Approvals__head-GearMenu {
  margin-left: 3px;
  margin-top: 6px !important;
/* .Approvals__head-GearMenu  .dropdown-menu__list-item-header*/
}
.Approvals__head-GearMenu .dropdown-menu__list-item-header {
  border-bottom: solid 1px #adadad;
/* .Approvals__head-GearMenu  .dropdown-menu__list-item-header--larger-text*/
}
.Approvals__head-GearMenu .dropdown-menu__list-item-header--larger-text {
  font-size: var(--font-size-medium, 15px);
}
.Approvals__head--action {
  margin-top: 20px;
}
.Approvals__head--action div {
  vertical-align: top;
}
.inline {
  display: inline-block;
}

/* AdvancedApprovalsNewPathContainer */
.AdvancedApprovalsNewPathContainer {
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  margin: 0px;
  padding: 0px;
/* AdvancedApprovalsNewPathContainer__actions-container */
/* .AdvancedApprovalsNewPathContainer__icon-container */
/*
		.AdvancedApprovalsNewPathContainer__icon-container,
		AdvancedApprovalsNewPathContainer__title
	 */
/* AdvancedApprovalsNewPathContainer__title */
}
.AdvancedApprovalsNewPathContainer__actions-container {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  margin: 16px 0px 0px 36px;
/*
			AdvancedApprovalsNewPathContainer__actions-container__buttons,
			AdvancedApprovalsNewPathContainer__actions-container__select
		 */
/* AdvancedApprovalsNewPathContainer__actions-container__buttons .btn */
/* AdvancedApprovalsNewPathContainer__actions-container__select */
}
.AdvancedApprovalsNewPathContainer__actions-container__buttons,
.AdvancedApprovalsNewPathContainer__actions-container__select {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 0.5;
  -ms-flex: 0.5;
  flex: 0.5;
}
.AdvancedApprovalsNewPathContainer__actions-container__buttons button {
  margin-right: 16px;
}
.AdvancedApprovalsNewPathContainer__actions-container__select {
  width: 0px;
  margin-right: 16px;
/* AdvancedApprovalsNewPathContainer__actions-container__select__toggle */
}
.AdvancedApprovalsNewPathContainer__actions-container__select__toggle {
  color: #222;
}
.AdvancedApprovalsNewPathContainer__icon-container {
  min-width: 0px;
/* .AdvancedApprovalsNewPathContainer__icon-container .icon*/
}
.AdvancedApprovalsNewPathContainer__icon-container .icon {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -o-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}
.AdvancedApprovalsNewPathContainer__icon-container,
.AdvancedApprovalsNewPathContainer__title {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -o-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}
.AdvancedApprovalsNewPathContainer__title {
  color: var(--theme-base-default, #527a00);
  color: var(--fabric-theme-base, #527a00);
  position: relative;
  margin-left: 12px;
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  top: 0px;
}

.Approvals__Select--readonly .ba-Select__caret,
.Approvals__Select--readonly .ba-Select__clearButton {
  display: none;
}
.Approvals__list {
  padding-top: 0;
}
.Approvals__list-levels {
  margin-left: 20px;
}
.ApprovalsForm__footerBreak {
  margin-bottom: 20px;
}

.SettingsApprovalsContainer .AdvancedApprovals__subheader .Subheader {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  gap: 12px;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.SettingsApprovalsContainer .AdvancedApprovals__subheader .Subheader__actionButton {
  cursor: pointer;
}
.SettingsApprovalsContainer .AdvancedApprovals__subheader .Subheader__actionButton,
.SettingsApprovalsContainer .AdvancedApprovals__subheader .Subheader__pathTypeIcon:not(.baseFillColor) {
  fill: #939393;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.SettingsApprovalsContainer .AdvancedApprovals__subheader .Subheader__actions {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  padding-top: 0;
  position: relative;
  top: 0;
}
.SettingsApprovalsContainer .AdvancedApprovals__subheader .Subheader__catch-all-divider {
  border-top: 1px solid #b1b1b1;
  margin-top: 20px;
  margin-left: 42px;
  width: 93%;
}
.SettingsApprovalsContainer .AdvancedApprovals__subheader .Subheader__icon {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -o-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  position: unset;
  top: 0;
  min-width: 0px;
}
.SettingsApprovalsContainer .AdvancedApprovals__subheader .Subheader__type {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  -ms-box-flex: 1;
  box-flex: 1;
  -webkit-flex-grow: 1;
  flex-grow: 1;
  position: relative;
  min-height: unset;
  top: 0px;
/* .SettingsApprovalsContainer .AdvancedApprovals__subheader .Subheader__type--editing */
}
.SettingsApprovalsContainer .AdvancedApprovals__subheader .Subheader__type--editing {
  display: block;
}
.SettingsApprovalsContainer .AdvancedApprovals__subheader .PathTypeOptions {
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -o-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  display: block;
}
.SettingsApprovalsContainer .AdvancedApprovals__subheader .PathTypeOptions__list {
  color: #222;
  list-style: none;
  list-style-position: inside;
  line-height: 19px;
  margin-left: 15px;
}
.SettingsApprovalsContainer .AdvancedApprovals__subheader .PathTypeOptions__listItem {
  margin-top: 8px;
}
.SettingsApprovalsContainer .AdvancedApprovals__subheader .PathTypeOptions__listItem:before {
  content: '\2022';
  color: var(--theme-base-default, #527a00);
  color: var(--fabric-theme-base, #527a00);
  margin-right: 9px;
}
.SettingsApprovalsContainer .AdvancedApprovals__subheader .PathTypeOptions__title {
  color: var(--theme-base-default, #527a00);
  color: var(--fabric-theme-base, #527a00);
  display: inline-block;
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  -ms-box-flex: 1;
  box-flex: 1;
  -webkit-flex-grow: 1;
  flex-grow: 1;
  padding-top: 0 !important;
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  margin-bottom: 0px !important;
  margin-top: 0px !important;
  margin-left: 0px;
}
.SettingsApprovalsContainer .AdvancedApprovals__subheader .PathTypeSelect {
  margin-left: 10px;
  width: 195px;
  display: inline-block;
}
.SettingsApprovalsContainer .AdvancedApprovals__subheader .PathTypeSelect__toggle {
  color: #222;
}
.SettingsApprovalsContainer .AdvancedApprovals__subheader .ActionButtons {
  display: block;
}
.SettingsApprovalsContainer .AdvancedApprovals__subheader .ActionButtons .ActionButton {
  cursor: pointer;
  fill: #939393;
}
.SettingsApprovalsContainer .AdvancedApprovals__subheader .ActionButtons .ActionButton__container {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
}
.SettingsApprovalsContainer .AdvancedApprovals__subheader .ActionButtons .ActionButton__deleteButton {
  margin-left: 12px;
}
.SettingsApprovalsContainer .AdvancedApprovals__subheader .ActionButtons--catchAll .ActionButton__deleteButton {
  display: none;
}

.SettingsApprovalsContainer .Approvals .PathContainer {
  padding: 22px 16px 0 26px;
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  margin: 0px;
  padding: 0px;
  background-color: var(--gray05, #f6f6f4);
}
.SettingsApprovalsContainer .Approvals .PathContainer:not(.PathContainer--edit) {
  border-color: #fff;
}
.SettingsApprovalsContainer .Approvals .PathContainer .showOnEdit {
  display: none;
}
.SettingsApprovalsContainer .Approvals .PathContainer--edit {
  background-color: #fff;
  border-color: var(--theme-lighter-default, #82af13);
  border-color: var(--fabric-theme-lighter, #82af13);
}
.SettingsApprovalsContainer .Approvals .PathContainer--edit .showOnEdit {
  display: block;
}
.SettingsApprovalsContainer .Approvals .PathContainer--edit .showOnEdit.Approvals__removeLevel {
  display: inline-block;
}
.SettingsApprovalsContainer .Approvals .PathContainer:last-of-type {
  margin-bottom: 0;
}


/* AdvancedApprovalsPathModal */
.AdvancedApprovalsPathModal {
  padding: 0;
  padding-bottom: 16px;
/* AdvancedApprovalsPathModal, AdvancedApprovalsPathModal p */
/* AdvancedApprovalsPathModal .checkboxCardSpacing */
/* AdvancedApprovalsPathModal__footer-link-look-button */
/* AdvancedApprovalsPathModal__footer-link-look-button:hover */
/* AdvancedApprovalsPathModal__icon */
/* AdvancedApprovalsPathModal__options-container */
/* AdvancedApprovalsPathModal .PathOptions */
/* AdvancedApprovalsPathModal__title */
/* AdvancedApprovalsPathModal__warning-container */
}
.AdvancedApprovalsPathModal,
.AdvancedApprovalsPathModal p,
.AdvancedApprovalsPathModal__confirmation-modal p {
  text-align: center;
  font-size: var(--font-size-medium, 15px);
  line-height: var(--line-height-medium, 22px);
}
.AdvancedApprovalsPathModal p {
  color: #444;
}
.AdvancedApprovalsPathModal .checkboxCardSpacing {
  margin-right: 18px;
}
.AdvancedApprovalsPathModal__footer-link-look-button {
  color: #0772b3;
  background: none;
  border: none;
  font-family: inherit;
  font-size: inherit;
}
.AdvancedApprovalsPathModal__footer-link-look-button:hover {
  text-decoration: underline;
}
.AdvancedApprovalsPathModal__icon {
  margin-top: 8px;
  fill: #555;
}
.AdvancedApprovalsPathModal__options-container {
  padding-top: 16px;
/* AdvancedApprovalsPathModal__options-container__options-header */
}
.AdvancedApprovalsPathModal__options-container__options-header {
  display: block;
  color: #444;
  font-size: var(--font-size-medium, 15px);
  margin-bottom: 24px;
}
.AdvancedApprovalsPathModal .PathOptions .PathOption {
  display: inline-block;
/* AdvancedApprovalsPathModal .PathOptions .PathOption__option */
}
.AdvancedApprovalsPathModal .PathOptions .PathOption__option {
  border-color: var(--theme-lighter-default, #82af13);
  border-color: var(--fabric-theme-lighter, #82af13);
  height: 100px;
  width: 156px;
}
.AdvancedApprovalsPathModal__title {
  margin: 28px 0 14px;
}
.AdvancedApprovalsPathModal__warning-container {
  margin-top: 18px;
  color: #bd5800;
  fill: #bd5800;
/* AdvancedApprovalsPathModal__warning-container__icon */
/* AdvancedApprovalsPathModal__warning-container__text */
}
.AdvancedApprovalsPathModal__warning-container__icon {
  margin-right: 5px;
}
.AdvancedApprovalsPathModal__warning-container__text {
  display: inline-block;
  margin-left: 5px;
  position: relative;
  top: -3px;
  text-align: center;
}
/* AdvancedApprovalsPathConfirmationModal */
.AdvancedApprovalsPathConfirmationModal {
  padding-left: 40px;
  padding-right: 40px;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -o-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
/* AdvancedApprovalsPathConfirmationModal, AdvancedApprovalsPathConfirmationModal p */
/* AdvancedApprovalsPathConfirmationModal__text-container */
/* AdvancedApprovalsPathConfirmationModal__warning-icon-container */
}
.AdvancedApprovalsPathConfirmationModal,
.AdvancedApprovalsPathConfirmationModal p {
  text-align: center;
}
.AdvancedApprovalsPathConfirmationModal__text-container {
  font-size: 16px;
  margin-bottom: 19px;
  max-width: 330px;
  color: #222;
/* AdvancedApprovalsPathConfirmationModal__text-container--sub-text */
}
.AdvancedApprovalsPathConfirmationModal__text-container--sub-text {
  font-size: 13px;
  max-width: 285px;
  margin-left: 22px;
  margin-top: 6px;
  color: #adadad;
}
.AdvancedApprovalsPathConfirmationModal__warning-icon-container {
  margin: 24px auto 22px;
  fill: #bd5800;
}

#workflowsReactRoot {
  width: 100%;
}
.confirmationModal__text {
  color: #444;
  text-align: center;
}
.SettingsApprovals #settings-main-content {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  min-height: 100%;
  padding: 0;
}
.SettingsApprovals .Approvals__subNav-icon {
  fill: #939393;
  margin-left: -22px;
  margin-top: 1px;
  vertical-align: top;
}
.SettingsApprovals .ba-Select__optionGroup[label] .ba-Select__option {
  padding-left: 8px;
}
.SettingsApprovals .loader {
  z-index: 1;
}
.SettingsApprovalsTabs {
  height: auto;
}
.SettingsApprovalsTabsTitle {
  font-weight: var(--font-weight-semibold, 600);
  line-height: var(--line-height-h4, 26px);
  margin-left: 0;
  color: var(--theme-base-default, #527a00);
  color: var(--fabric-theme-base, #527a00);
  font-size: 18px;
}
.SettingsApprovalsTabs .SettingsApprovalsTabs__tab {
  border-radius: 8px;
}
.SettingsApprovalsTabs .SettingsApprovalsTabs__tabLabel--loading {
  color: #adadad;
}
.SettingsApprovalsTabs .SettingsApprovalsTabs__tabGroup {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -o-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  gap: 4px;
}
.SettingsApprovalsTabs .SettingsApprovalsTabs__tabGroup:last-child {
  border-bottom: none;
}
.SettingsApprovalsTabs .SettingsApprovalsTabs__tabWrapperIcon {
  fill: #939393;
}
.SettingsApprovalsTabs .SettingsApprovalsTabs__tabWrapper {
  width: 22%;
  border-right: none;
}
.SettingsApprovalsTabs .SettingsApprovalsTabs__tabWrapperActions {
  margin-left: auto;
}
.SettingsApprovalsTabs .SettingsApprovalsTabs__tabWrapperHeader {
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  padding: 0;
  margin-bottom: 20px;
}
.SettingsApprovalsTabs .SettingsApprovalsTabs__tabWrapperTitle {
  margin-bottom: 0 !important;
  padding-top: 0 !important;
}
.SettingsApprovalsContainer {
  position: relative;
  position: static;
  padding-bottom: 40px;
}
.SettingsApprovalsContainerHeaderActions {
  margin-top: 13px;
  position: relative;
  top: 4px;
}
.SettingsApprovalsContainerWrapper {
  padding-left: 32px;
  padding-top: 0;
}
.SettingsApprovalsContainer .Approvals__description {
  font-size: 15px;
  margin-bottom: 6px;
  white-space: pre-line;
  margin-top: 0;
}
.SettingsApprovalsContainer .Approvals__section-title {
  font-size: 15px;
  font-weight: 600;
  margin: 0;
}
.SettingsApprovalsContainer .Approvals__section-text {
  color: #686868;
  font-size: var(--font-size-small, 14px);
  line-height: var(--line-height-medium, 22px);
  margin-bottom: 16px;
}
.SettingsApprovalsContainer .Approvals__section-note {
  line-height: 1.3em;
  color: #222;
  font-size: var(--font-size-medium, 15px);
}
.SettingsApprovalsContainer .Approvals__level {
  height: 38px;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  margin-bottom: 20px;
}
.SettingsApprovalsContainer .Approvals__level:hover .Approvals__removeLevel {
  opacity: 1;
  -ms-filter: none;
  filter: none;
}
.SettingsApprovalsContainer .Approvals__level-mark {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  background-color: transparent;
  border: none;
  border-radius: 50%;
  color: #fff;
  display: inline-block;
  font-size: var(--font-size-medium, 15px);
  font-weight: var(--font-weight-semibold, 600);
  height: 26px;
  position: relative;
  text-align: center;
  width: 26px;
  padding-top: 0;
  margin-right: 20px;
  top: 0;
}
.SettingsApprovalsContainer .Approvals__level[data-level="2"] {
  margin-left: 20px;
}
.SettingsApprovalsContainer .Approvals__level[data-level="3"] {
  margin-left: 40px;
}
.SettingsApprovalsContainer .Approvals__level[data-level="4"] {
  margin-left: 60px;
}
.SettingsApprovalsContainer .Approvals__level[data-level="5"] {
  margin-left: 80px;
}
.SettingsApprovalsContainer .Approvals_customApproval {
  margin-bottom: 20px;
}
.SettingsApprovalsContainer .Approvals_customApproval .Approvals__section-title {
  margin-bottom: 10px;
}
.SettingsApprovalsContainer .Approvals__actions {
  margin-bottom: 20px;
  margin-left: 20px;
}
.SettingsApprovalsContainer .Approvals__head--action {
  margin-bottom: 12px;
}
.SettingsApprovalsContainer .Approvals__people,
.SettingsApprovalsContainer .Approvals__group {
  display: inline-block;
  opacity: 1;
  -ms-filter: none;
  filter: none;
  vertical-align: middle;
  width: auto;
  z-index: 1;
  margin-left: 12px;
}
.SettingsApprovalsContainer .Approvals__group .ui-multiselect span:nth-of-type(2) {
  width: 85%;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}
.SettingsApprovalsContainer .Approvals__group .ui-multiselect:nth-of-type(2) {
  display: none;
}
.SettingsApprovalsContainer .Approvals__removeLevel {
  display: inline-block;
  margin-left: 7px;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  position: relative;
  -webkit-transition: opacity 0.2s ease;
  -moz-transition: opacity 0.2s ease;
  -o-transition: opacity 0.2s ease;
  -ms-transition: opacity 0.2s ease;
  transition: opacity 0.2s ease;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  top: 0;
}
.SettingsApprovalsContainer .Approvals__removeLevel:hover {
  cursor: pointer;
}
.SettingsApprovalsContainer .DisabledOverlay {
  position: absolute;
  width: 100%;
  height: calc(100% - 42px);
  background-color: rgba(255,255,255,0.9);
  color: rgba(255,255,255,0.9);
  pointer-events: none;
  z-index: 2;
}
.SettingsApprovalsContainer .DisabledOverlay__icon {
  margin: 0 auto 10px;
}
.SettingsApprovalsContainer .DisabledOverlay__content {
  color: #222;
  margin: 30px auto 0;
  text-align: center;
  max-width: 325px;
}
.SettingsApprovalsContainer .DisabledOverlay__content .cautionCone {
  margin: 0 auto 10px;
}
.SettingsApprovalsContainer .DisabledOverlay__content-action {
  margin-top: 10px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.SettingsApprovalsContainer .Approvals__level[data-level="2"]:before,
.SettingsApprovalsContainer .Approvals__level[data-level="3"]:before,
.SettingsApprovalsContainer .Approvals__level[data-level="4"]:before,
.SettingsApprovalsContainer .Approvals__level[data-level="5"]:before {
  border-bottom: 1px solid #e0e0e0;
  border-left: 1px solid #e0e0e0;
  content: "";
  display: block;
  position: absolute;
  top: -17px;
  left: -7px;
  height: 36px;
  width: 3px;
}
.SettingsApprovalsContainer .Approvals__undo,
.SettingsApprovalsContainer .Settings__undo {
  display: inline-block;
  font-size: 13px;
  margin-left: 25px;
  top: 2px;
}
.SettingsApprovalsContainer .WorkflowsForm {
  margin-top: 20px;
  padding-left: 16px;
}
.SettingsApprovalsContainer .WorkflowsForm hr {
  border-top: none;
}
.SettingsApprovalsContainer .WorkflowsForm h5 {
  font-size: 15px;
  font-weight: 600;
  margin-top: 13px;
  margin-bottom: 5px;
}
.SettingsApprovalsContainer .WorkflowsForm dt {
  font-size: 13px;
  line-height: 1.3;
  margin: 12px 0 5px 0;
}
.SettingsApprovalsContainer .WorkflowsForm div dt {
  margin: 8px 0 20px 8px;
}
.SettingsApprovalsContainer .WorkflowsForm .description {
  color: #999;
  line-height: 1.2em;
  margin-top: 6px;
  margin-bottom: 20px;
}
.SettingsApprovalsContainer .WorkflowsForm hr + .description {
  margin-top: 12px;
}
.SettingsApprovalsContainer .AdvancedWorkflowForm {
  padding-left: 28px;
  padding-right: 28px;
}
.displayOnHover * {
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  -webkit-transition: opacity 0.3s ease;
  -moz-transition: opacity 0.3s ease;
  -o-transition: opacity 0.3s ease;
  -ms-transition: opacity 0.3s ease;
  transition: opacity 0.3s ease;
}
tr:hover .displayOnHover * {
  opacity: 1;
  -ms-filter: none;
  filter: none;
}
.customApprovalFields .selectedLabel__sub {
  color: #686868;
}
.customApprovalFields .selectedLabel__star {
  position: relative;
  top: 3px;
  top: 7px;
  margin-right: 3px;
}
.customApprovalFields .selectedLabel__star:after {
  font-size: 30px;
}
.align-self-start {
  -webkit-align-self: flex-start;
  align-self: flex-start;
  -ms-flex-item-align: start;
}

.GarnishmentPayments__form {
  margin: 20px 0 32px;
}

.Payee__form {
  padding: 0;
}
.subscript {
  color: var(--gray7, #686868);
  font-size: var(--font-size-teenie, 13px);
  margin-top: -8px;
}
.subscript__button {
  font-size: var(--font-size-teenie, 13px);
}
.subscript__button.name {
  margin-top: -8px;
}
.subscript__text {
  padding-top: 6px;
  padding-right: 4px;
}
.subscript__text.name {
  padding-top: 0;
  margin-top: -2px;
}
.edit-modal-subscript__text {
  margin-block: -7px 10px;
}

.PayeeNeeded {
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  background-color: var(--gray1, #f4f4f4);
  border-radius: 2px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  height: 48px;
  margin-bottom: 32px;
  padding: 0 17px;
  width: 100%;
}
.PayeeNeeded--globalPayees {
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  border-bottom: 1px solid;
  border-radius: 2px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  border-color: var(--gray3, #e0e0e0);
  height: 49px;
  padding: 0 16px;
  width: 100%;
  border-bottom: none;
}
.PayeeNeeded--globalPayees:nth-child(even) {
  background-color: var(--gray1, #f4f4f4);
}
.PayeeNeeded__nameAmount {
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-pack: justify;
  -moz-box-pack: justify;
  -o-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  margin-right: 22px;
  width: 185px;
}
.PayeeNeeded__name {
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  margin-right: 22px;
  width: 107px;
}
.PayeeNeeded__name--globalPayees {
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  margin-right: 22px;
  width: 175px;
}
.PayeeNeeded__amount {
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-pack: justify;
  -moz-box-pack: justify;
  -o-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  margin-right: 22px;
  width: 63px;
}
.PayeeNeeded__select {
  margin-right: 31px;
}
.PayeeNeeded__paragraph {
  color: var(--gray10, #222);
  font-size: var(--font-size-small, 14px);
  margin: 0 3px 0 0;
}
.PayeeNeeded__paragraph--globalPayees {
  color: var(--gray10, #222);
  font-size: var(--font-size-medium, 15px);
  margin: 0 3px 0 0;
}
.PayeeNeeded__caseNumber {
  margin: 0 20px;
}
.PayeeNeeded__caseNumber--globalPayees {
  margin-right: 33px;
}
.PayeeNeeded__remittanceId {
  margin-right: 16px;
}
.PayeeNeeded__remittanceId--globalPayees {
  margin-right: 31px;
}
.PayeeNeeded__description {
  margin: 0;
}
.PayeeNeeded__description--childSupport {
  width: calc(100% - 699px);
}
.PayeeNeeded__description--childSupport--globalPayees {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  -ms-box-flex: 1;
  box-flex: 1;
  -webkit-flex-grow: 1;
  flex-grow: 1;
}
.PayeeNeeded__add {
  color: var(--info, #0772b3);
}
.PayeeNeeded__add:hover {
  color: var(--white, #fff);
}
.PayeeNeeded__settingsPage {
  padding-bottom: 0;
}
.PayeeNeeded__settingsPage > label {
  display: none;
}

.PayGroupsSettings__heading {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.PayGroupsSettings__icon {
  fill: var(--theme-base-default, #527a00);
  fill: var(--fabric-theme-base, #527a00);
  margin: 10px 9px 0 0;
}

.PayeeRow__employee-count {
  display: block;
  margin-top: 3px;
}
.PayeeRow__employee-count-link {
  margin-top: -4px;
}
.PayeeRow__employees {
  max-width: 180px;
}
.PayeeRow__actions {
  width: 40px;
}

.Payees__blank-state {
  color: #999;
}
.Payees__blank-state-container {
  padding: 79px 100px;
  text-align: center;
}
.Payees__blank-state-icon {
  fill: #ccc;
}
.Payees__blank-state-subtitle {
  color: #999;
  font-size: var(--font-size-large, 16px);
  line-height: 24px;
  margin-top: -5px;
}
.Payees__blank-state-title {
  color: #999;
  margin-block: -10px 8px;
}
.Payees__table {
  margin-top: 12px;
}

.SetupTourSettings__divider {
  border-top: 1px solid #afafaf;
  margin: 22px 0;
}
.SetupTourSettings__mainText {
  font-size: 15px;
  margin: 10px 0 22px;
}
.SetupTourSettings__subtitle {
  color: var(--theme-base-default, #527a00);
  color: var(--fabric-theme-base, #527a00);
}

.MultiEinBanksTable__group {
  display: inline-block;
}
.MultiEinBanksTable__companyEin {
  font-weight: var(--font-weight-normal, 400);
}
.MultiEinBanksTable__addNew {
  font-weight: var(--font-weight-heavy, 800);
}
.MultiEinBanksTable__bankName {
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: block;
  width: 220px;
}
.BankInfoHeader--noBottom ~ .MultiEinBanksTable {
  margin-top: 23px;
}

.BankAccountForm {
  margin-top: -9px;
  text-align: left;
}
.BankAccountForm--topSpacing {
  padding-top: 3px;
  margin-top: unset;
}
.BankAccountForm__bankLogo {
  vertical-align: middle;
}
.BankAccountForm__bankImage {
  height: 28px;
}
.BankAccountForm__contentBlock {
  padding-bottom: 27px;
  margin-top: 0;
}
.BankAccountForm__verified {
  margin-top: 12px;
}
.BankAccountForm__footerPrimary.fab-Button:disabled + .SiteFooter__action,
.BankAccountForm__footerPrimary.MuiButton-contained:disabled + .SiteFooter__action {
  visibility: hidden !important;
}
.BankAccountForm__plaidDropdown {
  display: block;
  margin-top: 4px;
}

.TaxTypeDetails {
  padding-bottom: 26px;
  border-bottom: 1px solid #e0e0e0;
}
.TaxTypeDetails__sections {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
}
.TaxTypeDetails__sections .TaxTypeDetails__section {
  margin-right: 40px;
  font-size: var(--font-size-small, 14px);
}
.TaxTypeDetails__sections .TaxTypeDetails__section:last-child {
  margin-right: 0;
}
.TaxTypeDetails__sections .TaxTypeDetails__sectionHeader {
  color: var(--gray6, #999);
}

.LoadingPlaceholder {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.LoadingPlaceholder .LoadingPlaceholder__loader {
  margin: 0 15px 0 0;
  width: 20px;
  height: 20px;
}

.TaxIdFormatNote {
  margin-top: 7px;
  font-size: var(--font-size-teenie, 13px);
  line-height: var(--line-height-teenie, 18px);
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.TaxIdFormatNote--error {
  color: var(--danger, #c20b0b);
  fill: var(--gray6, #999);
}
.TaxIdFormatNote--error > span {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  margin-left: 5px;
  cursor: pointer;
}

.TaxIdFormatPopover {
  min-width: 318px;
}

.TaxIdField__inputs {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.TaxTypeField .LoadingPlaceholder {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.TaxTypeField .LoadingPlaceholder .LoadingPlaceholder__loader {
  margin: 0 15px 0 0;
  width: 20px;
  height: 20px;
}

.CompanyTaxField .CompanyTaxField__errorLabel {
  display: none;
}
.CompanyTaxField .fab-Textarea {
  resize: none;
}
.CompanyTaxField .fab-Label__unit {
  margin-left: 4px;
}
.CompanyTaxField .fab-Label .icon-wrapper {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  margin-right: 5px;
  fill: var(--danger, #c20b0b);
}
.CompanyTaxField__content {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.CompanyTaxField__content .CompanyTaxField__additionalContent {
  margin-left: 20px;
}
.CompanyTaxField__label--optional {
  color: var(--gray6, #999);
  margin-left: 5px;
}
.CompanyTaxField--disabled .fab-Label {
  color: var(--gray6, #999);
}
.CompanyTaxField--disabled input,
.CompanyTaxField--disabled textarea {
  border-color: var(--gray3, #e0e0e0);
  pointer-events: none;
}
.CompanyTaxField--disabled input::placeholder,
.CompanyTaxField--disabled textarea::placeholder {
  color: var(--gray5, #adadad);
  opacity: 1;
  -ms-filter: none;
  filter: none; /* Firefox */
}
.CompanyTaxField--disabled input:-ms-input-placeholder,
.CompanyTaxField--disabled textarea:-ms-input-placeholder {
/* Internet Explorer 10-11 */
  color: var(--gray5, #adadad);
}
.CompanyTaxField--disabled input::-ms-input-placeholder,
.CompanyTaxField--disabled textarea::-ms-input-placeholder {
/* Microsoft Edge */
  color: var(--gray5, #adadad);
}
.CompanyTaxField--error {
  color: #f00;
}
.CompanyTaxField--error .CompanyTaxField__errorLabel {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
}
.CompanyTaxField--error .fab-Label {
  color: var(--danger, #c20b0b);
}
.CompanyTaxField--error .fab-Label__unit {
  color: var(--danger, #c20b0b);
}
.CompanyTaxField--error .fab-Label--required::after {
  color: var(--danger, #c20b0b);
}
.CompanyTaxField--error .fab-TextInput {
  border-color: var(--danger, #c20b0b);
}
.CompanyTaxField--employerTaxId .CompanyTaxField__content .fab-TextInput {
  margin-right: 20px;
}
.CompanyTaxField--hidden {
  display: none;
}
.CompanyTaxField__clientIdOption {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
}
.CompanyTaxField__clientIdOption--name {
  margin-right: 3px;
}

.DateSelectOption--notActive .DateSelectOption__date {
  color: var(--gray6, #999);
}
.DateSelectOption__text {
  margin-right: 5px;
}

.CompanySelectOption {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
}
.CompanySelectOption--notActive .CompanySelectOption__ein {
  color: var(--gray6, #999);
}
.CompanySelectOption__name {
  margin-right: 5px;
}
.CompanySelectOption__ein {
  -webkit-flex-shrink: 0;
  flex-shrink: 0;
  max-width: 100%;
}

.TaxesTable {
  margin: 16px 0 21px;
  scroll-margin-top: 150px;
}
.TaxesTable__appliedForCell {
  color: var(--gray7, #686868);
}
.TaxesTable__appliedForCellIcon {
  fill: var(--attention, #bd5800);
}
.TaxesTable__appliedForCellText {
  margin-left: 4px;
}
.TaxesTable__cell--undefined {
  color: var(--gray7, #686868);
}
.TaxesTable__gearDropdown {
  padding-top: 10px;
}
.TaxesTableModal {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  text-align: center;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -o-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -o-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 496px;
  margin: auto;
}
.TaxesTableModal--endTax {
  background-color: var(--gray1, #f4f4f4);
  padding: 20px 0;
}
.TaxesTableModal--endTax .TaxesTableModal__text {
  margin-bottom: 7px;
}
.TaxesTableModal--taxIdReminderModal .TaxesTableModal__text {
  margin-bottom: 20px;
}
.CompanyTaxesDateSelectOption__text {
  margin-right: 5px;
}
.CompanyTaxesDateSelectOption__date {
  color: var(--gray6, #999);
}

.CompanyTaxesWrapper__header {
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
}
.CompanyTaxesWrapper__headerIcon {
  fill: var(--theme-base-default, #527a00);
  fill: var(--fabric-theme-base, #527a00);
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  margin-right: 9px;
}

.CodeRateRow {
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  margin-bottom: 16px;
  position: relative;
  width: 100%;
}
.CodeRateRow--hidden {
  display: none;
}
.CodeRateRow__field--addMargin {
  margin-right: 12px;
}
.CodeRateRow__field--code {
  margin-left: 0 !important;
}
.CodeRateRow__field--checkbox {
  margin: -5px 21px 0 11px;
}
.CodeRateRow__formField {
  margin-left: 8px;
}
.CodeRateRow__remove {
  position: absolute;
  left: -35px;
}
.CodeRateRow__history {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  margin-left: 20px;
  padding-left: 20px;
  position: relative;
}
.CodeRateRow__historyDivider {
  border-left: 1px solid #e0e0e0;
  position: absolute;
  left: 0;
  top: 0;
  height: 50px;
}
.CodeRateRow__historyDivider--last {
  height: 35px;
}
.CodeRateRow__futureChange {
  fill: var(--attention, #bd5800);
  margin-left: 6px;
}
.CodeRateRow__perHourMessage {
  margin-left: 8px;
}

.CodesAndRates {
  text-align: left;
}
.CodesAndRates__cantEdit {
  color: var(--gray7, #686868);
  font-size: var(--font-size-teenie, 13px);
}
.CodesAndRates__filter {
  color: var(--gray7, #686868);
}
.CodesAndRates__filter--selected {
  color: var(--gray10, #222);
  font-weight: var(--font-weight-bold, 700);
}
.CodesAndRates__activeToggle {
  margin-bottom: 7px;
}
.CodesAndRates__labels {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
}
.CodesAndRates__label--active {
  margin-right: 9px;
}
.CodesAndRates__label--code {
  margin-right: 71px;
}
.CodesAndRates__label--codeError {
  margin-right: 51px;
}
.CodesAndRates__label--description {
  margin-right: 156px;
}
.CodesAndRates__label--descriptionError {
  margin-right: 136px;
}
.CodesAndRates__label--employer {
  margin-right: 55px;
}
.CodesAndRates__label--employerError {
  margin-right: 35px;
}
.CodesAndRates__label--employee {
  margin-right: 50px;
}

.ActionButtonsCell {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  height: 48px;
  padding-top: 7px;
}

.DeductionNameCell {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  height: 48px;
}
.DeductionNameCell--name {
  padding-top: 14px;
}
.DeductionNameCell--input {
  padding-top: 7px;
}

.DeductionsTable__blank {
  padding-top: 60px;
}

.AddDeductionRow {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  height: 48px;
  border-bottom: 1px solid #e0e0e0;
  text-align: left;
  padding: 6px 0 0 15px;
}

.DeductionSettings__top {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-pack: justify;
  -moz-box-pack: justify;
  -o-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  padding-top: 6px;
  margin-bottom: 6px;
}
.DeductionSettings__topHeader {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.DeductionSettings__topIcon {
  margin-right: 9px;
  padding-top: 9px;
}
.DeductionSettings__topFilter {
  padding-top: 6px;
}

.StepCheckFeedback {
  border-bottom: 1px solid #e0e0e0;
  color: var(--gray10, #222);
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-pack: justify;
  -moz-box-pack: justify;
  -o-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  height: 52px;
  text-align: left;
}
.StepCheckFeedback__complete {
  fill: var(--gray10, #222);
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.StepCheckFeedback__completeText {
  margin-left: 10px;
}
.StepCheckFeedback__checking {
  color: var(--theme-base-default, #527a00);
  color: var(--fabric-theme-base, #527a00);
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.StepCheckFeedback__checkingText {
  margin-left: -20px;
}
.StepCheckFeedback .dotLoader .StepCheckFeedback__dot {
  background-color: var(--theme-base-default, #527a00);
  background-color: var(--fabric-theme-base, #527a00);
}

.CheckMigrationProgress {
  padding-bottom: 100px;
}
.CheckMigrationProgress__header {
  text-align: left;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  border-bottom: 1px solid #ccc;
  padding-bottom: 6px;
}
.CheckMigrationProgress__headerText {
  margin-left: 8px;
}
.CheckMigrationProgress__message {
  text-align: center;
  padding: 109px 0 45px;
}
.CheckMigrationProgress__messageIcon {
  fill: var(--gray4, #ccc);
}
.CheckMigrationProgress__messageHeadline {
  color: var(--gray6, #999);
}
.CheckMigrationProgress__messageText {
  color: var(--gray6, #999);
  font-size: var(--font-size-large, 16px);
}
.CheckMigrationProgress__finish {
  margin-top: 28px;
}
.CheckMigrationProgress__feedback {
  max-width: 400px;
  margin: 0 auto;
}

.MigrationSectionHead {
  text-align: left;
}
.MigrationSectionHead__upper {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-pack: justify;
  -moz-box-pack: justify;
  -o-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  border-bottom: 1px solid #ccc;
  padding-bottom: 7px;
  margin-bottom: 21px;
}
.MigrationSectionHead__iconAndHeader {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.MigrationSectionHead__headerText {
  margin-left: 10px;
}
.MigrationSectionHead__subtitle {
  color: var(--gray6, #999);
  margin: 5px 0 0 10px;
}
.MigrationSectionHead__upNextText {
  color: var(--gray7, #686868);
  font-weight: var(--font-weight-semibold, 600);
  padding-top: 9px;
}
.MigrationSectionHead__lower {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-pack: justify;
  -moz-box-pack: justify;
  -o-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  padding-top: 33px;
}
.MigrationSectionHead__lowerRight {
  -webkit-box-align: end;
  -moz-box-align: end;
  -o-box-align: end;
  -ms-flex-align: end;
  -webkit-align-items: flex-end;
  align-items: flex-end;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
}
.MigrationSectionHead__progress {
  color: var(--theme-base-default, #527a00);
  color: var(--fabric-theme-base, #527a00);
  margin-top: 7px;
}
.MigrationSectionHead__helpButton {
  margin-left: 24px;
}

.BankBoxContent {
  width: 320px;
}
.BankBoxContent__title {
  color: var(--gray8, #555);
  font-weight: var(--font-weight-medium, 500);
}
.BankBoxContent__title--selected {
  font-weight: var(--font-weight-semibold, 600);
  color: var(--theme-base-default, #527a00);
  color: var(--fabric-theme-base, #527a00);
}
.BankBoxContent__breakout {
  font-size: var(--font-size-teenie, 13px);
  color: var(--gray8, #555);
}
.BankBoxContent__breakoutContainer {
  padding-top: 7px;
}

.FieldSelectBox__displayValue {
  color: var(--gray8, #555);
  text-align: left;
  width: 208px;
}
.FieldSelectBox__displayValue--selected {
  color: var(--theme-base-default, #527a00);
  color: var(--fabric-theme-base, #527a00);
  font-weight: var(--font-weight-bold, 700);
}
.FieldSelectBox__displayValue--disabled {
  color: var(--gray6, #999);
}

.MigrationTable__groupHeader {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: end;
  -moz-box-align: end;
  -o-box-align: end;
  -ms-flex-align: end;
  -webkit-align-items: flex-end;
  align-items: flex-end;
}
.MigrationTable__groupHeaderIcon {
  fill: var(--gray7, #686868);
  margin-right: 10px;
  max-height: 22px;
}
.MigrationTable__groupHeaderIcon--attention {
  fill: var(--attention, #bd5800);
}
.MigrationTable__groupHeaderName {
  font-size: var(--font-size-large, 16px);
}
.MigrationTable__nameCell {
  padding-left: 30px;
}
.MigrationTable__nameCell--subGroup {
  font-size: var(--font-size-small, 14px);
  color: var(--gray7, #686868);
  font-weight: var(--font-weight-semibold, 600);
}

.WageMetadata {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  min-height: 60px;
  text-align: left;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  padding-top: 16px;
}
.WageMetadata__detail {
  margin-bottom: 2px;
}
.WageMetadata__values {
  margin-left: 20px;
}

.LocationMetadata {
  margin-top: 18px;
}
.LocationMetadata--blank {
  margin-top: 4px;
}

.CompanyDeductionMetadata {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  margin-top: 26px;
}
.CompanyDeductionMetadata--blank {
  height: 41px;
}
.CompanyDeductionMetadata__label {
  margin-bottom: 8px;
}
.CompanyDeductionMetadata__labelContainer {
  margin-right: 20px;
}
.CompanyDeductionMetadata__value {
  margin-bottom: 8px;
}

.CompanyEmploymentStatusMetadata {
  margin-top: 14px;
  line-height: var(--line-height-teenie, 18px);
  min-height: 18px;
}

.MappingTableItem {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  border: 1px solid #e0e0e0;
  background-color: var(--gray1, #f4f4f4);
  padding: 15px;
  width: 420px;
  text-align: left;
  min-height: 66px;
}
.MappingTableItem__displayValue {
  height: 34px;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.MappingTable__icon {
  fill: var(--gray2, #ebebeb);
}
.MappingTable__icon--selected {
  fill: var(--gray6, #999);
}

.MigrationSection {
  padding-bottom: 100px;
}
.MigrationSection__tableContainer {
  padding-top: 19px;
}
.MigrationSection__whiteGradient {
  position: fixed;
  bottom: 91px;
  width: 100%;
  height: 100px;
  background: -webkit-linear-gradient(90deg, #fff 0, rgba(255,255,255,0) 100%);
  background: -moz-linear-gradient(90deg, #fff 0, rgba(255,255,255,0) 100%);
  background: -o-linear-gradient(90deg, #fff 0, rgba(255,255,255,0) 100%);
  background: -ms-linear-gradient(90deg, #fff 0, rgba(255,255,255,0) 100%);
  background: linear-gradient(0deg, #fff 0, rgba(255,255,255,0) 100%);
}
.MigrationSection__clientInfo {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  text-align: left;
  padding-bottom: 3px;
}
.MigrationSection__clientName {
  padding-left: 8px;
}

.MigrationErrorModal {
  padding-top: 15px;
}
.MigrationErrorModal__explanation {
  margin-bottom: 30px;
}
.MigrationErrorModal__tableContainer {
  margin-bottom: 39px;
}
.MigrationErrorModal__tables {
  border-top: 1px solid #e0e0e0;
  padding-top: 4px;
}
.MigrationErrorModal__typeExplanation {
  color: var(--attention, #bd5800);
}
.MigrationErrorModal__clientInfo {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  text-align: left;
  padding-bottom: 3px;
}
.MigrationErrorModal__clientName {
  padding-left: 8px;
}

.MigrationTasks__message {
  color: var(--theme-base-default, #527a00);
  color: var(--fabric-theme-base, #527a00);
  margin-top: 19px;
}
.MigrationTasks__details {
  margin-bottom: 30px;
}

.CompanyLogoAndColor .colors__wrapper {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  container-type: inline-size;
}
.CompanyLogoAndColor .colorBox__wrapper {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -o-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-lines: multiple;
  -moz-box-lines: multiple;
  -o-box-lines: multiple;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.CompanyLogoAndColor .colorBox {
  position: relative;
}
.CompanyLogoAndColor .colorBox.selected:after {
  content: '';
  display: block;
  width: 20px;
  height: 20px;
  background: url("data:image/svg+xml;utf8,%3Csvg%20xmlns%3D%27http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%27%20width%3D%2716%27%20height%3D%2716%27%20viewBox%3D%270%200%2016%2016%27%3E%3Cg%20fill%3D%27%23fff%27%3E%3Cpath%20d%3D%27M8%2016C3.6%2016%200%2012.4%200%208%200%203.6%203.6%200%208%200%2012.4%200%2016%203.6%2016%208%2016%2012.4%2012.4%2016%208%2016ZM12%205.4C12.3%204.9%2012.2%204.3%2011.8%204%2011.3%203.7%2010.8%203.8%2010.5%204.3L7.3%209.4%205.3%208C4.8%207.7%204.3%207.8%204%208.3%203.7%208.7%203.8%209.3%204.2%209.6L7.8%2012.2%2012%205.4Z%27%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E") no-repeat;
  position: absolute;
}
.CompanyLogoAndColor .activeColorFrame {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -o-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  margin-right: 20px;
}
.CompanyLogoAndColor .activeColorFrame__icon {
  fill: var(--white, #fff);
}
body:not([data-feature-toggles~="jade"]) .CompanyLogoAndColor .file-wrapper .file-name {
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
}
.CompanyLogoAndColor__tipsHeading {
  display: inline-block;
  font-weight: var(--font-weight-semibold, 600);
  margin-bottom: 10px;
}
.CompanyLogoAndColor__tipsItem {
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  color: #555;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  font-size: var(--font-size-small, 14px);
  margin-bottom: 16px;
}
.CompanyLogoAndColor__tipsItem:last-of-type {
  margin-bottom: 0;
}
.CompanyLogoAndColor__tipsImage {
  margin-right: 16px;
}
.CompanyLogoAndColor__fileInfo {
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
}
.CompanyLogoAndColor__fileInfo-detail {
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  margin-right: 8px;
}
.CompanyLogoAndColor__fileInfo-image {
  margin-right: 8px;
}
.CompanyLogoAndColor__fileWrapper {
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
}
#scaleContainer {
  position: relative;
  top: 0 !important;
}
#scaleContainer:after {
  content: 'Drag to resize';
  display: block;
  width: 90px;
  height: 15px;
  color: var(--gray6, #999);
  position: absolute;
  font-size: var(--font-size-teenie, 13px);
  top: -25px;
  right: -95px;
}
#scaleLogo {
  position: absolute;
}
.ui-resizable-handle.ui-resizable-ne {
  background: #adadad;
  border: solid #999 1px;
  top: -3px;
  right: -3px;
  width: 7px;
  height: 7px;
  cursor: ne-resize;
}
.singleUpload {
  width: 100%;
}
body[data-fabric-theme~="encore"] .FileType__icon {
  margin-right: 8px;
}
body[data-fabric-theme~="encore"] .CompanyLogoAndColor__fileWrapper {
  padding-top: 24px;
}
body[data-fabric-theme~="encore"] .CompanyLogoAndColor__fileInfo-detail {
  color: var(--gray8, #555);
  font-size: var(--font-size-medium, 15px);
}
body[data-fabric-theme~="encore"] .CompanyLogoAndColor__tipsItem {
  color: var(--gray8, #555);
}
body[data-fabric-theme~="encore"] settings-main-heading--extraMarginBottom {
  margin-bottom: 17px;
}
body[data-fabric-theme~="encore"] .fab-FormSection:not(:last-of-type) {
  border-bottom: 0;
}
body[data-fabric-theme~="encore"] .tipsLink {
  margin-bottom: 12px;
}
body[data-fabric-theme~="encore"] .file-wrapper .file-nameText {
  margin-left: 0;
}
body[data-fabric-theme~="encore"] .attach-wrapper {
  margin-right: 8px;
}
body[data-fabric-theme~="encore"] #uploadTips {
  margin-top: 0;
}
body[data-fabric-theme~="encore"] .CompanyLogoAndColor .colors__wrapper {
  margin-bottom: 12px;
  padding-top: 0;
}
body[data-fabric-theme~="encore"] .CompanyLogoAndColor .activeColorFrame {
  border: none;
  border-radius: 16px;
  -webkit-box-shadow: 2px 2px 0 2px rgba(56,48,47,0.05);
  box-shadow: 2px 2px 0 2px rgba(56,48,47,0.05);
  height: 84px;
  width: 84px;
  background-image: none;
  background-color: var(--theme-base-default, #527a00);
  background-color: var(--fabric-theme-base, #527a00);
}
body[data-fabric-theme~="encore"] .CompanyLogoAndColor .colorBox__wrapper {
  height: 84px;
  gap: 4px;
}
@container (min-width: 770px) {
  body[data-fabric-theme~="encore"] .CompanyLogoAndColor .colorBox__wrapper {
    height: 92px;
    gap: 12px;
  }
  body[data-fabric-theme~="encore"] .CompanyLogoAndColor .activeColorFrame {
    height: 92px;
    width: 92px;
  }
}
body[data-fabric-theme~="encore"] .CompanyLogoAndColor .colorBox {
  background-image: none;
  background-color: var(--theme-base-default, #527a00);
  background-color: var(--fabric-theme-base, #527a00);
  border-radius: 8px;
  -webkit-box-shadow: 1px 1px 0 2px rgba(56,49,47,0.03);
  box-shadow: 1px 1px 0 2px rgba(56,49,47,0.03);
  width: 40px;
  height: 40px;
  margin: 0;
}
body[data-fabric-theme~="encore"] .CompanyLogoAndColor .colorBox:nth-child(4n+1) {
  border-radius: 8px;
}
body[data-fabric-theme~="encore"] .CompanyLogoAndColor .colorBox:nth-child(4n) {
  border-radius: 8px;
}
body[data-fabric-theme~="encore"] .CompanyLogoAndColor .colorBox.selected:after {
  top: 12px;
  left: 12px;
}

.HolidayAction__year {
  display: -webkit-inline-box;
  display: -moz-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-box;
  display: inline-flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  margin-right: 8px;
}
.HolidaySettings__header {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.HolidaySettings__headerIcon {
  margin-right: 8px;
}
.HolidayAdd .reqDiv {
  font-size: var(--font-size-teenie, 13px);
  line-height: var(--line-height-teenie, 18px);
  color: var(--theme-base-default, #527a00);
  color: var(--fabric-theme-base, #527a00);
  padding: 4px 0 12px;
}
.HolidayAdd__addDateRange {
  margin-left: 8px;
}
.HolidayAdd__employeeFilterIcon {
  fill: var(--gray7, #686868);
}
.HolidayAdd .CalendarPicker__rangeColumn {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
}
.HolidayAdd .CalendarPicker__rangeSeparator {
  color: var(--gray10, #222);
}
.HolidaySettingsEncoreHeader {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-pack: justify;
  -moz-box-pack: justify;
  -o-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}
.HolidaySettingsEncoreHeader .fab-SectionHeader__titleMedium {
  font-size: 26px;
  line-height: 34px;
}
.HolidaySettingsEncoreHeader .addLinks .HolidayActionHeader {
  display: inline-block;
  margin-right: 12px;
}
.HolidaySettingsEncoreHeader .addLinks .fab-Button:hover {
  text-decoration: none;
}

