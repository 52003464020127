.earlyAccessSubPage hr {
	margin: 20px 0 13px;
}
.earlyAccessSubPage h4 {
	margin-bottom: 12px;
}
.earlyAccessSubPage .fieldRow.check-radio {
	margin-bottom: 5px;
}
.earlyAccessSubPage .ea-section-heading {
	margin: 9px 0 18px;
	font-size: 15px;
}

.earlyAccessSubPage .ea-submit-feedback {
	margin: 9px 0 0;
}

/** table styles **/
.earlyAccessSubPage .ea-table {
	display: table;
	margin: 0 0 20px 16px;
	border-top: solid #dfdfdf 1px;
}
.earlyAccessSubPage .ea-table-row {
	display: table-row;
}
.earlyAccessSubPage .ea-table-cell {
	display: table-cell;
	padding: 12px 0 14px;
	vertical-align: top;
	border-bottom: solid #dfdfdf 1px;
}

.earlyAccessSubPage .ea-table-row > .ea-table-cell:first-child {
	width: 30%;
}