$theme = '--sidebar-theme';

.BaTabs {
	&{$theme} {
		border-top: 1px solid #d3;
		display: flex;
		flex-direction: row;
		align-items: stretch;
	}

	&__tab {
		&{$theme} {
			width: 100%;
		}

		&--active&{$theme} {

		}

		&--disabled&{$theme} {

			&:hover {

			}
		}

		&{$theme}:not(&--active):not(&--disabled) {
			&:hover {

			}
		}

		&Label {
			&--primary&{$theme} {
				display: block;
				position: relative;
				padding: 11px;
				font-size: 15px;
				line-height: 13px;
				margin-top: 7px;
			}

			&--secondary&{$theme} {
				display: none;
			}

			&:not(&--active):not(&--disabled)&{$theme} {
				&:hover {
					text-decoration: underline;
				}
			}

			&--active&{$theme} {
				color: $bhrColors.gray12;
				font-weight: 600;
			}
		}

		&Caret {
			&{$theme} {
				position: absolute;
				top: 50%;
				right: -9px;
				size: 17px;
				background: $colors.white;
				border: 1px solid #d3;
				border-left-color: transparent;
				border-bottom-color: transparent;
				transform: translateY(-50%) scaleY(0.9) rotateZ(45deg);
			}

			&:not(&--active)&{$theme} {
				display: none;
			}
		}

		&Heading {
			position: relative;
		}

		&Icon {
			&{$theme} {
				position: absolute;
				top: calc(50% - 12px);
				right: calc(100% + 8px);

				> svg {
					size: 20px;
				}
			}
		}

		&Wrapper {
			&{$theme} {
				width: 130px;
				border-right: 1px solid #d3;
				flex-direction: column;
			}
		}

		&Content {
			&{$theme} {
				h3 {
					font-size: 16px;
					font-weight: 600;
					margin-bottom: 10px;
				}

				.fieldBox.check-radio {
					margin-bottom: 10px;

					&:last-child {
						margin-bottom: 0;
					}

					label.fileFolder {
						&:before {
							margin-right: 33px;
						}

						&:after {
							content: "";
							display: block;
							position: absolute;
							background-image: url("https://staticfe.bamboohr.com/resources/images/color-branded-icons/sprite-files-icons.png");
							background-position: -3px -61px;
							width: 16px;
							height: 13px;
							top: 3px;
							left: 23px;
							opacity: 0.55;
						}
					}

					input[type="checkbox"] {
						&:checked {
							+ label.fileFolder {
								&:after {
									opacity: 1;
								}
							}
						}

						+ label {
							&:last-of-type {
								+ .checkRadioFormNote {
									margin-top: 6px;
									margin-bottom: 5px;
								}
							}
						}
					}
				}

				.checkRadioFormNote {
					margin-top: 0;
					margin-bottom: 11px;
					font-size: 14px;
					color: $bhrColors.gray9;
					line-height: 18px;

					strong {
						font-weight: 600;
					}
				}
			}

			&Wrapper {
				&{$theme} {
					padding: 20px 0 0 50px;
				}
			}
		}
	}
}
