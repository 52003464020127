.yAxisLabel {
	font-size: 13px;
	line-height: 16;
	fill: #8A8A8A;
	text-align: left;
	width: 100px;

	+encore() {
		fill: var(--gray6);
		font-family: $encoreFontFamilyStack;
	}

	&__label {
		text-transform: uppercase;
		font-size: 13px;
		line-height: 16;
		fill: #8A8A8A;

		+encore() {
			text-transform: capitalize;
			font-family: $encoreFontFamilyStack;
		}
	}
}
