// .SettingsApprovalsContainer .Approvals
.SettingsApprovalsContainer .Approvals {
	// .SettingsApprovalsContainer .Approvals .PathContainer
	.PathContainer {
		padding: 22px 16px 0 26px;
		border: none;
		box-shadow: none;
		margin: 0px;
		padding: 0px;
		background-color: var(--gray05, #F6F6F4);

		// .SettingsApprovalsContainer .Approvals .PathContainer:not(.PathContainer--edit)
		&:not(.PathContainer--edit) {
			border-color: fab-color(white);
		}

		// .SettingsApprovalsContainer .Approvals .PathContainer .showOnEdit
		.showOnEdit {
			display: none;
		}

		// .SettingsApprovalsContainer .Approvals .PathContainer--edit
		&--edit {
			background-color: fab-color(white);
			border-color: fab-color(theme-lighter);
			// .SettingsApprovalsContainer .Approvals .PathContainer--edit .showOnEdit
			.showOnEdit {
				display: block;

				// .SettingsApprovalsContainer .Approvals .PathContainer--edit .showOnEdit.Approvals__removeLevel
				&.Approvals__removeLevel {
					display: inline-block;
				}
			}
		}

		// .SettingsApprovalsContainer .Approvals .PathContainer:last-of-type
		&:last-of-type {
			margin-bottom: 0;
		}
	}
}
