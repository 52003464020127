.ChecklistItem {
	height: 90px;
	width: 500px;
	border: 1px solid #C8C8C8;
	display: flex;
	flex-direction: row;
	align-items: center;
	margin: 10px 0;
	border-radius: var(--radius-300);

	&:hover {
		background-color: #FAFAFA;
	}

	&__checkbox {
		box-sizing: border-box;
		margin: 0 15px 0 21px;

		&-box {
			box-sizing: border-box;
			background-color: #fff;
			border: 1px solid #afafaf;
			border-radius: 3px;
			box-shadow: inset 0 9px 9px -10px #afafaf;
			display: block;
			height: 24px;
			opacity: 0.4;
			position: relative;
			width: 24px;

			&:hover,
			.css_checkMark {
				cursor: default;
			}

			&--pending {
				.css_checkMark {
					opacity: 0;
					position: absolute;
					transition: opacity 0.2s ease;
				}

				&.ChecklistItem__checkbox-box--active:hover {
					.css_checkMark {
						opacity: 1;
					}
				}
			}

			&--active {
				opacity: 1;
			}

			&--active:hover {
				&,
				.css_checkMark {
					cursor: pointer;
				}
			}
		}
	}

	&__title {
		font-size: 16px !important;
		padding-top: 0 !important;
	}

	&__text {
		margin: 1px 0 5px;
	}

	&__link {
		font-weight: 400;
	}
}

.ChecklistItem {
	height: 100px;
	width: 590px;
	border: 1px solid fab-color(gray4);
	display: flex;
	flex-direction: row;
	align-items: center;
	margin: 16px 0;
	border-radius: var(--radius-300);

	&:hover {
		fab-background-color: gray1;
	}

	&__checkbox {
		margin: 0 16px 45px 24px;
	}

	&__title {
		font-weight: 700;
		fab-color: theme-base;
		margin: 2px 0 4px;
		line-height: 18px;
	}

	&__text {
		fab-color: gray7;
		font-size: 14px;
		line-height: 18px;
		margin: 0 0 3px;
	}

	&__link {
		font-size: 14px;
	}
}

{featureToggleOn('time_tracking_trial')} {
	.ChecklistItem {
		height: unset;
		padding: 18px 0;

		&__checkbox {
			align-self: flex-start;
		}
	}
}
