.Badge {

	//.Badge--modalHeader
	&--modalHeader{
		background: fab-color(gray1);
	}

	display: flex;
	// @startCleanup encore
	padding: 0 16px;
	// @endCleanup encore
	+encore() {
		padding: 0 16px 0 0;
	}

	&--noPadding {
		padding: 0;
	}

	//.BadgeContainer
	&Container {

		//.BadgeContainer--modalHeader
		&--modalHeader {
			border-bottom: 1px solid fab-color(gray4);
			margin: 0 32px;
			padding-top: 20px;
		}

		// @startCleanup encore
		min-height: 64px;
		// @endCleanup encore
		+encore() {
			min-height: 48px;
		}

		// Theming for badge
		//.BadgeContainer--newHire
		&--newHire {
			.Badge {
				//.BadgeContainer--newHire .Badge .BadgeImage__image
				&Image__image {
					height: 90px;
					width: 90px;
					margin-right: 20px;
				}

				//.BadgeContainer--newHire .Badge .BadgeText__title
				&Text__title {
					color: fab-color(theme);
					fab-font-size: biggie;
					fab-font-weight: bold;
				}
			}
		}
	}

	//.BadgeImage
	&Image {
		// @startCleanup encore
		height: 64px;
		// @endCleanup encore
		display: inline-flex;
		flex-direction: column;
		justify-content: center;
		margin-right: 13px;
		+encore() {
			height: 48px;
		}

		//.BadgeImage__image
		&__image {
			height: 42px;
			width: 42px;
			border-radius: 50%;
			+encore(){
				border-radius: 25%;
				height: 48px;
				width: 48px;
			}
		}
	}

	//.BadgeText
	&Text {
		color: fab-color(gray7);
		fab-font-size: small;
		fab-line-height: teenie;
		display: inline-flex;
		flex-direction: column;
		justify-content: center;
		min-height: 100%;

		//.BadgeText__title
		&__title {
			color: fab-color(gray10);
			fab-font-size: large;
			fab-font-weight: semibold;
			fab-line-height: small;
		}
	}
}
