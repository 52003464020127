/**
 * Mask's for private data.
 */
.ba-privateContent {
	color: transparent;
	display: inline-block;
	position: relative;
	width: 100%;
	// .ba-privateContent:before
	+legacy() {
		&:before {
			content: '';
			position: absolute;
			width: 32px;
			height: 10px;
			left: 0;
			background-image: url("data:image/svg+xml, %3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20width%3D%2232%22%20height%3D%2210%22%20viewBox%3D%220%200%2032%2010%22%3E%3Cellipse%20cx%3D%225%22%20cy%3D%225%22%20rx%3D%224%22%20ry%3D%224%22%20fill%3D%22%23444%22/%3E%3Cellipse%20cx%3D%2227%22%20cy%3D%225%22%20rx%3D%224%22%20ry%3D%224%22%20fill%3D%22%23A1A1A1%22/%3E%3Cellipse%20cx%3D%2216%22%20cy%3D%225%22%20rx%3D%224%22%20ry%3D%224%22%20fill%3D%22%23737373%22/%3E%3C/svg%3E");
			background-size: 32px 10px;
			background-repeat: no-repeat;
		}
	}
	// .ba-privateContent:after
	&:after {
		color: #2;
		content: attr(data-show-text);
		display: inline-block;
		position: absolute;
		left: 36px;
		font-size: 15px;
		line-height: 20px;

		+jade() {
			fab-color: gray9;
			fab-font-size: teenie;
			fab-line-height: teenie;
		}
	}
	// .ba-privateContent:before,
	// .ba-privateContent:after
	&:before,
	&:after {
		/* @startCleanup encore */
		top: 50%;
		/* @endCleanup encore */
		transform: translateY(-50%);

		+encore() {
			top: 40%;
		}
	}
	// .ba-privateContent:hover
	&:hover {
		cursor: default;
		// .ba-privateContent:hover:after
		&:after {
			cursor: pointer;
		}
	}

	&--icon {
		display: none;
	}

	.ba-privateContent--icon {
		fab-fill: gray7;
		display: inline-flex;
		/* @startCleanup encore */
		position: absolute;
		top: calc(50% - 3px);
		/* @endCleanup encore */

		+encore() {
			position: inherit;
			top: unset;
		}
	}
}
