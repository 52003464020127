.Status {

	&__heading {
		margin: 70px 75px 40px;

		+jade() {
			margin-top: 72px;
			margin-bottom: 35px;
		}

		@media (min-width: 1090px) {
			margin: 70px 110px 40px;
		}
	}

	&__steps {
		background-color: $bhrColors.gray2;
		border-radius: $bhrCommon.border-radius;
		color: darken($colors.emperor, 6.5%);
		line-height: 18px;
		padding: 30px 40px;
		margin: 0 48px;

		+jade() {
			fab-background-color: gray1;
			margin-left: auto;
			margin-right: auto;
			width: 606px;
		}
	}

	&__stepHeading {
		font-family: BhrHeaderFontStack;
		font-size: 16px;
		font-weight: 500;
		margin-bottom: 5px;

		&--success {
			color: $bhrColors.success;
		}
	}

	&__flexElemsJustified {
		display: flex;
		justify-content: space-between;
	}

	&__complete {
		display: flex;

		&Figure {
			margin: -10px 8px -10px -10px; // account for extra space provided by chart so that the slices have room to grow on hover
		}

		&Chart__icon { // specific class within donut svg chart
			display: block;
			margin: 0 auto;
		}

		&Chart__value {
			+encore() {
				color: var(--gray10) !important;
			}

			&Label {
				+encore() {
					color: var(--gray10) !important;
					font-size: var(--font-size-teenie) !important;
					line-height: var(--line-height-teenie) !important;
				}
			}
		}

		&Title {
			font-family: BhrHeaderFontStack;
			font-size: 24px;
			margin-bottom: 13px;
		}

		&SubTitle {
			font-family: BhrHeaderFontStack;
			font-size: 16px;
			margin-bottom: 10px;
		}

		&Text {
			margin-bottom: 24px;
		}
	}
}

{featureToggleOn("jade")} {
	.Status {

		&__complete {

			&Title,
			&SubTitle {
				font-family: unset;
				fab-color: theme-base;
			}

			&Text {
				fab-line-height: medium;
			}
		}

		&__stepDescription {
			fab-color: gray8;
			fab-font-size: small;
			line-height: 17px;

			& + &, // change to this when the feature toggle is gone
			& + .Status__stepDescription {
				margin-top: 4px;
			}
		}

		&__stepHeading {
			fab-color: gray10;
			fab-font-size: large;
			fab-font-weight: semibold;
			font-family: unset;
			line-height: 19px;

			&--success {
				fab-color: success;
				fab-fill: success;
			}
		}
	}
}
