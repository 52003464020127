// #workflowsReactRoot
#workflowsReactRoot {
	width: 100%;
}

.confirmationModal__text {
	color: fab-color(gray9);
	text-align: center;
}

// .SettingsApprovals {
.SettingsApprovals {
	// .SettingsApprovals #inside-content

	// .SettingsApprovals #settings-main-content
	#settings-main-content {
		display: flex;
		min-height: 100%;
		padding: 0;
	}

	// .SettingsApprovals .Approvals__subNav-icon
	.Approvals__subNav-icon {
		fill: $svgColorPalette.gray-3;
		margin-left: -22px;
		margin-top: 1px;
		vertical-align: top;
	}

	// .SettingsApprovals .ba-Select__optionGroup[label] .ba-Select__option
	.ba-Select__optionGroup[label] .ba-Select__option {
		padding-left: 8px;
	}

	// .SettingsApprovals .loader
	.loader {
		z-index: 1;
	}
}

// .SettingsApprovalsTabs
.SettingsApprovalsTabs {
	height: auto;

	// .SettingsApprovalsTabsTitle
	&Title {
		fab-font-weight: semibold;
		fab-line-height: h4;
		margin-left: 0;
		color: fab-color(theme);
		font-size: 18px;

	}

	// .SettingsApprovalsTabs .SettingsApprovalsTabs__tab
	~/ &__tab {
		border-radius: 8px;
			
		// .SettingsApprovalsTabs .SettingsApprovalsTabs__tabLabel--loading
		&Label--loading{
			color: fab-color(gray5);
		}
		// .SettingsApprovalsTabs .SettingsApprovalsTabs__tabGroup
		&Group {
			// .SettingsApprovalsTabs .SettingsApprovalsTabs__tabGroup:last-child
			display: flex;
			flex-direction: column;
			gap: spacing(.5);

			&:last-child {
				border-bottom: none;
			}
		}

		// .SettingsApprovalsTabs .SettingsApprovalsTabs__tabWrapperIcon
		&WrapperIcon {
			fill: $svgColorPalette.gray-3;
		}
	}

	// .SettingsApprovalsTabs .SettingsApprovalsTabs__tabWrapper
	~/ &__tabWrapper {
		width: 22%;
		border-right: none;

		// .SettingsApprovalsTabs .SettingsApprovalsTabs__tabWrapperActions
		&Actions {
			margin-left: auto;
		}

		// .SettingsApprovalsTabs .SettingsApprovalsTabs__tabWrapperHeader
		&Header {
			align-items: center;
			padding: 0;
			margin-bottom: spacing(2.5);
		}

		// .SettingsApprovalsTabs .SettingsApprovalsTabs__tabWrapperTitle
		&Title {
			margin-bottom: 0 !important;
			padding-top: 0 !important;
		}
	}
}

//.SettingsApprovalsContainer
.SettingsApprovalsContainer {
	position: relative;
	position: static;
	padding-bottom: 40px;

	//.SettingsApprovalsContainerHeaderActions
	&HeaderActions {
		margin-top: 13px;
		position: relative;
		top: 4px;
	}

	//.SettingsApprovalsContainerWrapper
	&Wrapper {
		padding-left: 32px;
		padding-top: 0;
	}

	//.SettingsApprovalsContainer .Approvals
	.Approvals {
		//.SettingsApprovalsContainer .Approvals__description
		&__description {
			font-size: 15px;
			margin-bottom: 6px;
			white-space: pre-line;
			margin-top: 0;
		}

		//.SettingsApprovalsContainer .Approvals__section
		&__section {

			//.SettingsApprovalsContainer .Approvals__section-title
			&-title {
				font-size: 15px;
				font-weight: 600;
				margin: 0;
			}

			//.SettingsApprovalsContainer .Approvals__section-text
			&-text {
				color: fab-color(gray7);
				fab-font-size: small;
				fab-line-height: medium;
				margin-bottom: 16px;
			}

			//.SettingsApprovalsContainer .Approvals__section-note
			&-note {
				line-height: 1.3em;
				color: fab-color(gray10);
				fab-font-size: medium;
			}
		}

		//.SettingsApprovalsContainer .Approvals__level
		&__level {
			height: 38px;
			display: flex;
			align-items: center;
			margin-bottom: 20px;

			//.SettingsApprovalsContainer .Approvals__level:hover
			&:hover {
				//.SettingsApprovalsContainer .Approvals__level:hover .Approvals__removeLevel
				.Approvals__removeLevel {
					opacity: 1;
				}
			}

			//.SettingsApprovalsContainer .Approvals__level-mark
			&-mark {
				box-sizing: border-box;
				background-color: transparent;
				border: none;
				border-radius: 50%;
				color: fab-color(white);
				display: inline-block;
				fab-font-size: medium;
				fab-font-weight: semibold;
				height: 26px;
				position: relative;
				text-align: center;
				width: 26px;
				padding-top: 0;
				margin-right: 20px;
				top: 0;
			}

			//.SettingsApprovalsContainer .Approvals__level-mark[data-level="2"]
			//.SettingsApprovalsContainer .Approvals__level-mark[data-level="3"]
			//.SettingsApprovalsContainer .Approvals__level-mark[data-level="4"]
			//.SettingsApprovalsContainer .Approvals__level-mark[data-level="5"]
			for level in 1 2 3 4 {
				&[data-level=\"{level + 1}\"] {
					margin-left: 20px * level;
				}
			}
		}

		// .SettingsApprovalsContainer  .Approvals_customApproval_customApproval
		&_customApproval {
			margin-bottom: 20px;

			// .SettingsApprovalsContainer  .Approvals_customApproval_customApproval .Approvals__section-title
			.Approvals__section-title {
				margin-bottom: 10px;
			}
		}

		//.SettingsApprovalsContainer .Approvals__actions
		&__actions {
			margin-bottom: 20px;
			margin-left: 20px;
		}

		//.SettingsApprovalsContainer .Approvals__actions
		&__head {

			//.SettingsApprovalsContainer .Approvals__actions
			&--action {
				margin-bottom: 12px;
			}
		}

		//.SettingsApprovalsContainer .Approvals__people
		//.SettingsApprovalsContainer .Approvals__group
		&__people,
		&__group {
			display: inline-block;
			opacity: 1;
			vertical-align: middle;
			width: auto;
			z-index: 1;
			margin-left: 12px;
		}

		//.SettingsApprovalsContainer .Approvals
		&__group {
			//.SettingsApprovalsContainer .Approvals .ui-multiselect
			.ui-multiselect {
				span:nth-of-type(2) {
					width: 85%;
					display: inline-block;
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;
				}

				//.SettingsApprovalsContainer .Approvals .ui-multiselect:nth-of-type(2)
				&:nth-of-type(2) {
					display: none;
				}
			}

		}

		//.SettingsApprovalsContainer .Approvals__removeLevel
		&__removeLevel {
			display: inline-block;
			margin-left: 7px;
			opacity: 0;
			position: relative;
			transition: opacity .2s ease;
			user-select: none;
			top: 0;

			//.SettingsApprovalsContainer .Approvals__removeLevel:hover
			&:hover {
				cursor: pointer;
			}
		}
	}

	//.SettingsApprovalsContainer .DisabledOverlay
	.DisabledOverlay {
		position: absolute;
		width: 100%;
		height: calc(100% - 42px);
		background-color: rgba(255, 255, 255, .9);
		color: rgba(255, 255, 255, .9);
		pointer-events: none;
		z-index: 2;

		//.SettingsApprovalsContainer .DisabledOverlay__icon
		&__icon {
			margin: 0 auto 10px;
		}

		//.SettingsApprovalsContainer .DisabledOverlay__content
		&__content {
			color: fab-color(gray10);
			margin: 30px auto 0;
			text-align: center;
			max-width: 325px;

			//.SettingsApprovalsContainer .DisabledOverlay__content .cautionCone
			.cautionCone {
				margin: 0 auto 10px;
			}

			//.SettingsApprovalsContainer .DisabledOverlay__content-action
			&-action {
				margin-top: 10px;
				user-select: none;
			}
		}
	}

	//.SettingsApprovalsContainer .Approvals__level[data-level="2"]:before
	//.SettingsApprovalsContainer .Approvals__level[data-level="3"]:before
	//.SettingsApprovalsContainer .Approvals__level[data-level="4"]:before
	//.SettingsApprovalsContainer .Approvals__level[data-level="5"]:before
	.Approvals__level[data-level="2"]:before,
	.Approvals__level[data-level="3"]:before,
	.Approvals__level[data-level="4"]:before,
	.Approvals__level[data-level="5"]:before {
		border-bottom: 1px solid fab-color(gray3);
		border-left: 1px solid fab-color(gray3);
		content: "";
		display: block;
		position: absolute;
		top: -17px;
		left: -7px;
		height: 36px;
		width: 3px;
	}

	//.SettingsApprovalsContainer .Approvals__undo
	//.SettingsApprovalsContainer .Settings__undo
	.Approvals__undo,
	.Settings__undo {
		display: inline-block;
		font-size: 13px;
		margin-left: 25px;
		top: 2px;
	}

	//.SettingsApprovalsContainer .WorkflowsForm
	.WorkflowsForm {
		margin-top: 20px;
		padding-left: 16px;

		//.SettingsApprovalsContainer .WorkflowsForm hr
		hr {
			border-top: none;
		}

		//.SettingsApprovalsContainer .WorkflowsForm h5
		h5 {
			font-size: 15px;
			font-weight: 600;
			margin-top: 13px;
			margin-bottom: 5px;
		}

		//.SettingsApprovalsContainer .WorkflowsForm dt
		dt {
			font-size: 13px;
			line-height: 1.3;
			margin: 12px 0 5px 0;
		}

		//.SettingsApprovalsContainer .WorkflowsForm div
		div {
			//.SettingsApprovalsContainer .WorkflowsForm div dt
			dt {
				margin: 8px 0 20px 8px;
			}
		}

		//.SettingsApprovalsContainer .WorkflowsForm .description
		.description {
			color: $colors.dusty-gray;
			line-height: 1.2em;
			margin-top: 6px;
			margin-bottom: 20px;
		}

		//.SettingsApprovalsContainer .WorkflowsForm hr + .description
		hr + .description {
			margin-top: 12px;
		}
	}

	//.SettingsApprovalsContainer .AdvancedWorkflowForm
	.AdvancedWorkflowForm {
		padding-left: 28px;
		padding-right: 28px;
	}
}

// Display on Hover
// .displayOnHover *
.displayOnHover * {
	opacity: 0;
	transition: opacity .3s ease;
}
// tr:hover .displayOnHover *
tr:hover .displayOnHover * {
	opacity: 1;
}

// Approval Field Wizard CSS
// .customApprovalFields
.customApprovalFields {
	// .customApprovalFields .selectedLabel__sub
	.selectedLabel__sub {
		color: fab-color(gray7);
	}

	// .customApprovalFields .selectedLabel__star
	.selectedLabel__star {
		position: relative;
		top: 3px;
		top: 7px;
		margin-right: 3px;

		&:after {
			font-size: 30px;
		}
	}
}

.align-self-start {
	align-self: flex-start;
}
