.SettingsUsersEmailValidate {
	display: flex;
	align-items: center;
	justify-content: center;

	&__wrapper {
		width: 588px;
	}

	&__card {
		min-height: 400px;
		padding: 60px;
		padding-bottom: 52px;
		text-align: center;
		box-sizing: border-box;
	}

	&__footer {
		height: 20px;
		color: #8;
		font-size: 12px;
		padding: 0 5px;

		// make the card centered vertically, not the card + footer
		margin-top: -(@height);
		transform: translateY(calc(100% + 10px));
	}

	&__icon {
		&--success {
			margin-top: -12px;
		}
	}

	&__title {
		line-height: 29px;
		margin: 15px 10px 18px;
	}

	&__divider {
		width: 300px;
		margin: 0 auto;
	}

	&__msg {
		margin: 20px 0;
		line-height: 1.33em;

		&:last-of-type {
			margin-bottom: 0;
		}

		&&--email {
			margin: 24px 0 32px;
		}

		&&--small {
			font-size: 14px;
		}

		&&--success {
			margin-top: 24px;
		}
	}

	&__actions {
		padding-top: 35px;
	}
}
