.OneTimeCode {
	// .OneTimeCode__wrapper
	&__wrapper {
		display: flex;
		justify-content: center;
		position: relative;
	}

	&__input {
		height: 0;
		opacity: 0;
		position: absolute;
		width: 0;
	}

	// .OneTimeCode__codeWrapper
	&__codeWrapper {
		display: inline-block;
		position: relative;
	}

	// .OneTimeCode__code
	&__code {
		display: flex;
		justify-content: center;
	}

	// .OneTimeCode__digit
	&__digit {
		height: 55px;
		margin-right: 10px;
		text-align: center;
		width: 50px;
	}

	// .OneTimeCode__digit:last-child
	&__digit:last-child {
		margin-right: 0;
	}

	// .OneTimeCode__digit--chunkMargin
	&__digit--chunkMargin {
		margin-right: 30px;
	}

	// .OneTimeCode__digit span
	&__digit span {
		// h1 styles
		color: fab-color(theme-base);
		display: inline-block;
		font-size: 34px;
		font-weight: 700;
		line-height: 48px;
		transition: color 200ms ease-out;
	}

	// Error styles
	&__code--error &__digit span {
		color: fab-color(danger);
	}
}
