$theme = '--mini-theme';

.BaTabs {
	&__tab {
		&{$theme} {
			background: $bhrColors.gray2;
			color: $bhrColors.gray7;
			border: 1px solid $bhrColors.gray4;
			padding: 8px 11px 6px;
			border-radius: 3px 3px 0 0;
			margin: 0 3px -1px;
			font-size: 14px;
		}

		&--active&{$theme} {
			background: $colors.white;
			border-bottom-color: $colors.white;
			color: $bhrColors.gray11;
			font-weight: 600;
		}

		&--disabled&{$theme} {

			&:hover {
			}
		}

		&{$theme}:not(&--active):not(&--disabled) {
			cursor: pointer;

			&:hover {
				text-decoration: underline;
			}
		}

		&Label {
			&--primary&{$theme} {
				&:hover {
				}
			}

			&--secondary&{$theme} {
			}
		}

		&Wrapper {
			&{$theme} {
				margin: 7px 0 10px;
				border-bottom: 1px solid $bhrColors.gray4;
			}
		}

		&Content {
			&Wrapper {
				&{$theme} {
				}
			}
		}
	}
}
