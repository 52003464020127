.MigrationSectionHead {
	text-align: left;

	// .MigrationSectionHead__upper
	&__upper {
		display: flex;
		justify-content: space-between;
		align-items: center;
		border-bottom: 1px solid fab-color(gray4);
		padding-bottom: 7px;
		margin-bottom: 21px;
	}

	// .MigrationSectionHead__iconAndHeader
	&__iconAndHeader {
		display: flex;
		align-items: center;
	}

	// .MigrationSectionHead__headerText
	&__headerText {
		margin-left: 10px;
	}

	// .MigrationSectionHead__subtitle
	&__subtitle {
		fab-color: gray6;
		margin: 5px 0 0 10px;
	}

	// .MigrationSectionHead__upNextText
	&__upNextText {
		fab-color: gray7;
		fab-font-weight: semibold;
		padding-top: 9px;
	}

	// .MigrationSectionHead__lower
	&__lower {
		display: flex;
		justify-content: space-between;
		padding-top: 33px;

		// .MigrationSectionHead__lowerRight
		&Right {
			align-items: flex-end;
			display: flex;
		}
	}

	// .MigrationSectionHead__progress
	&__progress {
		fab-color: theme;
		margin-top: 7px;
	}

	// .MigrationSectionHead__helpButton
	&__helpButton {
		margin-left: 24px;
	}
}
