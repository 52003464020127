.HelpPopover {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	right: -20px;
	margin-left: 10px;
	background-color: #B6B6B6;
	color: white;
	height: 14px;
	width: 14px;
	border-radius: 100%;
	font-size: 12px;
	cursor: pointer;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;

	/* .HelpPopover--addTop */
	&--addTop {
		top: 8px;
	}
}

.EncoreHelpPopover {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	right: -20px;
	margin-left: 10px;
	height: 14px;
	width: 14px;
	border-radius: 100%;
	font-size: 12px;
	cursor: pointer;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;

	/* .HelpPopover--addTop */
	&--addTop {
		top: 8px;
	}
}
