/* AdvancedApprovalsNewPathContainer */
.AdvancedApprovalsNewPathContainer {
	border: none;
	box-shadow: none;
	margin: 0px;
	padding: 0px;

	/* AdvancedApprovalsNewPathContainer__actions-container */
	&__actions-container {
		display: flex;
		margin: 16px 0px 0px 36px;
		/*
			AdvancedApprovalsNewPathContainer__actions-container__buttons,
			AdvancedApprovalsNewPathContainer__actions-container__select
		 */
		&__buttons,
		&__select {
			display: flex;
			flex: 0.5;
		}

		/* AdvancedApprovalsNewPathContainer__actions-container__buttons .btn */
		&__buttons button {
			margin-right: 16px;
        }

		/* AdvancedApprovalsNewPathContainer__actions-container__select */
		&__select {
			width: 0px;
			margin-right: 16px;

			/* AdvancedApprovalsNewPathContainer__actions-container__select__toggle */
			&__toggle {
				color: fab-color(gray10);
			}

		}
	}


	/* .AdvancedApprovalsNewPathContainer__icon-container */
	&__icon-container {
		min-width: 0px;

		/* .AdvancedApprovalsNewPathContainer__icon-container .icon*/
		.icon {
			display: flex;
			justify-content: center;
		}
	}

	/*
		.AdvancedApprovalsNewPathContainer__icon-container,
		AdvancedApprovalsNewPathContainer__title
	 */
	&__icon-container,
	&__title {
		display: flex;
		justify-content: center;
	}

	/* AdvancedApprovalsNewPathContainer__title */
	&__title {
		color: fab-color(theme);
		position: relative;
		margin-left: 12px;
		font-family: Inter;
		font-size: 16px;
		font-weight: 500;
		line-height: 24px;
		text-align: left;
		top: 0px;
	}
}
