.EEO1Description {
	margin-bottom: 15px;

	&__toggle,
	&__toggle:active,
	&__toggle:focus,
	&__toggle:hover,
	&__toggle:visited {
		font-size: 13px;
	}

	&__caret {
		margin-right: 8px;

		svg {
			fill: var(--link);
			transition: transform 0.3s ease;
		}

		&--isRotatedDown svg {
			transform: rotate(90deg);
		}
	}

	&__body {
		display: none;
		margin-left: 10px;
		&--isVisible {
			display: block;
		}
	}

	&__paragraph {
		margin: 10px 0;
		fab-font-size: small;
	}
}
