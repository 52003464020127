.ApproverClockOut {
	box-sizing: border-box;

	/* .ApproverClockOut__warning */
	&__warning {
		display: flex;
		flex-direction: column;
		align-items: center;
		text-align: center !important;
		padding: 0 0 10px;

		/* ApproverClockOut__warningIcon */
		&Icon {
			color: $bhrColors.warning1;
			fab-fill: attention;
			margin-bottom: 0;
		}

		/* ApproverClockOut__warningHeader */
		&Header {
			margin-bottom: 4px;
		}

		/* ApproverClockOut__warningText */
		&Text {
			font-size: 14px;
			line-height: 21px;
			text-align: center !important;
			fab-color: gray7;
		}
	}
}
