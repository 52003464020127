.SettingsTraining {

	&__blankState {
		border-top: solid 1px;
		fab-border-color(gray6);
		fab-color(gray6);
		fab-fill(gray6);
		padding-top: 72px;
		text-align: center;

		&Text {
			fab-font-size(large);

			&--heading {
				fab-color(gray6);
			}
		}
	}

	&__required {
		text-align: center;
	}

	&__category {
		&Ico {
			fill: #89;
			margin-right: 3px;

			svg {
				transform: translateY(2px);
			}
		}

	+jade() {
		fab-background(gray1);
		padding: 9px 0;

		&:first-child {
			padding-left: 16px;
		}

		&:last-child {
			padding-right: 16px;
		}

		&Icon {
			fab-fill(gray7);
		}

	}

	}

	&__addCategory {
		&Ico {
			fill: #89;
			margin-right: 3px;
			overflow: inherit;

			> svg {
				transform: translateY(4px);
			}
		}
	}

	&__modalMessage {
		text-align: center;
	}

	&__modal {

		&Checkboxes {
			margin-top: 15px;
		}

		&.deleteModal {
			margin: 0 0 0 0;
		}
	}

	&__tableLabel {
		+jade() {
			color: inherit;
			fab-font-weight('bold');
			font-size: inherit;
		}
	}

}
