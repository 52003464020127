.TaxTypeDetails {
	padding-bottom: 26px;
	border-bottom: 1px solid fab-color(gray3);

	// .TaxTypeDetails__sections
	&__sections {
		display: flex;

		.TaxTypeDetails__section {
			margin-right: 40px;
			fab-font-size: small;

			// .TaxTypeDetails__section:last-child
			&:last-child {
				margin-right: 0;
			}

			// .TaxTypeDetails__sectionHeader
			&Header {
				fab-color: gray6;
			}
		}
	}
}
