// .PreviewEmail
.PreviewEmail {
	padding-top: 0;
	// .PreviewEmail-header_address
	&-header {
		margin-bottom: 32px;
		//.PreviewEmail-header_address
		&_address {
			fab-text: small;
			fab-color: gray7;
			font-weight: 700;
			// .PreviewEmail-header_address
			&--light {
				font-weight: 500;
			}
		}
		padding-bottom: 16px;
		border-bottom: solid 1px var(--gray2);
	}
	& table {
		tr {
			background-color: transparent;
		}
		&.baseBgColor {
			fab-background-color: theme;
		}
		& .baseBgColor {
			fab-background-color: theme;
		}
		& .baseBorderColor {
			fab-background-color: theme;
		}
	}
	& .missingPlaceholder {
		fab-color: danger;
	}
}

// Placeholder chips for the draft offer are wrapped around this class
.OfferLetter__draftPlaceholder {
	font-family: 'Lato';
	fab-text: teenie;
	fab-font-weight: bold;
	fab-color: gray8;
	display: inline-block;
	fab-background-color: gray3;
	padding: 0 4px;
    border-radius: 2px;
}
