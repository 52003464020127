.LoadingPlaceholder {
	display: flex;
	align-items: center;

	.LoadingPlaceholder__loader {
		margin: 0 15px 0 0;
		width: 20px;
		height: 20px;
	}
}
