.LinkedSelects {
	/* .LinkedSelects__select */
	&__select {
		margin-top: 4px;
	}
	/* .LinkedSelects__labelHelpContainer */
	&__labelHelpContainer {
		display: flex;
		align-items: center;
	}
}
