.settings-sub,
#settings-main-content {
	transition: opacity 0.2s linear;
}

.Settings {
	&__subNav {
		opacity: 0;
		left: 0;

		&-label {
			font-size: 13px !important;
			color: #888;
			font-weight: normal;
		}
	}
}
