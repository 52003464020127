.Payees {
    &__blank-state {
        color: fab-color(gray6);
    }
    /* Payees__blank-state-container */
    &__blank-state-container {
        padding: 79px 100px;
        text-align: center
    }
    /* Payees__blank-state-icon */
    &__blank-state-icon {
        fill: fab-color(gray4);
    }
    /* Payees__blank-state-subtitle */
    &__blank-state-subtitle {
        color: fab-color(gray6);
        fab-font-size: large;
        line-height 24px;
        margin-top: -5px;
    }
    /* Payees__blank-state-title */
    &__blank-state-title {
        color: fab-color(gray6);
        margin-block: -10px 8px;
    }
	/* Payees__table */
	&__table {
		margin-top: 12px;
	}
}
