$theme = '--horizontal-theme';

.BaTabs {
	&__tab {
		&{$theme} {
			white-space: nowrap;
		}

		&--active&{$theme} {

		}

		&--disabled&{$theme} {

			&:hover {

			}
		}

		&{$theme}:not(&--active):not(&--disabled) {
			&:hover {

			}
		}

		&--divider&{$theme} {
			border-top: 1px solid $bhrColors.gray4;
			padding: 2px 0 0;
			margin-top: 2px;
		}

		&OverflowWrapper {
			&{$theme} {
				top: 100%;
				right: 0;
				padding: 2px 0;
				border: 1px solid #af;
				border-radius: 3px;
				width: 160px;
				background: $colors.white;
				z-index: 99;
				overflow-y: auto;
				max-height: 300px;
			}
		}

		&Label {
			&{$theme} {
				padding: 16px 14px 3px;
				font-size: 15px;
				font-weight: 600;
				line-height: 19px;
			}

			&--disabled&{$theme} {
				color: #a;
			}

			&{$theme}:not(&--active):not(&--disabled) {
				color: $bhrColors.gray12;

				&:hover {
					background: $bhrColors.gray4;
				}
			}

			&--active&{$theme} {
				&:before {
					content: "";
					display: block;
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 5px;
					background: #46;

					.BaTabs__tab--overflow .BaTabs__tab > & {
						width: 4px;
						height: 27px;
					}
				}
			}

			&--primary&{$theme} {
				.BaTabs__tab--overflow > & {
					&:after {
						content: "";
						display: inline-block;
						position: relative;
						top: 4px;
						margin-left: 3px;
						border: 5px solid transparent;
						border-top-color: $bhrColors.gray12;
					}
				}

				.BaTabs__tab--overflow .BaTabs__tab > & {
					max-width: 200px;
					padding: 4px 11px;
					text-overflow: ellipsis;
				}
			}

			&--secondary&{$theme} {
				display: none;
			}
		}

		&Icon {
			&{$theme} {
				display: none;
			}
		}

		&Wrapper {
			&{$theme} {

			}
		}

		&Content {
			&Wrapper {

			}
		}
	}
}
