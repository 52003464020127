// .ReminderModal
.ReminderModal {
	// .ReminderModal__body
	&__body {
		fab-color: gray9;
		// .ReminderModal__body--block
		&--block {
			margin-top: 20px;
		}
	}
	// .ReminderModal__content
	&__content {
		text-align: center;
	}
	// .ReminderModal__header
	&__header {
		margin-bottom: 20px;
	}
}
