.earlyAccessSubPage hr {
  margin: 20px 0 13px;
}
.earlyAccessSubPage h4 {
  margin-bottom: 12px;
}
.earlyAccessSubPage .fieldRow.check-radio {
  margin-bottom: 5px;
}
.earlyAccessSubPage .ea-section-heading {
  margin: 9px 0 18px;
  font-size: 15px;
}
.earlyAccessSubPage .ea-submit-feedback {
  margin: 9px 0 0;
}
.earlyAccessSubPage .ea-table {
  display: table;
  margin: 0 0 20px 16px;
  border-top: solid #dfdfdf 1px;
}
.earlyAccessSubPage .ea-table-row {
  display: table-row;
}
.earlyAccessSubPage .ea-table-cell {
  display: table-cell;
  padding: 12px 0 14px;
  vertical-align: top;
  border-bottom: solid #dfdfdf 1px;
}
.earlyAccessSubPage .ea-table-row > .ea-table-cell:first-child {
  width: 30%;
}
.brandedBar {
  position: relative;
  height: 70px;
  padding: 20px 0 0;
}
.brandedBar:after {
  content: '';
  display: block;
  width: 100%;
  height: 15px;
  position: absolute;
  bottom: 0;
  background-color: rgba(255,255,255,0.5);
}
.brandedBar #contentTop h2 {
  color: #fff;
  margin-top: 0;
}
.frown:before {
  content: '';
  display: inline-block;
  width: 29px;
  height: 27px;
  margin-right: 7px;
  position: relative;
  top: 4px;
  background: url("https://staticfe.bamboohr.com/resources/images/account/frown.png") no-repeat;
}
.statusMessage {
  width: 90%;
  margin: 150px auto 150px;
}
.statusMessage > img {
  float: left;
  margin-right: 50px;
  vertical-align: top;
  margin-top: 8px;
}
.statusMessage > img.horn {
  margin-top: -26px;
}
.statusMessage > img.suspended {
  margin-top: 0;
}
.statusMessage h2 {
  font-weight: 400;
  font-size: 36px;
  margin-bottom: 13px;
  line-height: 1em;
}
.statusMessage p {
  font-size: 18px;
}
.statusMessage > p:last-child {
  margin-top: 28px;
}
.StorageLimit button.button--link {
  background: none;
  border: none;
  color: #006ec2;
  font-size: 12px;
  margin-left: 10px;
  padding: 0;
}
.StorageLimit button.button--link .button--text {
  position: relative;
}
.StorageLimit button.button--link:hover {
  cursor: pointer;
  text-decoration: underline;
}
.StorageLimit__labelIcon .StorageLimit__icon {
  margin-right: 5px;
}
.StorageLimit__percentage {
  display: none;
  font-family: Source Sans Pro;
  font-size: 14px;
  line-height: 14px;
  position: absolute;
  top: 0;
  left: 160px;
}
.StorageLimit__progressBar {
  position: relative;
}
.StorageLimit--requested button.button--link {
  color: #858585;
}
.StorageLimit--requested button.button--link:hover {
  cursor: default;
  text-decoration: none;
}
.StorageLimit--caution.StorageLimit--requested .StorageLimit__progressBar .ProgressBar,
.StorageLimit--warn.StorageLimit--requested .StorageLimit__progressBar .ProgressBar,
.StorageLimit--full.StorageLimit--requested .StorageLimit__progressBar .ProgressBar {
  -webkit-animation: none;
  -moz-animation: none;
  -o-animation: none;
  -ms-animation: none;
  animation: none;
}
.StorageLimit--caution .StorageLimit__progressBar .ProgressBar,
.StorageLimit--warn .StorageLimit__progressBar .ProgressBar,
.StorageLimit--full .StorageLimit__progressBar .ProgressBar {
  -webkit-animation-name: pulse;
  -moz-animation-name: pulse;
  -o-animation-name: pulse;
  -ms-animation-name: pulse;
  animation-name: pulse;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  -o-animation-iteration-count: infinite;
  -ms-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}
.StorageLimit--caution .StorageLimit__percentage,
.StorageLimit--warn .StorageLimit__percentage,
.StorageLimit--full .StorageLimit__percentage {
  display: block;
}
.StorageLimit--caution .StorageLimit__header {
  color: #d17f00 !important;
  font-size: 14px;
}
.StorageLimit--caution .StorageLimit__labelIcon svg {
  fill: #d17f00;
}
.StorageLimit--caution .StorageLimit__progressBar .ProgressBar {
  -webkit-animation-duration: 2s;
  -moz-animation-duration: 2s;
  -o-animation-duration: 2s;
  -ms-animation-duration: 2s;
  animation-duration: 2s;
}
.StorageLimit--caution .StorageLimit__percentage {
  color: #d17f00;
}
.StorageLimit--warn .StorageLimit__header,
.StorageLimit--full .StorageLimit__header {
  color: #b5320a !important;
  font-size: 14px;
}
.StorageLimit--warn .StorageLimit__labelIcon svg,
.StorageLimit--full .StorageLimit__labelIcon svg {
  fill: #b5320a;
}
.StorageLimit--warn .StorageLimit__percentage,
.StorageLimit--full .StorageLimit__percentage {
  color: #b5320a;
}
.StorageLimit--warn .StorageLimit__progressBar .ProgressBar {
  -webkit-animation-duration: 1.25s;
  -moz-animation-duration: 1.25s;
  -o-animation-duration: 1.25s;
  -ms-animation-duration: 1.25s;
  animation-duration: 1.25s;
}
.StorageLimit--full .StorageLimit__progressBar .ProgressBar {
  -webkit-animation-name: pulse;
  -moz-animation-name: pulse;
  -o-animation-name: pulse;
  -ms-animation-name: pulse;
  animation-name: pulse;
  -webkit-animation-duration: 0.75s;
  -moz-animation-duration: 0.75s;
  -o-animation-duration: 0.75s;
  -ms-animation-duration: 0.75s;
  animation-duration: 0.75s;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  -o-animation-iteration-count: infinite;
  -ms-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}
.StorageLimitModal__icon {
  fill: #858585;
  margin-bottom: 15px;
  text-align: center;
}
.StorageLimitModal__text {
  color: #555;
  font-size: 17px;
  line-height: 24px;
  max-width: 387px;
  text-align: center;
}
@-moz-keyframes pulse {
  0% {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
  50% {
    opacity: 0.4;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=40)";
    filter: alpha(opacity=40);
  }
  100% {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
}
@-webkit-keyframes pulse {
  0% {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
  50% {
    opacity: 0.4;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=40)";
    filter: alpha(opacity=40);
  }
  100% {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
}
@-o-keyframes pulse {
  0% {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
  50% {
    opacity: 0.4;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=40)";
    filter: alpha(opacity=40);
  }
  100% {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
}
@keyframes pulse {
  0% {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
  50% {
    opacity: 0.4;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=40)";
    filter: alpha(opacity=40);
  }
  100% {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
}
:where(body[data-fabric-theme~="encore"]) .ChangeOwnerFormSection {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  -ms-box-flex: 1;
  box-flex: 1;
  -webkit-flex-grow: 1;
  flex-grow: 1;
}
