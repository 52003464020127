.SettingsApps {
	&__appItem {
		display: flex;
		align-items: center;
		// startCleanup encore
		padding: 10px 0;
		// endCleanup encore

		+encore() {
			padding: 32px 0 0 0;
		}

		&Pub {
			&Wrapper {
				size: 215px 130px;
				text-align: center;
				border: 1px solid $bhrColors.gray5;
				border-radius: 3px;
				box-sizing: border-box;
				padding: 17px 0;
			}

			&Logo {
				width: 150px;

				&Wrapper {
					display: flex;
					align-items: center;
					justify-content: space-around;
					height: 75px;

					&:only-child {
						height: 100%;
					}
				}
			}

			&Msg {
				display: block;
				font-size: 13px;
				color: #7;
			}

			&Name {

			}
		}

		&DetailWrapper {
			display: flex;
			flex-direction: column;
			padding-left: 28px;
		}

		&Title {
			font-weight: 500;
			color: #2;
			font-size: 18px;
			line-height: 21px;
			margin-bottom: 3px;
		}

		&Installed {
			font-size: 13px;
			line-height: 17px;
			color: #7;
		}

		&Actions {
			padding-top: 16px;
		}

		&Action {
			margin: 0;

			+legacy() {
				display: inline-block;
			}

			& + & {
				margin-left: 10px;
			}


			&--install {

			}

			&--uninstall {

			}

			&--config {

			}

			&--learnMore {

			}
		}
	}
}