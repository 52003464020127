.FloatingCard {
	width: 100%;

	&__body {
		/* @startCleanup encore */
		+jadeFabricTheme() {
			background-color: white;
			border-bottom-style: solid;
			border-image-slice: 0 0 2 0;
			border-image-source: linear-gradient(90deg, #c0e94f 0, #82af13 100%); // For IE11
			border-image-source: linear-gradient(90deg, var(--fabric-theme-lightest), var(--fabric-theme-base));
			border-radius: 2px;
			border-width: 0 0 3px 0;
			box-shadow: 0 8px 14px 3px rgba(0, 0, 0, 0.05);
			text-align: left;
		}
		/* @endCleanup encore */
		
		+encore() {
			background-color: var(--white);
			border-radius: var(--radius-200);
			box-sizing: border-box;
			display: inline-grid;
			gap: 8px;
			padding: 12px;
			width: 100%;
		}
	}

	&__footer {
		height: 100%;
		align-items: center;
		display: flex;
		font-size: 12px;
		justify-content: space-between;
		+jadeFabricTheme() {
			fab-color: gray6;
		}
	}

/* @startCleanup encore */
	&__footerLock {
		padding-right: 4px;
		fab-fill: gray6;
	}
/* @endCleanup encore */

	&__footerLogo {
		padding-left: 4px;
		vertical-align: bottom;
		fab-fill: gray6;
	}

	&__poweredBy {
		margin-top: 16px;
	}

	&__privacy {
		margin-top: 12px;
	}
}


@media only screen and (max-width: 800px){
	.FloatingCard {
		width: calc(100% - 30px);
		margin: 15px;

		&__footer {
			flex-direction: column-reverse;

			+encore() {
				flex-direction: column;
			}
		}

		&__privacy {
			margin-top: 0;

			+encore() {
				margin-top: 20px;
			}
		}
	}
}
