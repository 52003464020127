.DynamicForm__pending {
    display: none;
    position: absolute;
    right: 6px;
    top: 3px;
    z-index: 2;

    &--icon {
        display: none;
        fill: fab-color(gray5);
        float: right;
        position: absolute;
        right: 6px;
        top: 8px;
        z-index: 2;
    }

    &.&--container {
        position: relative;

        &--icon {
            float: none;
            left: 4px;
            right: 0;
            top: -2px;
        }
    }
}

.fieldBox {
    &:hover {
        .DynamicForm__pending--icon {
            display: block;
        }
        .DynamicForm__pending {
            display: block;
        }
    }
}