elem = BEM('BaTabs', 'fab-side-theme');
sub = BEM('BaTabs', 'fab-side-theme fab-side-theme_sub');

{elem('^')} {
	display: flex;
	flex-direction: row;
}

{elem('tabWrapper')} {
	flex-direction: column;
	align-items: stretch;
	flex: 0 0 240px;
	fab-background-color: gray1;
	padding: 18px 16px;
}

{sub('tabWrapper')} {
	fab-background-color: 'white';
	// @startCleanup encore
	flex-basis: 224px;
	padding: 16px;
	// @endCleanup encore

	+encore() {
		gap: spacing(0.5);
		flex-basis: 200px;
		padding: 0;
	}
}

{elem('tab')} {
	padding: 10px 12px;
	border-radius: 4px;
	fab-text: medium;
	align-items: center;

	&:not(.BaTabs__tab--disabled):not(.BaTabs__tab--active):not(.BaTabs__tab--loading):hover {
		fab-color: theme;
		fab-fill: theme;
		fab-background-color: 'white';
	}

	+ {elem('tab')} {
		margin-top: -2px;
	}
}

{elem('tab', 'active')} {
	fab-color: theme;
	fab-fill: theme;

	+encore() {
		border-radius: 8px;
	}
}

{sub('tab')} {
	// @startCleanup encore
	padding: 7px 12px;
	// @endCleanup encore

	+encore() {
		padding: spacing(1) spacing(1.5);
	}

	/{sub('tab', 'active')},
	&:not(.BaTabs__tab--disabled):not(.BaTabs__tab--active):not(.BaTabs__tab--loading):hover {
		fab-background-color: gray1;

		+encore() {
			border-radius: 8px;
		}
	}
}

{elem('tabIcon')} {
	&,
	> svg {
		size: 20px;
	}

	+ {elem('tabLabel')} {
		margin-left: 11px;
	}
}

{sub('tabIcon')} {
	// @startCleanup encore
	display: none;
	
	+ {elem('tabLabel')} {
		margin-left: 0;
	}
	// @endCleanup encore

	+encore() {
		display: block;

		+ {elem('tabLabel')} {
			margin-left: spacing(1.5);
		}
	}
}

{elem('tabGroup', 'empty')} {
	display: none;
}

{elem('tabGroup')} ~ {elem('tabGroup')},
{elem('tab')} + {elem('tabGroup')} {
	border-top: 1px solid fab-color(gray3);
	margin-top: 10px;
}

{elem('tabWrapperHeader')} + {elem('tabGroup')} > {elem('tabGroupHeader')}:empty {
	display: none;
}

{elem('tabGroupHeader')} {
	&:not(:empty) {
		padding: 12px 16px 4px;
	}

	&:empty {
		padding-top: 12px;
	}
}

{sub('tabGroupHeader')} {
	&:not(:empty) {
		padding: 12px 12px 4px;
	}

	&:empty {
		padding-top: 10px;
	}
}

{elem('tabGroupLabel')} {
	fab-color: gray7;
	fab-text: teenie;
}

+encore() {
	{sub('tabLabel')} {
		fab-text: small;
	}
}

{elem('tabContent')} > {sub('^')} {
	height: 100%;
}