/* Styles for stale data modal */
.TimeTrackingWarningModal {
	text-align: center;
	width: 100%;
	padding: 0;

	/* .TimeTrackingWarningModal__icon */
	&__icon {
		fab-fill: attention;
		margin-bottom: 24px;
	}

	/* .TimeTrackingWarningModal__paragraph */
	&__paragraph {
		/* .TimeTrackingWarningModal__paragraph + .TimeTrackingWarningModal__paragraph */
		& + & {
			margin-top: 16px;
		}
	}
}
