.DynamicForm--taxInformation {
    position: relative;

    &__delete {
        position: absolute;
        right: 0;
        top: 0;
        z-index: 9999;
    }

    &.DynamicForm--error {
        legend {
            fab-color(danger);
        }
    }
}
