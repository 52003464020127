

	.CompanyLogoAndColor {

		// .CompanyLogoAndColor .colors__wrapper
		.colors__wrapper {
			display: flex;
			container-type: inline-size;

		}

		// .CompanyLogoAndColor .colorBox__wrapper
		.colorBox__wrapper {
			display: flex;
			flex-direction: column;
			flex-wrap: wrap;
		}

		// .CompanyLogoAndColor .colorBox
		.colorBox {
			position: relative;

			// .CompanyLogoAndColor .colorBox__wrapper.selected:after
			&.selected:after {
				content: '';
				display: block;
				width: 20px;
				height: 20px;
				background: url("data:image/svg+xml;utf8,%3Csvg%20xmlns%3D%27http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%27%20width%3D%2716%27%20height%3D%2716%27%20viewBox%3D%270%200%2016%2016%27%3E%3Cg%20fill%3D%27%23fff%27%3E%3Cpath%20d%3D%27M8%2016C3.6%2016%200%2012.4%200%208%200%203.6%203.6%200%208%200%2012.4%200%2016%203.6%2016%208%2016%2012.4%2012.4%2016%208%2016ZM12%205.4C12.3%204.9%2012.2%204.3%2011.8%204%2011.3%203.7%2010.8%203.8%2010.5%204.3L7.3%209.4%205.3%208C4.8%207.7%204.3%207.8%204%208.3%203.7%208.7%203.8%209.3%204.2%209.6L7.8%2012.2%2012%205.4Z%27%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E") no-repeat;
				position: absolute;
			}
		}

		// .CompanyLogoAndColor .activeColorFrame
		.activeColorFrame {
			display: flex;
			align-items: center;
			justify-content: center;
			margin-right: 20px;

			// .CompanyLogoAndColor .activeColorFrame__icon
			&__icon {
				fab-fill: white;
			}
		}


		+legacy() {
			// .CompanyLogoAndColor .file-wrapper
			.file-wrapper {

				// .CompanyLogoAndColor .file-wrapper .file-name
				.file-name {
					align-items: center;
					display: flex;
				}
			}
		}

		// .CompanyLogoAndColor__tips
		&__tips {

			// .CompanyLogoAndColor__tipsHeading
			&Heading {
				display: inline-block;
				fab-font-weight: semibold;
				margin-bottom: 10px;
			}

			// .CompanyLogoAndColor__tipsItem
			&Item {
				align-items: center;
				color: fab-color(gray8);
				display: flex;
				fab-font-size: small;
				margin-bottom: 16px;

				// .CompanyLogoAndColor__tipsItem:last-of-type
				&:last-of-type {
					margin-bottom: 0;
				}
			}

			// .CompanyLogoAndColor__tipsImage
			&Image {
				margin-right: 16px;
			}
		}

		// .CompanyLogoAndColor__fileInfo
		&__fileInfo {
			align-items: center;
			display: flex;

			// .CompanyLogoAndColor__fileInfo-detail
			&-detail {
				align-items: center;
				display: flex;
				margin-right: 8px;
			}

			// .CompanyLogoAndColor__fileInfo-image
			&-image {
				margin-right: 8px;
			}
		}

		// .CompanyLogoAndColor__fileWrapper
		&__fileWrapper {
			align-items: center;
			display: flex;
		}
	}

	// Logo Styles
	#scaleContainer {
		position: relative;
		top: 0 !important;

		&:after {
			content: 'Drag to resize';
			display: block;
			width: 90px;
			height: 15px;
			fab-color: gray6;
			position: absolute;
			fab-font-size: teenie;

			top: -25px;
			right: -95px;
		}
	}

	#scaleLogo {
		position: absolute;
	}

	.ui-resizable-handle.ui-resizable-ne {
		background: fab-color(gray5);
		border: solid fab-color(gray6) 1px;
		top: -3px;
		right: -3px;
		width: 7px;
		height: 7px;
		cursor: ne-resize;
	}

	.singleUpload {
		width: 100%;
	}


+encoreHighSpecificity() {
	.FileType__icon {
		margin-right: 8px;
	}

	.CompanyLogoAndColor__fileWrapper {
		padding-top: 24px;
	}

	.CompanyLogoAndColor__fileInfo-detail {
		fab-color: gray8;
		fab-font-size: medium;
	}

	.CompanyLogoAndColor__tipsItem {
		fab-color: (gray8)
	}

	settings-main-heading--extraMarginBottom {
		margin-bottom: 17px;
	}

	.fab-FormSection:not(:last-of-type) {
		border-bottom: 0;
	}

	.tipsLink {
		margin-bottom: 12px;
	}

	.file-wrapper .file-nameText {
		margin-left: 0;
	}

	.attach-wrapper {
		margin-right: 8px;
	}

	#uploadTips {
		margin-top: 0;
	}

	.CompanyLogoAndColor {
		.colors__wrapper {
			margin-bottom: 12px;
			padding-top: 0;
		}

		.activeColorFrame {
			border: none;
			border-radius: 16px;
			box-shadow: 2px 2px 0 2px rgba(56, 48, 47, .05);
			height: 84px;
			width: 84px;
			background-image: none;
			fab-background-color: theme-base;
		}

		.colorBox__wrapper {
			height: 84px;
			gap: 4px;
		}

		@container (min-width: 770px) {
			.colorBox__wrapper {
				height: 92px;
				gap: 12px;
			}
			.activeColorFrame {
				height: 92px;
				width: 92px;
			}
		}

		.colorBox { 
			background-image: none;
			fab-background-color: theme-base;
			border-radius: 8px;
			box-shadow: 1px 1px 0 2px rgba(56, 49, 47, .03);
			width: 40px;
			height: 40px;
			margin: 0;

			&:nth-child(4n+1) {
				border-radius: 8px;
			}

			&:nth-child(4n) {
				border-radius: 8px;
			}

			&.selected:after {
				top: 12px;
				left: 12px;
			}
		}
	}
}