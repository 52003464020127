.TaxIdFormatNote {
	margin-top: 7px;
	fab-font-size: teenie;
	fab-line-height: teenie;
	display: flex;
	align-items: center;

	&--error {
		fab-color: danger;
		fab-fill: gray6;


		> span {
			display: flex;
			margin-left: 5px;
			cursor: pointer;
		}
	}
}
