.FedWithholding {
	/* .FedWithholding__status */
	&__status {
		margin-top: 4px;
	}

	/* .FedWithholding__checkbox */
	&__checkbox {
		margin-right: 16px;

		/* .FedWithholding__checkboxGroup */
		&Group {
			padding-bottom: 8px;

			/* .FedWithholding__checkboxGroupLabel */
			&Label {
				margin-bottom: 5px;
			}
		}
	}

}
