.ATSSectionWrapperEncore {
	display: flex;
	gap: 32px;
}

.ATSEmailWrapper {
	margin: 0px;
	padding: 0px;
}

.ATSEmail {
	display: flex;
	margin: -1px auto 0;
	position: relative;
	min-height: 60vh;

	+encore() {
		min-height: 0;
	}
	&__Editor {
		width: calc(100% - 358px);
		padding: 8px 0 35px;

		+encore() {
			padding: 0px 0px 0px 0px;
		}

		&--reply {
			width: calc(100% - 2px);
		}

		&--settings {
			width: calc(100% - 358px);

			+encore() {
				width: 100%;
			}

		}

		&__legend {
			position: relative;
			display: flex;
			align-items: center;
			margin: 25px 0 15px;
			width: 100%;

			+encore() {
				display: flex;
				align-items: center;
				margin: 0px 0px 24px 0px;
				gap: 16px;
			}

			&Message {
				margin: 0 10px;
				min-width: 100px;

				+encore() {
					margin: 0px 0px 0px 0px;
				}
			}
			&Note {
				fab-text: small;
				fab-color: gray7;
			}
			&Link {
				position: absolute;
				right: 5px;
				top: -5px;

				+encore() {
					top: 10px;
				}
			}
			&Icon {
				display: flex;
				width: 40px;
				height: 40px;
				justify-content: center;
				align-items: center;
				background-color: fab-color(gray1);
				border-radius: 15px;
			}
		}

		&-tools {
			padding-bottom: 11px;

			+encore() {
				padding-bottom: 24px;
			}
		}
		&-cronofyAttach {
			display: inline-block	
		}
		&-toolbar {
			height: 33px;
			opacity: 0;
			transition: opacity 0.30s;
			overflow: visible;
			.mce-panel {
				// Keep the tinyMCE toolbar from hiding after it's been shown
				display: block !important;
				margin-top: -2px;
				background: none;
			}
		}
	}
	.mceWrapper--message {
		padding: 0 0 24px 24px;
		position: relative;
		box-sizing: border-box;
		min-width: 400px;
		overflow-x: hidden;
		overflow-y: hidden;
		.mceEditor {
			min-height: 300px;
			padding: 5px 20px 25px 0;
		}
		&--offerTemplate {
			& .PlaceholderWidget__scroll {
				height: calc(100vh - 120px);
			}
		}

		&--sendEmail {
			& .PlaceholderWidget__scroll {
				height: calc(100% - 42px);
			}
		}
	}
	&__SideColumn {
		box-sizing: border-box;
		background-color: #f3;
		border-left: 1px solid #df;
		height: 100%;
		margin: 0;
		padding: 0 0 10px 0;
		position: absolute;
		right: -32px;
		width: 358px;

		+encore() {
			border: none;
			position: relative;
			right: 0;
			text-align: left;
			padding: 0;
		}

		&--sendEmail {
			right: 0;
		}
	}
}

// TODO: The following are styles specific to ats emails (needs to be consolidated with offer letter)
.ATSEmail__Editor--ats {
	.mceWrapper--subject {
		margin-right: 0;
		position: relative;
		top: 1px;
		height: 52px;
		font-weight: 600;
		overflow: hidden;
		&:before {
			fab-background-color: gray4;
			bottom: -1px;
			content: '';
			height: 2px;
			position: absolute;
			right: 0;
			width: 100%;
			z-index: 0;
		}
		.mceEditor {
			border-top: 1px fab-color(gray4) solid;
			box-sizing: border-box;
			height: 71px;
			margin-top: -1px;
			min-width: 400px;
			overflow-x: auto;
			padding: 16px 23px 0;
			white-space: nowrap;
			&:before {
				margin-top: 0;
			}
		}
		&:hover .mceEditor {
			border-color: fab-color(gray5);
		}
	}
	.mceWrapper--error:not(.mceWrapper--focused) {
		border: solid fab-color(attention) 1px;
		.mceEditor {
			border-top-color: transparent;
		}
		&:before {
			background-color: transparent;
		}
	}
	.FileCards__status {
		fab-color: gray7;
		fab-text: teenie;
		margin: 0;
		padding: 4px 0 10px 0;
		margin-bottom: -24px;
	}
}

.ATS__sendEmail-container .ATSEmail__Editor--ats {
	.mceWrapper--message {
		top: 164px;
	}
}
