.PayeeNeeded {
	align-items: center;
	fab-background-color: gray1;
	border-radius: 2px;
	box-sizing: border-box;
	display: flex;
	height: 48px;
	margin-bottom: 32px;
	padding: 0 17px;
	width: 100%;
	&--globalPayees { // Note: Remove --globalPayees from classname use globalPayee styles with Global Payees toggle removal
		align-items: center;
		//@startCleanup encore
		border-bottom: 1px solid;
		//@endCleanup encore
		border-radius: 2px;
		box-sizing: border-box;
		display: flex;
		//@startCleanup encore
		fab-border-color: gray3;
		//@endCleanup encore
		height: 49px;
		padding: 0 16px;
		width: 100%;
		&:nth-child(even) {
			fab-background-color: gray1;
		}
		border-bottom: none;
	}

	// .PayeeNeeded__nameAmount
	&__nameAmount {
		align-items: center;
		display: flex;
		justify-content: space-between;
		margin-right: 22px;
		width: 185px;
	}

	// .PayeeNeeded__name
	&__name {
		align-items: center;
		display: flex;
		margin-right: 22px;
		width: 107px;
		&--globalPayees { // Note: Remove --globalPayees from classname use globalPayee styles with Global Payees toggle removal
			align-items: center;
			display: flex;
			margin-right: 22px;
			width: 175px;
		}
	}

	// .PayeeNeeded__amount
	&__amount {
		align-items: center;
		display: flex;
		justify-content: space-between;
		margin-right: 22px;
		width: 63px;
	}

	// .PayeeNeeded__select
	&__select {
		margin-right: 31px
	}

	// .PayeeNeeded__paragraph
	&__paragraph {
		fab-color: gray10;
		fab-font-size: 'small';
		margin: 0 3px 0 0;
		&--globalPayees { // Note: Remove --globalPayees from classname use globalPayee styles with Global Payees toggle removal
			fab-color: gray10;
			fab-font-size: 'medium';
			margin: 0 3px 0 0;
		}
	}

	// .PayeeNeeded__caseNumber
	&__caseNumber {
		margin: 0 20px; // Override BhrForms style
		&--globalPayees { // Note: Remove --globalPayees from classname use globalPayee styles with Global Payees toggle removal
			margin-right: 33px;
		}
	}

	// .PayeeNeeded__remittanceId
	&__remittanceId {
		margin-right: 16px;
		&--globalPayees { // Note: Remove --globalPayees from classname use globalPayee styles with Global Payees toggle removal
			margin-right: 31px;
		}
	}

	// .PayeeNeeded__description
	&__description {
		margin: 0; // Override BhrForms style

		// .PayeeNeeded__desription--childSupport
		&--childSupport {
			width: calc(100% - 699px);
			&--globalPayees {
				display: flex;
				flex-grow: 1;
			}
		}
	}

	// .PayeeNeeded__add
	&__add {
		fab-color: info;

		// .PayeeNeeded__add:hover
		&:hover {
			fab-color: white;
		}
	}

	// .PayeeNeeded__hideLabel
	&__settingsPage {
		padding-bottom: 0;
		> label {
			display: none;
		}
	}
}
