@require "./email";
$layout = json('@bamboohr/fabric/dist/definitions/json/layout.json', { hash: true });

/*
* Offer Letter preview
* styles for the Offer Letter Preview page
*/
.OLPreview {
	&__wrapper {
		fab-background-color: gray2;
		padding-bottom: 40px;
		background-color: var(--gray05, #f6f6f4);
		padding: 0;

		&Form {
			max-width: 1348px;
			margin: 0 auto;
			box-shadow: 0 8px 14px 3px rgba(0,0,0,0.05);
			max-width: ($layout.pageWidths.max)px;
			box-shadow: none;
		}

		&--view {
			box-sizing: border-box;
			width: 100%;
			background-color: var(--white);
			min-width: 100%;
			max-width: 100%;
			min-height: 100%;
			margin: 0;
			padding-top: 40px;
			margin-bottom: -20px; // ensure the page and footer align
		}
	}

	&__letter {
		padding: 10px 110px 40px;
		box-shadow: 0 8px 14px 3px rgba(0,0,0,0.05);
		fab-background-color: white;
		box-shadow: 0 0 0 0 rgba(0,0,0,0,0);
		background-color:  var(--gray05, #f6f6f4);
		padding: 0;
		margin: 0 40px;
		min-width: calc(100% - 80px);
		&--encoreContainer {
			background-color: var(--white, #ffffff);
			border-radius: var(--radius-600);
			max-width: 944px;
			margin: 40px auto 60px auto;
			min-width: 700px;
			padding: 32px;
		}
	}

	&__header {
		margin-bottom: 43px;
		padding: 29px 0 36px;
		text-align: center;
		position: relative;
		margin-bottom: 40px;
		padding: 24px 0 32px;

		&-date {
			margin-top: 17px;
			fab-text: small;
			color: var(--gray6);
			text-transform: uppercase;
			letter-spacing: .3px;
		}
	}

	&__content {
		fab-text: large;
	}

	&__section {
		padding-bottom: 22px;
		padding-bottom: 24px;
		&:not(:last-of-type) {
			position: relative;
			margin-bottom: 35px;
			margin-bottom: 40px;
			&:after {
				content: '';
				fab-gradient: theme-lightest theme-lighter;
				height: 2px;
				position: absolute;
				bottom: -2px;
				left: 0;
				right: 0;
				background-image: none;
				background-color: var(--gray2)
			}
		}

		&-header {
			margin: 0;
			margin-bottom: 16px;
		}

		&-note {
			fab-color: gray7;
			fab-text: small;
		}

		&--FileCards {
			margin-top: 24px;
		}

		&--flex {
			display: flex;
		}
	}

	&__acceptance {
		width: 60%;

		&-tagline {
			margin-bottom: 21px;
			margin-top: 5px;
			margin-bottom: 20px;
			margin-top: 4px;
		}
	}

	&__signatureField,
	&__dateField {
		border-radius: 2px;
		box-sizing: border-box;
		cursor: pointer;
		position: relative;
		border-radius: var(--radius-200);
		&Wrapper {
			margin-bottom: 12px;
			display: flex;
			flex-direction: column;
			align-items: baseline;

			&:after {
				content: '';
				fab-background-color: gray6;
				position: absolute;
				left: 0;
				height 1px;
				width: 100%;
				bottom: 24px;
				content: none;
			}
		}

		&--complete {
			&::placeholder {
				color: transparent;
			}
			&::-ms-input-placeholder { /* Internet Explorer 10-11 */
				color: transparent;
			}
			&:-ms-input-placeholder { /* Microsoft Edge */
				color: transparent;
			}
		}
	}


	&__signatureField {
		background-size: contain;
		background-repeat: no-repeat;
		background-position-y: center;
		background-position-x: 10px;
		background-origin: content-box;
		padding: 3px 8px;
		padding: 4px 8px;
		background-position-x: 0;
	}

	&__signatureWrapper {
		position: relative;
		margin-bottom: 23px;
		width: 286px;
		margin-bottom: 24px;
		color: var(--gray10);

		//.OLPreview__signatureWrapper .OLPreview__signatureField
		^[0]__signatureField {
			height: 40px;
			padding: 5px 10px;
			height: var(--height--biggie);
			padding: 4px 8px;

			&-canvas {
				background-position: left center;
				background-repeat: no-repeat;
				background-size: contain;
				width: 100%;
				height: 100%;
			}

			&.OLPreview__field--disabled &-placeholder {
				cursor: default;
			}
		}

	}

	&__fieldLabel {
		margin-top: 4px;
		padding-top: 9px;
		fab-color: gray5;
		border-top: solid fab-color(gray6) 1px;
		padding-top: 8px;
	}

	&__dateWrapper {
		display: inline-block;
		position: relative;

		//.OLPreview__dateWrapper .OLPreview__dateField
		^[0]__dateField {
			padding: 6px 5px 7px;
			width: 100%;
			font-family: inherit;
			color: var(--gray10);
		}
	}
	// hides jquery datepicker btn.
	&__dateWrapper {
		.ui-datepicker-trigger{
			display: none;
		}
	}

	&__questions {
		width: 40%;
		&-whoToContact {
			display: flex;
			flex-direction: row;
			column-gap: 12px;
			align-items: start;
		}
	}
}


