.EnpsAction {
	align-items: center;
	display: flex;
	padding-bottom: 38px;
	padding-left: 56px;
	overflow: hidden;
	max-height: 1000px;
	transition: max-height 600ms ease-in, padding-bottom 200ms ease-in;

	+encore() {
		background-color: var(--gray05);
		flex-direction: row-reverse;
		padding: 20px;
	}

	&--hidden {
		max-height: 0;
		padding-bottom: 0;

		+encore() {
			visibility: hidden;
		}
	}

	&__privacyStatement {
		fab-color: gray7;
		fab-fill: gray6;
		fab-font-size: small;
		line-height: 17px;
		margin-left: 19px;

		+encore() {
			align-items: center;
			color: var(--gray7);
			display: flex;
			font-size: var(--font-size-medium);
			line-height: var(--line-height-medium);
			margin-left: 0;
		}

		&Text {
			margin-left: 5px;
		}

		/* @startCleanup encore */
		button {
			+jadeFabricTheme() {
				fab-color: info;
				fab-fill: info;
				fab-font-size: small;
				line-height: 17px;
				height: 17px;
				font-weight: 400;
			}
		}
		/* @endCleanup encore */
	}

	&__row {
		align-items: center;
		display: flex;

		+encore() {
			flex-direction: row-reverse;
			justify-content: space-between;
			width: 100%;
		}

		/* @startCleanup encore */
		&Spacer {
			flex: 1 1 auto;
		}
		/* @endCleanup encore */
	}
}

@media only screen and (max-width: 800px) {
	.EnpsAction {
		justify-content: center;
		padding-left: 0;

		+encore() {
			background-color: unset;
		}

		&__button {
			width: 100%;
			height: 36px;
			font-size: 18px;
			font-weight: 600;
			line-height: 23px;

			+encore() {
				display: flex;
				height: unset;
				justify-content: center;
			}
		}
	}
}
