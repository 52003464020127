.CheckMigrationProgress {
	padding-bottom: 100px;

	// .CheckMigrationProgress__header
	&__header {
		text-align: left;
		display: flex;
		align-items: center;
		border-bottom: 1px solid fab-color(gray4);
		padding-bottom: 6px;

		// .CheckMigrationProgress__headerText
		&Text {
			margin-left: 8px;
		}
	}

	// .CheckMigrationProgress__message
	&__message {
		text-align: center;
		padding: 109px 0 45px;

		// .CheckMigrationProgress__messageIcon
		&Icon {
			fab-fill: gray4;
		}

		// .CheckMigrationProgress__messageHeadline
		&Headline {
			fab-color: gray6;
		}

		// .CheckMigrationProgress__messageText
		&Text {
			fab-color: gray6;
			fab-font-size: large;
		}
	}

	// .CheckMigrationProgress__finish
	&__finish {
		margin-top: 28px;
	}

	// .CheckMigrationProgress__feedback
	&__feedback {
		max-width: 400px;
		margin: 0 auto;
	}
}
