.NHPTemplateListHeader {

	// .NHPTemplateListHeader__actions
	&__actions {
		display: flex;
		justify-content: space-between;
	}

	// .NHPTemplateListHeader__header
	&__header {
		margin-bottom: 6px;
	}

	// .NHPTemplateListHeader__icon
	&__icon {
		margin-right: 5px;
		position: relative;
		top: 1px;
	}

	// .NHPTemplateListHeader__subHeader
	&__subHeader {
		color: fab-color(gray7);
		fab-font-size: small;
		fab-line-height: teenie;
		margin-bottom: 25px;
	}

}

.NHPEmailLinkOption {
	width: 246px;
}
