
/*---------------------------------*\
$TAB MODULE

style individual tab elements
/*---------------------------------*/
.Tab {
	position: relative;
	display: inline-block;
	padding: 2px 5px 0;
	border: solid 1px;
	border-bottom: none;
}

@css {
	.Tab--select {
		padding: 4px 5px 6px 5px;
		min-width: 150px;
		font-size: 15px;
		background: -moz-linear-gradient(top, #f2f2f2 0%, #fcfcfc 100%); /* FF3.6+ */
		background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #f2f2f2), color-stop(100%, #fcfcfc)); /* Chrome,Safari4+ */
		background: -webkit-linear-gradient(top, #f2f2f2 0%, #fcfcfc 100%); /* Chrome10+,Safari5.1+ */
		background: -o-linear-gradient(top, #f2f2f2 0%, #fcfcfc 100%); /* Opera 11.10+ */
		background: -ms-linear-gradient(top, #f2f2f2 0%, #fcfcfc 100%); /* IE10+ */
		background: linear-gradient(to bottom, #f2f2f2 0%, #fcfcfc 100%); /* W3C */
		filter: progid: DXImageTransform . Microsoft . gradient(startColorstr = '#f2f2f2', endColorstr = '#fcfcfc', GradientType = 0  ); /* IE6-9 */
		border-color: #787878;
		border-radius:  2px 2px 0 0;
		box-shadow: inset 0 1px 2px 0 rgba(141, 141, 141, .5);
	}
}

.Tab--select:after {
	content: '';
	position: absolute;
	top: 12px;
	right: 8px;
	display: block;
	border: solid transparent 5px;
	border-top-color: #777;
}

/***/

.Tab--select + .ScrollBox {
	border-top-left-radius: 0;
}