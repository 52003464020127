.EnpsScaleAnswer {
	
	&__questions {
		padding: 27px 22px 5px;
	}

	&__scale {
		margin-left: 20px;
		margin-top: 25px;
		width: 470px;
	}
}

@media only screen and (max-width: 800px) {
	.EnpsScaleAnswer {

		&__questions {
			margin: 0;
			padding: 0 40px;
		}
		
		&__scale {
			margin: 0;
			width: 100%;
		}
	}
}
