.ChooseDate {
	width: 100%;
	margin-bottom: 10px;

	&__clockInTime {
		margin-left: 8px;
	}

	&__formDash {
		margin: 0 8px;
		position: relative;
		top: 10px;
	}

	&__timeInput {
		margin: 0 8px;
	}

	&__totalTime {
		fab-color: gray9;
		font-size: 14px;

		&Container {
			position: relative;
			top: 13px;
			left: 13px;
		}
	}
}
