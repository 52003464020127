.PS_AddButton {
	display: inline-block;
	width: auto;
	height: 28px;
	box-sizing: border-box;
	-webkit-font-smoothing: antialiased;
	margin: 13px 0 0;
	font-family: 'Source Sans Pro';
}

.PS_BlankState {
	margin-top: 15px;
}

.PS_List {
	margin-top: 15px;

	&__col {
		&--wide {
			width: 54px;
		}
		&--wider {
			width: 160px;
		}
	}

	&__actions {
		position: relative;
		top: 2px;
		display: flex;
		justify-content: flex-end;
		align-items: center;
	}

	& .BhrTable__row,
	& .BhrTable__cell,
	& .BhrTable__row:hover,
	& .BhrTable__cell:hover {
		cursor: default !important;
	}

	& .BhrTable__cell {
		padding-top: 11px;
		padding-bottom: 12px;
	}

	&__inPayrollCol {
		margin: 0;
		padding-right: 0;
		width: 17px;

		&Icon {
			fill: #898a8a;
			position: relative;
			top: 2px;
		}

		&EinDeletedIcon {
			fill: fab-color(attention);
			position: relative;
			top: 2px;
		}
	}
}

.PS_TableButton {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 14px;
	width: 14px;
	margin: 0 0 0 8px;
	position: relative;

	&:hover:before {
		content: "";
		width: 18px;
		height: 18px;
		border: 1px solid #af;
		border-radius: 3px;
		position: absolute;
		top: -3px;
		left: -3px;
	}

	&__icon {
		fill: #898a8a;

		&--edit {
			position: relative;
			left: 1px;
		}
	}
}

.PS_ListItem:only-child .PS_TableButton--delete {
	cursor: default;

	&:hover, &:active {
		cursor: default;
		background-color: transparent;
		border-color: transparent;
	}

	& .PS_TableButton__icon {
		fill: #c5;
	}
}

.PS_Tooltip {
	font-size: 12px;
	line-height: 15px;
	max-width: 140px;
	padding: 6px 10px;
	text-align: left;
}

.PS_EmpListModal {
	min-height 300px;
	width: 910px;

	&__header {
		background-color: #F4F4F4;
		box-sizing: border-box;
		display: flex;
		padding: 20px 20px 18px;

		&Icon {
			margin-right: 10px;
		}

		&Title {
			font-size: 20px;
			font-weight: 500;
		}
	}

	&__table {
		border-bottom: 1px solid #D9D9D9;
	}
}
