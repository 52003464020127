@require 'GrantAccessModal.mod';

.Settings {
	&AccessLevels {
		&__title {
			&Icon {
				margin-right: 5px;
				transform: translateY(2px);

				display: none;
			}

			color: fab-color(theme);
			margin: 0;
		}

		&__container {
			display: flex;
			justify-content: space-between;

			margin-top: spacing(1.5);
			margin-bottom: spacing(2.5);
		}

		&__exportItems {
			align-self: end;
		}

		&__modalLink {
			font-size: 14px;
			line-height: 0;
		}

		&__table {
			table {
				table-layout: fixed;
			}
			tbody tr {
				&.SettingsAccessLevels__row--selected {
					background: #feffe3 !important;
				}

				&:has(.DataTable__cell--actions) {
					&:hover {
						background-color: var(--gray1);
					}
				}
			}

			th.DataTable__cell {
				white-space: wrap !important;
			}

			.dataTables_scrollHeadInner {
				width: unset !important;
			}

			.DataTable__cell {
				overflow: visible;
				cursor: default !important;
				vertical-align: top !important;
				white-space: normal;
				word-break: break-word;

				/.SettingsAccessLevels__body--noAccess tr:not(.DataTable__row--disabled) .DataTable__cell {
					cursor: pointer !important;
				}

				&--employeeName {
					word-break: break-word;

					.SettingsAccessLevels__ico {
						display: inline-block;
						size: 12px;
						margin-right: 4px;


						&:not(.baseFillColor) {
							fab-fill: gray4;
						}
					}

					.SettingsAccessLevels__userNote {
						color: #888;
						font-size: 0.95em;
						padding-left: 0.33em;
					}

					input[type="checkbox"] + label {
						display: inline-block;
						size: 15px;
						margin: -3px 6px 0 0;
					}

					a {
						font-weight: normal;
					}
				}

				&--checkbox {
					padding-right: 4px;
					width: 36px !important;
					max-width: 36px !important;
					min-width: 36px !important;

					.fab-Checkbox {
						margin-top: 1px;
					}
				}
				&:not(&--actions, &--checkbox) {
					width: 162px !important;
					max-width: 162px !important;
					min-width: 162px !important;
				}
				&--actions {
					vertical-align: middle!important;
					width: 85px !important;
					min-width: 85px !important;
					max-width: 85px !important;
					&.DataTable__cell--multipleActions {
						min-width: 160px !important;
						max-width: 160px !important;
						width: 160px !important;
					}
					box-sizing: content-box;

					.access-button-spacer {
						height: 32px;
					}

					.btn:not(.active),
					.AccessLevelSelector {

						opacity: 1;
						animation-name: fadeInOpacity;
						animation-iteration-count: 1;
						animation-timing-function: ease-in;
						animation-duration: .1s;
						@keyframes fadeInOpacity {
							0% {
								opacity: 0;
							}
							100% {
								opacity: 1;
							}
						}
						transform: translateY(-1px);

						margin: 0;

						.down-list {
							left: auto;
							right: 0;
						}
					}
				}
			}

			&ActionsCell {
				display: flex;
				justify-content: flex-end;
				gap: 8px;
			}

			&ActionOption {
				display: inline-flex;
				align-items: center;
				gap: 8px;
			}
		}

		&__empty {
			&-wrapper {
				border-top: 1px solid fab-color(gray3);
			}

			&-ico {
				size: 84px;
				margin-bottom: 6px;
				fill: #ec;
				color: @fill;
			}

			&-msg {
				color: #c5;
				font-weight: normal;
				margin-bottom: 13px !important;
			}
		}

		&__button {
			&-wrapper {
				display: flex;
				padding: 14px 0;

				&--left {
					display: flex;
					flex: 1;
				}

				&--right {
					display: flex;
					flex: 0;
					justify-content: flex-end;
				}
			}

			&--googleImport {
				.bicon {
					width: 22px;
					height: 17px;
					background-position: center center;
				}
			}
		}

		&__newAccessLevelDown {
			&Btn {
				margin-top: -4px;
				position: relative;
				width: 31px;
				height: 26px !important;

				.caret {
					position: absolute;
					margin: 0;
					top: 11px;
					right: 3px;
				}

				&Ico {
					position: absolute;
					top: 6px;
					left: 5px;
					fill #82;
				}
			}

			&List {
				width: 320px;

				&Label,
				&Description {
					display: block !important;
				}

				&Label {
					font-weight: 500;
					font-size: 1.15em;
				}

				&Description {
					color: #888;
					max-width: 100% !important;
					white-space: normal !important;
					padding-top: 3px !important;
					line-height: 1.2em !important;
				}

				> li {
					padding: 0 14px !important;

					&:hover {
						> a > span {
							color: #fff;
						}
					}

					&:first-of-type {
						> a {
							padding-top: 5px !important;
							padding-bottom: 0 !important;
						}
					}

					&:last-of-type {
						> a {
							padding-top: 1px !important;
							padding-bottom: 7px !important;
						}
					}

					> a {
						max-width: 100% !important;
						padding: 5px 0 !important;
					}
				}
			}
		}

		&__accessLevelDescription {
			white-space: pre-wrap;

			margin-bottom: spacing(2);
		}

		&__accessLevelMenu {
			.down-list {
				li a {
					max-width: none;
				}
			}
		}
	}
}


@media screen and (min-width: 1430px) and (max-width: 1600px) {
	.SettingsAccessLevels__table .DataTable__cell--actions.DataTable__cell--multipleActions {
		width: 140px !important;
		max-width: 140px !important;
		min-width: 140px !important;
	}
}

@media screen and (max-width: 1600px) {
	.SettingsAccessLevels__table .DataTable__cell:not(.SettingsAccessLevels__table .DataTable__cell--actions, .SettingsAccessLevels__table .DataTable__cell--checkbox) {
		width: 108px !important;
		max-width: 108px !important;
		min-width: 108px !important;
	}
	.SettingsAccessLevels__tableActionsCell {
		gap: 2px !important;
	}
}

@media screen and (min-width: 1650px) {
	.SettingsAccessLevels__table .DataTable__cell--actions.DataTable__cell--multipleActions {
		width: 150px !important;
		max-width: 150px !important;
		min-width: 150px !important;
	}
}

@media screen and (min-width: 1750px) {
	.SettingsAccessLevels__table .DataTable__cell--actions.DataTable__cell--multipleActions {
		width: 140px !important;
		max-width: 140px !important;
		min-width: 140px !important;
	}
}
