.ConductSurvey {

	&__privacyMessage {
		fab-color: gray6;
		fab-fill: gray6;
		padding-bottom: 40px;
		margin-left: 0 auto;
		text-align: center;

		+encore() {
			align-items: center;
			color: var(--gray7);
			display: flex;
			font-size: var(--font-size-medium);
			line-height: var(--line-height-medium);
			margin-left: 0;
		}

		&Text {
			margin-left: 8px;
		}

		button {
			height: 17px;
			fab-color: info;
			fab-fill: info;
			font-weight: 400;
		}
	}

	&__questions {
		padding: 27px 22px 5px;

		+encore() {
			padding: 32px 48px 10px 20px;
		}
	}

	&__question {

		&Heading {

			&--fadeOut {
				fadeOut();
			}

			&--fadeIn {
				fadeIn();
			}
		}
	}

	&__scale {
		/* @startCleanup encore */
		margin-left: 20px;
		margin-top: 25px;
		width: 470px;
		/* @endCleanup encore */

		+encore() {
			box-sizing: border-box;
			margin-top: 20px;
			margin-left: 0;
			padding-left: 20px;
			width: 100%;
		}
	}
}

@media only screen and (max-width: 800px) {
	.ConductSurvey {

		&__privacyMessage {
			+encore() {
				justify-content: center;
				width: 100%;
			}
		}

		&__questions {
			margin: 0;
			padding: 0 40px;
		}

		&__scale {
			margin: 0;
			width: 100%;
		}
	}
}
