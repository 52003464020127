.SettingsApps {
	&__appList {
		// startCleanup encore
		padding: 18px 16px;
		// endCleanup encore
		+encore() {
			padding: 18px 24px;
		}

		&--installed {
			// startCleanup encore
			padding-bottom: 30px;
			// endCleanup encore
			+encore() {
				padding-bottom: 52px;
				padding-top: 0;
			}
		}

		&--uninstalled {
			// startCleanup encore
			padding-top: 12px;
			// endCleanup encore

			+encore() {
				padding-top: 0;
			}
		}
	}
}