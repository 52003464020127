.CodesAndRates {
	text-align: left;

	// .CodesAndRates__cantEdit
	&__cantEdit {
		fab-color: gray7;
		fab-font-size: teenie;
	}

	// .CodesAndRates__filter
	&__filter {
		fab-color: gray7;

		// .CodesAndRates__filter--selected
		&--selected {
			fab-color: gray10;
			fab-font-weight: bold;
		}
	}

	// .CodesAndRates__activeToggle
	&__activeToggle {
		margin-bottom: 7px;
	}

	// .CodesAndRates__labels
	&__labels {
		display: flex;
	}

	// .CodesAndRates__label
	&__label {

		// .CodesAndRates__label--active
		&--active {
			margin-right: 9px;
		}

		// .CodesAndRates__label--code
		&--code {
			margin-right: 71px;
		}
		&--codeError {
			margin-right: 51px;
		}

		// .CodesAndRates__label--description
		&--description {
			margin-right: 156px;
		}
		&--descriptionError {
			margin-right: 136px;
		}

		// .CodesAndRates__label--employer
		&--employer {
			margin-right: 55px;
		}
		&--employerError {
			margin-right: 35px;
		}

		// .CodesAndRates__label--employee
		&--employee {
			margin-right: 50px;
		}
	}
}
