.Approvals {

	/* .Approvals__head */
	&__head {
		margin-top: -6px;

		//.Approvals__headIcon
		&Icon {
			margin-right: 7px;
			position: relative;
			top: 2px;
		}

		//.Approvals__headTitle
		&Title {
			color: var(--fabric-theme-base);
			display: inline-block;
			flex-grow: 2;
			margin: 0;
			padding-top: 0 !important;
		}

		//.Approvals__headHeaderContainer
		&HeaderContainer {
			display: flex;
			padding-top: 0;
		}

		//.Approvals__headActions
		&Actions {
			display: flex;
			flex-direction: column;
			justify-content: center;
		}

		/* .Approvals__head h3.Approvals__header */
		h3.Approvals__header {
			margin-bottom: 7px;
		}

		/* .Approvals__head-sub-text */
		&-sub-text {
			margin-top: 20px;
			color: fab-color(gray7);
			fab-font-size: small;
			margin-bottom: 16px;
		}

		/* .Approvals__head-preview */
		&-preview {
			display: inline-block;
			margin-left: 20px;
			position: relative;
			top: 4px;
			color: fab-color(gray7);
		}

		/* .Approvals__head-GearMenu */
		&-GearMenu {
			margin-left: 3px;
			margin-top: 6px !important;

			/* .Approvals__head-GearMenu  .dropdown-menu__list-item-header*/
			.dropdown-menu__list-item-header {
				border-bottom: solid 1px fab-color(gray5);

				/* .Approvals__head-GearMenu  .dropdown-menu__list-item-header--larger-text*/
				&--larger-text {
					fab-font-size: medium;
				}
			}
		}

		/* .Approvals__head--action */
		&--action {
			margin-top: 20px;

			div {
				vertical-align: top;
			}
		}
	}
}

.inline {
	display: inline-block;
}
