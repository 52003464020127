.FieldSelectBox {

	// .FieldSelectBox__displayValue
	&__displayValue {
		fab-color: gray8;
		text-align: left;
		width: 208px;

		// .FieldSelectBox__displayValue--selected
		&--selected {
			fab-color: theme;
			fab-font-weight: bold;
		}

		// .FieldSelectBox__displayValue--disabled
		&--disabled {
			fab-color: gray6;
		}
	}
}
