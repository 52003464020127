	.Payee {
		&__form {
			padding: 0;
		}
	}

.subscript {
	fab-color: gray7;
	fab-font-size: teenie;
	margin-top: -8px;
}

.subscript__button {
	fab-font-size: teenie;
	&.name {
		margin-top: -8px;
	}
}

.subscript__text {
	padding-top: 6px;
	padding-right: 4px;
	&.name {
		padding-top 0
		margin-top: -2px;
	}
}

.edit-modal-subscript__text {
	margin-block: -7px 10px;
}