// .MfaManualCodeModal
.MfaManualCodeModal {
	// .MfaManualCodeModal__body
	&__body {
		// .MfaManualCodeModal__bodyCode
		&Code {
			fab-color: theme;
		}
	}
	// .MfaManualCodeModal__content
	&__content {
		text-align: center;
	}
}
