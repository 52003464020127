.DynamicForm__fieldBox {
    position: relative;
    vertical-align: bottom;

    &.checkbox {
        bottom: 6px;
    }

    &.fieldBox + &.fieldBox {
        margin-left: 12px;
    }
}
