.DirectDeposit {

	/* .DirectDeposit__accounts */
	&__accounts {
		position: relative;

		/* .DirectDeposit__accounts--blank */
		&--blank {
			padding-top: 67px;
			text-align: center;
		}

		/* .DirectDeposit__accounts--borderBottom */
		&--borderBottom {
			border-bottom: 1px solid fab-color(gray3);
		}
	}
}
