.BankBoxContent {
	width: 320px;

	// .BankBoxContent__title
	&__title {
		fab-color: gray8;
		fab-font-weight: medium;

		// .BankBoxContent__title--selected
		&--selected {
			fab-font-weight: semibold;
			fab-color: theme;
		}
	}

	// .BankBoxContent__breakout
	&__breakout {
		fab-font-size: teenie;
		fab-color: gray8;

		// .BankBoxContent__breakoutContainer
		&Container {
			padding-top: 7px;
		}
	}
}
