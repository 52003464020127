.EnpsScoreHeading {
	text-align: center;

	&__score {
		border-radius: 50%;
		border: 2px solid fab-color(theme-base);
		fab-background-color: theme-base;
		fab-color: white;
		fab-font-weight: heavy;
		font-size: 32px;
		height: 50px;
		line-height: 50px;
		margin: 0 auto 25px auto;
		text-align: center;
		width: 50px;
	}
}
