.SurveyStatus {
	align-items: center;
	display: flex;
	flex-direction: column;
	margin-top: 25px;
	text-align: center;

	&--has-image {
		padding: 46px 0;
	}

	/* @startCleanup encore */
	&__header {
		fab-color: theme-base;
		fab-font-weight: semibold;
		font-family: unset;
		font-size: 22px;
		line-height: 28px;
		margin: 20px 0;
		text-align: center;

		+encore() {
			font-family: $encoreHeaderFontFamilyStack;
		}
	}

	&__icon {
		fab-fill: theme-base;
	}

	&__subtitle {
		fab-color: gray10;
		fab-font-size: medium;
		text-align: center;
	}
	/* @endCleanup encore */
}
