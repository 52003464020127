.TTProjectTracking {
	margin: 0px;

	&__radioGroup {
		margin-top: 18px;
	}

	&__bulkSelector {
		margin-left: 26px;
		margin-top: 8px;
	}

	&__select {
		margin-top: 2px;
	}

	&__selectLabel {
		padding-left: 0;
		font-size: 14px;
		font-weight: 500;
		margin-bottom: 6px;
	}

	&__taskInputLabel {
		margin-bottom: 1px;
	}

	&__fieldset {
		margin: 8px 0 0 24px;
	}

	&__selectToggle {
		display: flex;
	}

	&__actions {
		display: flex;
		justify-content: space-between;
		padding: 20px 0 18px;
	}

	&__dropdownGearBox {
		margin-left: 12px;
	}

	&__flexDiv {
		display: flex;
	}

	&__isLoading {
		opacity: 0.5;
		pointer-events: none;
	}

	&__emptyTable {
		width: 100%;
		padding-top: 63px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	&__modalContent {
		text-align: center;
	}

	&__taskActions {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin: 14px 0 0 24px;
		width: 496px;
	}
}
