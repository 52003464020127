.CompanyDeductionMetadata {
	display: flex;
	margin-top: 26px;

	// .CompanyDeductionMetadata--blank
	&--blank {
		height: 41px;
	}

	// .CompanyDeductionMetadata__label
	&__label {
		margin-bottom: 8px;

		// .CompanyDeductionMetadata__labelContainer
		&Container {
			margin-right: 20px;
		}
	}

	// .CompanyDeductionMetadata__value
	&__value {
		margin-bottom: 8px;
	}
}
