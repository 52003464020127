.SettingsApps {
	&__appList {
		&EmptyState {
			text-align: center;
			padding: 50px 100px 40px;

			&Icon {
				fill: $bhrColors.gray5;
			}

			&Title {
				font-size: 25px;
				margin: 13px 0 2px;
				color: $bhrColors.gray6;
			}

			&Msg {
				font-size: 18px;
				line-height: 22px;
				color: $bhrColors.gray6;
				margin: 0 0 22px;
			}
		}
	}
}
