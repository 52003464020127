.CompensationForm {

	&__warningNote {
		fab-color: attention;
		fab-font-weight: semibold;
	}

	.percentChange {
		align-items: center;
		color: fab-color(gray8);
		display: inline-flex;
		font-size: 14px;
		font-weight: 400;
		line-height: 19px;
		margin: auto 17px;

		&-percent {
			margin-right: 5px;
		}
		&-icon {
			font-size: unset;
			margin-left: 4px;

			&-increase {
				fill: fab-color(success);
				transform: rotate(180deg);
			}
			&-decrease {
				fill: fab-color(danger);
			}
		}
		&.nextLine {
			margin: 8px 0 3px;
			width: 100%;
		}
	}
}

.TaxTypeWarningText {
	margin-left: 12px;
	position: absolute;
	top: -21px;
	fab-color: info;
	fab-line-height: teenie;
	width: 290px;
	font-size: var(--font-size-teenie);

	&__text {
		fab-font-weight: semibold;
	}

	&__doubleCheck {
		fab-color: gray7;
	}
}

.EmploymentStatusModal__statusSelectWrapper {
	&--inactive {
		flex-direction: column;

		.EmploymentStatusForm__inactiveStatusNote {
			display: block;
		}
	}
}

.EmploymentStatusForm__inactiveStatusNote {
	display: none;
	fab-color: gray7;
	fab-font-size: teenie;
	fab-line-height: teenie;
	margin-top: 8px;
}

.EmploymentStatusForm__employeeTaxTypeFieldRow{
	.fab-Label {
		display: flex;
		align-items: center;

		.iconWrapper {
			fab-fill: info;
			margin-right: 6px;
		}
	}
	&--info {
		.fab-Label {
			fab-color: info;
		}
	}
}

.currencyChange-message {
	height: 70px;
	padding: 10px 14px;
	display: flex;
	justify-content: start;
	align-items: center;
	box-sizing: border-box;
	column-gap: 13px;
	background-color: rgb(230, 245, 254);
	border-radius: 4px;
	width: 435px;
	margin-top: 15px;
}

.currencyChange-message--icon {
	fill: rgb(7, 114, 179);
	flex-shrink: 0;
}

.currencyChange-message--text {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	line-height: 18px;
}

.currencyChange-message--title {
	font-weight: 600;
	font-size: 15px;
	color: #222;
}

.currencyChange-message--description {
	font-weight: 400;
	font-size: 14px;
	color: #444;
}
