// Hover elements are double wrapped in FieldRow
.DynamicForm__FieldRow {
    & > & {
        margin-bottom: 0;
    }

    .fab-FormNote--error {
        position: relative;
        bottom: 8px;
        vertical-align: bottom;
    }
}

.DynamicForm__FieldRow + .DynamicForm__Table {
    margin-top: 40px;
}
.DynamicForm__Table + .DynamicForm__FieldRow {
    margin-top: 40px;
}
