#popover {
	> div {
		max-width: 462px;
	}

	p {
		font-size: 14px;
		line-height: 20px;
		fab-color: gray9;
		margin-bottom: 8px;

		&:last-child {
			margin-bottom: 0;
		}
	}

	.listHeader {
		font-size: 15px;
		line-height: 22px;
		fab-color: gray10;
		font-weight: 700;
	}

	ul {
		margin-bottom: 8px;

		li span {
			font-size: 15px;
			line-height: 22px;
			fab-color: gray7;
		}

		li span:last-child {
			font-size: 14px;
			line-height: 22px;
			fab-color: gray10;
			margin-left: 10px;
		}

		li:last-child span:last-child {
			margin-left: 13px;
		}
	}
}
