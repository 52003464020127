.PS_AddEditForm {
	width: 410px;

	&__fieldSet {
		padding-bottom: 9px !important;
	}

	&__NoEdit {
		position: relative;

		&Message {
			display: none;
			padding: 3px 0 10px;
			position: relative;
		}

		&Icon {
			margin: 3px 7px 0 0;
		}

		&Text {
			font-size: 12px;
		}
	}

	&FrequencySection {
		display: none;
	}

	&__continuedLabel {
		display: inline-block;
		color: #555;
		font-size: 15px;
		margin-left: 6px;
		margin-right: 7px;
		vertical-align: middle;
		position: relative;
		top: 1px;
	}

	&__sameError {
		margin-left: 0 !important;
	}

	&--limitedEdit {
		& ^[0] {
			&__NoEdit {
				margin: 30px 0 20px;

				& ^[0]__continuedLabel {
					color: #9;
				}
			}
			&__NoEditMessage {
				display: flex;
			}
			&__NoEditBG {
				background-color: #f0;
				position: absolute;
				top: -10px;
				right: -10px;
				bottom: -10px;
				left: -10px;
			}
		}
	}
}
