.DeductionDate {
	
	&__option {

		&Note {
			fab-color: gray7;
			fab-font-size: teenie;
			line-height: 16px;

			/.fab-MenuOption--active & {
				fab-color: white;
			}
		}

		&--bold {
			fab-font-weight: bold;
		}
	}
}
