.WithholdingWrapper {
	.fab-FormSection {
		margin-top: 16px;
		padding-bottom: 16px;

		&:first-child {
			margin-top: 32px;
		}

		&--hidden {
			display: none;
		}
	}

	// WithholdingWrapper__taxType .fab-FormSection
	&__taxType.fab-FormSection  {
		margin-top: 32px;
		padding-bottom: 32px;

		.fab-Label {
			fab-color: gray6;
		}

		.fab-FormSection--content {
			display: flex;
			align-items: center;

			.fab-Select {
				margin-right: 12px;
			}

			.WithholdingWrapper__explanation {
				fab-color: gray6;
			}
		}
	}

	.StateWithholdingContainer--hidden {
		display: none;
	}
}

.ExemptionsCheckbox {
	margin-left: 34px !important;
}
