//TODO: CLEAN THIS UP AFTER https://bamboohr.atlassian.net/browse/FAB-740 is completed
//DON'T USE THIS. THIS IS JUST TO UNBLOCK A JADE THING AND THE RADIO BUTTON SHOULD ACTUALLY BE FIXED
.fabRadio-tempFix {
	width: 500px;
	position: relative;
	left: 25px;
	top: -22px;

	& .fieldLabel {
		margin-top: 3px !important;
	}
}

.TaskForm {
	margin-left: 22px;
}

.fab-FormField {
	//.fab-FormField .fieldLabel
	& .fieldLabel {
		margin-left: 8px;
		margin-top: 6px;
	}
}

.afterRelation,
.afterType {
	width: 85px;
}

.titleIcon {
	margin-right: 9px;
	position: relative;
	top: 2px;
}
.settingsTasklists {
	#departmentFilter,
	#locationFilter,
	#jobTitleFilter,
	#divisionFilter,
	#employmentStateFilter {
		display: none;
	}
	.updateInformation {
		display: flex;
		align-items: center;

		// .updateInformation__icon
		&__icon {
			margin-left: 6px;
			fab-fill: gray6;

			&:hover {
				fab-fill: theme;
			}
		}
	}
	div.optional {
		background: #F2;
		padding: 10px 10px 5px 10px;
		border-radius: 5px;
		width: 370px;
		margin-top: 10px;
		margin-left: 5px;
	}
	hr {
		margin-top: 4px;
	}
	.dueInactive {
		opacity: 0.2;
	}
	.filter-options-link {
		display: inline-block;
		background: none;
		margin: -3px 0 0 6px;
	}

	// .settingsTasklists .allow-ee-uploads
	.allow-ee-uploads {
		display: inline-block;
		margin-left: 15px;
		padding-top: 7px;

		input[type=checkbox] {
			// .settingsTasklists .allow-ee-uploads label
			+ label {
				color: fab-color(gray8);
			}
		}
	}
}

.RequiredFor {
	//.RequiredFor__icon
	&__icon {
		fill: fab-color(gray7);
		margin-right: 3px;
		vertical-align: middle;
	}
	//.RequiredFor--bold
	&--bold{
		fab-font-weight(semibold);
	}
}

#filter-description
{
	padding-left: 8px;

	& div {
		padding: 0;
		margin: 0;
	}
}

{featureToggleOn("NHP_Template_Creation")} {
	.TaskListTable {
		&__assignedTo {
			min-width: 112px;
		}
	}
}
