.FedMessage2020 {
	display: flex;
	align-items: center;
	margin: -4px 0 12px;

	// .FedMessage2020__icon
	&__icon {
		fab-fill: info;
		margin-right: 5px;
		height: 20px;
	}

	// .FedMessage2020__text
	&__text {
		fab-font-size: small;
		display: inline;
	}
}
