.EEO1Category {
	padding: 0 0 24px 0;
	& + & {
		border-top: 1px solid fab-color(gray3);
	}

	&__heading {
		color: fab-color(gray8);
		font-size: 16px;
		margin-bottom: 5px;
	}

	&__body {
		padding-left: 2px;
		margin-left: 0;
		margin-top: 0;
	}

	&__label {
		display: block;
		margin-bottom: 4px;
	}

	&__select {
		width: 240px;
	}
}
