.SettingsApps {
	&__installHeaders {
		+encore() {
			color: fab-color(theme);
			margin-bottom: 0;

			&--installed {
				color: fab-color(theme);
				margin-bottom: 0;
				margin-top: 26px;
			}
		}
	}

	&__heading {
		// startCleanup encore 
		&Icon {
			margin-right: 8px;
		}
		// endCleanup encore 

		+encore() {
			color: fab-color(theme);
			font-size: 26px;
			font-weight: 600;
			line-Height: 34px;
			margin-top: 0;
		}
	}

	&__description {
		fab-font-size: "biggie";
	}

	&__form {
		padding-bottom: 0;

		&Row--last {
			margin-bottom: 0;
		}
	}

	&__groupLabel {
		&--required:after {
			content: '*';
			color: fab-color(theme);
			font-family: sans-serif;
			font-size: fabricFontSize(large);
		}
	}

	&__msg {
		&--uninstalled {
			margin-top: 11px;
		}
	}

	&__appModalForm {
		&--config {

			.header {
				margin-bottom: 24px;
			}

			.bold {
				font-weight: 700;
			}

			.info {
				font-size: 14px;
				margin-top: 8px;
			}
		}
		// SettingsApps__appModalForm--inline
		&--inline {
			display: inline-block;
		}
		// SettingsApps__appModalForm--secondaryGray
		&--secondaryGray{
			fab-color: gray7
		}
		// SettingsApps__appModalForm--confirmIcon
		&--confirmIcon {
			fill: #0E840D
		}
	}
}
