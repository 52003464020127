.MigrationTasks {

	// .MigrationTasks__message
	&__message {
		fab-color: theme;
		margin-top: 19px;
	}

	// .MigrationTasks__detail
	&__details {
		margin-bottom: 30px;
	}
}
