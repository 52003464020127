.PayeeRow {
	/* PayeeRow__employee-count */
	&__employee-count {
		display: block;
		margin-top: 3px;
	}
	/* .PayeeRow__employee-count-link */
	&__employee-count-link {
		margin-top -4px;
	}
	/* .PayeeRow__employees */
	&__employees {
		max-width: 180px;
	}
	/* .PayeeRow__actions */
	&__actions {
		width: 40px;
	}
}
