.TaxesTable {
	margin: 16px 0 21px;
	scroll-margin-top: 150px;

	// .TaxesTable__appliedForCell
	&__appliedForCell {
		fab-color: gray7;

		// .TaxesTable__appliedForCellIcon
		&Icon {
			fab-fill: attention;
		}

		&Text {
			margin-left: 4px;
		}
	}

	&__cell {
		&--undefined {
			fab-color: gray7;
		}
	}

	&__gearDropdown {
		padding-top: 10px;
	}
}

.TaxesTableModal {
	display: flex;
	text-align: center;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	width: 496px;
	margin: auto;

	// TaxesTableModal--endTax
	&--endTax {
		fab-background-color: gray1;
		padding: 20px 0;

		.TaxesTableModal__text {
			margin-bottom: 7px;
		}
	}

	// TaxesTableModal--taxIdReminderModal
	&--taxIdReminderModal {
		.TaxesTableModal__text {
			margin-bottom: 20px;
		}
	}
}

.CompanyTaxesDateSelectOption {
	// CompanyTaxesDateSelectOption__text
	&__text {
		margin-right: 5px;
	}
	// CompanyTaxesDateSelectOption__date
	&__date {
		fab-color: gray6;
	}
}
