.DynamicForm--static {
    // .DynamicForm--static__dash
    &__dash {
        bottom: 8px;
        font-weight: bold;
        margin: 0px 20px;
        padding: 0px;
        position: relative;
    }

    // .DynamicForm--static__payrollInformatio
    &__payrollInformation {

        // .DynamicForm--static__payrollInformationText
        &Text {
            color: fab-color(info);
            fab-font-weight: semibold;
            display: inline;
        }

        // .DynamicForm--static__payrollInformationIcon
        &Icon {
            display: inline-block;
            margin-right: 5px;
            position: relative;
            top: 2px;
        }
    }
}
