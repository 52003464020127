// .ba-BlankState
.ba-BlankState {
	align-items: center;
	fab-color: gray6;
	cursor: default;
	display: flex;
	flex-direction: column;
	justify-content: center;
	text-align: center;
	user-select: none;
	width: 100%;

	&--topSpacing {
		padding-top: 72px;
	}

	// .ba-BlankState__icon
	&__icon {
		fab-fill: gray4;
		margin-bottom: 32px;

		+encore() {
			margin-bottom: 24px;
		}
	}

	// .ba-BlankState__title
	&__title {
		fab-color: gray6;
		margin: 0;
		width: 100%;

		+legacy() {
			font-family: BhrHeaderFontStack;
			font-size: 22px;
			line-height: 26px;
		}

		+encore() {
			fab-color: gray8;
			fab-text: h3;
		}
	}

	// .ba-BlankState__subtitle
	&__subtitle {
		fab-text: large;
		margin-top: 8px;
		max-width: 512px;
		width: 100%;

		&--wide {
			max-width: 612px;
		}

		+encore() {
			fab-color: gray7;
			fab-text: medium;
		}
	}

	// .ba-BlankState__button
	&__button {
		margin-top: 24px;
	}
}
