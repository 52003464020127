.GetStarted {
	align-items: center;
	display: flex;
	flex-direction: column;
	padding: 30px 98px 0;

	// .GetStarted__header
	&__header {
		fab-color: theme;
		margin: 0 0 30px;
		text-align: center;
		width: 100%;
	}

	// .GetStarted__getStartedBtn
	&__getStartedBtn {
		display: flex;
		justify-content: center;
		margin: 30px 0;
	}

	// .GetStarted__info
	&__info {
		fab-color: gray9;
		fab-font-size: medium;
		fab-line-height: medium;
		text-align: center;
		margin-bottom: 30px;

		// .GetStarted__info&:last-child
		&:last-child {
			margin-bottom: 0;
		}
	}

	// TODO: Remove styles below once we get the video for this page from product.
	// .GetStarted__iconWrapper
	&__iconWrapper {
		fab-fill: gray5;
		margin: 76px 0 93px;
	}
}
