.SetupTourSettings {

	/* .SetupTourSettings__divider */
	&__divider {
		border-top: 1px solid #afafaf;
		margin: 22px 0;
	}

	/* .SetupTourSettings__mainText */
	&__mainText {
		font-size: 15px;
		margin: 10px 0 22px;
	}

	/* .SetupTourSettings__subtitle */
	&__subtitle {
		fab-color: theme;
	}
}
