.Columns {

	// .Columns__status
	&__status {

		// .Columns__statusNotEnrolled
		&NotEnrolled {
			fab-color: gray7;
		}

		// .Columns__status--inactive
		&--inactive {
			fab-color: gray6;
		}
	}
}
