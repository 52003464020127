.EmployeeWellbeingMarketingPromo {
	text-align: center;

	&__container {
		margin-left: auto;
		margin-right: auto;
		max-width: 618px;
		padding-top: 40px;
		overflow: hidden;
	}

	&__title {
		font-family: unset;
		font-size: 32px;
		fab-font-weight: semibold;
		fab-color: theme-base;
		line-height: 42px;
		margin-top: 18px;
		max-width: 618px;
		margin-left: auto;
		margin-right: auto;
	}

	&__video {
		height: 430px;
		position: relative;
		top: -120px;
		margin-bottom: -120px;
	}

	&__banner {
		margin-top: 40px;
	}

	&__button {
		margin-top: 40px;

		&Text {
			font-size: 18px;
			padding: 0 10px;
		}
	}

	&__contactMessage {
		margin-top: 40px;

		&Content {
			border-radius: 4px;
			border: 1px solid;
			font-size: 17px;
			padding: 5px 20px;
		}
	}

	&__summary {
		fab-color: gray7;
		font-size: 18px;
		line-height: 26px;
		margin: 40px 0 0;
	}

	&__copyright {
		color: $bhrColors.gray10;
		font-size: 9px;
		line-height: 11px;
		margin-top: 60px;
	}

	&__previewButtons {
		display: flex;
		justify-content: center;
		align-items: center;

		div {
			padding: 4px 16px;
			fab-color: info;

			a {
				fab-fill: info;
			}

			&:first-child {
				border-right: 1px solid fab-color(gray4);
			}
		}

		span {
			margin-left: 8px;
			font-size: 15px;
			line-height: 22px;
		}
	}

	&__getStartedButtonContainer {
		display: flex;
		justify-content: center;
		align-items: center;
		margin-top: 20px;
	}

	&__marketingPromoContactMessage {
		margin-top: 40px;
	}
}
