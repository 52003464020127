// .InterstitialAuth
.InterstitialAuth {
	min-height: 450px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	// .InterstitialAuth__Auth
	&__Auth {
		display: flex;
		flex-direction: column;
		flex-grow: 1;
		justify-content: flex-end;
		padding-bottom: 120px;
		text-align: center;
	}
	// .InterstitialAuth__Code
	&__Code {
		margin-top: 20px;
	}
	// .InterstitialAuth__Footer
	&__Footer {
		display: flex;
		flex-shrink: 0;
		// .InterstitialAuth__FooterAction
		&Action {
			// .InterstitialAuth__FooterAction:not(:first-of-type)
			&:not(:first-of-type) {
				margin-left: 12px;
			}
			// .InterstitialAuth__FooterAction--last
			&--last {
				display: flex;
				flex-grow: 1;
				justify-content: flex-end;
			}
		}
	}
	// .InterstitialAuth__Instructions
	&__Instructions {
		&Error {
			fab-color: danger;
			margin-top: 10px;
		}
	}
	// .InterstitialAuth__RememberMe
	&__RememberMe {
		display: flex;
		justify-content: center;
		align-items: center;
		margin-top: 30px;
	}
}
