$theme = '--settings-subnav-theme';

.BaTabs {
	&&{$theme} {
		display: flex;
		flex-direction: row;
	}

	&__tab {
		&&{$theme} {
			padding: 5px 0;
			line-height: 1.1em;
		}

		&&--active&{$theme} {

		}

		&&--disabled&{$theme} {

			&:hover {
			}
		}

		&:not(&--active):not(&--disabled)&{$theme} {
			cursor: default;
		}

		&Label {
			&&{$theme} {
				display: inline-block;
			}

			&&--primary&{$theme} {
				font-size: 15px;
				line-height: 1.1em;
				color: #2;
				cursor: pointer;
			}

			&&--primary&--active&{$theme} {
				font-weight: 600;
			}

			&&--primary:not(&--active):not(&--disabled)&{$theme} {
				cursor: pointer;

				&:hover {
					color: $bhrColors.textLink;
				}
			}

			&&--secondary&{$theme} {
				font-size: 13px;
				color: #8;
				padding-left: 3px;
			}
		}

		&Icon {
			&&{$theme} {
				padding-right: 6px;
				transform: translateY(3px);

				> svg {
					size: 16px;
				}
			}
		}

		&Wrapper {
			&&{$theme} {
				flex-direction: column;
				align-items: stretch;
				width: 19%;
				padding: 22px 1.59% 45px;
				border-width: 0;
				border-right-width: 1px;
				border-right-style: solid;
			}

			&--transparent {
			}

			&Header {
				&&{$theme} {
					padding: 10px 0 13px;
					display: flex;
					flex-direction: row;
					justify-content: space-between;
				}
				
				&Actions {
					&&{$theme} {
						order: 10;
					}
				}
			}

			&Icon {
				&&{$theme} {
					padding-top: 1px;
					padding-right: 6px;

					> svg {
						size: 16px;
					}
				}
			}

			&Title {
				&Wrapper {
					&&{$theme} {
						display: flex;
						flex-direction: row;
						font-size: 19px;
					}
				}
			}
		}

		&Group {
			&&{$theme} {
				border-bottom: 1px solid #af;
				padding: 1px 0 5px 10px;
				margin-bottom: 5px;
			}

			&&--empty&{$theme} {
				display: none;
			}

			&Header {
				&&{$theme} {
				}
			}

			&Icon {
				&&{$theme} {
					padding-right: 6px;
					transform: translateY(3px);
					margin-left: -10px;

					> svg {
						size: 16px;
					}
				}
			}

			&Label {
				&&{$theme} {
					display: inline-block;
					color: #8;
					font-size: 13px;
					line-height: 1.1em;
					padding: 5px 0;
				}
			}
		}

		&Content {
			&&{$theme} {
				padding: 22px 0 45px 4.4%;
			}
			
			&Header {
				&&{$theme} {
					display: flex;
					flex-direction: row;
					justify-content: space-between;
				}
				
				&Actions {
					&&{$theme} {
						order: 10;
					}
				}
			}

			&Wrapper {
				&&{$theme} {
				}
			}

			&Title {
				&&{$theme} {
					font-size: 19px;
					line-height: @font-size;
					padding-top: 10px;
					margin-bottom: 22px;
				}
			}

			&Subtitle {
				&&{$theme} {
					padding-left: 0.4em;
				}
			}
		}
	}
}
