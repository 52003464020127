$buttonColumnWidth = 80px;

.EmployeeSelectorReact {
	display: flex;
	margin: 10px 0;
	padding: 0;
	max-width: 780px;

	+encore() {
		margin: 0 !important;
	}

	&__container {
		width: "calc((100% - %s) / 2)" % $buttonColumnWidth;
	}

	&__title {
		margin-bottom: 2px;
		// @startCleanup encore
		+encore() {
			// @endCleanup encore
			fab-font-size: small;
			color: var(--gray9);
			// @startCleanup encore
		}
		// @endCleanup encore
		// @startCleanup encore
		fab-font-weight: bold;
		// @endCleanup encore
		// These styles are being overriden by a `+jade()` mixin somehow. No indication of explicit definitions in the repo. Probably some undesired inclusion order that can't be tracked down right now.
		+encoreHighSpecificity() {
			fab-font-weight: medium;
		}
	}

	&__wrapper {
		background-color: fab-color(gray1);
		border: 1px solid fab-color(gray4);
		box-sizing: border-box;
		display: flex;
		flex-direction: column;
		height: 374px;
		user-select: none;
		// @startCleanup encore
		justify-content: center;
		+encore() {
			// @endCleanup encore
			justify-content: start;
			overflow: clip;
			// @startCleanup encore
		}
		// @endCleanup encore
		// @startCleanup encore
		border-radius: 2px;
		// @endCleanup encore
		// These styles are being overriden by a `+jade()` mixin somehow. No indication of explicit definitions in the repo. Probably some undesired inclusion order that can't be tracked down right now.
		+encoreHighSpecificity() {
			border-radius: 16px;
		}
	}

	&__selects {
		align-items: center;
		box-sizing: border-box;
		display: flex;
		flex-shrink: 0;
		// @startCleanup encore
		+encore() {
			// @endCleanup encore
			margin-bottom: 8px;
			// @startCleanup encore
		}
		// @endCleanup encore
		// @startCleanup encore
		height: 57px;
		padding-left: 12px;
		border-bottom: 1px solid fab-color(gray4);
		// @endCleanup encore
		// These styles are being overriden by a `+jade()` mixin somehow. No indication of explicit definitions in the repo. Probably some undesired inclusion order that can't be tracked down right now.
		+encoreHighSpecificity() {
			height: max-content;
			padding: 16px 16px 0;
			border-bottom: none;
		}
	}

	&__addSelectIcon {
		align-items: center;
		cursor: pointer;
		display: flex;
		fill: fab-color(gray7);
		height: 20px;
		justify-content: center;
		margin-left: 6px;
		width: 20px;

		&:hover {
			fill: fab-color(theme-base);
		}
	}

	&__plusSelectIcon {
		align-items: center;
		display: flex;
		font-size: 18px;
		height: 28px;
		justify-content: center;
		width: 30px;
	}


	&__search {
		&Container {
			position: relative;

			+encore() {
				padding: 0 16px 16px;
				border-bottom: 1px solid fab-color(gray4);
			}
		}

		&Input {
			box-shadow: 0 1px 2px -1px rgba(0, 0, 0, 0.1);
			box-sizing: border-box;
			height: 42px;
			width: 100%;
			border: 0;
			border-bottom: 1px solid fab-color(gray3);
			font-size: 14px;
			padding-top: 1px;
			padding-left: 33px;

			&Icon {
				position: absolute;
				left: 10px;
				top: 12px;
				fill: fab-color(gray5);
			}
		}
	}

	&__list {
		background-color: fab-color(white);

		&Container {
			flex: 2;
			overflow: auto;
			+encore() {
				border-radius: 0 0 16px 16px;
			}
		}

		&Item {
			align-items: center;
			box-sizing: border-box;
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			border-bottom: 1px solid fab-color(gray3);
			font-size: 14px;
			height: 42px;
			padding: 0 10px;

			&:hover {
				background-color: fab-color(theme-lightest10);
			}

			&--disabled {
				color: fab-color(gray6);
			}

			&--highlighted {
				&:hover {
					background-color: fab-color(theme-base);
				}
				background-color: fab-color(theme-base);
				color: fab-color(white);
				font-weight: 600;
			}

			&__mainContent {
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
			}

			&__textRight {
				color: fab-color(gray6);
				flex: 0 0 auto;
				font-size: 13px;
				padding: 0 10px 0 10px;
				max-width: 50%;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;

				&Icon {
					fab-fill: gray6;
					margin-right: 7px;

					&--highlighted {
						fab-fill: white;
					}
				}

				&--highlighted {
					color: fab-color(white);
				}
			}
		}
	}

	&__buttons {
		align-items: center;
		display: flex;
		flex-direction: column;
		flex-shrink: 0;
		justify-content: center;
		// @startCleanup encore
		margin-top: 58px;
		// @endCleanup encore
		width: $buttonColumnWidth;

		+encore() {
			margin-top: 12px;
			padding: 0 4px;
		}
	}

	&__btn {
		// @startCleanup encore
		margin-bottom: 4px;
		// @endCleanup encore
		+encore() {
			margin-bottom: 8px;
		}
	}

	&__noFilterMatchBlankState {
		color: fab-color(gray5);
		fab-font-size: h4;
		fab-line-height: h4;
		fab-font-weight: bold;
		margin-top: 40px;
		text-align: center;
		padding: 0 20px;
	}

	&__rightBlankState {
		/* @startCleanup encore */
		color: fab-color(gray5);
		fab-font-size: h3;
		fab-line-height: h3;
		fab-font-weight: bold;
		text-align: center;
		padding: 0 20px;
		/* @endCleanup encore */
		position: relative;

		&Icon {
			fill: fab-color(gray4);
			/* @startCleanup encore */
			margin-bottom: 10px;
			/* @endCleanup encore */

			+encore() {
				margin-bottom: 20px;
			}
		}

		+encore() {
			font-family: Inter;
			font-size: 15px;
			line-height: 22px;
			text-align: center;
			font-weight: var(--font-weight-regular);
			color: var(--gray7);
			margin-top: calc(50% - 39px);
			padding: 0 40px;
		}

		&__text {
			text-wrap: pretty;
		}
	}
}
