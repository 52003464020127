.SettingsUsersEmailValidate .SiteFooter {
	display: none;
}

/* @startCleanup encore */
.EmailVerification {
	text-align: center;
	padding-top: 9px;

	// .EmailVerification__header
	&__header {
		fab-color: gray10;
		fab-font-weight: medium;
		margin-top: 19px;
	}

	// .EmailVerification__instruction
	&__instruction {
		fab-color: gray9;
		fab-font-size: medium;
		margin: 0;

		// .EmailVerification__instructionContainer
		&Container {
			margin: 15px 0 19px;
		}
	}

	// .EmailVerification__email
	&__email {
		fab-color: theme;
	}

	// .EmailVerification__resend
	&__resend {
		fab-font-size: small;
		fab-color: gray7;
	}
}
/* @endCleanup encore */
