.EnpsSurveyBodyStyles {
	background: linear-gradient(180deg, #EEEEEE 0, #FBFBFB 100%);
}

.SurveyWrapper {
	display: flex;
	justify-content: center;
	width: 100%;

	&__content {
		margin-top: 32px;
		width: 800px;

		&--small {
			width: 590px;
		}
	}

	&__body {
		/* @startCleanup encore */
		padding: 16px 30px 40px 30px;
		/* @endCleanup encore */

		+encore() {
			padding: 16px 30px 0 30px;
		}

		&--full-bleed {
			display: flex;
			justify-content: center;
			padding: 0;
		}
	}

	&__customFooter {
		align-items: center;
		display: inline-flex;
		font-size: 15px;

		&Divider {
			margin: 0 3px;
		}

		&--muted {
			fab-color: gray7;
			fab-fill: gray7;
		}

		& > * {
			margin: 0 5px;
		}
	}
}

@media only screen and (max-width: 800px) {
	.SurveyWrapper {
		&__content {
			margin-top: 0;
			width: 100%;

			&--small {
				width: 100%;
				max-width: 590px;
			}
		}
	}
}