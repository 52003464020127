.OptionalSelect {
	margin-top: 4px;

	/* .OptionalSelect__help > .HelpPopover */
	&__help > .HelpPopover {
		position: absolute;
		top: 11px;
		right: -25px;
	}
}
