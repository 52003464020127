// .PlaceholderWidget
.PlaceholderWidget {
	height: 100%;
	fab-background-color: gray1;

	+encore() {
		font-family: $encoreFontFamilyStack;
		overflow-y: hidden;
		height: 100%;
		background-color: var(--gray05);
	}
	// .PlaceholderWidget__scroll
	&__scroll {
		height: 100%;
		overflow-y: auto;
		// .PlaceholderWidget__scroll .fixed
		&.fixed {
			height: calc(100vh - 120px);
			position: fixed;
			top: 22px;
			width: 358px;
			+encore() {
				position: sticky;
				top: 108px;
			}
		}
	}
	// .PlaceholderWidget__list
	&__list {
		user-select: none;
		// .PlaceholderWidget__listEmpty
		// .PlaceholderWidget__listHeader
		// .PlaceholderWidget__listFilterWrap
		// .PlaceholderWidget__listItem
		&Empty,
		&Header,
		&FilterWrap,
		&Item {
			position relative;
			// .PlaceholderWidget__list :after
			&:after {
				content: '';
				background-color: #e5;
				height: 1px;
				width: calc(100% - 8px);
				position: absolute;
				left: 5px;
				bottom: 0;

				+encore() {
					width: 100%;
					left: 0;
				}
			}
		}
		// .PlaceholderWidget__listSearch
		&Search {
			align-items: center;
		}
		// .PlaceholderWidget__listHeader
		&Header {
			min-width: 240px;
			padding: 15px 0 0 12px;
			margin: 0 23px 3px 20px;

			+encore() {
				margin: 0;
				padding: 0 32px 24px 32px;
			}

			// .PlaceholderWidget__listHeaderTitle
			&Title{
				display: flex;
				align-items: center;
				top: 1px;

				+encore() {
					margin: 0;
				}

				// .PlaceholderWidget__listHeaderTitleIcon
				&Icon {
					margin-right: 5px;
					fab-fill: gray7;
					+encore() {
						margin-right: 12px;
						height: 20px;
					}
				}
			}
			// .PlaceholderWidget__listHeaderText
			&Text {
				fab-color: gray7;
				fab-text: small;

				+encore() {
					fab-font-size: medium;
					margin-top: 16px;
				}
			}
			// .PlaceholderWidget__list:after
			&:after {
				bottom: -10px;

				+encore() {
					bottom: 0;
				}
			}
		}
		// .PlaceholderWidget__listFilter
		&Filter {
			// .PlaceholderWidget__listFilter--muteFocusOnLoad
			// see explanatory comments in JS/Twig
			&--muteFocusOnLoad {
				border-color: fab-color(gray4);
				box-shadow: none;
				outline: none;
			}
		}
		// .PlaceholderWidget__listFilterWrap
		&FilterWrap {
			margin: 0 23px 0 20px;
			padding: 15px 0 11px 12px;
			position: relative;

			+encore() {
				padding: 24px 32px 24px 32px;
				margin: 0;
			}
		}
		// .PlaceholderWidget__listFilterClear
		&FilterClear {
			display: none;
			fab-fill: gray5;
			cursor: pointer;
			position: absolute;
			line-height: 16px;
			right: 6px;
			height: 20px;
			margin-top: 3px;
		}
		// .PlaceholderWidget__listEmpty
		&Empty {
			fab-color: gray7;
			fab-text: teenie;
			margin: 0 23px 0 20px;
			padding: 22px 0 24px 12px;

			+encore() {
				margin: 0;
				padding: 16px 32px 16px 32px;
				fab-text: small;
			}
		}
		// .PlaceholderWidget__listFooter
		&Footer {
			fab-color: gray7;
			fab-text: teenie;
			margin: 0 23px 0 20px;
			padding: 13px 0 27px 12px;

			+encore() {
				margin: 0;
				padding: 16px 32px 16px 32px;
				fab-text: medium;
			}

			// .PlaceholderWidget__listFooterTitle
			&Title {
				fab-text: small;
				fab-color: gray8;
				font-weight: 600;

				+encore() {
					margin: 0 0 4px 0;
					fab-text: medium;
				}
			}
		}
		// .PlaceholderWidget__listItem
		&Item {
			position: relative;
			fab-text: small;
			box-sizing: border-box;
			margin: -1px 19px 0 20px;
			border: 1px solid transparent;

			+encore() {
				margin: -1px 0 0 0;
				fab-font-size: medium;
			}

			// .PlaceholderWidget__listItem:after
			&:after {
				bottom: -1px;
			}

			// .PlaceholderWidget__listItem:hover:not(.dragEnded)
			&:hover:not(.dragEnded) {
				fab-background-color: white;
				box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
				border: 1px solid fab-color(gray4);
				cursor: pointer;

				// .PlaceholderWidget__listItem:hover:not(.dragEnded):after
				&:after {
					height: 0;
				}
			}

			// .PlaceholderWidget__listItem:last-of-type:after
			&:last-of-type:after {
				height: 0;
			}

			// .PlaceholderWidget__listItem-name
			&-name {
				fab-text: small;
				fab-color: gray10;
				font-weight: 600;
				margin-left: 4px;

				+encore() {
					fab-text: medium;
					margin: 0 0 4px 0;
				}
			}
			// .PlaceholderWidget__listItem-example
			&-example {
				fab-color: gray7;
				fab-text: teenie;
				margin-left: 4px;

				+encore() {
					fab-text: small;
					margin-left: 0px;
				}
			}

			// .PlaceholderWidget__listItem img
			img {
				position: absolute;
				top: 0;
				bottom: 0;
				left: 0;
				right: 0;
				width: calc(100% - 2px);
				height: calc(100% - 1px);
				opacity: 0;
			}

			// .PlaceholderWidget__listItem-wrap
			&-wrap {
				padding: 11px 7px 11px;
				pointer-events: none;

				+encore() {
					padding: 16px 32px 17px 32px;
				}
			}
		}
	}
}

.BhrForms input[type="text"].PlaceholderWidget__listFilter {
	&::-ms-clear {
		display: none;
	}
}
