.OptionalCheckbox {
	margin-bottom: 0 !important; // Override BhrForms style... :(

	/* .OptionalCheckbox__label */
	&__label {
		margin-bottom: 5px !important; // Override BhrForms style... :(
	}

	/* .OptionalCheckbox__tempCheckbox */
	&__tempCheckbox {
		margin: 3px 0;
	}

	/* .OptionalCheckBox__help > .HelpPopover */
	&__help > .HelpPopover {
		position: absolute;
		top: 5px;
		right: -3px;
	}
}
