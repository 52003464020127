.PS_Calendar {
	padding: 0 0 32px 0;

	&__adjustedIcon {
		height: 13px;
		position: relative;
		display: inline-flex;
		fill: fab-color(gray6);
		line-height: 0;
		top: 0;
		margin: 0 4px 0 8px;
		&:hover {
			fill: fab-color(theme);
		}
	}
}
