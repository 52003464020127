.BenefitPlans {

	// .BenefitPlans__blank
	&__blank {
		margin-top: 72px;
	}

	// .BenefitPlans__loader
	&__loader {
		left: 50%;
		position: absolute;
		top: 80%;
	}
}
