.StateWithholding {
	/* .StateWithholding__sectionNoteContainer */
	&__sectionNoteContainer {
		align-items: flex-start;
		display: flex;
		fab-color: 'info';
		fab-fill: 'info';
		fab-font-size: 'small';
		fab-font-weight: 'semibold';
		margin-bottom: 8px;
	}

	/* .StateWithholding__sectionNoteIcon */
	&__sectionNoteIcon {
		margin-top: 3px;
	}

	/* .StateWithholding__sectionNoteText */
	&__sectionNoteText {
		margin-left: 4px;
	}

	/* .StateWithholding__select */
	&__select {
		margin-top: 4px;
	}
}
/* Override form style */
.OptionalCheckbox {
	/* .OptionalCheckbox ~ .OptionalCheckbox */
	& ~ .OptionalCheckbox {
		/* .OptionalCheckbox ~ .OptionalCheckbox .OptionalCheckbox__label */
		.OptionalCheckbox__label {
			display: none;
		}
	}
}

.OptionalCheckbox ~ *:not(.OptionalCheckbox) {
	margin-top: 16px;
}

.StateWithholding {
	/* .StateWithholding__exempt */
	&__exempt {
		padding-top: 22px;
	}
}
