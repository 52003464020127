.TaskRow {
	margin: 4px 0 8px 24px;

	&__deleteAction {
		cursor: pointer;
		margin-left: 12px;

		&--hasLabel {
			margin-top: 20px;
		}
	}
}
