.NHPTemplateSettings {
	padding-top: 6px;

	//.NHPTemplateSettings__wrapper
	&__wrapper {
		padding-bottom: 11px;

		&--empty {
			border-bottom: solid 1px fab-color(gray3);
		}
	}
}

.NHPTemplateListBlankState__wrapper {
	margin-top: 108px;
}
