@require "./pto";

#departmentFilter, #employmentStateFilter, #locationFilter, #jobTitleFilter, #divisionFilter, #dobFilter, #hireDateFilter {
	display:none;
}

label+span.description {
	margin-left: 15px;
}

.emailAlerts #edit_form checkbox { vertical-align: middle; }
.emailAlerts .description {
	color: fab-color(gray7);
	fab-font-size: teenie;
}
.emailAlerts .reminder-row { 
	margin-bottom: 8px; 
	+encore() {
		display: flex;
		align-items: center;
	}
}

.emailAlerts .block-form p{margin: 5px 0 0 0}
.emailAlerts .block-form li {clear: left}
.emailAlerts #otherFields {
	margin:5px 0 0 0;
	/*border: 1px solid #CCCCCC;
	background-color: #FFFFFF;
	border-radius: 3px;
	-moz-border-radius: 3px;
	-webkit-border-radius: 3px;	*/
}
.emailAlerts #otherFields li{
	display: inline-block;
	width: 30%;
	zoom:1;
	*display: inline;
	_height: 30px;
}
.emailAlerts span.item{ margin: 4px 2px 2px }

.emailAlerts  #alertWho label{
	display: inline-block;
	margin-right: 8px;
}

.emailAlerts .inlineGroup{
	float: left;
	margin: 0 10px 5px 0;
}
.emailAlerts h3 {
	margin-bottom: 10px;
}
.emailAlerts hr {
	margin-top:20px;
}

	.reminder-row .bicon-remove{
		margin-top:10px;
		margin-left:5px;
	}
span.input {
	margin-top: 0;
}
.input-list input.free {
	margin-top: 0;
}
.emailAlerts .input-list.shown{
  min-height: 45px;
}

.SettingsEmailAlerts {
	&__otherField {
		margin-top: 2px;
	}

	&__sendToCheckbox {
		&[name="useInputList"] {
			&:checked {
				~ .formNote .SettingsEmailAlerts__otherFieldNote {
					display: none;
				}
			}

			&:not(:checked) {
				~ .formNote .SettingsEmailAlerts__otherField {
					display: none !important;
				}
			}
		}
	}
}
