.TosConsent__box {
	background-color: #f4f4f4;
	padding: 20px 20px 24px;
	margin: 32px 0 24px;
}

.TosConsent__copy {
	color: #686868;
	font-size: 13px;
	margin-bottom: 20px;
	text-align: left;
}

.TosConsent__buttons {
	text-align: left;
}
