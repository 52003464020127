.DDModal {

	/* .DDModal__buttonLinks */
	&__buttonLinks {
		display: flex;
		justify-content: space-between;
	}

	/* .DDModal__cardMaxText */
	&__cardMaxText {
		fab-color: gray6;
		font-size: 13px;
	}

	/* .DDModal__paperCheck */
	&__paperCheck {
		display: flex;
		align-items: center;
		border-top: 1px solid fab-color(gray3);
		padding-top: 15px;
		margin-top: 20px;
		transition: padding 1s, opacity 1s, margin-top 1s, height 1s;

		/* .DDModal__paperCheck--hidden */
		&--hidden {
			padding-top: 0;
			opacity: 0;
			margin-top: 0;
			height: 0;
		}

		/* .DDModal__paperCheckIcon */
		&Icon {
			display: inline-block;
			fab-fill: gray6;
			margin-top: 6px;
		}

		/* .DDModal__paperCheckText */
		&Text {
			margin-left: 10px;
			font-size: 13px;
			fab-color: gray7;
			padding-top: 5px;
		}
	}

	/* .DDModal__checkingText */
	&__checkingText {
		text-align: center;
	}
}

