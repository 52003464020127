/* $FILELIST MODULE
styles for displaying list of files (add file modal in NHP)
.FileList--minimal is on assessment (view state)
*/
.FileListModal {
	&__subHeader {
		height: 36px;
		margin-top: 20px;
		padding: 16px;
		background-color: fab-color(gray1);
		border-bottom: solid fab-color(gray3) 1px;
	}
}

.FilesList {
	&__count {
		color: fab-color(gray8);
		fab-line-height(teenie);
		fab-font-size(teenie);
		fab-font-weight(regular);
		display: flex;
		flex-direction: column;
		justify-content: center;
		height: 33px;
	}
}

.FileList {
	width: 100%;
	// .FileListodal .chzn-container
	&Modal .chzn-container {
		margin-top: 2px;
	}
	// .FileList__row
	&__row {
		display: flex;
		border-bottom: solid fab-color(gray3) 1px;
		margin: 0;
		padding: 0 16px;
		height: 64px;
		align-items: center;
		// .FileList__row:hover
		&:hover {
			background: fab-color(gray1);
			cursor: pointer;
		}
		// .FileList__row--active, .FileList__row--active:hover
		&--active,
		&--active:hover {
			background: fab-color(theme-lightest10);
		}
		// .FileList__row--active
		&--active {
			// .FileList__row--active .FileList
			& .FileList {
				// .FileList__row--active .FileList__actions
				&__actions {
					opacity: 1;
					transition: opacity 0.15s linear;
					visibility: visible;
				}
				// .FileList__row--active .FileList__info-title
				&__info-title {
					color: fab-color(gray10);
					fab-font-weight(semibold);
				}
			}
		}
		// .FileList__row--disabled
		&--disabled {
			cursor: default !important;
			// .FileList__row--disabled:hover
			&:hover {
				background: transparent;
			}
			// .FileList__row--disabled > div
			& > div {
				opacity: 0.4;
			}
			// .FileList__row--disabled
			& label {
				// the way we build checkboxes caused some weirdness with firefox running in windows.
				z-index: 9999;
			}
		}
	}

	// .FileList__check
	&__check {
		align-items: center;
		display: flex;
		width: 35px;
		// .FileList__check > label
		& > label {
			width: 16px;
		}
	}
	// .FileList__icon
	&__icon {
		align-items: center;
		display: flex;
		margin-right: 5px;
		padding: 1px 0;
		width: 27px;
	}
	//.FileList__info
	&__info {
		flex: 1;
		min-width: 0;
	}

	// .FileList__title, .FileList__info
	&__info-title,
	&__info-info {
		display: block;
		fab-line-height(teenie);
	}
	//.FileList__title
	&__info-title {
		color: fab-color(gray8);
		fab-font-size(medium);
	}
	//.FileList__info-info
	&__info-info {
		fab-font-size(teenie);
		color: fab-color(gray7);
	}

	//.FileList__actions
	&__actions {
		align-items: center;
		display: flex;
		margin-left: 10px;
		opacity: 0;
		visibility: hidden;
		width: 38px;
	}

	// Variants
	// .FileList--scrollable
	&--scrollable {
		overflow-y: auto;
		/*set height for specific instance*/
	}
	// .FileList--minimal
	&--minimal {
		// .FileList--minimal .FileList__icon
		.FileList__icon {
			margin-right: 5px;
		}
		// .FileList--minimal .FileList__info
		.FileList__info {
			width: 90%;
		}
		// .FileList--minimal .FileList__info-info
		.FileList__info-info {
			color: #7;
			fab-font-size(teenie);
			margin-top: -3px;
		}
		// .FileList--minimal .FileList__row
		.FileList__row {
			padding: 0 0 17px;
			&:last-child {
				padding-bottom: 0;
			}
		}
		// .FileList--minimal .FileList__row, .FileList--minimal .FileList__row:hover
		.FileList__row,
		.FileList__row:hover {
			background: transparent;
			border: none;
		}
		// .FileList--minimal .FileList__info-title
		.FileList__info-title {
			display: inline-block;
			margin-bottom: 0;
			position: relative;
			top: -2px;
			width: 100%;
		}
	}
}
