.QuestionsList {
	margin-bottom: 6px;

	// .QuestionsList__questions
	&__questions {
		margin-left: 24px;
	}

	&__questionsNote {
		fab-text: small;
		fab-color: gray8;
		margin-bottom: 3px;
	}

	&__addQuestion {
		margin-left: 24px;
	}
}
