.Webhook {
	&__header {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
	}

	&__dropdown {
		display: flex;
		flex-direction: row;
		align-items: center;	
	}

	&__type {
		display: flex;
		flex-direction: column;	
	}

	&__subtype {
		font-size: 14px;
		color: fab-color(gray6);
	}

	&__label {
		margin-right: 10px;
		color: fab-color(gray8);
		font-size: 14px;
	}

	&__icon {
		margin-left: 5px;
	}
}