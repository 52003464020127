.CompanyTaxesWrapper {
	// .CompanyTaxesWrapper__header
	&__header {
		align-items: center;
		display: flex;

		// .CompanyTaxesWrapper__headerIcon
		&Icon {
			fab-fill: theme;
			display: flex;
			margin-right: 9px;
		}
	}
}
