.TransactionMethodSelector {
	.TransactionMethodSelector__groupLabel {
		margin-bottom: 12px; 
	}

	.TransactionMethodSelector__otherBank {
		margin-left: 24px;
	}

	.TransactionMethodSelector__otherBank > div {
		margin-bottom: 12px;
	}

	.TransactionMethodSelector__wireTransferMessage {
		margin-left: 24px;
	}
}
