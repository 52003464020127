form .fieldDiv > .description {
	color: #000;
}

table#AlertDates {
	border: none;
}

.alertDateTableWrapper.collapsed {
	padding-bottom: 3em;
}

.alertDateTableWrapper.collapsed > div {
	height: 120px;
	max-height: 120px;
	overflow: hidden;
	position: relative;
}

.alertDateTableWrapper.collapsed > div:before {
	content: "";
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	display: block;
	position: absolute;
	background: -moz-linear-gradient(top, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 1) 100%);
	background: -webkit-gradient(linear, left top, left bottom, color-stop(0,rgba(255, 255, 255, 0)), color-stop(100%, rgba(255, 255, 255, 1)));
	background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 1) 100%);
	background: -o-linear-gradient(top, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 1) 100%);
	background: -ms-linear-gradient(top, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 1) 100%);
	background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 1) 100%);
	z-index: 1;
}

.alertDateTableExpand {
	cursor: pointer;
	display: none;
}

.alertDateTableWrapper.collapsed .alertDateTableExpand {
	display: inline-block;
}

table#AlertDates .BhrTable__body {
	position: relative;
	display: block;
	border-top: 2px solid #d9d9d9;
}

table#AlertDates .BhrTable__body:before {
	content: attr(data-year);
	background: #fff;
	position: absolute;
	display: inline-block;
	top: -2px;
	right: 0;
	transform: translateY(-50%);
	font-size: 0.75em;
	line-height: 0.75em;
	padding: 5px;
}


table#AlertDates .BhrTable__body:first-of-type {
	border-top: none;
	min-height: 10px;
}

table#AlertDates .BhrTable__body:first-of-type:before {
	display: none;
}

table#AlertDates {
	.dateCell,
	.dayCell {
		white-space: nowrap;
	}
	.dayCell--warning {
		fab-color(attention);
		fab-font-weight: semibold;
	}
}

.weekend-msg--warning {
	fab-color(attention);
	fab-font-weight: semibold;
}

table#AlertDates .actionCell input.calendar-field {
	background: transparent url('https://staticfe.bamboohr.com/resources/images/bicons/inline-icons.png') -1px -46px;
	color: transparent;
	border: 1px solid transparent;
	padding: 0;
	margin: 0;
	width: 20px;
	height: 20px;
	opacity: 0;
	cursor: pointer;
	box-shadow: none;
}

table#AlertDates .BhrTable__row:hover .actionCell input.calendar-field {
	opacity: 0.7;
}

table#AlertDates .BhrTable__row .actionCell input.calendar-field:hover {
	opacity: 1;
	border-color: #d9d9d9;
}
