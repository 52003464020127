.CompanyTaxField {
	.CompanyTaxField__errorLabel {
		display: none;
	}
	.fab-Textarea {
		resize: none;
	}

	.fab-Label {
		// fab-Label__unit
		&__unit {
			margin-left: 4px;
		}

		.icon-wrapper {
			display: flex;
			margin-right: 5px;
			fab-fill: danger;
		}
	}

	// CompanyTaxField__content
	&__content {
		display: flex;
		align-items: center;

		.CompanyTaxField__additionalContent {
			margin-left: 20px;
		}
	}

	&__label {
		&--optional {
			fab-color:	gray6;
			margin-left: 5px;
		}
	}

	// CompanyTaxField--disabled
	&--disabled {
		.fab-Label {
			fab-color: gray6;
		}

		input, textarea {
			fab-border-color: gray3;
			pointer-events: none;

			&::placeholder {
				fab-color: gray5;
				opacity: 1; /* Firefox */
			}

			&:-ms-input-placeholder { /* Internet Explorer 10-11 */
				fab-color: gray5;
			}

			&::-ms-input-placeholder { /* Microsoft Edge */
				fab-color: gray5;
			}
		}
	}

	// CompanyTaxField--error
	&--error {
		color: red;

		.CompanyTaxField__errorLabel {
			display: flex;
		}

		.fab-Label {
			fab-color: danger;

			// fab-Label__unit
			&__unit {
				fab-color: danger;
			}

			// .fab-Label--required::after
			&--required::after {
				fab-color: danger;
			}
		}
		.fab-TextInput {
			fab-border-color: danger;
		}
	}

	&--employerTaxId {
		.CompanyTaxField__content {
			.fab-TextInput {
				margin-right: 20px;
			}
		}
	}

	&--hidden {
		display: none;
	}

	&__clientIdOption {
		display: flex;

		&--name {
			margin-right: 3px;
		}

		// &--ein {
		// 	fab-color: gray7;
		// }
	}
}
