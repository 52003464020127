.EnpsRadioScale {

	&__scale {
		align-items: center;
		display: flex;
		height: 40px;
		justify-content: space-between;

		+encore() {
			height: 48px;
		}
	}

	&__divider {
		fab-background-color: gray3;
		bottom: 22px;
		height: 4px;
		position: relative;
		width: 100%;
		z-index: 1;

		+encore() {
			background-color: var(--gray2);
			bottom: 26px;
		}
	}

	&__legend {
		align-items: center;
		display: flex;
		fab-color: gray6;
		fab-font-size: teenie;
		fab-font-weight: semibold;
		justify-content: space-between;
		line-height: 16px;
		margin-bottom: 25px;
		text-transform: none;
		width: 100%;

		+encore() {
			color: var(--gray7);
			font-size: var(--font-size-small);
			font-weight: var(--font-weight-normal);
			line-height: var(--line-height-small);
			margin: 6px 0 0;
		}
	}
}
