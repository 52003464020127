.EnpsQuestion {
	overflow: hidden;
	max-height: 1000px;
	transition: max-height 600ms ease-in;

	&--hidden {
		max-height: 0;
	}

	& &__heading {
		fab-font-weight: semibold;
		font-size: 16px;
		line-height: 23px;
		margin-bottom: 3px;
		padding-left: 20px;
		text-indent: -20px;
	}

	&__number {
		padding-right: 7px;
	}

	&__subtitle {
		fab-color: gray7;
		fab-font-size: teenie;
		line-height: 16px;
		padding-left: 20px;

		+encore() {
			margin-top: 12px;
		}
	}

	& + &:not(&--hidden) {
		+encore() {
			margin-top: 40px;
		}
	}
}

@media only screen and (max-width: 800px) {
	.EnpsQuestion {
		+jadeFabricTheme() {
			margin-bottom: 35px;
		}

		& &__heading {
			fab-color: theme-base;
			font-size: 22px;
			font-weight: 600;
			line-height: 26px;
			margin-bottom: 24px;
			text-align: center;
		}

		&__heading--encore {
			text-align: center;
		}

		&__subtitle {
			padding-left: 0;
			text-align: center;

			+encore() {
				margin-top: 16px;
			}
		}
	}
}
