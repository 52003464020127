.EmployeeFieldsForm {
    .disabled .EmployeeFieldsForm__actions {
        display: none;
    }

    &__deleteWarning {
        text-align: center;
    }
    &__modal--legend {
        margin-bottom: 16px;
        fab-font-weight: medium;
    }
    &__modal--radiogroup .fab-Radio {
        margin-bottom: 16px;
    }
    &__modal--label {
        margin-bottom: 4px;
    }

}
