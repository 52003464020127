.STS_ {

	&Header {
		display: flex;
		align-items: center;

		&Icon {
			fab-fill: theme-base;
			margin: 5px 9px 0 0;

			&--trial {
				margin: 8px 9px 0 0;
			}
		}
	}

	/* .STS_SectionHeader */
	&SectionHeader {
		fab-color: gray10;
		font-size: 16px;
		font-weight: 600;
		margin: 15px 0 9px;
		padding: 18px 0 0;
		border-top: 1px solid fab-color(gray3);

		&--timesheetType {
			border-top: none;
			margin-top: 10px;
			padding: 0 0 10px;
		}
	}

	/* .STS_Select */
	&Select {
		margin: 2px 9px 0 0;
	}

	&formNote {
		margin-left: 0;
	}

	/* .STS_TimesheetTypeCard.STS_TimesheetTypeCard  (BhrForms override) */
	&TimesheetTypeCard&TimesheetTypeCard {
		margin-right: 12px;
	}

	/* .STS_TimesheetType */
	&TimesheetType {
		display: flex;
		padding: 21px 26px 14px 13px;
		height: 67px;
		width: 285px;

		&Card:hover {

			.STS_TimesheetType__icon {
				fab-fill: theme-base;
			}

			.STS_TimesheetType__name {
				fab-color: theme-base;
			}
		}

		// We have to hide the radio button for Jade, since it shows up.
		&Card input[type="radio"] {
			display: none;
		}

		/* .STS_TimesheetType__icon */
		&__icon {
			margin: 2px 12px 0 0;
			fab-fill: theme-base;

			/input[type="radio"]:not(:checked) + .ba-CheckboxCard & {
				fab-fill: gray7;
			}
		}

		/* .STS_TimesheetType__description */
		&__description {
			width: 100%;
			display: flex;
			flex-direction: column;
			fab-color: gray7;
			font-size: 13px;
		}

		&__name {
			display: inline-block;
			fab-color: gray8;
			margin-bottom: 2px;
			font-size: 15px;
			line-height: 18px;
			font-family: unset;

			/input[type="radio"]:checked + .ba-CheckboxCard & {
				fab-color: gray10;
				font-weight: 600;
			}
		}

		&__text {
			font-size: 13px;
			line-height: 18px;
			fab-color: gray7;
		}
	}

	&fieldBox {
		display: inline-block;
		+ .STS_fieldBox:not(.masked) {
			margin-left: 8px;
			&.spaced {
				margin-left: 16px;
			}
		}

		&.fullWidth {
			display: block;
		}
	}

	/* .STS_TimesheetTypeClock.STS_TimesheetTypeClock  (BhrForms override) */
	&TimesheetTypeClock&TimesheetTypeClock {
		margin: 17px 0 0;
	}

	/* .STS_Approvals */
	&Approvals {
		padding-top: 15px;

		&__fieldStatement {
			fab-font-size: medium;
			fab-color: gray9;
			position: relative;
			top: 2px;
			right: 6px;
		}
	}

	/* .STS_Holidays */
	&Holidays {

		/* .STS_Holidays__select */
		&__select {
			margin-bottom: 12px;
		}

		/* .STS_Holidays__rateContainer */
		&__rateContainer {
			position: relative;
		}

		&__radioWrapper {
			margin-top: 4px;
		}

		&__rateContainer {
			margin-bottom: 22px;
		}

		&__dropdownGroup {
			margin-bottom: 0;
		}

		/* .STS_Holidays__times */
		&__times {
			position: relative;
			top: 1px;
			margin: 0 4px;
			fab-color: gray9;
			font-size: 18px;
		}

		/* .STS_Holidays__link */
		&__link {
			margin-top: 14px;
		}

		&__payTypeRadioGroup {
			margin-bottom: 16px;
		}

		&__formNote {
			fab-color: gray9;
			display: inline;
			font-family: unset;
			position: relative;
			bottom: 3px;
			font-size: 15px;
			margin: 0 4px;
		}

		&__employeeStatuses {
			display: inline-block;
			margin: 0 8px 5px 0;
		}

		&__employmentStatusRow {
			margin-bottom: 4px;
		}

		&__deleteRow {
			fab-color: gray6;
			cursor: pointer;
			display: inline-block;
			font-family: unset;
			font-size: 20px;
			font-weight: 600;
			margin: 0 6px 7px 0;

			&:hover {
				fab-color: gray8;
			}
		}

		&__addRowIcon {
			fab-color: gray4;
			fab-fill: gray4;
			position: relative;
			top: 1px;

			&--clickable {
				cursor: pointer;
				fab-fill: gray6;

				&:hover {
					fab-fill: gray8;
				}
			}
		}
	}

	&NoStatusesTooltip {
		color: #f;
		font-size: 12px;
		line-height: 14px;
		padding: 7px;
		text-align: left;
		width: 162px;

		&__add {
			color: #f;
			display: block;
			font-weight: 600;
			margin-top: 8px;

			&:hover,
			&:active,
			&:visited {
				color: #f !important;
			}
		}
	}

	&Mobile {
		margin-bottom: 47px;

		&__checkboxLabel {
			display: block;
		}

		&__geolocationCheckbox {
			margin: 24px 0 0 24px;
		}
	}

	&warningIcon {
		fab-fill: attention;
		float: left;
		margin-top: 4px;
		display: inline-block;
		margin-right: 5px;
	}

	&formNoteStandout {
		margin-left: 5px;
		margin-top: 10px;
		top: 12px;
		vertical-align: top;
		display: block;
		position: static;
		height: auto;

		&_textStyle {
			fab-color: gray10;
			font-size: 14px;
			line-height: 18px;
			display: inline-block;
			float: left;
			width: 97%;
		}

		&_highlightText {
			font-weight: 600;
			fab-color: attention;
		}
	}
}

.settings-main-heading__color {
	color: var(--fabric-theme-base, #527a00);
}

.TT_SetupBox {
	border: 1px solid fab-color(gray5);
	border-radius: 2px;
	display: flex;
	align-items: center;
	padding 22px;
	box-shadow: 0 1px 4px 0 rgba(0,0,0,0.15);
	fab-background-color: gray2;
	fab-color: gray9;
	margin-bottom: 18px;

	&--info {
		fab-border-color: info;
		background-color: rgba(7,114,179,0.1);
		flex-shrink 0;
		margin-right 6px;
		position: relative;
		top: 4px;

		& .TT_SetupBox__icon {
			fab-fill: info;
		}

		& .TT_SetupBox__header {
			fab-color: info;
		}
	}

	&__icon {
		flex-shrink 0;
		margin-right 16px;
		position: relative;
		top: -6px;
		fab-fill: gray9;
	}

	&__header {
		margin: 0 0 1px;
		padding-top: 0 !important; // only !important until #settings h4 styles are updated
	}

	.icon-wrapper{
		margin-right: 22px;
	}
}

input[type="radio"]:checked + .ba-CheckboxCard .STS_TimesheetType__name {
	font-weight: 600;
	fab-color: theme-base;
}

@media only screen and (max-width: 1270px) {
	.STS_ {

		&TimesheetTypeCard&TimesheetTypeCard {
			margin-top: 12px;
		}

		&fieldBox {
			
			margin-left: 0;

			+ .STS_fieldBox:not(.masked) {
				margin-left: 0;
			}
		}
	}
	
}

{featureToggleOn('multipleEntriesByHour')} {
	.STS_ {

		&TimesheetType {
			height: 75px;
			width: 271px;
		}
	}
}

.TimeTrackingTrialPill {
	margin-left: 4px;
	line-height: 14px;

	&__tag {
		display: inline-flex;
		justify-content: center;
		align-items: center;
		padding: 2px 4px;
		border-radius: 4px;
		fab-font-weight: bold;
		font-size: 11px;
		background-color: fab-color(theme-base);
		color: fab-color(white);
	}

	&--subnav {
		margin-left: 8px;
	}
}
