.SettingsPermissionsApi {
	&__section {
		& + & {
			padding-top: 30px;
		}

		&Title {
			margin: 21px 0 7px;
		}
	}

	&&--indent &__section {
		padding-left: 18px;
	}

	&__section > &__table:first-child {
		margin-top: 6px;
	}

	&__sectionTitle + &__headerActions {
		padding-top: 0;
	}

	&__header {
		&Title {
			padding-bottom: 8px;
		}

		&Icon {
			vertical-align: middle;
		}

		&Actions {
			padding: 12px 0 10px;
		}

		&Action {

		}
	}

	&__table {
		&Row {

		}

		&Cell {
			&&--name {

			}

			&&--created {
				width: 18%;
			}

			&&--application {
				width: 18%;
			}

			&&--deviceId {
				width: 18%;
			}

			&&--actions {
				width: 40px;
				padding-right: 15px;
			}
		}

		&Note {
			margin: 10px 0;
			color: #9;
			font-size: 13px;
		}
	}

	&__action {
		text-decoration: none !important;
		fill: #89;

		.SettingsPermissionsApi__tableRow:not(:hover) & {
			opacity: 0;
		}

		&:hover {
			fill: #6;
		}

		&:not(:first-child) {
			margin-left: 6px;
		}

		&&--edit {

		}

		&&--delete {

		}
	}

	&AddEditModal {
		.SimpleModal__body {
			width: 346px;
		}

		&__keySummary {
			position: relative;

			&:not(&--added) {
				display: none;
			}
		}

		&__newApiKey {
			background: #ffece5;
			margin: 7px 0 2px;
			padding: 11px 8px;
			box-sizing: border-box;
			height: 44px;
			width: 512px;
		}

		&__copyApiKey {
			float: right;
			text-transform: uppercase;
			font-size: 12px;
			line-height: 15px;
			position: absolute;
			top: 0;
			right: 0;
		}
	}
}
