.PlaceholderItem {
	height: 90px;
	width: 500px;
	border: 1px solid #DCDCDC;
	display: flex;
	flex-direction: row;
	align-items: center;
	margin: 10px 0;
	border-radius: var(--radius-300);

	&__checkbox {
		height: 25px;
		width: 25px;
		background-color: #F7F7F7;
		margin: 0 14px 0 21px;
		border-radius: 2px;
	}

	&__title {
		height: 14px;
		width: 230px;
		background-color: #F7F7F7;
		border-radius: 2px;
	}

	&__text {
		height: 14px;
		width: 405px;
		background-color: #F7F7F7;
		margin: 6px 0;
		border-radius: 2px;
	}

	&__link {
		height: 14px;
		width: 94px;
		background-color: #F7F7F7;
		border-radius: 2px;
	}
}

.PlaceholderItem {
	height: 100px;
	width: 590px;
	border: 1px solid fab-color(gray3);
	display: flex;
	flex-direction: row;
	align-items: center;
	margin: 16px 0;
	border-radius: var(--radius-300);

	&__checkbox {
		height: 24px;
		width: 24px;
		fab-background-color: gray1;
		margin: 0 16px 45px 24px;
		border-radius: 2px;
	}

	&__title {
		height: 14px;
		width: 230px;
		fab-background-color: gray1;
		border-radius: 2px;
	}

	&__text {
		height: 14px;
		width: 405px;
		fab-background-color: gray1;
		margin: 6px 0;
		border-radius: 2px;
	}

	&__link {
		height: 14px;
		width: 94px;
		fab-background-color: gray1;
		border-radius: 2px;
	}
}
