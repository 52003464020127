.CodeRateRow {
	align-items: center;
	display: flex;
	margin-bottom: 16px;
	position: relative;
	width: 100%;

	// .CodeRateRow--hidden
	&--hidden {
		display: none;
	}

	// .CodeRateRow__field
	&__field {

		// .CodeRateRow__field--addMargin
		&--addMargin {
			margin-right: 12px;
		}

		// .CodeRateRow__field--code
		&--code {
			margin-left: 0 !important; // To override Fabric style
		}

		// .CodeRateRow__field--checkbox
		&--checkbox {
			margin: -5px 21px 0 11px;
		}
	}

	// .CodeRateRow__formField
	&__formField {
		margin-left: 8px;
	}

	// .CodeRateRow__remove
	&__remove {
		position: absolute;
		left: -35px;
	}

	// .CodeRateRow__history
	&__history {
		display: flex;
		align-items: center;
		margin-left: 20px;
		padding-left: 20px;
		position: relative;

		// .CodeRateRow__historyDivider
		&Divider {
			border-left: 1px solid fab-color(gray3);
			position: absolute;
			left: 0;
			top: 0;
			height: 50px;

			&--last {
				height: 35px;
			}
		}
	}


	// .CodeRateRow__futureChange
	&__futureChange {
		fab-fill: attention;
		margin-left: 6px;
	}

	// .CodeRateRow__perHourMessage
	&__perHourMessage {
		margin-left: 8px;
	}
}
