.CompanySelectOption {
	display: flex;

	// .CompanySelectOption--notActive
	&--notActive {
		.CompanySelectOption__ein {
			fab-color: gray6;
		}
	}

	// .CompanySelectOption__name
	&__name {
		margin-right: 5px;
	}

	// .CompanySelectOption__ein
	&__ein {
		flex-shrink: 0;
		max-width: 100%;
	}
}
