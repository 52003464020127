.OptionalTextInput {
	/* .OptionalTextInput__box */
	&__box {
		display: inline-block;
	}

	/* .OptionalTextInput__help > .HelpPopover */
	&__help > .HelpPopover {
		position: absolute;
		right: -25px;
		top: 8px;
	}
}
