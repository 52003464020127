elem = BEM('BaTabs', 'fab-medium-theme');

{elem('tabContent')} {
	+encore() {
		fab-background-color: gray1;
		border-radius: 8px;
	}
}

{elem('tab')} {
	fab-font-size: large;
	line-height: 1em;
	padding: 17px 24px;
	border-top-radius: 4px;
	border-bottom-radius: 0;
	height: 50px;
	align-items: center;
	width: auto !important;
	flex-shrink: 0;

	+encore() {
		fab-text: medium;
		padding: 20px 24px;
		margin: 0 2px;
		border-top-radius: 8px;

		&:focus-visible {
			&:after {
				content: none;
			}
		}

		&:first-child {
			margin-left: 0;
		}

		&:last-child {
			margin-right: 0;
		}
	}

	&:not(.BaTabs__tab--active) {
		fab-color: gray8;
		fill: rgba(fab-color(gray8), 0.8);
	}

	&:not(.BaTabs__tab--disabled):not(.BaTabs__tab--active):not(.BaTabs__tab--loading):hover {
		fab-color: theme;
		fab-fill: theme;
		background-color: rgba(fab-color('white'), 0.5);
		+encore() {
			background-color: rgba(fab-color('white'), 0.1);
		}
	}

	&:not(.BaTabs__tab--disabled):not(.BaTabs__tab--active):not(.BaTabs__tab--loading):focus-visible  {
		&:after{
			+encore() {
				content: none;
			}
		}
		+encore() {
			background-color: rgba($fabEncoreColors.white, 0.2);
		}
	}

	&:not(.BaTabs__tab--fab-medium-theme_dark):not(.BaTabs__tab--disabled):not(.BaTabs__tab--active):not(.BaTabs__tab--loading):hover {
		+encore() {
			background-color: rgba($fabEncoreColors.gray05, 0.9);
			fab-color: theme;
		}
	}

	&:not(.BaTabs__tab--fab-medium-theme_dark):not(.BaTabs__tab--disabled):not(.BaTabs__tab--active):not(.BaTabs__tab--loading):focus-visible {
		&:after{
			+encore() {
				content: none;
			}
		}
		+encore() {
			background-color: rgba($fabEncoreColors.white, 0.2);
			fab-color: white;
		}
	}
}

{elem('tab', 'active')} {
	fab-font-weight: heavy;
	fab-color: theme;
	fab-fill: theme;
	fab-background-color: 'white';
	box-shadow: 0 -4px 8px 0 rgba(#0, 0.05);
	+encore() {
		background-color: var(--gray05);
		box-shadow: none;
	}
}

{elem('tab', 'overflow fab-medium-theme_header')} {
	.fab-link:hover {
		text-decoration: none;
	}

	.BaTabs__tabLabel {
		fab-color: white;
	}

	.BaTabs__textButton {
		fill: white;
		box-shadow: none;

		&:hover {
			fill: white;
			text-decoration: none;
		}

		&:focus {
			box-shadow: none;
		}
	}

	+encoreHighSpecificity() {
		.MuiButton-root {
			color: fab-color(white);
			fill: fab-color(white);
			outline: none;
			border: none;
			box-shadow: none;

			&:after {
				// Focus styles from the button are causing issues with the tab. This ensures the focus styles dont trigger for any reason
				// !important forces the focus styles to never show allowing better control on the tab itself
				content: none !important;
			}
		}

		&:has(.MuiButton-root[aria-expanded="true"]) {
			background-color: rgba($fabEncoreColors.white, 0.2);
			.MuiButton-root {
				fill: fab-color(white);
			}

			.BaTabs__tabLabel {
				color: fab-color(white);
			}
		}

		&:hover {
			.MuiButton-root {
				color: fab-color(white);
				fill: fab-color(white);
				text-decoration: none;
			}
		}

		&:focus-within {
			background-color: rgba($fabEncoreColors.white, 0.2);
			.MuiButton-root {
				fill: fab-color(white);
			}

			.BaTabs__tabLabel {
				color: fab-color(white);
			}
		}
	}
}

{elem('tab', 'fab-medium-theme_dark')} {
	&:not(.BaTabs__tab--active) {
		fab-color: 'white';
		fill: rgba(fab-color('white'), 0.8);
	}

	&:not(.BaTabs__tab--disabled):not(.BaTabs__tab--active):not(.BaTabs__tab--loading):hover {
		fab-color: 'white';
		fab-fill: 'white';
		background-color: rgba(fab-color('white'), 0.2);
	}
}

{elem('overflowTab')} {
	display: block;
	margin: -5px -10px;
	padding: 5px 10px;

	&,
	&:hover {
		text-decoration: none;
	}

	+encore() {
		display: flex;
		color: $fabEncoreColors.link;
		fill: $fabEncoreColors.link;
		font-weight: $fabEncoreText.textWeights.regular;

		&.fab-Link--inherit {
			color: fab-color(gray9);
		}
	}
}

{elem('overflowTab', 'active')} {
	fab-font-weight: heavy;
}

{elem('tabIcon')} {
	margin-right: 8px;

	> svg {
		size: 18px;
	}
}

{elem('tabWrapper')} {
	padding: 32px 24px 0;

	&:not(.BaTabs__tabWrapper--fab-medium-theme_header) {
		// @startCleanup encore
		fab-background-color: gray1;
		// @endCleanup encore
		+encore() {
			fab-background-color: white;
		}
	}

	// @startCleanup encore
	&:before {
		content: "";
		display: block;
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		height: 3px;
		background-image: linear-gradient(to top, rgba(#0, 0.05) 0, rgba(#0, 0) 3px, rgba(#0, 0) 100%);

		+encore() {
			content: none;
		}
	}
	// @endCleanup encore
}

{elem('tabWrapper', 'fab-medium-theme_dark')} {
	&:not(.BaTabs__tabWrapper--fab-medium-theme_header) {
		// @startCleanup encore
		fab-gradient: theme-lighter theme-base;
		// @endCleanup encore
		+encore() {
			background-image: none;
			fab-background-color: theme-base;
			border-radius: 8px;
		}
	}
}

{elem('tabWrapper', 'fab-medium-theme_light')} {
	&:before {
		// @startCleanup encore
		fab-background-color: gray1;
		// @endCleanup encore
		+encore() {
			fab-background-color: white;
		}
	}
}

{elem('tabWrapper', 'fab-medium-theme_header')} {
	margin-top: -50px;
	padding: 0;
}

{elem('tab', 'fab-medium-theme_header')} {
	&:not(.BaTabs__tab--fab-medium-theme_light) {
		&:not(.BaTabs__tab--active) {
			fab-color: 'white';
			fill: rgba(fab-color('white'), 0.8);
		}

		&.BaTabs__tab--active {
			fab-color: theme;
			fab-fill: theme;
			text-decoration: none;
		}

		&:not(.BaTabs__tab--disabled):not(.BaTabs__tab--active):not(.BaTabs__tab--loading):hover {
			fab-color: 'white';
			fab-fill: 'white';
			background-color: rgba(fab-color('white'), 0.2);
			text-decoration: none;
		}
	}
}

{elem('overflowTab', 'fab-medium-theme_header')} {
	{elem('tab', 'fab-medium-theme_header')} {
		&:not(.BaTabs__tab--active) {
			fab-color: theme-base;
			background-color: transparent;
		}

		&.BaTabs__tab--active {
			fab-color: theme;
			fab-fill: theme;
			background-color: transparent;
			box-shadow: none;
			&:hover {
				fab-color: 'white';
				fab-fill: 'white';
			}
		}

		&:not(.BaTabs__tab--disabled):not(.BaTabs__tab--active):not(.BaTabs__tab--loading):hover {
			fab-color: 'white';
			fab-fill: 'white';
			background-color: transparent;
		}
	}
}
