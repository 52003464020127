.StateUnemployment {
	/* .StateUnemployment__location */
	&__location {
		margin-top: 4px;
	}

	/* .StateUnemployment__check */
	&__check {
		padding-left: 14px;
		padding-top: 17px;
	}

	/* .StateUnemployment__sectionNoteContainer */
	&__sectionNoteContainer {
		align-items: flex-start;
		display: flex;
		fab-color: 'info';
		fab-fill: 'info';
		fab-font-size: 'small';
		fab-font-weight: 'semibold';
		margin-bottom: 8px;
	}

	/* .StateUnemployment__sectionNoteIcon */
	&__sectionNoteIcon {
		margin-top: 3px;
	}

	/* .StateUnemployment__sectionNoteText */
	&__sectionNoteText {
		margin-left: 4px;
	}
}
