.EnpsBannerHeading {
	fab-background-color: theme-base;
	display: flex;
	flex-direction: column;
	height: 102px;
	justify-content: center;
	padding-left: 40px;
	border-top-left-radius: 4px;
	border-top-right-radius: 4px;

	&__title {
		fab-color: white;
		fab-font-weight: bold;
		font-size: 22px;
		line-height: 23px;
		padding-bottom: 5px;
	}

	&__subtitle {
		fab-color: white;
		fab-font-weight: semibold;
		font-size: 16px;
		line-height: 23px;
	}
}
