.SimpleMultiLineAreaChart {

	&--themeFill {
		fab-fill: theme-base;
	}

	&--themeStroke {
		stroke: fab-color(theme-base);
	}
}
