.ProcessBar {
	background-color: $bhrColors.gray2;
	border: 1px solid #AFAFAF;
	border-left: none;
	border-right: none;
	font-family: "BhrHeaderFont", "Trebuchet MS";
	height: 47px;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;

	&__list {
		display: block !important;
		float: left;
		margin-left: 21px;
		padding: 11px 29px 11px 0;

		&:last-of-type {
			&^[0]--current {
				background: none;
			}
		}

		&:not(^[0]--current) {
			& ^[0]__step {
				&-number {
					background-color: $bhrColors.gray2;
				}

				&-name {
					color: #888888;
				}
			}
		}

		&^[0]--current {
			background: url("https://staticfe.bamboohr.com/resources/images/settings/new_benefit/process-arrow.svg") no-repeat right center;
		}


		&^[0]--complete {
			& ^[0]__step {
				&-number {
					background-color: #BFBFBF;
					border: none;
					color: transparent;

					&:before {
						border: solid #fff;
						border-width: 0 4px 4px 0;
						content: "\00a0";
						display: block;
						height: 10px;
						position: relative;
						top: 44%;
						left: 50%;
						transform: translate(-50%, -50%) rotate(45deg);
						width: 5px;
						border-radius: 1px;
					}
				}
			}
		}
	}

	&__step {
		&-number {
			border: 1px solid #B3B3B3;
			border-radius: 100%;
			box-sizing: border-box;
			color: #888888;
			font-size: 15px;
			font-weight: bold;
			size: 25px;
			line-height: @height;
			margin-right: 7px;
			text-align: center;

			~/__list^[0]--current & {
				border-color: transparent;
				color: white;
			}
		}

		&-name {
			height: 25px;
			font-size: 16px;
			line-height: 28px;

			~/__list^[0]--current & {
				font-weight: bold;
			}
		}
	}
}
