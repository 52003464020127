elem = BEM('BaTabs', 'fab-small-theme');

{elem('tab')} {
	padding: 16px 10px;
	margin-bottom: -1px;
	// @startCleanup encore
	border-bottom-width: 2px;
	// @endCleanup encore
	border-bottom-style: solid;
	border-color: transparent;
	align-items: center;
	flex-shrink: 0;

	+encore() {
		border-bottom-width: 3px;
	}

	& + & {
		margin-left: 12px;
	}

	&:not(.BaTabs__tab--disabled):not(.BaTabs__tab--active):not(.BaTabs__tab--loading):hover {
		fab-color: theme;
		fab-fill: theme;
		+encore() {
			border-bottom-color: $fabEncoreColors.gray6;
			border-bottom-width: 3px;
		}
	}
}

{elem('tab', 'active')} {
	fab-color: theme;
	fab-fill: theme;
	border-color: fab-color(theme);
}

{elem('tab', 'fab-small-theme_light')} {
	padding-top: 24px;
}

{elem('tab', 'fab-small-theme_icons')} {
	padding: 13px 10px;
}

{elem('tab', '--overflow')} {
	cursor: default !important;
	padding-top: 0;
	padding-bottom: 0;
}

{elem('overflowTab')} {
	> {elem('tab')} {
		padding: 0;
		border: 0;
		margin: 0;
	}
}

{elem('tabLabel', 'primary')} {
	white-space: nowrap;
	line-height: 1em;
}

{elem('tabIcon')} {
	> svg {
		size: 14px;
	}

	+ {elem('tabLabel')} {
		margin-left: 8px;
	}
}

// @startCleanup encore
{elem('tabLabel')} {
	+jadeFabricTheme() {
		/.fab-MenuOption.fab-MenuOption--active & {
			fab-color: white;
		}
	}
}
// @endCleanup encore

{elem('tabIcon', 'fab-small-theme_icons')} {
	> svg {
		size: 22px;
	}
}

{elem('tabWrapper')} {
	border-bottom: 1px solid $fabColors.hex.gray4;
	padding: 0 22px;
	box-sizing: content-box;
}

{elem('tabWrapper', 'fab-small-theme_light')} {
	// @startCleanup encore
	fab-background-color: gray1;
	// @endCleanup encore

	+encore() {
		fab-background-color: white;
	}
}

{elem('tabWrapper', 'fab-small-theme_tight')} {
	padding: 0;
}

{elem('tabWrapperFooter')} {
	display: flex;
	flex: 1;
	justify-content: flex-end;
}
