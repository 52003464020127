+legacy() {
.SettingsApps {
	&__appModal {
		&&--conflict,
		&&--uninstall {
			text-align: center;
		}

		&&--conflict {
			max-width: 365px;
			padding: 13px 0 7px;
		}

		&&--uninstall {
			max-width: 297px;
			padding: 2px 34px 0;
		}

		&&--install {
			width: 360px;
		}

		&&--config {
			width: 530px;

			// WARNING: There be hacks ahead...
			// unfortunately, this modal is loading Twig stuff that we
			// can't touch at this time, so there's gonna be a bunch
			// of really nast hacky stuff here to get it to look decent

			legend {
				display: none;
			}

			fieldset {
				&[class*="fieldsetIndent-"] {
					margin-left: 0;
					padding-left: 0;
				}
			}
		}

		&Logo {
			width: 190px;
			margin-bottom: 20px;
		}

		&Icon {
			&&--conflict {
				margin-bottom: 7px;
			}

			&&--uninstall {
				fill: $bhrColors.gray8;
			}
		}

		&Msg {
			&&--install,
			&&--conflict,
			&&--uninstall {
				font-size: 17px;
				line-height: 24px;
				text-align: center;
			}

			&&--install {
				max-width: 300px;
				margin: 10px auto;
			}

			&&--uninstall {
				margin: 5px 0 -3px;
			}
		}

		& textarea&TxtCopy {
			// overly-specific selector to override overly-specific BhrForms styles
			resize: none;
			height: 150px;
			font-size: 13px;
			line-height: 14px;
			font-family: monospace;
			word-wrap: pre;
			cursor: pointer;
		}
	}
}
}

.SettingsApps {
	&__appModal {
		&Copy {
			color: fab-color(gray9);

			&--centered {
				text-align: center;
			}
		}
	}
}
