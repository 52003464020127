pulse($name, $minSize = 0, $maxSize = 100%, $color = "#fff") {
	position: relative;

	&:after {
		animation-timing-function: cubic-bezier(0.2, 0, 0.2, 1);
		animation: $name 2s infinite;
		border: 1px solid #f;
		border-radius: 50%;
		box-sizing: border-box;
		content: "";
		display: block;
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
	}

	@keyframes $name {
		0% {
			height: $minSize;
			opacity: 0;
			width: $minSize;
		}
		5% {
			opacity: 1;
		}
		50% {
			height: ($maxSize / 2);
			opacity: 0;
			width: ($maxSize / 2);
		}
		100% {
			height: $maxSize;
			opacity: 0;
			width: $maxSize;
		}
	}
}

fadeOut($duration = 0.5s) {
	opacity: 0;
	animation: fadeOut $duration;

	@keyframes fadeOut {
		0% {
			opacity: 1;
		}
		100% {
			opacity: 0;
		}
	}
}

fadeIn($duration = 0.5s) {
	opacity: 1;
	animation: fadeIn $duration;

	@keyframes fadeIn {
		0% {
			opacity: 0;
		}
		100% {
			opacity: 1;
		}
	}
}
