/* Overrides to support BhrForms--view mode with ba-selects */
.BhrForms--view {
	/* .BhrForms--view .ba-Select */
	.ba-Select {
		background: #fff;
		box-shadow: none;
		border-color: #dedede;
		/* .BhrForms--view .ba-Select:hover */
		&:hover {
			border-color: #aaa;

			.ba-Select {
				/* .BhrForms--view .ba-Select:hover .ba-Select__caret,
				   .BhrForms--view .ba-Select:hover .ba-Select__x */
				&__caret,
				&__x {
					display: inline-block;
				}
			}
		}
		/* .BhrForms--view .ba-Select__caret,
		   .BhrForms--view .ba-Select__x */
		&__caret,
		&__x {
			display: none;
		}
	}
}

.HelpPopover {
	position: unset;
}

.Federal {
	height: 373px;
	transition: height 600ms;
	overflow: hidden;

	// .Federal--2020
	&--2020FedEx {
		overflow: visible;
		height: 475px;
	}

	// .Federal--hidden
	&--hidden {
		display: none;
	}
}

