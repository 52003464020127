$theme = '--banner-theme';

.BaTabs {
	&__tab {
		&{$theme} {
			border-color: transparent;
			border-radius: 3px;
			border-style: solid;
			border-width: 1px;
			color: $bhrColors.gray8;
			height: 135px;
			margin: 15px;
			max-width: 275px;
			padding: 10px 10px 0;
			position: relative;
			user-select: none;
			text-align: center;
		}

		&--active&{$theme} {
			background-color: $colors.white;
			box-shadow: 0 1px 2px 0 rgba(#0, 0.25);
			height: 163px;
			padding-top: 24px;
		}

		&--disabled&{$theme} {

		}

		&{$theme}:not(&--active):not(&--disabled) {
			&:hover {
				background-color: $bhrColors.gray2;
				border-color: $bhrColors.gray5;
			}
		}

		&Label {
			&--primary&{$theme} {
				color: $colors.black;
				font-family: BhrHeaderFontStack;
				font-size: 16px;
				margin-top: 5px;
				margin-bottom: 5px;
				text-align: center;
			}

			&--secondary&{$theme} {
				font-family: BhrFontStack;
				font-size: 14px;
				line-height: 17px;
				text-align: center;
			}
		}

		&Icon {
			&{$theme} {
				display: flex;
				justify-content: center;
				fill: $bhrColors.gray8;
				margin: 0 auto;

				> svg {
					size: 50px;
				}

				/.BaTabs__tab:not(.BaTabs__tab--active):hover & {
					fill: $svgColorPalette.gray-5;
				}
			}
		}

		&Caret {
			&{$theme} {
				position: absolute;
				top: calc(100% - 2px);
				left: 50%;
				transform: translateX(-50%);
			}

			&:not(&--active)&{$theme} {
				display: none;
			}
		}

		&Wrapper {
			&{$theme} {
				background-color: $colors.gallery;
				border-bottom: 1px solid lighten($colors.silver-chalice, 1%);
				border-top: 1px solid lighten($colors.silver-chalice, 1%);
				height: 145px;
				margin-bottom: 20px;
				margin-top: 10px;
				padding: 0 15px;
			}
		}

		&Content {
			&Wrapper {

			}
		}
	}
}