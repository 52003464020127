.EmploymentSubtitles {
	&__subTitleIcon {
		display: inline-block;
		width: 21px;
	}

	&__subTitleText {
		color: fab-color(gray7);
		fab-text: small;
	}
}
