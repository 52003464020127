.EnpsIconHeading {
	display: none;
}

@media only screen and (max-width: 800px) {
	.EnpsIconHeading {
		fab-fill: theme-base;
		display: block;
		text-align: center;
		margin: 35px 0 20px 0;
	}
}
