.MigrationErrorModal {
	padding-top: 15px;

	// .MigrationErrorModal__explanation
	&__explanation {
		margin-bottom: 30px;
	}

	// .MigrationErrorModal__tableContainer
	&__tableContainer {
		margin-bottom: 39px;
	}

	// .MigrationErrorModal__tables
	&__tables {
		border-top: 1px solid fab-color(gray3);
		padding-top: 4px;
	}

	// .MigrationErrorModal__typeExplanation
	&__typeExplanation {
		fab-color: attention;
	}

	// .MigrationErrorModal__clientInfo
	&__clientInfo {
		display: flex;
		align-items: center;
		text-align: left;
		padding-bottom: 3px;
	}

	// .MigrationErrorModal__clientName
	&__clientName {
		padding-left: 8px;
	}
}
