.DynamicForm--employeeAccess {
    margin-left: 28px; // 20px icon width + 8px margin

    .fieldBox.fab-FormField {
        margin: 10px 0 0 0;
    }

    &__noAccess {
        fab-color(gray5);
    }

    &__noMargin {
        margin: 0;
    }
}
