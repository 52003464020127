.AnonymityBanner {
	height: 25px;
	background: #444;
	padding: 8px 16px;
	display: flex;
	align-items: center;

	+encore() {
		align-items: center;
		height: unset;
		padding: 5px 20px;
	}

	@media(max-width: 768px) {
		padding: 14px 0 14px 20px;

		+encore() {
			padding: 8px 20px 4px;
		}
	}

	&__icon {
		flex-shrink: 0;

		svg {
			height: 25px;
			width: 30px;
			fill: white;

			@media(max-width: 768px) {
				height: 32px;
				width: 38px;
			}
		}
	}

	&__textContainer {
		display: flex;
		align-items: center;
		margin-left: 16px;
		font-size: 15px;
		line-height: 18px;
		flex-flow: row wrap;

		@media(max-width: 768px) {
			font-size: 18px;
			line-height: 22px;

			+encore() {
				font-size: var(--font-size-medium);
				line-height: var(--line-height-medium);
			}
		}
	}

	&__text {
		color: white;
		margin-right: 6px;
	}

	&__link {
		fab-color: gray4;
	}

	&__button {
		+jadeFabricTheme() {
			fab-color: gray4;
			font-weight: 400;
		}
	}
}
