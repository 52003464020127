$theme = '--basic-theme';

.BaTabs {
	&__tab {
		&{$theme} {
			background: transparent;
			display: flex;
			align-items: center;
			border-radius: 5px 5px 0 0;
			border: 1px solid transparent;
			bottom: -1px;
			color: $bhrColors.gray10;
			font-family: BhrFontStack;
			font-size: 16px;
			height: 50px;
			margin-right: 5px;
			padding: 0 18px;
		}

		&--active&{$theme} {
			background-color: $bhrColors.gray1;
			border-bottom-color: $bhrColors.gray1;
			color: $bhrColors.gray12;
			font-weight: 600;
		}

		&--disabled&{$theme} {
			opacity: 0.6;

			&:hover {
				border-color: $bhrColors.gray5;
			}
		}

		&{$theme}:not(&--active):not(&--disabled) {
			&:hover {
				border-color: $bhrColors.gray5;
				background-color: $bhrColors.gray2;
			}
		}

		&--dark {
			border-bottom: none;
			color: #f;

			&.BaTabs__tab--active {
				background-color: #f;
			}

			&:not(.BaTabs__tab--active):not(.BaTabs__tab--disabled) {
				transition: border-color 100ms linear;
				&:hover {
					background-color: transparent;
					border-color: rgba(255,255,255, .8);					
				}

				.BaTabs__tabIcon {
					fill: #f;
				}
			}
		}

		&Label {
			&--primary&{$theme} {
				padding-left: 5px;
				line-height: 19px;
			}

			&--secondary&{$theme} {
				display: none;
			}
		}

		&Icon {
			&{$theme} {
				fill: $svgColorPalette.gray-4;

				> svg {
					size: 20px;
				}
			}
		}

		&Wrapper {
			&{$theme} {
				align-items: flex-end;
				background-color: $bhrColors.gray4;
				border-bottom: 1px solid $bhrColors.gray5;
				display: flex;
				flex-direction: row;
				margin: 0 auto;
				max-width: 1140px;
				width: 100%;
				height: 60px;
				bottom: -1px;
				padding: 8px 20px 0;
			}

			&--transparent {
				background-color: transparent;
				border-bottom: none;
			}
		}

		&Content {
			&Wrapper {
				&{$theme} {
					max-width: 1140px;
					padding: 0 20px;
				}
			}
		}
	}
}
