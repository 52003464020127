#extra {
	margin-top: -4px;

	> li {
		margin: 0 5px 4px;

		> .optional .drop {
			margin-top: -2px;
		}
	}

	.fieldInfo {
		position: relative;
		top: 5px;
	}

	&.checkboxColList2 {
		padding: 4px 0 0 3px;
	}

	&.checkboxColList2 > li {
		margin-bottom: 5px;
		height: 21px;
	}

	li {
		> div:not(:first-child) {
			margin-left: 8px;
		}

		> div {
			display: inline-block;
		}
	}

	.filter-option {

		> label {
			width: calc(100% - 12px);
			line-height: 1.2em !important;

			> span {
				display: inline-block;
				width: 100%;
				vertical-align: bottom;
			}
		}

		&.selected {
			max-width: calc(100% - 60px);
		}

		&-drop {

			&.selected {
				max-width: calc(100% - 220px);
			}
		}

		&-drop-link {

			&.selected {
				max-width: calc(100% - 280px);
			}
		}
	}
}

#extra .disabled-row,
select.some-fields {
	display: none;
}

.fieldBox.addNew {
	min-width: 13%;
	float: left;
}
.fieldBox {

	.fieldInfo {
		color: #90;
		font-size: 13px;
	}

	&.permSectionsBox {
		width: 13.5%;
		display: table-cell;
		vertical-align: top;
	}

	&.sectionBox{
		min-height: 200px;
		display: table-cell;
		width: 100%;
		vertical-align: top;
	}
}

form {
	.sectionBox {
		ul {
			li {
				margin: 4px 5px 4px;
			}
		}
	}
}

.multi-column {
	display: table;
	width: 100%;
	margin-top: 11px;
}

.permSectionsBox {

	> .fieldDiv {
		margin-top: 10px;
	}
}

.sectionBox {

	> .fieldDiv {
		border-left-style: solid;
		border-left-width: 1px;
		margin-top: 14px;
		padding-left: 5%;
	}

	> label {
		padding-left: 5%;
	}
}

#permSections {
	position: relative;

	li {
		font-size: 15px;
		height: 27px;
		padding: 6px 0 0;

		&.on {
			a {
				color: #222;
				font-weight: bold;
			}
		}
	}
}

#section-arrow {
	background-image: url("https://staticfe.bamboohr.com/resources/images/bicons/perm-selected-arrow.png");
	display: block;
	position: absolute;
	z-index: 5;
	width: 7px;
	height: 13px;
	right: -8px;
	top: 9px;
}

.sectionFilterBox {
	margin-top: 10px;
	border-top: solid 1px #e1e1e1;
	padding-top: 15px;
	width: 100%;
}

.optional {
	box-sizing: border-box;
	height: 20px;
	line-height: 21px;
	vertical-align: top;
}

.optional .showPopup {
	font-size: 13px;
	float: left;
	margin-top: 1px;
}

.drop {
	float: left;
}

.showPopup + .drop {
	margin-left: 5px;
}

.drop .small {
	margin-top: 0;
}


.selfServiceSingle {
	margin-top: 6px;
	display: inline-block;
}

#filter-description {

	&-wrapper {
		display: none;
		margin-top: 9px;
	}

	a {
		margin-right: 2px;
	}
}

.extra {

	&__celebration {
		margin-left: 20px;

		&-child {
			padding: 0 0 4px;
			margin: 0;
		}
	}

	&Commentary {

		&__text {
			color: #77;
			font-size: 14px;
			padding-left: 5px;
			margin: 14px 0 4px;
		}

		&__list {

			&Item {
				color: #2;
				font-size: 15px;
				font-weight: 600;
				line-height: 1.2em;
				padding-top: 3px;
			}
		}
	}
}

.GroupAccessModal {

	& {
		height: 100%;
		margin: 0;
		padding: 0;
	}

	&__List {
		height: calc(100% - 56px);

		&.fieldRow {
			margin-bottom: 0;
		}
	}

	&__Label {
		margin-left: 10px;
		margin-right: 10px;
	}

	&__Label-Text {
		color: #AFAFAF;
		font-size: 11px;
		border-bottom: #AFAFAF solid 1px;
	}

	.filter-list-wrapper {
		height: 100%;
	}

	ul.filter-list li.GroupAccessModal__Label {
		min-height: 17px;
	}

	ul.filter-list {
		height: calc(100% - 20px) !important;
		min-width: 450px;
		min-height: 110px;
		max-height: 350px;
		overflow-y: auto;
		overflow-x: hidden;

		& > li {
			min-height: 34px;
			overflow: visible !important;

			> .filter-option label {
				margin-top: 0;
			}

			> .optional {
				margin-top: 5px;
				height: auto;
			}

			.chzn-container {
				margin: 0;
			}

			h3 {
				padding: 0 10px;
			}

			span.truncate {
				display: inline-block;
				position: relative;
				top: 3px;
			}
		}
	}
}
