.EnpsSurvey {
	margin-bottom: 35px;
	
	&__content {

		&--full-width {
			width: 100%;
		}
	}
}
