.TrialExpired {
	text-align: center;

	// .TrialExpired__header
	&__header {
		margin: 19px 0 13px;
	}

	// .TrialExpired__thanks
	&__thanks {
		fab-color: gray9;
	}
}
