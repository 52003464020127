__ = BEM('SettingsAccountTranslations');

{__('langSelector')} {

	position: absolute;
	right: 0;
	top: 10px;
}

{__('table', 'loading')} {
	opacity: 0.6;
}

{__('translationInput')} {
	margin-right: 10px;
}

{__('translationInputWrapper')} {
	display: flex;
	align-items: center;
	margin: -10px 0;
}

{__('pageHeader')} {
	&&--active {
		> {__('tableCell', 'key')} {
			&:before {
				transform: rotateZ(90deg);
			}
		}

		> {__('tableCell', 'key')}:not({__('tableCell--noToggle')}) {
			&:before {
				top: spacing(1);
				transform: rotate(90deg);
			}
		}
	}

	{__('tableCell', 'key')} {
		&{__('group')} {
			padding: 10px spacing(1) 10px spacing(5);
		}
	}

	> {__('tableCell', 'key')}:not({__('tableCell--noToggle')}) {
		cursor: pointer;

		&:before {
			display: block;
			position: absolute;

			border: none;
			content: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.6875 8.71875L7.6875 12.7188C7.40625 13 6.96875 13.0938 6.59375 12.9375C6.21875 12.7812 5.96875 12.4062 5.96875 12V4C5.96875 3.59375 6.21875 3.25 6.59375 3.09375C6.96875 2.9375 7.40625 3.03125 7.6875 3.3125L11.6875 7.3125C12.0938 7.6875 12.0938 8.34375 11.6875 8.71875Z' fill='%23777270'/%3E%3C/svg%3E%0A");
			height: unset;
			left: spacing(1.5);
			top: 10px;
			width: unset;
		}

		&:hover {
			fab-color: gray10;
			text-decoration: underline;

			&:before {
				border-left-color: fab-color(gray10);
			}
		}
	}

	> {__('tableCell', 'value')}:not({__('tableCell--noToggle')}) {
		fab-font-weight: medium;
	}
}

{__('group')} {
	display: table-cell;
	top: 2px;
}

{__('tableHeader', 'key')},
{__('tableCell', 'key')} {
	width: 100%;

	padding-left: spacing(2);
}

{__('tableHeader', 'value')},
{__('tableCell', 'value')} {
	fab-font-weight: regular;
	vertical-align: middle;
	width: 0;

	min-width: 330px;
	padding-bottom: 0;
	padding-left: spacing(2);
	padding-top: 0;
	white-space: wrap;

	{__('pageHeader')} & {

		padding-top: 0;
		padding-bottom: 0;
	}
}

{__('tableHeader', 'key')} {
	padding-left: spacing(2);
}

{__('tableHeader', 'value')} {
	fab-font-weight: medium;
}

{__('tableCell')} {
	vertical-align: middle;
}

{__('tableRow--last')} {
	> {__('tableCell')} {
		border: none;
	}
}

{__('tableHeader', 'actions')},
{__('tableCell', 'actions')} {
	vertical-align: middle;
	white-space: nowrap;
	width: 0;

	min-width: 84px;

	> {__('cellActions')} {
		height: unset;
		top: unset;
	}
}

{__('tableCell', 'value')} {
	padding-top: 0;
	padding-bottom: 0;
}

{__('tableCell', 'actions')} {
	padding: 0;
}

{__('subTableHeader')},
{__('subTableRow')}:not({__('tableRow--last')}) {
	> {__('tableCell')} {
		border: none;
		padding-bottom: 10px;
	}

	> {__('tableCell', 'key')} {
		padding-bottom: spacing(2);
	}

	> {__('tableCell', 'value')} {
		padding-top: 0;
		padding-bottom: 0;
	}
}

{__('subTableRow')} {
	> {__('tableCell')} {
		padding-top: 10px;
	}

	> {__('tableCell', 'key')} {

		padding: spacing(2) spacing(2) spacing(2) spacing(4);
	}

	> {__('tableCell', 'value')} {

		padding-top: 0;
		padding-bottom: 0;
	}
}

{__('tableRow')}:not({__('subTableRow')}):not({__('pageHeader')}) {
	> {__('tableCell', 'key')} {

		fab-font-weight: medium;
	}
}

{__('tableRow', 'missing')} > {__('tableCell', 'key')}:after,
{__('tableCell', 'value missing')}:before {
	content: "";
	display: inline-block;
	border-radius: 100%;

	background-color: var(--errorDark);
	height: 8px;
	width: 8px;
}

{__('tableRow', 'missing')} > {__('tableCell', 'key')}:after {
	margin-left: 0.5em;
}

{__('tableCell', 'value missing')}:before {
	position: absolute;
	top: 50%;
	left: 0;
	transform: translateY(-50%);
}

{__('icon', 'missing')} {
	fab-fill: attention;

	{__('tableCell', 'key')} & {
		margin-left: 0.5em;
	}

	{__('tableCell', 'value')} & {
		position: absolute;
		left: -3px;
		top: 50%;
		transform: translateY(-50%);
	}
}

// We need to set a height and unset top/bottom otherwise the background takes up the entire table in Safari
.fab-Table__row--group:after {
	bottom: unset;
	height: 36px;
	margin-top: 4px;
	top: unset;
}
