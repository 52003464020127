.DateSelectOption {

	// .DateSelectOption--notActive
	&--notActive {
		.DateSelectOption__date {
			fab-color: gray6;
		}
	}

	// .DateSelectOption__text
	&__text {
		margin-right: 5px;
	}
}
