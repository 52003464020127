.DynamicForm {
	// .DynamicForm__Legend
	&__Legend {
		display: flex;
		flex-direction: row;
		align-items: center;

		// .DynamicForm__LegendIcon
		&Icon {
			display: inline-flex;
			flex-direction: column;
			justify-content: center;
			min-height: 22px;
		}

		// .DynamicForm__LegendText
		&Text {
			display: inline-block;
		}

		&.BhrTable__header--section {
			float: left;

			& + .fieldRow {
				clear: both;
			}
		}
	}
}
