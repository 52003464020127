.Columns {

	// .Columns__eligible
	&__eligible {

		// .Columns__eligibleList
		&List {
			counter-reset: eligible-counter;
			margin-left: 0;
		}
		&List li {
			counter-increment: eligible-counter;
			fab-color: gray10;
			padding-left: 16px;
			text-indent: -16px;
		}
		&List li:before {
			content: counter(eligible-counter) '. ';
			fab-color: gray6;
		}

		// .Columns__eligible--inactive
		&--inactive {
			fab-color: gray6;

			.Columns__eligibleList li {
				fab-color: gray6;
			}
		}
	}
}
