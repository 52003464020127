.SettingsTraining {
	&AddEdit {
		& &__attach {
			&Row {
				.FilesWrapper {
					margin: 0;

					&__files {
						margin-top: 5px;
						margin-bottom: -1px;
					}
				}

				.FileCard {
					height: 94px;
				}
			}

			&Label {
				margin-bottom: 4px;
			}
		}

		& &__filter {
			&Row {
				margin-top: -5px;
				margin-bottom: 7px;
			}

			&Msg {
				margin-left: 4px;
			}
		}

		& &__requiredWithinDays {
			margin-left: 6px;
			margin-right: 5px;
			padding: 4px 8px;
			min-width: 35px;
		}
	}
}
