.SettingsUsersAccountSettings {
	&__header {
		&Bar {
			height: 56px !important;
		}

		&Wrapper {

		}

		&Icon {
			size: 60px;
			border: 2px solid #f;
			background-color: #d8;
			border-radius: 100%;
			margin: 7px 11px 0 -3px;
		}

		&Name,
		&Title {
			color: #f !important;
		}

		&Name {
			margin: 9px 0 2px;
			font-size: 18px;
			line-height: 21px;
		}

		&Title {
			font-size: 14px;
			line-height: 16px;
		}
	}

	&__birthdayToggle {
		padding-bottom: 12px;

		&__text {
			fab-color: gray9;
			margin-left: 12px;
		}
	}
}