// @startCleanup encore
/* styles needed for new employee card example */
.EmployeeCardContainer {

	/* EmployeeCardContainer__card */
	&__card {
		border-top: 1px solid #D9D9D9;
		border-bottom: 1px solid #D9D9D9;
		box-shadow: 0 10px 10px -7px #f5f5f5, 0 -10px 10px -7px #f5f5f5;
		margin: 17px 0 25px;
	}

	/* EmployeeCardContainer__infoContainer */
	&__infoContainer {
		width: 80%;
	}
}
/* end styles needed for new employee card example */
// @endCleanup encore

#directoryDetails {
	margin-top: 17px;
	
	.fieldsetIndent-inside { 
		margin-bottom: 15px;
	}

	.directoryLabel {
		color: #909090;
		font-size: 15px;
		margin-bottom: 12px;

		strong {
			font-weight: 600;
		}
	}
}

.directoryForm {
	padding-left: 0;
	border-color: #dfdfdf;
	padding-top: 3px;
	
	h5 {
		font-weight: 600;
		font-size: 15px;
		margin-top: 0;
		margin-bottom: 4px;
	}
	
	.ui-multiselect.ui-widget { 
		margin-top: 0; 
	}
	
	fieldset {
		margin-top: 11px;
		margin-bottom: 0;
		padding-bottom: 0;
	}
}

.checkboxColList2 { 
	width: 480px; 
}

#dir {
	margin-bottom: 15px;
	
	&:before,
	&:after {
		content:" ";
		width:100%;
		display:block;
		height:10px;
	}
	
	&:before {
		border-bottom: #dfdfdf 1px solid;
		background-image: linear-gradient(top, #ffffff, #f6f6f6);
	}
	
	&:after {
		border-top: #dfdfdf 1px solid;
		margin-bottom:20px;
		background-image: linear-gradient(top, #f6f6f6, #ffffff);
	}
	
	+ hr {
		margin-bottom:25px;
	}
	
	.entry { 
		margin-left: 0 !important;
		
		> img { 
			float: left; 
		}
	}
}


#settings-page {
	
	h3 {
		&.directoryHeader {
			margin-bottom: 15px;
			font-size: 18px;
		}
	}
}

#sfWrapper {
	height: 185px;
	clear: both;
}

.aWarning {
	width: 640px;
	margin-top: 15px;
}

.selectFields {
	float: left;
	margin-left: 15px;
	margin-bottom: 15px;
	min-width: 203px;
}

.NA {
	display:none;
}

.fieldRow {
	
	.fieldBox {
		
		&.share {
			
			.fieldDiv {
				display: inline-block;
				vertical-align: middle;
			}
			
			> label {
				display: inline-block;
				font-size: 15px;
				color: #000;
				margin: 0 4px 0 11px;
				top: 2px;
			}
		}
	}
}

.ui-multiselect-checkboxes {
	
	li {
		&.divider {
		    border-bottom: solid #f1f1f1 1px;
		}
	}
}

.ui-widget {
	
	&.ui-multiselect-menu {
		
		.everyone {
			
			label {
				
				> input[type="checkbox"] {
					
					+ span:before {
						background:none;
						width: 0;
					}
				}
			}
		}
	}
}

.CompanyDirectoryForm {
	&__infoFields {
		display: flex;
		flex-wrap: wrap;

		&Item {
			margin-top: 16px;
			width: 31%;
		}
	}
	&__settingGroup {
		// @startCleanup encore
		height: 32px;
		// @endCleanup encore
		align-items: center;
		display: flex;
		+encore() {
			height: auto;
			&__canBeSeenBy {
				margin-left: 29px;
			}
			& label {
				fab-font-weight: medium;
				fab-font-size: medium;
			}
		}
	}
	&__InlineMessage {
		width: 100%;
		display: flex;
		flex-direction: column;
		border-radius: var(--radius-200);
		background-color: var(--infoLight, fab-color(infoLight));
		padding: 12px;
		font-size: var(--font-size-teenie);
		box-sizing: border-box;
		&__Title {
			display: flex;
			align-items: center;
			color: var(--infoDark, fab-color(infoDark));
			font-size: var(--font-size-small);
			& strong {
				margin-left: 8px;
			}
		}
		&__Text {
			color: var(--gray8, fab-color(gray8));
			margin-left: 23px;
		}
	}
	&__InfoCard {
		display: flex;
		width: 100%;
		box-sizing: border-box;
		gap: 25px;
		&__Details {
			display: flex;
			width: 100%;
			&__Column {
				display: flex;
				flex-direction: column;
				gap: 4px;
				width: 33%;
				min-width: 234px;
				&--gap-12 {
					gap: 12px;
				}
				&__Text {
					color: var(--gray6, fab-color(gray6));
					font-size: var(--font-size-small);
					display: flex;
					align-items: center;
					gap: 8px;
					& a {
						color: var(--gray6, fab-color(gray6));
						text-decoration: none;
						font-weight: var(--font-weight-regular);
						display: flex;
						align-items: center;
						gap: 8px;
						&:hover {
							text-decoration: underline;
							color: var(--gray8, fab-color(gray8));
							& svg {
								fill: var(--gray8, fab-color(gray8));
							}
						}
					}
				}
				&__Title {
					font-size: var(--font-size-h5);
					color: var(--gray9, fab-color(gray9));
					font-weight: var(--font-weight-semibold);
				}
			}
			&__SocialLinks {
				display: flex;
				gap: 3px;
			}
		}
	}
}
