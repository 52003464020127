.GrantAccessModal {
	min-width: 320px;

	&__user {
		font-size: 14px;
		line-height: 1.4em;
		color: #000;
		font-weight: normal;

		&List {

		}

		&Ico {
			size: 0.8em;
			padding: 3px;
			margin-bottom: -4px;
		}
	}

	&__body {
		padding: 20px;

		label {
			color: #64;
		}
	}

	&__accessLevel {
		&,
		.chzn-drop {
			width: 216px;
			min-width: @width;
			max-width: @width;
			box-sizing: border-box;
		}

		.chzn-search {
			width: 100% !important;
		}
	}

	.modalSubHeader {
		height: auto;
		padding: 10px 20px 14px;
	}
}
