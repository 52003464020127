.TimeTrackingNotificationModal {

	&__description {
		text-align: center;
		fab-color: gray9;
		padding: 0 30px 20px;
	}

	&__doubleRow {
		display: flex;
		flex-direction: column;

		&__subText {
			fab-color: gray7;
			fab-font-size: teenie;
		}
	}
}