.EnpsEmailPreviewModal {
	position: relative;
	width: 600px;
	padding: 20px 0 25px;
	margin: auto;

	+encore() {
		pointer-events: none;
	}

	td {
		text-align: inherit;
	}

	&__blocker {
		position: absolute;
		width: 100%;
		height: 100%;
	}
}

.js-simplemodal-close {
	line-height: 100%;
}


// reset email 'sendwithus' inline styles that Jade is overriding
.CTAbutton {
	background-color: fab-color(theme-base) !important;
}

.FollowUp {

	&__icon {
		background-color: fab-color(theme-base) !important;
	}

	&__heading {
		color: fab-color(theme-base) !important;
	}
}

.LightTop,
.BodyFallback {

	+encore() {
		background-color: #fff !important; // resetting styles for emails which are inline
	}

	tr:hover {
		background-color: #fff !important;
	}
}

.LightTop__iconArea > .HeavyTop__iconImage {
	background-color: fab-color(theme-base) !important;
}

.LightTop {
	&__heading {
		text-align: center !important;
	}

	&__padding {
		border-top: 4px solid fab-color(theme-base) !important;
	}

	&__stripe {
		background-color: fab-color(theme-base) !important;
	}
}

.MainText {

	&__paragraph {
		width: 100% !important;
		padding-left: 15px !important;
		padding-right: 15px !important;
	}
}

.Survey {

	&__scaleButton {
		background-color: #fff !important;
		border: 1px solid fab-color(theme-base) !important;
		border-radius: 16px !important;
		fab-color: theme-base;

	}

	&__scaleLegend {
		td {
			width: 100% !important;
			white-space: nowrap;
		}
	}
}

.mobile-content {
	display: none !important;
}
.lg-screen-only {
	display: unset !important;
}
tr.lg-screen-only {
	display: table-row !important;
}
table.lg-screen-only {
	display: table !important;
}
