.CompanyInfo {

	&__column {
		flex: 1;
		max-width: 275px;
	}

	&__description {
		fab-color: gray8;
		fab-font-size: small;
	}

	&__edit {
		fab-color: gray8;
		fab-font-size: small;
	}

	&__info {
		align-items: center;
		display: flex;

		&--branded {
			fab-color: theme-base;
			fab-font-weight: semibold;
		}
	}

	&__label {
		display: flex;
		fab-color: gray7;
		fab-font-size: teenie;
		margin-bottom: 2px;
	}

	&__row {
		display: flex;
		fab-color: gray7;
		margin-bottom: 16px;
	}

	&__sectionHeading {
		display: flex;
		margin-bottom: 0;
	}

	&__spacer {
		flex: 1 1 auto;
	}
}
