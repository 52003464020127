// .ba-CheckboxCard
.ba-CheckboxCard {
	display: inline-block;
	position: relative;
	border: 1px solid fab-color(theme-base);
	border-radius: 2px;
	box-sizing: border-box;
	cursor: pointer;

	+encore() {
		display: flex;
		flex-direction: column;
		fab-color: gray8;
		fab-fill: gray7;
		box-shadow: 1px 1px 0 2px #38312F08;
		width: 220px;
		height: 145px;
		padding: 20px !important;
		border-radius: 16px;
		justify-content: flex-start;
		align-items: flex-start;
		gap: 35px;
	}

	// input[type="checkbox"] .ba-CheckboxCard,
	// input[type="radio"] .ba-CheckboxCard,
	// .BhrForms input[type="checkbox"] .ba-CheckboxCard,
	// .BhrForms input[type="radio"] .ba-CheckboxCard
	/input[type="checkbox"] + &,
	/input[type="radio"] + &,
	/.BhrForms input[type="checkbox"] + &,
	/.BhrForms input[type="radio"] + & {
		padding: 0;
	}
	// body input[type="checkbox"]:focus .ba-CheckboxCard:before,
	// body input[type="radio"]:focus .ba-CheckboxCard:before,
	// body .BhrForms input[type="radio"]:focus .ba-CheckboxCard:before,
	// body .BhrForms input[type="radio"]:focus .ba-CheckboxCard:before,
	/body input[type="checkbox"]:focus + &:before,
	/body input[type="radio"]:focus + &:before,
	/body .BhrForms input[type="checkbox"]:focus + &:before,
	/body .BhrForms input[type="radio"]:focus + &:before {
		border-radius: 100%;
		box-shadow: none;
	}
	// .ba-CheckboxCard:before,
	// input[type="checkbox"] + .ba-CheckboxCard:before,
	// input[type="checkbox"]:checked + .ba-CheckboxCard:before,
	// input[type="checkbox"] + .ba-CheckboxCard:before,
	// input[type="radio"]:checked + .ba-CheckboxCard:before
	&:before,
	/input[type="checkbox"] + &:before,
	/input[type="checkbox"]:checked + &:before,
	/input[type="radio"] + &:before,
	/input[type="radio"]:checked + &:before {
		content: "";
		position: absolute;
		bottom: -1px;
		right: -1px;
		margin: 0;
		height: 21px;
		width: 21px;
		display: none;
		background: none;
		fab-background-color: theme-base;
		border: 3px solid fab-color(theme-base);
		border-radius: 4px 1px 2px 1px;
		box-sizing: border-box;

		+encore() {
			background: var(--gray1, #F5F4F1);
          	border: 1px solid var(--gray4, #C6C2BF);
			gap: 0;
			border-radius: 4px;
			box-shadow: 1px 1px 0 1px #38312F0D inset;
			display: none;
			bottom: 20px;
			right: 20px;
			left: unset;
			top: unset;
			height: 16px;
			width: 16px;
		}
	}

	/input[type="checkbox"]:checked + &:before,
	/input[type="radio"]:checked + &:before {
		+encore() {
			border: none;
			fab-background-color: theme-base;
		}
	}

	// .ba-CheckboxCard--no-description
	&--no-description {
		display: inline-flex;
		flex-direction: column;
		justify-content: center;
	}
	// .ba-CheckboxCard__icon
	&__icon {
		// .ba-CheckboxCard__icon--no-description
		&--no-description {
			text-align: center;
		}
	}
	// .ba-CheckboxCard:after
	&:after {
		content: "";
		position: absolute;
		bottom: 8px;
		right: 4px;
		transform: rotate(-50deg);
		width: 9px;
		height: 4px;
		display: none;
		background: none;
		border: 2px solid fab-color(white);
		border-top: none;
		border-right: none;

		+encore() {
			bottom: 22px;
			right: 21px;	
			border: none;
			width: 12px;
			height: 12px;
			transform: none;
			background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" fill="white" viewBox="0 0 448 512"><path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"/></svg>');
			background-repeat: no-repeat;
		}
	}
	// input[type="checkbox"]:not(:checked) + .ba-CheckboxCard,
	// input[type="radio"]:not(:checked) + .ba-CheckboxCard
	/input[type="checkbox"]:not(:checked) + &,
	/input[type="radio"]:not(:checked) + & {
		fab-border-color: gray5;
		+encore() {
			fab-border-color: gray2;
		}
	}
	// input[type="checkbox"]:checked + .ba-CheckboxCard,
	// input[type="radio"]:checked + .ba-CheckboxCard
	/input[type="checkbox"]:checked + &,
	/input[type="radio"]:checked + & {
		background: none;
		box-shadow: 0 0 1px 1px fab-color(theme-lightest);

		+encore() {
			background-color: var(--white);
			fab-color: theme;
			fab-fill: theme;

			& .ba-CheckboxCard__activeIcon {
				display: block;	
			}

			& .ba-CheckboxCard__icon {
				display: none;	
			}
		}
		// input[type="checkbox"]:checked + .ba-CheckboxCard:before,
		// input[type="checkbox"]:checked + .ba-CheckboxCard:after,
		// input[type="radio"]:checked + .ba-CheckboxCard:before,
		// input[type="radio"]:checked + .ba-CheckboxCard:after
		&:before,
		&:after {
			display: block;
		}
	}
	// .ba-CheckboxCard:hover,
	// input[type="checkbox"] + .ba-CheckboxCard:hover,
	// input[type="radio"] + .ba-CheckboxCard:hover,
	&:hover,
	/input[type="checkbox"] + &:hover,
	/input[type="radio"] + &:hover {
		// .ba-CheckboxCard:hover:before,
		// .ba-CheckboxCard:hover:after,
		// input[type="checkbox"] + .ba-CheckboxCard:hover:before,
		// input[type="checkbox"] + .ba-CheckboxCard:hover:after,
		// input[type="radio"] + .ba-CheckboxCard:hover:before,
		// input[type="radio"] + .ba-CheckboxCard:hover:after
		&:before,
		&:after {
			display: block;
		}
	}
	// .ba-CheckboxCard:hover,
	// input[type="checkbox"]:not(:checked) + .ba-CheckboxCard:hover,
	// input[type="radio"]:not(:checked) + .ba-CheckboxCard:hover,
	&:hover,
	/input[type="checkbox"]:not(:checked) + &:hover,
	/input[type="radio"]:not(:checked) + &:hover {
		background: none;
		// .ba-CheckboxCard:hover:before,
		// .ba-CheckboxCard:hover:after,
		// input[type="checkbox"]:not(:checked) + .ba-CheckboxCard:hover:before,
		// input[type="checkbox"]:not(:checked) + .ba-CheckboxCard:hover:after,
		// input[type="radio"]:not(:checked) + .ba-CheckboxCard:hover:before,
		// input[type="radio"]:not(:checked) + .ba-CheckboxCard:hover:after
		&:before,
		&:after {
			display: block;
		}
		// .ba-CheckboxCard:hover:before,
		// input[type="checkbox"]:not(:checked) + .ba-CheckboxCard:hover:before,
		// input[type="radio"]:not(:checked) + .ba-CheckboxCard:hover:before
		&:before {
			fab-background-color: gray6;
			border: 3px solid fab-color(gray6);

			+encore() {
				border: 1px solid fab-color(gray6);
			}
		}
	}
	// input[type="checkbox"]:checked + .ba-CheckboxCard.ba-CheckboxCard--no-description,
	// input[type="radio"]:checked + .ba-CheckboxCard.ba-CheckboxCard--no-description
	/input[type="checkbox"]:checked + &&--no-description,
	/input[type="radio"]:checked + &&--no-description {
		// input[type="checkbox"]:checked + .ba-CheckboxCard.ba-CheckboxCard--no-description,
		// input[type="radio"]:checked + .ba-CheckboxCard.ba-CheckboxCard--no-description
		// input[type="checkbox"]:checked + .ba-CheckboxCard.ba-CheckboxCard--no-description p,
		// input[type="radio"]:checked + .ba-CheckboxCard.ba-CheckboxCard--no-description p {
		&,
		p {
			color: fab-color(theme);
			fab-font-weight: bold;
		}
	}

	&__label {
		fab-font-weight: medium;
	}

	&__activeIcon {
		display: none;
	}
}
