$theme = '--minimal-theme';

.BaTabs {
	&__tab {
		&{$theme} {
			white-space: nowrap;
			display: flex;
			align-items: center;
			padding: 3px 20px 16px;
			margin: 0 1px;
		}		

		&--divider&{$theme} {
			border-top: 1px solid $bhrColors.gray4;
			padding: 2px 0 0;
			margin-top: 2px;
		}

		&OverflowWrapper {
			&{$theme} {
				top: 100%;
				right: 0;
				padding: 2px 0;
				border: 1px solid #af;
				border-radius: 3px;
				width: 160px;
				background: $colors.white;
				z-index: 99;
				overflow-y: auto;
				max-height: 300px;
			}
		}

		&Label {
			&{$theme} {
				font-size: 15px;
				font-weight: 600;
				line-height: 19px;
			}

			&--disabled&{$theme} {
				color: #a;
			}


			&--primary&{$theme} {
				.BaTabs__tab--overflow > & {
					&:after {
						content: "";
						display: inline-block;
						position: relative;
						top: 4px;
						margin-left: 3px;
						border: 5px solid transparent;
						border-top-color: $bhrColors.gray12;
					}
				}

				.BaTabs__tab--overflow .BaTabs__tab > & {
					max-width: 200px;
					padding: 4px 11px;
					text-overflow: ellipsis;
				}
			}

			&--secondary&{$theme} {
				font-weight: 100;
				padding-left: 5px;
				color: $bhrColors.gray7 !important;
			}
		}

		&--active&{$theme} {
			&:after {
				content: "";
				display: block;
				position: absolute;
				bottom: -1px;
				left: 0;
				width: 100%;
				height: 2px;

				.BaTabs__tab--overflow .BaTabs__tab > & {
					width: 4px;
					height: 27px;
				}
			}
		}

		&{$theme}:not(&--active):not(&--disabled) {				
				& .BaTabs__tabIcon {
					&{$theme} {
						fill: $bhrColors.textLink;
					}					
				}

				& .BaTabs__tabLabel {
					font-weight: normal;
					&{$theme} {
						color: $bhrColors.textLink;
					}
				}				

				&:after {
					content: "";
					transition: 300ms;
					opacity: 0;
				}				
				&:hover {
					& .BaTabs__tabIcon {
						&{$theme} {
							fill: inherit;
						}						
					}

					& .BaTabs__tabLabel {
						&{$theme} {
							color: inherit;
						}
					}
			}
		}

		&Icon {
			&{$theme} {
				> svg {
					size: 20px;
					padding-right: 8px;
				}
			}
		}

		&Wrapper {
			&{$theme} {
				margin: 7px 0 10px;
				border-bottom: 1px solid $bhrColors.gray4;
			}
		}
	}
}
