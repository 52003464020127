.WireTransferInfo {
	td {
		padding: 2px 0;
		fab-font-size: medium;
	}

	td:nth-child(1) {
		width: 170px;
		fab-color: gray7;
	}

	td:nth-child(2) {
		fab-font-weight: bold;
		fab-color: gray8;
	}
}
