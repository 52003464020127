.MigrationSection {
	padding-bottom: 100px;

	// .MigrationSection__tableContainer
	&__tableContainer {
		padding-top: 19px;
	}

	// .MigrationSection__whiteGradient
	&__whiteGradient {
		position: fixed;
		bottom: 91px;
		width: 100%;
		height: 100px;
		background: linear-gradient(0deg, rgba(255,255,255,1) 0, rgba(255,255,255,0) 100%);
	}

	// .MigrationSection__clientInfo
	&__clientInfo {
		display: flex;
		align-items: center;
		text-align: left;
		padding-bottom: 3px;
	}

	// .MigrationSection__clientName
	&__clientName {
		padding-left: 8px;
	}
}
