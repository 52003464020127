.MigrationTable {

	// .MigrationTable__groupHeader
	&__groupHeader {
		display: flex;
		align-items: flex-end;

		// .MigrationTable__groupHeaderIcon
		&Icon {
			fab-fill: gray7;
			margin-right: 10px;
			max-height: 22px;

			// .MigrationTable__groupHeaderIcon--attention
			&--attention {
				fab-fill: attention;
			}
		}

		// .MigrationTable__groupHeaderName
		&Name {
			fab-font-size: large;
		}
	}

	// .MigrationTable__nameCell
	&__nameCell {
		padding-left: 30px;

		// .MigrationTable__nameCell--subGroup
		&--subGroup {
			fab-font-size: small;
			fab-color: gray7;
			fab-font-weight: semibold;
		}
	}
}
