/* For the marketing upsell page for time tracking */
.TimeTrackingUpsell {
	padding-top: 18px;
	text-align: center;

	&__video {
		height: 386px;
		margin-left: auto;
		margin-right: auto;
		margin-top: 40px;
		max-width: 682px;
	}

	&__button {
		margin-top: 40px;

		&--hide {
			display: none;
		}
	}

	&__contactMessage {
		border-radius: 4px;
		border: 1px solid;
		font-size: 17px;
		margin-left: auto;
		margin-right: auto;
		margin-top: 40px;
		max-width: 320px;
		padding: 5px 20px;

		&--hide {
			display: none;
		}
	}

	&__summary {
		fab-color: gray7;
		fab-font-size: large;
		fab-line-height: large;
		margin-left: auto;
		margin-right: auto;
		margin-top: 40px;
		text-align: center;
	}

	&__note {
		margin-top: 30px;
	}
}

.TTModal {
	/* This div is needed to allow hidden "wizard" steps. */
	overflow-x: hidden;
	width: 520px;
	background-color: #fff;
	height: 520px;

	&__steps-wrap {
		left: 0;
		position: relative;
		width: calc(520px * 4);
	}

	&__step-section {
		float: left;
		margin: 0;
		padding: 0 0 18px;
		width: 520px;
		box-sizing: border-box;
	}

	&__header {
		padding: 10px 0;
		text-align: center;
	}

	&__subtext {
		margin: 18px 0 11px;
	}

	&__page-section {
		margin-bottom: 20px;
	}

	&__confirmation-message {
		text-align: center;
	}

	&__not-included-list > &__list-item {
		fab-color: gray10;
		font-size: 15px;
		padding: 0 0 8px;
		line-height: 17px;
	}

	&__not-included-list > &__list-item::before {
		content: "\2022";
		display: inline-block;
		width: 15px;
		fab-color: theme-base;
	}

	&__icon {
		margin-bottom: 12px;
		fab-fill: theme-base;
	}

	&__icon--question {
		display: flex;
		justify-content: center;
	}
}
