.HoursPerWeekField__container {
	align-items: center;
	color: fab-color('gray7');
	font-size: 13px;
	margin-top: 20px;
}

.HoursPerWeekField__helpButton {
	background-color: $bhrColors.gray9;
	border-radius: 100%;
	border-width: 0;
	color: fab-color('white');
	cursor: pointer;
	display: inline-block;
	font-family: Lato;
	font-size: 12px;
	font-weight: 800;
	height: 14px;
	line-height: 14px;
	margin-left: 4px;
	padding: 0;
	text-align: center;
	user-select: none;
	width: 14px;
}

.HoursPerWeekField__inputContainer {
	align-items: center;
	display: flex;
}

.HoursPerWeekField__inputNote {
	margin-left: 8px;
}

.HoursPerWeekField__popoverButton {
	margin-top: 16px;
	text-align: left;
}
