.MeteredTextarea {
/* @startCleanup encore */
	padding: 0 41px 0 20px;
/* @endCleanup encore */

	+encore() {
		margin-top: 20px;
		padding: 0 0 0 20px;
	}

	&__meter {
		border-radius: 10px;
		bottom: 4px;
		fab-background-color: gray4;
		height: 8px;
		overflow: hidden;
		position: relative;
		width: 100%;

		+encore() {
			border-radius: var(--radius-100);
			bottom: 0;
			margin-top: 12px;
			height: 4px;
			width: calc(100% - 10px);
			left: 5px;
		}
	}

	&__progress {
		height: 8px;
		transition: width ease .5s;

		&--default {
			fab-background-color: gray7;
		}

		&--better {
			fab-background-color: attention;
		}

		&--best {
			fab-background-color: success;
		}

	}

	&__comment {
		font-size: 12px;
		padding-top: 7px;
		fab-font-size: teenie;

		&--default {
			fab-color: gray7;
		}

		&--better {
			fab-color: attention;
		}

		&--best {
			fab-color: success;
		}

	}

	&__wrapper {
		display: flex;
		flex-flow: column;
		-ms-flex-flow: column;
		width: 100%;

		& > textarea {
			font-family: inherit;
			width: 100%;
		}
	}

	// BhrForms overrides
	& .MeteredTextarea__body {
		font-size: 14px;
		height: 155px;
		line-height: 21px;
		position: relative;
		resize: none;
		z-index: 2;

		+jadeFabricTheme() {
			border-bottom: none;
		}
	}

}

@media only screen and (max-width: 800px) {
	.MeteredTextarea {
		padding: 0;

		&__comment {
			font-size: 14px;
			line-height: 18px;
			text-align: center;
			margin-top: 10px;
		}
	}
}
