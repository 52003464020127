.Setup {
	fab-color: gray8;

	&__includeContractors {
		margin-bottom: 20px;
	}

	&__emailBody {
		display: block;
		margin-bottom: 8px;
		height: 240px;
		font-size: 15px;
		line-height: 22px;

		+encore() {
			height: 280px;
			margin-bottom: 12px;
		}
	}

	&__emailEnd {
		display: block;
		fab-background-color: gray1;
		fab-color: gray7;
		font-size: 14px;
		line-height: 20px;
		border: none;
		padding: 14px 13px;
		margin-bottom: 6px;
		min-height: auto;

		p + p {
			margin-top: 7px;
		}

		+encore() {
			background-color: var(--gray05);
			border-radius: var(--radius-200)
			color: var(--gray10);
			padding: 20px 16px;
			margin-bottom: 16px;
		}
	}

	&__6MthInfoIcon {
		margin-left: 4px;
		fill: currentColor;
		width: 14px;
		height: 14px;
		display: inline-block;
		vertical-align: sub;
	}

	& &__surveyFrequency {
		margin: 15px 0 20px;

		label {
			margin-bottom: 4px;
		}

		+encore() {
			margin: 16px 0 0;
		}
	}

	&__pssst {
		display: flex;
		justify-content: space-between;
		align-items: center;
		font-size: 13px;
	}

	&__icon {
		margin-right: 5px;

		&--link {
			fab-fill: info;
		}
	}

	&__footer {
		// global <footer> overrides
		height: auto;
		text-align: left;
		min-width: auto;
		// END global <footer> overrides

		margin-top: 30px;
		padding-top: 15px;
		border-top: 1px solid fab-color(gray4);

		+encore() {
			border-top: 1px solid var(--gray2);
		}

		&CancelButton {
			margin-left: 16px;
		}
	}

	&__save {
		margin-right: 10px;
	}

	&__stepHeading {
		fab-color: gray10;
		font-size: 16px;
		font-weight: 600;
		margin-bottom: 5px;
	}

	&__sendDateWrapper {
		+encore() {
			margin-bottom: 12px;
		}
	}

	&__stepParagraph {
		margin-bottom: 20px;
		font-size: 14px;
		line-height: 17px;

		+encore() {
			margin-bottom: 0;
		}
	}

	// BhrForm overrides
	&.BhrForms {
		padding-top: 0;
	}

	& .Setup__lessMargin {
		margin-bottom: 0;
	}

	& .Setup__emailBody,
	& .Setup__emailEnd {
		font-size: 14px;
		line-height: 21px;
		resize: none;
	}
	// END BhrForm overrides

}
