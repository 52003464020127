.MultiEinBanksTable {

	// .MultiEinBanksTable__group
	&__group {
		display: inline-block;
	}

	// .MultiEinBanksTable__companyEin
	&__companyEin {
		fab-font-weight: normal;
	}

	// .MultiEinBanksTable__addNew
	&__addNew {
		fab-font-weight: heavy;
	}

	// .MultiEinBanksTable__bankName
	&__bankName {
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
		display: block;
		width: 220px;
	}
	.BankInfoHeader--noBottom ~ & {
		margin-top: 23px;
	}
}
