.DynamicForm__OneEmailRequired {
    &--error {
        position: relative;

        .fab-Label {
            margin-bottom: 10px;
        }

        .DynamicForm__OneEmailRequired__wrapper {
            &:before {
                border: 1px solid fab-color(danger);
                bottom: -3px;
                content: "";
                left: -3px;
                position: absolute;
                top: 24px;
                width: 293px;
            }
        }
    }

    &--required {
        .DynamicForm__FieldRow {
            // hide the original label
            .fab-Label {
                display: none;
            }
        }
    }

    &__wrapper {
        & > .DynamicForm__FieldRow {
            margin-bottom: 8px;
        }
    }
}