.SetAccessLevelModal {
	&__header {
		fab-background-color: gray1;
		padding: 12px 16px 16px;
		margin: -12px -16px 24px;
		border-bottom: 1px solid fab-color(gray4);

		&Title {
			fab-text: large;
			fab-font-weight: semibold;
		}

		&List {
			&Item {
				display: flex;
				align-items: center;
				fab-fill: gray8;
				margin-top: 2px;

				&Ico {
					margin-right: 8px;
				}

				&Text {

				}
			}
		}
	}
}