.ClockOutSlat {
	border-bottom: 1px solid darken($bhrColors.gray4, 5.88);
	&:last-of-type {
		border-bottom: none;
	}
	margin: 0 20px;
	padding: 12px 0 15px;

	/* .ClockOutSlat:last-of-type */
	&:last-of-type {
		padding-bottom: 30px;
	}

	/* .ClockOutSlat__header */
	&__header {
		display: flex;
		justify-content: space-between;
		margin-bottom: 8px;
	}

	/* .ClockOutSlat__employee */
	&__employee {
		align-items: center;
		display: flex;
	}

	/* .ClockOutSlat__name */
	&__name {
		font-size: 16px;
		fab-color: gray10;
		font-weight: 600;
		margin-left: 7px;
	}

	/* .ClockOutSlat__timezone */
	&__timezone {
		fab-color: gray7;
		font-size: 13px;
		margin: 0 0 0 5px;
	}

	/* .ClockOutSlat__dateRange */
	&__dateRange {
		font-size: 13px;
		fab-color: gray7;
	}

	/* .ClockOutSlat__clockForm */
	&__clockForm {
		padding-left: 20px !important;
	}

	/* .ClockOutSlat__startTime */
	&__startTime {
		width: 73px !important;
	}

	/* .ClockOutSlat__clockedOut */
	&__clockedOut {
		display: inline-flex;
		fill: $bhrColors.warning1;
		line-height: 0;
		margin-bottom: 5px;
	}
}
