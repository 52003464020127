.SettingsAccessLevelsAddEmployeeModal {
	&__advancedOptions {
		&Container {
			padding-left: 17px;

			&--closed {
				display: none;
			}
		}

		&ToggleIco {
			&--closed {
				transform: rotateZ(-90deg);
			}
		}
	}
}
