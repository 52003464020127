
.ba-BarChart__groupLabelsArea {
	fill: $bhrColors.gray9;;
}

{featureToggleOn("jade")} {
	.BarChart {
		&--fillThemeColor {
			fill: fab-color(theme-base);
		}
	}

	.ba-BarChart__groupLabelsArea {
		fab-fill: gray8;
	}
}
