/* @startCleanup encore */
.EmployeeWellbeingHeader {
	border-bottom: 1px solid fab-color(gray4);
	margin-top: 19px;
	margin-bottom: 16px;
	padding-bottom: 20px;
	// overwrite global header style
	min-width: auto;
	text-align: left;

	&--noBorderBottom {
		border-bottom: none;
	}

	&__heading {
		align-items: center;
		fab-color: gray10;
		display: flex;

		&Icon {
			line-height: 1px;
			fab-fill: theme;
			display: inline-flex;
			margin-top: 1px;
		}

		&Spacer {
			flex: 1 1 auto;
		}
	}

	&__title {
		fab-color: gray10;
		fab-font-weight: semibold;
		font-family: unset;
		font-size: 18px;
		line-height: 25px;
		margin-left: 10px;
	}
}
/* @endCleanup encore */
