/* AdvancedApprovalsPathModal */
.AdvancedApprovalsPathModal {
	padding: 0;
 padding-bottom: 16px;

	/* AdvancedApprovalsPathModal, AdvancedApprovalsPathModal p */
	&,
	p,
	&__confirmation-modal p {
		text-align: center;
		fab-font-size: medium;
		fab-line-height: medium;
	}

	// .AdvancedApprovalsPathModal p
	& p {
		color: fab-color(gray9);
	}

	/* AdvancedApprovalsPathModal .checkboxCardSpacing */
	& .checkboxCardSpacing {
		margin-right: 18px;
	}

	/* AdvancedApprovalsPathModal__footer-link-look-button */
	&__footer-link-look-button {
		color: fab-color(info);
		background: none;
		border: none;
		font-family: inherit;
		font-size: inherit;
	}

	/* AdvancedApprovalsPathModal__footer-link-look-button:hover */
	&__footer-link-look-button:hover {
		text-decoration: underline;
	}

	/* AdvancedApprovalsPathModal__icon */
	&__icon {
		margin-top: 8px;
		fill: fab-color(gray8);
	}

	/* AdvancedApprovalsPathModal__options-container */
	&__options-container {
		padding-top: 16px;

		/* AdvancedApprovalsPathModal__options-container__options-header */
		&__options-header {
			display: block;
			color: fab-color(gray9);
			fab-font-size: medium;
			margin-bottom: 24px;
		}
	}

	/* AdvancedApprovalsPathModal .PathOptions */
	.PathOptions {
		/* AdvancedApprovalsPathModal .PathOptions .PathOption */
		.PathOption {
			display: inline-block;

			/* AdvancedApprovalsPathModal .PathOptions .PathOption__option */
			&__option {
				border-color: fab-color('theme-lighter');
				height: 100px;
				width: 156px;
			}
		}
	}

	/* AdvancedApprovalsPathModal__title */
	&__title {
		margin: 28px 0 14px;
	}

	/* AdvancedApprovalsPathModal__warning-container */
	&__warning-container {
		margin-top: 18px;
		color: fab-color('attention');
		fill: fab-color('attention');

		/* AdvancedApprovalsPathModal__warning-container__icon */
		&__icon {
			margin-right: 5px;
		}

		/* AdvancedApprovalsPathModal__warning-container__text */
		&__text {
			display: inline-block;
			margin-left: 5px;
			position: relative;
			top: -3px;
			text-align: center;
		}
	}
}

/* AdvancedApprovalsPathConfirmationModal */
.AdvancedApprovalsPathConfirmationModal {
	padding-left: 40px;
	padding-right: 40px;

 display: flex;
 justify-content: center;

	/* AdvancedApprovalsPathConfirmationModal, AdvancedApprovalsPathConfirmationModal p */
	&,
	p {
		text-align: center;
	}

	/* AdvancedApprovalsPathConfirmationModal__text-container */
	&__text-container {
		font-size: 16px;
		margin-bottom: 19px;
		max-width: 330px;
		color: fab-color(gray10);

		/* AdvancedApprovalsPathConfirmationModal__text-container--sub-text */
		&--sub-text {
			font-size: 13px;
			max-width: 285px;
			margin-left: 22px;
			margin-top: 6px;
			color: fab-color(gray5);
		}
	}

	/* AdvancedApprovalsPathConfirmationModal__warning-icon-container */
	&__warning-icon-container {
		margin: 24px auto 22px;
		fill: fab-color('attention');
	}
}
