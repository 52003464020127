.InterstitialBackupCodes {
	.InterstitialBackupCodes__footer {
		margin-top: 40px;

		button:nth-child(n+2) {
			margin-left: 16px;
		}
	}

	.InterstitialBackupCodes__headerTitle {
		margin-bottom: 24px;
	}
}
